import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import { LABELS, DATASETS_ACIERTOS, DATASETS_TRABAJO} from "./barras_constants"

@Component({
    selector: 'app-barras',
    templateUrl: './barras.component.html',
    styleUrls: ['./barras.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BarrasComponent implements OnInit {
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.consultaID !== null && this.consultaID != 0) {
			//this.datasets = this.datasetsImported;
			//this.labels = this.labelsImported;
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, this.parametroID - 1)];
			this.labels = this.obtenerLabels(this.datasetsImported, this.parametroID - 1);
    } else if(this.consultaID !== null && this.consultaID == 0){
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0)];
			this.labels = this.obtenerLabels(this.datasetsImported, 0);
		}
		setTimeout(() =>	{
			if(this.parametroID == 0){
				this.createChartSocial();
			} else	if(this.parametroID == 1 && this.consultaID != null){
				this.createChartAciertos();
			} else if(this.parametroID == 2 && this.consultaID != null){
				this.createChartConocimiento();
			}
		}, 200);
  }

	obtenerLabels(dataSets, index) {
		let filteredData;
		if(this.consultaID == 0){
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null);
		} else{
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null && value !== 0);
		}
    return dataSets.map(info =>
			this.parametroID == 0 ? this.translateService.instant('GLOBALS.OPTION') + ': ' + info.label : info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
		let filteredData;
		if(this.consultaID == 0){
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null);
		} else{
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null && value !== 0);
		}
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}
	colorKnowledge(valor: number): string {
		let color = "rgba(154, 155, 156, 0.5)"; // gris con 50% de transparencia por defecto
		if(this.parametroID == 0){
			color = this.generateHexCode(); // #COLOR ALEATORIO
		} else if(this.parametroID == 1){
			if (valor == 0) color = "rgba(154, 155, 156, 0.5)"; // #9A9B9C
			else if (valor > 0 && valor < 11) color = "rgba(251, 13, 28, 0.5)"; // #FB0D1C
			else if (valor < 21) color = "rgba(252, 92, 32, 0.5)"; // #FC5C20
			else if (valor < 31) color = "rgba(252, 154, 39, 0.5)"; // #FC9A27
			else if (valor < 41) color = "rgba(254, 229, 51, 0.5)"; // #FEE533
			else if (valor < 51) color = "rgba(255, 253, 56, 0.5)"; // #FFFD38
			else if (valor < 61) color = "rgba(212, 253, 53, 0.5)"; // #D4FD35
			else if (valor < 71) color = "rgba(156, 253, 50, 0.5)"; // #9CFD32
			else if (valor < 81) color = "rgba(41, 248, 46, 0.5)"; // #29F82E
			else if (valor < 91) color = "rgba(42, 213, 45, 0.5)"; // #2AD52D
			else if (valor < 101) color = "rgba(26, 175, 84, 0.5)"; // #1AAF54
		} else if(this.parametroID == 2){
			if (valor == 0) color = "rgba(154, 155, 156, 0.5)"; // #9A9B9C
			else if (valor > 0 && valor < 1.1) color = "rgba(251, 13, 28, 0.5)"; // #FB0D1C
			else if (valor < 2.1) color = "rgba(252, 92, 32, 0.5)"; // #FC5C20
			else if (valor < 3.1) color = "rgba(252, 154, 39, 0.5)"; // #FC9A27
			else if (valor < 4.1) color = "rgba(254, 229, 51, 0.5)"; // #FEE533
			else if (valor < 5.1) color = "rgba(255, 253, 56, 0.5)"; // #FFFD38
			else if (valor < 6.1) color = "rgba(212, 253, 53, 0.5)"; // #D4FD35
			else if (valor < 7.1) color = "rgba(156, 253, 50, 0.5)"; // #9CFD32
			else if (valor < 8.1) color = "rgba(41, 248, 46, 0.5)"; // #29F82E
			else if (valor < 9.1) color = "rgba(42, 213, 45, 0.5)"; // #2AD52D
			else if (valor < 10.1) color = "rgba(26, 175, 84, 0.5)"; // #1AAF54
		}
		return color;
	}
	generateHexCode() {
		const letters = "89ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 8)];
		}
		return color;
	}

	createChartAciertos(){
		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						stacked: false,
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						ticks: {
							callback: function(value) {
								return value + '%'; // Formatea los valores como porcentajes
							}
						}
					}
				},
			}
		});
	}

	createChartConocimiento(){
		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw;
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						stacked: false,
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						ticks: {
							callback: function(value) {
								return value; // Formatea los valores como porcentajes
							}
						}
					}
				},
			}
		});
	}

	createChartSocial(){
		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw;
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						stacked: false,
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
							text: this.translateService.instant('INFORMES.AVERAGETIMEPERMINUTE'),
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						ticks: {
							callback: function(value) {
								return value; // Formatea los valores como porcentajes
							}
						}
					}
				},
			}
		});
	}

}
