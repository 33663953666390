
import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import countdown from 'countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login/login.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetsService } from 'src/app/core/services/targets';
import { ListQuizzesStackChallengesModel, QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { ModalReproducirNodoComponent } from 'src/app/shared/components/sigma-canvas/modal-reproducir-nodo/modal-reproducir-nodo.component';
import { QuizModel } from 'src/app/core/models/quizzes';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { QuizTemplateElement } from 'src/app/core/models/quizzes/quiz-template-element.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { QuizEditTemplateTextEditorComponent } from '../../../quiz-edit/components/quiz-edit-template/components/quiz-edit-template-text-editor/quiz-edit-template-text-editor.component';
import { TranslateService } from '@ngx-translate/core';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizPlayBodyOptionComponent } from '../quiz-play-body-option/quiz-play-body-option.component';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { QuizElement } from 'src/app/core/models/quizzes/quiz-element.model';
import { QuizElementsPipe } from 'src/app/shared/pipes/quiz-elements.pipe';
import { environment } from 'src/environments/environment';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { Utils } from 'src/app/core/utils/utils';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { take } from 'rxjs/operators';
import { LocalStorage } from 'src/app/core/utils';

export interface Time {
	hours: number,
	minutes: number,
	seconds: number;
}


@Component({
    selector: "app-quiz-play-body",
    templateUrl: "./quiz-play-body.component.html",
    styleUrls: ["./quiz-play-body.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QuizPlayBodyComponent implements OnInit, OnDestroy, OnChanges {
	@Input() quiz: QuizModel;
	@Input() rawQuiz: any;
	@Input() courseId: number;
	@Input() graphId: number;
	@Input() elements: any[];
	@Input() option: number;
	@Input() viewQuizDuration?: boolean = false;

	@Input() modeAuto = false;
	@Input() disabledButton = false;
	@Input() answered: boolean;
	@Input() modeSocket: boolean;
	@Input() answeredBySocket = false;
	@Input() idGroup: number;
	@Input() selectedType: string = SOCKETMESSAGES.OPEN;
	@Output() answeredChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onTimeEnd: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onSaveResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() discoverAnswer$: Observable<boolean>;
	@Input() evaluate$: Observable<boolean>;
	@Input() playNext$: Observable<boolean>;
	@Input() tryClose$: Observable<boolean>;
	isLoading = false;
	responseByFile = false; //si es true se debe responder con video, audio, imagen o documento

	time: Time;
	iniBlock: boolean;
	countSeconds: number;
	timerId: number = null;
	template: any;

	arrayQuiz: any[] = [];
	isResponse: boolean;
	userQuiz: any;
	automatic: boolean;
	node: any;
	currentGraph: any;
	type: any;
	nextNodo: any;
	variablesPublicUtils: any;
	hideCheckButton = false;

	public quizType: number;

	elementsQP: QuizTemplateElement[] = [];
	elementsCorrect: QuizTemplateElement[] = [];
	optionCheckedId: number[] = [];
	// ============================================================
	private timeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public time$: Observable<any> = this.timeSubject.asObservable();
	public questions: any[] = [];
	options: any[] = [];
	public answers: any[] = [];
	//==============================================================
	//EXTENSIONES DE FICHEROS
	quizElementsPipe: QuizElementsPipe = new QuizElementsPipe();
	@ViewChild(QuizPlayBodyOptionComponent)
	optionManualComponent: QuizPlayBodyOptionComponent;

	public filesUploads = {
		answersImagen: "",
		answersDocs: "",
		answersAudio: "",
		answersVideo: "",
		answersText: "",
	};
	public arrayFileView = [];
	public imagenExt: any[] = ["png", "gif", "jpg", "jpeg"];
	public docExt: any[] = ["pdf"];
	public audioExt: any[] = ["mp3"];
	public videoExt: any[] = ["mp4", "mov", "flv", "webm"];
	//==============================================================

	files: File[] = [];
	files_aux: any[] = [];

	private openTime: number;
	private questionTypes: QuizElementTypes[] = [
		QuizElementTypes.Q_TEXTS,
		QuizElementTypes.Q_AUDIOS,
		QuizElementTypes.Q_PDFS,
		QuizElementTypes.Q_PICTURES,
		QuizElementTypes.Q_VIDEOS,
	];

	private optionTypes: QuizElementTypes[] = [
		QuizElementTypes.O_TEXTS,
		QuizElementTypes.O_AUDIOS,
		QuizElementTypes.O_PDFS,
		QuizElementTypes.O_PICTURES,
		QuizElementTypes.O_VIDEOS,
	];

	private answerTypes: QuizElementTypes[] = [
		QuizElementTypes.A_TEXTS,
		QuizElementTypes.A_AUDIOS,
		QuizElementTypes.A_PDFS,
		QuizElementTypes.A_PICTURES,
		QuizElementTypes.A_VIDEOS,
	];

	opcionesMarcadas: any = "";
	textRespuesta: any;
	mostrarMensajeTimeAcabado: boolean;
	multipleTextoResponse: boolean;

	challengeTypes = SOCKETMESSAGES;
	item: ListQuizzesStackChallengesModel;
	private httpClient: HttpClient;
	countdownInstance: any;
	stopTime: boolean = false;
	challengeOn: boolean = false;
	modePractice = false;
	loadingResponses: boolean = false;
	uploadProgress: number;
	showGif: boolean;
	showUploadComplete: boolean = false;

  private uploadSubscription: Subscription;
  uploadComplete: boolean = false;
	constructor(
		public quizService: QuizzesService,
		public loginService: LoginService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public targetsService: TargetsService,
		public quizzesStackService: QuizzesstackService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private groupServices: GruposService,
		public utils: Utils,
		public http: HttpClient,
		handler: HttpBackend,
		private socketService: SocketService,
		private localStorage: LocalStorage,
	) {
		this.httpClient = new HttpClient(handler);
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Si el valor es true, tengo que enviar las respuestas al server
		// if (
		// 	changes.answeredBySocket &&
		// 	changes.answeredBySocket.currentValue &&
		// 	this.modeSocket
		// ){
		// 	this.discoverAnswer();
		// 	this.answeredBySocket = false;
		// }
	}

	ngOnInit() {
		this.modePractice = this.localStorage.getItem("modePractice") ? true : false;
		this.challengeOn = JSON.parse(localStorage.getItem('challengeOn'));
		//this.answered = this.loginService.esProfesor();
		this.quizType = Number(
			this.quiz.isMultiplexed === 1 ? 1 : this.quiz.quizType
		);
		this.openTime = Date.now();
		if (this.elements !== undefined) {
			this.questions = this.elements
				.filter((e) => this.questionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.options = this.elements
				.filter((e) => this.optionTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			this.answers = this.elements
				.filter((e) => this.answerTypes.includes(e.elementType))
				.sort((a, b) => a.yPosition - b.yPosition);

			//revisamos si algun elemento es del tipo 5, 6, 7 u 8
			for (let index = 0; index < this.elements.length; index++) {
				const element = this.elements[index];
				if(	element.elementType == 5 || element.elementType == 6 ||	element.elementType == 7 ||	element.elementType == 8){
					this.responseByFile = true;
					break;
				}
			}
		}

		this.showClock(this.quiz);

		this.quizService.checkQuiz.subscribe(() => {
			this.discoverAnswer();
		});

		if (
			this.selectedType === SOCKETMESSAGES.OPEN &&
			this.loginService.esProfesor()
		) {
			this.hideCheckButton = false;
		} else if (
			this.loginService.esEstudiante() &&
			this.modeSocket &&
			this.selectedType === SOCKETMESSAGES.ORDERMODLIST
		) {
			this.hideCheckButton = true;
		} else if (
			this.loginService.esEstudiante() &&
			this.modeSocket &&
			this.selectedType === SOCKETMESSAGES.ORDERMODAUTO
		) {
			this.hideCheckButton = true;
		} else if (
			this.loginService.esEstudiante() &&
			this.modeSocket &&
			(this.option < 3 || this.selectedType === SOCKETMESSAGES.OPEN)
		) {
			this.hideCheckButton = true;
		}

		this.socketService.getansweredBySocket().subscribe(anweredBySocket => {
				if (anweredBySocket == true) {
					clearInterval(this.timerId);
					this.discoverAnswer();
				}
			});

			this.uploadSubscription = this.quizzesStackService.uploadProgress$.subscribe(progress => {
				this.uploadProgress = progress;
				this.uploadComplete = progress === 100;
				if(progress === 100){
					this.showUploadComplete = true;
				}
			});

			this.quizzesStackService.showGif$.subscribe(showGif => {
				this.showGif = showGif; // Set a component property to control the GIF
			});

			this.quizzesStackService.loading$.subscribe(loading => {
				this.isLoading = loading;
			});

	}
	@ViewChild(QuizPlayBodyOptionComponent)
	optionsComponent: QuizPlayBodyOptionComponent;

	ngOnDestroy() {
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
	}

	emitAudioFile($event: File) {
		this.files.push($event);
	}

	private getAnswerFiles(): any {
		return this.files;
	}

	containResponseTypeText(): boolean {
		let existResponseTypeText: boolean = false;
		this.options.forEach((element) => {
			if (element.elementType === QuizElementTypes.O_TEXTS) {
				existResponseTypeText = true;
			}
		});

		return existResponseTypeText;
	}

	evaluateTextoAndSolution(textRespuesta: string, data: string): boolean {
		if (textRespuesta.toLowerCase() === data.toLowerCase()) {
			return true;
		}
		return false;
	}

	async hacerConsultaChatGPT() {
		const apiEndpoint = "https://api.openai.com/v1/chat/completions";
		const apiKey = API_KEY_CHAT_GTP;

		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
		});

		const solutionElement = this.rawQuiz.elements.find(
			(e) => e.elementType === QuizElementTypes.A_TEXTS
		);
		const questionElement = this.rawQuiz.elements.find(
			(e) => e.elementType === QuizElementTypes.Q_TEXTS
		);

		if (!solutionElement || !questionElement) {
			console.error("Solution or question element not found");
			return;
		}

		let solution = solutionElement.data;
		let question = questionElement.data;

		let preDefinedPrompt: string = `Actúa como un profesor de la asignatura de ${
			this.rawQuiz.subject
		} que va a corregir una pregunta de examen a
		un estudiante, la pregunta estará en idioma ${this.rawQuiz.language}.
		${
			this.rawQuiz.quizInstructions !== null
				? "Al estudiante se le han dado las siguientes instrucciones: " +
				  this.rawQuiz.quiz.quizInstructions +
				  "."
				: ""
		}
		${
			question != null
				? "La pregunta tiene el siguiente enunciado: " + question + "."
				: ""
		}
		La respuesta que ha dado el estudiante a esta pregunta es: ${
			this.textRespuesta.answer
		}.
		La solución a la pregunta es: ${solution}.
		Analiza las instrucciones, el enunciado si los hay y la solución y compara la respuesta del
		estudiante con la solución de la pregunta.
		Es importante que la respuesta y la solución estén en el mismo idioma, si no es así considéralo una respuesta erronea.
		tu respuesta debe ser a modo binario, es decir,
		contesta unica y solamente un 0 si no estaría bien la respuesta del estudiante y unica y solamente 1 en el caso de que si lo esté.
		${this.rawQuiz.promptText}`;

		const body = {
			model: "gpt-4",
			messages: [
				{
					role: "user",
					content: preDefinedPrompt.replace(/(\r\n|\n|\r|\t)/gm, " "),
				},
			],
			max_tokens: 150,
			temperature: 0.1,
		};

		try {
			const response = await this.httpClient
				.post<any>(apiEndpoint, body, { headers })
				.toPromise();
				// La respuesta de ChatGPT estará en respuesta.choices[0].text
			// Evaluamos si chatGPT ha respondido con un 0 o con un 1 usamos el includes por que la respuesta puede contener caracteres extraños que hacen que la condicion falle
			if (response.choices[0].message.content.includes("0")) {
				this.successOrNotSuccessful(false);
			} else {
				this.successOrNotSuccessful(true);
			}
			//console.log(response);
		} catch (error) {
			console.error(error);
		}
	}

	discoverAnswer() {
		if(this.loginService.esProfesor() && !this.modeSocket){
			this.answered = true;
			this.answeredChange.emit(true);
			this.stopTime = true;
			this.isResponse = false;
			return
		}

		this.loadingResponses = true;
		if (Number(this.quiz.quizType) == 4) {
			this.hacerConsultaChatGPT();
		} else {
			if (!this.answered) {
				//Madamos mensaje al server, para hacer un broadcast a través del socket
				if (this.loginService.esProfesor() && this.modeSocket)
					this.groupServices
						.sendDataToServerSocket(
							parseInt(this.quiz.id),
							this.idGroup,
							SOCKETMESSAGES.CLOSE,
							this.graphId,
							this.courseId,
							this.quiz.idMultiplexQuiz === 0 ? "simple" : "multiple"
						)
						.subscribe();

				this.answered = true;
				this.answeredChange.emit(true);
				this.stopTime = true;
				this.isResponse = false;

				if (this.timerId) {
					clearInterval(this.timerId);
				}

				if (
				(+this.quiz.quizType === QuizTypes.MULTIPLE ||
					+this.quiz.quizType === QuizTypes.SINGLE) &&
					!this.loginService.esProfesor()
				) {
					const checked = (
						this.optionsComponent.optionForm.getRawValue().options as any[]
					)
						.filter((o) => o.checked)
						.map((o) => o.id)
						.sort((a, b) => a - b);
					const correctAnswers = this.options
						.filter((o) => o.responseCheck)
						.map((o) => o.idQuizzesDataElements)
						.sort((a, b) => a - b);

					for (let index = 0; index < checked.length; index++) {
						const element = checked[index];
						if (index === 0) {
							this.opcionesMarcadas += JSON.stringify(element);
						} else {
							this.opcionesMarcadas += "," + JSON.stringify(element);
						}
					}
					const result =
						JSON.stringify(checked) === JSON.stringify(correctAnswers);
					if (!this.loginService.esProfesor()) this.evaluateResponse(result);

					// if(this.answers.length){
					// 	//Mostramos un modal con las respuestas y con los botones de acierto o fallo
					// 	const modalRef = this.modalService.open( QuizPlayBodyAnswerComponent, {
					// 		scrollable: false,
					// 		centered: true,
					// 		windowClass: MODAL_DIALOG_TYPES.W55,
					// 	})

					// 	modalRef.componentInstance.answers = this.answers;
					// 	modalRef.componentInstance.mostrarBotones = false;

					// 	modalRef.result.then((result) => {
					// 			if (result === true) {
					// 					this.evaluationPositive()
					// 			} else if(result === false)  {
					// 					this.evaluationNegative()
					// 			}
					// 	}, (reason) => {});
					// }
				} else if ((+this.quiz.quizType === QuizTypes.TEXT) && !this.loginService.esProfesor()) {
					this.loadingResponses = false;
					let mostrarBotones = true;
					if (
						this.containResponseTypeText() &&
						(this.textRespuesta !== undefined || this.files.length > 0)
					) {
						mostrarBotones = false;
						if (this.textRespuesta !== undefined) {
							let data: string = "";
							this.answers.forEach((respuesta) => {
								if (respuesta.elementType === QuizElementTypes.A_TEXTS) {
									data = respuesta.data;
								}
							});
							this.successOrNotSuccessful(
								this.evaluateTextoAndSolution(this.textRespuesta.answer, data)
							);
						} else {
							this.successOrNotSuccessful(false);
							this.toaster.success(
								this.translateService.instant("QUIZZES.NOTREGISTEREDQUESTION")
							);
						}
					}

					// if(this.answers.length){

					// 	//Mostramos un modal con las respuestas y con los botones de acierto o fallo
					// 	const modalRef = this.modalService.open( QuizPlayBodyAnswerComponent, {
					// 		scrollable: false,
					// 		centered: true,
					// 		windowClass: MODAL_DIALOG_TYPES.W55,
					// 	})

					// 	modalRef.componentInstance.answers = this.answers;
					// 	modalRef.componentInstance.mostrarBotones = this.loginService.esProfesor() ? false : mostrarBotones;

					// 	modalRef.result.then((result) => {

					// 			if (result === true) {
					// 					this.evaluationPositive()
					// 			} else if(result === false)  {
					// 					this.evaluationNegative()
					// 			}
					// 	}, (reason) => {});
					// }
				}

				if (
					(+this.quiz.quizType === QuizTypes.MULTIPLE ||
						+this.quiz.quizType === QuizTypes.SINGLE) &&
					this.loginService.esProfesor()
				) {
					//Tenemos que mostrar las opciones que han elegido los usuarios
					this.quizService
						.getResponsesFromStudents(this.idGroup, parseInt(this.quiz.id))
						.subscribe((result) => {
							this.loadingResponses = false;
							result.data.forEach((response: any) => { // Explicitly define the type of 'response'
								this.options.forEach((option) => {
									if (option.idQuizzesDataElements === response.option_id)
										option.responsesArray = response.option_usersList;
								});
							});
							this.options = [];

						});
				}
				this.socketService.setansweredBySocket(false);
			}
		}
	}

	evaluationNegative() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALNOK"));

		if (!this.loginService.esProfesor()) this.evaluateResponse(false);
	}

	evaluationPositive() {
		this.answeredChange.emit(true);
		this.isResponse = false;
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.toaster.success(this.translateService.instant("QUIZZES.MANUALOK"));

		if (!this.loginService.esProfesor()) this.evaluateResponse(true);
	}

	evaluateResponse(result: boolean) {
		this.result.emit(result);
		this.quizTypes.TEXT === +this.quiz.quizType &&
		!this.modeAuto &&
		!this.loginService.esEstudiante()
			? this.closeModal()
			: "";
		this.disabledButton = true;
		const files = this.getAnswerFiles();
		this.quiz.answersText = this.getTextRespuestaEscrita();

		this.quiz.selectedOptions = this.opcionesMarcadas;

		this.quizzesStackService
			.createQuizzesStack(
				this.quiz,
				this.loginService.getUser(),
				this.courseId,
				this.graphId,
				this.openTime,
				result,
				files,
				this.filesUploads,
				this.modeSocket,
				this.idGroup
			)
			.subscribe((res) => {
				if (this.quiz.compuCorrect) {
					this.toaster.success(
						this.translateService.instant("QUIZZES.ANSWERSAVED")
					);
				}
				this.colorChange.emit(res.data);
			});
	}
	getTextRespuestaEscrita() {
		return this.textRespuesta;
	}
	nextQuiz(quiz) {
		//Verificar si es el ultimo quiz
		//Invocar al array de nodos ordenado
		//Verificar si el nodo es el ultimo
		//Si no es ultimo sigues al siguiente
		//Si es el ultimo cierra el modal.

		if (
			this.automatic &&
			!this.isLastNode(this.node) &&
			index == this.arrayQuiz.length - 1
		) {
			this.nextNode(this.nextNodo);
		} else {
			this.userQuiz.idQuizOriginal = quiz.idQuiz;
			var index = this.arrayQuiz.findIndex(function (el) {
				return el.idQuiz == quiz.idQuiz;
			});

			if (index != -1 && index < this.arrayQuiz.length - 1) {
				var nexQuiz = this.arrayQuiz[index + 1];
			} else {
				this.closeModal();
			}
		}
	}

	showQuiz(quiz: any) {
		//this.cargando=false;
		this.answered = false;
		this.answeredChange.emit(false);
		//this.isDisabled=(this.isDisabled==true)?false:true;
		this.quiz = quiz;
		this.template = undefined;

		this.elementsQP = [...quiz.template]
			.filter((e) => e.elementsType.idElementType <= 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
				responseCheck: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].responseCheck
					: undefined,
			}));

		this.elementsCorrect = [...quiz.template]
			.filter((e) => e.elementsType.idElementType > 9)
			.map((e) => ({
				idTemplateElement: e.idTemplateElement,
				idTemplate: e.idTemplate,
				elementsType: e.elementsType,
				xPosition: e.xPosition,
				yPosition: e.yPosition,
				xSize: e.xSize,
				ySize: e.ySize,
				style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
				icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
				data: e.templateElementQuizz[0]
					? e.templateElementQuizz[0].data
					: undefined,
			}));

		this.template = {
			idTemplate: this.elementsQP[0]
				? this.elementsQP[0].idTemplate
				: undefined,
			idQuiz: quiz.template.idQuiz,
			templateTittle: quiz.quizTittle,
			quizInstructions: quiz.quizInstructions,
			writable: quiz.writable,
			compuCorrect: quiz.compuCorrect,
			multiplexed: quiz.multiplexed,
			elementsQP: this.elementsQP,
			elementsCorrect: this.elementsCorrect,
		};

		this.showClock(this.quiz);

		if (!this.template) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
		}
	}

	private showClock(quiz: QuizModel) {
		if (quiz && quiz.duration) {
			if (this.timerId) {
				clearInterval(this.timerId);
			}
			this.iniBlock = false;
			let date = new Date();
			date.setSeconds(date.getSeconds() + quiz.duration);
			this.timeSubject.next({ time: this.time, max: this.countSeconds });
			// this.timerId = countdown(
			// 	date,
			// 	(timeStamp) => {
			// 		this.time = timeStamp;
			// 		this.timeSubject.next({ time: this.time, max: this.countSeconds });

			// 		if (!this.iniBlock) {
			// 			this.iniBlock = true;
			// 			this.countSeconds = this.time.seconds + this.time.minutes * 60;
			// 		}

			// 		if (this.time.minutes === 0 && this.time.seconds === 0) {
			// 			clearInterval(this.timerId);
			// 			this.mostrarMensajeTimeAcabado = true;
			// 			this.discoverAnswer();
			// 			if (this.selectedType !== SOCKETMESSAGES.OPEN)
			// 				this.onTimeEnd.emit(true);
			// 		}
			// 	},
			// 	countdown.HOURS | countdown.MINUTES | countdown.SECONDS
			// );
		}
	}

	onTimeEnded(event){
		clearInterval(this.timerId);
		this.mostrarMensajeTimeAcabado = true;
		this.discoverAnswer();
		if (this.selectedType !== SOCKETMESSAGES.OPEN)
			this.onTimeEnd.emit(true);
	}

	nextNode(nextNode) {
		const modalRef = this.modalService.open(ModalReproducirNodoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W65,
		});
		modalRef.componentInstance.node = nextNode;
		modalRef.componentInstance.currentGraph = this.currentGraph;
		modalRef.componentInstance.type = "node";
		modalRef.result.then(
			(result) => {
			},
			(reason) => {}
		);
	}

	isLastNode(node) {
		var nodes = this.currentGraph.nodes.filter(
			(node) => node.nodeType == "Node"
		);
		var array = nodes.sort(function (a, b) {
			return a["idOriginal"] - b["idOriginal"];
		});

		var n = array[array.length - 1];
		if (n.idOriginal == node.idOriginal) {
			return true;
		} else {
			var pos = 0;
			for (n of array) {
				pos = pos + 1;
				if (n.idOriginal == node.idOriginal) {
					this.nextNodo = array[pos];
					return false;
				}
			}
		}
	}

	calculateAchieveKnowledge(quiz: any) {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}

	onQuizPlayElementChecked(value: boolean, idTemplateElement: number) {
		if (value) {
			this.optionCheckedId = [idTemplateElement, ...this.optionCheckedId];
		} else {
			this.optionCheckedId = [
				...this.optionCheckedId.filter((e) => e !== idTemplateElement),
			];
		}
	}

	onQuizPlayElementFileUploaded(element, eventFile) {
		element.data = URL.createObjectURL(eventFile.target.files[0]);
		element.file = eventFile.target.files[0];
	}

	onQuizPlayElementSetDescription(element: QuizTemplateElement) {
		const modalRef = this.modalService.open(
			QuizEditTemplateTextEditorComponent,
			{
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W95,
				backdrop: "static",
			}
		);

		modalRef.componentInstance.text = element.data;

		modalRef.result.then(
			(result) => {
				element.data = result;
				element.file = result;
			},
			(reason) => {}
		);
	}

	resumeInterval() {
		let date = new Date();
		date.setSeconds(
			date.getSeconds() + this.time.seconds + this.time.minutes * 60
		);

		this.timerId = countdown(
			date,
			(timeStamp) => {
				this.time = timeStamp;

				this.timeSubject.next({ time: this.time, max: this.countSeconds });

				if (!this.iniBlock) {
					this.iniBlock = true;
					this.countSeconds = this.time.seconds + this.time.minutes * 60;
				}

				if (this.time.minutes === 0 && this.time.seconds === 0) {
					clearInterval(this.timerId);
					this.answered = true;
					this.answeredChange.emit(true);
					this.discoverAnswer();
				}
			},
			countdown.HOURS | countdown.MINUTES | countdown.SECONDS
		);
	}

	saveTxt(text) {
		this.filesUploads.answersText = text;
	}

	saveOptionMultiple(option: any) {
		option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
		const element: QuizElement = this.elementToQuizElement(option.element);
		this.optionManualComponent.isLoading = true;
		this.quizzesStackService
			.anserwQuizElement(this.quiz.idOriginal, element, option.file)
			.subscribe(
				(res) => {
					const extResFile = this.getFileExtension(res.data);
					const i = this.imagenExt.filter((data) => data == extResFile);
					let url = `${environment.quizzesContent}`;

					if (i.length > 0) {
						if (this.filesUploads.answersImagen === "") {
							this.filesUploads.answersImagen = res.data;
							this.optionManualComponent.arrayFileView[option.index].url =
								this.optionManualComponent.arrayFileView[option.index].url +
								res.data;
							this.optionManualComponent.arrayFileView[option.index].view =
								true;
						} else {
							if (this.optionManualComponent.arrayFileView[option.index].view) {
								this.replaceValue(
									this.filesUploads.answersImagen,
									res.data,
									"picture/",
									option.index,
									url
								);
							} else {
								this.filesUploads.answersImagen = this.addValueToArray(
									this.filesUploads.answersImagen,
									res.data,
									option.index,
									url
								);
							}
						}
					} else {
						const i = this.docExt.filter((data) => data == extResFile);
						if (i.length > 0) {
							if (this.filesUploads.answersDocs === "") {
								this.filesUploads.answersDocs = res.data;
								this.optionManualComponent.arrayFileView[
									this.quiz.idMultiplexQuiz === 1
										? option.index + 1
										: option.index
								].url =
									this.optionManualComponent.arrayFileView[option.index].url +
									res.data;
								this.optionManualComponent.arrayFileView[
									this.quiz.idMultiplexQuiz === 1
										? option.index + 1
										: option.index
								].view = true;
							} else {
								if (
									this.optionManualComponent.arrayFileView[option.index].view
								) {
									this.replaceValue(
										this.filesUploads.answersDocs,
										res.data,
										"pdf/",
										option.index,
										url
									);
								} else {
									this.filesUploads.answersDocs = this.addValueToArray(
										this.filesUploads.answersDocs,
										res.data,
										option.index,
										url
									);
								}
							}
						} else {
							const i = this.audioExt.filter((data) => data == extResFile);
							if (i.length > 0) {
								if (this.filesUploads.answersAudio === "") {
									this.filesUploads.answersAudio = res.data;
									this.optionManualComponent.arrayFileView[option.index].url =
										this.optionManualComponent.arrayFileView[option.index].url +
										res.data;
									this.optionManualComponent.arrayFileView[option.index].view =
										true;
								} else {
									if (
										this.optionManualComponent.arrayFileView[option.index].view
									) {
										this.replaceValue(
											this.filesUploads.answersAudio,
											res.data,
											"audio/",
											option.index,
											url
										);
									} else {
										this.filesUploads.answersAudio = this.addValueToArray(
											this.filesUploads.answersAudio,
											res.data,
											option.index,
											url
										);
									}
								}
							} else {
								const i = this.videoExt.filter((data) => data == extResFile);
								if (i.length > 0) {
									if (this.filesUploads.answersVideo === "") {
										this.filesUploads.answersVideo = res.data;
										this.optionManualComponent.arrayFileView[option.index].url =
											this.optionManualComponent.arrayFileView[option.index]
												.url + res.data;
										this.optionManualComponent.arrayFileView[
											option.index
										].view = true;
									} else {
										if (
											this.optionManualComponent.arrayFileView[option.index]
												.view
										) {
											this.replaceValue(
												this.filesUploads.answersVideo,
												res.data,
												"video/",
												option.index,
												url
											);
										} else {
											this.filesUploads.answersVideo = this.addValueToArray(
												this.filesUploads.answersVideo,
												res.data,
												option.index,
												url
											);
										}
									}
								}
							}
						}
					}
					this.optionManualComponent.isLoading = false;
				},
				(err) => console.error(err)
			);
	}

	public onSaveOption(option: any) {
		this.utils.showGifResponse = false;
		// Verificar si el index ya existe en files_aux
		const indexExists = this.files_aux.some(item => item.index === option.index);
		if (option.action === 'delete') {
			// Eliminar la opción si action es 'delete'
			this.files_aux = this.files_aux.filter(item => item.index !== option.index);
			this.files = this.files.filter(file => file !== option.file);
		} else if (!indexExists) {
			// Agregar solo si el index no existe en files_aux
			const element: QuizElement = this.elementToQuizElement(option.element);
			this.files.push(option.file);
			this.files_aux.push(option);
			this.optionManualComponent.arrayFileView[option.index].url = option.element.data;
			this.optionManualComponent.arrayFileView[option.index].view = true;
		}
	}


	public onSaveOptionWebCam(option: any) {
		this.utils.showGifResponse = false;
		// Verificar si el index ya existe en files_aux
		const indexExists = this.files_aux.some(item => item.index === option.index);
		if (option.action === 'delete') {
			// Eliminar la opción si action es 'delete'
			this.files_aux = this.files_aux.filter(item => item.index !== option.index);
			this.files = this.files.filter(file => file !== option.file);
		} else if (!indexExists) {
			// Agregar solo si el index no existe en files_aux
			option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
			this.files.push(option.res.file);
			this.files_aux.push(option);
			this.optionManualComponent.arrayFileView[option.index].url =
				option.res.webcamImage._imageAsDataUrl;
			this.optionManualComponent.arrayFileView[option.index].view = true;
		}
	}

	public saveOptionVideoCamera(option: any) {
		this.utils.showGifResponse = false;
		// Verificar si el index ya existe en files_aux
		const indexExists = this.files_aux.some(item => item.index === option.index);
		if (option.action === 'delete') {
			// Eliminar la opción si action es 'delete'
			this.files_aux = this.files_aux.filter(item => item.index !== option.index);
			this.files = this.files.filter(file => file !== option.file);
		} else if (!indexExists) {
			// Agregar solo si el index no existe en files_aux
			option.index =
			this.quiz.idMultiplexQuiz === 1 ? option.index : option.index;
			this.files.push(option.res.files);
			this.files_aux.push(option);
			this.optionManualComponent.arrayFileView[option.index].url =
				option.res.videoUrl[0].changingThisBreaksApplicationSecurity;
			this.optionManualComponent.arrayFileView[option.index].view = true;
		}
	}

	addValueToArray(
		answersImagen: string,
		data: any,
		index: any,
		url: string
	): string {
		answersImagen = answersImagen + "," + data;
		this.optionManualComponent.arrayFileView[index].url =
			this.optionManualComponent.arrayFileView[index].url + data;
		this.optionManualComponent.arrayFileView[index].view = true;
		return answersImagen;
	}
	replaceValue(
		answersArrays: string,
		data: any,
		type: string,
		index: number,
		url: string
	) {
		let arrayDeCadenas = answersArrays.split(",");
		arrayDeCadenas[arrayDeCadenas.length - 1] = data;
		this.optionManualComponent.arrayFileView[index].url = "";
		this.optionManualComponent.arrayFileView[index].url = url + type + data;
		if (type === "picture/") {
			this.filesUploads.answersImagen = "";
			this.filesUploads.answersImagen = arrayDeCadenas.toString();
		} else if (type === "video/") {
			this.filesUploads.answersVideo = "";
			this.filesUploads.answersVideo = arrayDeCadenas.toString();
		} else if (type === "audio/") {
			this.filesUploads.answersAudio = "";
			this.filesUploads.answersAudio = arrayDeCadenas.toString();
		} else if (type === "pdf/") {
			this.filesUploads.answersDocs = "";
			this.filesUploads.answersDocs = arrayDeCadenas.toString();
		}
	}

	//Obtener extencion del archivo
	getFileExtension(filename) {
		return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
	}

	private elementToQuizElement(element: any): QuizElement {
		const quizElement: QuizElement = {
			idQuizzesDataElements: element.id,
			data: element.data,
			idQuiz: this.quiz.idOriginal,
			elementType: element.type,
			responseCheck: +element.isCorrect,
			xPosition: 0,
			xSize: 0,
			yPosition: element.order,
			ySize: 0,
		};

		return quizElement;
	}

	successOrNotSuccessful(succes: boolean) {
		this.answeredChange.emit(succes);
		this.isResponse = false;
		if(this.timerId) {
			clearInterval(this.timerId);
		}
		if(succes){
			this.toaster.success(this.translateService.instant("QUIZZES.MANUALOK"));
		} else if(!succes && !this.responseByFile){
			this.toaster.success(this.translateService.instant("QUIZZES.MANUALNOK"));
		} else if(!succes && this.responseByFile){
			this.toaster.success(this.translateService.instant("QUIZZES.ANSWERSAVED"));
		}
		if(!this.loginService.esProfesor()){
			this.evaluateResponse(succes);
		}
	}

	public get quizTypes() {
		return QuizTypes;
	}

	emitText(event: string) {
		this.textRespuesta = event;
	}

	enviarAudioRecorder(eventAudio: any) {
		if(eventAudio.action === 'delete'){
			// Eliminar la opción si action es 'delete'
			this.files_aux = this.files_aux.filter(item => item.index !== eventAudio.index);
			this.files = this.files.filter(file => file !== eventAudio.audio);
		} else {
			this.utils.showGifResponse = false;
			this.files.push(eventAudio.audio);
			this.files_aux.push(eventAudio);
		}
	}

	onSaveOptionChallenge(value: any) {
		this.saveResponse();
	}

	containsObject(obj, list) {
		for (let i = 0; i < list.length; i++) {
			if (list[i] === obj) {
				return true;
			}
		}
		return false;
	}

	saveResponse(): void {
		// Guardamos la respuesta del quiz
		let optionsLive = this.optionsComponent.optionForm.getRawValue().options;
		let valueChanged = false;
		let checked = [];
		let resultChanged = false;

		const valueChangesSubscription =
			this.optionsComponent.optionForm.valueChanges.subscribe((value) => {
				optionsLive = value;
				valueChanged = true;
				resultChanged =
					JSON.stringify(
						(valueChanged ? optionsLive["options"] : (optionsLive as any[]))
							.filter((o) => o.checked)
							.map((o) => o.id)
							.sort((a, b) => a - b)
					) === JSON.stringify(correctAnswers);
			});

		const correctAnswers = this.options
			.filter((o) => o.responseCheck)
			.map((o) => o.idQuizzesDataElements)
			.sort((a, b) => a - b);

		checked = (valueChanged ? optionsLive["options"] : (optionsLive as any[]))
			.filter((o) => o.checked)
			.map((o) => o.id)
			.sort((a, b) => a - b);

		const result = JSON.stringify(checked) === JSON.stringify(correctAnswers);

		this.item = {
			idQuiz: +this.quiz.id,
			reponses: checked,
			quiz: this.quiz,
			openTime: this.openTime,
			idCourse: this.courseId,
			idTarget: this.graphId,
			answered: Date.now(),
			result: result,
			text: "",
		};

		const existingItemIndex =
			this.quizzesStackService.responseListQuizzesStackChallenges.findIndex(
				(e) =>
					e.idQuiz === this.item.idQuiz && e.idTarget === this.item.idTarget
			);

		if (existingItemIndex !== -1) {
			this.quizzesStackService.responseListQuizzesStackChallenges[
				existingItemIndex
			] = this.item;
		} else {
			this.quizzesStackService.responseListQuizzesStackChallenges.push(
				this.item
			);
		}

		this.onSaveResponse.emit(true);

		// Unsubscribe to prevent memory leaks
		valueChangesSubscription.unsubscribe();
	}

	get isButtonDisabled(): boolean {
		let bd = false;
		if(!this.textRespuesta?.answer){
			if(this.files_aux.length === 0){
				bd = true;
			} else{
				bd = false;
			}
		}
		return bd;
	}

}
