import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AcceptDialogComponent } from 'src/app/shared/components/accept-dialog/accept-dialog.component';
import { ConditionsDialogComponent } from 'src/app/shared/components/conditions-dialog/conditions-dialog.component';
import { ConfirmModalTutorialComponent } from 'src/app/shared/components/confirm-modal-tutorial/confirm-modal-tutorial.component';
import { PrivacyDialogComponent } from 'src/app/shared/components/privacy-dialog/privacy-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class TutorialModalService {

    constructor(private dialog: MatDialog) { }

    openConfirmDialog(isValid: boolean, title: string, message: string, panelClasses: string[] = [], picture: string = ''): MatDialogRef<ConfirmModalTutorialComponent> {
        const dialogRef = this.dialog.open(ConfirmModalTutorialComponent, {
            data: {
                title,
                message,
                picture
            },
            panelClass: ['salware-dialog', ...panelClasses, isValid ? 'ok' : 'nok']
        });

        return dialogRef;
    }

    openAcceptDialog(isValid: boolean, title: string, message: string, panelClasses: string[] = []): MatDialogRef<AcceptDialogComponent> {
        const dialogRef = this.dialog.open(AcceptDialogComponent, {
            data: {
                title,
                message
            },
            panelClass: ['salware-dialog', ...panelClasses, isValid ? 'ok' : 'nok']
        });

        return dialogRef;
    }

    openPrivacyDialog() {
        const dialogRef = this.dialog.open(PrivacyDialogComponent, { panelClass: ['salware-dialog', 'ok'] });
        return dialogRef;
    }

    openConditionsDialog(): MatDialogRef<ConditionsDialogComponent> {
        const dialogRef = this.dialog.open(ConditionsDialogComponent, { panelClass: ['salware-dialog', 'ok'] });
        return dialogRef;
    }


    openManualAutoDialog
    (isValid: boolean, title: string, message: string, panelClasses: string[] = [], picture: string = '', okButtonText?:string, koButtonText?:string, okButtonImage?:string, koButtonImage?:string): MatDialogRef<ConfirmModalTutorialComponent> {
        const dialogRef = this.dialog.open(ConfirmModalTutorialComponent, {
            data: {
                title,
                message,
                picture,
                okButtonText,
                koButtonText,
                okButtonImage,
                koButtonImage
            },
            panelClass: ['salware-dialog', ...panelClasses, isValid ? 'ok' : 'nok']
        });

        return dialogRef;
    }

}
