import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ACTIONS } from 'src/app/core/utils/actions';
import { PadsUtils } from 'src/app/core/utils/pads.utils';



@Component({
    selector: 'app-quiz-pad-controller',
    templateUrl: './quiz-pad-controller.component.html',
    styleUrls: ['./quiz-pad-controller.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QuizPadControllerComponent implements OnInit {

	@HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.getCurrentKeyboardKey(event.key)
	}

	@Input() isShow: boolean;
	@Input() isShowLabels:boolean
	@Output() action = new EventEmitter<string>()


	constructor(private padsUtils:PadsUtils) { }

	ngOnInit() {}

	clickAction(value:string):void{
		this.action.emit(value)
		this.padsUtils.vibratePad()
	}

	private getCurrentKeyboardKey(key:string): void{
		switch(key){
				case ACTIONS.ARROWUP:
					this.clickAction('up')
					break
				case ACTIONS.ARROWDOWN:
					this.clickAction('down')
					break;
				case ACTIONS.ARROWRIGHT:
					this.clickAction('next')
					break
				case ACTIONS.ARROWLEFT:
					this.clickAction('back')
					break
		}
	}


}
