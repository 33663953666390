import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Quill from 'quill'
import { Subscription } from 'rxjs';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { ModalTextEditService } from './modal-text-edit.service';

@Component({
    selector: 'modal-text-edit',
    templateUrl: './modal-text-edit.component.html',
    styleUrls: ['./modal-text-edit.component.scss'],
    standalone: false
})
export class ModalTextEditComponent implements OnInit, OnDestroy {
    @Input() value: string = '';
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    modules: {};
    form: UntypedFormGroup;
    valueChangesSubscription: Subscription;
    textSource: string;
    idOriginal: any;
    mediaUrl: any;

    constructor(private fb: UntypedFormBuilder, public activeModal: NgbActiveModal,
        private quizzesStackService: QuizzesstackService,
        private modalTextEditServ: ModalTextEditService
        ) {
        this.modules = {
            toolbar: [
                [
                    { header: [1, 2, false] }
                ],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block'],
            ]
        };
    }

    ngOnInit() {
        this.form = this.fb.group({
            content: this.textSource
        });

        this.valueChangesSubscription = this.form.get('content').valueChanges.subscribe(value => this.saveText(value));
    }

    ngOnDestroy(): void {
        this.valueChangesSubscription.unsubscribe();
    }

    public saveText(valuehtml: string) {
        this.textSource =  valuehtml;
        this.saveTextHtml()
         this.autoSave(this.idOriginal, this.textSource, this.mediaUrl);
    }
    autoSave(idOriginal: any, textSource: string, mediaUrl: any) {
        // this.quizzesStackService.updateTextEditQuizz(idOriginal, textSource, `text${mediaUrl}`).subscribe(
        //     res => {
                this.modalTextEditServ.emitTextActive(textSource);
        //     },
        //     err => console.error(err)
        //   );
    }

    saveTextHtml(){
        const sendData = {
            result: 'OK',
            datos: this.textSource
        };
    }

    closeModal(sendData?: any){
        this.activeModal.close(sendData);
    }

    setFocus(editor) {
        editor.focus()
    }
}
