import { MastersService } from "src/app/core/services/masters";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { TargetsService } from "src/app/core/services/targets";
import { UsersService } from "src/app/core/services/users";
import { CourseListModel } from '../../cursos/modal-cursos-listado/interface/modal-cursos-listado';
import { TaskCalendarListModel } from "../models/task-calendar-list";
import { NodeService } from "src/app/core/services/node/node.service";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { debounceTime, Subject, takeUntil } from "rxjs";
import { ModalSchedulesComponent } from "../modal-schedules.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { LocalStorage } from "src/app/core/utils";
import { Utils } from "src/app/core/utils/utils";
import { CalendarEvent } from "angular-calendar";

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
    selector: "app-modal-task-calendar",
    templateUrl: "./modal-task-calendar.component.html",
    styleUrls: ["./modal-task-calendar.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class ModalTaskCalendarComponent implements OnInit {
	fromNode: boolean = false;
	tree: TaskCalendarListModel[] = [];
	idCurso: number = 0;
	idMapa: number = 0;
	idNodo: number = 0;
	idGrupo: number = 0;

	selectedLabel: any;
	selectedDate: string = new Date().toISOString().split('T')[0];
	selectedQuizzes: number[] = [];

	comentario: string = '';
	dayEvents: CalendarEvent<any>[];

	constructor(
		public loginService: LoginService,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		public activeModal: NgbActiveModal,
		public userService: UsersService,
		public router: Router,
		private masterService: MastersService,
		private nodeService: NodeService,
		private toaster: ToasterService,
		private groupService: GruposService,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		private utils: Utils,
	) {}

	ngOnInit() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));	
		this.idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		//Creamos el log
		const bodyLog1 = {
			action: "tareas calendario",
			actionData: "Ha entrado en la pantalla para asignar o visualizar las tareas del calendario",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.masterService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
		//seleccionamos el nodo
		if (this.fromNode) {
			this.selectedLabel = this.tree[0];
			this.selectedDate = localStorage.getItem("taskCalendarDate");
		} else {
			this.groupService.getTaskCalendar(this.idGrupo, this.selectedDate).subscribe(res => {
				this.tree = res.data;
			})
		}
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

	closeModal(sendData?: any) {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(sendData);
	}

	clickElement(element: TaskCalendarListModel) {
		this.selectedLabel = element;
		if(element.icon === "quiz"){
			element.checked = !element.checked;
		}
		this.updateSelectedQuizzes();
	}
	onCheckboxClick(event: Event, quiz: TaskCalendarListModel) {
		event.stopPropagation(); // Evita que el evento se propague al div y haga doble cambio
		setTimeout(() => {
			quiz.checked = !quiz.checked;
			this.clickElement(quiz);
		});
	}

	clickElement2(element: TaskCalendarListModel) {
		this.selectedLabel = element;
	}

	updateSelectedQuizzes() {
		this.selectedQuizzes = this.tree.flatMap(node => 
			node.children.filter(quiz => quiz.checked).map(quiz => quiz.id)
		);
	}

	sendSelectedQuizzes() {
		//creamos el listado de tareas, el primero siempre es el nodo
		let body = [
			{
				idCalendarTasks: 0,
				idGroup: this.idGrupo,
				taskDate: this.selectedDate,
				idCourse: this.idCurso,
				idTarget: this.idMapa,
				idNode: this.idNodo,
				idQuiz: 0,
			},
		];
		for (let index = 0; index < this.selectedQuizzes.length; index++) {
			const idQuiz = this.selectedQuizzes[index];
			const b = {
				idCalendarTasks: 0,
				idGroup: this.idGrupo,
				taskDate: this.selectedDate,
				idCourse: this.idCurso,
				idTarget: this.idMapa,
				idNode: this.idNodo,
				idQuiz: idQuiz,
			};
			body.push(b);
		}
		this.nodeService.createUpdateCalendarTask(body).subscribe(res => {
			if(res.data){
				this.toaster.success(this.translateService.instant("TASKCALENDAR.TASKASIGNED"));
				this.closeModal();
			} else {
				this.toaster.error(this.translateService.instant("TASKCALENDAR.TASKNOTASIGNED"));
			}
		})
	}

	deleteTask(idTaskCalendar: number, isNode: boolean){
		this.groupService.deleteTaskCalendar(idTaskCalendar, isNode).subscribe(res => {
			if(res.data > 0){
				this.toaster.success(this.translateService.instant("TASKCALENDAR.TASKDELETED"));
				this.groupService.getTaskCalendar(this.idGrupo, this.selectedDate).subscribe(res => {
					this.tree = res.data;
				})
			} else{
				this.toaster.success(this.translateService.instant("TASKCALENDAR.TASKDELETEDFAIL"));
			}
		})
	}

	newTask() {
		this.localStorage.setItem("taskCalendarDate", this.selectedDate);
		this.masterService.verListadoCursos(
			null,
			Profiles.Teacher,
			JSON.parse(localStorage.getItem("selectedGroupInfo")),
			"fromTaskCalendar"
		);
	}

	onDateChange(newDate: string) {
		this.nodeService.getQuizzesForTaskCalendar(this.idNodo, this.idGrupo, newDate)
			.subscribe(res => {
				//actualizamos el arbol
				this.tree = res.data;
				this.selectedLabel = res.data[0];
			})
	}

	openGrafo(idCourse: number, idTarget: number){
		this.router
		.navigate([`/course/${idCourse}/graph/${idTarget}`], {
			state: { dontLoadMenu: true },
		})
		.then(() => {
			this.utils.loadMenu = false;
			// this.sigmaCanvasService.setTriggerInitSigma(true);
		});
		this.closeModal();
		this.modalService.dismissAll();
	}
}
