import { Component, OnInit, Input } from '@angular/core';
import { QuizzesService } from 'src/app/core/services/quizzes/quizzes.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-body-quizzes',
    templateUrl: './body-quizzes.component.html',
    styleUrls: ['./body-quizzes.component.scss'],
    standalone: false
})
export class BodyQuizzesComponent implements OnInit {
    @Input() mensajeBody: string;
    @Input() quizEnviado: any;
    visibility: boolean;
    intervalId;
    time: Time;
    iniBlock: boolean;
    countSeconds: number;
    timerId: number = null;
    @Input() date: Date | string;
    template: any = {};
    elements = [];
    elementsTemplatesQuizzes: any[];
    source: any;
    quiz: any;
    newQuiz: any;
    progress: boolean;
    imagen: any;
    titulo: string;
    elementCurrent: any;
    description: string;
    fontStyles: string[] = ["normal", "italic", "oblique", "inherit", "initial", "unset"];
    fontStyle: string;
    fontSizes: string[] = ["8", "9", "10", "11", "12", "14", "16", "18", "20"];
    fontSize: string;
    private templateTypes = [
        'VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];
    private elementIcons = [
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    constructor(private quizService: QuizzesService,
        public http: HttpClient) {
        this.quizService.currentQuiz.subscribe((q) => {
            this.quiz = q
        });


    }


    public form: UntypedFormGroup = new UntypedFormGroup({
        templateTittle: new UntypedFormControl(''),
        quizInstructions: new UntypedFormControl('')
    });

    async ngOnInit() {
        this.form.valueChanges.subscribe(() => {
            this.template.templateTittle = this.form.value.templateTittle;
            this.template.quizInstructions = this.form.value.quizInstructions;

        });
        this.description = "";
        this.progress = false;
        // await this.quizService.currentQuiz.subscribe( (q) => this.quiz = q);
        this.visibility = true;
    }

    elementsTemplatesQuizzesToElements() {
        this.elements = [];
        for (var i = 0; i < this.elementsTemplatesQuizzes.length; i++) {
            this.elements[i] = this.elementsTemplatesQuizzes[i].templateElement[0];
        }
        this.calculatePositions2(this.elements);
        this.assignIconTypeElement(this.elements);
        return this.elements;
    }

    descubrirRespuesta() {
    }

    autoEvaluacionNegativa() {
    }

    autoEvaluacionPositiva() {
    }

    nextQuiz() {
    }


    ngOnDestroy() {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    calculatePositions(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.newyposition;
                element.xposition = element.newxposition;
            });
        }
    }

    calculatePositions2(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.yposition;
                element.xposition = element.xposition;
            });
        }
    };

    cargarRecurso(event, element: any) {
        this.progress = true;
        var file = event.target.files[0];
        if (file) {
            var reader = new FileReader();
            let arch: any;
            reader.readAsDataURL(file);
            reader.onload = async (event: any) => {
                this.source = event.target.result;
                var idDiv = '#' + element.idTemplateElement;
                //document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImage(file) + ')';
                //element.data = file.name;

                //Cargar Imagen
                const base64 = this.source.split(';base64,')[1];
                const imageName = 'archivo' + Date.now() + '.png';
                const imageBlob = this.dataURItoBlob(base64);
                const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

                let urlApi = 'https://localhost:44300/api/files';
                let formData = new FormData();
                formData.append("file", imageFile, imageFile.name);
            }
        }
        this.progress = false;
    }

    getImage(file: any) {
        if (file.type == "video/mp4") {


        }
        if (file.type == "image/jpeg" || file.type == "image/png") {
            //return file;
            //return '../../../../../assets/images/'+ file.name;
            return '../../../../../assets/images/Abstract.png';
            //return '/home/default/Files/Abstract.png';
            //return '~/Files/Abstract.png';
        }
        if (file.type == "audio/mpeg") {
            return '../../../../../assets/images/headphones.jpg';
        }
        if (file.type == "pdf") {
            return '../../../../../assets/document/' + file.name;
        }
        if (file.type == "txt") {
            return '../../../../../assets/document/' + file.name;
        }
    }

    getImages(element: any) {
        if (element.data) {
            if (element.icon == "videocam") {
                return '../../../assets/images/video.jpg';
            }
            if (element.icon == "image") {
                //return '../../../assets/images/' + element.data;
                /*let url = `${environment.imagesUrl}`;
                var url_l= url + element.data;*/
                var url_l = 'assets/images/' + element.data;
                //var url_l = '../../../assets/images/Abstract.png';
                return url_l;
            }
            if (element.icon == "volume_up") {
                return '../../../../../assets/images/headphones.jpg';
            }
            if (element.icon == "picture_as_pdf") {
                return '../../../assets/document/' + element.data;
            }
            if (element.icon == "subject") {
                return '../../../assets/document/' + element.data;
            }
        }
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    changeValue(event: any) {
        this.titulo = event.target.value;
    }

    setElementType(quiz: any, element: any) {
        switch (element.elementType) {
            case (0): {
                quiz.stringQuestionsVideo = element.idTemplateElement;
                break;
            }
            case (1): {
                quiz.stringQuestionsImage = element.idTemplateElement;
                break;
            }
            case (2): {
                quiz.stringQuestionsAudio = element.idTemplateElement;
                break;
            }
            case (3): {
                quiz.stringQuestionsDoc = element.idTemplateElement;
                break;
            }
            case (4): {
                quiz.stringQuestionsText = element.idTemplateElement;
                break;
            }
            case (5): {
                quiz.stringOptionsVideo = element.idTemplateElement;
                break;
            }
            case (6): {
                quiz.stringOptionsImage = element.idTemplateElement;
                break;
            }
            case (7): {
                quiz.stringOptionsAudio = element.idTemplateElement;
                break;
            }
            case (8): {
                quiz.stringOptionsDoc = element.idTemplateElement;
                break;
            }
            case (9): {
                quiz.stringOptionsText = element.idTemplateElement;
                break;
            }
            case (10): {
                quiz.stringAnswersVideo = element.idTemplateElement;
                break;
            }
            case (11): {
                quiz.stringAnswersImage = element.idTemplateElement;
                break;
            }
            case (12): {
                quiz.stringAnswersAudio = element.idTemplateElement;
                break;
            }
            case (13): {
                quiz.stringAnswersDoc = element.idTemplateElement;
                break;
            }
            case (14): {
                quiz.stringAnswersText = element.idTemplateElement;
                break;
            }
            default: {
                break;
            };
        }
    }

    getDescription(element: any) {}

    closeModalText() {
        this.description = "";
    }

    showFondStyle(event: Event) {
        this.fontStyle = (event.target as HTMLSelectElement).value;
    }

    showFontSize(event: Event) {
        this.fontSize = (event.target as HTMLSelectElement).value + 'px';
    }


    onChange(event, element: any) {
        var resposeCheck = event.target.value;
        if (resposeCheck == "true") {
            if (event.target.classList.contains('result-wrong')) {
                event.target.classList.remove('result-wrong');
            }

            event.target.classList.add('result-correct')
        } else {
            if (event.target.classList.contains('result-correct')) {
                event.target.classList.remove('result-correct');
            }
            event.target.classList.add('result-wrong')
        }
        this.elements.forEach((x) => {
            if (x.idTemplateElement == element.idTemplateElement) x.responseCheck = resposeCheck ? 1 : 0;
        })

    }

    saveQuizz() {}

    saveTemplatesQuizzes() {}

    assignIconTypeElement(elements: any) {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].style = this.templateTypes[elements[i].elementType];
            this.elements[i].icon = this.elementIcons[elements[i].elementType];
        }
    }

}
