<div *ngIf="loading" class="d-flex justify-content-center align-items-center loading">
	<div class="loaderBar"></div>
	<span>{{'REPORTBUTTON.SENDINGREPORT' | translate}}</span>
</div>
<form class="w-100 h-100" [formGroup]="infoForm">
	<div class="d-flex justify-content-between align-items-center gap-3 pt-3">
		<div class="d-flex gap-3">
			<!-- BOTON HISTORIAL -->
			<div class="d-flex justify-content-center align-items-center" *ngIf="loginService.esEstudiante() && !challengeOn">
				<button nbButton size="small" outline status="info" (click)="openModalRecord()">
					<nb-icon icon="file-text-outline"></nb-icon>
					{{'QUIZZES.RECORD' | translate}}
				</button>
			</div>
			<!-- BOTON REPORTE -->
			<div class="d-flex justify-content-center align-items-center">
				<button nbButton size="small" outline status="warning" (click)="openModalReport()">
					<nb-icon icon="alert-triangle-outline"></nb-icon>
					{{'QUIZZES.REPORT' | translate}}
				</button>
			</div>
		</div>
		<!-- CONTADOR DE TIEMPO -->
		<div [ngClass]="{ 'HideDuration': viewQuizDuration}" class="d-flex justify-content-end"
			*ngIf="loginService.esEstudiante() || challengeOn || !modePractice">
			<div class="d-flex justify-content-end" *ngIf="showClock == true">
				<countdown #cd [config]="config" class="counter-down" (event)="handleCountDownEvent($event)">
				</countdown>
			</div>
		</div>
	</div>

	<div class="d-flex mt-3">
		<div class="cont-small col-12">
			<h2>{{infoForm.get('instructions').value}}</h2>
		</div>
	</div>
</form>
