import { LoginService } from 'src/app/core/services/login';
import { User } from './../../models/users/user.models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { RegisterModel } from '../../models/users/register.model';
import { tap } from 'rxjs/operators';

const UPDATEUSER = 'user/update-user';
const UPDATEUSERPROFILE = 'user/updateUserProfile';
const CHANGEPASS = 'user/changepassword';
const CHANGEMOBILE = 'user/changemobile';
const UNSUBSCRIBE = 'user/unsubscribeuser';
const FILTERPARENTS = 'user/getfilterparents';
const FILTERCHILDREN = 'user/getFilterChildren';
const CHILDINVITEFATHER = 'user/childrequesttofather';
const FATHERINVITECHILD = 'user/invitechildrenfromfather';
const ACCEPTINVITATION = "user/acceptchildfather";
const DELETEINVITATIONCHILDPARENT = 'user/deletechildfather';
const INVITATIONOUTSIDE = 'user/invitationoutsidefather';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
		public _listUserProfile: any[] = [];
    constructor(private http: HttpClient, private loginService:LoginService) { }

		public get appLevels(): any[] {
			return this._listUserProfile;
		}
		public set appLevels(value: any[]) {
				this._listUserProfile = value;
		}

		private profilesListSubject: Subject<any[]> = new Subject<any[]>();
		profilesList$: Observable<any[]> = this.profilesListSubject.asObservable();


    getUserByIdUser(idUser):Observable<any> {
        const url:string = 'user/getuserbyid';
        return this.http.get<any>(`${url}/${idUser}`);
    }

		getUserByIdUserV2(idUser):Observable<any> {
			const url:string = 'user/getuserbyid/v2';
			return this.http.get<any>(`${url}/${idUser}`);
	}



		getListUserProfile():Observable<any> {
			const url:string = 'user/getListUserProfile';
			return this.http.get<any>(`${url}`).pipe(
				tap((userList) => {
					this.profilesListSubject.next(userList);
				})
			);
		}

		updateUser(user: User):Observable<any> {
			return this.http.post<any>(UPDATEUSER,user)
		}

		updateUserProfile(userProfile: RegisterModel):Observable<any> {
      return this.http.post<any>(UPDATEUSERPROFILE,userProfile)
    }

    getColorByRol():string{
        try{
            let profile = this.loginService.getProfile().toLowerCase()
            return `bg-${profile}`
        }catch(err){
            return 'bg-estudiante'
        }
    }

    updateOrDeleteImageProfile(imageName:string|null, file: File | string): Observable<any>{
      let url:string = `user/setImageUser/image`
      let form:FormData = new FormData()

      if(file)
        form.append('files', file)
      form.append('nameImage', imageName);

      return this.http.post<any>(url, form, {
        reportProgress: true,
        observe: 'events'
      });
    }

		updateOrDeleteImageAgent(imageName:string|null, file: File | string, idAgent: number): Observable<any>{
      let url:string = `agent/setImageAgent/${idAgent}`
      let form:FormData = new FormData()

      if(file)
        form.append('files', file)
      form.append('nameImage', imageName);

      return this.http.post<any>(url, form, {
        reportProgress: true,
        observe: 'events'
      });
    }


    // Verifica si el usuario ha introducido la informacion suficiente para ser validado
    checkIfUserDataAreRegistered(user, profile) {

        // Para que tenga acceso a todas las funcionalidades, voy a comprobar el perfil
        // Dependiendo del perfil habra que comprobar unas cosas u otras
        switch (profile) {

            case 'estudiante':

                // La fecha de nacimiento debe estar informada
                if (user.birthdate == null || user.birthdate === undefined) {
                    return { result: false, mensaje: 'USERDATA.AVISOINFORMARDATOS', tab: 'Datos' };
                } else {
                    // Debo comprobar que es mayor de 18 años, si no es mayor de 18 años debo
                    // comprobar que ya lo ha validado uno de sus padres
                    let fecha = new Date();
                    fecha.setTime(Date.parse(user.birthdate));

                    if (new Date() > new Date(fecha.setFullYear(fecha.getFullYear() + 18))) {
                        return { result: true };
                    } else {
                        // Y ahora hay que verificar si algun padre le ha validado
                        if (user.usersProfileValidation !== undefined &&
                            user.usersProfileValidation.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
                            // Ya ha sido validado por alguno de sus padres
                            return { result: true };
                        } else {
                            // Y lo mando a los datos de usuario
                            return { result: false, mensaje: 'USERDATA.AVISODEBESSERVALIDADOPORALGUNODETUSPADRES', tab: 'Padres' };
                        }
                    }
                }
            default:
                return { result: true };
        }
    }

		//ENDPOINT PARA CAMBIAR CONTRASEÑA DE UN USUARIO DESDE DENTRO SIN VALIDAR POR MAIL
		changePassword(oldPassWord: string, newPassWord: string){
			const body = {
				oldPassWord: oldPassWord,
				newPassWord: newPassWord
		}
		return this.http.post<any>(`${CHANGEPASS}`, body)
		}

		//ENDPOINT PARA CAMBIAR NUMERO DE TELEFONO DE UN USUARIO DESDE DENTRO CON PIN DE VALIDACION
		changeMobile(mobile: string, prefix: string, pin: string){
			const body = {
				mobile: mobile,
				extension: prefix
		}
		return this.http.post<any>(`${CHANGEMOBILE}/${pin}`, body)
		}

		//ENDPOINT PARA DAR DE BAJA A UN USUARIO
		dropOut(){
			const body = {}
			return this.http.put<any>(`${UNSUBSCRIBE}`, body)
		}

		// TRAER LISTADO DE PADRES DE UN ESTUDIANTE
		getparentsListByStudent():Observable<any> {
			const url:string = 'user/parentsListByStudent';
			return this.http.get<any>(`${url}`);
		}

		// TRAER LISTADO DE HIJOS DE UN PADRE
		getChildrenListByParent():Observable<any> {
			const url:string = 'user/childrenListByParent';
			return this.http.get<any>(`${url}`);
		}

		//ENDPOINT PARA FILTRAR LOS PADRES QUE NO HAN SIDO ASIGNADOS
		getFilterParents(filter: string){
			const body = {
				filter: filter
		}
		return this.http.post<any>(`${FILTERPARENTS}`, body)
		}

		//ENDPOINT PARA FILTRAR LOS HIJOS QUE NO HAN SIDO ASIGNADOS
		getFilterChildren(filter: string){
			const body = {
				filter: filter
		}
		return this.http.post<any>(`${FILTERCHILDREN}`, body)
		}

		//ENDPOINT PARA QUE UN HIJO INVITE A UN PADRE (REGISTRADO)
		childrequesttofather(idFather: number){
			const body = {}
			return this.http.put<any>(`${CHILDINVITEFATHER}/${idFather}`, body)
		}

		//ENDPOINT PARA QUE UN PADRE INVITE A UN HIJO (REGISTRADO)
		parentRequestToFather(idChild: number){
			const body = {}
			return this.http.put<any>(`${FATHERINVITECHILD}/${idChild}`, body)
		}

		//ENDPOINT PARA ACEPTAR PETICIONES TANTO HIJOS COMO PADRES
		acceptchildfather(idUser: number, role: string){
			const body = {}
			return this.http.put<any>(`${ACCEPTINVITATION}/${idUser}/${role}`, body)
		}

		//ENDPOINT PARA CANCELAR UNA INVITACION A SER PADRE O HIJO
		deletechildfather(idUser: number, role: string): Observable<any>{
			return this.http.delete(`${DELETEINVITATIONCHILDPARENT}/${idUser}/${role}`)
		}

		//ENDPOINT PARA INVITAR A UN PADRE POR MAIL A QUE SE REGISTRE EN SALWARE
		invitationoutsidefather(mail: string, mobile: string, extension: string){
			const body = {
				mail: mail,
				mobile: mobile,
				extension: extension,
			}
			return this.http.post<any>(`${INVITATIONOUTSIDE}`, body)
		}

		// TRAER LISTADO DE PADRES QUE HAN INVITADO AL HIJO
		findParentsListNotAcceptedByChild():Observable<any> {
			const url:string = 'user/findParentsListNotAcceptedByChild';
			return this.http.get<any>(`${url}`);
		}

		// TRAER LISTADO DE HIJOS QUE HAN INVITADO AL PADRE
		findChildrenListNotAcceptedByParent():Observable<any> {
			const url:string = 'user/findChildrenListNotAcceptedByParent';
			return this.http.get<any>(`${url}`);
		}
}
