<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="modal-title">
			<h2>{{ "SETTINGS.CENTERTITLE" | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="row">
			<div class="col-12">
				<nb-toggle class="item-profile" [(ngModel)]="realData" (ngModelChange)="onToggleRealData($event)">
					{{ ('SIGMACOMPONENT.DEMODATA' |	translate) }}
				</nb-toggle>
			</div>
		</div>
		<div class="teacher-settings" [hidden]="isLoading">
			<div class="row" *ngIf="userCenter == null">
				<div class="col-12 py-4">
					<ngb-alert type="info" [dismissible]="false">{{"TEACHERSETTINGS.NOTCENTERSASSOCIATED" | translate}}</ngb-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12 py-4">
					<div class="mat-elevation-z8" *ngIf="userCenter != null">
						<table>
							<thead>
								<tr>
									<th>{{ "SETTINGS.CENTERNAME" | translate }}</th>
									<th>{{ "SETTINGS.CENTERADDRESS" | translate }}</th>
									<th>{{ "SETTINGS.CENTERPHONE" | translate }}</th>
									<th>{{ "SETTINGS.IDCENTER" | translate }}</th>
								</tr>
							</thead>
							<tbody>
								<tr >
									<td>{{ userCenter.centerName }}</td>
									<td>{{ userCenter.centerAddress }}</td>
									<td>{{ userCenter.centerPhone }}</td>
									<td>{{ userCenter.idCenter }}</td>
									<td style="text-align: center;">
										<button nbButton class="btn-themecolor" size="small" (click)="editCenter()">
											<span>{{ "TEACHERSETTINGS.BUTTONEDIT" | translate }}</span>
										</button>
									</td>
									<td style="text-align: center;">
										<button nbButton status="danger" size="small" (click)="deleteAssign(userCenter.idCenter)">
											<span>{{ "TEACHERSETTINGS.BUTTONDELETE" | translate }}</span>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-12 py-4 d-flex justify-content-center align-center" *ngIf="userCenter == null">
					<button nbButton class="btn-themecolor" size="small" (click)="openModalCenters()">
						<span>{{ "TEACHERSETTINGS.BUTTONASSIGNMENT" | translate }}</span>
					</button>
				</div>
			</div>
		</div>
	</nb-card-body>

</nb-card>
