import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login';

@Component({
    selector: 'app-modal-tutorial-grafo',
    templateUrl: './modal-tutorial-grafo.component.html',
    styleUrls: ['./modal-tutorial-grafo.component.scss'],
    standalone: false
})
export class ModalTutorialGrafoComponent implements OnInit {
  tutoId2: boolean = false;
  tutoId4: boolean = false;
  ideografo: boolean = false;
  ideas: boolean = false;
  actividades: boolean = false;
  asociaciones: boolean = false;
  cursos: boolean = false;
  padsCopilot: boolean = false;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {}

  skip(){
    if (this.ideografo) {
      this.ideografo = false;
      this.ideas = true;
      this.actividades = false;
      this.asociaciones = false;
    }
    else if( this.ideas){
      this.ideografo = false;
      this.ideas = false;
      this.actividades = true;
      this.asociaciones = false;
    }
    else if( this.actividades){
      this.ideografo = false;
      this.ideas = false;
      this.actividades = false;
      this.asociaciones = true;
    }
    else if( this.asociaciones){
      this.ideografo = true;
      this.ideas = false;
      this.actividades = false;
      this.asociaciones = false;
    } 
  }

  done = () => {
      this.activeModal.close(true);
  }

}
