import { InstagramVideo, SpotifyAudio, TiktokVideo } from './../../models/shared/external-video.model';
import { BodyCompetitionCouplesComponent } from './../../../shared/components/sigma-canvas/modal-competition-couples/body-competition-couples/body-competition-couples.component';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { Injectable } from '@angular/core';
import { catchError, map} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
// Graph Model
import { gNode } from '../../models/graph/gNode.model';
import { HttpClient, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { ModelIcono } from '../../models/courses/icono.model';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';
import { YoutubeVideo } from '../../models/shared/external-video.model';
import { ModalListAsso } from 'src/app/shared/components/modal-associations-edit-nodos/modal-associations.class';
import { NodeElementsHistorialModel } from '../../models/nodes/node-elements-historial.model';
@Injectable({
    providedIn: 'root'
})
export class NodeService {

    public newNode = new Subject();
    public currentNode = new Subject();
    public currentUploadProgress = new Subject<{value: number, type: string}>();
    public delNode = new Subject<boolean>();
    public changeImage = new Subject<{node:any, value:string, format:string}>();
    public deleteFile = new Subject<{type:string, idNodeFile:number, fileName:string}>();
    public videoYoutube = new Subject<YoutubeVideo>();
    public videoInstagram = new Subject<InstagramVideo>();
    private _redirectedNode: Subject<any> = new BehaviorSubject<any>({});
    public videoTiktok = new Subject<TiktokVideo>();
		public spotifyAudio = new Subject<SpotifyAudio>();
    public redirectedNode = new Subject<gNode>();

		private _redirectedQuiz: Subject<any> = new BehaviorSubject<any>({});

    constructor(private datePipe: DatePipe, private http: HttpClient) { }

    // *** CURRENT NODE ***
    public setCurrentNode(node) {
        this.currentNode.next(node);
    }

		public setRedirectedNode(node) {
			this._redirectedNode.next(node);
		}

		//get redirected node as observable
		public getRedirectedNode(){
			return this._redirectedNode.asObservable();
		}

		public setRedirectedQuiz(node) {
			this._redirectedQuiz.next(node);
		}

		//get redirected node as observable
		public getRedirectedQuiz(){
			return this._redirectedQuiz.asObservable();
		}
    public setDeleteFile(){}
    public emptyCurrentNode() {}

    // *** N O D E ***
    public getEmptyNode() {
        var date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        return {
            certificable: false,
            language1: '',
            language2: '',
            author: '',
            video: '',
            image: '',
            audio: '',
            pdf: '',
            textfile: '',
            creation: date,
            edition: date,
            idCourseCreation: 0,
            idTargetCreation: 0
        }
    }

    public getNode(id: number, courseId: number, graphId: number): Observable<any> {
        return this.http.get(`node/courses/${courseId}/graphs/${graphId}/nodes/${id}`).pipe(
            map((res: any) => {
                if (res.data && res.data.length > 0) {
                    res.data[0].pictureNode = (res.data[0].pictureNode !== 'null' && res.data[0].pictureNode !== '') ? res.data[0].pictureNode : undefined;
                }
                return res;
            }),
            catchError(() => of({ error: { code: 500 } }))
        );
    }

    // *** N O D E ***
    // C R E A T E
    public createNode(courseId: number, graphId: number, node: gNode, file: File): Observable<any> {
        const n = {
            user: {
                idUser: node.user.idUser,
                nick: node.user.nick
            },
            subject: {
                idSubject: node.subject.idSubject,
                subject: node.subject.subject
            },
            language: {
                idLanguage: 38,
                idLanguageIso: "ES",
                language: "Español"
            },
            duration: node.duration,
            label: node.label,
            description: node.description,
            certificable: node.certificable,
            nodeType: 'Node',
            idTargetCreation: graphId,
            idCourseCreation: courseId,
            nodeSwlevel: node.nodeSwlevel,
            ordinalPower0: node.ordinalPower0,
            ordinalPower1: node.ordinalPower1,
            ordinalPower2: node.ordinalPower2,
            ordinalPower3: node.ordinalPower3,
            ordinalPowerNegative1: node.ordinalPowerNegative1,
            ordinalPowerNegative2: node.ordinalPowerNegative2,
            ordinalPowerNegative3: node.ordinalPowerNegative3,
            x: node.x,
            y: node.y,
            id: node.id,
            idOriginal: node.idOriginal,
            idPower: node.idPower,
            idNodeTarget: node.idNodeTarget,
            idNodesFile: node.idNodesFile,
            edges: [],
            creationDate: node.creationDate,
            pictureNode: node.pictureNode ? node.pictureNode : undefined,
            imageNode: node.imageNode || 0,
            published : node.published || null,
            pages: node.pages || '',
            ordinal: node.ordinal,

        }

        let form: FormData = new FormData()

        form.append('node', JSON.stringify(n));
        form.append('files', file);

        return this.http.post<any>(`node/courses/graphs/nodes`, form, {
            reportProgress: true,
            observe: 'events'
          }).pipe(
            map((res:HttpEvent<any>) => {
                if(res.type == HttpEventType.Response ){
                    if(!res.body.error.code){
                        res.body.data = {
                            ...res.body.data,
                            originalX: res.body.data.x,
                            originalY: res.body.data.y,
                            type: 'circle',
                            size: SIGMA_CONSTANTS.NODE_SIZE,
                            color: '#D7DBDC',
                            originalColor: '#D7DBDC',
                            nodesFiles: []
                        }
                    }
                }
                return res;
            })
        );
    }

    /**
     * ENDPOINT PARA CREAR UN NODO DESDE EL MODO LISTA
     */
    createNodeFromModeList(idTarget: number): Observable<any>{
        const body = {};
        return this.http.post(`node/createNodeFromModeList/${idTarget}`, body);
    }

    public deleteNode(nodeId: number, courseId: number, graphId: number) {
        return this.http.delete(`node/courses/${courseId}/graphs/${graphId}/nodes/${nodeId}`);
    }

    public createEdge(nodeFromId: number, nodeToId: number, courseId: number, graphId: number, type: string, label: string, size: number, color: string) {
        const body = {
            idNode2: nodeToId,
            size: size,
            label: label,
            type: type,
            color: color
        };

        return this.http.post(`node/courses/${courseId}/graphs/${graphId}/nodes/${nodeFromId}/edges`, body);
    }

    public deleteEdge(nodeFromId: number, nodeToId: number, courseId: number, graphId: number) {
        return this.http.delete(`node/courses/${courseId}/graphs/${graphId}/nodes/${nodeFromId}/edges/${nodeToId}`);
    }

    public postNodeFile(nodeFilesId: number, type: string, operators: ModelIcono[] = [], file?: File, text?: string):Observable<any> {

        const form: FormData = new FormData();

        form.append('type', type);
        form.append('operators', JSON.stringify( { operators: operators.map(o => ({ id: o.id })) } ));

        if (file) {
            form.append('files', file);
        } else {
            form.append('files', JSON.stringify({}))
        }


        if (text) {
            form.append('text', text);
        } else {
            form.append('text', JSON.stringify(''))
        }

        return this.http.post<any>(`node/nodes/${nodeFilesId}/type/${type}`, form, {
            reportProgress: true,
            observe: 'events'
        });
    }

		public extractAudioNodeFile(nodeFilesId: number, type: string, operators: ModelIcono[] = [], file?: File, text?: string):Observable<any> {

			const form: FormData = new FormData();

			form.append('type', type);
			form.append('operators', JSON.stringify( { operators: operators.map(o => ({ id: o.id })) } ));

			if (file) {
					form.append('files', file);
			} else {
					form.append('files', JSON.stringify({}))
			}


			if (text) {
					form.append('text', text);
			} else {
					form.append('text', JSON.stringify(''))
			}

			return this.http.post<any>(`node/extractaudionodes/${nodeFilesId}/type/${type}`, form, {
					reportProgress: true,
					observe: 'events'
			});
	}

    public saveNodePos(nodeId: number, x: number, y: number, courseId: number, graphId: number) {
        const form: FormData = new FormData();

        const n = {
            idNode: nodeId,
            x,
            y,
            idCourseNode1: courseId,
            idTargetNode1: graphId
        }

        form.append('node', JSON.stringify(n));

        return this.http.post<any>(`node/courses/graphs/nodes`, form);
    }

    public getPowers(courseId: number, graphId: number, max: number, min: number) {
        let httpCalls: Observable<number>[] = [];

        for (let i = min; i <= max; i++) {
            httpCalls = [...httpCalls, this.getNextPower(courseId, graphId, i < 0 ? 'orderpowernegative' + Math.abs(i) : 'orderpower' + i)]
        }

        return forkJoin(httpCalls);
    }

    public getNextPower(courseId: number, graphId: number, power: string): Observable<number> {
        return this.http.get<any>(`node/courses/${courseId}/graphs/${graphId}/order/${power}`).pipe(map(res => res.data));
    }

    public selectFilePreviewAsImage(idNodeFile: number, fileType: number) {
        return this.http.put(`node/nodefile/${idNodeFile}/imagenode/${fileType}`, undefined);
    }

    /**
     *
     * @param element Element to delete (video, videoImage, image, audio, doc)
     * @param idNodeFiles ID element
     * @param nameFile Name file
     * @returns
     */
    public deleteFileFromNode(element: string, idNodeFiles:number, fileName:string):Observable<any>{
        let params:HttpParams = new HttpParams().append('nameFile', fileName)
        return this.http.delete(`quizze/deleteElementNode/${element}/${idNodeFiles}`, {params: params})
    }

    /**
     *
     * @param idNodeFile ID from the files associated to the node
     * @param url Youtube URL
     * @param start Video time start
     * @param end Video time end
     * @param nameFileVideo Name of the video file upload to delete from the server and replace by the Youtube video
     * @param isDelete Boolean (1-0) to delete the current Youtube video
     * @returns Observable
     */
    public postVideoYoutube(idNodeFile:number, url:string, start:string, end:string, nameFileVideo:string, isDelete:number, videoImage:string):Observable<any>{
        const body = {
            url : url ,
            start: start || null,
            end: end || null,
            nameFileVideo: nameFileVideo || null,
            isDelete : isDelete || 0,
						videoImage: videoImage || ''
        }
        return this.http.post(`node/videoyoutube/${idNodeFile}`, body)
    }

		postVideoInstagram(idNodeFile:number, url:string, videoImage:string, isDelete:number):Observable<any>{
			const body = {
				url : url ,
				isDelete : isDelete || 0,
				videoImage: videoImage || ''
			}
			return this.http.post(`node/videoinstagram/${idNodeFile}`, body)
		}

		postVideoTiktok(idNodeFile:number, url:string, videoImage:string, isDelete:number):Observable<any>{
			const body = {
				url : url ,
				isDelete : isDelete || 0,
				videoImage: videoImage || ''
			}
			return this.http.post(`node/videotiktok/${idNodeFile}`, body)
		}

		postSpotifyAudio(idNodeFile:number, url:string, isDelete: boolean):Observable<any>{
			const body = {
				url : url ,
				isDelete : isDelete,
				videoImage: ''
			}
			return this.http.post(`node/spotifyAudio/${idNodeFile}`, body)
		}



    public crearAsociacion(body) {
        return this.http.post(`node/nodesAsociation/`, body);
    }

    getListAss(id: number) {
        return this.http.get(`node/getnodeassociation/${id}`);
    }

    deleteAss(element: ModalListAsso) {
        return this.http.delete(`node/deleteNodeAssociation/${element.idNodeAss}/${element.nodeOrg.idNode}/${element.nodeDes.idNode}`);
    }



    /////ALQUILAR NODO
    alquilarNodo // *** N O D E ***
    (e: any) {

        let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
        let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
        const body = {
            columnX: e.x,
            idCourseTargetA: idNode_Course,
            idTargetA: idNode_Target,
            row: e.y,
            node:{
                idNode: e.idOriginal
            }
        }

        return this.http.post(`node/rentNodes/`, body);
    }

    alquilarQuiz// *** N O D E ***
    (e: any) {
        let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
        let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
        const body = {
            columnX: e.x,
            idCourse: idNode_Course,
            idTarget: idNode_Target,
            row: e.y,
            quizze:{
                idQuiz: e.idOriginal
            }
        }

        return this.http.post(`quizze/rentQuizzes/`, body);
    }


    /**
     * URL (TIPO GET):
        IdCourse: id del del curso
        IdGraph: id del grafo
        IdUser: id del usuario
        IdNode: id del nodo
        api-dev-pruebas/rest/node/coursescreatelastnode/courses/{idCourse}/graphs/{idGraph}/user/{idUser}/node/{idNode}

     * @param idCurso
     * @param idMapa
     * @param idOriginal
     */
    registerLastNodeTable(idCurso: number, idMapa: number, idUser: number, idNode: number) {
        return this.http.get(`node/createlastnode/courses/${idCurso}/graphs/${idMapa}/node/${idNode}`);
    }

    downloadFile(fileName:string, type:string):Observable<any>{
        return this.http.get(`node/downloadFile/fileName/${fileName}/type/${type}`, { reportProgress: true, observe: 'events' } )
    }

    updatePictureNode(idNode:number, file:File):Observable<any>{
        const body = new FormData()
        body.append('idNode', idNode.toString())
        body.append('files', file)
        return this.http.post<any>('node/updatePictureNode', body)
    }

    deletePictureNode(idNode:number):Observable<any>{
        return this.http.get<any>(`node/deletePictureNode/${idNode}`)
    }

    createNodesElementsHistorial(nodeElementsHistorial: any):Observable<any>{
        return this.http.post<any>(`node/createNodesElementsHistorial`, nodeElementsHistorial)
    }

    inheritNodeImage(idNode: number):Observable<any>{
        const body = {}
        return this.http.post(`node/inheritNodeImage/${idNode}`, body)
    }

    getQuizzesForTaskCalendar(idNode: number, idGroup: number, fecha: any): Observable<any>{
        return this.http.get(`node/getQuizzesForTaskCalendar/${idNode}/${idGroup}/${fecha}`);
    }

    createUpdateCalendarTask(body: any):Observable<any>{
        return this.http.post(`node/createUpdateCalendarTask`, body)
    }
}
