import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { MastersService } from 'src/app/core/services/masters';
import { LocalStorage } from 'src/app/core/utils';

@Component({
    selector: 'app-modal-alert-flash',
    templateUrl: './modal-alert-flash.component.html',
    styleUrls: ['./modal-alert-flash.component.scss'],
    standalone: false
})
export class ModalAlertFlashComponent implements OnInit {

	formulario: UntypedFormGroup;
	maxSize: number;
	maxBigQuizzes: number;
	maxAllQuizzes: number;

  constructor(
	public translateService: TranslateService,
	private activeModal: NgbActiveModal,
	private fb: UntypedFormBuilder,
	private localStorage: LocalStorage,
	private masterService: MastersService,
	){
		this.formulario = this.fb.group({
			sizeDeck: [1],
			includeSmall: [false],
			resp: [false],
		});
		this.formulario.get('includeSmall').valueChanges.subscribe((value) => {
			this.actualizarMaxSize(value);
		});
	}

	ngOnInit(){
		this.maxSize = this.maxBigQuizzes;
		this.sizePredetermined();
		//definimos variable para tutoriales
		this.masterService.setIdTutorialContext(66);
	}

  aceptar() {
	this.formulario.value.resp = true;
	this.masterService.getPreviousIdTutorialContext();
    this.activeModal.close(this.formulario);
  }

  cancelar() {
	this.masterService.getPreviousIdTutorialContext();
    this.activeModal.close(this.formulario);
  }

  closeModal(sendData?: any) {
	this.masterService.getPreviousIdTutorialContext();
    this.activeModal.close(this.formulario);
  }

  sizePredetermined() {
	this.formulario.get('sizeDeck').setValue(this.maxBigQuizzes);
  }


	actualizarMaxSize(includeSmall: boolean) {
    this.maxSize = includeSmall ? this.maxAllQuizzes : this.maxBigQuizzes;
  }
}
