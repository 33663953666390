import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserModel } from 'src/app/core/models/courses';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { Subject } from 'rxjs';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';
import { Agent, AgentDto, AgentService } from 'src/app/core/services/agents/agent.service';

@Component({
    selector: 'app-modal-ajustes-usuario',
    templateUrl: './modal-ajustes-usuario.component.html',
    styleUrls: ['./modal-ajustes-usuario.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalAjustesUsuarioComponent implements OnInit {

	public parentsList: UserModel[] = [];
	screenView: number = 1;
	user: User;
	profile: string;
	public formulario: UntypedFormGroup;
	public agentSettingsForm: UntypedFormGroup;
	cargando: boolean;
	private destroy$ = new Subject();

	// voice Options: 'alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'
	femaleVoices = [ 'alloy' , 'echo', 'fable'];
	maleVoices = ['onyx', 'nova', 'shimmer'];
	voiceOptions = [...this.femaleVoices, ...this.maleVoices];
	userAgentInfo: any;

	constructor(
		public loginService: LoginService,
		private formBuild: UntypedFormBuilder,
		public activeModal: NgbActiveModal,
		private store: Store<State>,
		private localStorage: LocalStorage,
		private masterService: MastersService,
		private agentService: AgentService,
		private cd: ChangeDetectorRef
	) {
		this.formulario = this.formBuild.group({
			filtrado: [''],
		});

		this.agentSettingsForm = this.formBuild.group({
			genre: [''],
			voice: [''],
			prompt: [''],
		});
	}

	ngOnInit() {
		this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
			this.profile = selectedProfile['selectedProfile']
		})
		this.user = this.loginService.getUser();
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		if(this.loginService.esProfesor()){
			idTutorialContext = 50;
		} else if(this.loginService.esEstudiante()){
			idTutorialContext = 81;
		}
		this.masterService.setIdTutorialContext(idTutorialContext);
		this.getAgentInfoAndFillForm();
	}

	getAgentInfoAndFillForm() {
		this.agentService.getAgentByIdUser(this.user.idUser).subscribe((response) => {
			this.userAgentInfo = response;
			this.loginService.setCurrentAgent(response);
			this.agentSettingsForm.setValue({
				genre: response.gender,
				voice: response.voice,
				prompt: response.prompt,
			});
		});

		this.loginService.currentAgent$.subscribe(agent => {
			this.userAgentInfo = null; // Clear the object reference
			this.userAgentInfo = { ...agent }; // Create a new object reference
			this.cd.markForCheck(); // Refresh the view
		});
	}

	refreshImage(event){
		this.getAgentInfoAndFillForm();
	}


	actualizarPerfil() {
    const formData = this.agentSettingsForm.getRawValue();
    const agentDto: AgentDto = {
      id: this.userAgentInfo.id,
      gender: formData.genre,
			voice: formData.voice,
			functions: '[1,2,3]', // Hardcoded for now
			prompt: formData.prompt,
			user: { idUser: this.user.idUser }
    };

    this.agentService.updateAgentProfile(agentDto)
      .subscribe(
        (agent: Agent) => {
					this.loginService.setCurrentAgent(agent);
					this.cd.markForCheck();
        },
        err => {
          console.error('Error al actualizar el perfil', err);
        }
      );
  }

	changeScreen(screen: number){
		this.screenView = screen;
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		if(this.screenView === 1){
			if(this.loginService.esProfesor()){
				idTutorialContext = 50;
			} else if(this.loginService.esEstudiante()){
				idTutorialContext = 81;
			}
		} else if(this.screenView === 2){
			idTutorialContext = 82;
		}
		this.masterService.setIdTutorialContext(idTutorialContext);
	}

	closeModal(sendData?: any) {
		this.actualizarPerfil();
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(sendData);
	}

}
