import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbProgressBarModule, NbRadioModule, NbSelectModule, NbSpinnerModule, NbTooltipModule, NbWindowRef, NbWindowService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { LocalStorage } from 'src/app/core/utils';
import { ComponentsModule } from '../components.module';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MatTableDataSource as MatTableDataSource, MatTableModule as MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { SalwizarContenidoComponent } from '../salwizar-contenido/salwizar-contenido.component';
import { PROMPT_SHAPE_GENERATIONS } from 'src/app/core/models/masters/masters.enum';
import { ModalGeneratedIdeasComponent } from '../salwizar-contenido/modal-generated-ideas/modal-generated-ideas.component';


@Component({
    selector: 'app-manage-graph-shape',
    imports: [
        CommonModule,
        NbFormFieldModule,
        NbCardModule,
        NbIconModule,
        NbTooltipModule,
        NbSelectModule,
        NbButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NbSpinnerModule,
        TranslateModule,
        MatTableModule,
        MatButtonModule,
        NbProgressBarModule,
        NbRadioModule,
    ],
    templateUrl: "./manage-ideas-options.component.html",
    styleUrls: ["./manage-ideas-options.component.scss"],
    changeDetection: ChangeDetectionStrategy.Default
})


export class ManageIdeasOptionsComponent {

	private httpClient: HttpClient;


	constructor(private formBuild: FormBuilder,
		private activeModal: NgbActiveModal,
		public http: HttpClient,
		private modalService: NgbModal,
		private localStorage: LocalStorage,
		handler: HttpBackend) { this.httpClient = new HttpClient(handler); }



	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}


}
