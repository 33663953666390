<div class="container">
	<input #myInput1 type="file" [id]="AUDIO_TYPE" accept="audio/mp3" (change)="onFileSelected($event)" hidden />
	<input #myInput2 type="file" [id]="VIDEO_TYPE" accept="video/mp4" (change)="onFileSelected($event)" hidden />
	<input #myInput3 type="file" [id]="IMAGE_TYPE" accept="image/*" (change)="onFileSelected($event)" hidden />
	<input #myInput4 type="file" [id]="PDF_TYPE" accept="application/pdf,application/vnd.ms-excel"
		(change)="onFileSelected($event)" hidden />

	<app-loading [isVisible]="loading"></app-loading>

	<div class="d-flex flex-column gap-4 align-items-center">
		<div class="d-flex flex-column gap-4 align-items-center">
			<div class="d-flex gap-3 align-items-center w-100">
				<!-- Titulo de la actividad -->
				<form [formGroup]="infoForm" style="flex: 0 50%;">
					<input nbInput fullWidth fieldSize="small" class="form-control" type="text" formControlName="title"
						(blur)="onTextBlur($event)" (keydown)="keydown($event)" />
				</form>
				<!-- Boton para mostrar/ocultar los quiz -->
				<button nbButton size="small" (click)="toggleViewFull()">
					<nb-icon [icon]="iconoFlecha"></nb-icon>
				</button>
			</div>
			<!-- quiz -->
			<ng-container *ngIf="viewFull">
				<div class="d-flex align-items-center gap-4">

					<div class="idioma">
						<!-- Idioma 1 -->
						<div class="d-flex justify-content-center align-items-center">
							<h5 class="mb-0">{{ 'QUIZZES.IDIOMA1' | translate }}</h5>
						</div>
						<!-- Contenido del idioma 1 -->
						<div class="d-flex align-items-center gap-2">

							<div class="d-flex justify-content-center flex-column gap-2">
								<div class="d-flex justify-content-center align-items-center">
									<!-- preview -->
									<div *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''" class="opt-quiz-icon"
										(click)='previewContentMedia(mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video1, DIR_VIDEO_URL)'>
										<div class="d-flex flex-column align-items-center gap-1">
											<span>{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}</span>
										</div>
									</div>
								</div>

								<div class="d-flex justify-content-center gap-2">
									<!-- adjuntar -->
									<div class="opt-quiz-icon" nbTooltip="Cargar video" (click)="attachment(VIDEO_TYPE, 1)"
										*ngIf="quizFiles.video1 === null || quizFiles.video1 === ''">
										<div class="d-flex flex-column align-items-center gap-1">
											<span>{{ "QUIZZES.VIDEO" | translate }}</span>
											<nb-icon icon="video-outline"></nb-icon>
										</div>
									</div>
									<!-- editar -->
									<button *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(VIDEO_TYPE, 1)">published_with_changes</mat-icon>
									</button>
									<!-- eliminar -->
									<button *ngIf="quizFiles.video1 !== null && quizFiles.video1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(VIDEO_TYPE, 1, quizFiles.video1)">delete</mat-icon>
									</button>
								</div>
							</div>

							<div class="d-flex justify-content-center flex-column gap-2">
								<div class="d-flex justify-content-center align-items-center">
									<!-- preview -->
									<div *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''" class="opt-quiz-icon"
										(click)='previewContentMedia(mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen1, DIR_PICTURE_URL)'>
										<div class="d-flex flex-column align-items-center gap-1">
											<span>{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}</span>
										</div>
									</div>
								</div>

								<div class="d-flex justify-content-center gap-2">
									<!-- adjuntar -->
									<div class="opt-quiz-icon" nbTooltip="Cargar video" (click)="attachment(IMAGE_TYPE, 1)"
										*ngIf="quizFiles.imagen1 === null || quizFiles.imagen1 === ''">
										<div class="d-flex flex-column align-items-center gap-1">
											<span>{{ "QUIZZES.IMAGEN" | translate }}</span>
											<nb-icon icon="image-outline"></nb-icon>
										</div>
									</div>
									<!-- editar -->
									<button *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(IMAGE_TYPE, 1)">published_with_changes</mat-icon>
									</button>
									<!-- eliminar -->
									<button *ngIf="quizFiles.imagen1 !== null && quizFiles.imagen1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(IMAGE_TYPE, 1,  quizFiles.imagen1)">delete</mat-icon>
									</button>
								</div>
							</div>

							<!-- <div class="card pr-1 pt-2 flex justify-content-center">

								<div class="d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''"
										(click)='previewContentMedia(mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio1, DIR_AUDIO_URL)'
										class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}">volume_up</mat-icon>
											</span>
										</button>
									</div>
									<mat-icon *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''"
										ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}" class="icon-hypermedia align-items pointer"
										(click)="attachment(AUDIO_TYPE, 1)">volume_up</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(AUDIO_TYPE, 1)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio1 === null || quizFiles.audio1 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer"
											ngbTooltip="{{ 'NODEFORMCOMPONENT.GRABARAUDIO' | translate }}" placement="right"
											(click)="attachment(AUDIO_TYPE, 1)">mic</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(AUDIO_TYPE, 1)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio1 !== null && quizFiles.audio1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(AUDIO_TYPE, 1, quizFiles.audio1)">delete</mat-icon>
									</button>
								</div>
							</div> -->

							<!-- <div class="card pr-1 pt-2 flex justify-content-center">
								<div class="d-flex justify-content-center align-items-center">
									<p *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''"
										[innerHTML]="quizFiles.text1 | truncate: [15]">
									</p>
									<mat-icon *ngIf="quizFiles.text1 === null  || quizFiles.text1 === ''"
										ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}" class="icon-hypermedia  align-items pointer"
										(click)="openQuillEditor(1)">subject</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.text1 === null  || quizFiles.text1 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
											placement="right" (click)="openQuillEditor(1)">edit</mat-icon>
									</button>
									<button *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
											placement="right" (click)="openQuillEditor(1)">edit</mat-icon>
									</button>
									<button *ngIf="quizFiles.text1 !== null && quizFiles.text1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteTextQuillEditor(1)">delete</mat-icon>
									</button>
								</div>
							</div> -->

							<!-- <div class="card  pr-1 pt-2 flex justify-content-center">
								<div class="d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''"
										(click)='previewContentMedia(mediaViewUrl+"pdf/"+quizFiles.doc1, "pdf")' class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}">insert_drive_file</mat-icon>
											</span>
										</button>
									</div>
									<mat-icon *ngIf="quizFiles.doc1 === null || quizFiles.doc1 === ''"
										ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}" class="icon-hypermedia align-items pointer"
										(click)="attachment(PDF_TYPE, 1)">insert_drive_file</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.doc1 === null || quizFiles.doc1 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(PDF_TYPE, 1)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(PDF_TYPE, 1)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.doc1 !== null && quizFiles.doc1 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(PDF_TYPE, 1, quizFiles.doc1)">delete</mat-icon>
									</button>
								</div>
							</div> -->

						</div>
					</div>
					<!-- Idioma 2 -->
					<!-- <div class="idioma">
						<div class="d-flex justify-content-center align-items-center">
							<h5 class="mb-0">{{ 'QUIZZES.IDIOMA2' | translate }}</h5>
						</div>

						<div class="d-flex align-items-center">

							<div class="card pr-1 pt-2">
								<div class="d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''"
										(click)='previewContentMedia(mediaViewUrl+DIR_VIDEO_URL+"/"+quizFiles.video2, DIR_VIDEO_URL)'
										class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}" class="lng2">videocam</mat-icon>
											</span>
										</button>
									</div>

									<mat-icon *ngIf="quizFiles.video2 === null || quizFiles.video2 === ''"
										ngbTooltip="{{ 'QUIZZES.VIDEO' | translate }}" class="icon-hypermedia  align-items pointer lng2"
										(click)="attachment(VIDEO_TYPE, 2)">videocam</mat-icon>

								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.video2 === null || quizFiles.video2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(VIDEO_TYPE, 2)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(VIDEO_TYPE, 2)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.video2 !== null && quizFiles.video2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(VIDEO_TYPE, 2, quizFiles.video2)">delete</mat-icon>
									</button>
								</div>
							</div>

							<div class="card pr-1 pt-2">
								<div class="d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''"
										(click)='previewContentMedia(mediaViewUrl+DIR_PICTURE_URL+"/"+quizFiles.imagen2, DIR_PICTURE_URL)'
										class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}" class="lng2">image</mat-icon>
											</span>
										</button>
									</div>
									<mat-icon *ngIf="quizFiles.imagen2 === null || quizFiles.imagen2 === ''"
										ngbTooltip="{{ 'QUIZZES.IMAGEN' | translate }}" class="icon-hypermedia align-items pointer lng2"
										(click)="attachment(IMAGE_TYPE, 2)">image</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.imagen2 === null || quizFiles.imagen2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(IMAGE_TYPE, 2)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(IMAGE_TYPE, 2)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.imagen2 !== null && quizFiles.imagen2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(IMAGE_TYPE, 2,  quizFiles.imagen2)">delete</mat-icon>
									</button>
								</div>
							</div>

							<div class="card pr-1  pt-2">
								<div class="col-12 d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''"
										(click)='previewContentMedia(mediaViewUrl+DIR_AUDIO_URL+"/"+quizFiles.audio2, DIR_AUDIO_URL)'
										class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon class="lng2" ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}">volume_up</mat-icon>
											</span>
										</button>
									</div>
									<mat-icon *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''"
										ngbTooltip="{{ 'QUIZZES.AUDIO' | translate }}" class="icon-hypermedia align-items pointer lng2"
										(click)="attachment(AUDIO_TYPE, 2)">volume_up</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center ">
									<button *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(AUDIO_TYPE, 2)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio2 === null || quizFiles.audio2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2"
											ngbTooltip="{{ 'NODEFORMCOMPONENT.GRABARAUDIO' | translate }}" placement="right"
											(click)="attachment(AUDIO_TYPE, 2)">mic</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(AUDIO_TYPE, 2)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.audio2 !== null && quizFiles.audio2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(AUDIO_TYPE, 2, quizFiles.audio2)">delete</mat-icon>
									</button>
								</div>
							</div>

							<div class="card pr-1 pt-2 flex justify-content-center">
								<div class="col-12 d-flex justify-content-center align-items-center">
									<p *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''"
										[innerHTML]="quizFiles.text2 | truncate: [15]">
									</p>
									<mat-icon *ngIf="quizFiles.text2 === null  || quizFiles.text2 === ''"
										ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}" class="icon-hypermedia  align-items pointer lng2"
										(click)="openQuillEditor(2)">subject</mat-icon>
								</div>
								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.text2 === null  || quizFiles.text2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
											placement="right" (click)="openQuillEditor(2)">edit</mat-icon>
									</button>
									<button *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.EDITAR' | translate }}"
											placement="right" (click)="openQuillEditor(2)">edit</mat-icon>
									</button>
									<button *ngIf="quizFiles.text2 !== null && quizFiles.text2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteTextQuillEditor(2)">delete</mat-icon>
									</button>
								</div>
							</div>

							<div class="card pr-1 pt-2 flex justify-content-center">
								<div class="col-12 d-flex justify-content-center align-items-center">
									<div *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''"
										(click)='previewContentMedia(mediaViewUrl+"pdf/"+quizFiles.doc2, "pdf")' class="row">
										<button nbButton status="basic" size="tiny">
											<span class="d-flex justify-content-center align-items-center">
												{{ 'QUIZZES.DIALOGS.VERCONTENIDO' | translate }}
												<mat-icon ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}"
													class="lng2">insert_drive_file</mat-icon>
											</span>
										</button>
									</div>
									<mat-icon *ngIf="quizFiles.doc2 === null || quizFiles.doc2 === ''"
										ngbTooltip="{{ 'QUIZZES.DOCUMENTO' | translate }}" class="icon-hypermedia align-items pointer lng2"
										(click)="attachment(PDF_TYPE, 2)">insert_drive_file</mat-icon>
								</div>

								<div class="card-body d-flex justify-content-center">
									<button *ngIf="quizFiles.doc2 === null || quizFiles.doc2 === ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ADJUNTAR' | translate }}"
											placement="right" (click)="attachment(PDF_TYPE, 2)">attach_file</mat-icon>
									</button>
									<button *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2"
											ngbTooltip="{{ 'QUIZZES.DIALOGS.CAMBIARFICHERO' | translate }}" placement="right"
											(click)="attachment(PDF_TYPE, 2)">published_with_changes</mat-icon>
									</button>
									<button *ngIf="quizFiles.doc2 !== null && quizFiles.doc2 !== ''" type="button"
										class="btn btn-link  btn-sm">
										<mat-icon class="align-items edit pointer lng2" ngbTooltip="{{ 'QUIZZES.ELIMINAR' | translate }}"
											placement="right" (click)="deleteFile(PDF_TYPE, 2, quizFiles.doc2)">delete</mat-icon>
									</button>
								</div>
							</div>

						</div>
					</div> -->

				</div>
			</ng-container>
		</div>
		<!-- Accion para crear los quiz -->
		<div *ngIf="quizzes.length == 0" class="d-flex justify-content-center align-items-center">
			<button nbButton class="btn-themecolor" size="small" (click)="crearSubQuizzes()">
				{{ 'QUIZZES.CREARQUIZZES' | translate }}
			</button>
		</div>
	</div>
</div>
