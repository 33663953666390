<div class="d-flex justify-content-center align-items-center gap-3">

	<!-- <div class="d-flex justify-content-center align-items-center">
		<div class="d-flex justify-content-center align-items-center flex-column px-3">
			<button mat-raised-button mat-icon-button [ngbTooltip]="'SIGMACOMPONENT.CERTIFICAR' | translate"
				placement="button" (click)="certifiedQuiz($event)">
				<mat-icon class="circle-button" svgIcon="quiz_certificado"></mat-icon>
			</button>
			<span class="mt-2">{{'SIGMACOMPONENT.CERTIFICAR' | translate}}</span>
		</div>

		<form [formGroup]="form" class="px-3">
			<mat-slide-toggle class="buttonPublicar" (click)="certifiedQuiz($event)"
				formControlName="certificadoQuiz"></mat-slide-toggle>
		</form>
	</div> -->

	<form [formGroup]="form">
		<div class="gap-1 toggle-edit-multiple">
			<nb-toggle class="item-profile"
				formControlName="published" (click)="publish($event)">
			</nb-toggle>
			<span>
				{{'EDITARCURSO.PUBLISH' | translate}}
			</span>
		</div>
	</form>

	<div class="d-flex justify-content-center align-items-center">
		<form [formGroup]="form">
			<nb-select formControlName="pattern" class="SelectForm" hero size="small" (change)="updateRepetition()">
				<nb-option class="SelectFormOpt" *ngFor="let item of repetitionList" [value]="item.pattern">
					{{item.descripcion}}
				</nb-option>
			</nb-select>
		</form>
	</div>

	<div class="d-flex justify-content-center align-items-center" *ngIf="loginService.esAutor()">
		<div class="gap-1 toggle-edit-multiple">
			<div class="delete pointer d-flex" (click)="deleteMultipleQuiz()">
				<mat-icon class="pointer color-red-text" nbTooltip="{{'QUIZZES.DELETE' | translate}}" nbTooltipPlacement="top">delete</mat-icon>
			</div>
			<span>
				{{'QUIZZES.DELETE' | translate}}
			</span>
		</div>
	</div>

</div>
<!-- Evento para cerrar la actividad -->
<div class="closeModal">
	<nb-icon class="m-1" icon="checkmark-circle-2" [options]="{ animation: { type: 'zoom' } }"
		nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}" nbTooltipPlacement="left" (click)="onCloseModal()">
	</nb-icon>
</div>
