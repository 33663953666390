import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
    selector: 'app-power-selector',
    templateUrl: './power-selector.component.html',
    styleUrls: ['./power-selector.component.scss'],
    standalone: false
})
export class PowerSelectorComponent implements OnInit, OnChanges {
    @Input() arrayValues: number[] = [];
    @Output() arrayValuesChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

    private _arrayValues: number[];

    public powerGroup: UntypedFormGroup

    constructor(private fb: UntypedFormBuilder) {
        this._generateForm();        
    }

    ngOnInit() {
        this._arrayValues = this.arrayValues;
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.arrayValues.currentValue) {
            this._arrayValues = changes.arrayValues.currentValue;
            Object.keys(this.powerGroup.controls).forEach(key => {
                this.powerGroup.get(key).patchValue(false);
            });

            this._arrayValues.forEach(val => {
                this.powerGroup.get(val.toString()).patchValue(true);
            });
        }
    }

    private _generateForm(): void {
        this.powerGroup = this.fb.group({
            "3": [false],
            "2": [false],
            "1": [false],
            "0": [true],
            "-1": [false],
            "-2": [false],
            "-3": [false]
        })
    }

    toggleSelection($ev, value: number): void {
        //Elimino el valor del array
        if (!$ev.checked) {
            this._arrayValues.forEach((e, i) => {
                if (e === value)
                    this._arrayValues.splice(i, 1)
            })
        }
        else
            this._arrayValues.push(value)

        this._arrayValues.sort((a, b) => a - b)

        this.arrayValuesChanged.emit(this._arrayValues);
    }
}
