import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { CenterModel } from 'src/app/core/models/masters';
import { CenterById } from './../../models/masters/center.model';
import { LoginService } from 'src/app/core/services/login';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseCenterDomainModel, ConfigCenter } from './../../models/center/center-domain-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from '../../utils';
import { ResponseCenterByIdModel } from '../../models/masters/center.model';


const GETCONFIGCENTER = 'commons/getcenter-setting'

export enum MODERETURNIMAGE{
    IMAGE = 'image',
    BACKGROUND = 'bg'
}

@Injectable({
  providedIn: 'root'
})
export class CenterService {

    public centerConfig: Subject<ConfigCenter> = new Subject<ConfigCenter>()
    private _currentConfig: ConfigCenter;

    public get currentConfig(): ConfigCenter {
        return this._currentConfig;
    }
    public set currentConfig(value: ConfigCenter) {
        this._currentConfig = value;
    }

    constructor(private http:HttpClient, private localStorage:LocalStorage, private loginService: LoginService) { }

    getCenterConfigByDomain(): void {
        this.http.get<ResponseCenterDomainModel>(GETCONFIGCENTER).pipe(map(res => res.data)).subscribe(response => {
            this.setConfigCenter(response)
        })
    }

    setConfigCenter(value:ConfigCenter){
        this.currentConfig = value
        this.localStorage.setItem(LOCALSTORAGESTRINGS.CONFIGSITE, JSON.stringify(value))
        this.centerConfig.next(value)
    }

    getImageCenter(): Observable<string> | null{

        return new Observable((o) => {

            if(this.currentConfig){
                o.next(this.currentConfig.imgLogo)
            }
            else{
                this.centerConfig.subscribe(data => {
                   o.next(data.imgLogo)
                }, err => {
                    o.error(null)
                })
            }
        })
    }


    /**
     * Función que se le pasa el parámetro del usuario actual y devuelve el listado de centro asociados a él (profesor)
     * @returns Listado con los centros asociados al profesor
     */
    getListCenterByIdUser():Observable<CenterById[]>{
        return this.http.get<ResponseCenterByIdModel>(`group/getcentersasignedteacher`).pipe( map(res => res.data as CenterById[]) )
    }

    /**
     * Función que se le pasa el parámetro del usuario actual y devuelve el listado de centro asociados a él (editor)
     * @returns Listado con los centros asociados al profesor
     */
    getCentersAsignedEditor(): Observable<any[]> {
        return this.http.get<any>(`group/getCentersAsignedEditor`).pipe(map(res => res.data as CenterById[]))
    }

    /**
     * Función que se le pasa el parámetro del usuario actual y devuelve el listado de centros NO asociados a él (editor)
     * @returns Listado con los centros asociados al profesor
     */
    getCentersNotAsignedEditor(): Observable<any[]> {
        return this.http.get<any>(`group/getCentersNotAsignedEditor`).pipe(map(res => res.data as CenterById[]))
    }

		/**
     * Función que se le pasa el parámetro del usuario actual y devuelve el listado de centro asociados a él (profesor)
     * @returns Listado con los centros asociados al profesor
     */
    getListCenterByIdUserForUser():Observable<any>{
			return this.http
				.get<ResponseCenterByIdModel>(`group/getcentersasigneduser`)
				.pipe(map((res) => res.data));
		}

    /**
     * Función que se le pasa el Id del centro y se le asigna ese centro al usuario (profesor)
     * @param idCenter Id del centro
     * @returns
     */
    setCenterToTeacher(idCenter:number): Observable<any>{
        return this.http.get<any>(`group/asigncentertoteacher/${idCenter}`)
    }

    /**
     * Función que se le pasa el Id del centro y se le asigna ese centro al usuario (profesor)
     * @param idCenter Id del centro
     * @returns
     */
    asignCenterToEditor(idCenter:number): Observable<any>{
        return this.http.get<any>(`group/asignCenterToEditor/${idCenter}`)
    }

    setCenterToUser(idCenter:number): Observable<any>{
        return this.http.get<any>(`group/asigncentertouser/${idCenter}`);
    }

    changeRealDataCenter(realData: number): Observable<any>{
        return this.http.get<any>(`group/changeRealDataCenter/${realData}`);
    }

    getUsersCenter(): Observable<any>{
        return this.http.get<any>(`group/getUsersCenter`);
    }

    /**
     * Función para la creación de un centro y el asigando automático al usuario creador (profesor)
     * @returns
     */
    createCenterAssignTeacher(file: File | null, center: CenterModel): Observable<any>{

        let form: FormData = new FormData()
        center.centerPicture = null

        form.append('center', JSON.stringify(center))
        form.append('files', file)

        return this.http.post<any>(`group/createcenterasignteacher`,form)
    }

    createCenterAssignUser(file: File | null, center: CenterModel): Observable<any>{
        let form: FormData = new FormData();
        center.centerPicture = null;

        form.append("center", JSON.stringify(center));
        form.append("files", file);

        return this.http.post<any>(`group/createcenterasignuser`, form);
    }

    deleteCenterAssign(idCenter:number): Observable<any>{
        return this.http.delete<any>(`group/deleteasigncentertoteacher/${idCenter}`)
    }

    deleteAsignCenterToEditor(idCenter:number): Observable<any>{
        return this.http.delete<any>(`group/deleteAsignCenterToEditor/${idCenter}`)
    }

    deleteCenterAssignUser(idCenter:number): Observable<any>{
        return this.http.delete<any>(
            `group/deleteasigncentertouser/${idCenter}`
        );
    }

}
