<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="border-bottom-0">
		<div class="row">
			<div class="col-12 col-md-12 modal-title">
				<h2>{{ "QUIZZES.RECORDQUIZZES" | translate }}</h2>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal(comentario)">
		</nb-icon>
	</div>

	<nb-card-body>
		<app-loading [isVisible]="cargando"></app-loading>
		<!-- LIST VIEW -->
		<div class="row h-180">
			<div class="col-12 col-xl-3">
				<!--LISTA PARA VER LAS TAREAS POR ASIGNAR-->
				<div class="flex flex-column background_themecolor h-180 pl-3 pt-3 pb-3 pr-0 overflow-auto">
					<!--PRIMER NIVEL DEL ARBOL (NODO)-->
					<ul class="m-0 pt-0 pb-0 pl-0 pr-3" style="list-style: none;">
						<li *ngFor="let quiz of tree">
							<div class="d-flex pointer py-2 px-3 gap-2" [class.selected]="selectedLabel === quiz" (click)="clickElement(quiz)">
								<mat-icon svgIcon="act"	class="mat-icon pointer"></mat-icon>
								<span> {{ quiz.time }} (rep)</span> <!--numero repeticiones-->
								<span> {{ quiz.elementsAnswer[0].lastDate | date:'yyyy-MM-dd' }} </span> <!--fecha-->
								<nb-icon icon="eye-outline" status="basic" *ngIf="selectedLabel === quiz"></nb-icon>
								<nb-icon icon="eye-off-2-outline" status="basic" *ngIf="selectedLabel !== quiz" (click)="verQuiz(quiz)"></nb-icon>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-12 col-xl-9 h-180 overflow-auto">
				<div class="px-5 mx-5" *ngIf="quiz != null">
					<app-recordar-quiz-play-body
						[courseId]="courseId" [graphId]="graphId" [quiz]="quiz"
						[elements]="elements"[respuestaEstudiante]="respuestaEstudiante"
						[answered]="true" [fechaUltimaRespuesta]="fechaUltimaRespuesta">
					</app-recordar-quiz-play-body>
				</div>
			</div>
		</div>
		<!-- END LIST VIEW -->
	</nb-card-body>
</nb-card>
