import { CommonModule } from "@angular/common";
import { HttpEventType } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { gNode } from "src/app/core/models/graph/gNode.model";
import { NodeService } from "src/app/core/services/node/node.service";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

@Component({
    selector: "app-modal-ideas-order",
    templateUrl: "./modal-ideas-order.component.html",
    styleUrls: ["./modal-ideas-order.component.scss"],
    standalone: false
})
export class ModalIdeasOrderComponent implements OnInit {
	public ideas: gNode[]; // Current node to edit
	public idCurso;
	public idMapa;
	sortableStyles = {
		width: "100%", //width of the list defaults to 300,
		height: "auto",
		dropZoneHeight: "50px", // height of the dropzone indicator defaults to 50
	};
	constructor(
		private activeModal: NgbActiveModal,
		public nodeService: NodeService,
		private toaster: ToasterService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		console.log(this.ideas);
		this.ideas.sort((a, b) => a.ordinalPower0 - b.ordinalPower0);
	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	saveChanges(evt) {
		this.ideas.forEach((idea, index) => {
			idea.ordinalPower0 = index + 1;
			this.updateNodes(idea);
		});
	}

	updateNodes(node) {
		this.nodeService
			.createNode(this.idCurso, this.idMapa, node, null)
			.subscribe(
				(res) => {},
				(err) => {
					this.toaster.error(
						this.translateService.instant("NODEFORMCOMPONENT.ERROR")
					);
				}
			);
	}
}
