import { image } from 'html2canvas/dist/types/css/types/image';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
export interface gNode {
	associated: boolean;
	id: string;
	idOriginal?: number;
	label: string;
	x: number;
	y: number;
	image?: string;
	imageData?: {
		url: string;
		clip: number;
		scale: number;
		h: number;
		w: number;
	};
	originalX?: number;
	originalY?: number;
	description: string;
	nodeType: string;
	nodeSwlevel: number;
	duration: number;
	certificable?: boolean;
	idTargetCreation: number;
	idCourseCreation: number;
	ordinalPower0?: number;
	ordinalPower1?: number;
	ordinalPower2?: number;
	ordinalPower3?: number;
	ordinalPowerNegative1?: number;
	ordinalPowerNegative2?: number;
	ordinalPowerNegative3?: number;
	size?: number;
	originalColor?: string;
	color?: string;
	type?: string;
	url?: string;
	text?: string;
	badge?: number;
	backgroundColor?: string;
	disabled?: string;
	delete: false;
	hidden?: boolean;
	timesDone?: number;
	edges?: any[];
	level?: number;
	subject?: any;
	user?: any;
	idPower?: number;
	idNodeTarget?: number;
	idNodesFile?: number;
	creationDate?: number;
	pictureNode?: string;
	imageNode?: number;
	published?: any;
	pages?: string;
	ordinal?: number;
	isSOS?: number;
	isMultiplexed?: number;
	link?: string;
	quizTittle?: string;
	certifiedQuiz?: number;
}

export class ObjetoNodoAuto {

    public nodosAndQuizzes: [];
    public powerSeledted: [];
    public pageSelected: number;
    public explicacionesPrototype: any[];
    public soloExplicaciones: any[];
    public soloQuizes: any[];
    quizzes: any[];
    explicacionesPower0: any[] = [];
    explicacionesPower1: any[] = [];
    explicacionesPower2: any[] = [];
    explicacionesPower3: any[] = [];
    explicacionesPowerMinus3: any[] = [];
    explicacionesPowerMinus2: any[] = [];
    explicacionesPowerMinus1: any[] = [];

    constructor(nodosAndQuizzes: [], powerSeledted) {
        this.powerSeledted = powerSeledted;
        this.ordenarObjeto(nodosAndQuizzes);
    }
    ordenarObjeto(nodosAndQuizzes: []) {

        //QUE exista por lo menos un elemento
        if (nodosAndQuizzes.length > 0) {
            this.separarExplicacionesYQuizzes(nodosAndQuizzes);
        }
    }
    obtenerListado(listPowers: number[]): any[]{
        return this.listadoPorPower(listPowers);
    }

    listSoloExplicaciones(){
        return this.soloExplicaciones;
    }

    listadoSoloQuiz(){
        return this.soloQuizes;
    }

    /**
     *
     * @param numPower Array con las potencias seleccionadas
     * @returns Devuelve un array con las ideas (nodos) correspondientes a las potencias seleccionadas
     */
    listadoPorPower(numPower: number[]): any[]{

        let total: any[] = []

        numPower.forEach(n => {
            if(n === 0)
                total = total.concat(this.explicacionesPower0)
            if(n === 1)
                total = total.concat(this.explicacionesPower1)
            if(n === 2)
                total = total.concat(this.explicacionesPower2)
            if(n === 3)
                total = total.concat(this.explicacionesPower3)
            if(n === -1)
                total = total.concat(this.explicacionesPowerMinus1)
            if(n === -2)
                total = total.concat(this.explicacionesPowerMinus2)
            if(n === -3)
                total = total.concat(this.explicacionesPowerMinus3)
        })

        return total
    }

    separarExplicacionesYQuizzes(nodosAndQuizzes) {
        let explicaciones = [];
        this.quizzes = [];
        let allQuizzes = [];
        let explicacionesPower0 = [];
        let explicacionesPower1 = [];
        let explicacionesPower2 = [];
        let explicacionesPower3 = [];
        let explicacionesPowerMinus3 = [];
        let explicacionesPowerMinus2 = [];
        let explicacionesPowerMinus1 = [];


        nodosAndQuizzes.forEach(element => {
            //Todos los quizes y nodos en arrays diferentes
            if (element.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
                this.quizzes.push(element);
                allQuizzes.push(element)
            }
            else if (element.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
                //El orden seleccionado en el boton power
                if (element.power0) {
                    explicacionesPower0.push(element)
                    this.ordenarPorBurbujaYPower0(explicacionesPower0);
                }
                if (element.power1) {
                    explicacionesPower1.push(element)
                    this.ordenarPorBurbujaYPower1(explicacionesPower1);
                }
                if (element.power2) {
                    explicacionesPower2.push(element)
                    this.ordenarPorBurbujaYPower2(explicacionesPower2);
                }
                if (element.power3) {
                    explicacionesPower3.push(element);
                    this.ordenarPorBurbujaYPower3(explicacionesPower3);
                }
                if (element.powerNegative1) {
                    explicacionesPowerMinus1.push(element)
                    this.ordenarPorBurbujaYPowerNegative1(explicacionesPowerMinus1);
                }
                if (element.powerNegative2) {
                    explicacionesPowerMinus2.push(element)
                    this.ordenarPorBurbujaYPowerNegative2(explicacionesPowerMinus2);
                }
                if (element.powerNegative3) {
                    explicacionesPowerMinus3.push(element)
                    this.ordenarPorBurbujaYPowerNegative3(explicacionesPowerMinus3);
                }
                explicaciones.push(element);
            }
        });
        this.soloExplicaciones = explicaciones
        this.soloQuizes = this.quizzes

        this.explicacionesPower0 = this.construirArrayF(explicacionesPower0);
        this.explicacionesPower1 = this.construirArrayF(explicacionesPower1);
        this.explicacionesPower2 = this.construirArrayF(explicacionesPower2);
        this.explicacionesPower3 = this.construirArrayF(explicacionesPower3);
        this.explicacionesPowerMinus1 = this.construirArrayF(explicacionesPowerMinus1);
        this.explicacionesPowerMinus2 = this.construirArrayF(explicacionesPowerMinus2);
        this.explicacionesPowerMinus3 = this.construirArrayF(explicacionesPowerMinus3);
    }

    construirArrayF(explicaciones: any[]): any[]{
        let explicacionesPrototype = [];
        explicacionesPrototype = this.crearExplicacionesPadrePrototype(explicaciones);
        return explicacionesPrototype;
    }



    crearExplicacionesPadrePrototype(explicaciones: any) {
        let explicacionesPrototype: any = []

        //Se empieza por la ultima explicacion
        for (let index = 0; index < explicaciones.length; index++) {
            let cuurent = explicaciones[index];
            let currentExplicacion = new ExplicacionPrototype(cuurent);
                //para cada explicacion agregamos sus quizes
            explicacionesPrototype.push(currentExplicacion);
            this.insertarQuizzesPrototype(currentExplicacion);

        }

        return explicacionesPrototype;
    }

    crearListadosPorPower(explicacionesPrototype:any[]) {
        let ordenPower0 = this.ordenarPorBurbujaYPower0(explicacionesPrototype);
        let ordenPower1 = this.ordenarPorBurbujaYPower1(explicacionesPrototype);

    }
    insertarQuizzesPrototype(currentExplicacion: any): any {
        let salir = false;
        for (let q = 0; q < this.quizzes?.length; q++) {
            const quiz = this.quizzes[q];
            for (let h = 0; h < quiz.edges?.length; h++) {
                let edge = quiz.edges[h];
                if(currentExplicacion.id === edge.target){
                    let elementCurrentQuiz: any=  {}
                    Object.defineProperties(elementCurrentQuiz,
                        {
                            'id': { value: quiz.id },
                            'idOriginal': { value: quiz.idOriginal },
                            'quizTittle': { value: quiz.quizTittle },
                            'idMultiplexQuiz': { value: quiz.isMultiplexed }
                    });

                    // this.quizzes = this.quizzes.filter(quiz => quiz.id !== elementCurrentQuiz.id)
                    currentExplicacion.quiz.push(elementCurrentQuiz);
                }

            }
        }
   }
    //Ordenar por power
    ordenarPorBurbujaYPower0(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.ordinalPower0 > tempArray[index + 1].ordinalPower0) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPower0(tempArray)
        }
        // Array ordenado
        return tempArray;

    }


    ordenarPorBurbujaYPower1(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.ordinalPower1 > tempArray[index + 1].ordinalPower1) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPower1(tempArray)
        }
        // Array ordenado
        return tempArray;
    }

    ordenarPorBurbujaYPower2(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.ordinalPower2 > tempArray[index + 1].ordinalPower2) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPower2(tempArray)
        }
        // Array ordenado
        return tempArray;
    }

    ordenarPorBurbujaYPower3(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.ordinalPower3 > tempArray[index + 1].ordinalPower3) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPower3(tempArray)
        }
        // Array ordenado
        return tempArray;
    }

    ordenarPorBurbujaYPowerNegative1(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.powerNegative1 > tempArray[index + 1].powerNegative1) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPowerNegative1(tempArray)
        }
        // Array ordenado
        return tempArray;
    }

    ordenarPorBurbujaYPowerNegative2(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.powerNegative2 > tempArray[index + 1].powerNegative2) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPowerNegative2(tempArray)
        }
        // Array ordenado
        return tempArray;
    }

    ordenarPorBurbujaYPowerNegative3(arrayDesordenado) {
        // Copia el array recibido
        let tempArray: any = arrayDesordenado;
        let volverAOrdenar = false;

        // Recorre el array
        for (let index = 0; index < tempArray.length; index++) {
            const valor = tempArray[index];
            if (tempArray.length - 1 != index) {
                if (valor.powerNegative3 > tempArray[index + 1].powerNegative3) {
                    // Intercambia la primera posición por la segunda
                    let primerNodo: number = tempArray[index];
                    let segundoNodo: number = tempArray[index + 1];
                    tempArray[index] = segundoNodo;
                    tempArray[index + 1] = primerNodo;
                    volverAOrdenar = true;
                }
            }
        }
        // Vuelve a llamar al función
        if (volverAOrdenar) {
            this.ordenarPorBurbujaYPowerNegative3(tempArray)
        }
        // Array ordenado
        return tempArray;
    }




}


export class ExplicacionPrototype {
    id: any;
    idOriginal:any;
    label: any;
    quiz: any | [];
    power0:boolean;
    power1:boolean;
    power2:boolean;
    power3:boolean;
    powerNegative1:boolean;
    powerNegative2:boolean;
    powerNegative3:boolean;
    numPaginas: any[];
    /**
     *
     */
    constructor(currentExp) {

        this.id = currentExp.id;
        this.idOriginal = currentExp.idOriginal;
        this.label = currentExp.label;
        this.quiz = [];

        this.power0 = currentExp.power0;
        this.power1 = currentExp.power1;
        this.power2 = currentExp.power2;
        this.power3 = currentExp.power3;
        this.powerNegative1 = currentExp.powerNegative1;
        this.powerNegative2 = currentExp.powerNegative2;
        this.powerNegative3 = currentExp.powerNegative3;


    }
}
