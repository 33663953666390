import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialog-cookies',
    templateUrl: './dialog-cookies.component.html',
    styleUrls: ['./dialog-cookies.component.scss'],
    standalone: false
})
export class DialogCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
