<nb-card class="mb-0 no-edge-card modal-height-100-62 ">
	<nb-card-header class="border-bottom-0">
		<div class="col-11 modal-title">
			<h2>{{ 'MENUACTIONS.TEACHERTITLE10' | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<nb-tabset fullWidth>
			<!-- Ajustes padres -->
			<nb-tab tabTitle="Ajustes padres" class="p-0">
				<app-loading [isVisible]="cargando"></app-loading>
				<app-lista-padres *ngIf="screenView === 1" [profile]="profile" (action)="changeScreen($event)">
				</app-lista-padres>
				<app-nuevo-padre *ngIf="screenView === 2" [profile]="profile" (action)="changeScreen($event)">
				</app-nuevo-padre>
				<app-invitar-padre *ngIf="screenView === 3" (action)="changeScreen($event)">
				</app-invitar-padre>
			</nb-tab>
			<!-- Ajustes Agente IA -->
			<nb-tab tabTitle="Ajustes Agente IA" class="p-0">
				<form [formGroup]="agentSettingsForm">
					<div class="container">
						<div class="d-flex justify-content-center flex-column gap-4">
							<!-- Avatar del agente IA -->
							<div class="d-flex justify-content-center align-items-center mt-4" *ngIf="userAgentInfo">
								<app-image-agent-profile [image]="userAgentInfo.profilePhotoUrl" [idAgent]="userAgentInfo.id"
									(imageChange)="refreshImage($event)">
								</app-image-agent-profile>
							</div>
							<div class="d-flex justify-content-center flex-column gap-3">
								<div class="d-flex justify-content-between align-items-center gap-5">
									<!-- genre selector -->
									<div class="d-flex justify-content-center flex-column w-100">
										<label for="genre">Género</label>
										<select class="form-control" id="genre" name="genre" formControlName="genre">
											<option value="male">Male</option>
											<option value="female">Female</option>
										</select>
									</div>
									<!-- voice selector depending on genre-->
									<div class="d-flex justify-content-center flex-column w-100">
										<ng-container *ngIf="agentSettingsForm.get('genre').value === 'male'; else femaleTemplate">
											<label for="voice">Select Voice:</label>
											<select class="form-control" id="voice" name="voice" formControlName="voice">
												<option *ngFor="let voice of maleVoices" [value]="voice">{{ voice }}</option>
											</select>
										</ng-container>

										<ng-template #femaleTemplate>
											<label for="voice">Select Voice:</label>
											<select class="form-control" id="voice" name="voice" formControlName="voice">
												<option *ngFor="let voice of femaleVoices" [value]="voice">{{ voice }}</option>
											</select>
										</ng-template>
									</div>
								</div>
								<!-- Prompt Editor -->
								<div class="d-flex justify-content-center flex-column">
									<label for="prompt">Edit Prompt:</label>
									<textarea class="form-control text-area-height" id="prompt" formControlName="prompt">
									</textarea>
								</div>
							</div>
						</div>
					</div>
				</form>
			</nb-tab>
		</nb-tabset>
	</nb-card-body>
</nb-card>
