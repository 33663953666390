import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-upload-progress-bar',
    templateUrl: './upload-progress-bar.component.html',
    styleUrls: ['./upload-progress-bar.component.scss'],
    standalone: false
})
export class UploadProgressBarComponent implements OnInit {
  @Input() progressValue: number;

  constructor() { }

  ngOnInit() {
  }

}
