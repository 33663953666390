import { UsersService } from './../../../../core/services/users/users.service';
import { User } from '../../../../core/models/users/user.models';
import { CourseTargetModel } from '../../../../core/models/courses/course-target.model';
import { ImagenPipe } from '../../../pipes/imagen.pipe';
import { ErrorStateMatcher } from '@angular/material/core';
import { IdiomaModel } from '../../../../core/models/masters/idioma.model';
import { NivelModel } from '../../../../core/models/masters/nivel.model';
import { MateriaModel } from 'src/app/core/models/masters';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, NgForm, FormGroupDirective, Validators, UntypedFormGroup } from '@angular/forms';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Models
import { CourseModel } from 'src/app/core/models/courses';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { TargetsService } from 'src/app/core/services/targets';
import { LoginService } from 'src/app/core/services/login';
import { AlertService } from 'src/app/core/services/shared';

import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { Utils } from 'src/app/core/utils/utils';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"

export class ParentGroupValidationStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched));
        const invalidParent = !!(control && (control.dirty || control.touched) && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-modal-ver-curso',
    templateUrl: './modal-ver-curso.component.html',
    styleUrls: ['./modal-ver-curso.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        ImagenPipe
    ],
    standalone: false
})
export class ModalVerCursoComponent implements OnInit {

    public progress: number = 0
    public graphView:string = 'gridList'
    public modoTraerNodo = false;
    public modoTraerActividad = false;
    // id: string;
    curso: CourseModel
    courseTargets: CourseTargetModel[] = []
    idImage: string = ''
		public formulario: UntypedFormGroup;
    user: User
    saving: boolean = false
		exclusiveCourseTargets: CourseTargetModel[] = []
    matcher = new ParentGroupValidationStateMatcher();

    // Maestros
    materias: MateriaModel[] = [];
    niveles: NivelModel[] = [];
    idiomas: IdiomaModel[] = [];

    cargando: boolean = false;

    scrolled: boolean = false;
    openAssociated = false;

    validationMessages = {
        title: '',
        description: '',
        subject: '',
        lang1: '',
        level: '',
        image: ''
    };

    cursoDatos = {
        title: '',
        description: '',
        subject: '',
        lang1: '',
        level: '',
        image: '',
        user : {
            name : '',
            surname : '',
            image : ''
        }
    };
    buscarNodo: any;

		@Input() id: string;
		@Input() showModalFormat: boolean = true
		isOpenInfoCourse: boolean;
		public formularioInfo: UntypedFormGroup;

		options:any
		exclusiveOptions:any
		clickSidebar: any;

    constructor(public router: Router,
        public translateService: TranslateService,
        public coursesService: CoursesService,
        public targetsService: TargetsService,
        private getDataService: GetDataService,
        public loginService: LoginService,
        private alertService: AlertService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private imagePipe: ImagenPipe,
        public userService:UsersService,
        private utils:Utils,
				private formBuild: UntypedFormBuilder,
    ) {
			this.createForm();
    }

    updateUrl(event: any) {
        event.target.src = '../../assets/images/no-image.png'
    }

    ngOnInit() {

        this.cargando = true;
        this.user = this.loginService.getUser()
        this.materias = this.getDataService.appSubjects
				this.createForm()
        this.obtenerDatosMaestros();
				this.obtenerDatosCurso(this.id);
        // if (this.id !== 'nuevo') {
        //     // Si no es nuevo busco sus datos
        //     this.obtenerDatosCurso(this.id);
        // }
        // else
        //     this.cargando = false;
    }

    isMine(): boolean {
        if (this.loginService.esAutor() && this.user.idUser === this.curso.user.idUser)
            return true
        return false
    }

		createForm() {

			this.formulario = this.formBuild.group({
				image: [{ value: '', disabled: false }],
				title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
				description: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
				subject: ['', [Validators.required]],
				level: [''],
				lang1: ['', [Validators.required]],
				published : [false],
				isVisible : [false],
				courseType : [false]
			})
		}

    obtenerDatosMaestros() {
        this.idiomas = this.getDataService.appLanguages
        this.niveles = this.getDataService.appLevels
    }


    obtenerDatosCurso(id) {

        this.cargando = true;
				this.coursesService.getCourseById(id).subscribe(response => {
					this.curso = response.data.courses as CourseModel // Course detail
					this.orderCoursesTargets(response.data.coursesTarget)
					this.formulario.patchValue({
						title: this.curso.courseTittle,
						description: this.curso.description,
						subject: this.curso.subject.idSubject,
						level: this.curso.courseSWLevel,
						lang1: this.curso.language.idLanguage,
						image: this.curso.cpicture ? this.imagePipe.transform(this.curso.cpicture, 'cursos') : '',
						published : this.curso.published ? true  : false,
						isVisible : this.curso.isVisible === 1 ? true : false,
						courseType : this.curso.courseType === 1 ? true : false,
						country: this.curso.idCountry,
						sonsNumber: this.curso.sonsNumber,
					})
					this.cargando = false;
					this.cursoDatos.user.name = this.curso.user.firstName;
					this.cursoDatos.user.surname = this.curso.user.surname;
					this.cursoDatos.user.image = this.curso.user.pictureUser;
			});
        this.coursesService.getCourseById(id).subscribe(response => {
            this.curso = response.data.courses as CourseModel // Course detail
            this.courseTargets = response.data.coursesTarget.filter((element: any) => {
                return element.target.published !== null;
            })

            this.cursoDatos = {
                title: this.curso.courseTittle,
                description: this.curso.description,
                subject: '',
                lang1: '',
                level: '',
                image: this.curso.cpicture ? this.imagePipe.transform(this.curso.cpicture, 'cursos') : '',
                user : {
                    name : this.curso.user.firstName,
                    surname : this.curso.user.surname,
                    image : this.curso.user.pictureUser
                }
            }
            this.cargando = false;
        });
		}


    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }
    // FIN CURSO

    // MAPAS
    verDetalleMapa(idMapa: number) {
        if (this.modoTraerNodo) {
            localStorage.setItem('modoTraerNodo', 'true')
        }
        else if (this.modoTraerActividad) {
            localStorage.setItem(LOCALSTORAGESTRINGS.GETACTIVITY, 'true')
        }
        this.buscarNodo = JSON.parse(localStorage.getItem('buscarActividad'));
        this.router.navigateByUrl(`/course/${this.curso.idCourse}/graph/${idMapa}`);
        this.closeModal('Abrir mapa');
    }
    getUserAvatar():string{
        return this.utils.getUserAvatar(this.cursoDatos.user.image)
    }
    getImageBackground(image:string|null):string{
        let noImg = '../../assets/images/no-image.png'
        if(!image)
            return `url('${noImg}')`
        return `url(${this.imagePipe.transform(image, 'mapas')})`
    }

		private orderCoursesTargets(data:CourseTargetModel[]){

			this.courseTargets = data.filter((element: CourseTargetModel) => {
				if (this.loginService.esAutor()) {
					return element.target && !element.target.exclusive;
				} else {
					return element.target.published !== null && !element.target.exclusive;
				}
			})

			this.exclusiveCourseTargets = data.filter((element: CourseTargetModel,index) => {
				return element.target.exclusive;
			})
		}
}
