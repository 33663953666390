import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'quizElements',
    standalone: false
})
export class QuizElementsPipe implements PipeTransform {

    transform(fileName: string, tipo: string): any {

        if (!fileName) return;

        if (fileName.includes('blob')) {
            return fileName;
        }

        let url = `${environment.quizzesContent}`;

        switch (tipo) {
            case 'image':
                url += 'picture/';
                break;
            case 'video':
                url += 'video/';
                break;
            case 'audio':
                url += 'audio/';
                break;
            case 'pdf':
                url += 'pdf/';
                break;
        }
        return url += `${fileName}`
    }

}
