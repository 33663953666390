import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-solar-centro',
    templateUrl: './solar-centro.component.html',
    styleUrls: ['./solar-centro.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SolarCentroComponent implements OnInit{
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];
	auxData: any[] = [];
	step: number = 0;
	title1: string = '';
	title2: string = '';

	parametros: any[] = [
		{ id: 1, title: this.translateService.instant("CENTRO.ACIERTOS")},
		{ id: 2, title: this.translateService.instant("CENTRO.CONOCIMIENTO")},
		{ id: 3, title: this.translateService.instant("CENTRO.TRABAJO")},
		{ id: 4, title: this.translateService.instant("CENTRO.CONSTANCIA")},
		{ id: 5, title: this.translateService.instant("CENTRO.CARGA")},
		{ id: 6, title: this.translateService.instant("CENTRO.SOCIALIZACION")},
	];

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.consultaID !== null) {
			if(this.parametroID == 1){
				//this.datasets.push(this.calcularPromedios(this.datasetsImported));
				//this.auxData = this.datasetsImported;
				//this.labels = this.labelsImported;
				this.labels = this.obtenerLabels(this.datasetsImported, 0);
				this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0), this.obtenerLabelsConEstructura(this.datasetsImported, 1)];
				switch (this.consultaID){
					case 1:
						this.title1 = 'Niveles';
						this.title2 = 'Profesores';
						break;
					case 2:
						this.title1 = 'Asignaturas';
						this.title2 = 'Profesores';
						break;
					case 3:
						this.title1 = 'Niveles';
						this.title2 = 'Asignaturas';
						break;
					case 4:
						this.title1 = 'Niveles';
						this.title2 = 'Grupos';
						break;
					case 5:
						this.title1 = 'Asignaturas';
						this.title2 = 'Grupos';
						break;
					case 6:
						this.title1 = 'Grupos';
						this.title2 = 'Alumnos';
						break;
					case 7:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
					case 8:
						this.title1 = 'Grupos';
						this.title2 = 'Alumnos';
						break;
					case 9:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
				}
			} else if(this.parametroID == 2){
				switch (this.consultaID){
					case 9:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
				}
			}
    }
		setTimeout(() =>	{
			this.createChart();
			this.step = 1; //mostramos el primer paso del informe
		}, 200);
  }

	calcularPromedios(dataSets) {
    const numDatasets = dataSets.length;
    const numDataPoints = dataSets[0].data.length;
    const promedios = new Array(numDataPoints).fill(null).map(() => 0);
    const count = new Array(numDataPoints).fill(0);
    dataSets.forEach(profesor => {
        profesor.data.forEach((value, index) => {
            if (value !== null && value !== 0) {
                promedios[index] += value;
                count[index] += 1;
            }
        });
    });
    const promedioData = promedios.map((total, index) => {
        return count[index] > 0 ? parseFloat((total / count[index]).toFixed(1)) : null;
    });
    const backgroundColor = promedioData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: promedioData,
        backgroundColor: backgroundColor,
    };
	}

	colorKnowledge(valor: number): string {
    let color = "grey";
    if (valor == 0) color =  "#9A9B9C";
    else if (valor > 0 && valor < 11) color = "#FB0D1C";
    else if (valor < 21) color = "#FC5C20";
    else if (valor < 31) color = "#FC9A27";
    else if (valor < 41) color = "#FEE533";
    else if (valor < 51) color = "#FFFD38";
    else if (valor < 61) color = "#D4FD35";
    else if (valor < 71) color = "#9CFD32";
    else if (valor < 81) color = "#29F82E";
    else if (valor < 91) color = "#2AD52D";
    else if (valor < 101) color = "#1AAF54";
    return color;
  }

	colorDataset(valor: number, index: number): string {
    let color = "grey";
    if(index == 0){
        // Escala de azules (de oscuro a claro)
        if (valor == 0) color =  "#003B64";  // Azul más oscuro
        else if (valor > 0 && valor < 11) color = "#01579B";  // Azul profundo
        else if (valor < 21) color = "#0277BD";  // Azul muy oscuro
        else if (valor < 31) color = "#0288D1";  // Azul oscuro
        else if (valor < 41) color = "#039BE5";  // Azul más oscuro
        else if (valor < 51) color = "#03A9F4";  // Azul medio
        else if (valor < 61) color = "#29B6F6";  // Azul más claro
        else if (valor < 71) color = "#4FC3F7";  // Azul intermedio
        else if (valor < 81) color = "#81D4FA";  // Azul medio claro
        else if (valor < 91) color = "#B3E5FC";  // Azul claro
        else if (valor < 101) color = "#E0F7FA"; // Azul muy claro
    } else if(index == 1){
				// Escala de violetas (de oscuro a claro)
				if (valor == 0) color =  "#4A0072";  // Violeta más oscuro
				else if (valor > 0 && valor < 1.1) color = "#6A1B9A";  // Violeta profundo
				else if (valor < 2.1) color = "#7B1FA2";  // Violeta muy oscuro
				else if (valor < 3.1) color = "#8E24AA";  // Violeta oscuro
				else if (valor < 4.1) color = "#9C27B0";  // Violeta
				else if (valor < 5.1) color = "#AB47BC";  // Violeta medio
				else if (valor < 6.1) color = "#BA68C8";  // Violeta intenso
				else if (valor < 7.1) color = "#CE93D8";  // Violeta intermedio
				else if (valor < 8.1) color = "#E1BEE7";  // Violeta medio claro
				else if (valor < 9.1) color = "#F3E5F5";  // Violeta claro
				else if (valor < 10.1) color = "#F8EAF6"; // Violeta muy claro
    }
    return color;
	}

	obtenerLabels(dataSets, index) {
		const filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
    const filteredData = dataSets
        .map(info => info.data[index])
        .filter(value => value !== null);
    const backgroundColor = filteredData.map(value => this.colorDataset(value, index));
    return {
        label: this.parametros.find(p => p.id === index + 1).title,
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}

	createChart(){
    var canvas = document.getElementById('myChartControl2') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (this.chart) {
        this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: this.labels,
            datasets: this.datasets
        },
				options: {
					responsive: true,
					plugins: {
						legend: {
							display: false,
							position: 'right',
						},
						title: {
							display: false,
							text: this.title2,
							font: {
								size: 20  // Tamaño de la fuente del título
							},
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									const label = context.dataset.label || ' ';
									const dato = context.raw;
									return [
										`${label}`,
										`${dato}`
									];
								}
							}
						}
					},
					/**
					onClick: (event, elements) => {
						if (elements.length > 0) {
							if(this.step == 1){
								this.actualizarChart(elements[0].index);
							}
						}
					}
					*/
				},
    });
	}

}
