import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Models
import { DetailCourseTargetModel, ResponseCourseTargetDetail, ResponseCourseTargets } from 'src/app/core/models/courses';


const CREATETARGET = 'target/courses/$1/graphs'
const GETTARGETSBYCOURSE = 'course/getListCourseByIdCourse'
const GETTARGET = 'target/graphs/$1'
const OPENGRAPH= 'target/registercountopengraph'
const ORDERGRAPH = 'course/changedPosition'

@Injectable({
  providedIn: 'root'
})
export class TargetsService {

  constructor(private http: HttpClient) { }


  /**
   * Function that returns the detail of the target
   * @param idTarget The id of target
   * @returns An Observable of ResponseCourseTargetDetail
   */
  getTargetById(idTarget: number): Observable<ResponseCourseTargetDetail> {
    return this.http.get<ResponseCourseTargetDetail>(GETTARGET.replace('$1', idTarget.toString()))
  }
  
  getCourseTargetByIdTarget(idTarget: number):Observable<any> {
	const url:string = 'target/getCourseTargetByIdTarget';
	return this.http.get<any>(`${url}/${idTarget}`);
  }

  getNodesWithoutElements(idTarget: number):Observable<any> {
	const url:string = 'target/getNodesWithoutElements';
	return this.http.get<any>(`${url}/${idTarget}`);
  }

  /**
   * Function that detele a target
   * @param idTarget The id of target
   * @returns An Observable. Get HTTP CODE 200 to delete OK
   */
  deleteTarget(idTarget: number): Observable<any>{
    return this.http.delete(GETTARGET.replace('$1', idTarget.toString()))
  }

  /**
   * Function that creates or updates a target
   * @param target DetailCourseTargetModel object, with target info
   * @param file Image to upload
   * @param idCourse Id of the course to which that target belongs
   * @returns An Observable. Get HTTP CODE 200 to create or update OK
   */
  setTarget(target: DetailCourseTargetModel, file: File | string, idCourse:number): Observable<any> {

    let form: FormData = new FormData()
    form.append('target', JSON.stringify(target))
    form.append('files', file)
    return this.http.post<any>(CREATETARGET.replace('$1',idCourse.toString()),form, {
      reportProgress: true,
      observe: 'events'
    })
  }

  /**
   * Function that returns a list with the targets of the course
   * @param idCourse Course id
   * @returns An Observable of ResponseCourseTargets
   */
  getCourseTargetByIdCourse(idCourse: number): Observable<ResponseCourseTargets> {
    let httpParams = new HttpParams().set('idCourse', idCourse.toString())
    return this.http.get<ResponseCourseTargets>(GETTARGETSBYCOURSE, {params: httpParams})
  }

  updateGraphOrder(idCourse:number, currentPosition:number, updatePosition:number, exclusive:boolean):Observable<any>{
    const body:FormData = new FormData()
    body.append('idCourse', String(idCourse))
    body.append('currentPosition', String(currentPosition))
    body.append('updatePosition', String(updatePosition))
		body.append('exclusive', String(exclusive))
    return this.http.post<any>(ORDERGRAPH, body)
  }

	setExclusiveGraph(idGraph:number, value:boolean): Observable<any>{
		return this.http.put(`target/exclusivetarget/${idGraph}/${value}`, {})
	}

	getListMultiplesByTarget(idCourse: number, idTarget: number):Observable<any> {
		const url:string = 'target/getListMultiplesByTarget';
		return this.http.get<any>(`${url}/${idCourse}/${idTarget}`);
	}

	getListTargetQuizzesMultiplesActiveByTarget(idTarget: number, idGroup: number):Observable<any> {
		const url:string = 'target/getListTargetQuizzesMultiplesActiveByTarget';
		return this.http.get<any>(`${url}/${idTarget}/${idGroup}`);
	}

	changeTargetQuizzesMultiplesActiveByTarget(index: string, change: number, idTarget: number, idCourse: number, idGroup: number):Observable<any> {
		const url:string = 'target/changeTargetQuizzesMultiplesActiveByTarget';
		const body = {
			index: index,
			change: change,
			idTarget: idTarget,
			idCourse: idCourse,
			idGroup: idGroup,
		}
		return this.http.post<any>(url, body)
	}

	registercountopengraph(): Observable<any>{
		return this.http.put(OPENGRAPH, {})
	}

	moveTargetToOtherProject(idCourseOld: number, idCourseNew: number, idTarget: number):Observable<any> {
		const url:string = 'target/moveTargetToOtherProject';
		return this.http.get<any>(`${url}/${idCourseOld}/${idCourseNew}/${idTarget}`);
	}

	copyTargetToOtherProject(idCourseOld: number, idCourseNew: number, idTarget: number): Observable<any>{
		return this.http.put(`target/copytargettootherproject/${idCourseOld}/${idTarget}/${idCourseNew}`, {})
	}

	translateTarget(langOut: string, idTarget: number):Observable<any>{
		return this.http.get(`target/translateTarget/${langOut}/${idTarget}`)
	}

	lockTargetForTranslate(idTarget: number): Observable<any>{
		return this.http.get(`target/lockTargetForTranslate/${idTarget}`)
	}

		/**
	 * METODO PARA OBTENER LISTADO DE GRAFOS DE UN CURSO QUE EL ESTUDIANTE HA REALIZADO SIQUIERA UNO DE SUS QUIZZES
	 * @author jdela
	 */
	getTargetListResolved(idCourse: number):Observable<any> {
		const url:string = 'target/getTargetListResolved';
		return this.http.get<any>(`${url}/${idCourse}`);
	}

	createTaskPensarCreando(idGroup: number, idTarget: number, idCourse: number, idCourseNew: number):Observable<any> {
		const url:string = 'target/createTaskPensarCreando';
		const body = {
			idGroup: idGroup,
			idTarget: idTarget,
			idCourse: idCourse,
			idCourseNew: idCourseNew,
		}
		return this.http.post<any>(url, body)
	}

	gettargetpensarcreandobyidcourse(idCourse: number):Observable<any> {
		const url:string = 'target/gettargetpensarcreandobyidcourse';
		return this.http.get<any>(`${url}/${idCourse}`);
	}

}
