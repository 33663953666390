import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { MastersService } from "src/app/core/services/masters";
import { LoginService } from "src/app/core/services/login";
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalSchedulesComponent } from "../modal-schedules/modal-schedules.component";
import { ModalStructureComponent } from "../modal-structure/modal-structure.component";
import { finalize, forkJoin } from 'rxjs';
import { ReportService } from 'src/app/core/services/report/report.service';
import { ChatAssistantComponent } from '../chat-assistant/chat-assistant.component';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { Utils } from 'src/app/core/utils/utils';
import { CenterService } from 'src/app/core/services/center/center.service';
import { ModalAceptarCancelarComponent } from '../modal';

@Component({
    selector: 'app-control-panel-padres',
    templateUrl: './control-panel-padres.component.html',
    styleUrls: ['./control-panel-padres.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ControlPanelPadresComponent implements OnInit {
	profile: any;
	isLoading: boolean;

	constructor(
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		private modalService: NgbModal,
		public loginService: LoginService,
		private mastersService: MastersService,
		private reportService: ReportService,
		private utils: Utils,
		private centerService: CenterService
	) {}

	ngOnInit() {
		this.profile = this.loginService.getProfile();
		//elimino el registro del grupo seleccionado como profe o estudiante
		localStorage.removeItem("selectedGroupInfo");
	}

	ngOnDestroy() {}

	closeModal(sendData?: any) {
		this.activeModal.close();
	}
}
