import { C } from '@angular/cdk/focus-monitor.d-810a02e6';
import { NodeService } from './../../../core/services/node/node.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { PowerService } from 'src/app/core/services/power/power.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { UsersService } from 'src/app/core/services/users';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { Profiles } from 'src/app/core/utils/profiles.enum';

@Component({
    selector: "app-pad-center",
    templateUrl: "./pad-center.component.html",
    styleUrls: ["./pad-center.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PadCenterComponent implements OnInit, OnDestroy {
	@Output() modeAuto = new EventEmitter<boolean>();
	@Output() changeViewType = new EventEmitter<Object>();
	@Input() isShow: boolean;
	@Input() isShowLabels: boolean = false;
	@Input() idCourse: number;
	@Input() courseName: string;
	@Input() graphTitle: string;
	@Input() alertCloseQuiz: boolean = false;
	@Input() quiz: any;
	@Input() courseId: any;
	@Input() graphId: any;
	@Input() nodeView: boolean = false;
	@Input() node: any;

	_colors: Array<string> = ["grey", "red", "yellow", "lgreen", "dgreen"];

	_topButton: string = "";
	_leftButton: string = "";
	_rightButton: string = "check_graph";
	_bottomButton: string = "ActividadIdea";
	viewCopilotPadLeft: boolean;
	// courseId: number;
	// graphId: number;
	@Input() canEdit: boolean;
	gotoText: string = "";
	isEditor: boolean = false;
	profile: any;
	user: User;
	profileSelected: Profiles;
	profileTitle: string;
	selectedGroupInfo: any;
	idGrupo: number;
	lessPowerNodeTittle: string = "";
	lessPowerNodeId: any;
	lessOrdinalQuizzId: any;
	actualProfile: string;
	lessOrdinalQuizzTittle: string = "";
	private destroy$ = new Subject();
	quizTitleBar: string;
selectedButton: any;

	public esAutor() {
		return this.loginService.esAutor();
	}
	public esEstudiante() {
		return this.loginService.esEstudiante();
	}

	constructor(
		private toolsService: SigmaToolbarsService,
		private quizService: QuizzesService,
		private loginService: LoginService,
		private padsUtils: PadsUtils,
		private powerServ: PowerService,
		public userService: UsersService,
		public MastersService: MastersService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		public activeModal: NgbActiveModal,
		public NodeService: NodeService
	) {}

	ngOnInit() {
		this.user = this.loginService.getUser();
		this._isEditor();
		this.actualProfile = this.loginService.getProfile();
		if (this.actualProfile == "AUTOR") {
			this.profileTitle = "EDITOR";
			this.gotoText = "Editar";
		} else {
			this.profileTitle = this.profile;
			this.gotoText = "Proyectos";
		}
		this.powerChanged();
		this.subscribeProfile();
		if (this.loginService.esEstudiante()) this._bottomButton = "ActividadIdea";
		this.setSelectedGroupInfo();
		this.idGrupo = this.selectedGroupInfo
			? this.selectedGroupInfo.idGroup
			: null;
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		if (this.nodeView == true) {
			this.lessPowerNodeTittle = this.node.label;
			this.getLessOrdinalQuiz(
				this.courseId,
				this.graphId,
				this.node.idOriginal
			);
		} else {
			if (this.quiz !== undefined && this.quiz.length > 0) {
				//this.getNodesLessPower(this.courseId,this.graphId,this.quiz);
				if (this.quiz.idMultiplexQuiz === 0) {
					this.quizTitleBar = this.quiz.quizTittle;
				} else {
					this.quizService
						.getQuiz(this.quiz.idQuizFather, this.courseId, this.graphId)
						.subscribe((res) => {
							this.quizTitleBar = res.quiz.quizTittle;
						});
				}
			}
		}
	}

	ngDoCheck(): void {
		//Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
		//Add 'implements DoCheck' to the class.
		this.setSelectedGroupInfo();
		this.profile = this.loginService.getProfile();
	}

	getLessOrdinalQuiz(courseId, graphId, idNode) {
		this.quizService
			.getQuizLessOrdinal(courseId, graphId, idNode)
			.subscribe((res) => {
				this.lessOrdinalQuizzTittle = res ? res.tittle : "";
				this.lessOrdinalQuizzId = res ? res.idQuiz : null;
			});
	}

	getNodesLessPower(courseId, graphId, quiz) {
		let idQuizAux = quiz.idQuizFather != 0 ? quiz.idQuizFather : quiz.idQuiz;
		this.quizService
			.getNodesLessPower(courseId, graphId, idQuizAux)
			.subscribe((res) => {
				this.lessPowerNodeTittle = res ? res.tittle : "";
				this.lessPowerNodeId = res ? res.idNode : null;
			});
	}

	powerChanged() {
		//  this.powerServ.powerActive.subscribe(res => {
		//       this._bottomButton  = 'ActividadIdea'
		//  })
	}

	subscribeProfile() {
		this.loginService.currentProfile.subscribe((val) => {
			this._bottomButton = "ActividadIdea";
		});
	}

	/** LEFT PAD */

	setKnowledge(color: string) {
		this._topButton = color;
		this.padsUtils.vibratePad();
	}

	public viewNodes() {
		if (this.alertCloseQuiz == true) {
			this.NodeService.getNode(
				this.lessPowerNodeId,
				this.courseId,
				this.graphId
			).subscribe((res) => {
				if (this.nodeView == true) {
					//this.NodeService.setRedirectedNode(this.node);
				} else {
					this.NodeService.setRedirectedNode(res.data[0]);
				}
				this.activeModal.close();
			});
		} else {
			this.changeViewType.emit({ nodes: true, quizzes: false });
			this._bottomButton = "ideasvg";
			this.padsUtils.vibratePad();
		}
	}

	public viewQuizzes() {
		if (this.alertCloseQuiz == true && this.lessOrdinalQuizzId != undefined) {
			this.quizService
				.getQuizSimpleCanvasQuizSimple(
					this.lessOrdinalQuizzId,
					this.courseId,
					this.graphId
				)
				.pipe(takeUntil(this.destroy$), take(1))
				.subscribe((res) => {
					if (this.nodeView == true) {
						this.NodeService.setRedirectedQuiz(res.quiz);
						this.activeModal.close();
					} else {
						//this.NodeService.setRedirectedNode(res.data[0]);
					}
				});
		} else {
			this.changeViewType.emit({ nodes: false, quizzes: true });
			this._bottomButton = "inte";
			this.padsUtils.vibratePad();
		}
	}

	public viewAll() {
		if (this.alertCloseQuiz == true) {
			this.activeModal.close();
			this.changeViewType.emit({ nodes: true, quizzes: true });
			this.toolsService.changeVisibility({ nodes: true, quizzes: true });
			this._bottomButton = "ActividadIdea";
			this.padsUtils.vibratePad();
		} else {
			this.changeViewType.emit({ nodes: true, quizzes: true });
			this._bottomButton = "ActividadIdea";
			this.padsUtils.vibratePad();
		}
	}

	selectAtomatic() {
		this.quizService.setAutomatic(true);
		this._rightButton = "automatic";
		this.modeAuto.emit(true);
		this.padsUtils.vibratePad();
		this.toolsService.setHideElements();
	}

	selectManual() {
		this.quizService.setAutomatic(false);
		this._rightButton = "manual";
	}

	emitshowModalCourse(value:string, buttonName: string) {
		this.selectedButton = buttonName;
		if(this.alertCloseQuiz == true){
			this.activeModal.close();
			this.toolsService.changeShowModalCourses(value);
			this.padsUtils.vibratePad();
			this.toolsService.setHideElements();
			// const dialogRef = this.dialogService.openAcceptDialog(
			// 	true,
			// 	this.translateService.instant('QUIZZES.CLOSETITLE'),
			// 	this.translateService.instant('QUIZZES.GOIDEOGRAPHS'),
			// 	[],
			// 	this.translateService.instant('QUIZZES.EXIT'),
			// 	this.translateService.instant('QUIZZES.RESPONSE')
			// );
			// dialogRef.afterClosed().subscribe(result => {
			// 	//this.tryClose.next(result);
			// 	if (result) {
			// 		this.activeModal.close();
			// 		this.toolsService.changeShowModalCourses(value);
			// 		this.padsUtils.vibratePad();
			// 		this.toolsService.setHideElements();
			// 	}
			// });
		}else{
			this.toolsService.changeShowModalCourses(value);
			this.padsUtils.vibratePad()
			this.toolsService.setHideElements()
		}
	}

	_isEditor() {
		this.userService.getListUserProfile().subscribe((res) => {
			let profiles = res.data;
			for (let i = 0; i < profiles.length; i++) {
				if (profiles[i].idProfile === 1) {
					this.isEditor = true;
					break;
				}
			}
		});
	}

	setSelectedGroupInfo() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if (storedGroupInfo) {
			if (storedGroupInfo.idUser != this.user.idUser) {
				localStorage.removeItem("selectedGroupInfo");
			}
		}
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
	}

	goModule(module: string, buttonName: string) {
		this.selectedButton = buttonName;
		if(this.alertCloseQuiz == true){
			this.activeModal.close();
			switch (module) {
				case 'Proyectos':
					if (this.esEstudiante()) {
						this.MastersService.verListadoCursos(null, this.profile, 0);
					} else {
						this.MastersService.verListadoCursos(null, this.profile, this.idGrupo);
					}
					break;
				case 'Editar':
					//localStorage.setItem('dontLoadMenu', 'true'); //Este provoca que no se muestre el menú al cambiar de perfil --> REVISAR REY
					this.MastersService.verListadoCursos('editar', Profiles.Author);
					break;
			}
			// const dialogRef = this.dialogService.openAcceptDialog(
			// 	true,
			// 	this.translateService.instant('QUIZZES.CLOSETITLE'),
			// 	this.translateService.instant('QUIZZES.GOPROJECT'),
			// 	[],
			// 	this.translateService.instant('QUIZZES.EXIT'),
			// 	this.translateService.instant('QUIZZES.RESPONSE')
			// );
			// dialogRef.afterClosed().subscribe(result => {
			// 	//this.tryClose.next(result);
			// 	if (result) {
			// 		this.activeModal.close();
			// 		switch (module) {
			// 			case 'Proyectos':
			// 				if (this.esEstudiante()) {
			// 					this.MastersService.verListadoCursos(null, this.profile, 0);
			// 				} else {
			// 					this.MastersService.verListadoCursos(null, this.profile, this.idGrupo);
			// 				}
			// 				break;
			// 			case 'Editar':
			// 				//localStorage.setItem('dontLoadMenu', 'true'); //Este provoca que no se muestre el menú al cambiar de perfil --> REVISAR REY
			// 				this.MastersService.verListadoCursos('editar', Profiles.Author);
			// 				break;
			// 		}
			// 	}
			// });
		}else{
			switch (module) {
				case 'Proyectos':
					if (this.esEstudiante()) {
						this.MastersService.verListadoCursos(null, this.profile, 0);
					} else {
						this.MastersService.verListadoCursos(null, this.profile, this.idGrupo);
					}
					break;
				case 'Editar':
					//localStorage.setItem('dontLoadMenu', 'true'); //Este provoca que no se muestre el menú al cambiar de perfil --> REVISAR REY
					this.MastersService.verListadoCursos('editar', Profiles.Author);
					break;
			}
		}
	}

	ngOnDestroy() {}
}
