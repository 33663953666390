import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { isPlatformBrowser } from "@angular/common";
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
	Inject,
	PLATFORM_ID,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { ModelIcono } from "src/app/core/models/courses/icono.model";
import { NodeFilesModel } from "src/app/core/models/nodes";
import { NodeFilesFormat } from "src/app/core/models/nodes/node-files-format.model";
import { Operator } from "src/app/core/models/operators/operator.model";
import { LoginService } from "src/app/core/services/login";
import { NodeService } from "src/app/core/services/node/node.service";
import { SnapshotService } from "src/app/core/services/snapshot/snapshot.service";
import {
	AVAILABLE_TYPE_FILES,
	FILE_FORMATS,
} from "src/app/core/utils/file-formats";
import { Utils } from "src/app/core/utils/utils";
import { environment } from "src/environments/environment";
import { URL_TIKTOK } from "src/app/core/models/masters/masters.enum";
import { TimerService } from "src/app/core/services/timer/timer.service";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import {
	NgbModal,
	NgbActiveModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { GruposService } from "src/app/core/services/groups/grupos.service";
import { NodeElementsHistorialModel } from "src/app/core/models/nodes/node-elements-historial.model";
import { ModalAlertAsistenciaComponent } from "../../../../modal-profile-actions/modal-alert-asistencia/modal-alert-asistencia.component";
import { LocalStorage } from "src/app/core/utils";

declare var window;
declare var $;
const HEADERHEIGHT: number = 1;

@Component({
    selector: "app-node-detail-files",
    templateUrl: "./node-detail-files.component.html",
    styleUrls: ["./node-detail-files.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class NodeDetailFilesComponent implements OnInit, OnDestroy {
	@Input() modeAuto: boolean = false;
	@Input() showFilesContainer: boolean = null;
	@Input() inicial;
	@Input() node;
	@Input() mensaje: string;
	@Input() onSaveClicked$: Observable<any>;
	@Input() canEdit: boolean = false;
	@Input() adjustWindow: boolean = false;
	@Input() showInContainer: boolean = false;
	@Input() isFooterOpen: boolean = true;
	@Output() nodeFilesTypeChanged: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() nodeFileAdded: EventEmitter<any> = new EventEmitter<any>();
	@Output() fileSelected: EventEmitter<NodeFilesFormat> =
		new EventEmitter<NodeFilesFormat>();
	@Output() onHoverElements: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@ViewChild("audioPlayer") audioPlayer: ElementRef;
	speedIndex = 0;
	showFullImage: boolean = false;

	// Array of available playback speeds for the audio element
	speeds = [1.0, 0.5];
	sanitizedUrl!: SafeResourceUrl;
	ext: string = "";

	changeSpeed() {
		// Get the native HTML audio element
		const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
		// Increment the speed index and loop back to the beginning if needed
		this.speedIndex = (this.speedIndex + 1) % this.speeds.length;
		// Set the playbackRate property of the audio element to the selected speed
		audio.playbackRate = this.speeds[this.speedIndex];
	}

	FILES_URL = environment.remoteFilesUrl + "nodeContent";

	AUDIO_TYPE = FILE_FORMATS.AUDIO_TYPE;
	IMAGE_TYPE = FILE_FORMATS.IMAGE_TYPE;
	PDF_TYPE = FILE_FORMATS.PDF_TYPE;
	TXT_TYPE = FILE_FORMATS.TXT_TYPE;
	VIDEO_TYPE = FILE_FORMATS.VIDEO_TYPE;

	cargando: boolean;
	videoSource: any;
	imageSource: string;
	audioSource: string;
	pdfSource: string;
	textSource: string;
	youtubeSource: string;
	tiktokSource: SafeResourceUrl;
	spotifySource: SafeResourceUrl;
	instagramSource: SafeResourceUrl;
	imagen: string;
	format: string;
	saveClickedSubscription: Subscription;
	windowWidth: number = 0;
	windowHeight: number = 0;

	videoId: string = "";
	startSeconds: number = null;
	endSeconds: number = null;
	isLoadFile: boolean = true;
	isMenuOpen: boolean = true;
	selectedGroupInfo: any;

	formats: NodeFilesFormat[] = [
		{
			id: 1,
			name: "NODEFORMCOMPONENT.VIDEO",
			type: this.VIDEO_TYPE,
			icon: "videocam",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "video/mov",
			progress: 0,
		},
		{
			id: 2,
			name: "NODEFORMCOMPONENT.IMAGE",
			type: this.IMAGE_TYPE,
			icon: "crop_original",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "image/*",
			progress: 0,
		},
		{
			id: 3,
			name: "NODEFORMCOMPONENT.AUDIO",
			type: this.AUDIO_TYPE,
			icon: "volume_up",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: "audio/*",
			progress: 0,
		},
		{
			id: 4,
			name: "NODEFORMCOMPONENT.FILE",
			type: this.PDF_TYPE,
			icon: "upload_file",
			edit: false,
			operatorIcons: [],
			visible: true,
			accept: AVAILABLE_TYPE_FILES.join(","),
			progress: 0,
		},
		{
			id: 5,
			name: "NODEFORMCOMPONENT.TEXT",
			type: this.TXT_TYPE,
			icon: "text_snippet",
			edit: false,
			operatorIcons: [],
			visible: true,
			progress: 0,
		},
	];

	iconosAdicionados: ModelIcono[] = [];
	@ViewChild("videoElement") videoPlayer;
	@ViewChild("horizontalForm") horizontalForm;

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.windowWidth = $(".form-horizontal").width();
		this.windowHeight = $(".form-horizontal").height() - HEADERHEIGHT;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		public loginService: LoginService,
		private nodeService: NodeService,
		private snapshotService: SnapshotService,
		private utils: Utils,
		private sanitizer: DomSanitizer,
		private timerService: TimerService,
		private localStorage: LocalStorage,
		private groupService: GruposService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal
	) {}

	ngOnInit() {
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);

		this.windowWidth = $(".form-horizontal").width();
		this.windowHeight = $(".form-horizontal").height() - HEADERHEIGHT;

		if (this.node !== undefined) this.loadFiles(this.node);

		this.saveClickedSubscription = this.onSaveClicked$.subscribe((res) =>
			this.saveText()
		);
		this.nodeService.currentUploadProgress.subscribe((res) => {
			this.formats.forEach((format) => {
				if (format.type === res.type) format.progress = res.value;
			});
		});

		if (this.loginService.esEstudiante() || this.loginService.esProfesor()) {
			this._playFirstFile();
		}
	}

	// Función para detectar iOS
	isIOS(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const ua = navigator.userAgent;
			return (
				/iPad|iPhone|iPod/.test(ua) ||
				(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
			);
		}
		return false;
	}

	openFullImage() {
		this.showFullImage = true;
	}

	closeFullImage() {
		this.showFullImage = false;
	}

	closeNode() {
		//si estamos como estudiante revisamos que no hayan pasado a lista en el grupo
		if (this.loginService.esEstudiante() && this.selectedGroupInfo) {
			this.llamadoAsistencia();
		}
		this.activeModal.close(this.node.idOriginal);
		//window.location.reload();
	}

	//con esto verificamos si hay algun llamado a asistencia pendiente por responder
	llamadoAsistencia() {
		this.groupService
			.receiveCallAttendance(this.selectedGroupInfo.idGroup, 2)
			.subscribe((resp) => {
				if (resp.data != null && resp.data[0].status == 1) {
					const modalRef = this.modalService.open(
						ModalAlertAsistenciaComponent,
						{
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W30,
						}
					);
					modalRef.componentInstance.idGrupo = this.selectedGroupInfo.idGroup;
					modalRef.componentInstance.group = this.selectedGroupInfo.group;
					modalRef.componentInstance.fechaLlamada = resp.data[0].fecha;
				}
			});
	}

	ngOnDestroy(): void {
		this.saveClickedSubscription.unsubscribe();
		this.saveNodeElementHistorial(
			this.localStorage.getItem("lastNodeElement"),
			this.localStorage.getItem("lastNodeElement"),
			true
		); //guardamos el historial de uso de los elementos del nodo
	}

	// onEnterElements(value){
	//     this.onHoverElements.emit(value)
	// }

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	/**
	 * Orden de reproducción de los ficheros laterales.
	 */
	private _playFirstFile(): void {
		const nodesFiles: NodeFilesModel = this.node.nodesFiles as NodeFilesModel;
		let type: string = localStorage.getItem("lastNodeElement")
			? localStorage.getItem("lastNodeElement")
			: "";
		// Si 'lastNodeElement' existe, debemos ver si el nodo actual lo tiene para abrir el mismo elemento
		// para eso creamos un array con los elementos que tiene el nodo y revisamos que lo tenga, si no lo tiene ponemos el primero de ese array
		let listNodeElements = [];
		if (
			nodesFiles.videoFile ||
			nodesFiles.videoYouTube ||
			nodesFiles.videoInstagram ||
			nodesFiles.videoTiktok
		) {
			listNodeElements.push("video");
		}
		if (nodesFiles.pdfFile) {
			listNodeElements.push("pdf");
		}
		if (nodesFiles.pictureFile) {
			listNodeElements.push("picture");
		}
		if (nodesFiles.audioFile || nodesFiles.spotifyAudio) {
			listNodeElements.push("audio");
		}
		if (nodesFiles.txtFile) {
			listNodeElements.push("text");
		}
		//listNodeElements.push('');
		let noExiste = true;
		for (let index = 0; index < listNodeElements.length; index++) {
			const element = listNodeElements[index];
			if (type === listNodeElements[index]) {
				noExiste = false;
				break;
			}
		}
		if (noExiste) {
			this.reproducirArchivo(listNodeElements[0]);
		} else {
			this.reproducirArchivo(type);
		}
	}

	private loadFiles(node) {
		const nodeFiles: NodeFilesModel = node.nodesFiles as NodeFilesModel;

		if (nodeFiles.pictureFile) {
			const imageFormat = this.formats.filter(
				(f) => f.type === this.IMAGE_TYPE
			)[0];
			imageFormat.edit = true;
			imageFormat.background =
				"url(" + this.fileUrl(this.IMAGE_TYPE, nodeFiles.pictureFile) + ")";
		}

		if (nodeFiles.videoFile) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.edit = true;
		}

		if (nodeFiles.videoImage) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.videoImage) +
				")";
		}

		if (nodeFiles.audioFile) {
			const audioFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			audioFormat.edit = true;
			audioFormat.background = "url(assets/images/nodes/audio.jpg)";
		}

		if (nodeFiles.spotifyAudio) {
			const audioFormat = this.formats.filter(
				(f) => f.type === this.AUDIO_TYPE
			)[0];
			audioFormat.edit = true;
			audioFormat.url = nodeFiles.spotifyAudio;
			audioFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.AUDIO_TYPE, nodeFiles.spotifyAudio) +
				")";
			audioFormat.idNodeFile = nodeFiles.idNodeFile;
		}

		if (nodeFiles.pdfFile) {
			const pdfFormat = this.formats.filter((f) => f.type === this.PDF_TYPE)[0];
			pdfFormat.edit = true;
			pdfFormat.background = this.fileUrl(this.PDF_TYPE, nodeFiles.pdfFile);
		}

		if (nodeFiles.videoYouTube) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.end = nodeFiles.end;
			videoFormat.url = nodeFiles.videoYouTube;
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.imageYoutube) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.start = nodeFiles.start;
			videoFormat.edit = true;
		}

		if (nodeFiles.videoInstagram) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = nodeFiles.videoInstagram;
			videoFormat.background =
				"url(" +
				this.fileUrl(
					FILE_FORMATS.VIDEO_PREVIEW_TYPE,
					nodeFiles.imageInstagram
				) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.edit = true;
		}

		if (nodeFiles.videoTiktok) {
			const videoFormat = this.formats.filter(
				(f) => f.type === this.VIDEO_TYPE
			)[0];
			videoFormat.url = nodeFiles.videoTiktok;
			videoFormat.background =
				"url(" +
				this.fileUrl(FILE_FORMATS.VIDEO_PREVIEW_TYPE, nodeFiles.imageTiktok) +
				")";
			videoFormat.idNodeFile = nodeFiles.idNodeFile;
			videoFormat.edit = true;
		}

		if (nodeFiles.txtFile) {
			const txtFormat = this.formats.filter((f) => f.type === this.TXT_TYPE)[0];
			txtFormat.edit = true;
			txtFormat.background = nodeFiles.txtFile;
		}

		this.hideEmptyFiles(node);
	}

	hideEmptyFiles(node) {
		const nodeFiles: NodeFilesModel = node.nodesFiles as NodeFilesModel;
		const imageFormat = this.formats.filter(
			(f) => f.type === this.IMAGE_TYPE
		)[0];
		const videoFormat = this.formats.filter(
			(f) => f.type === this.VIDEO_TYPE
		)[0];
		const audioFormat = this.formats.filter(
			(f) => f.type === this.AUDIO_TYPE
		)[0];
		const pdfFormat = this.formats.filter((f) => f.type === this.PDF_TYPE)[0];
		const txtFormat = this.formats.filter((f) => f.type === this.TXT_TYPE)[0];

		imageFormat.visible = nodeFiles.pictureFile !== null;
		videoFormat.visible =
			nodeFiles.videoFile ||
			nodeFiles.videoYouTube ||
			nodeFiles.videoInstagram ||
			nodeFiles.videoTiktok
				? true
				: false;
		audioFormat.visible =
			nodeFiles.audioFile || nodeFiles.spotifyAudio ? true : false;
		pdfFormat.visible = nodeFiles.pdfFile !== null;
		txtFormat.visible = nodeFiles.txtFile !== null;
	}

	cargarIconos(formatType: string, file?: File, text?: string) {
		this.agruparIconosAdicionados(formatType, [], [], [], [], file, text);
	}

	agruparIconosAdicionados(
		formatType: string,
		iconosSenmotemo: Operator[],
		iconosAssostatic: Operator[],
		iconosTemporary: Operator[],
		iconosAbstract: Operator[],
		file?: File,
		text?: string
	) {
		const operatorIcons: Operator[] = [];

		for (var val of iconosSenmotemo) {
			operatorIcons.push(val);
		}
		for (var val of iconosAssostatic) {
			operatorIcons.push(val);
		}
		for (var val of iconosTemporary) {
			operatorIcons.push(val);
		}
		for (var val of iconosAbstract) {
			operatorIcons.push(val);
		}

		const actualFormat = this.formats.filter((f) => f.type === formatType)[0];
		actualFormat.operatorIcons = [...operatorIcons];

		this.nodeFileAdded.emit({
			type: actualFormat.type,
			content: file ? file : text,
			operators: actualFormat.operatorIcons,
		});
	}

	onFileSelected({ event, format, fileDevice }) {
		this.iconosAdicionados = [];
		this.videoSource =
			this.imageSource =
			this.audioSource =
			this.pdfSource =
			this.textSource =
			this.youtubeSource =
			this.instagramSource =
			this.tiktokSource =
			this.spotifySource =
				"";

		const actualFormat: NodeFilesFormat = this.formats.filter(
			(f) => f.id === format.id
		)[0];
		this.format = format.type;
		this.inicial = false;
		if (fileDevice !== undefined) {
			var file = fileDevice;
		} else {
			var file = event.target.files[0];
		}
		actualFormat.edit = false;

		if (file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async (event: any) => {
				switch (actualFormat.type) {
					case this.VIDEO_TYPE:
						if (file.type == "video/mp4" || file.type == "video/webm") {
							this.videoSource = await (<FileReader>event.target).result;
							//SnapShop del video
							this.cargarIconos(actualFormat.type, file);
							actualFormat.edit = true;
							this.node.videoFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							//this.cargando = false;
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.IMAGE_TYPE:
						if (file.type == "image/jpeg" || file.type == "image/png") {
							this.imageSource = event.target.result;
							this.cargarIconos(actualFormat.type, file);
							actualFormat.background = "url(" + event.target.result + ")";
							actualFormat.edit = true;
							this.node.pictureFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.AUDIO_TYPE:
						if (file.type == "audio/mpeg" || file.type == "audio/mp3") {
							this.audioSource = event.target.result;
							this.cargarIconos(actualFormat.type, file);
							actualFormat.background = "url(assets/images/nodes/audio.jpg)";
							format.edit = true;
							this.node.audioFile = file.name;
							this.nodeService.setCurrentNode(this.node);
							if (this.node.nodesFiles.spotifyAudio) {
								format.url = this.node.nodesFiles.spotifyAudio;
							}
						} else {
							//this.cargando = false;
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					case this.PDF_TYPE:
						if (AVAILABLE_TYPE_FILES.includes(file.type)) {
							this.pdfSource = event.target.result;
							this.cargarIconos(actualFormat.type, file);
							format.edit = true;
							this.node.pdfFile = file.name;
							this.nodeService.setCurrentNode(this.node);
						} else {
							this.inicial = true;
							this.mensaje = "noFormato";
						}
						break;
					default:
						break;
				}

				this.nodeFilesTypeChanged.emit(actualFormat.type);
			};

			if (file.type == "video/mp4") {
				var previewReader = new FileReader();
				previewReader.onload = () => {
					// this.handleFile(fileReader.result, file.type);
					this.snapshotService.processSnapshotVideo(
						previewReader.result,
						file.type
					);
					this.snapshotService.previewBlob$.pipe(first()).subscribe((res) => {
						this.addPreview(res as File, false);
					});
				};
				previewReader.readAsArrayBuffer(file);
			}
		} else {
			//this.cargando = false;
			this.inicial = true;
			this.mensaje = "noEscogido";
		}
	}

	playFile(type: string) {
		this.reproducirArchivo(type, true);
	}

	saveNodeElementHistorial(type: string, typeNew: string, exit?: boolean) {
		if (exit) {
			if (document.fullscreenElement) {
				document.exitFullscreen().catch((err) => {});
			} else {
			}
		}
		const tiempoTranscurrido = this.timerService.stopTimer();
		let nodeElementsHistorial: NodeElementsHistorialModel =
			new NodeElementsHistorialModel();
		let graphVisited_Student = JSON.parse(
			this.localStorage.getItem("graphVisited_Student")
		);
		const soloDigitos = this.node.id.replace(/\D/g, "");
		const idNodo = parseInt(soloDigitos, 10);
		if (tiempoTranscurrido > 3000) {
			//si el tiempo fue menor a 3seg no se guardará registro
			nodeElementsHistorial.idCourse = graphVisited_Student[0].idCurso;
			nodeElementsHistorial.idTarget = graphVisited_Student[0].idGrafo;
			nodeElementsHistorial.idNode = idNodo;
			nodeElementsHistorial.idUser = 0;
			nodeElementsHistorial.fecha = null;
			nodeElementsHistorial.tiempoTotalNode = 0;
			nodeElementsHistorial.element = type;
			nodeElementsHistorial.tiempoActualElement =
				tiempoTranscurrido < 3600000 ? tiempoTranscurrido : 3600000; //el tiempo mayor que puede tener es de 1h
			nodeElementsHistorial.tiempoTotalElement = 0;
			nodeElementsHistorial.totalVisitas = 0;
			nodeElementsHistorial.elementNew = typeNew;

			this.nodeService
				.createNodesElementsHistorial(nodeElementsHistorial)
				.subscribe();
		}
	}

	reproducirArchivo(type: string, clickElement?: boolean) {
		if (clickElement) {
			//si estas cambiando de elemento
			let elemento = this.localStorage.getItem("lastNodeElement");
			this.saveNodeElementHistorial(elemento, type);
		}
		this.timerService.startTimer();
		localStorage.setItem("lastNodeElement", type); // GUARDAMOS EN LOCAL EL ELEMENTO ULTIMO QUE HE VISTO
		this.inicial = false;
		this.format = type;
		this.nodeFilesTypeChanged.emit(type);
		const nodesFiles: NodeFilesModel = this.node.nodesFiles as NodeFilesModel;
		switch (type) {
			case this.VIDEO_TYPE:
				//Puede ser vídeo , instagram. tiktok o vídeo normal subido por el usuario
				if (nodesFiles.videoYouTube) {
					this.youtubeSource = "";
					this.videoId = "";
					this.endSeconds = null;
					this.startSeconds = null;
					setTimeout(() => {
						this.videoId = this.utils.getYoutubeId(nodesFiles.videoYouTube);
						this.youtubeSource = nodesFiles.videoYouTube;
						this.startSeconds = nodesFiles.start
							? this.utils.hoursToSeconds(nodesFiles.start)
							: null;
						this.endSeconds = nodesFiles.end
							? this.utils.hoursToSeconds(nodesFiles.end)
							: null;
					}, 300);
				} else if (nodesFiles.videoInstagram) {
					this.instagramSource = "";
					setTimeout(() => {
						this.instagramSource =
							this.sanitizer.bypassSecurityTrustResourceUrl(
								nodesFiles.videoInstagram + "embed"
							);
					}, 100);
				} else if (nodesFiles.videoTiktok) {
					this.tiktokSource = "";
					setTimeout(() => {
						const idTiktok = this.utils.getTiktokId(nodesFiles.videoTiktok);
						this.tiktokSource = this.sanitizer.bypassSecurityTrustResourceUrl(
							URL_TIKTOK + idTiktok
						);
					}, 100);
				} else
					this.videoSource = this.fileUrl(
						this.VIDEO_TYPE,
						nodesFiles.videoFile
					);
				break;
			case this.PDF_TYPE:
				this.ext = this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile)
					.split(".")
					.pop();

				this.pdfSource = this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile);

				this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
					this.fileUrl(this.PDF_TYPE, nodesFiles.pdfFile)
				);
				// se comenta el pantalla completa en PDF

				// if(clickElement && document){
				// 	document.documentElement.requestFullscreen()
				// }
				break;
			case this.IMAGE_TYPE:
				this.imageSource = this.fileUrl(
					this.IMAGE_TYPE,
					nodesFiles.pictureFile
				);
				break;
			case this.AUDIO_TYPE:
				//SI ES UN ENLACE DE SPOTIFY LO ABRIMOS EN OTRA PESTAÑA
				if (nodesFiles.spotifyAudio) {
					//document.exitFullscreen();
					//window.open(nodesFiles.spotifyAudio, '_blank');
					this.spotifySource = "";
					setTimeout(() => {
						const idSpotify = this.utils.getSpotifyId(nodesFiles.spotifyAudio);
						//this.spotifySource = this.sanitizer.bypassSecurityTrustResourceUrl(URL_TIKTOK + idSpotify)
						const spotifyUrl = nodesFiles.spotifyAudio.split("?")[0];
						const embedUrl = spotifyUrl.replace(
							/open\.spotify\.com\/(?:intl-es\/|)([^\/]+)/,
							"open.spotify.com/embed/$1"
						);
						this.spotifySource = this.sanitizer.bypassSecurityTrustResourceUrl(
							embedUrl + "?utm_source=generator"
						);
					}, 100);
				} else {
					this.audioSource = this.fileUrl(
						this.AUDIO_TYPE,
						nodesFiles.audioFile
					);
				}
				break;
			case this.TXT_TYPE:
				this.textSource = nodesFiles.txtFile;
				break;
		}
	}

	private fileUrl(type: string, fileName: string) {
		if (!fileName) return undefined;
		return `${this.FILES_URL}/${type}/${fileName}`;
	}

	public saveText() {
		const txtFormat = this.formats.filter((f) => f.type === this.TXT_TYPE)[0];
		txtFormat.background = this.textSource;
		this.cargarIconos(this.TXT_TYPE, undefined, this.textSource);
	}

	onVideoPreview(event) {
		const file: File = event.target.files[0];
		this.addPreview(file);
	}

	private addPreview(file: File, showResult: boolean = true) {
		const reader = new FileReader();
		const actualFormat = this.formats.filter(
			(f) => f.type === FILE_FORMATS.VIDEO_TYPE
		)[0];
		reader.readAsDataURL(file);
		reader.onload = async (event: any) => {
			actualFormat.background = "url(" + event.target.result + ")";
		};

		this.nodeFileAdded.emit({
			type: FILE_FORMATS.VIDEO_PREVIEW_TYPE,
			content: file,
			showResult,
		});
	}

	typeSelected(format: NodeFilesFormat) {
		this.fileSelected.emit(format);
	}

	fileLoaded(): void {
		this.isLoadFile = false;
	}
}
