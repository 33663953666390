import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { AcceptDialogComponent } from 'src/app/shared/components/accept-dialog/accept-dialog.component';
import { DataHistoryInterface } from '../../datos-resultado-quizes/interface/datos-resultado.interface';

@Component({
    selector: 'app-actividad-quiz-play',
    templateUrl: './actividad-quiz-play.component.html',
    styleUrls: ['./actividad-quiz-play.component.scss'],
    standalone: false
})
export class ActividadQuizPlayComponent implements OnInit {
    @Input() quiz: QuizModel;
    @Input() elements: any[];
    @Input() courseId: number;
    @Input() graphId: number;

    private automatic: boolean = false;

    answered: boolean = false;

    private _newColor: string;

    discoverAnswer: Subject<boolean> = new Subject<boolean>();
    discoverAnswer$: Observable<boolean> = this.discoverAnswer.asObservable();

    evaluate: Subject<boolean> = new Subject<boolean>();
    evaluate$: Observable<boolean> = this.evaluate.asObservable();

    playNext: Subject<boolean> = new Subject<boolean>();
    playNext$: Observable<boolean> = this.playNext.asObservable();

    tryClose: Subject<boolean> = new Subject<boolean>();
    tryClose$: Observable<boolean> = this.tryClose.asObservable();
    private _dataHistory: DataHistoryInterface;

    constructor(private quizService: QuizzesService, private dialog: MatDialog, public activeModal: NgbActiveModal, private translateService: TranslateService, private dialogService: DialogService) { }

    ngOnInit() {
        // this.quizService.getAutomatic().pipe(first()).subscribe(automatic => this.automatic = automatic);
    }

    onDiscoverAnswer() {
        this.discoverAnswer.next(true);
    }

    onEvaluate(result: boolean) {
        this.evaluate.next(result);
    }

    onPlayNext() {
        this.playNext.next(true);
    }

    onAnswered() {
        this.answered = true;
    }

    onColorChanged(newColor: string) {
        this._newColor = newColor;
    }
    dataTableResponse(data: DataHistoryInterface){
        this._dataHistory = data;
    }
    onClose() {
        if (this.answered) {
            this.activeModal.close(this._dataHistory);
            return;
        }

        this.tryClose.next(true);

        const dialogRef = this.dialogService.openAcceptDialog(
			true,
			undefined,
			this.translateService.instant('QUIZZES.CLOSEQUESTION'),
			[],
			this.translateService.instant('QUIZZES.EXIT'),
			this.translateService.instant('QUIZZES.RESPONSE')
		);

        dialogRef.afterClosed().subscribe(result => {
            this.tryClose.next(result);

            if (result) {
                this.tryClose.next(false);
                this.activeModal.close();
            }
        });
    }
}
