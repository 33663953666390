<div [ngClass]="{
		'files-container': showInContainer,
		'files-container-2': !showInContainer && isFooterOpen,
		'files-container-3': !showInContainer && !isFooterOpen,
		'files-container-IOS-ON': !showInContainer && isFooterOpen && isIOS(),
		'files-container-IOS-OFF': !showInContainer && !isFooterOpen && isIOS()
	}">
	<form class="h-100 flex justify-content-center align-items-center main-content">
		<!-- MENSAJE NODO SIN ELEMENTOS -->
		<div *ngIf="
				!node.nodesFiles.audioFile &&
				!node.nodesFiles.pdfFile &&
				!node.nodesFiles.pictureFile &&
				!node.nodesFiles.spotifyAudio &&
				!node.nodesFiles.txtFile &&
				!node.nodesFiles.videoFile &&
				!node.nodesFiles.videoInstagram &&
				!node.nodesFiles.videoTiktok &&
				!node.nodesFiles.videoYouTube"
				class="d-flex no-data">
			<h2>{{ "NODEDETAIL.SINELEMENTOS" | translate }}</h2>
		</div>
		<!-- END MENSAJE NODO SIN ELEMENTOS -->
		<!-- YOUTUBE -->
		<app-youtube-player class="h-100 w-100 p-0" [height]="'100%'" [videoId]="videoId" [startSeconds]="startSeconds"
			[endSeconds]="endSeconds" *ngIf="format === VIDEO_TYPE && !cargando && !inicial && youtubeSource">
		</app-youtube-player>
		<!-- END YOUTUBE -->

		<!-- INSTAGRAM VIDEO -->
		<ng-container *ngIf="format === VIDEO_TYPE && !cargando && !inicial && instagramSource">
			<iframe [src]="instagramSource" class="iframeVideo"></iframe>
		</ng-container>
		<!--END  INSTAGRAM VIDEO -->

		<!-- TIKTOK VIDEO -->
		<ng-container *ngIf="format === VIDEO_TYPE && !cargando && !inicial && tiktokSource">
			<iframe [src]="tiktokSource" class="iframeVideo"></iframe>
		</ng-container>
		<!-- END TIKTOK VIDEO -->

		<!-- SPOTIFY AUDIO -->
		<ng-container *ngIf="format === AUDIO_TYPE && !cargando && !inicial && spotifySource">
			<iframe style="border-radius:12px" [src]="spotifySource" width="60%" height="60%" frameBorder="0"
				allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
				loading="lazy">
			</iframe>
		</ng-container>
		<!-- END SPOTIFY AUDIO -->

		<!-- VIDEO -->
		<div class="h-100 w-100 p-0" *ngIf="format === VIDEO_TYPE && videoSource && !cargando && !inicial">
			<video style="width: 100%; height: 100%; object-fit: contain;" #videoElement controls autoplay
				[src]="videoSource | safe"></video>
		</div>
		<!-- END VIDEO -->

		<!-- IMAGE -->
		<div class="full-image w-100" [style.width.px]="windowWidth" [style.height.px]="windowHeight"
			*ngIf="format === IMAGE_TYPE && imageSource && !cargando && !inicial"
			[style.background-image]="'url(' + imageSource + ')'" (click)="openFullImage()" nbTooltip="Clic para hacer zoom"
			nbTooltipPlacement="top"></div>
		<!-- END IMAGE -->

		<!-- FULL IMAGE CONTAINER -->
		<div class="full-image-container" [ngClass]="{'full-img': !isFooterOpen}" *ngIf="showFullImage">
			<div class="closeModal" (click)="closeFullImage()">
				<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
					ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
				</nb-icon>
			</div>
			<img [src]="imageSource" alt="Full Image" />
		</div>
		<!-- END FULL IMAGE CONTAINER -->

		<!-- AUDIO -->
		<div class="d-flex gap-3 w-100 justify-content-center align-items-center"
			*ngIf="format === AUDIO_TYPE && audioSource && !cargando && !inicial">

			<audio #audioPlayer [src]="audioSource | safe" controls></audio>
			<!-- Boton que baja la velociodad del audio -->
			<div class="d-flex justify-content-center align-items-center style-speedIndex" (click)="changeSpeed()">
				<span *ngIf="speedIndex == 0" nbTooltip="Bajar velocidad de reproducción">x1</span>
				<span *ngIf="speedIndex !== 0" nbTooltip="Aumentar velocidad de reproducción">x0.25</span>
			</div>
		</div>
		<!-- END AUDIO -->

		<!-- PDF -->
		<div *ngIf="format === PDF_TYPE && pdfSource && !cargando && !inicial" class="d-flex w-100 h-100">
			<!-- Verifica si es un archivo DOCX -->
			<ng-container *ngIf="ext === 'docx'; else embedPdf">
				<ngx-doc-viewer [url]="pdfSource" viewer="office" class="w-100 h-100"></ngx-doc-viewer>
			</ng-container>
			<!-- Verifica si es un archivo PFD -->
			<ng-template #embedPdf>
				<!-- Dispositivos distintos a IOS -->
				<iframe *ngIf="sanitizedUrl && !isIOS()" [src]="sanitizedUrl" class="w-100 h-100" frameborder="0"></iframe>
				<!-- Dispositivos IOS -->
				<div *ngIf="sanitizedUrl && isIOS()"
					class="d-flex w-100 justify-content-center align-items-center h-100 btn-IOS">
					<button nbButton class="btn-themecolor" size="small">
						<a [href]="sanitizedUrl" download="archivo.pdf">Descargar</a>
					</button>
				</div>
			</ng-template>
		</div>
		<!-- END PDF -->

		<!-- TXT -->
		<div *ngIf="format === TXT_TYPE && !cargando && !inicial" class="h-100 w-100 p-0">
			<div class="h-100 ql-editor bg-white rounded text-node" style="overflow-y: auto" [innerHTML]="textSource"></div>
		</div>
		<!-- END TXT -->
	</form>
	<!-- LATERAL COLUMN FILES -->
	<div class="cont-icon-hide" [ngClass]="{'in': isMenuOpen}" (click)="toggleMenu()">
		<mat-icon class="pointer">
			{{ isMenuOpen ? 'arrow_right' : 'arrow_left' }}
		</mat-icon>
	</div>

	<div *ngIf="isMenuOpen" class="header-close-ismenuOpen">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeNode()">
		</nb-icon>
		<span class="background-forIcon"></span>
	</div>

	<div class="right-files" [ngClass]="{'open': isMenuOpen}">
		<div class="d-flex flex-column gap-3 px-3 w-100 h-100">
			<div *ngFor="let format of formats; let last = last; let i = index" class="file-button">
				<div class="d-flex w-100" [style.visibility]="format.visible ? 'visible' : 'hidden'">
					<app-node-detail-files-player class="w-100" [node]="node" [format]="format"
						(filesUploaderPlayFile)="playFile($event)">
					</app-node-detail-files-player>
				</div>
			</div>
		</div>
	</div>
	<!-- END LATERAL COLUMN FILES -->
</div>
