import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { Utils } from 'src/app/core/utils/utils';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';

@Component({
	selector: 'app-modal-information-student',
	templateUrl: './modal-information-student.component.html',
	styleUrls: ['./modal-information-student.component.scss'],
	standalone: false,
})
export class ModalInformationStudentComponent implements OnInit {
	cargando: boolean = false;
	idUser: number = 0;
	student: any;

	NOIMAGE: string = "../../../../../assets/images/icons/account_circle.svg";

	constructor(
		public activeModal: NgbActiveModal,
		public loginService: LoginService,
		public courseService: CoursesService,
		public getDataService: GetDataService,
		public translateService: TranslateService,
		private imagePipe: ImagenPipe,

	) { }

	ngOnInit() {
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	getUserAvatar(imagen): string {
		let image: string = ''
		imagen !== null && imagen !== "" ? image = this.imagePipe.transform(imagen, 'userAvatar') : image = this.NOIMAGE
		return `url('${image}')`
	}

}
