<nb-card id="quiz" class="no-edge-card mb-0">
	<nb-card-header class="border-bottom-0">
		<app-quiz-edit-multiple-header [quiz]="quiz" [courseId]="courseId" [graphId]="graphId" [fromLista]="fromLista"
			(quizTypeChanged)="onQuizTypeChanged($event)" (close)="closeModal($event)" (publishQuiz)="onPublishQuiz($event)" (closeInModeList)="onCloseInModeList($event)">
		</app-quiz-edit-multiple-header>
	</nb-card-header>

	<nb-card-body>
		<div class="d-flex flex-column gap-4">
			<app-quiz-edit-multiple-body (saveTitle)="saveTitle($event)" (updateFooter)="updateFooter($event)"
				(activeCrearTabs)="activeCrearTabs($event)" [courseId]="courseId" [graphId]="graphId" [quiz]="quiz"
				[quizFiles]="quizFiles" [elements]="elements" [quizzes]="quizzes" (onQuizSupEdited)="onQuizSupEdited($event)"
				(quizEdited)="onQuizEdited($event)" (publishQuiz)="onPublishQuiz($event)">
			</app-quiz-edit-multiple-body>

			<div class="d-flex justify-content-center align-items-center" *ngIf="quizzes.length > 0">
				<button nbButton class="btn-red" size="small" (click)="deleteAllQuizes()">
					Eliminar los quiz
				</button>
			</div>
		</div>
	</nb-card-body>

	<nb-card-footer class="border-top-0">
		<app-quiz-edit-multiple-footer (quizzAdded)="quizzAdded($event)" (updateElementSize)="updateElementSize($event)"
			(updateArrayElement)="updateArrayElements($event)" (updateArrayDeleteElement)="updateArrayDeleteElement($event)"
			(quizEdited)="onQuizEdited($event)" [courseId]="courseId" [quizFiles]="quizFiles" [quizzes]="quizzes"
			[graphId]="graphId" [quiz]="quiz">
		</app-quiz-edit-multiple-footer>
	</nb-card-footer>
</nb-card>
