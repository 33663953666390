import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OptionsModel } from 'src/app/core/models/surveys/options.model';

@Component({
    selector: 'app-surveys-question-edit',
    templateUrl: './surveys-question-edit.component.html',
    styleUrls: ['./surveys-question-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SurveysQuestionEditComponent implements OnInit {
	@Input() question: any = null;
	@Input() editMode: boolean = false;
	@Input() totalQuestions: number = 0;
	@Output() clicked = new EventEmitter<void>();
	@Output() deleteQuestion = new EventEmitter<any>();
	@Output() editQuestion = new EventEmitter<any>();
	@Output() moveQuestion = new EventEmitter<any>();

	questionsTypes = [
		{	id: 1, color: "#ff3d71", title: this.translateService.instant('ENCUESTAS.LIKERT')	},
		{	id: 2, color: "#4ac9b4", title: this.translateService.instant('ENCUESTAS.UNICA')	},
		{	id: 3, color: "#ffc94d", title: this.translateService.instant('ENCUESTAS.MULTIPLE')},
		{	id: 4, color: "#ffc94d", title: this.translateService.instant('ENCUESTAS.ABIERTA')},
	];
	obligatory: boolean = false;
	openResponseDefault: string = "(Respuesta ingresada por el usuario)";
	numbers = [ 2, 3, 4, 5, 6, 7, 8, 9, 10];
	likertOptions: number[] = [];

  selectedValue: any; // Add this line
	constructor(
		public translateService: TranslateService,
		private formBuild: UntypedFormBuilder,
	){}

	ngOnInit(): void{
		this.obligatory = this.question.required == 1 ? true : false;
		this.likertOptions = Array.from({ length: this.question.likertOptions }, (_, i) => i + 1);
	}

	changeMode(){
		this.editMode = !this.editMode;
	}

	onSelectOption(newValue: any) {
		console.log('Selected value:', newValue);
		this.selectedValue = newValue;
		// Perform any actions you need to do when the selection changes
	}

	onToggleChange(newValue: boolean) {
    	this.question.required = newValue ? 1 : 0;
		this.onEditQuestion();
	}

	viewPreview(){
		this.editMode = false;
		this.likertOptions = Array.from({ length: this.question.likertOptions }, (_, i) => i + 1);
	}

	onDelete(): void {
    	this.deleteQuestion.emit(this.question); // Emitimos la pregunta a eliminar
		this.onEditQuestion();
	}

	onEditQuestion(): void {
		this.editQuestion.emit(true); // Emitimos el mensaje de pregunta editada
	}

	newOption(){
		let opt: OptionsModel = new OptionsModel (
			0,
			this.question.idSurveyQuestion,
			this.question.options.length + 1,
			this.translateService.instant('ENCUESTAS.OPTIONNEW')
		)
		this.question.options.push(opt);
		this.onEditQuestion();
	}

	deleteOption(optionToDelete: any){
		this.question.options = this.question.options.filter(option => option !== optionToDelete);
		this.onEditQuestion();
	}

	selectText(event: FocusEvent): void {
		const input = event.target as HTMLInputElement;
		input.select();
	}

	handleClick(): void {
		this.clicked.emit();  // Informa al componente padre del clic
	}

	changeOrdinal(from: number, to: number): void {
		let data = from + ':' + to;
		console.log(data);
		this.moveQuestion.emit(data);  // Informa al componente padre del clic
	}

}
