import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-select-recipient',
    templateUrl: './modal-select-recipient.component.html',
    styleUrls: ['./modal-select-recipient.component.scss'],
    standalone: false
})
export class ModalSelectRecipientComponent implements OnInit {
  idCurso:string;
  constructor() { }

  ngOnInit() {
  }

}
