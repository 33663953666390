import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-pop-up-reload',
    templateUrl: './pop-up-reload.component.html',
    styleUrls: ['./pop-up-reload.component.scss'],
    standalone: false
})
export class PopUpReloadComponent {
	@Input() isVisible = false;

	constructor(private cookieService: CookieService) {}

  reloadAndDeleteCookies() {
    this.cookieService.deleteAll();

    // Recargar la página
    window.location.reload();
  }

}
