import { SIGMA_CONSTANTS } from '../../../../core/utils/sigma-constants';
import { ACTIONS } from 'src/app/core/utils/actions';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { environment } from 'src/environments/environment';
import { Utils } from '../../../../core/utils/utils';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModelIcono } from 'src/app/core/models/courses/icono.model';
import { gNode, ObjetoNodoAuto } from 'src/app/core/models/graph/gNode.model';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SnapshotService } from 'src/app/core/services/snapshot/snapshot.service';
import { MateriaModel } from 'src/app/core/models/masters';
import { Observable, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FILE_FORMATS } from 'src/app/core/utils/file-formats';
import { finalize, map } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { ModalRadarComponent } from 'src/app/shared/components/modal-radar/modal-radar.component';
import { PowerService } from 'src/app/core/services/power/power.service';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { DEFAULTCLOSEPADS } from 'src/app/core/models/masters/masters.enum';
import { ModalCelebrationComponent } from 'src/app/shared/components/modal-celebration/modal-celebration.component';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { GruposService, SOCKETMESSAGES } from 'src/app/core/services/groups/grupos.service';
import { ModalSocketCoursesComponent } from '../../grupos/modal-socket-courses/modal-socket-courses.component';
import { SocketService } from 'src/app/core/services/socket/socket-service.service';
import { MastersService } from 'src/app/core/services/masters';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { ModalAceptarCancelarComponent } from '../../modal';
import { ModalProfileActionsComponent } from '../../modal-profile-actions/modal-profile-actions.component';
import { TargetsService } from 'src/app/core/services/targets';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $: any;
declare var document
interface Time {
	hours: number,
	minutes: number,
	seconds: number;
}

@Component({
    selector: 'app-actividades-flash',
    templateUrl: './app-actividades-flash.component.html',
    styleUrls: ['./app-actividades-flash.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ActividadesFlashComponent implements OnInit, OnDestroy {

	// A.G.
	@Input() idCurso: number;
	@Input() idMapa: number;
	@Input() quiz;
	@Input() indice: number = 0;
	@Input() sizeDeck: number = 0;

	answered: boolean = false;
	nextButton: boolean = false;
	disabledButton: boolean = false;
	modeAuto: boolean = true;
	mouseInterval:any = ''
	elements: any[];
	rawQuiz: any;
	hideBar = true;

	discoverAnswer: Subject<boolean> = new Subject<boolean>();
	discoverAnswer$: Observable<boolean> = this.discoverAnswer.asObservable();

	evaluate: Subject<boolean> = new Subject<boolean>();
	evaluate$: Observable<boolean> = this.evaluate.asObservable();

	playNext: Subject<boolean> = new Subject<boolean>();
	playNext$: Observable<boolean> = this.playNext.asObservable();

	tryClose: Subject<boolean> = new Subject<boolean>();
	tryClose$: Observable<boolean> = this.tryClose.asObservable();

	subjects$: Observable<MateriaModel[]>;

	saveClicked: Subject<any> = new Subject<any>();
	saveClicked$: Observable<any> = this.saveClicked.asObservable();

	nomGrafo: string;
	nomNode: string

	cargando: boolean;
	inicial: boolean;

	mensaje: string;
	format: string;
	automatic: any = false;
	isShowFiles:boolean = true

	intervalId;
	time: Time;
	countSeconds: number;
	timerId: number = null;
	private send = false;

	private _newColor: string;

	verGif: any;
	files: File[] = [];

	showSaveButton: boolean = false;
	isLoading: boolean = false;
	canEdit: boolean = false;

	nextNodo: boolean = false;
	backList: boolean = false;

	isShowDownArrow:boolean = true
	isShowUpArrow:boolean = false

	urlCelebrations:string = environment.celebrations

	action: string;
	private subscriptions: Subscription[] = [];

	showUserAccount: boolean = false;
	private destroy$ = new Subject();
  public cursosGrupo: any[] = [];
	isProjectInGroup: boolean = false;
	graphTitle: any;

	/**
	 *
	 */
	constructor(
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public loginService: LoginService,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		public snapshotService: SnapshotService,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private powerServ: PowerService,
    private utils:Utils,
		private st:SigmaToolbarsService,
		private socketService: SocketService,
		private groupService: GruposService,
		private MastersService: MastersService,
		private targetsService: TargetsService,
	) {
		var subscribe = this.quizService.loading.subscribe((n: boolean) => {
			this.cargando = n
			if (this.cargando == false && loginService.esEstudiante()) { subscribe.unsubscribe(); }
		});
	}

	// Retorna un entero aleatorio entre min (incluido) y max (excluido)
	// ¡Usando Math.round() te dará una distribución no-uniforme!

	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	ngOnInit() {
		if(this.loginService.esEstudiante() && !this.IsFullScreen())		{
			document.documentElement.requestFullscreen().catch( err => {
				console.log('err')
			})
		}
		this.quizService.setExecuteAutomatic(false);
		this.automatic = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.AUTOMATIC));

		this.inicial = true;
		if (this.loginService.esAutor()) {
			//this.mensaje = "Por favor suba los archivos de estudio";
			this.mensaje = "subir";
		}
		if (this.loginService.esEstudiante()) {
			//this.mensaje = "Aquí se mostrará sus archivos de estudio";
			this.mensaje = "mostrar";
		}
		setTimeout(() => {
            //Ocultamos files, pads y labels a los 3 segundos para dejar visible el contenido;
            this.isShowFiles = false
      	}, DEFAULTCLOSEPADS);

		this.getQuiz();
	}

	showFiles(value){

		let interval: number = 1
        if(value)
		    clearInterval(this.mouseInterval)

		this.mouseInterval = setInterval(() => {
			interval++
			if(interval === 4)
			{
				this.isShowFiles = false;
				clearInterval(this.mouseInterval);
			}
		}, DEFAULTCLOSEPADS / 3)

		this.isShowFiles = true//Change variable value
	}

	onDiscoverAnswer() {
		this.discoverAnswer.next(true);
	}

	onEvaluate(result: boolean) {
		this.evaluate.next(result);
	}

	onPlayNext() {
		this.playNext.next(true);
	}

	onAnswered() {
		this.answered = true;
		this.nextButton = true;
		//this.activeModal.close(true);
	}

	nextFlash(){
		this.answered = true;
		this.activeModal.close(true);
	}

	onColorChanged(newColor: string) {
		this._newColor = newColor;
	}

	onViewGif(result: boolean) {

		if (this.utils.showGifResponse == true) {
			let url: string = ''

			//Si result es true, gif ok; si es false, gif KO
			const numRandon = this.getRandomInt(1, 40);
			const numRandonNeg = this.getRandomInt(1, 19);

			if (result)
				url = this.urlCelebrations + '/positive/image-' + numRandon + '.gif'
			else
				url = this.urlCelebrations + '/negative/image-' + numRandonNeg + '.gif'

			//Mostrar un modal con el gif y que se cierre en 3 segundos o lo haga el usuario
			const modalRef = this.modalService.open(ModalCelebrationComponent,
				{
					windowClass: 'celebration-modal-window',
				})

			modalRef.componentInstance.url = url;

			modalRef.result.then(res => {
				modalRef.close()
			}, err => { })

			setTimeout(() => {
				modalRef.close()
			}, 3000);
		}else{
			this.utils.showGifResponse = true;
		}
	}

	hidenGif(event){}

    closeNode(){
        this.activeModal.close();
    }

	onClose() {
		if(this.loginService.esProfesor()){
			this.activeModal.close();
		} else{

			if (this.answered) {
				this.activeModal.close(this._newColor);
				return;
			}

			this.tryClose.next(true);

			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				'¿Salir de la actividad?',
				this.translateService.instant('QUIZZES.CLOSEQUESTION'),
				[],
				this.translateService.instant('QUIZZES.EXIT'),
				this.translateService.instant('QUIZZES.RESPONSE')
			);

			dialogRef.afterClosed().subscribe(result => {
				this.tryClose.next(result);

				if (result) {
					this.tryClose.next(false);
					this.activeModal.close();
				}
			});
		}

	}

	////////////////////////////////////////////////
	////////////////////////////////////////////////



	changeStatus($event) {
		this.send = $event;
	}

	cancelar() {
		this.modalService.dismissAll();
	}

	registrar() {
		this.modalService.dismissAll();
	}

	reproducirFormato(formato: string) {
		this.format = formato;
	}

	closeModal(sendData?: any) {
		this.st.changeUpdateGraph(true)
	}

	ngOnDestroy() {
		if(this.loginService.esEstudiante() && this.IsFullScreen())
		{
			document.exitFullscreen();
		}
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.st.changeUpdateGraph(true)
	}


	IsFullScreen() {
		return (document.fullscreenEnabled && document.fullscreenElement !== null)
	}

	save() {
		this.saveClicked.next(true);
	}

	onNodeFilesTypeChanged(format: string) {
		this.showSaveButton = format === FILE_FORMATS.TXT_TYPE;
	}

	next(){
		this.action = "next";
		//if(this.nodeView == true )
		if (!this.answered) {
			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				undefined,
				this.translateService.instant('QUIZZES.CLOSEQUESTION'),
				[],
				this.translateService.instant('QUIZZES.EXIT'),
				this.translateService.instant('QUIZZES.RESPONSE')
			);

			dialogRef.afterClosed().subscribe(result => {
				this.tryClose.next(result);

				if (result) {
					this.viewTrue();
				}
				else {
					this.tryClose.next(false);
				}
			});
		}
		else {
			this.viewTrue();
		}
	}

	viewTrue() {
		this.answered = false;
		this.disabledButton = false;
		this.getQuiz();
	}

	getQuiz() {
		//let quiz = this.listQuiz[this.posArrayQuiz];
			if(this.quiz !== undefined)
			{
				//this.viewQuiz = false;
				this.isLoading = true;
				//this.quiz = '';

				if(this.quiz.idQuiz === this.quiz.idQuizOriginal){
					//se obtiene el detalle de un quiz simple para ejecutarlo
					this.quizService.getQuizSimpleCanvasQuizSimple(this.quiz.idQuizOriginal, this.idCurso, this.idMapa).pipe(finalize(() => this.isLoading = false)).subscribe(res => {
						this.quiz = res.quiz;
						this.elements = res.elements;
					});
				} else {
					//se obtiene el quiz hijo de un quiz multiple para ejecutarlo
					this.quizService.getQuizMultipleCanvasQuizMultiple(this.quiz.idQuiz, this.idCurso, this.idMapa).pipe(finalize(() => this.isLoading = false)).subscribe(res => {
						this.quiz = res.quiz;
						this.elements = res.elements;
					});
				}
			}
			else if(!this.backList){
				//this.toaster.success(this.translateService.instant('CURSOMODOAUTO.CURSOTERMINADO'));
				this.closeModal('');
			}
			else if(this.backList){
				this.toaster.error(this.translateService.instant('Ha salido del modo automatico'));
				this.closeModal('');
			}
	}

	///Verifica que la pregunta se ha respondido para avanzar al siguiente
	quizRespondido() {
		if (this.answered) {
			return true; // Pregunta ya respondida
		}
		const dialogRef = this.dialogService.openAcceptDialog(
			true,
			undefined,
			this.translateService.instant('QUIZZES.CLOSEQUESTION'),
			[],
			this.translateService.instant('QUIZZES.EXIT'),
			this.translateService.instant('QUIZZES.RESPONSE')
		);
		dialogRef.afterClosed().subscribe(result => {
			this.tryClose.next(result);

			if (result) {
				return true; //avanzar sin responder
			}
			else {
				return false; //Responder pregunta
			}
		});
	}

	checkQuizSolution():void{
		this.quizService.checkQuiz.next(true)
	}

	onScroll(event){
			var element = event.target;
			if (element.scrollHeight - element.scrollTop === element.clientHeight)
					this.isShowDownArrow = false
			if(element.scrollTop === 0)
					this.isShowDownArrow = true
	}

}
