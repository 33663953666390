import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelQuizFile } from 'src/app/core/models/quizzes';
import { environment } from 'src/environments/environment';
import { VID_URL, AUD_URL, PDF_URL, PICTURE_URL, TEXT_URL } from '../quiz-edit-multiple-body/quiz-edit-multiple-body.component';

@Component({
    selector: 'app-quiz-modal-hypermedia',
    templateUrl: './quiz-modal-hypermedia.component.html',
    styleUrls: ['./quiz-modal-hypermedia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QuizModalHypermediaComponent implements OnInit {

    IDIOMA1 = 1;
    IDIOMA2 = 2;


    public DIR_VIDEO_URL = VID_URL;
    public DIR_AUDIO_URL = AUD_URL;
    public DIR_DOC_URL = PDF_URL;
    public DIR_PICTURE_URL = PICTURE_URL;
    public DIR_TEXT_URL  = TEXT_URL;
    public mediaViewUrl = `${environment.quizzesContent}`;

    public IMG = 'IMG';
    public VID = 'VID';
    public DOC = 'DOC';
    public AUD = 'AUD';
    public TEXT = 'TEXT';

    @Input() insert: boolean = true;
    @Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
    @Input() hypermediaFiles: any;
    @Input() quizFiles: ModelQuizFile;
    @Input() idQuizMultiple: number;
    @Input() elementType: number;

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    public onCloseModal(sendData?: any) {
        this.activeModal.close(false);
    }

    public onFileSelected() {

    }

    public onTypeSelected() {
        this.fileSelected.emit({});
    }



    selectFile( fileName:string, fileType, language){
        this.fileSelected.emit({ fileName, fileType, language });

    }
}
