import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-competition',
    templateUrl: './modal-competition.component.html',
    styleUrls: ['./modal-competition.component.scss'],
    standalone: false
})
export class ModalCompetitionComponent implements OnInit {


  images: Array<string> = []
  currentImage: number = 0
  baseUrl:string = '../../../../assets/images/demo_images/competition-menu/'

  public option:string

  constructor( public activeModal: NgbActiveModal ) {}

  ngOnInit() {

    switch(this.option){
      case 'competition':
        this.images = [`${this.baseUrl}${this.option}/image.png`]
        break
      case 'train':
        this.images = [
          `${this.baseUrl}${this.option}/1.png`,
          `${this.baseUrl}${this.option}/2.png`,
          `${this.baseUrl}${this.option}/4.png`,
          `${this.baseUrl}${this.option}/5.png`,
          `${this.baseUrl}${this.option}/6.png`,
          `${this.baseUrl}${this.option}/7.png`
        ]
        break
      case 'couples':
        this.images = [
          `${this.baseUrl}${this.option}/1.png`,
          `${this.baseUrl}${this.option}/2.png`,
          `${this.baseUrl}${this.option}/4.png`,
          `${this.baseUrl}${this.option}/5.png`,
          `${this.baseUrl}${this.option}/6.png`,
          `${this.baseUrl}${this.option}/7.png`,
          `${this.baseUrl}${this.option}/8.png`,
          `${this.baseUrl}${this.option}/9.png`
        ]
        break
      case 'groups':
        this.images = [
          `${this.baseUrl}${this.option}/1.png`,
          `${this.baseUrl}${this.option}/2.png`,
          `${this.baseUrl}${this.option}/4.png`,
          `${this.baseUrl}${this.option}/5.png`,
          `${this.baseUrl}${this.option}/6.png`,
          `${this.baseUrl}${this.option}/7.png`,
          `${this.baseUrl}${this.option}/8.png`,
          `${this.baseUrl}${this.option}/9.png`,
          `${this.baseUrl}${this.option}/10.png`

        ]
        break

    }
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  changeImage(){
    if(this.currentImage != this.images.length -1)
      this.currentImage++
  }

}
