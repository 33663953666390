import { Injectable } from '@angular/core';
import axios from 'axios';
import { API_KEY_CHAT_GTP, PINECONE_API_KEY } from '../../models/masters/masters.enum';

@Injectable({
	providedIn: "root",
})
export class ChatService {
	private openaiApiKey = API_KEY_CHAT_GTP;
	private pineconeApiKey = PINECONE_API_KEY;
	private pineconeIndexUrl =
		"https://salware-uuoj8rr.svc.aped-4627-b74a.pinecone.io";

	constructor() {}

	async sendMessage(
		idUser: number,
		text: string,
		initialContext: string = ""
	): Promise<any> {
		// Generar embedding del mensaje del usuario
		const userEmbeddingResponse = await axios.post(
			"https://api.openai.com/v1/embeddings",
			{
				model: "text-embedding-ada-002",
				input: text,
			},
			{
				headers: {
					Authorization: `Bearer ${this.openaiApiKey}`,
					"Content-Type": "application/json",
				},
			}
		);
		const userEmbedding = userEmbeddingResponse.data.data[0].embedding;

		// Guardar el embedding en Pinecone
		await axios.post(
			`${this.pineconeIndexUrl}/vectors/upsert`,
			{
				vectors: [
					{
						id: `${idUser}-${Date.now()}`,
						values: userEmbedding,
						metadata: { text, sender: `${idUser}`, idUser },
					},
				],
			},
			{
				headers: {
					"Api-Key": this.pineconeApiKey,
					"Content-Type": "application/json",
				},
			}
		);

		// Generar respuesta de ChatGPT
		const chatResponse = await axios.post(
			"https://api.openai.com/v1/chat/completions",
			{
				model: "gpt-4",
				messages: [
					{
						role: `system`,
						content: initialContext,
					},
					{
						role: `user`,
						content: text,
					},
				],
				max_tokens: 150,
			},
			{
				headers: {
					Authorization: `Bearer ${this.openaiApiKey}`,
					"Content-Type": "application/json",
				},
			}
		);
		const chatText = chatResponse.data.choices[0].message.content;

		// Generar embedding de la respuesta de ChatGPT
		const chatEmbeddingResponse = await axios.post(
			"https://api.openai.com/v1/embeddings",
			{
				model: "text-embedding-ada-002",
				input: chatText,
			},
			{
				headers: {
					Authorization: `Bearer ${this.openaiApiKey}`,
					"Content-Type": "application/json",
				},
			}
		);
		const chatEmbedding = chatEmbeddingResponse.data.data[0].embedding;

		// Guardar la respuesta de ChatGPT en Pinecone
		await axios.post(
			`${this.pineconeIndexUrl}/vectors/upsert`,
			{
				vectors: [
					{
						id: `${idUser}-${Date.now()}`,
						values: chatEmbedding,
						metadata: { text: chatText, sender: "chatgpt", idUser },
					},
				],
			},
			{
				headers: {
					"Api-Key": this.pineconeApiKey,
					"Content-Type": "application/json",
				},
			}
		);

		return { response: chatText };
	}

	async queryEmbeddings(idUser: number, queryText: string): Promise<any> {
		// Generar embedding del texto de consulta
		const queryEmbeddingResponse = await axios.post(
			"https://api.openai.com/v1/embeddings",
			{
				model: "text-embedding-ada-002",
				input: queryText,
			},
			{
				headers: {
					Authorization: `Bearer ${this.openaiApiKey}`,
					"Content-Type": "application/json",
				},
			}
		);
		const queryEmbedding = queryEmbeddingResponse.data.data[0].embedding;

		console.log("Query Embedding:", queryEmbedding);

		// Consultar Pinecone para obtener embeddings similares
		const queryResponse = await axios.post(
			`${this.pineconeIndexUrl}/query`,
			{
				topK: 20,
				vector: queryEmbedding,
				includeMetadata: true,
				filter: { idUser: idUser },
			},
			{
				headers: {
					"Api-Key": this.pineconeApiKey,
					"Content-Type": "application/json",
				},
			}
		);

		console.log("Results of query:", queryResponse.data);
		return queryResponse.data;
	}

	public async sendDirectMessage(initialPrompt: string, userText): Promise<any> {
		const chatResponse = await axios.post(
			"https://api.openai.com/v1/chat/completions",
			{
				model: "gpt-4o",
				messages: [
					{
						role: `system`,
						content: initialPrompt,
					},
					{
						role: `user`,
						content: userText,
					},
				],
				max_tokens: 4096, // Cantidad máxima de tokens en la respuesta
				temperature: 0.7,
			},
			{
				headers: {
					Authorization: `Bearer ${this.openaiApiKey}`,
					"Content-Type": "application/json",
				},
			}
		);
		const chatText = chatResponse.data.choices[0].message.content;

		console.log("ChatGPT Response:", chatText);

		return chatText;
	}
}
