<nb-card class="mb-0 no-edge-card">

	<app-loading [isVisible]="cargando"></app-loading>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left"
			[options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body class="py-4 px-0 d-flex">
		<div class="col-6">
			<div class="avatar" [ngStyle]="{'background-image': getUserAvatar(student?.pictureUser)}"></div>
		</div>
		<div class="col-6">
			<div class="col-12 col-md-6 form-group">
				<label for="username" class="label">{{ "USERDATA.NOMBRE" | translate }}</label>
				<p> {{ student?.nameUser }} </p>
			</div>
			<div class="col-12 col-md-6 form-group">
				<label for="surname" class="label">{{ "USERDATA.APELLIDOS" | translate }}</label>
				<p> {{ student?.surnameUser }} </p>
			</div>
			<div class="col-12 col-md-6 form-group">
				<label for="mail" class="label">{{ "USERDATA.EMAIL" | translate }}</label>
				<p> {{ student?.mail }} </p>
			</div>
		</div>
	</nb-card-body>

</nb-card>
