import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupModel } from '../../models/groups/groups-model';
import { id } from 'date-fns/locale';

export const SOCKETMESSAGES = {
	"OPEN" : "open_quiz",
	"CLOSE" : "close_quiz",
	"FINISH" : "finish_quiz",
	"CLOSESOCKET" : "close_socket",
	"USERSCOUNTER" : "counter_users",
	"ORDERMODLIST" : "order_mod_list",
	"ORDERMODAUTO" : "order_mod_auto",
	"SURVEYS" : "surveys",
	"CONTROL" : "control",
	"ACTTOSTART" : "act_about_to_start",
	"QUIZTOSTART" : "quiz_about_to_start",
	"CONTROLTOSTART" : "control_about_to_start",
}

@Injectable({
	providedIn: "root",
})
export class GruposService {
	constructor(private http: HttpClient) {}

	public runInterval = new BehaviorSubject<boolean>(false);

	public getRunInterval(): Observable<boolean> {
		return this.runInterval.asObservable();
	}

	public setRunInterval(value: boolean) {
		this.runInterval.next(value);
	}

	createGroup(group: GroupModel, image?: File | string) {
		const url = `group/createupdate/group`;
		const formData: FormData = new FormData();
		const q = {
			idGroup: group.idGroup,
			idProfessor: group.idProfessor,
			title: group.title,
			description: group.description,
			imagen: "",
			idLanguage: group.idLanguage,
			idCenter: group.idCenter,
			share: group.share,
			creationDate: group.creationDate,
			editDate: group.editDate,
			identification: group.identification,
			created_from_role: group.created_from_role,
			nivel: group.nivel,
			letras: group.letras,
			idSubject: group.idSubject,
		};

		formData.append("files", image);
		formData.append("group", JSON.stringify(q));
		return this.http.post<any>(url, formData);
	}

	updateGroup(group: GroupModel, image: string | File) {
		// @PostMapping("/group/createupdate/group")
		const url = `group/createupdate/group`;
		const formData: FormData = new FormData();
		const q = {
			idGroup: group.idGroup,
			idProfessor: group.idProfessor,
			title: group.title,
			description: group.description,
			imagen: group.imagen,
			idLanguage: group.idLanguage,
			idCenter: group.idCenter,
			share: group.share,
			creationDate: group.creationDate,
			editDate: group.editDate,
			identification: group.identification,
			created_from_role: group.created_from_role,
			nivel: group.nivel,
			letras: group.letras,
			idSubject: group.idSubject,
		};

		formData.append("files", image);
		formData.append("group", JSON.stringify(q));
		return this.http.post<any>(url, formData);
	}

	/**
	 *
	 * @param group de tipo GroupModel
	 * @returns
	 */
	deleteGroup(group: GroupModel) {
		// @PostMapping("/group/createupdate/group") const q = {
		const url = `group/deletegroup`;
		const q = {
			idGroup: group.idGroup,
			nameImage: group.imagen,
		};
		return this.http.post<any>(url, q);
	}

	getGrupos() {
		const url = `group/getgroups`;
		return this.http.get<any>(url);
	}

	getGruposByCenter(idCenter: any) {
		const url = `group/getgroups/${idCenter}`;
		return this.http.get<any>(url);
	}

	getGruposByUser(idUser: any, idCenter: any) {
		const url = `group/getgroupsbyid/${idUser}/${idCenter}`;
		return this.http.get<any>(url);
	}

	getListEstudiantes(idGrupo: number) {
		const url = `group/studentsListByGroup/${idGrupo}`;
		return this.http.get<any>(url);
	}

	getListEstudiantesEnClase(idGrupo: number) {
		const url = `group/getstudentslistgroupsession/${idGrupo}`;
		return this.http.get<any>(url);
	}

	getListadoEstudiantesInscribir(idGrupo: number, filter: string) {
		const url = `group/StudentsNotAsigned`;
		const q = {
			idGroup: idGrupo,
			filter: filter,
		};
		return this.http.post<any>(url, q);
	}

	//  Endpoint para invitar al estudiante
	getInvitarEstudiante(idGrupo: number, idUser: number) {
		const url = `group/inviteStudentToGroup/${idGrupo}/${idUser}`;
		return this.http.get<any>(url);
	}

	//  Endpoint para obtener el listado de peticiones de grupos como estudiante
	getPeticionesEstudiante() {
		const url = `group/groupInviteList`;
		return this.http.get<any>(url);
	}

	//Endpoint de listado de peticiones para acceder al grupo como profesor
	getPeticionesProfesor(idGroup: number) {
		const url = `group/studentsInviteList/${idGroup}`;
		return this.http.get<any>(url);
	}

	filtrarEstudiantesBusqueda(idGroup: number, filter: any) {
		const url = `group/studentsnotasignedidentification`;
		const q = {
			idGroup: idGroup,
			filter: filter.filtrado,
		};
		return this.http.post<any>(url, q);
	}

	filtrarEstudiantesBusqueda2(idGroup: number, filter: any) {
		const url = `group/studentsnotasignedidentification2`;
		const q = {
			idGroup: idGroup,
			filter: filter.filtrado,
		};
		return this.http.post<any>(url, q);
	}

	filtrarCursosBusqueda(idGroup: number, filter: any, idTeacher: number) {
		const url = `group/targetListNotAsignedToGroup`;
		const q = {
			idGroup: idGroup,
			filter: filter.filtrado,
			idTeacher: idTeacher,
		};
		return this.http.post<any>(url, q);
	}

	filtrarTargetsCompetition(filter: any, idGroup: number) {
		const url = `group/targetlistcompetition`;
		const q = {
			filter: filter.filtrado,
			idGroup: idGroup,
		};
		return this.http.post<any>(url, q);
	}

	filtrarTargetsCenter(filter: any, idCenter: number) {
		const url = `group/targetlistcenter/${idCenter}`;
		const q = {
			filter: filter.filtrado,
			idCenter: idCenter,
		};
		return this.http.post<any>(url, q);
	}

	/////////////////////////
	//  Como profesor
	/////////////////////////
	/**
	 *
	 * @param idGrupo
	 * @param idUser
	 * @returns
	 */
	aceptarInvitacion(idGrupo: number, idUser: number) {
		const url = `group/acceptInvitation/teacher`;
		const q = {
			idGroup: idGrupo,
			idStudent: idUser,
		};
		return this.http.post<any>(url, q);
	}

	/**
	 *
	 * @param idGrupo
	 * @param idUser
	 * @returns
	 */
	rechazarInvitacion(idGrupo: number, idUser: number) {
		const url = `group/deleteInvitation/teacher`;
		const q = {
			idGroup: idGrupo,
			idStudent: idUser,
		};
		return this.http.post<any>(url, q);
	}

	aceptarInvitacionEstudiante(idGrupo: number, idUser: number) {
		const url = `group/acceptInvitation/student`;
		const q = {
			idGroup: idGrupo,
			idStudent: idUser,
		};
		return this.http.post<any>(url, q);
	}

	rechazarInvitacionEstudiante(idGrupo: number, idUser: number) {
		const url = `group/deleteInvitation/student`;
		const q = {
			idGroup: idGrupo,
			idStudent: idUser,
		};
		return this.http.post<any>(url, q);
	}

	eliminarEstudiante(idGrupo: number, idUser: number) {
		const url = `group/deleteStudent`;
		const q = {
			idGroup: idGrupo,
			idStudent: idUser,
		};
		return this.http.post<any>(url, q);
	}

	getGruposEstudiante() {
		const url = `group/groupsListByStudent`;
		return this.http.get<any>(url);
	}

	// ENDPOINT PARA OBTENER LISTA DE INVITACIONES A GRUPOS DE UN ESTUDIANTE
	// URL:
	// api-dev-pruebas/rest/group/groupInviteList/{idStudent}
	// PARAMETROS GET:
	// idStudent : String

	getGruposInvitaciones() {
		const url = `group/groupInviteList`;
		return this.http.get<any>(url);
	}

	// ENDPOINT PARA BUSCAR UN GRUPO POR NOMBRE
	// URL:
	// api-dev-pruebas/rest/group/groupListNotAsigned/{idStudent}
	// PARAMETROS – BODY POST:
	// {
	//     "filter":"ja"
	// }

	getGruposFiltradoEstudiante(filter: any) {
		const url = `group/groupListNotAsigned`;
		const q = {
			filter: filter.filtrado,
		};
		return this.http.post<any>(url, q);
	}

	// ENDPOINT PARA SOLICITAR EL REGISTRO DE UN ESTUDIANTE A UN GRUPO
	// URL:
	// api-dev-pruebas/rest/group/requestStudentToGroup/{idGroup}/{idStudent}
	solicitarUnirGrupo(idGroup: number) {
		const url = `group/requestStudentToGroup/${idGroup}`;

		return this.http.get<any>(url);
	}

	getListCursos(idGrupo: number) {
		const url = `group/coursesListByGroup/${idGrupo}`;
		return this.http.get<any>(url);
	}

	getAgregarCurso(idGrupo: number, idCourse: number) {
		const url = `group/addCourseToGroup/${idGrupo}/${idCourse}`;
		return this.http.get<any>(url);
	}

	agregarCurso(idGrupo: number, idCourse: number) {
		const url = `group/addAllTargetToGroup/${idGrupo}/${idCourse}`;
		return this.http.get<any>(url);
	}

	agregarTarget(idGrupo: number, idCourse: number, idTarget: number) {
		const url = `group/addTargetToGroup/${idGrupo}/${idCourse}/${idTarget}`;
		return this.http.get<any>(url);
	}

	eliminarCurso(idGrupo: number, idCourse: number) {
		const url = `group/deleteGroupsCourses/${idGrupo}/${idCourse}`;
		return this.http.delete<any>(url);
	}

	eliminarGrafo(idGrupo: number, idCourse: number, idTarget: number) {
		const url = `group/deleteTargetToGroup/${idGrupo}/${idCourse}/${idTarget}`;
		return this.http.delete<any>(url);
	}

	/**
	 * Tenemos que revisar si cuando el alumno entra en su grupo, existe creada una sesión de Actividades Instantáneas (socket) para poder comenzar a realizar el examen
	 * @param idGroup
	 * @returns
	 */
	getIfSessionExists(idGroup: number, hex?): Observable<any> {
		let url = "";
		if (hex) {
			url = `group/existsession/${idGroup}/${hex}`;
		} else {
			url = `group/existsession/${idGroup}/null`;
		}

		return this.http.get<any>(url);
	}

	/**
	 * Tenemos que revisar si cuando el alumno entra en su grupo, existe creada una sesión del estudiante en Actividades Instantáneas (socket) para poder comenzar a realizar el examen
	 * @param idGroup
	 * @returns
	 */
	getIfSessionExistsByUser(idGroup: number): Observable<any> {
		const url = `group/existSessionByUser/${idGroup}`;
		return this.http.get<any>(url);
	}

	/**
	 * Función obtiene los usuarios conectados a la sesion del grupo
	 * @param idGroup ID del grupo
	 * @returns
	 */
	getGroupSession(idGroup): Observable<any> {
		const url = `group/getgroupsession/${idGroup}`;
		return this.http.get(url);
	}

	/**
	 * Función que crea una sesion para poder realizar actividades mediante WebSocket
	 * @param idGroup ID del grupo
	 * @returns
	 */
	createGroupSession(idGroup, hex?, isTeacher?): Observable<any> {
		const url = "group/creategroupsession";
		const body = {
			idGroup: idGroup,
			idCompSession: hex ? hex : "null",
			teacher: isTeacher ? isTeacher : false,
		};
		return this.http.post<any>(url, body);
	}

	/**
	 * Función que
	 * @param idGroup ID del grupo
	 * @returns
	 */
	deleteGroupSession(idGroup: number): Observable<any> {
		const url = `group/deletegroupsession/${idGroup}`;
		return this.http.delete(url);
	}

	/**
	 * Función para mandar mensaje al servidor para distribuirlo a través del socket y que llegue a los alumnos
	 * @param idQuiz ID del quiz
	 * @param idGroup ID del grupo, que representará la room donde esté incluida la sesion del usuario
	 * @param action Acción: open_quiz,closed_quiz,finish_quiz
	 */
	sendDataToServerSocket(
		idQuiz: number,
		idGroup: number,
		action: string,
		idTarget?: number,
		idCourse?: number,
		type?: string
	): Observable<any> {
		let urlParams: HttpParams = new HttpParams()
			.set("idQuiz", idQuiz.toString())
			.set("room", idGroup.toString());
		if (idTarget >= 0) {
			urlParams = urlParams.append("idTarget", idTarget.toString());
		}
		if (idCourse >= 0) {
			urlParams = urlParams.append("idCourse", idCourse.toString());
		}
		urlParams = urlParams.append("type", type);
		const url = `socket/sendMsgToRoomClients/${action}`;
		return this.http.post<any>(url, {}, { params: urlParams });
	}


	/**
	 * METODO PARA TRAER LISTADO DE GRAFOS QUE ESTAN EN UN GRUPO
	 * @PostMapping("/targetListAsignedToGroup")
	 */
	targetListAsignedToGroup(idGroup: number, filter: String): Observable<any> {
		const url = `group/targetListAsignedToGroup`;
		const body = {
			idGroup: idGroup,
			filter: filter,
		};
		return this.http.post<any>(url, body);
	}

	/**
	 * METODO PARA TRAER LISTADO DE GRAFOS QUE ESTAN ASIGNADOS A UN EVENTO
	 * @PostMapping("/targetListAsignedToGroup")
	 */
	targetAsignedToEvent(idEvent: number): Observable<any> {
		const url = `group/targetasignedtoevent/${idEvent}`;
		return this.http.get(url);
	}

	/**
	 * METODO PARA TRAER LISTADO DE GRAFOS DISPONIBLES PARA COMPETICIONES
	 * @PostMapping("/targetlistcompetition")
	 */
	targetListCompetition(filter: String, idGroup?: number): Observable<any> {
		const url = `group/targetlistcompetition`;
		const body = {
			filter: filter,
			idGroup: idGroup ? idGroup : 0,
		};
		return this.http.post<any>(url, body);
	}

	/**
	 * Función para cambiar el nombre de la sesión cuando el profesor abre un socket
	 * @param idSession ID de la session
	 * @param nameSession Nombre nuevo de la sesión
	 * @returns
	 */
	updateNameSession(
		idSession: number,
		nameSession: string,
		idGroup: number
	): Observable<any> {
		const url = `group/updatenamegroupsession/${idSession}`;
		const body = {
			nameSession: nameSession,
			idGroup: idGroup,
		};
		return this.http.put(url, body);
	}

	/**
	 *
	 * @param idTaget ID del grafo
	 * @param idGroup ID del grupo (sala/room del socket)
	 * @returns
	 */
	sendChallengeListModeSocket(
		idTaget: number,
		idGroup: number,
		time: number
	): Observable<any> {
		const urlParams: HttpParams = new HttpParams()
			.set("idTarget", idTaget.toString())
			.set("room", idGroup.toString())
			.set("time", time.toString());
		const url = `socket/sendMsgToRoomClientsFreeOrder/order_mod_list`;
		return this.http.post<any>(url, {}, { params: urlParams });
	}

	/**
	 * Función para crear el orden de los quizzes cuando se selecciona un grafo con la opción aleatoria
	 * @param idTaget ID del grafo
	 * @param idGroup ID del grupo (sala/room del socket)
	 * @param idCase Número con la configuración elegida ( 1. Por defecto, sincronizado y aleatorio - 2. Sincronizado y no aleatorio - 3. No sincronizado y si aleatorio - 4. No sincronizado y no aleatorio)
	 * @returns
	 */
	sendModAutoChallengeSocket(
		idTaget: number,
		idGroup: any,
		idCase: number
	): Observable<any> {
		const urlParams: HttpParams = new HttpParams()
			.set("idTarget", idTaget.toString())
			.set("room", idGroup.toString())
			.set("idCase", idCase.toString());
		const url = `socket/sendMsgToRoomClientsAutoOrder/order_mod_auto`;
		return this.http.post<any>(url, {}, { params: urlParams });
	}

		/**
	 * Endpoint para enviar mensajes a todos los clientes para enviar a los estudiantes la encuesta seleccionada por el profesor
	 * @param idSurvey ID de la encuesta
	 * @param idEvent ID del evento
	 * @param anonymous si la encuesta es anonima
	 * @param idGroup ID del grupo (sala/room del socket)
	 * @returns
	 */
		sendMsgToRoomClientsSurvey(
			idSurvey: number,
			idEvent: any,
			anonymous: number,
			idGroup: any,
		): Observable<any> {
			const urlParams: HttpParams = new HttpParams()
				.set("idSurvey", idSurvey.toString())
				.set("idEvent", idEvent.toString())
				.set("anonymous", anonymous.toString())
				.set("room", idGroup.toString());
			const url = `socket/sendMsgToRoomClientsSurvey`;
			return this.http.post<any>(url, {}, { params: urlParams });
		}


	/** Endpoint para enviar mensajes a todos los clientes para enviar a los estudiantes la encuesta seleccionada por el profesor
	 * @param message Mensaje a enviar
	 * @param room ID del grupo (sala/room del socket)
	 * @param idGroup ID del grupo (sala/room del socket)
	 * @returns
	 *
	 *
	 * *
	 */
	sendMsgToRoomClients(
		idGroup: any,
		message: any,
		room: any,
	): Observable<any> {
		const urlParams: HttpParams = new HttpParams()
			.set("idGroup", idGroup.toString())
			.set("message", message.toString())
			.set("room", room.toString());
		const url = `socket/SendMsgToRoomClients`;
		return this.http.post<any>(url, {}, { params: urlParams });
	}

	getClientsInRoom(room: any): Observable<any> {
		const urlParams: HttpParams = new HttpParams()
		.set("room", room.toString());
		const url = `socket/getconnectedclients`;
		return this.http.post<any>(url, {}, { params: urlParams });
	}

	/**
	 * Función para obtener los desafíos que ha generado el profesor
	 * @param idGroup ID del grupo
	 * @returns
	 */
	getChallenges(idGroup: number): Observable<any> {
		const url = `group/getchallengesbygroup/${idGroup}`;
		return this.http.get(url);
	}

	deleteChallenge(idGroup: number, idSession: number) {
		const url = `group/deletegroupsession/${idGroup}/${idSession}`;
		return this.http.delete(url);
	}

	createAttendance(idGroup: number): Observable<any> {
		const hoy = new Date();
		const url = `group/createAttendance`;
		const body = {
			idGroup: idGroup,
			fechaHoy: hoy,
		};	
		return this.http.post<any>(url, body);
	}
	

	reponseAttendance(idGroup: number, status: number): Observable<any> {
		let hoy = new Date();
		const url = `group/reponseAttendance`;
		const body = {
			idGroup: idGroup,
			status: status,
			fechaHoy: hoy,
		};
		return this.http.post<any>(url, body);
	}

	changeAttendance(
		idGroup: number,
		status: number,
		idUser: number,
		fecha: string,
		motivo: string
	): Observable<any> {
		let hoy = new Date();
		const url = `group/changeAttendance`;
		const body = {
			idGroup: idGroup,
			status: status,
			fechaHoy: hoy,
			idUser: idUser,
			fecha: fecha,
			motivo: motivo,
		};
		return this.http.post<any>(url, body);
	}

	receiveCallAttendance(idGroup: number, idProfile: number): Observable<any> {
		let hoy = new Date();
		const url = `group/receiveCallAttendance`;
		const body = {
			idGroup: idGroup,
			fechaHoy: hoy,
			idProfile: idProfile,
		};
		return this.http.post<any>(url, body);
	}

	userAttendanceHistorial(idUser: number): Observable<any> {
		const url = `group/userAttendanceHistorial/${idUser}`;
		return this.http.get(url);
	}

	classAttendanceGroup(idGroup: number, last30Days: number): Observable<any> {
		const url = `group/classAttendanceGroup`;
		const body = {
			idGroup: idGroup,
			last30Days: last30Days,
		};
		return this.http.post<any>(url, body);
	}

	summary(
		idGroup: number,
		idUser: number,
		startDate: string,
		endDate: string
	): Observable<any> {
		const url = `group/summary/${idGroup}/${idUser}/${startDate}/${endDate}`;
		return this.http.get(url);
	}

	/**
	 * Endpoint para crear o actualizar eventos.
	 * @param idGroup ID del grupo
	 * @returns
	 */
	createUpdateEvent(event: any): Observable<any> {
		const url = "group/createupdateevent";
		const body = {
			idEvent: event.idEvent ? event.idEvent : null,
			fecha_Inicio: event.Fecha_Fin,
			fecha_Fin: event.Fecha_Inicio,
			createDate: event.createDate ? event.createDate : null,
			idCenter: event.IdCenter,
			idCourse: event.IdCourse,
			idGroup: event.IdGroup,
			idSubject: event.idSubject,
			idTarget: event.IdTarget,
			idSurvey: event.IdSurvey,
			idUser: event.IdUser,
			name: event.Name,
			nivel: event.Nivel,
			realizado: event.Realizado == 1 ? true : false,
		};
		return this.http.post<any>(url, body);
	}

	getCenterEvents(idCenter: number): Observable<any> {
		const url = `group/geteventlistbyidcenter/${idCenter}`;
		return this.http.get<any>(url);
	}

	deleteEventByIdEvent(idEvent: string | number): Observable<any> {
		const url = `group/deleteeventbyidevent/${idEvent}`;
		return this.http.delete(url);
	}

	getEventsProfesor(idGroups: any[]): Observable<any> {
		const url = `group/geteventsprofessor`;
		const body = {
			idGroups: idGroups,
		};
		return this.http.post<any>(url, body);
	}

	/**
	 * Función para cambiar el nombre de la sesión cuando el profesor abre un socket
	 * @param idSession ID de la session
	 * @param nameSession Nombre nuevo de la sesión
	 * @returns
	 */
	finishEvent(
		idEvent: number
	): Observable<any> {
		const url = `group/finishcontrol/${idEvent}`;
		const body = {
		};
		return this.http.put(url,body);
	}


	createStructure(numberStudents: number, numberTeachers: number, centerName: string): Observable<any> {
		const url = `group/createcenterpresentation`;
		const body = {
			studentNumber: numberStudents,
			teacherNumber: numberTeachers,
			centerName: centerName,
		};
		return this.http.post<any>(url, body);
	}

	getScheduleComentary(idGrupo : number, fecha : string): Observable<any> {
		const url = `group/getScheduleComentary/${idGrupo}/${fecha}`;
		return this.http.get<any>(url);
	}

	createScheduleComentary(comentary: string, comentaryDate: string, idGroup: number): Observable<any> {
		const url = `group/createScheduleComentary`;
		const body = {
			comentary: comentary,
			comentaryDate: comentaryDate,
			idGroup: idGroup,
		};
		return this.http.post<any>(url, body);
	}

	getmailsbygroup(idGrupo: number): Observable<any> {
		const url = `group/getmailsbygroup/${idGrupo}`;
		return this.http.get<any>(url);
	}

	/**
 * Endpoint para recibir el listado de tareas asignadas a un dia
 * @param idNode
 * @param idGroup
 * @param fecha
 * @return
 * @author jdela
 */
	getTaskCalendar(idGroup: number, fecha: any): Observable<any> {
		return this.http.get(`group/getTaskCalendar/${idGroup}/${fecha}`);
	}

	deleteTaskCalendar(idTaskCalendar: number, isNode: boolean): Observable<any> {
		const url = `group/deleteTaskCalendar/${idTaskCalendar}/${isNode}`;
		return this.http.delete(url);
	}

	quizsimpleinformestudent(idQuiz: number): Observable<any> {
		return this.http.get(`group/quizsimpleinformestudent/${idQuiz}`);
	}

}
