<!-- <h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    {{data.message}}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{data.okButton ? data.okButton : ('ACEPTARCANCELAR.ACEPTAR' | translate)}}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{data.koButton ? data.koButton : ('ACEPTARCANCELAR.CANCELAR' | translate)}}</button>
</mat-dialog-actions> -->


<nb-card class="no-edge-card">
		<nb-card-header class="border-bottom-0">
			<h6 *ngIf="data.title">{{data.title}}</h6>
		</nb-card-header>
		<nb-card-body>
				<div class="row">
						<div class="col-12">
								<p class="paragraph">
									{{data.message}}
								</p>
						</div>
				</div>
		</nb-card-body>
		<nb-card-footer class="border-top-0">
				<div class="row">
						<div class="col-12 flex justify-content-center gap-3">
							<button  nbButton class="btn-themecolor" (click)="closeModalFalse()">
								{{data.koButton ? data.koButton : ('ACEPTARCANCELAR.CANCELAR' | translate)}}
							</button>
							<button nbButton class="btn-red" (click)="closeModalTrue()">
								{{data.okButton ? data.okButton : ('ACEPTARCANCELAR.ACEPTAR' | translate)}}
							</button>
						</div>
				</div>
		</nb-card-footer>
</nb-card>

