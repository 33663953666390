import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { HttpClient } from '@angular/common/http';
import { NbDialogRef } from '@nebular/theme';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-modal-report-quiz.component',
    templateUrl: './modal-report-quiz.component.html',
    styleUrls: ['./modal-report-quiz.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalReportQuizComponent implements OnInit {
	comentary: string = '';
	shareMail: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		public loginService: LoginService,
		public translateService: TranslateService,
		private dialogRef: NbDialogRef<any>,
	) {}

	ngOnInit() {}

	closeModal(sendData?: any) {
		this.dialogRef.close(false);
	}

	showStatus() {}

	sendComments() {
		let data = {
			comentary: this.comentary,
			shareMail: this.shareMail,
		}
		this.dialogRef.close(data);
	}

}
