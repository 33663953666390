<div class="h-100 w-100 file-style">
	<div class="h-100 w-100" id={{format.name}} [style.background]="format.background" [style.backgroundSize]="'cover'"
		[style.backgroundRepeat]="'no-repeat'" [style.backgroundPosition]="'center'" (click)="playFile()">

		<ng-container *ngIf="!(format.type === AUDIO_TYPE && node.nodesFiles.spotifyAudio)">
			<div class="w-100 h-100 pointer flex align-items-center justify-content-center flex-column"
				for="{{ format.id }}">
				<mat-icon class="medios pointer" nbTooltip="{{ format.name | translate }}" nbTooltipPlacement="bottom">
					{{ format.icon}}
				</mat-icon>
			</div>
		</ng-container>

		<ng-container *ngIf="(format.type === AUDIO_TYPE && node.nodesFiles.spotifyAudio)">
			<div class="w-100 h-100 pointer flex align-items-center justify-content-center flex-column"
				for="{{ format.id }}">
				<mat-icon class="medios pointer" nbTooltip="{{ 'SPOTIFYAUDIO.LISTEN' | translate }}"
					nbTooltipPlacement="bottom" svgIcon="spotify" (click)="goSpotify(node.nodesFiles.spotifyAudio)">
				</mat-icon>
			</div>
		</ng-container>

	</div>
</div>
