<div class="w-100" #step8>
	<ng-template class="w-100">
		<div class="copilot-custom copilop-info" style="position: sticky">
			<div class="row flex justify-content-center align-items-center">
				<div class="col-12 row flex justify-content-center align-items-center">
					<h3 class="title-info">
						{{ "TUTORIALINICIO.TUTORIAL_TITLE_08" | translate }}
					</h3>
				</div>
				<div class="col-12 row flex justify-content-center align-items-center">
					<p class="text-info-help pr-2">
						{{ "TUTORIALINICIO.TUTORIAL_TEXTO_008" | translate }}
					</p>
				</div>
			</div>
			<div class="footer-btn">
				<div class="row flex justify-content-center align-items-center">
					<div class="col-2">
						<button class="btn btn-block btn-themecolor btn-sm button-ok" (click)="done()" type="button">
							{{ "TUTORIALINICIO.BOTONES.ENTENDIDO" | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>

<div class="right-files" >
	<div class="d-flex flex-column gap-3 px-3 w-100 h-100">
		<div *ngFor="let format of formats; let last = last; let i = index" class="file-button">
			<div class="d-flex w-100" [style.visibility]="format.visible ? 'visible' : 'hidden'">
				<app-node-detail-files-uploader class="w-100" [format]="format" [progress]="format.progress"
					(filesUploaderFileSelected)="onFileSelected($event)" (filesUploaderPlayFile)="reproducirArchivo($event)"
					(filesUploaderVideoPreview)="onVideoPreview($event)" (filesUploaderDeleteFile)="onFileDelete($event)"
					(filesYoutubeVideo)="onYoutubeVideo($event)" (filesInstagramVideo)="onInstagramVideo($event)"
					(filesTiktokVideo)="onTiktokVideo($event)" (filesUploaderDownloadFile)="onFileDownload($event)"
					(filesSpotifyAudio)="onSpotifyAudio($event)"
					[node]="node">
				</app-node-detail-files-uploader>
			</div>
		</div>
	</div>
</div>
