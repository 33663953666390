import { Component, OnInit, EventEmitter, Output } from '@angular/core';
// Models
import { QuizzesCourseModel } from 'src/app/core/models/quizzes/quizzes-course.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';
import { VariablesPublicUtils } from 'src/app/core/utils/variablesPublic.utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';


@Component({
    selector: 'app-footer-reproducir-quizzes',
    templateUrl: './footer-reproducir-quizzes.component.html',
    styleUrls: ['./footer-reproducir-quizzes.component.scss'],
    standalone: false
})

export class FooterReproducirQuizzesComponent implements OnInit {
  @Output() quizEnviado:EventEmitter<any> = new EventEmitter<any>();
  @Output() mostrar:EventEmitter<boolean> = new EventEmitter<boolean>();

  id:string;
  quizCourse:QuizzesCourseModel[];
  quizzes:QuizzesCourseModel[]=[];
  quizzesBloque:QuizzesCourseModel[];
  templateQuiz:any;
  templateElementQuiz:any;
  posIni;
  posEnd;
  cantQuizzes:number;
  widthQuiz:number;
  cargando:boolean;
  maxQuizzes:number;
  previus:boolean;
  next:boolean;
  widthPrevius:number;
  userQuiz;
  cant:number;
  public quiz;
  public idUser;
  public variablesPublicUtils;
  public node;
  currentGraph:any;
  automatic:any;
  type:any;
  executeAutomatic:any;

  constructor(public quizService: QuizzesService,
              public loginService: LoginService,
              public quizzesStackService: QuizzesstackService) {
                this.quizService.currentQuiz.subscribe((n) => {
                  this.quiz = n;
                });
                this.quizService.currentGraph.subscribe((g) => {
                  this.currentGraph = g;
                });
                this.quizService.automatic.subscribe((a:boolean)=>{
                  this.automatic = a;
                 });
                this.quizService.currentNode.subscribe((n) => {
                  this.node = n;
                });
                this.quizService.type.subscribe((t) => {
                  this.type = t;
                });
                this.quizService.executeAutomatic.subscribe((ea) => {
                    this.executeAutomatic = ea;
                });
    }

  ngOnInit() {
    localStorage.getItem('automatic');
    this.automatic=JSON.parse(localStorage.getItem('automatic'));
    //if(this.automatic && this.type=="node"){
    if(this.type=="node" && this.loginService.esEstudiante()){
      this.cargando = true;
      this.quizService.setLoading(this.cargando);
      this.maxQuizzes=40;
      this.previus=false;
      this.next=false;
      this.idUser=this.loginService.getUser().idUser;
      this.variablesPublicUtils = new VariablesPublicUtils();
      this.obtenerDatosQuizzesNode(this.currentGraph);
      this.cant=0;
    }
  }

  obtenerDatosQuizzesNode(graph:any) {
    //OBSERVACION AVECES EL GRAFO GRABA EN NODO EN EL TARGET Y AVECES EN SOURCE
    var node = 'n'+ this.node.idOriginal;
    var edges = graph.edges.filter((edge) => edge.connectionType = "QuizzesNodes");
    var quizzesNodes = edges.filter((edge) => ((edge.target.includes('n') && edge.target==node) || (edge.source.includes('n') && edge.source==node)));

    quizzesNodes.forEach((quiz)=>{
        if(quiz.source.includes('q')){
            quiz.idQuiz=parseInt(quiz.source.substring(1,));
            quiz.idCourse=graph.idCourse;
            quiz.idTarget=graph.idTarget;
        }else if(quiz.target.includes('n')){
            quiz.idQuiz=parseInt(quiz.target.substring(1,));
            quiz.idCourse=graph.idCourse;
            quiz.idTarget=graph.idTarget;
        }
    });

    /*quizzesNodes.forEach((quiz) => {
        this.obtenerDatosQuizzesTarget(quiz.idTarget,quiz.idQuiz,quizzesNodes);
    });*/

    for (let i = 0; i < quizzesNodes.length; i++) {
        var quiz = quizzesNodes[i];
        this.obtenerDatosQuizzesTarget(quiz.idTarget,quiz.idQuiz,quizzesNodes);
    };

     this.cargando = false;
     this.quizService.setLoading(this.cargando);
  }

  obtenerDatosQuizzesTarget(idTarget, idQuiz, quizzesNodes) {

  }

  public showQuiz(idQuiz:number){
    this.executeAutomatic=JSON.parse(localStorage.getItem('executeAutomatic'));
     if(this.executeAutomatic){
        var userQuiz={
            idUser:this.loginService.getUser().idUser,
            idQuizOriginal:idQuiz,
            idTarget:this.currentGraph.idTarget
          };
          this.quizService.setUserQuiz(userQuiz);
     }
   }

  getColor(quiz:any) {
    if(quiz.quizz[0].usersQuizzes[0]){
        quiz.score = quiz.quizz[0].usersQuizzes[0].score;
    }else{
        quiz.score = null;
    }
    if(quiz.score == null){
        return this.variablesPublicUtils.LEAD;//plomo
    }
    if(0<=quiz.score && quiz.score<=8){
        return this.variablesPublicUtils.RED;//rojo
    }
    if(9<=quiz.score && quiz.score<=21){
        return this.variablesPublicUtils.YELLOW;//amarillo
    }
    if(22<=quiz.score){
        return this.variablesPublicUtils.GREEN;//verde
    }

    /*if(this.cant==this.quizzesBloque.length){
       this.cargando = false;
       this.quizService.setLoading(this.cargando);
    }*/
  }

   getWidth() {
     let width = this.widthQuiz + "px";
     return width;
    }

    getWidthPrevius() {
        let width = this.widthPrevius + "px";
        return width;
       }

    getWidthNext() {
        let width = this.widthPrevius + "px";
        return width;
       }

    toPrevius(){
        this.calculateWidthQuizPrevius(this.quizzes);
    }

    toNext(){
        this.calculateWidthQuizNext(this.quizzes,this.posIni,this.posEnd);
    }

    calculateWidthQuizPrevius(quizzes:QuizzesCourseModel[]){
        var posEnd=this.posIni;
        var posIni=this.posIni-38;
        //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
        this.quizzesBloque=quizzes.slice(posIni,posEnd);
        this.cantQuizzes=this.quizzesBloque.length;
        this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
        //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        this.previus=true;
        this.next=true;
    }

    calculateWidthQuizNext(quizzes:any[], posIni:number, posEnd:number){
        this.posEnd=posEnd;
        if(posEnd>this.maxQuizzes){
            if(posIni==0){
                this.quizzesBloque=quizzes.slice(posIni,38);
                this.posIni=38;
            }else if (this.posIni>=38){
                 var dif = quizzes.length-this.posIni;
                 if(dif<=38){
                    this.posIni=this.posIni;
                    this.quizzesBloque=quizzes.slice(this.posIni,quizzes.length);
                 }else{
                    this.posIni=posIni;
                    this.posEnd=posIni+38;
                    this.quizzesBloque=quizzes.slice(this.posIni,this.posEnd);
                 }
            }
            this.previus=true;
            this.next=true;
            this.cantQuizzes=this.quizzesBloque.length;
            this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            //this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
            this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }else{
            this.quizzesBloque=quizzes.slice(posIni,posEnd);
            this.cantQuizzes=this.quizzesBloque.length;
            //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes))
            //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }

        for (let index = 0; index < this.quizzesBloque.length; index++) {
            const element = this.quizzesBloque[index];
            element.color = this.getColor(element);
            element.width = this.getWidth();
            this.quizzesBloque[index] = element;
        };
        /*this.cargando = false;
        this.quizService.setLoading(this.cargando);*/
        this.quizService.setArrayQuiz(this.quizzesBloque);
    }

    getAlternativeQuiz(arrayQuizzes:any){
        var min = 0
        var max =arrayQuizzes.length -1
        var i = Math.floor(Math.random() * (max - min) + min);
        return arrayQuizzes[i];
    }

    getQuizStack(userQuiz:any){}

    showQuizStack(idQuiz:any,userQuiz:any){}

    getIndice(arrayQuiz,idQuiz) {
        var indice = -1;
        arrayQuiz.filter(function (quiz, i) {
            if (quiz.linkedIdQuiz === idQuiz) {
                indice = i;
            }
        });
        return indice;
    }
}
