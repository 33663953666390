import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-radar',
    templateUrl: './modal-radar.component.html',
    styleUrls: ['./modal-radar.component.scss'],
    standalone: false
})
export class ModalRadarComponent implements OnInit {

  images: Array<string> = []

  constructor(public activeModal: NgbActiveModal) {
    this.images = [ '../../../../assets/images/demo_images/radar.png' ]
  }

  ngOnInit() {}

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

}
