import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { MastersService } from 'src/app/core/services/masters';

@Component({
    selector: "app-modal-tutorial-manager",
    imports: [CommonModule, NbCardModule, NbIconModule, NbTooltipModule, TranslateModule, FormsModule, NbInputModule, NbButtonModule],
    templateUrl: "./modal-tutorial-manager.component.html",
    styleUrls: ["./modal-tutorial-manager.component.scss"],
    encapsulation: ViewEncapsulation.None
})

export class ModalTutorialManagerComponent implements OnInit{

	contextList: any[] = [];
	videoList: any[] = [];
	selectedContext: any = null;
	indexSelectedContext: number = 0;
	step: number = 1;
	videosDisponibles: any[] = [];
	videoTitle: string = '';
	videoUrl: string = '';
	isValidUrl: boolean = false;
	isValidTitle: boolean = false;
	isMobile: boolean = false; //si el dispositivo es movil o escritorio

	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private modalService: NgbModal,
		private groupService: GruposService,
		private toaster: ToasterService,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		this.loadContextList();
	}

	getVideoId(url: string): string {
		const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^?&]+)/;
		const match = url.match(regExp);
		return match && match[1] ? match[1] : '';
	}

	//===========================================
	//===== PANTALLA 1 - LISTA DE CONTEXTOS =====

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	loadContextList(){
		this.masterService.getContextListForTutorial().subscribe( res => {
			if(res.data){
				this.contextList = res.data;
				//console.log(this.contextList);
			}
		})
	}

	loadVideoList(context: any, index: number){
		this.selectedContext = context;
		this.indexSelectedContext = index;
		this.videoList = context.videoList;
		this.step = 1;
	}

	verVideosDisponibles(context: any){
		this.masterService.getVideosAvailablesForContext(context.idTutorialContext).subscribe( res => {
			if(res.data){
				this.videosDisponibles = res.data;
				this.step = 2;
				//console.log(this.videosDisponibles);
			}
		})
	}

	onDeleteContextVideo(video){
		this.masterService.deleteTutorialContextVideo(this.selectedContext.idTutorialContext, video.idTutorialVideo).subscribe( res => {
			if(res.data > 0){
				this.videoList = this.videoList.filter(v => v.idTutorialVideo !== video.idTutorialVideo);
				this.loadContextList();
				setTimeout(() => {
					this.selectedContext = this.contextList[this.indexSelectedContext];
				}, 300)
			}
		})
	}

	changeOrdinal(index: number, direction: string){
		let from = index + 1; //ordinal origen
		let to = 0; //ordinal destino
		let index2 = 0; //indice destino
		let idTutorialContext = this.selectedContext.idTutorialContext;
		switch (direction) {
			case 'up':
				to = index;
				index2 = index - 1;
				break;
			case 'down':
				to = index + 2;
				index2 = index + 1;
				break;
			default:
				break;
		}
		// Intercambiar elementos
        [this.videoList[index], this.videoList[index2]] = [this.videoList[index2], this.videoList[index]];
		this.masterService.changeOrdinalTutorialContextVideo(idTutorialContext, from, to).subscribe( res => {
			if(res.data){
				this.loadContextList();
				setTimeout(() => {
					this.selectedContext = this.contextList[this.indexSelectedContext];
				}, 200)
			}
		})
	}

	//===================================================
	//===== PANTALLA 2 - AGREGAR VIDEOS AL CONTEXTO =====

	backToStep1(){
		this.step = 1;
	}

	onAddVideo(video){
		this.masterService.createTutorialContextVideo(this.selectedContext.idTutorialContext, video.idTutorialVideo).subscribe( res => {
			if(res.data){
				this.loadContextList();
				setTimeout(() => {
					this.selectedContext = this.contextList[this.indexSelectedContext];
					this.verVideosDisponibles(this.selectedContext);
					this.loadVideoList(this.selectedContext, this.indexSelectedContext);
				}, 200)
			}
		})
	}

	onDeleteVideo(video){
		this.masterService.deleteTutorialVideo(video.idTutorialVideo).subscribe( res => {
			if(res.data > 0){
				this.videosDisponibles = this.videosDisponibles.filter(v => v.idTutorialVideo !== video.idTutorialVideo);				
				this.videoList = this.videoList.filter(v => v.idTutorialVideo !== video.idTutorialVideo);
				this.loadContextList();
			}
		})
	}

	goToStep3(){
		this.step = 3;
	}

	//=====================================================
	//===== PANTALLA 3 - AGREGAR VIDEOS DESDE YOUTUBE =====

	backToStep2(){
		this.step = 2;
	}

	validateTitle() {
		if(this.videoTitle && this.videoTitle.length >= 5){
			this.isValidTitle = true;
		} else {
			this.isValidTitle = false;
		}
	}

	validateUrl() {
		const cleanedUrl = this.cleanUrl(this.videoUrl);
		const youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?)|(shorts\/))\??(v=)?([^#\&\?]*).*/;
		const match = cleanedUrl.match(youtubeRegex);
		this.isValidUrl = youtubeRegex.test(this.videoUrl);
	}
	cleanUrl(url: string): string {
		return url.replace(/app=desktop&/, '');
	}

	saveUrl() {
		if (this.isValidUrl && this.isValidTitle) {
			this.masterService.createTutorialVideo(this.videoTitle, this.videoUrl).subscribe( res => {
				if(res.data){
					this.loadContextList();
					setTimeout(() => {
						this.selectedContext = this.contextList[this.indexSelectedContext];
						this.verVideosDisponibles(this.selectedContext);
						this.loadVideoList(this.selectedContext, this.indexSelectedContext);
						this.videoTitle = '';
						this.videoUrl = '';
						this.isValidUrl = false;
						this.isValidTitle = false;
					}, 200)
				}
			})
		}
	}

}
