import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAccordionModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSelectModule, NbWindowService, NbWindowRef, NbButtonModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { SalwizarDesignIdeasComponent } from '../../salwizar-design-ideas/salwizar-design-ideas.component';
import { LocalStorage } from 'src/app/core/utils';
import { SigmaCanvasService } from '../../nodes/sigma-canvas/sigma-canvas.service';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';
import { ModalEditarCursoMapaComponent } from '../../cursos/modal-editar-curso-mapa';
import { TargetsService } from 'src/app/core/services/targets';
import { LoginService } from 'src/app/core/services/login';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/utils/utils';

@Component({
    selector: "app-modal-generated-ideas",
    imports: [
        CommonModule,
        NbCardModule,
        NbIconModule,
        NbSelectModule,
        NbFormFieldModule,
        NbInputModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NbAccordionModule,
        NbButtonModule,
    ],
    templateUrl: "./modal-generated-ideas.component.html",
    styleUrls: ["./modal-generated-ideas.component.scss"],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ModalGeneratedIdeasComponent {
	generatedIdeas: any;
	initialPositions: any;
	selectedForm: string;
	radioSelected: number = 400;
	private sigmaUtils: SigmaUtils;
	isLoading: boolean = false;
	AllIdeasAdded: boolean = false;
	selectedShape: number = 0;
	curso
	constructor(
		private activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private windowService: NbWindowService,
		private localStorage: LocalStorage,
		private sigmaCanvasService: SigmaCanvasService,
		public targetsService: TargetsService,
		public loginService: LoginService,
		public router: Router,
		private utils: Utils,
	) {}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		let generatedContentString = this.localStorage.getItem("IAGeneratedIdeas");

		let generatedContent = JSON.parse(generatedContentString);

		let wasShapeGeneratedByIa = this.localStorage.getItem("ShapedGeneratedByIa");



		if(wasShapeGeneratedByIa == "true") {

			let shape = this.localStorage.getItem("SelectedShape");
			let iaGeneratedContent = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));
			let arrayShapes = JSON.parse(iaGeneratedContent);
			//from iaGeneratedIdeas we going to get the item that has codigo == to shape

			let selectedIdea = arrayShapes.find((element) => element.codigo == shape);
			let selectedIdeasArray = selectedIdea.Estructura_Ideas;

			this.generatedIdeas = selectedIdeasArray
		}else {
			let shape = this.localStorage.getItem("SelectedShape");
			let iaGeneratedContent = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));
			let arrayShapes = JSON.parse(iaGeneratedContent);
			//from iaGeneratedIdeas we going to get the item that has codigo == to shape

			let selectedIdea = arrayShapes.find((element) => element.codigo == shape);
			let selectedIdeasArray = selectedIdea.Estructura_Ideas;

			this.generatedIdeas = selectedIdeasArray
		}

		this.selectedShape = this.localStorage.getItem("SelectedShape");

		this.selectedForm = "circle";
		this.radioSelected = 100;
		this.checkIFAlreadyAdded();
		this.checkIfAllIdeasAdded();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		this.checkIFAlreadyAdded();
		this.checkIfAllIdeasAdded();
	}

	checkIFAlreadyAdded() {
		let alreadyExistingNodes = JSON.parse(
			this.localStorage.getItem("nodesInCanvas")
		);
		// we going to run the this.generatedIdeas to add a property calles "alreadyAdded" if we find the idea in the alreadyExistingNodes
		this.generatedIdeas.forEach((idea, i) => {
			this.generatedIdeas[i].alreadyAdded = alreadyExistingNodes.some(
				(node) => node.label === idea.Titulo_de_la_Idea
			);
		});

	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	updateGeneratedIdeas(i, event) {
		this.generatedIdeas[i].Cuerpo_Explicativo_de_la_Idea = event.target.value;
		this.localStorage.setItem(
			"IAGeneratedIdeas",
			JSON.stringify(this.generatedIdeas)
		);
		this.generatedIdeas = JSON.parse(
			this.localStorage.getItem("IAGeneratedIdeas")
		);
	}

	addSingleIdea(idea, e, alreadyAdded) {
		if (alreadyAdded) {
			this.removeIdea(idea);
		}

		e.preventDefault();
		e.stopPropagation();
		this.isLoading = true;
		this.initialPositions = JSON.parse(this.localStorage.getItem("mouseClick"));
		this.calculateCircularPositions(
			this.generatedIdeas,
			this.radioSelected,
			this.initialPositions.x,
			this.initialPositions.y
		);

		this.localStorage.setItem("selectedIAIdea", JSON.stringify(idea));
		this.sigmaCanvasService.setPreviewNode("single");

		setTimeout(() => {
			this.checkIFAlreadyAdded();
			this.isLoading = false;
		}, 500);
	}

	removeIdea(idea) {
		// logic to remove the idea from the generatedIdeas and the canvas
	}

	openDesignIdeasModal() {
		if(this.AllIdeasAdded == true) {
			return;
		}
		this.modalService.dismissAll();
		this.initialPositions = JSON.parse(this.localStorage.getItem("mouseClick"));

		// get SelectedShape from localstorage
		this.selectedShape = this.localStorage.getItem("SelectedShape");

		// switch case to determine the selected shape
		// console.log(this.selectedShape, 'la forma seleccionada');
		// switch (this.selectedShape) {
		// 	case 7:
		// 		this.calculateCircularPositions(
		// 			this.generatedIdeas,
		// 			this.radioSelected,
		// 			this.initialPositions.x,
		// 			this.initialPositions.y
		// 		);
		// 		break;
		// 	case 2:
		// 		console.log('entro a la forma 2');
		// 		this.calculateStarPositions(
		// 			this.generatedIdeas,
		// 			this.radioSelected,
		// 			this.initialPositions.x,
		// 			this.initialPositions.y
		// 		);
		// }



		// this.calculateLinearPositions(
		// 	this.generatedIdeas,
		// 	this.initialPositions.x,
		// 	this.initialPositions.y,
		// 	this.initialPositions.x + 500,
		// 	this.initialPositions.y
		// );

		// this.calculateAscendantLinearPositions(
		// 	this.generatedIdeas,
		// 	this.initialPositions.x,
		// 	this.initialPositions.y + 500,
		// 	this.initialPositions.x + 500,
		// 	this.initialPositions.y
		// );

		// this.calculateHorizontalTreePositions(
		// 	this.generatedIdeas,
		// 	this.initialPositions.x,   // Coordenada X inicial (izquierda)
		// 	this.initialPositions.y,   // Coordenada Y inicial
		// 	200,                       // Espaciado horizontal entre niveles
		// 	100                        // Espaciado vertical entre ideas
		// );


		this.checkIfAllIdeasAdded();

		this.sigmaCanvasService.setPreviewNode(this.selectedForm);
	}

	checkIfAllIdeasAdded() {
		this.AllIdeasAdded = this.generatedIdeas.every((idea) => idea.alreadyAdded == true);
	}

	calculateCircularPositions(
		ideas,
		radius: number,
		initialX: number,
		initialY: number
	): any {
		const angleIncrement = (2 * Math.PI) / ideas.length;

		ideas.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}

	calculateLinearPositions(
		ideas,
		startX: number,
		startY: number,
		endX: number,
		endY: number
	): any {
		const totalIdeas = ideas.length;
		ideas.forEach((point, index) => {
			const t = index / (totalIdeas - 1);
			point.x = startX + t * (endX - startX);
			point.y = startY + t * (endY - startY);
		});

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}

	calculateAscendantLinearPositions(
		ideas,
		startX: number,
		startY: number,
		endX: number,
		endY: number
	): any {
		const totalIdeas = ideas.length;
		ideas.forEach((point, index) => {
			const t = index / (totalIdeas - 1);
			point.x = startX + t * (endX - startX);
			point.y = startY - t * (startY - endY); // Restar para ascender en Y
		});

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(ideas));

		return ideas;
	}

	calculateHorizontalTreePositions(
		ideas,
		startX: number,
		startY: number,
		horizontalSpacing: number,
		verticalSpacing: number
	): any {
		// Función recursiva para posicionar cada idea y sus subideas
		const positionIdea = (idea, x, y) => {
			idea.x = x;
			idea.y = y;

			if (idea.subIdeas && idea.subIdeas.length > 0) {
				const totalHeight = (idea.subIdeas.length - 1) * verticalSpacing;
				let currentY = y - totalHeight / 2;

				idea.subIdeas.forEach((subIdea) => {
					positionIdea(subIdea, x + horizontalSpacing, currentY);
					currentY += verticalSpacing;
				});
			}
		};

		// Iniciar el posicionamiento desde la idea principal
		positionIdea(ideas[0], startX, startY);

		// Aplanar la estructura jerárquica si es necesario
		const flattenedIdeas = this.flattenIdeas(ideas[0]);

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(flattenedIdeas));

		return flattenedIdeas;
	}

	calculateVerticalTreePositions(
		ideas,
		startX: number,
		startY: number,
		horizontalSpacing: number,
		verticalSpacing: number
	): any {
		const positionIdea = (idea, x, y) => {
			idea.x = x;
			idea.y = y;

			if (idea.subIdeas && idea.subIdeas.length > 0) {
				const totalWidth = (idea.subIdeas.length - 1) * horizontalSpacing;
				let currentX = x - totalWidth / 2;

				idea.subIdeas.forEach((subIdea) => {
					positionIdea(subIdea, currentX, y - verticalSpacing);
					currentX += horizontalSpacing;
				});
			}
		};

		positionIdea(ideas[0], startX, startY);

		const flattenedIdeas = this.flattenIdeas(ideas[0]);

		this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(flattenedIdeas));

		return flattenedIdeas;
	}

	calculateStarPositions( ideas, radius: number, initialX: number, initialY: number): any {
		const angleIncrement = (2 * Math.PI) / ideas.length;

		ideas.forEach((point, index) => {
			const angle = angleIncrement * index;
			point.x = initialX + radius * Math.cos(angle - Math.PI / 2); // start at the top
			point.y = initialY + radius * Math.sin(angle - Math.PI / 2);
		});

		let shape = this.localStorage.getItem("SelectedShape");
  let iaGeneratedContent = JSON.parse(this.localStorage.getItem("IAGeneratedIdeas"));
  let arrayShapes = JSON.parse(iaGeneratedContent);

  let selectedShape = arrayShapes.find((element) => element.codigo == shape);
  let selectedIdeasArray = selectedShape.Estructura_Ideas;

  // Actualizar cada idea en selectedShape.Estructura_Ideas con las propiedades x e y
  selectedIdeasArray.forEach((idea, index) => {
    idea.x = ideas[index].x;
    idea.y = ideas[index].y;
  });

  // Guardar la estructura actualizada en el localStorage
  this.localStorage.setItem("IAGeneratedIdeas", JSON.stringify(arrayShapes));



		return ideas;
	}


	flattenIdeas(idea) {
		let result = [idea];
		if (idea.subIdeas && idea.subIdeas.length > 0) {
			idea.subIdeas.forEach((subIdea) => {
				result = result.concat(this.flattenIdeas(subIdea));
			});
		}
		return result;
	}

	nuevoMapa(exclusive?: boolean) {
		if (!this.loginService.esAutor()) {
			return;
		}

		const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalRef.componentInstance.id = "nuevo";
		modalRef.componentInstance.curso = this.curso;
		modalRef.componentInstance.challengesExclusive = exclusive;

		modalRef.result.then(
			(result) => {
				if (result) {
					this.targetsService
						.getCourseTargetByIdCourse(this.curso.idCourse)
						.subscribe((result) => {
							//this.orderCoursesTargets(result.data);
							localStorage.setItem('dontLoadMenu', 'true')
							this.utils.loadMenu = false;

							//get the last item of result.data
							let lastItem = result.data[result.data.length - 1];


							this.router.navigate([`/course/${this.curso.idCourse}/graph/${lastItem.target.idTarget}`])
									.then(() => {
									this.closeModal("Editar nodos mapa");
									this.activeModal.close("closeAll");
							});
						});
				}
			},
			(reason) => {}
		);
	}

}
