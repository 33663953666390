<nb-card class="mb-0 no-edge-card modal-height-100-75">
	<nb-card-header class="d-flex flex-column gap-1">
		<div class="row">
			<div class="col-12 d-flex justify-content-between align-items-center">
				<h5>
					Preview version Beta asistente IA Salware
				</h5>
				<!-- <button nbButton status="primary" (click)="toggleSettings()" class="settings-btn">
									<nb-icon icon="settings" pack="eva"></nb-icon>
							</button> -->
			</div>
		</div>

	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="chat-container">
			<div class="messages">
				<div *ngFor="let message of messages"
					[ngClass]="{'user-message': message.sender === 'user', 'chatgpt-message': message.sender === 'chatgpt'}">
					{{ message.text }}
				</div>
			</div>
			<div *ngIf="loading" class="spinner-container">
				<div class="spinner"></div>
			</div>
			<div class="input-container">
				<input [(ngModel)]="userInput" (keyup.enter)="sendMessage()" placeholder="Escribe tu mensaje..."
					[disabled]="loading">
				<button nbButton size="small" (click)="sendMessage()" status="primary" [disabled]="loading">Enviar</button>
				<button nbButton size="small" (click)="toggleRecording()" status="success" [ngClass]="{'record-btn': true, 'recording': isRecording}">
					<nb-icon icon="mic-outline"></nb-icon>
					{{ isRecording ? 'Grabando...' : 'Hablar' }}
				</button>
			</div>
		</div>
	</nb-card-body>
</nb-card>
