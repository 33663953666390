import { Component, OnInit, Output } from '@angular/core';
import { TaskService } from 'src/app/core/services/task';

@Component({
    selector: 'app-modal-competition-couples',
    templateUrl: './modal-competition-couples.component.html',
    styleUrls: ['./modal-competition-couples.component.scss'],
    standalone: false
})
export class ModalCompetitionCouplesComponent implements OnInit {
  @Output() idCurso:string;
  remitente:any;
  constructor(public taskService:TaskService) { }

  ngOnInit() {
    this.taskService.setTask(this.remitente);
  }

}
