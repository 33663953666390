<nb-card class="no-edge-card mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="d-flex pr-5">
			<h3 class="text-center">
				{{ 'STRUCTURE.FILLSTRUCTUREDATA' | translate }}
			</h3>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body>
		<form (submit)="createStructure()">
			<!-- Nombre del centro -->
			<div class="form-group">
				<label for="centerName">{{ 'STRUCTURE.CENTERNAME' | translate }}</label>
				<input type="text"  [(ngModel)]="centerName" nbInput fullWidth hero fieldSize="medium"
				class="form-control" id="centerName" name="centerName">
			</div>
			<div class="d-flex justify-content-between align-items-center gap-3">
				<!-- Numero de estudiantes -->
				<div class="form-group">
					<label for="numberOfStudents">{{ 'STRUCTURE.NUMBERSTUDENTS' | translate }}</label>
					<input type="number"  [(ngModel)]="numberStudents" nbInput fullWidth hero fieldSize="medium"
					class="form-control" id="numberOfStudents" name="numberOfStudents">
				</div>
				<!-- Numero de profesores -->
				<div class="form-group">
					<label for="numberOfTeachers">{{ 'STRUCTURE.NUMBERTEACHERS' | translate }}</label>
					<input type="number"  [(ngModel)]="numberTeachers" nbInput fullWidth hero fieldSize="medium"
					class="form-control" id="numberOfTeachers" name="numberOfTeachers">
				</div>
			</div>

			<div class="d-flex justify-content-center align-items-center py-4">
				<button nbButton type="submit"
					[ngClass]="{
						'disabled': creating == true,
						'btn-themecolor': creating == false
					}">
					{{ 'STRUCTURE.CREATESTRUCTURE' | translate }}
				</button>
			</div>
		</form>
	</nb-card-body>
</nb-card>
