import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "src/app/core/services/login";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { ModalListStickersComponent } from "../../../../../shared/components/nodes/modal-list-stickers/modal-list-stickers.component";
import { VisCanvasService } from "../vis-canvas.service";
import { VisCanvasUtils } from "./canvas.utils";
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalAddTextComponent } from '../../modal-add-text/modal-add-text.component';

declare var $
@Injectable({
	providedIn: "root",
})
export class StickerUtils {
	constructor(
		public loginService: LoginService,
		private sigmaCanvasService: VisCanvasService,
		private scUtils: VisCanvasUtils,
		private modalService: NgbModal,
		private imagePipe: ImagenPipe
	) {}

	public addSticker() {
		this.scUtils.hidePopWindows();

		const tempNode =
			this.sigmaCanvasService.sigmaUtils.sigma.graph.nodes("temp");

		const modalRef = this.modalService.open(ModalListStickersComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.tempNode = tempNode;

		modalRef.componentInstance.idCourse = this.sigmaCanvasService.courseId;
		modalRef.componentInstance.idGraph = this.sigmaCanvasService.graphId;

		modalRef.result.then(
			(data: any) => {
				//Select the icon and create in DB

				if (!data) return;

				data.url = this.imagePipe.transform(data.nameImg, "stickers");
				data.id = `s${data.idImageTarget}`;
				data.x = data.xposition;
				data.y = data.yposition;
				data.size = (data.sizeImg / 10) * SIGMA_CONSTANTS.STICKER_DEFAULT_SIZE;
				data.idOriginal = data.idImageTarget;
				data.renewCache = true;

				this.sigmaCanvasService.sigmaUtils.addNode(data);
				this.sigmaCanvasService.sigmaUtils.dropNode("temp");
				this.sigmaCanvasService.sigmaUtils.refresh();
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}

	public addText() {
		this.scUtils.hidePopWindows();

		const tempNode =
			this.sigmaCanvasService.sigmaUtils.sigma.graph.nodes("temp");

		const modalRef = this.modalService.open(ModalAddTextComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.componentInstance.tempNode = tempNode;

		modalRef.componentInstance.idCourse = this.sigmaCanvasService.courseId;
		modalRef.componentInstance.idGraph = this.sigmaCanvasService.graphId;

		modalRef.result.then(
			(data: any) => {
				//Select the icon and create in DB

				if (!data) return;

				data.url = this.imagePipe.transform(data.nameImg, "stickers");
				data.id = `s${data.idImageTarget}`;
				data.x = data.xposition;
				data.y = data.yposition;
				data.size = (data.sizeImg / 10) * SIGMA_CONSTANTS.STICKER_DEFAULT_SIZE;
				data.idOriginal = data.idImageTarget;
				data.renewCache = true;

				this.sigmaCanvasService.sigmaUtils.addNode(data);
				this.sigmaCanvasService.sigmaUtils.dropNode("temp");
				this.sigmaCanvasService.sigmaUtils.refresh();
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}

	public editSticker(node: any): void {
		this.scUtils.hidePopWindows();

		const modalRef = this.modalService.open(ModalListStickersComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.idCourse = this.sigmaCanvasService.courseId;
		modalRef.componentInstance.idGraph = this.sigmaCanvasService.graphId;
		modalRef.componentInstance.node = node;

		modalRef.result.then(
			(data: any) => {
				if (!data) return;

				data.url = this.imagePipe.transform(data.nameImg, "stickers");
				data.id = `s${data.idImageTarget}`;
				data.x = data.xposition;
				data.y = data.yposition;
				data.size = (data.sizeImg / 10) * SIGMA_CONSTANTS.STICKER_DEFAULT_SIZE;
				data.idOriginal = data.idImageTarget;

				this.sigmaCanvasService.sigmaUtils.updateSticker(data);
				this.sigmaCanvasService.sigmaUtils.refresh();
			},
			(reason) => {
				console.error("Can not select the sticker");
			}
		);
	}

	public editText(node: any): void {
		this.scUtils.hidePopWindows();

		const modalRef = this.modalService.open(ModalAddTextComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.componentInstance.idCourse = this.sigmaCanvasService.courseId;
		modalRef.componentInstance.idGraph = this.sigmaCanvasService.graphId;
		modalRef.componentInstance.node = node;

		// modalRef.result.then((data:any) => {

		// 		if(!data)
		// 				return

		// 		data.url = this.imagePipe.transform(data.nameImg, 'stickers')
		// 		data.id = `s${data.idImageTarget}`
		// 		data.x = data.xposition
		// 		data.y = data.yposition
		// 		data.size = (data.sizeImg / 10) * SIGMA_CONSTANTS.STICKER_DEFAULT_SIZE
		// 		data.idOriginal = data.idImageTarget

		// 		this.sigmaCanvasService.sigmaUtils.updateSticker(data);
		// 		this.sigmaCanvasService.sigmaUtils.refresh();

		// }, (reason) => {
		// 		console.error("Can not select the sticker")
		// });
	}
}
