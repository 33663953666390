import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { VisCanvasService } from "../vis-canvas.service";
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $
@Injectable({
    providedIn: 'root'
  })
export class BackgroundUtils {


    private _settingBackground: boolean = false;
    public progress: Subject<number> = new Subject<number>()
    public _background: string | null = '';


    public get background(): string | null {
        return this._background;
    }
    public set background(value: string | null) {
        this._background = value;
    }

    public get settingBackground(): boolean {
        return this._settingBackground;
    }
    public set settingBackground(value: boolean) {
        this._settingBackground = value;
    }

	constructor(private translateService: TranslateService, private sigmaCanvasService: VisCanvasService, private toaster: ToasterService,
        private graphServ: GraphService, private imagePipe:ImagenPipe) {}

    public setBackground() {
        this.settingBackground = !this.settingBackground
    }

    public addBackground(file){
        this.settingBackground = false
        $('#pActions').hide();

        this.graphServ.saveBackgroundImage(this.sigmaCanvasService.graphId, file).subscribe((event: HttpEvent<any>) => {


            switch (event.type) {
                case HttpEventType.UploadProgress:
                    this.progress.next(Math.round(event.loaded / event.total * 100))
                  break;
                case HttpEventType.Response:
                    this.background = event.body.data.name;
										this.graphServ.bgImage.next(event.body.data.name);
                    this.setGraphBackground(event.body.data.name);
                    this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDUPLOADED'));


            }


        }, err => this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDERROR'))
        );


    }

    public rmBackground(){
        this.graphServ.saveBackgroundImage(this.sigmaCanvasService.graphId, undefined).subscribe((event: HttpEvent<any> ) => {

            switch (event.type) {
                case HttpEventType.Response:
                    this.background = null
                    this.setGraphBackground(null)
                    this.toaster.success(this.translateService.instant('SIGMACOMPONENT.BACKGROUNDUPLOADED'));
            }

        }, err => this.toaster.success(this.translateService.instant('SIGMACOMPONENT.SIGMACOMPONENT'))
        );
        $('#pActions').hide();
    }

    public setGraphBackground(url = '') {
        document.getElementById("vis-container").style.backgroundImage = url ? "url('" + this.imagePipe.transform(url, 'graphBg') + "')" : "";
    }

		public adaptBackgroundToZoom(scale, mousePosition) {
			const visContainer = document.getElementById("vis-container");

			// Configuración de tamaño mínimo (100%) para el fondo
			const baseSize = 100;
			const backgroundSize = Math.max(baseSize, baseSize * scale) + "%";

			// Aplica el tamaño calculado al fondo, con un mínimo de 100%
			visContainer.style.backgroundSize = `${backgroundSize} ${backgroundSize}`;

			// Solo aplicar desplazamiento del fondo cuando se hace zoom in (scale > 1)
			if (scale > 1) {
				// Obtiene el tamaño del contenedor y las coordenadas del mouse
				const containerRect = visContainer.getBoundingClientRect();
				const mouseX = mousePosition.x - containerRect.left;
				const mouseY = mousePosition.y - containerRect.top;

				// Calcula la posición del fondo para que parezca acercarse al punto del mouse
				const offsetX = ((mouseX / containerRect.width) * 100) * (1 - scale);
				const offsetY = ((mouseY / containerRect.height) * 100) * (1 - scale);

				// Ajusta `backgroundPosition` con el desplazamiento calculado
				visContainer.style.backgroundPosition = `${50 + offsetX}% ${50 + offsetY}%`;

				// Constrain the background position to prevent showing borders
				const maxOffset = (scale - 1) * 50;
				visContainer.style.backgroundPositionX = `${Math.min(Math.max(50 + offsetX, -maxOffset), maxOffset)}%`;
				visContainer.style.backgroundPositionY = `${Math.min(Math.max(50 + offsetY, -maxOffset), maxOffset)}%`;
			} else {
				// Restaura la posición central cuando se hace zoom out al 100% o menos
				visContainer.style.backgroundPosition = "center center";
			}
		}
}
