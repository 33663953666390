import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { UserModel } from './../../../../../core/models/courses/course.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModel } from 'src/app/core/models/courses';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-modal-listado-editores',
    templateUrl: './modal-listado-editores.component.html',
    styleUrls: ['./modal-listado-editores.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalListadoEditoresComponent implements OnInit {

  curso: CourseModel;
  public editores: UserModel[] = [];
  public users: UserModel[] = [];
  filter: {
    idCurso: number,
    filter: string
  }
  public formulario: UntypedFormGroup;
  cargando: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private coursesService: CoursesService,
    private formBuild: UntypedFormBuilder,
    private translateService: TranslateService,
		private toaster: ToasterService

  ) {
    this.formulario = this.formBuild.group({
      filtrado: [''],
  });

   }

  ngOnInit() {
    this.getListadoEditores();
  }

  filtrarNoEditores(value: string){
		if(value.length >= 5){
			this.cargando = true;
			const filtradoValue = this.formulario.value;
			this.coursesService.noEditorList(this.curso.idCourse, filtradoValue.filtrado).subscribe((res:any) => {
				this.users = res;
				this.cargando = false;
				},
				err => console.log(err));
		}
  }

  getListadoEditores() {
    this.coursesService.editorList(this.curso.idCourse).subscribe(res => {
        this.editores = res.data;
    });
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  agregarEditor(editor: UserModel){
      this.cargando = true;
      this.coursesService.addEditor(this.curso.idCourse, editor.idUser).subscribe(res => {
        this.cargando = false;
        this.getListadoEditores();
        this.users = [];
        this.toaster.success(this.translateService.instant('EDITORS.ADDEDITORMSG'));
      },
      err => console.log(err));
  }

  eliminarEditor(editor: UserModel){
    this.cargando = true;
    this.coursesService.deleteEditor(this.curso.idCourse, editor.idUser).subscribe(res => {
        this.cargando = false;
        this.getListadoEditores();
				this.users = [];
        this.toaster.success(this.translateService.instant('EDITORS.REMOVEEDITORMSG'));
    },
    err => console.log(err));
  }

  eliminarCreador(editor: UserModel){
    this.toaster.error(this.translateService.instant('EDITORS.CANTDELETECREATOR'));
  }

  eliminarUltimo(editor: UserModel){
    this.toaster.error(this.translateService.instant('EDITORS.COURSEWITHOUTEDITORS'));
  }

  updateUrl(event: any) {
    event.target.src = '../../assets/images/no-image.png'
}

}
