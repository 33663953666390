import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

// Services
import { MastersService } from 'src/app/core/services/masters';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { AlertService } from 'src/app/core/services/shared';

// Models
import { CenterModel } from 'src/app/core/models/masters';
import { UserStudentCenterModel, UserTeacherCenterModel } from 'src/app/core/models/users';

// Components
import { ModalCentersComponent } from '../modal-centers';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
    selector: 'app-modal-student-centers',
    templateUrl: './modal-student-centers.component.html',
    standalone: false
})
export class ModalStudentCentersComponent implements OnInit {

  centersAssigned: CenterModel[];
  profile: string;

  center: CenterModel;
  centers: CenterModel[];
  centersFilter: CenterModel[];

  textoCentro: string;

  mostrarDatosCentro = false;

  constructor(public translateService: TranslateService,
              private modalService: NgbModal,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              private mastersService: GetDataService,
              private alertService: AlertService,
              public activeModal: NgbActiveModal,
              public userService:UsersService
              ) { }

  ngOnInit() {

    this.obtenerDatosMaestros();
  }

  private obtenerDatosMaestros() {
      this.centers = this.centersFilter = this.mastersService.appCenters.filter(x => !this.centersAssigned.map(x => x.idCenter).includes(x.idCenter));
  }

  public buscarCentro() {
    if (this.textoCentro === '' && this.textoCentro != undefined) {
      this.centersFilter = this.centers;
    } else {
      // tslint:disable-next-line: max-line-length
      this.centersFilter = this.centers.filter(x => x.centerName.toUpperCase().indexOf(this.textoCentro.toUpperCase()) !== -1);
    }
  }

   public obtenerDatosCentro(idCenter) {

    this.mastersService.getCenterById(idCenter).subscribe(data => {
        this.mostrarDatosCentro = false;

        if (data) {
          this.mostrarDatosCentro = true;
          this.center = data
        } else {
          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.ModalEstudianteCentro);
          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Mostrar datos centro - Centros Estudiante`, 'ERROR');
        }
      }, (error) =>
      {
        this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.ModalEstudianteCentro);
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Mostrar datos centro - Centros Estudiante - ERROR: ${error}`, 'ERROR');
      });
  }

  public asignarCentro() {

    // Si es profesor lo grabo en un sitio y si es estudiante en otro
    if (this.profile.toLowerCase() === 'estudiante') {
      let userStudentCenter = new UserStudentCenterModel();

      userStudentCenter.idUserStudentCenter = 0;
      userStudentCenter.idUser = this.loginService.getUser().idUser;
      userStudentCenter.idCenter = this.center.idCenter;
    }

    // Profesor
    if (this.profile.toLowerCase() === 'profesor') {
      let userTeacherCenter = new UserTeacherCenterModel();

      userTeacherCenter.idUserTeacherCenter = 0;
      userTeacherCenter.idUser = this.loginService.getUser().idUser;
      userTeacherCenter.idCenter = this.center.idCenter;
    }
  }

  public crearCentro() {
    const modalRef = this.modalService.open(ModalCentersComponent,
    {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W70
    });

    // Lo registro en el log
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear centro - Centros Estudiante`, 'OK');

    modalRef.result.then((result) => {

      switch (result.message) {
        case 'Centro creado':

          this.textoCentro = '';

          // tslint:disable-next-line: max-line-length
          this.alertService.success(this.translateService.instant('ESTUDIANTECENTRO.ELCENTROSEHACREADOCORRECTAMENTE'), AlertService.AlertServiceContextValues.ModalEstudianteCentro);

          // Y lo asigno al objeto que almacena los centros
          this.centers.push(result.centerData);

          // Y vuelvo a buscar
          this.buscarCentro();

          break;
      }
    }, (reason) => {
    });
  }


  // Y lo grabo
  closeModal(sendData?: any) {
    this.activeModal.close(sendData);

    // tslint:disable-next-line: max-line-length
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Centros Estudiante`, 'OK');
  }
}
