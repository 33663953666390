import { CenterModel } from 'src/app/core/models/masters';
import { CenterService } from 'src/app/core/services/center/center.service';
import { CommunityModel } from './../../../core/models/masters/community.model';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { PrefixCountry } from '../../models/shared/prefixCountry.model';
import { TranslateService } from '@ngx-translate/core';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { formsValidations } from 'src/app/core/utils/forms-validations';
import { Observable } from 'rxjs/internal/Observable';
import { PrefixCountry } from 'src/app/core/models/shared/prefixCountry.model';
import { LoginService } from 'src/app/core/services/login';
import { DEFAULTMOBILEPREFIX } from 'src/app/core/models/masters/masters.enum';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
const PREFIXCOUNTRIES = '../../../../assets/json/countries.json'

import { Country, State, City } from "country-state-city";
import { count } from 'console';
import { MastersService } from 'src/app/core/services/masters';

@Component({
    selector: "app-modal-centers",
    templateUrl: "./modal-centers.component.html",
    styleUrls: ["./modal-centers.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalCentersComponent implements OnInit {
	@Input() center: CenterModel;
	@Input() isEdit: boolean;

	formularioDatos: UntypedFormGroup;
	provinces: CommunityModel[] = [];
	$phonePrefixes: Observable<PrefixCountry[]>;

	validationMessages = {
		centerName: [],
		centerAddress: [],
		province: [],
		centerMail: [],
		centerPhone: [],
		centerUrl: [],
		phonePrefixes: [],
		extension: [],
		calificacion: [],
	};

	private emailPattern: string = formsValidations.EMAIL_PATTERN;
	private emailMaxLength: number = formsValidations.EMAIL_MAX_LENGTH;
	private urlPattern: string = formsValidations.URL_PATTERN;

	public loading = false;
	allCountries: any[] = [];
	allRegions: any[] = [];
	countrySelected: boolean = false;
	states: any;

	opcionesCalificacion: any[] = [];
	calificacion: number = 1;

	constructor(
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		public activeModal: NgbActiveModal,
		private dataService: GetDataService,
		private centerService: CenterService,
		private loginService: LoginService,
		private toaster: ToasterService,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		this.provinces = this.dataService.appCommunities;

		//obtenemos las calificaciones
		this.getCalificacionesList();

		// Cargo las validaciones del formulario
		this.cargarValidacionesFormulario();

		// Cargo los formularios
		this.crearFormularios();

		//cargamos la lista de extensiones
		this.$phonePrefixes = this.loginService.getPhonePrefix();

		if (this.isEdit) {
			this.formularioDatos.patchValue(this.center);

			this.formularioDatos
				.get("extension")
				.setValue("+" + this.center.extension);
		}
		//traemos los paises que tengamos registro de regiones en bbdd
		this.dataService.getCountriesWithRegions().subscribe(res => {
			this.allCountries = res;
		})
		//el pais predeterminado es españa, por lo que traeremos sus regiones
		this.getRegions("ESP");
	}

	getRegions(idCountry: string){
		this.dataService.getRegions(idCountry).subscribe(res => {
			this.allRegions = res;
		});
	}

	private cargarValidacionesFormulario() {
		//Center Name
		this.translateService
			.get("VALIDACIONES.ELNOMBREESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.centerName.push({
					type: "required",
					message: res,
				});
			});

		//Center Address
		this.translateService
			.get("VALIDACIONES.CENTERADDRESSREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.centerAddress.push({
					type: "required",
					message: res,
				});
			});

		//Province
		this.translateService
			.get("VALIDACIONES.PROVINCEREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.province.push({
					type: "required",
					message: res,
				});
			});

		//Center Email Required *
		this.translateService
			.get("VALIDACIONES.ELEMAILESOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.centerMail.push({
					type: "required",
					message: res,
				});
			});

		//Center Email Pattern *
		this.translateService
			.get("VALIDACIONES.ELEMAILNOESVALIDO")
			.subscribe((res: string) => {
				this.validationMessages.centerMail.push({
					type: "pattern",
					message: res,
				});
			});

		//Center Phone *
		this.translateService
			.get("VALIDACIONES.MAILOTELEFONOBLIGATORIO")
			.subscribe((res: string) => {
				this.validationMessages.centerPhone.push({
					type: "required",
					message: res,
				});
			});

		//Center Url *
		this.translateService
			.get("VALIDACIONES.FORMATURL")
			.subscribe((res: string) => {
				this.validationMessages.centerUrl.push({
					type: "pattern",
					message: res,
				});
			});

		//Calificaciones *
		this.translateService
			.get("VALIDACIONES.CALIFICACION")
			.subscribe((res: string) => {
				this.validationMessages.calificacion.push({
					type: "pattern",
					message: res,
				});
			});

		this.translateService
			.get("VALIDACIONES.PREFIXREQUIRED")
			.subscribe((res: string) => {
				this.validationMessages.extension.push({
					type: "required",
					message: res,
				});
			});
	}

	private crearFormularios() {
		this.formularioDatos = this.formBuild.group({
			image: [{ value: "", disabled: false }],
			centerName: ["", Validators.required],
			centerAddress: ["", Validators.required],
			country: ["ESP", Validators.required],
			idCalificaciones: [this.calificacion, Validators.required],
			province: ["", Validators.required],
			centerMail: [
				"",
				{
					validators: [
						Validators.required,
						Validators.maxLength(this.emailMaxLength),
						Validators.pattern(this.emailPattern),
					],
				},
			],
			centerPhone: ["", Validators.required],
			centerUrl: ["", Validators.pattern(this.urlPattern)],
			remarks: [""],
			extension: [DEFAULTMOBILEPREFIX, Validators.required],
		});
	}

	public crearCentro() {
		console.log(this.formularioDatos);
		if (this.formularioDatos.invalid) {
			return;
		}
		const fv = this.formularioDatos.value;
		let image: File | null = fv.image;
		delete fv.image;
		fv.extension = fv.extension.replace("+", "");
		let body: CenterModel = fv;

		if (this.loginService.getProfile() === "PROFESOR") {
			this.centerService.createCenterAssignTeacher(image, body).subscribe(
				(response) => {
					this.toaster.success(
						this.translateService.instant("MODALNEWCENTER.OKCREATECENTER")
					);
					this.closeModal(true);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("MODALNEWCENTER.KOCREATECENTER")
					);
				}
			);
		} else if (this.loginService.getProfile() === "CENTRO") {
			this.centerService.createCenterAssignUser(image, body).subscribe(
				(response) => {
					this.toaster.success(
						this.translateService.instant("MODALNEWCENTER.OKCREATECENTER")
					);
					this.closeModal(true);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("MODALNEWCENTER.KOCREATECENTER")
					);
				}
			);
		}
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	getCalificacionesList(){
		this.masterService.getCalificacionesList().subscribe( res => {
			if(res.data && res.data.length > 0){
				this.opcionesCalificacion = res.data;
			}
		})
	}
}
