<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ 'EDITARCURSOMAPA.GRAFO' | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
			nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('guardar')">
		</nb-icon>
	</div>
	<nb-card-body>
		<nb-card *ngIf="screenView === 1" class="no-edge-card">
			<nb-card-body class="p-0">
				<div>
					<ng-template class="w-100" #step9>
						<div class="copilot-custom copilop-info" style="position: sticky;">
							<div class="row">
								<div class="col-12 flex justify-content-center align-items-center">
									<h3 class="title-info">
										{{'TUTORIALINICIO.TUTORIAL_TITLE_04'| translate}}
									</h3>
								</div>
								<div class="col-12 col-12 row flex justify-content-center align-items-center">
									<p class="text-info-help">
										{{'TUTORIALINICIO.TUTORIAL_TEXTO_04'| translate}}
									</p>
								</div>
							</div>
							<div class="footer-btn">
								<div class="row  flex justify-content-center align-items-center">
									<div class="col-2">
										<button class="btn btn-block btn-themecolor btn-sm button-ok" (click)="done(2)" type="button">
											{{'TUTORIALINICIO.BOTONES.ACEPTAR'| translate}}
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</div>

				<div>
					<!-- <div class="sticky-top sticky-top-header">
							<div class="modal-header mr-4 ml-4 mt-4" align="center">
									<div>
											<span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold"></span>
									</div>
									<div>
											<span class="textoFontSize1 colorTextoActivadoCursoGrafo font-weight-bold">&nbsp;</span>
									</div>
									<div>
											<button type="button" class="close" data-dismiss="modal" (click)="closeModal('')">
													<span aria-hidden="true">&times;</span>
											</button>
									</div>
							</div>
					</div> -->

					<div class="modal-body">
						<mat-progress-bar mode="determinate" value="{{progress}}" *ngIf="progress"></mat-progress-bar>

						<form (ngSubmit)="grabarCursoMapa()" #thisForm="ngForm" class="form-horizontal" [formGroup]="form"
							novalidate>

							<div class="row mt-2">
								<div class="col-12">
									<app-alert context="ModalEditarCursoMapa"></app-alert>
								</div>
							</div>

							<div class="sticky-top">

								<div class="container">
									<!-- <div class="row justify-content-center mb-4 justify-content-md-end">
															<div class="col-6 text-center text-md-left">
																	<span class="textoFontSize1 colorTextoActivadoGrafo font-weight-bold">{{ 'EDITARCURSOMAPA.GRAFO' | translate }}</span>
															</div>
													</div> -->
									<div class="row mb-2">
										<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4 mb-md-0">
											<div class="d-flex mx-4 p-3 align-items-center container-imgupload">
												<app-imgupload class="w-100" [isShowDelete]="true" formControlName="image"></app-imgupload>
											</div>
										</div>
										<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

											<div class="form-row">
												<div class="full-width mb-3">
													<input type="text" nbInput fullWidth status="basic"
														placeholder="{{ 'EDITARCURSOMAPA.TITULOGRAFO' | translate }}" id="validationCustom01"
														formControlName="tittle" maxlength="250" class="form-control">
													<div *ngFor="let validation of validationMessages.title">
														<div class="error-message"
															*ngIf="form.get('tittle')?.hasError(validation.type) && (form.get('tittle')?.dirty || form.get('tittle')?.touched || thisForm.submitted)">
															{{validation.message}}</div>
													</div>
												</div>
												<div class="full-width mb-3">
													<textarea type="text" nbInput fullWidth
														placeholder="{{ 'EDITARCURSOMAPA.DESCRIPCIONGRAFO' | translate }}" id="validationCustom01"
														formControlName="description" style="height: 100px;" maxlength="500" class="form-control">
													</textarea>

													<div *ngFor="let validation of validationMessages.description">
														<div class="error-message"
															*ngIf="form.get('description')?.hasError(validation.type) && (form.get('description')?.dirty || form.get('description')?.touched || thisForm.submitted)">
															{{validation.message}}</div>
													</div>
												</div>
												<div class="full-width mb-3">
													<textarea type="text" nbInput fullWidth
														placeholder="{{ 'EDITARCURSOMAPA.LABELS' | translate }}" id="validationCustom01"
														formControlName="labels" style="height: 100px;" maxlength="500" class="form-control">
													</textarea>
												</div>
												<div class="full-width mb-3">
													<!-- <select style="width: -webkit-fill-available;" class="custom-select btn-block"  formControlName="idSubject" id="idSubject" >
																							<option *ngFor="let materia of materias | sortBy:'asc':'subject'" [value]="materia.idSubject">{{ materia.subject }}</option>
																					</select> -->
													<nb-select fullWidth name="idSubject"
														formControlName="idSubject" class="SelectForm" hero>
														<nb-option *ngFor="let materia of materias | sortBy:'asc':'subject'"
															[value]="materia.idSubject" class="SelectFormOpt">
															{{ materia.subject }}
														</nb-option>
													</nb-select>
													<div *ngFor="let validation of validationMessages.subject">
														<div class="error-message"
															*ngIf="form.get('idSubject')?.hasError(validation.type) && (form.get('idSubject')?.dirty || form.get('idSubject')?.touched || thisForm.submitted)">
															{{validation.message}}</div>
													</div>
												</div>
												<div class="full-width mb-3">
													<!-- <select style="width: -webkit-fill-available;" class="custom-select btn-block"  formControlName="viewType" id="viewType" >
																						<option *ngFor="let item of viewTypesList | sortBy:'asc':'viewType'" [value]="item.viewType">{{ item.description }}</option>
																				</select> -->
													<nb-select fullWidth name="viewType"
														formControlName="viewType" class="SelectForm" hero>
														<nb-option *ngFor="let item of viewTypesList | sortBy:'asc':'viewType'"
															[value]="item.viewType" class="SelectFormOpt">
															{{ item.description }}
														</nb-option>
													</nb-select>
												</div>
											</div>
											<div class="d-flex mt-4 gap-4 justify-content-between align-items-center">
												<div class="d-flex gap-4">
													<!-- traducir -->
													<button *ngIf="id !='nuevo'" type="button" nbButton (click)="traducirGrafo()" size="small">
														{{ 'GLOBALS.TRANSLATE' | translate }}
													</button>
													<!-- mover -->
													<button *ngIf="id !='nuevo'" type="button" nbButton (click)="changeScreen(3)" size="small">
														{{ 'GLOBALS.MOVE' | translate }}
													</button>
													<!-- copiar -->
													<button *ngIf="id !='nuevo'" type="button" nbButton (click)="changeScreen(4)" size="small">
														{{ 'GLOBALS.COPY' | translate }}
													</button>
												</div>
												<!-- visible -->
												<div class="d-flex flex-column gap-1">
													<nb-toggle class="item-profile" *ngIf="id !=='nuevo' && isMine()"
														formControlName="published" (change)="publish($event)">
													</nb-toggle>
													<span class="text-center" style="line-height: 1rem; font-size: 12px;">
														{{
															form.get('published').value
															? ('EDITARCURSO.VISIBLE' | translate)
															: ('EDITARCURSO.NOVISIBLE' | translate)
														}}
													</span>
												</div>
											</div>

											<div class="d-flex mt-4 align-items-center justify-content-center gap-4">
												<!-- editar ideografo -->
												<button copilot [copilot-template]="step9" [copilot-step]="9" *ngIf="id !=='nuevo' && isMine()" type="button"
													(click)="verNodosMapa()" nbButton class="btn-themecolor" size="small">
													<span>{{ 'EDITARCURSOMAPA.EDITARNODOS' | translate }}</span>
												</button>

												<!-- eliminar -->
												<button *ngIf="isMine() && id !='nuevo'" (click)="deleteGraph()" type="button" nbButton class="btn-red" size="small">
													{{'EDITARCURSOMAPA.ELIMINAR' | translate }}
												</button>

												<button *ngIf="id=='nuevo'" nbButton class="btn-themecolor" type="submit" [disabled]="saving" size="small">
													{{ saving ?
														('EDITARCURSO.SAVING' | translate) :
														('EDITARCURSOMAPA.GRABAR' | translate)
													}}
												</button>
											</div>
										</div>
									</div>
									<div class="row mt-4 pb-2 justify-content-end px-3" style="gap: 20px;" *ngIf="id!='nuevo' && haveMultiple">
										<button type="button" nbButton class="btn-themecolor" (click)="changeScreen(2)">{{
											'QUIZZES.MULTIPLES' | translate }}
										</button>
										<!--
										<nb-toggle labelPosition="start" *ngIf="id !=='nuevo' && isMine() && curso.project_type != 5" formControlName="challenge"
											(change)="challenge($event)">{{'EDITARCURSOMAPA.EXCLUSIVECHALLENGE' |
											translate}}
										</nb-toggle>
										-->
										<!-- <mat-slide-toggle class="buttonPublicar"  *ngIf="id !=='nuevo' && isMine()" formControlName="published" (click)="publish($event)" [labelPosition]="'before'">{{'EDITARCURSO.PUBLISH' | translate}}</mat-slide-toggle> -->
									</div>
								</div>

							</div>
						</form>

						<app-loading [isVisible]="cargando"></app-loading>
					</div>
				</div>
			</nb-card-body>
		</nb-card>
		<nb-card *ngIf="screenView === 2" class="no-edge-card">
			<nb-card-body>
				<div>
					<app-seleccion-multiples [listMultiples]="listMultiples" [configMultiples]="configMultiples" [target]="target"
						[curso]="curso" [idGroup]="0" (action)="changeScreen($event)"></app-seleccion-multiples>
				</div>
			</nb-card-body>
		</nb-card>
		<nb-card *ngIf="screenView === 3" class="no-edge-card">
			<nb-card-body class="p-0">
				<div>
					<app-proyecto-destino [target]="target" [curso]="curso" [idGroup]="0" [screenView]="screenView"
						(action)="changeScreen($event)"></app-proyecto-destino>
				</div>
			</nb-card-body>
		</nb-card>
		<nb-card *ngIf="screenView === 4" class="no-edge-card">
			<nb-card-body class="p-0">
				<div>
					<app-proyecto-destino [target]="target" [curso]="curso" [idGroup]="0" [screenView]="screenView"
						(action)="changeScreen($event)"></app-proyecto-destino>
				</div>
			</nb-card-body>
		</nb-card>
	</nb-card-body>

</nb-card>
