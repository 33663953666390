import { Action } from '@ngrx/store';
import { graphData } from '../models/graph-data.model';

export enum ActionTypes {
	loadGraphData = '[Graphs Data ACTION] load graph data',
}

export class loadGraphData implements Action {
	readonly type = ActionTypes.loadGraphData;
	constructor(public payload: graphData) { }
}


export type ActionsUnion = loadGraphData;
