<nb-card class="mb-0 no-edge-card background_themecolor modal-height-100-62">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">

		<div class="row p-4 background_white">
			<div class="col-12 col-lg-6 modal-title px-2">
				<h4>{{'SOCKETQUIZPREVIEW.TITLE' | translate}} - {{group.title}}</h4>
			</div>

			<div class="col-12 col-lg-6 mt-3 mt-lg-0 pr-lg-5 d-flex align-items-center justify-content-end">
				<button nbButton class="btn-themecolor" size="small" class="mr-4">{{'SOCKETQUIZPREVIEW.PAUSE' | translate}}</button>
				<button nbButton class="btn-red" size="small" (click)="finalizeChallenge()">{{'SOCKETQUIZPREVIEW.FINALIZEQUIZZ' | translate}}</button>
			</div>
		</div>

		<div class="row px-3 py-2 mt-3">
			<div class="col-4">
				<div class="d-flex justify-content-between gap-4">
					<!-- BUTTON ONLY & EXCLUSIVE -->
					<div *ngIf="cameFromCalendar == false" class="d-flex align-items-start gap-4">
						<!-- ONLYGRAPHS -->
						<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
							nbTooltip="{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}" nbTooltipPlacement="top">
							<div>
								<button nbButton status="basic" size="tiny" shape="rectangle"
									class="d-flex align-items-center {{showOnlyGraphs ? 'enabled' : 'disabled'}}"
									(click)="showOnlyGraphs = !showOnlyGraphs;filterGraphsTree()">
									<mat-icon svgIcon="graph"></mat-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{ 'SOCKETQUIZPREVIEW.ONLYGRAPHS' | translate }}
							</label>
						</div>
						<!-- EXCLUSIVEGRAPHS
						<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
							nbTooltip="{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}">
							<div>
								<button nbButton status="basic" size="tiny" shape="rectangle"
									class="d-flex align-items-center {{showOnlyExclusive ? 'enabled' : 'disabled'}}"
									(click)="showOnlyExclusive = !showOnlyExclusive;filterGraphsTree()">
									<mat-icon id="desafios_icon" svgIcon="desafios_icon"></mat-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{ 'SOCKETQUIZPREVIEW.EXCLUSIVEGRAPHS' | translate }}
							</label>
						</div>
						-->
					</div>
					<div *ngIf="cameFromCalendar == true" class="d-flex align-items-start gap-4">
						<h6>Este contenido fue asignado por el centro</h6>
					</div>
				</div>
			</div>
			<div class="col px-4">
				<div class="d-flex justify-content-between gap-4">
					<!-- TITULO -->
					<div class="d-flex flex-column align-items-center gap-1">
						<ng-container *ngIf="cameFromCalendar == false; else nameFromCalendar">
							<input type="text" nbInput class="form-control" fullWidth status="basic" fieldSize="small" [(ngModel)]="nameSession"
							style="text-align: center;" (ngModelChange)="this.nameSessionUpdate.next($event)">
							<label class="label-header m-0">
								Nombre del desafio
							</label>
						</ng-container>
						<ng-template #nameFromCalendar>
							<h6>{{ nameSessionFromCalendar }} </h6>
						</ng-template>
					</div>
					<!-- button for reporting modal -->
					<div class="d-flex align-items-start">
						<div class="d-flex align-items-center justify-content-center filter-buttons flex-column" style="gap: 5px;"
							nbTooltip="{{ 'INFORMES.INFORME' | translate }}" nbTooltipPlacement="top">
							<div>
								<button nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center"
									(click)="openReportModal()">
									<div class="card-image">
										<div class="icon-option icon-informes pointer"></div>
									</div>
								</button>
							</div>
							<label class="label-header m-0">
								{{ 'INFORMES.INFORME' | translate }}
							</label>
						</div>
					</div>
					<!-- ONLINE USER -->
					<div class="d-flex justify-content-center align-items-center">
						<div class="pointer position-relative" [nbPopover]="templateRef" nbPopoverPlacement="bottom" (click)="prefillOfflineUsers()"
							[ngbTooltip]="'SOCKETQUIZPREVIEW.ONLINEUSERS' | translate">
							<span class="dot position-dot"></span>
							<nb-icon icon="people-outline" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
							<span>
								{{onlineUsers - 1 }} / {{ usersInGroupCounter }}
							</span>
						</div>
					</div>
					<!-- CHALLENGE TIME -->
					<div class="d-flex align-items-start" *ngIf="isClockShown">
						<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;">
							<countdown #cd [config]="configClock" (event)="handleCountDownEvent($event)"
								class="counter-down p-1">
							</countdown>
							<label class="label-header mb-0">{{'SOCKETQUIZPREVIEW.CHALLENGEENDS' | translate}}</label>
						</div>
					</div>
					<!--  -->
					<div *ngIf="quiz && quiz.duration != null && quiz.duration > 0" class="d-flex align-items-start">
						<div class="d-flex align-items-center justify-content-center flex-column" style="gap: 5px;">
							<countdown #cd [config]="configClockQuiz"
								class="counter-down p-1">
							</countdown>
							<label class="label-header mb-0">{{'SOCKETQUIZPREVIEW.CHALLENGEENDQUIZ' | translate}}</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #templateRef>
			<nb-card [size]="'tiny'" accent="success" class="mb-0">
				<nb-card-header class="border-bottom-0">{{'SOCKETQUIZPREVIEW.ONLINESTUDENTS' | translate}}</nb-card-header>
				<nb-card-body>
					<div class="d-flex justify-content-center align-items-center h-100" *ngIf="usuariosOnline.length < 1">
						<p class="mb-0">
							{{'SOCKETQUIZPREVIEW.NOTONLINESTUDENTS' | translate}}
						</p>
					</div>
					<nb-list>
						<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let user of usuariosOnline">
							<span class="dot"></span>{{user.firstName}} {{user.surname}}
						</nb-list-item>
					</nb-list>
				</nb-card-body>
			</nb-card>

			<nb-card [size]="'tiny'" accent="danger" class="mb-0 mt-1">
				<nb-card-header class="border-bottom-0">{{'SOCKETQUIZPREVIEW.OFFLINESTUDENTS' | translate}}</nb-card-header>
				<nb-card-body>
					<nb-list>
						<nb-list-item class="border-bottom-0 border-top-0" *ngFor="let user of usuariosOffline">
							<span class="dot-red"></span>{{user.firstName}} {{user.surname}}
						</nb-list-item>
					</nb-list>
				</nb-card-body>
			</nb-card>
		</ng-template>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal()" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
		</nb-icon>
	</div>

	<nb-card-body class="position-static">
		<div class="row min-h-100" *ngIf="viewList">

			<app-loading [isVisible]="cargando"></app-loading>

			<!-- COLUMN TREE COURSES-->
			<div  [ngClass]="{'disable-list': cameFromCalendar == true}" class="col-12 col-lg-4 py-4 pl-4 pr-0 container-tree background_white">

				<h5  class="text-title font-weight-bold">
					{{ 'INFORMES.EXPANDIRVISTA' | translate }}
				</h5>
				<app-mat-tree-cursos *ngIf="cameFromCalendar == false && treeCourses.length > 0" [idCourse]="idCourse" [idGroup]="group.idGroup"
					[optionSelected]="optionSelected"
					[treeCourses]="treeCourses" [fromNode]="fromNode" [idCurso]="idCurso" [idGrafo]="idGrafo"
					[idNodo]="idNodo" (clickElement)="clickElement($event)">
				</app-mat-tree-cursos>
				<h6 *ngIf="cameFromCalendar == true && selectedCourseFromCalendar && graphfromCalendar">
						<ul style="list-style-type: circle; padding-left: 0;">
							<li style="display: inline;">
								{{ selectedCourseFromCalendar?.courseTittle }}
							</li>
							<li style="margin-left: 10px; color: rgb(65, 92, 241);">
								{{ graphfromCalendar.tittle }}
							</li>
						</ul>
				</h6>
			</div>
			<!-- END COLUMN TREE COURSES-->

			<!-- COLUMN CENTER CONTAINER-->
			<div class="table-datod col-12 col-lg-8 py-0 pl-0 pl-lg-4 pr-0 container-tree" [ngClass]="{ 'fullscreen-table': isFullscreen }">
				<div class="background_white h-100 py-4 pl-4 pr-0">
					<div class="d-flex">
						<!-- BOTON FULLSCREEN-->
						<div *ngIf="showFullscreen" class="w-100 d-flex justify-content-end pr-4">
							<button (click)="toggleFullscreen()" nbButton class="btn-themecolor" size="small">
								{{ isFullscreen ? ('INFORMES.NOTFULLSCREEM' | translate) : ('INFORMES.FULLSCREEM' | translate) }}
							</button>
						</div>
					</div>
					<div class="container-data-table">
						<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->
						<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading">
							<mat-spinner [diameter]="50"></mat-spinner>
						</div>
						<!-- VISUALIZAR QUIZ -->

						<div class="overflow-hidden" *ngIf="showReport">
							<div class="py-3" id="container">
								<app-live-reporting-table [headTable]="headTableReport"
									[bodyTable]="bodyTableReport">
								</app-live-reporting-table>
							</div>
						</div>

						<div *ngIf="quiz && !isLoading">
							<!-- SIMPLE QUIZ -->
							<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="overflow-hidden" id="quiz">
								<div class="py-3" id="container">
									<app-quiz-play-body [courseId]="idSelectedCourse" [graphId]="idGraph" [quiz]="quiz" [elements]="elements"
										[answered]="answered" [modeSocket]="true" [answeredBySocket]="false" [idGroup]="group.idGroup"
										(answeredChange)="onAnswered()" [viewQuizDuration]="true">
									</app-quiz-play-body>
								</div>
							</div>
							<!-- END SIMPLE QUIZ -->

							<!-- MULTIPLE QUIZ -->
							<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="overflow-hidden" id="quiz">
								<div class="py-3" id="container">
									<app-quiz-play-multiple-body [courseId]="idSelectedCourse" [graphId]="idGraph" [quiz]="quiz"
										[elements]="elements" [answered]="answered" [modeSocket]="true" [answeredBySocket]="false"
										[idGroup]="group.idGroup" (answeredChange)="onAnswered()">
									</app-quiz-play-multiple-body>
								</div>
							</div>
							<!-- END MULTIPLE QUIZ -->
						</div>
						<!-- FIN VISUALIZAR QUIZ -->
					</div>
				</div>
			</div>
			<!-- END COLUMN CENTER CONTAINER-->
		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>
</nb-card>
