import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, messageArray: string[] }
    ) { }

    ngOnInit() {
    }

		handleLoginClick() {
			this.dialogRef.close({ action: 'login', additionalData: 'optional' });
		}

		handleRecoveryClick() {
			this.dialogRef.close({ action: 'recovery', additionalData: 'optional' });
		}

		handleChangeClick() {
			this.dialogRef.close({ action: 'change', additionalData: 'optional' });
		}

}
