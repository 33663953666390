import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appNumberInput]',
    standalone: false
})
export class NumberInputDirective {
    regexStr = '^[0-9]*$';

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const key = event.key;
        return (
            new RegExp(this.regexStr).test(event.key) ||
            (key === 'a' && event.ctrlKey === true) || // Ctrl+A
            (key === 'c' && event.ctrlKey === true) || // Ctrl+C
            (key === 'v' && event.ctrlKey === true) || // Ctrl+V
            (key === 'x' && event.ctrlKey === true) || // Ctrl+X
            (key === 'a' && event.metaKey === true) || // Cmd+A (Mac)
            (key === 'c' && event.metaKey === true) || // Cmd+C (Mac)
            (key === 'v' && event.metaKey === true) || // Cmd+V (Mac)
            (key === 'x' && event.metaKey === true) || // Cmd+X (Mac)
            (key === 'Delete') ||
            (key === 'Backspace') ||
            (key === 'ArrowLeft' || key === 'Left') ||
            (key === 'ArrowRight' || key === 'Right') ||
            (key === 'ArrowDown' || key === 'Down') ||
            (key === 'ArrowUp' || key === 'Up') ||
            (key === 'Tab')
        );
    }

    @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput: string = event.clipboardData
            .getData('text/plain')
            .replace(/\D/g, ''); // get a digit-only string
        document.execCommand('insertText', false, pastedInput);
    }
}
