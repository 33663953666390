import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { AgentService } from 'src/app/core/services/agents/agent.service';

@Component({
    selector: 'app-image-agent-profile',
    templateUrl: './image-agent-profile.component.html',
    styleUrls: ['./image-agent-profile.component.scss'],
    providers: [ImagenPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ImageAgentProfileComponent implements OnInit {

  @Input() image:string|null;
	@Input() idAgent: number;
  public imageProfile:string = ''
  public progress: number = 0;
  private _idUser:number;
	public userData: any;
	@Input() readOnlyMode: boolean = false;
	@Output() imageChange = new EventEmitter<string>();


	constructor(private userServices: UsersService,
		private loginService: LoginService,
		private translateService: TranslateService,
		private imagePipe: ImagenPipe,
		private toaster: ToasterService,
		private agentService: AgentService
	) { }

  ngOnInit() {
		this.userData = this.loginService.getUser();
    if(this.image)
      this.imageProfile = this.imagePipe.transform(this.image, 'agent')

    this._idUser = this.loginService.getUser().idUser
  }

  onFileSelected(event){
    const file = event.target.files[0];

    if(!file) return

    this.userServices.updateOrDeleteImageAgent(this.image,file, this.idAgent).subscribe((event: HttpEvent<any>) => {

      switch(event.type){
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100)
          break
        case HttpEventType.Response:
          setTimeout(() => {
            this.progress = 0
            this.image = event.body.data
            this.imageProfile = this.imagePipe.transform(this.image, 'agent')
            // let user: User = this.loginService.getUser()
            // user.pictureUser = this.image
            // this.loginService.setUserLocalStorage(user)
						this.agentService.getAgentByIdUser(this._idUser).subscribe(agent => {
							this.loginService.setCurrentAgent(agent)
						});
						this.imageChange.emit(this.image)

            this.toaster.success(this.translateService.instant('IMAGEPROFILE.ADDIMAGEOK'))
          }, 500);
          break
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.ADDIMAGEKO')) })
  }

  deleteProfileImage(){
    this.userServices.updateOrDeleteImageAgent(this.image,'',this.idAgent).subscribe((event: HttpEvent<any>) => {
      switch(event.type){
        case HttpEventType.Response:
          this.toaster.success(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEOK'))
          // this.image = ''
          // this.progress = 0
          // let user: User = this.loginService.getUser()
          // user.pictureUser = this.image
          //this.loginService.setUserLocalStorage(user)
          break;
      }
    }, err => { this.toaster.error(this.translateService.instant('IMAGEPROFILE.REMOVEIMAGEKO')) })
  }

}
