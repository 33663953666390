import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FOTOS } from './lista_constants'
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-lista',
    templateUrl: './lista.component.html',
    styleUrls: ['./lista.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ListaComponent implements OnInit {
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	labels: any[] = [];
	datasets: any[] = [];

	fotosEstudiantes: string[] = [];
	fotosProfesores: string[] = [];
	fotosPadres: string[] = [];

	constructor(
		private translateService: TranslateService,
	){}

	ngOnInit(){
		if (this.consultaID !== null && this.consultaID != 0) {
			//this.datasets = this.datasetsImported;
			//this.labels = this.labelsImported;
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, this.parametroID - 1)];
			this.labels = this.obtenerLabels(this.datasetsImported, this.parametroID - 1);
    } else if(this.consultaID !== null && this.consultaID == 0){
			this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0)];
			this.labels = this.obtenerLabels(this.datasetsImported, 0);
		}
		//obtenemos las fotos de los estudiantes
		for (let index = 0; index < FOTOS.ESTUDIANTES.length; index++) {
			const element = FOTOS.ESTUDIANTES[index];
			this.fotosEstudiantes.push(element.picture);
		}
		this.fotosEstudiantes.sort(() => Math.random() - 0.5);
		//obtenemos las fotos de los profesores
		for (let index = 0; index < FOTOS.PROFESORES.length; index++) {
			const element = FOTOS.PROFESORES[index];
			this.fotosProfesores.push(element.picture);
		}
		this.fotosProfesores.sort(() => Math.random() - 0.5);
		//obtenemos las fotos de los padres
		for (let index = 0; index < FOTOS.PADRES.length; index++) {
			const element = FOTOS.PADRES[index];
			this.fotosPadres.push(element.picture);
		}
		this.fotosPadres.sort(() => Math.random() - 0.5);
	}

	obtenerLabels(dataSets, index) {
		let filteredData;
		if(this.consultaID == 0){
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null);
		} else{
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null && value !== 0);
		}
    return dataSets.map(info =>
			this.parametroID == 0 ? this.translateService.instant('GLOBALS.OPTION') + ': ' + info.label : info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
		let filteredData;
		if(this.consultaID == 0){
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null);
		} else{
			filteredData = dataSets
			.map(info => info.data[index])
			.filter(value => value !== null && value !== 0);
		}
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}
	colorKnowledge(valor: number): string {
		let color = "rgba(154, 155, 156, 1)"; // gris con 50% de transparencia por defecto
		if(this.parametroID == 0){
			color = "rgba(78, 176, 238, 1)";
		} else if(this.parametroID == 1){
			if (valor == 0) color = "rgba(154, 155, 156, 1)"; // #9A9B9C
			else if (valor > 0 && valor < 11) color = "rgba(251, 13, 28, 1)"; // #FB0D1C
			else if (valor < 21) color = "rgba(252, 92, 32, 1)"; // #FC5C20
			else if (valor < 31) color = "rgba(252, 154, 39, 1)"; // #FC9A27
			else if (valor < 41) color = "rgba(254, 229, 51, 1)"; // #FEE533
			else if (valor < 51) color = "rgba(255, 253, 56, 1)"; // #FFFD38
			else if (valor < 61) color = "rgba(212, 253, 53, 1)"; // #D4FD35
			else if (valor < 71) color = "rgba(156, 253, 50, 1)"; // #9CFD32
			else if (valor < 81) color = "rgba(41, 248, 46, 1)"; // #29F82E
			else if (valor < 91) color = "rgba(42, 213, 45, 1)"; // #2AD52D
			else if (valor < 101) color = "rgba(26, 175, 84, 1)"; // #1AAF54
		} else if(this.parametroID == 2){
			if (valor == 0) color = "rgba(154, 155, 156, 1)"; // #9A9B9C
			else if (valor > 0 && valor < 1.1) color = "rgba(251, 13, 28, 1)"; // #FB0D1C
			else if (valor < 2.1) color = "rgba(252, 92, 32, 1)"; // #FC5C20
			else if (valor < 3.1) color = "rgba(252, 154, 39, 1)"; // #FC9A27
			else if (valor < 4.1) color = "rgba(254, 229, 51, 1)"; // #FEE533
			else if (valor < 5.1) color = "rgba(255, 253, 56, 1)"; // #FFFD38
			else if (valor < 6.1) color = "rgba(212, 253, 53, 1)"; // #D4FD35
			else if (valor < 7.1) color = "rgba(156, 253, 50, 1)"; // #9CFD32
			else if (valor < 8.1) color = "rgba(41, 248, 46, 1)"; // #29F82E
			else if (valor < 9.1) color = "rgba(42, 213, 45, 1)"; // #2AD52D
			else if (valor < 10.1) color = "rgba(26, 175, 84, 1)"; // #1AAF54
		}
		return color;
	}

	convertirMinutos(minutos: number): string {
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;
    return `${horas}h ${minutosRestantes}min`;
  }

}
