import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slicestring',
    standalone: false
})

export class SliceStringPipe implements PipeTransform {

    SEARCH_STRING: string = '|-|';

    transform(value: string): string {
        const CUT = value.indexOf(this.SEARCH_STRING);
        if (CUT >= 0) {
            value = value.substr(0,CUT);
            this.transform(value);
        }
        return value;
    }
}
