import * as i0 from '@angular/core';
import { EventEmitter, Directive, Output, Input, HostListener, NgModule } from '@angular/core';
import { fromEvent } from 'rxjs';
class Position {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  static fromEvent(e, el = null) {
    /**
     * Fix issue: Resize doesn't work on Windows10 IE11 (and on some windows 7 IE11)
     * https://github.com/xieziyu/angular2-draggable/issues/164
     * e instanceof MouseEvent check returns false on IE11
     */
    if (this.isMouseEvent(e)) {
      return new Position(e.clientX, e.clientY);
    } else {
      if (el === null || e.changedTouches.length === 1) {
        return new Position(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
      }
      /**
       * Fix issue: Multiple phone draggables at the same time
       * https://github.com/xieziyu/angular2-draggable/issues/128
       */
      for (let i = 0; i < e.changedTouches.length; i++) {
        if (e.changedTouches[i].target === el) {
          return new Position(e.changedTouches[i].clientX, e.changedTouches[i].clientY);
        }
      }
    }
    return null;
  }
  static isMouseEvent(e) {
    return Object.prototype.toString.apply(e).indexOf('MouseEvent') === 8;
  }
  static isIPosition(obj) {
    return !!obj && 'x' in obj && 'y' in obj;
  }
  static getCurrent(el) {
    let pos = new Position(0, 0);
    if (window) {
      const computed = window.getComputedStyle(el);
      if (computed) {
        let x = parseInt(computed.getPropertyValue('left'), 10);
        let y = parseInt(computed.getPropertyValue('top'), 10);
        pos.x = isNaN(x) ? 0 : x;
        pos.y = isNaN(y) ? 0 : y;
      }
      return pos;
    } else {
      console.error('Not Supported!');
      return null;
    }
  }
  static copy(p) {
    return new Position(0, 0).set(p);
  }
  get value() {
    return {
      x: this.x,
      y: this.y
    };
  }
  add(p) {
    this.x += p.x;
    this.y += p.y;
    return this;
  }
  subtract(p) {
    this.x -= p.x;
    this.y -= p.y;
    return this;
  }
  multiply(n) {
    this.x *= n;
    this.y *= n;
  }
  divide(n) {
    this.x /= n;
    this.y /= n;
  }
  reset() {
    this.x = 0;
    this.y = 0;
    return this;
  }
  set(p) {
    this.x = p.x;
    this.y = p.y;
    return this;
  }
}
class HelperBlock {
  constructor(parent, renderer) {
    this.parent = parent;
    this.renderer = renderer;
    this._added = false;
    // generate helper div
    let helper = renderer.createElement('div');
    renderer.setStyle(helper, 'position', 'absolute');
    renderer.setStyle(helper, 'width', '100%');
    renderer.setStyle(helper, 'height', '100%');
    renderer.setStyle(helper, 'background-color', 'transparent');
    renderer.setStyle(helper, 'top', '0');
    renderer.setStyle(helper, 'left', '0');
    // done
    this._helper = helper;
  }
  add() {
    // append div to parent
    if (this.parent && !this._added) {
      this.parent.appendChild(this._helper);
      this._added = true;
    }
  }
  remove() {
    if (this.parent && this._added) {
      this.parent.removeChild(this._helper);
      this._added = false;
    }
  }
  dispose() {
    this._helper = null;
    this._added = false;
  }
  get el() {
    return this._helper;
  }
}
class AngularDraggableDirective {
  /** Set z-index when not dragging */
  set zIndex(setting) {
    this.renderer.setStyle(this.el.nativeElement, 'z-index', setting);
    this._zIndex = setting;
  }
  set ngDraggable(setting) {
    if (setting !== undefined && setting !== null && setting !== '') {
      this.allowDrag = !!setting;
      let element = this.getDragEl();
      if (this.allowDrag) {
        this.renderer.addClass(element, 'ng-draggable');
      } else {
        this.putBack();
        this.renderer.removeClass(element, 'ng-draggable');
      }
    }
  }
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.allowDrag = true;
    this.moving = false;
    this.orignal = null;
    this.oldTrans = new Position(0, 0);
    this.tempTrans = new Position(0, 0);
    this.currTrans = new Position(0, 0);
    this.oldZIndex = '';
    this._zIndex = '';
    this.needTransform = false;
    this.draggingSub = null;
    /**
     * Bugfix: iFrames, and context unrelated elements block all events, and are unusable
     * https://github.com/xieziyu/angular2-draggable/issues/84
     */
    this._helperBlock = null;
    this.started = new EventEmitter();
    this.stopped = new EventEmitter();
    this.edge = new EventEmitter();
    /** List of allowed out of bounds edges **/
    this.outOfBounds = {
      top: false,
      right: false,
      bottom: false,
      left: false
    };
    /** Round the position to nearest grid */
    this.gridSize = 1;
    /** Whether to limit the element stay in the bounds */
    this.inBounds = false;
    /** Whether the element should use it's previous drag position on a new drag event. */
    this.trackPosition = true;
    /** Input css scale transform of element so translations are correct */
    this.scale = 1;
    /** Whether to prevent default event */
    this.preventDefaultEvent = false;
    /** Set initial position by offsets */
    this.position = {
      x: 0,
      y: 0
    };
    /** Lock axis: 'x' or 'y' */
    this.lockAxis = null;
    /** Emit position offsets when moving */
    this.movingOffset = new EventEmitter();
    /** Emit position offsets when put back */
    this.endOffset = new EventEmitter();
    this._helperBlock = new HelperBlock(el.nativeElement, renderer);
  }
  ngOnInit() {
    if (this.allowDrag) {
      let element = this.getDragEl();
      this.renderer.addClass(element, 'ng-draggable');
    }
    this.resetPosition();
  }
  ngOnDestroy() {
    this.bounds = null;
    this.handle = null;
    this.orignal = null;
    this.oldTrans = null;
    this.tempTrans = null;
    this.currTrans = null;
    this._helperBlock.dispose();
    this._helperBlock = null;
    if (this.draggingSub) {
      this.draggingSub.unsubscribe();
    }
  }
  ngOnChanges(changes) {
    if (changes['position'] && !changes['position'].isFirstChange()) {
      let p = changes['position'].currentValue;
      if (!this.moving) {
        if (Position.isIPosition(p)) {
          this.oldTrans.set(p);
        } else {
          this.oldTrans.reset();
        }
        this.transform();
      } else {
        this.needTransform = true;
      }
    }
  }
  ngAfterViewInit() {
    if (this.inBounds) {
      this.boundsCheck();
      this.oldTrans.add(this.tempTrans);
      this.tempTrans.reset();
    }
  }
  getDragEl() {
    return this.handle ? this.handle : this.el.nativeElement;
  }
  resetPosition() {
    if (Position.isIPosition(this.position)) {
      this.oldTrans.set(this.position);
    } else {
      this.oldTrans.reset();
    }
    this.tempTrans.reset();
    this.transform();
  }
  moveTo(p) {
    if (this.orignal) {
      p.subtract(this.orignal);
      this.tempTrans.set(p);
      this.tempTrans.divide(this.scale);
      this.transform();
      if (this.bounds) {
        let edgeEv = this.boundsCheck();
        if (edgeEv) {
          this.edge.emit(edgeEv);
        }
      }
      this.movingOffset.emit(this.currTrans.value);
    }
  }
  transform() {
    let translateX = this.tempTrans.x + this.oldTrans.x;
    let translateY = this.tempTrans.y + this.oldTrans.y;
    if (this.lockAxis === 'x') {
      translateX = this.oldTrans.x;
      this.tempTrans.x = 0;
    } else if (this.lockAxis === 'y') {
      translateY = this.oldTrans.y;
      this.tempTrans.y = 0;
    }
    // Snap to grid: by grid size
    if (this.gridSize > 1) {
      translateX = Math.round(translateX / this.gridSize) * this.gridSize;
      translateY = Math.round(translateY / this.gridSize) * this.gridSize;
    }
    let value = `translate(${Math.round(translateX)}px, ${Math.round(translateY)}px)`;
    this.renderer.setStyle(this.el.nativeElement, 'transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
    this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);
    // save current position
    this.currTrans.x = translateX;
    this.currTrans.y = translateY;
  }
  pickUp() {
    // get old z-index:
    this.oldZIndex = this.el.nativeElement.style.zIndex ? this.el.nativeElement.style.zIndex : '';
    if (window) {
      this.oldZIndex = window.getComputedStyle(this.el.nativeElement, null).getPropertyValue('z-index');
    }
    if (this.zIndexMoving) {
      this.renderer.setStyle(this.el.nativeElement, 'z-index', this.zIndexMoving);
    }
    if (!this.moving) {
      this.started.emit(this.el.nativeElement);
      this.moving = true;
      const element = this.getDragEl();
      this.renderer.addClass(element, 'ng-dragging');
      /**
       * Fix performance issue:
       * https://github.com/xieziyu/angular2-draggable/issues/112
       */
      this.subscribeEvents();
    }
  }
  subscribeEvents() {
    this.draggingSub = fromEvent(document, 'mousemove', {
      passive: false
    }).subscribe(event => this.onMouseMove(event));
    this.draggingSub.add(fromEvent(document, 'touchmove', {
      passive: false
    }).subscribe(event => this.onMouseMove(event)));
    this.draggingSub.add(fromEvent(document, 'mouseup', {
      passive: false
    }).subscribe(() => this.putBack()));
    // checking if browser is IE or Edge - https://github.com/xieziyu/angular2-draggable/issues/153
    let isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (!isIEOrEdge) {
      this.draggingSub.add(fromEvent(document, 'mouseleave', {
        passive: false
      }).subscribe(() => this.putBack()));
    }
    this.draggingSub.add(fromEvent(document, 'touchend', {
      passive: false
    }).subscribe(() => this.putBack()));
    this.draggingSub.add(fromEvent(document, 'touchcancel', {
      passive: false
    }).subscribe(() => this.putBack()));
  }
  unsubscribeEvents() {
    this.draggingSub.unsubscribe();
    this.draggingSub = null;
  }
  boundsCheck() {
    if (this.bounds) {
      let boundary = this.bounds.getBoundingClientRect();
      let elem = this.el.nativeElement.getBoundingClientRect();
      let result = {
        top: this.outOfBounds.top ? true : boundary.top < elem.top,
        right: this.outOfBounds.right ? true : boundary.right > elem.right,
        bottom: this.outOfBounds.bottom ? true : boundary.bottom > elem.bottom,
        left: this.outOfBounds.left ? true : boundary.left < elem.left
      };
      if (this.inBounds) {
        if (!result.top) {
          this.tempTrans.y -= (elem.top - boundary.top) / this.scale;
        }
        if (!result.bottom) {
          this.tempTrans.y -= (elem.bottom - boundary.bottom) / this.scale;
        }
        if (!result.right) {
          this.tempTrans.x -= (elem.right - boundary.right) / this.scale;
        }
        if (!result.left) {
          this.tempTrans.x -= (elem.left - boundary.left) / this.scale;
        }
        this.transform();
      }
      return result;
    }
    return null;
  }
  /** Get current offset */
  getCurrentOffset() {
    return this.currTrans.value;
  }
  putBack() {
    if (this._zIndex) {
      this.renderer.setStyle(this.el.nativeElement, 'z-index', this._zIndex);
    } else if (this.zIndexMoving) {
      if (this.oldZIndex) {
        this.renderer.setStyle(this.el.nativeElement, 'z-index', this.oldZIndex);
      } else {
        this.el.nativeElement.style.removeProperty('z-index');
      }
    }
    if (this.moving) {
      this.stopped.emit(this.el.nativeElement);
      // Remove the helper div:
      this._helperBlock.remove();
      if (this.needTransform) {
        if (Position.isIPosition(this.position)) {
          this.oldTrans.set(this.position);
        } else {
          this.oldTrans.reset();
        }
        this.transform();
        this.needTransform = false;
      }
      if (this.bounds) {
        let edgeEv = this.boundsCheck();
        if (edgeEv) {
          this.edge.emit(edgeEv);
        }
      }
      this.moving = false;
      this.endOffset.emit(this.currTrans.value);
      if (this.trackPosition) {
        this.oldTrans.add(this.tempTrans);
      }
      this.tempTrans.reset();
      if (!this.trackPosition) {
        this.transform();
      }
      const element = this.getDragEl();
      this.renderer.removeClass(element, 'ng-dragging');
      /**
       * Fix performance issue:
       * https://github.com/xieziyu/angular2-draggable/issues/112
       */
      this.unsubscribeEvents();
    }
  }
  checkHandleTarget(target, element) {
    // Checks if the target is the element clicked, then checks each child element of element as well
    // Ignores button clicks
    // Ignore elements of type button
    if (element.tagName === 'BUTTON') {
      return false;
    }
    // If the target was found, return true (handle was found)
    if (element === target) {
      return true;
    }
    // Recursively iterate this elements children
    for (let child in element.children) {
      if (element.children.hasOwnProperty(child)) {
        if (this.checkHandleTarget(target, element.children[child])) {
          return true;
        }
      }
    }
    // Handle was not found in this lineage
    // Note: return false is ignore unless it is the parent element
    return false;
  }
  onMouseDown(event) {
    // 1. skip right click;
    if (event instanceof MouseEvent && event.button === 2) {
      return;
    }
    // 2. if handle is set, the element can only be moved by handle
    let target = event.target || event.srcElement;
    if (this.handle !== undefined && !this.checkHandleTarget(target, this.handle)) {
      return;
    }
    // 3. if allow drag is set to false, ignore the mousedown
    if (this.allowDrag === false) {
      return;
    }
    if (this.preventDefaultEvent) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.orignal = Position.fromEvent(event, this.getDragEl());
    this.pickUp();
  }
  onMouseMove(event) {
    if (this.moving && this.allowDrag) {
      if (this.preventDefaultEvent) {
        event.stopPropagation();
        event.preventDefault();
      }
      // Add a transparent helper div:
      this._helperBlock.add();
      this.moveTo(Position.fromEvent(event, this.getDragEl()));
    }
  }
  static {
    this.ɵfac = function AngularDraggableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularDraggableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AngularDraggableDirective,
      selectors: [["", "ngDraggable", ""]],
      hostBindings: function AngularDraggableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function AngularDraggableDirective_mousedown_HostBindingHandler($event) {
            return ctx.onMouseDown($event);
          })("touchstart", function AngularDraggableDirective_touchstart_HostBindingHandler($event) {
            return ctx.onMouseDown($event);
          });
        }
      },
      inputs: {
        handle: "handle",
        bounds: "bounds",
        outOfBounds: "outOfBounds",
        gridSize: "gridSize",
        zIndexMoving: "zIndexMoving",
        zIndex: "zIndex",
        inBounds: "inBounds",
        trackPosition: "trackPosition",
        scale: "scale",
        preventDefaultEvent: "preventDefaultEvent",
        position: "position",
        lockAxis: "lockAxis",
        ngDraggable: "ngDraggable"
      },
      outputs: {
        started: "started",
        stopped: "stopped",
        edge: "edge",
        movingOffset: "movingOffset",
        endOffset: "endOffset"
      },
      exportAs: ["ngDraggable"],
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularDraggableDirective, [{
    type: Directive,
    args: [{
      selector: '[ngDraggable]',
      exportAs: 'ngDraggable'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    started: [{
      type: Output
    }],
    stopped: [{
      type: Output
    }],
    edge: [{
      type: Output
    }],
    handle: [{
      type: Input
    }],
    bounds: [{
      type: Input
    }],
    outOfBounds: [{
      type: Input
    }],
    gridSize: [{
      type: Input
    }],
    zIndexMoving: [{
      type: Input
    }],
    zIndex: [{
      type: Input
    }],
    inBounds: [{
      type: Input
    }],
    trackPosition: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    preventDefaultEvent: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    lockAxis: [{
      type: Input
    }],
    movingOffset: [{
      type: Output
    }],
    endOffset: [{
      type: Output
    }],
    ngDraggable: [{
      type: Input
    }],
    onMouseDown: [{
      type: HostListener,
      args: ['mousedown', ['$event']]
    }, {
      type: HostListener,
      args: ['touchstart', ['$event']]
    }]
  });
})();
class ResizeHandle {
  constructor(parent, renderer, type, css, onMouseDown, existHandle) {
    this.parent = parent;
    this.renderer = renderer;
    this.type = type;
    this.css = css;
    this.onMouseDown = onMouseDown;
    this.existHandle = existHandle;
    // generate handle div or using exist handle
    let handle = this.existHandle || renderer.createElement('div');
    renderer.addClass(handle, 'ng-resizable-handle');
    renderer.addClass(handle, css);
    // add default diagonal for se handle
    if (type === 'se') {
      renderer.addClass(handle, 'ng-resizable-diagonal');
    }
    // append div to parent
    if (this.parent && !this.existHandle) {
      parent.appendChild(handle);
    }
    // create and register event listener
    this._onResize = event => {
      onMouseDown(event, this);
    };
    handle.addEventListener('mousedown', this._onResize, {
      passive: false
    });
    handle.addEventListener('touchstart', this._onResize, {
      passive: false
    });
    // done
    this._handle = handle;
  }
  dispose() {
    this._handle.removeEventListener('mousedown', this._onResize);
    this._handle.removeEventListener('touchstart', this._onResize);
    if (this.parent && !this.existHandle) {
      this.parent.removeChild(this._handle);
    }
    this._handle = null;
    this._onResize = null;
  }
  get el() {
    return this._handle;
  }
}
class Size {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
  static getCurrent(el) {
    let size = new Size(0, 0);
    if (window) {
      const computed = window.getComputedStyle(el);
      if (computed) {
        size.width = parseInt(computed.getPropertyValue('width'), 10);
        size.height = parseInt(computed.getPropertyValue('height'), 10);
      }
      return size;
    } else {
      console.error('Not Supported!');
      return null;
    }
  }
  static copy(s) {
    return new Size(0, 0).set(s);
  }
  set(s) {
    this.width = s.width;
    this.height = s.height;
    return this;
  }
}
class AngularResizableDirective {
  /** Disables the resizable if set to false. */
  set ngResizable(v) {
    if (v !== undefined && v !== null && v !== '') {
      this._resizable = !!v;
      this.updateResizable();
    }
  }
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this._resizable = true;
    this._handles = {};
    this._handleType = [];
    this._handleResizing = null;
    this._direction = null;
    this._directionChanged = null;
    this._aspectRatio = 0;
    this._containment = null;
    this._origMousePos = null;
    /** Original Size and Position */
    this._origSize = null;
    this._origPos = null;
    /** Current Size and Position */
    this._currSize = null;
    this._currPos = null;
    /** Initial Size and Position */
    this._initSize = null;
    this._initPos = null;
    /** Snap to gird */
    this._gridSize = null;
    this._bounding = null;
    /**
     * Bugfix: iFrames, and context unrelated elements block all events, and are unusable
     * https://github.com/xieziyu/angular2-draggable/issues/84
     */
    this._helperBlock = null;
    this.draggingSub = null;
    this._adjusted = false;
    /**
     * Which handles can be used for resizing.
     * @example
     * [rzHandles] = "'n,e,s,w,se,ne,sw,nw'"
     * equals to: [rzHandles] = "'all'"
     *
     * */
    this.rzHandles = 'e,s,se';
    /**
     * Using exist handles for resizing instead of generate them.
     * @example
     * [rzHandleDoms] = {
     *   e: handelE,
     *   s: handelS,
     *   se: handelSE
     * };
     * */
    this.rzHandleDoms = {};
    /**
     * Whether the element should be constrained to a specific aspect ratio.
     *  Multiple types supported:
     *  boolean: When set to true, the element will maintain its original aspect ratio.
     *  number: Force the element to maintain a specific aspect ratio during resizing.
     */
    this.rzAspectRatio = false;
    /**
     * Constrains resizing to within the bounds of the specified element or region.
     *  Multiple types supported:
     *  Selector: The resizable element will be contained to the bounding box of the first element found by the selector.
     *            If no element is found, no containment will be set.
     *  Element: The resizable element will be contained to the bounding box of this element.
     *  String: Possible values: "parent".
     */
    this.rzContainment = null;
    /**
     * Snaps the resizing element to a grid, every x and y pixels.
     * A number for both width and height or an array values like [ x, y ]
     */
    this.rzGrid = null;
    /** The minimum width the resizable should be allowed to resize to. */
    this.rzMinWidth = null;
    /** The minimum height the resizable should be allowed to resize to. */
    this.rzMinHeight = null;
    /** The maximum width the resizable should be allowed to resize to. */
    this.rzMaxWidth = null;
    /** The maximum height the resizable should be allowed to resize to. */
    this.rzMaxHeight = null;
    /** Input css scale transform of element so translations are correct */
    this.rzScale = 1;
    /** Whether to prevent default event */
    this.preventDefaultEvent = true;
    /** emitted when start resizing */
    this.rzStart = new EventEmitter();
    /** emitted when start resizing */
    this.rzResizing = new EventEmitter();
    /** emitted when stop resizing */
    this.rzStop = new EventEmitter();
    this._helperBlock = new HelperBlock(el.nativeElement, renderer);
  }
  ngOnChanges(changes) {
    if (changes['rzHandles'] && !changes['rzHandles'].isFirstChange()) {
      this.updateResizable();
    }
    if (changes['rzAspectRatio'] && !changes['rzAspectRatio'].isFirstChange()) {
      this.updateAspectRatio();
    }
    if (changes['rzContainment'] && !changes['rzContainment'].isFirstChange()) {
      this.updateContainment();
    }
  }
  ngOnInit() {
    this.updateResizable();
  }
  ngOnDestroy() {
    this.removeHandles();
    this._containment = null;
    this._helperBlock.dispose();
    this._helperBlock = null;
  }
  ngAfterViewInit() {
    const elm = this.el.nativeElement;
    this._initSize = Size.getCurrent(elm);
    this._initPos = Position.getCurrent(elm);
    this._currSize = Size.copy(this._initSize);
    this._currPos = Position.copy(this._initPos);
    this.updateAspectRatio();
    this.updateContainment();
  }
  /** A method to reset size */
  resetSize() {
    this._currSize = Size.copy(this._initSize);
    this._currPos = Position.copy(this._initPos);
    this.doResize();
  }
  /** A method to get current status */
  getStatus() {
    if (!this._currPos || !this._currSize) {
      return null;
    }
    return {
      size: {
        width: this._currSize.width,
        height: this._currSize.height
      },
      position: {
        top: this._currPos.y,
        left: this._currPos.x
      }
    };
  }
  updateResizable() {
    const element = this.el.nativeElement;
    // clear handles:
    this.renderer.removeClass(element, 'ng-resizable');
    this.removeHandles();
    // create new ones:
    if (this._resizable) {
      this.renderer.addClass(element, 'ng-resizable');
      this.createHandles();
    }
  }
  /** Use it to update aspect */
  updateAspectRatio() {
    if (typeof this.rzAspectRatio === 'boolean') {
      if (this.rzAspectRatio && this._currSize.height) {
        this._aspectRatio = this._currSize.width / this._currSize.height;
      } else {
        this._aspectRatio = 0;
      }
    } else {
      let r = Number(this.rzAspectRatio);
      this._aspectRatio = isNaN(r) ? 0 : r;
    }
  }
  /** Use it to update containment */
  updateContainment() {
    if (!this.rzContainment) {
      this._containment = null;
      return;
    }
    if (typeof this.rzContainment === 'string') {
      if (this.rzContainment === 'parent') {
        this._containment = this.el.nativeElement.parentElement;
      } else {
        this._containment = document.querySelector(this.rzContainment);
      }
    } else {
      this._containment = this.rzContainment;
    }
  }
  /** Use it to create handle divs */
  createHandles() {
    if (!this.rzHandles) {
      return;
    }
    let tmpHandleTypes;
    if (typeof this.rzHandles === 'string') {
      if (this.rzHandles === 'all') {
        tmpHandleTypes = ['n', 'e', 's', 'w', 'ne', 'se', 'nw', 'sw'];
      } else {
        tmpHandleTypes = this.rzHandles.replace(/ /g, '').toLowerCase().split(',');
      }
      for (let type of tmpHandleTypes) {
        // default handle theme: ng-resizable-$type.
        let handle = this.createHandleByType(type, `ng-resizable-${type}`);
        if (handle) {
          this._handleType.push(type);
          this._handles[type] = handle;
        }
      }
    } else {
      tmpHandleTypes = Object.keys(this.rzHandles);
      for (let type of tmpHandleTypes) {
        // custom handle theme.
        let handle = this.createHandleByType(type, this.rzHandles[type]);
        if (handle) {
          this._handleType.push(type);
          this._handles[type] = handle;
        }
      }
    }
  }
  /** Use it to create a handle */
  createHandleByType(type, css) {
    const _el = this.el.nativeElement;
    const _h = this.rzHandleDoms[type] ? this.rzHandleDoms[type].nativeElement : null;
    if (!type.match(/^(se|sw|ne|nw|n|e|s|w)$/)) {
      console.error('Invalid handle type:', type);
      return null;
    }
    return new ResizeHandle(_el, this.renderer, type, css, this.onMouseDown.bind(this), _h);
  }
  removeHandles() {
    for (let type of this._handleType) {
      this._handles[type].dispose();
    }
    this._handleType = [];
    this._handles = {};
  }
  onMouseDown(event, handle) {
    // skip right click;
    if (event instanceof MouseEvent && event.button === 2) {
      return;
    }
    if (this.preventDefaultEvent) {
      // prevent default events
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this._handleResizing) {
      this._origMousePos = Position.fromEvent(event);
      this.startResize(handle);
      this.subscribeEvents();
    }
  }
  subscribeEvents() {
    this.draggingSub = fromEvent(document, 'mousemove', {
      passive: false
    }).subscribe(event => this.onMouseMove(event));
    this.draggingSub.add(fromEvent(document, 'touchmove', {
      passive: false
    }).subscribe(event => this.onMouseMove(event)));
    this.draggingSub.add(fromEvent(document, 'mouseup', {
      passive: false
    }).subscribe(() => this.onMouseLeave()));
    // fix for issue #164
    let isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (!isIEOrEdge) {
      this.draggingSub.add(fromEvent(document, 'mouseleave', {
        passive: false
      }).subscribe(() => this.onMouseLeave()));
    }
    this.draggingSub.add(fromEvent(document, 'touchend', {
      passive: false
    }).subscribe(() => this.onMouseLeave()));
    this.draggingSub.add(fromEvent(document, 'touchcancel', {
      passive: false
    }).subscribe(() => this.onMouseLeave()));
  }
  unsubscribeEvents() {
    this.draggingSub.unsubscribe();
    this.draggingSub = null;
  }
  onMouseLeave() {
    if (this._handleResizing) {
      this.stopResize();
      this._origMousePos = null;
      this.unsubscribeEvents();
    }
  }
  onMouseMove(event) {
    if (this._handleResizing && this._resizable && this._origMousePos && this._origPos && this._origSize) {
      this.resizeTo(Position.fromEvent(event));
      this.onResizing();
    }
  }
  startResize(handle) {
    const elm = this.el.nativeElement;
    this._origSize = Size.getCurrent(elm);
    this._origPos = Position.getCurrent(elm); // x: left, y: top
    this._currSize = Size.copy(this._origSize);
    this._currPos = Position.copy(this._origPos);
    if (this._containment) {
      this.getBounding();
    }
    this.getGridSize();
    // Add a transparent helper div:
    this._helperBlock.add();
    this._handleResizing = handle;
    this.updateDirection();
    this.rzStart.emit(this.getResizingEvent());
  }
  stopResize() {
    // Remove the helper div:
    this._helperBlock.remove();
    this.rzStop.emit(this.getResizingEvent());
    this._handleResizing = null;
    this._direction = null;
    this._origSize = null;
    this._origPos = null;
    if (this._containment) {
      this.resetBounding();
    }
  }
  onResizing() {
    this.rzResizing.emit(this.getResizingEvent());
  }
  getResizingEvent() {
    return {
      host: this.el.nativeElement,
      handle: this._handleResizing ? this._handleResizing.el : null,
      size: {
        width: this._currSize.width,
        height: this._currSize.height
      },
      position: {
        top: this._currPos.y,
        left: this._currPos.x
      },
      direction: {
        ...this._directionChanged
      }
    };
  }
  updateDirection() {
    this._direction = {
      n: !!this._handleResizing.type.match(/n/),
      s: !!this._handleResizing.type.match(/s/),
      w: !!this._handleResizing.type.match(/w/),
      e: !!this._handleResizing.type.match(/e/)
    };
    this._directionChanged = {
      ...this._direction
    };
    // if aspect ration should be preserved:
    if (this.rzAspectRatio) {
      // if north then west (unless ne)
      if (this._directionChanged.n && !this._directionChanged.e) {
        this._directionChanged.w = true;
      }
      // if south then east (unless sw)
      if (this._directionChanged.s && !this._directionChanged.w) {
        this._directionChanged.e = true;
      }
      // if east then south (unless ne)
      if (this._directionChanged.e && !this._directionChanged.n) {
        this._directionChanged.s = true;
      }
      // if west then south (unless nw)
      if (this._directionChanged.w && !this._directionChanged.n) {
        this._directionChanged.s = true;
      }
    }
  }
  resizeTo(p) {
    p.subtract(this._origMousePos).divide(this.rzScale);
    const tmpX = Math.round(p.x / this._gridSize.x) * this._gridSize.x;
    const tmpY = Math.round(p.y / this._gridSize.y) * this._gridSize.y;
    if (this._direction.n) {
      // n, ne, nw
      this._currPos.y = this._origPos.y + tmpY;
      this._currSize.height = this._origSize.height - tmpY;
    } else if (this._direction.s) {
      // s, se, sw
      this._currSize.height = this._origSize.height + tmpY;
    }
    if (this._direction.e) {
      // e, ne, se
      this._currSize.width = this._origSize.width + tmpX;
    } else if (this._direction.w) {
      // w, nw, sw
      this._currSize.width = this._origSize.width - tmpX;
      this._currPos.x = this._origPos.x + tmpX;
    }
    this.checkBounds();
    this.checkSize();
    this.adjustByRatio();
    this.doResize();
  }
  doResize() {
    const container = this.el.nativeElement;
    if (!this._direction || this._direction.n || this._direction.s || this._aspectRatio) {
      this.renderer.setStyle(container, 'height', this._currSize.height + 'px');
    }
    if (!this._direction || this._direction.w || this._direction.e || this._aspectRatio) {
      this.renderer.setStyle(container, 'width', this._currSize.width + 'px');
    }
    this.renderer.setStyle(container, 'left', this._currPos.x + 'px');
    this.renderer.setStyle(container, 'top', this._currPos.y + 'px');
  }
  adjustByRatio() {
    if (this._aspectRatio && !this._adjusted) {
      if (this._direction.e || this._direction.w) {
        const newHeight = Math.floor(this._currSize.width / this._aspectRatio);
        if (this._direction.n) {
          this._currPos.y += this._currSize.height - newHeight;
        }
        this._currSize.height = newHeight;
      } else {
        const newWidth = Math.floor(this._aspectRatio * this._currSize.height);
        if (this._direction.n) {
          this._currPos.x += this._currSize.width - newWidth;
        }
        this._currSize.width = newWidth;
      }
    }
  }
  checkBounds() {
    if (this._containment) {
      const maxWidth = this._bounding.width - this._bounding.pr - this._bounding.deltaL - this._bounding.translateX - this._currPos.x;
      const maxHeight = this._bounding.height - this._bounding.pb - this._bounding.deltaT - this._bounding.translateY - this._currPos.y;
      if (this._direction.n && this._currPos.y + this._bounding.translateY < 0) {
        this._currPos.y = -this._bounding.translateY;
        this._currSize.height = this._origSize.height + this._origPos.y + this._bounding.translateY;
      }
      if (this._direction.w && this._currPos.x + this._bounding.translateX < 0) {
        this._currPos.x = -this._bounding.translateX;
        this._currSize.width = this._origSize.width + this._origPos.x + this._bounding.translateX;
      }
      if (this._currSize.width > maxWidth) {
        this._currSize.width = maxWidth;
      }
      if (this._currSize.height > maxHeight) {
        this._currSize.height = maxHeight;
      }
      /**
       * Fix Issue: Additional check for aspect ratio
       * https://github.com/xieziyu/angular2-draggable/issues/132
       */
      if (this._aspectRatio) {
        this._adjusted = false;
        if ((this._direction.w || this._direction.e) && this._currSize.width / this._aspectRatio >= maxHeight) {
          const newWidth = Math.floor(maxHeight * this._aspectRatio);
          if (this._direction.w) {
            this._currPos.x += this._currSize.width - newWidth;
          }
          this._currSize.width = newWidth;
          this._currSize.height = maxHeight;
          this._adjusted = true;
        }
        if ((this._direction.n || this._direction.s) && this._currSize.height * this._aspectRatio >= maxWidth) {
          const newHeight = Math.floor(maxWidth / this._aspectRatio);
          if (this._direction.n) {
            this._currPos.y += this._currSize.height - newHeight;
          }
          this._currSize.width = maxWidth;
          this._currSize.height = newHeight;
          this._adjusted = true;
        }
      }
    }
  }
  checkSize() {
    const minHeight = !this.rzMinHeight ? 1 : this.rzMinHeight;
    const minWidth = !this.rzMinWidth ? 1 : this.rzMinWidth;
    if (this._currSize.height < minHeight) {
      this._currSize.height = minHeight;
      if (this._direction.n) {
        this._currPos.y = this._origPos.y + (this._origSize.height - minHeight);
      }
    }
    if (this._currSize.width < minWidth) {
      this._currSize.width = minWidth;
      if (this._direction.w) {
        this._currPos.x = this._origPos.x + (this._origSize.width - minWidth);
      }
    }
    if (this.rzMaxHeight && this._currSize.height > this.rzMaxHeight) {
      this._currSize.height = this.rzMaxHeight;
      if (this._direction.n) {
        this._currPos.y = this._origPos.y + (this._origSize.height - this.rzMaxHeight);
      }
    }
    if (this.rzMaxWidth && this._currSize.width > this.rzMaxWidth) {
      this._currSize.width = this.rzMaxWidth;
      if (this._direction.w) {
        this._currPos.x = this._origPos.x + (this._origSize.width - this.rzMaxWidth);
      }
    }
  }
  getBounding() {
    const el = this._containment;
    const computed = window.getComputedStyle(el);
    if (computed) {
      let p = computed.getPropertyValue('position');
      const nativeEl = window.getComputedStyle(this.el.nativeElement);
      let transforms = nativeEl.getPropertyValue('transform').replace(/[^-\d,]/g, '').split(',');
      this._bounding = {};
      this._bounding.width = el.clientWidth;
      this._bounding.height = el.clientHeight;
      this._bounding.pr = parseInt(computed.getPropertyValue('padding-right'), 10);
      this._bounding.pb = parseInt(computed.getPropertyValue('padding-bottom'), 10);
      this._bounding.deltaL = this.el.nativeElement.offsetLeft - this._currPos.x;
      this._bounding.deltaT = this.el.nativeElement.offsetTop - this._currPos.y;
      if (transforms.length >= 6) {
        this._bounding.translateX = parseInt(transforms[4], 10);
        this._bounding.translateY = parseInt(transforms[5], 10);
      } else {
        this._bounding.translateX = 0;
        this._bounding.translateY = 0;
      }
      this._bounding.position = computed.getPropertyValue('position');
      if (p === 'static') {
        this.renderer.setStyle(el, 'position', 'relative');
      }
    }
  }
  resetBounding() {
    if (this._bounding && this._bounding.position === 'static') {
      this.renderer.setStyle(this._containment, 'position', 'relative');
    }
    this._bounding = null;
  }
  getGridSize() {
    // set default value:
    this._gridSize = {
      x: 1,
      y: 1
    };
    if (this.rzGrid) {
      if (typeof this.rzGrid === 'number') {
        this._gridSize = {
          x: this.rzGrid,
          y: this.rzGrid
        };
      } else if (Array.isArray(this.rzGrid)) {
        this._gridSize = {
          x: this.rzGrid[0],
          y: this.rzGrid[1]
        };
      }
    }
  }
  static {
    this.ɵfac = function AngularResizableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularResizableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AngularResizableDirective,
      selectors: [["", "ngResizable", ""]],
      inputs: {
        ngResizable: "ngResizable",
        rzHandles: "rzHandles",
        rzHandleDoms: "rzHandleDoms",
        rzAspectRatio: "rzAspectRatio",
        rzContainment: "rzContainment",
        rzGrid: "rzGrid",
        rzMinWidth: "rzMinWidth",
        rzMinHeight: "rzMinHeight",
        rzMaxWidth: "rzMaxWidth",
        rzMaxHeight: "rzMaxHeight",
        rzScale: "rzScale",
        preventDefaultEvent: "preventDefaultEvent"
      },
      outputs: {
        rzStart: "rzStart",
        rzResizing: "rzResizing",
        rzStop: "rzStop"
      },
      exportAs: ["ngResizable"],
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularResizableDirective, [{
    type: Directive,
    args: [{
      selector: '[ngResizable]',
      exportAs: 'ngResizable'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    ngResizable: [{
      type: Input
    }],
    rzHandles: [{
      type: Input
    }],
    rzHandleDoms: [{
      type: Input
    }],
    rzAspectRatio: [{
      type: Input
    }],
    rzContainment: [{
      type: Input
    }],
    rzGrid: [{
      type: Input
    }],
    rzMinWidth: [{
      type: Input
    }],
    rzMinHeight: [{
      type: Input
    }],
    rzMaxWidth: [{
      type: Input
    }],
    rzMaxHeight: [{
      type: Input
    }],
    rzScale: [{
      type: Input
    }],
    preventDefaultEvent: [{
      type: Input
    }],
    rzStart: [{
      type: Output
    }],
    rzResizing: [{
      type: Output
    }],
    rzStop: [{
      type: Output
    }]
  });
})();
class AngularDraggableModule {
  static {
    this.ɵfac = function AngularDraggableModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularDraggableModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AngularDraggableModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularDraggableModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [AngularDraggableDirective, AngularResizableDirective],
      exports: [AngularDraggableDirective, AngularResizableDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular2-draggable
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularDraggableDirective, AngularDraggableModule, AngularResizableDirective, Position };
