import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/core/models/courses';
import { User } from 'src/app/core/models/users/user.models';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { UsersService } from 'src/app/core/services/users';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/models/store.model';
import { take } from "rxjs/operators";
import { availableProfiles } from 'src/app/store/models/profiles.model';

@Component({
    selector: 'app-lista-padres',
    templateUrl: './lista-padres.component.html',
    styleUrls: ['./lista-padres.component.scss'],
    standalone: false
})
export class ListaPadresComponent implements OnInit {
	@Input() profile: string;
	@Output() action: EventEmitter<number> = new EventEmitter<number>()

	parentsList: UserModel[];
	cargando: boolean;
	user: User;
	numberOfInvited: number = 0;
	invitacionesList: any;
	profilesOfUser: availableProfiles;
	year: any;
	showDeleteButton: boolean;

	constructor(
		public loginService: LoginService,
		public userService: UsersService,
		private toaster: ToasterService,
    private translateService: TranslateService,
		public usersService: UsersService,
		private store: Store<State>,
		) {}

	ngOnInit() {
		this.cargando = true;
		this.user = this.loginService.getUser();
		//Para conocer que roles tiene el usuario
		this.store.select(store => store.profiles).subscribe((profiles) => {
			this.profilesOfUser = profiles
		})
		if(this.profile === 'ESTUDIANTE'){
			// OBTENEMOS LISTADO DE PADRES
			this.getParentsList();
			// OBTENEMOS LISTADO DE INVITACIONES
			this.getParentsInvitationsList();
		} else if(this.profile === 'PADRE'){
			// OBTENEMOS LISTADO DE PADRES
			this.getChildrenList();
			// OBTENEMOS LISTADO DE INVITACIONES
			this.getChildrenInvitationsList();
		}
	}

	getParentsList(){
		this.parentsList = [];
		this.userService.getparentsListByStudent().subscribe(res => {
			this.parentsList = res.data;
			this.cargando = false;
		})
	}

	getParentsInvitationsList(){
		this.invitacionesList = [];
		this.userService.findParentsListNotAcceptedByChild().subscribe(res => {
			this.invitacionesList = res.data;
			this.numberOfInvited = res.data.length;
		})
	}

	getChildrenList(){
		this.parentsList = [];
		this.userService.getChildrenListByParent().subscribe(res => {
			this.parentsList = res.data;
			this.cargando = false;
		})
	}

	getChildrenInvitationsList(){
		this.invitacionesList = [];
		this.userService.findChildrenListNotAcceptedByParent().subscribe(res => {
			this.invitacionesList = res.data;
			this.numberOfInvited = res.data.length;
		})
	}

	aceptarInvitacion(user: any){
		const idUser = user.idUser;
		if(this.profile === 'ESTUDIANTE'){
			this.userService.acceptchildfather(idUser, "child").subscribe(res => {
				if(res.error.code === 0){
					this.getParentsList();
					this.getParentsInvitationsList();
					this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONOK'));
				}
			})
		} else if(this.profile === 'PADRE'){
			this.userService.acceptchildfather(idUser, "father").subscribe(res => {
				if(res.error.code === 0){
					this.getChildrenList();
					this.getChildrenInvitationsList();
					this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONOK'));
				}
			})
		}

	}

	rechazarInvitacion(user: any){
		const idUser = user.idUser;
		if(this.profile === 'ESTUDIANTE'){
			this.userService.deletechildfather(idUser, "child").subscribe(res => {
				this.getParentsList();
				this.getParentsInvitationsList();
				this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONKO'));
			})
		} else if(this.profile === 'PADRE'){
			this.userService.deletechildfather(idUser, "father").subscribe(res => {
				this.getChildrenList();
				this.getChildrenInvitationsList();
				this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONKO'));
			})
		}

	}

	nuevoPadre(){
		// mandamos el numero de la pantalla de nuevo padre/hijo
		this.action.emit(2);
	}

	invitarPadre(){
		// mandamos el numero de la pantalla de invitar padre
		this.action.emit(3);
	}

	deleteParent(user: UserModel){
		const idUser = user.idUser;
		if(this.profile === 'ESTUDIANTE'){
			const role = "child";
			this.userService.deletechildfather(idUser, role).subscribe(res => {
				this.getParentsList();
				this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONKO'));
			})
		}else if(this.profile === 'PADRE'){
			const role = "father";
			this.userService.deletechildfather(idUser, role).subscribe(res => {
				this.getChildrenList();
				this.toaster.success(this.translateService.instant('MODALLISTSTUDENTS.INVITATIONKO'));
			})
		}
	}

}
