import { Component } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ModalContactComponent } from '../../../modal-contact/modal-contact.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

@Component({
    selector: 'app-guia-proyectos',
    templateUrl: './guia-proyectos.component.html',
    styleUrls: ['./guia-proyectos.component.scss'],
    standalone: false
})
export class GuiaProyectosComponent {

	constructor(
		private dialogRef: NbDialogRef<any>,
		private nebularDialogService: NbDialogService,
	){}

	closeModal(sendData?: any) {
		this.dialogRef.close(sendData);
	}

	showOpinar(){
		const modalRef = this.nebularDialogService.open(ModalContactComponent, {
			dialogClass: MODAL_DIALOG_TYPES.W60,
		});
		this.closeModal();
	}

}
