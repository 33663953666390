import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface AgentDto {
  id: number;
  profilePhotoUrl?: string;
  voice: string;
  gender: string;
  prompt: string;
  functions: string; // Si ya se está enviando como string
  user: { idUser: number };
}

export interface Agent {
  id: number;
  profilePhotoUrl: string;
  voice: string;
  gender: string;
  prompt: string;
  functions: string;
  user: { idUser: number };
}

@Injectable({
	providedIn: 'root'
})
export class AgentService {



	constructor(
		private http: HttpClient,
	) {}

	/**
	 * ENDPOINT QUE TRAE LOS DATOS INICIALES DE UN USUARIO PARA LA IA DE SALWARE
	 * @returns
	 */
	getInitialInfo():Observable<any> {
		const url:string = 'agent/getInitialInfo';
		return this.http.get<any>(`${url}`);
	}

	/**
	 * ENDPOINT QUE TRAE LOS DATO DE EL AGENTE DE UN USUARIO POR ID
	 * EL ID SE PASA POR PATH PARAM
	 * @returns
	 */
	getAgentByIdUser(id:number):Observable<any> {
		const url:string = 'agent/getAgentByUser';
		return this.http.get<any>(`${url}/${id}`);
	}

	/**
	 * ENDPOINT QUE GUARDA LOS CAMBIOS DE UN AGENTE DE UN USUARIO
	 * @returns
	 */
	updateAgentProfile(agentDto: AgentDto): Observable<Agent> {
    const url = `agent/updateProfile`;
    return this.http.post<Agent>(url, agentDto);
  }

}
