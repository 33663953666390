import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { QuizModel } from 'src/app/core/models/quizzes';
import { DataHistoryClass, DataHistoryInterface } from './interface/datos-resultado.interface';
import { ResultadoActividadesService } from './resultados-quizes-service/resultado-actividades.service';

@Component({
    selector: 'app-datos-resultado-quizes',
    templateUrl: './datos-resultado-quizes.component.html',
    styleUrls: ['./datos-resultado-quizes.component.scss'],
    standalone: false
})
export class DatosResultadoQuizesComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,  {static: true}) sort: MatSort;
  displayedColumns: string[] = [ 'identificador', 'titulo',  'resultado'];
  dataSource: MatTableDataSource<DataHistoryClass> = null;
  listQuiz: QuizModel[] = [];

  constructor(private resultadoActividadesService: ResultadoActividadesService) {}

  ngOnInit() {
    this.updateDataSusbs();
  }

  updateDataSusbs() {
      this.resultadoActividadesService.dataModelSuscrip.subscribe((res: DataHistoryClass) => {
        if (res !== undefined && res !== null) {
          let xs: DataHistoryClass = {
            identificador: res.identificador,
            resultado: res.escrito,
            escrito: res.escrito,
            titulo: res.titulo,
          }
          this.resultadoActividadesService.updateDataHistory(xs);
          this.obtenerDataResultados();
        }
      });

      this.obtenerDataResultados();
  }

  obtenerDataResultados() {
      let res = this.resultadoActividadesService.getDataResultado();
      this.dataSource =  new  MatTableDataSource<DataHistoryClass>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

  }

}

