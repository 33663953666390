<nb-card class="no-edge-card mb-0">
	<nb-card-body>
		<div class="d-flex justify-content-between align-items-center gap-4">
			<div class="d-flex justify-content-center">
				<mat-button-toggle-group [(ngModel)]="type" (change)="onQuizTypeSelected($event)">
					<mat-button-toggle [value]="quizType.TEXT" matTooltip="{{'QUIZZES.HEADER.RESPUESTAABIERTA' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							subject
						</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle [value]="quizType.SINGLE" matTooltip="{{'QUIZZES.HEADER.OPCIONCORRECTA' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							radio_button_checked
						</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle [value]="quizType.MULTIPLE" matTooltip="{{'QUIZZES.HEADER.VARIASOPCIONES' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							check_box
						</mat-icon>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<!-- COMENTADO 11-dic-2024 POR ERRORES EN DESAFIOS, REVISA JAIME -->
			<div class="d-flex justify-content-center">
				<mat-button-toggle-group [(ngModel)]="ordinalType" (change)="onQuizOrdinalTypeSelected($event)">
					<mat-button-toggle [value]="quizOrdinalType.MOTIVATING" matTooltip="{{'QUIZZES.HEADER.MOTIVADORA' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							start
						</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle [value]="quizOrdinalType.ORDINARY" matTooltip="{{'QUIZZES.HEADER.ORDINARIA' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							check_box_outline_blank
						</mat-icon>
					</mat-button-toggle>

					<mat-button-toggle [value]="quizOrdinalType.ACTIVEMEMORY" matTooltip="{{'QUIZZES.HEADER.RECUERDOACTIVO' | translate}}"
						matTooltipClass="tooltip-button-toggle">
						<mat-icon>
							sports_score
						</mat-icon>
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
			<!-- -->

			<div class="d-flex justify-content-center align-items-center gap-3" >
				<div class="d-flex justify-content-center align-items-center px-2">
					<form [formGroup]="form">
						<div class="d-flex flex-column align-items-center gap-1">
							<span class="text-center" style="line-height: 1rem; font-size: 12px;">
								{{'REPETITIONLIST.TITLE' | translate}}
							</span>
							<nb-select formControlName="pattern" class="SelectForm" hero size="small" (selectedChange)="onUpdateRepetition()">
								<nb-option class="SelectFormOpt" *ngFor="let item of repetitionList" [value]="item.pattern">{{item.descripcion}}</nb-option>
							</nb-select>
						</div>
					</form>
				</div>

				<div class="d-flex justify-content-center align-items-center px-2">
					<form [formGroup]="form">
						<div class="d-flex flex-column align-items-center gap-1">
							<span class="text-center" style="line-height: 1rem; font-size: 12px;">
								{{'SIGMACOMPONENT.CERTIFICAR' | translate}}
							</span>
							<nb-toggle class="item-profile" formControlName="certificadoQuiz" (click)="certifiedQuiz($event)"></nb-toggle>
						</div>
					</form>
				</div>

				<div class="d-flex justify-content-center align-items-center px-2">
					<form [formGroup]="form">
						<div class="d-flex flex-column align-items-center gap-1">
							<span class="text-center" style="line-height: 1rem; font-size: 12px;">
								{{ form.get('published').value ? ('EDITARCURSO.VISIBLE' | translate) : ('EDITARCURSO.NOVISIBLE' | translate) }}
							</span>
							<nb-toggle class="item-profile" formControlName="published" (click)="publish($event)"></nb-toggle>
						</div>
					</form>
				</div>

				<div class="d-flex justify-content-center align-items-center px-2">
					<mat-icon class="pointer color-red-text icon-head"
						nbTooltip="{{'QUIZZES.DELETE' | translate }}" nbTooltipPlacement="botton" (click)="deleteQuiz()" *ngIf="loginService.esAutor()">delete
					</mat-icon>
				</div>

				<div class="d-flex justify-content-center align-items-center px-2 ml-4">
					<nb-icon icon="info-outline" class="pointer" (click)="showInfo()"
						nbTooltip="{{'SIDEBARDERECHO.INFORMACION' | translate }}" nbTooltipPlacement="botton">
					</nb-icon>
				</div>
			</div>
		</div>
	</nb-card-body>

	<div class="closeModal" style="position: fixed;">
		<nb-icon class="m-1" icon="checkmark-circle-2" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}" nbTooltipPlacement="left" (click)="onCloseModal()">
		</nb-icon>
	</div>
</nb-card>
