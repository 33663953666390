import { Component, ViewEncapsulation, OnInit, HostListener, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoursesService } from 'src/app/core/services/courses';
import { NodeService } from 'src/app/core/services/node/node.service';
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { map, debounceTime } from 'rxjs/operators';
import { CourseModel } from 'src/app/core/models/courses';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { AlertService } from 'src/app/core/services/shared';
import { TranslateService } from '@ngx-translate/core';
import { ModalCursoEditDetailComponent } from '../../modal-curso-edit-detail/modal-curso-edit-detail.component';
import { LoginService } from 'src/app/core/services/login';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { NbPopoverDirective, NbWindowService, NbDialogService } from "@nebular/theme";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MateriaModel, PaisModel } from 'src/app/core/models/masters';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { ModalEditarCursoMapaComponent } from '../../modal-editar-curso-mapa';
import { LocalStorage } from 'src/app/core/utils';
import { MastersService } from 'src/app/core/services/masters';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
    selector: 'app-lista-cursos-editor',
    templateUrl: './lista-cursos-editor.component.html',
    styleUrls: ['./lista-cursos-editor.component.scss'],
    providers: [SliceStringPipe],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ListaCursosEditorComponent implements OnInit{
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  dataList: any[] = []; //listado de proyectos
  cargando: boolean = false; //para spinner
  networkSpeed = 1; //velocidad de internet
  modeList: boolean = false; //para cambiar entre modo lista y modo grilla
  isMobile: boolean = false; //si el dispositivo es movil o escritorio
  selectedLabel = null; // Variable para guardar el elemento seleccionado
  idSelected: number = 0; // nodo = 1; quizSimple = 2; quizMulti = 3
  i_selected: number = null; //para saber el index del curso abierto
  j_selected: number = null; //para saber el index del target abierto
  k_selected: number = null; //para saber el index del nodo abierto

  //===== variables para editar nodos =====
  node: any;
  idCurso: number;
  idMapa: number;
  canEdit: boolean = false;
  course: CourseModel;
  isNewNode: boolean = false;
  //===== =====

  //===== variables para editar quizzes simples =====
  quiz: any;
  elements: any;
  //===== =====

  //===== variables para editar quizzes multiples =====
  quizFiles: any;
  quizzes: any;
  //===== =====

  // ===== variables para filtros =====
  orderList = [
		{
			nombre: "A - Z",
			idOrder: 1,
		},
		{
			nombre: "Z - A",
			idOrder: 2,
		},
		{
			nombre: "Más recientes",
			idOrder: 3,
		},
		{
			nombre: "Más antiguos",
			idOrder: 4,
		},
		{
			nombre: "Editados Recientemente",
			idOrder: 5,
		},
	];
  filtersList = [
		{
			nombre: "Tipo de proyecto",
			id: 1,
			active: false,
			value: null,
		},
		{
			nombre: "Idioma",
			id: 2,
			active: false,
			value: null,
		},
		{
			nombre: "Asignatura",
			id: 3,
			active: false,
			value: null,
		},
		{
			nombre: "País",
			id: 4,
			active: false,
			value: null,
		},
		{
			nombre: "Nivel",
			id: 5,
			active: false,
			value: null,
		},
		{
			nombre: "Tranversal",
			id: 6,
			active: false,
			value: null,
		},
	];
  langList = [
		{
			nombre: this.translateService.instant("CURSOAUTORES.TODOS"),
			idLang: null,
		},
		{
			nombre: "Español",
			idLang: 38,
		},
		{
			nombre: "Ingles",
			idLang: 36,
		},
		{
			nombre: "Alemán",
			idLang: 31,
		},
		{
			nombre: "Francés",
			idLang: 46,
		},
		{
			nombre: "Italiano",
			idLang: 71,
		},
		{
			nombre: "Portugués",
			idLang: 127,
		},
	];
  projectTypeList = [
		{
			id: null,
			descripcion: this.translateService.instant("CURSOAUTORES.TODOS"),
		},
		{
			id: 1,
			descripcion: this.translateService.instant("EDITARCURSO.ESTUDIO"),
		},
		{
			id: 2,
			descripcion: this.translateService.instant("EDITARCURSO.PRACTICO"),
		},
		{
			id: 3,
			descripcion: this.translateService.instant("EDITARCURSO.PISA"),
		},
		{
			id: 4,
			descripcion: this.translateService.instant("MENUACTIONS.TEACHERTITLE18"),
		},
		{
			id: 5,
			descripcion: this.translateService.instant("EDITARCURSO.CONTROL"),
		},
	];
  subjectsList: MateriaModel[] = this.getDataService.appSubjects;
  subject_all: MateriaModel = {
		idSubject: null,
		subject: this.translateService.instant("CURSOAUTORES.TODOS"),
		description: null,
		idHumanKnowledge: null,
		humanKnowledgeName: null,
		acronym: null,
		color: null,
	};
	buttonText: string = "Filtros";
	showElement: boolean = false;
	isMenuOpen: boolean = false;
  selectedOrder = 1;
  activeOrder = 0;
  historyFilterActive: boolean;
  dataList_aux: any[] = []; //listado de proyectos auxiliar para filtros
  dataList_historial: any[] = [];
  dataList_transversal: any[] = [];
  iconSelected = false;
  fromButtom: boolean;
  formulario: UntypedFormGroup;
  selectedProjectType = null;
	selectedLang = null;
	selectedSubject = null;
	selectedCountry = null;
	selectedLevelCountry = null;
  trasversalProjects: boolean = false;
  activeFiltering: boolean = false;
  notCountryLevelsForThisCountry: boolean = false;
	levelsOfCountry: any[] = [];
	filterCountrySelected: boolean = false;
  countriesList: PaisModel[] = this.getDataService.appCountries;
	country_all: PaisModel = {
		idCountry: null,
		country: "All",
		fieldOrder: null,
		idCountry_ISO3166: null,
		internetDomain: null,
		centers: null,
		countriesRegions: null,
		country_de: "Alle",
		country_es: "Todos",
		country_pt: "Todos",
	};
  // ===== =====
  
	page: number = 1;

  constructor(
    public coursesService: CoursesService,
		public activeModal: NgbActiveModal,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		private modalService: NgbModal,
		private alertService: AlertService,
		public translateService: TranslateService,
		public loginService: LoginService,
		public getDataService: GetDataService,
		private formBuild: UntypedFormBuilder,
		private localStorage: LocalStorage,
		private masterService: MastersService,
  ){
    this.formulario = this.formBuild.group({ filtrado: [""] });
		this.setupSearchListener();
		this.subjectsList.unshift(this.subject_all);
		this.countriesList.unshift(this.country_all);
  }

  ngOnInit(): void {
    this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
    this.getCoursesList(false);
	this.isMobile = window.innerWidth < 1200;
	//definimos variable para tutoriales
	this.localStorage.setItem("filtroInicial", '');
	let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");
	if(lastSelectedProfile === "PROFESOR"){
		this.masterService.setIdTutorialContext(44);
	} else if(lastSelectedProfile === "AUTOR"){
		this.masterService.setIdTutorialContext(89);
	} else if(lastSelectedProfile === "ESTUDIANTE"){
		this.masterService.setIdTutorialContext(75);
	}	
  }

  getCoursesList(fromHide: boolean){
    this.cargando = true;
    this.dataList = [];
    this.coursesService.getListadoCursosEditor().subscribe(res => {
      if(res.data){
        this.dataList = res.data;
        this.dataList_aux = res.data;
        this.changeOrder(this.activeOrder);//ordenado inicial
        if(fromHide){
          this.viewTargets(this.i_selected);
          this.viewNodes(this.i_selected, this.j_selected);
          if(this.k_selected != null){
            this.viewQuizzes(this.i_selected, this.j_selected, this.k_selected);
          }
        }
        this.cargando = false;
      }
    })
  }

  getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

  updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
  }

  closeModal(sendData?: any) {
	this.masterService.getPreviousIdTutorialContext();
	this.activeModal.close();
  }

  changeView(change: boolean){
    this.modeList = change;
    this.closeTree();
  }

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

  viewTargets(i: number){
	if(!this.dataList[i].childrenVisible && this.dataList[i].children.length == 0){
		this.dataList[i].childrenVisible = !this.dataList[i].childrenVisible;
		this.coursesService.getListadoGrafosEditor(this.dataList[i].id).subscribe(res => {
			this.dataList[i].children = res.data;
			this.dataList_aux[i].children = res.data;
			for (let index = 0; index < this.dataList[i].children.length; index++) {
				this.dataList[i].children[index].childrenVisible = false;
			}
		})
	} else{
		this.dataList[i].childrenVisible = !this.dataList[i].childrenVisible;
		for (let index = 0; index < this.dataList[i].children.length; index++) {
			this.dataList[i].children[index].childrenVisible = false;
		}
	}
  }

  viewNodes(i: number, j: number){
	if(!this.dataList[i].children[j].childrenVisible && this.dataList[i].children[j].children.length == 0){
		this.dataList[i].children[j].childrenVisible = !this.dataList[i].children[j].childrenVisible;
		this.coursesService.getListadoNodosEditor(this.dataList[i].id, this.dataList[i].children[j].id).subscribe(res => {
			this.dataList[i].children[j].children = res.data;
			this.dataList_aux[i].children[j].children = res.data;
			for (let index = 0; index < this.dataList[i].children[j].children.length; index++) {
				this.dataList[i].children[j].children[index].childrenVisible = false;
			}
		})
	} else{
		this.dataList[i].children[j].childrenVisible = !this.dataList[i].children[j].childrenVisible;
		for (let index = 0; index < this.dataList[i].children[j].children.length; index++) {
			this.dataList[i].children[j].children[index].childrenVisible = false;
		}
	}
  }

  viewQuizzes(i: number, j: number, k: number){
    this.dataList[i].children[j].children[k].childrenVisible = !this.dataList[i].children[j].children[k].childrenVisible;
  }

  closeTree(){
		if(this.dataList != null && this.dataList.length > 0){
			for (let i = 0; i < this.dataList.length; i++) {
				this.dataList[i].childrenVisible = false;
				if(this.dataList[i].children != null && this.dataList[i].children.length > 0){
					for (let j = 0; j < this.dataList[i].children.length; j++) {
						this.dataList[i].children[j].childrenVisible = false;
            if(this.dataList[i].children[j].children != null && this.dataList[i].children[j].children.length > 0){
              for (let k = 0; k < this.dataList[i].children[j].children.length; k++){
                this.dataList[i].children[j].children[k].childrenVisible = false;
              }
            }
					}
				}
			}
		}
  }

  viewData(indexCurso: number, indexTarget: number, indexNode: number, select: any){
    this.idSelected = 0; //Ocultamos el nodo que este abierto
    this.selectedLabel = select;
    this.i_selected = indexCurso;
    this.j_selected = indexTarget;
    this.k_selected = select.type == 0 ? null : indexNode;
    //si type = 0 es un nodo
    if(select.type == 0){
      this.nodeService.getNode(select.id, this.dataList[indexCurso].id, this.dataList[indexCurso].children[indexTarget].id)
        .pipe(map((res: any) => res.data[0]))
        .subscribe(res => {
          //obtenemos el curso y mostramos el nodo
          if(res){
            this.coursesService.getCourseById(this.dataList[indexCurso].id)
              .subscribe(resp => {
                if(resp.data){
                  //===datos para el nodo===
                  this.node = res;
                  this.idCurso = this.dataList[indexCurso].id;
                  this.idMapa = this.dataList[indexCurso].children[indexTarget].id;
                  this.canEdit = false;
                  this.course = resp.data.courses;
                  this.isNewNode = false;
                  //=== ===
                  this.idSelected = 1; //Para mostrar el nodo
                }
              })
          }
      })
    }
    //si type = 1 es un quiz simple
    else if(select.type == 1){
      this.quizService.getQuiz(select.id, this.dataList[indexCurso].id, this.dataList[indexCurso].children[indexTarget].id)
        .subscribe(res => {
          if(res){
            this.quizService.getQuizFromTargetByModeList(this.dataList[indexCurso].id, this.dataList[indexCurso].children[indexTarget].id, select.id)
              .subscribe(resp => {
                if(resp){
                  //===datos para el quiz simple===
                  this.quiz = {
                    ...res.quiz,
                    user: resp.data.user,
                    idOriginal: resp.data.idOriginal,
                    id: resp.data.id,
                    ordinal: resp.data.ordinal,
                    originalX: resp.data.originalX,
                    originalY: resp.data.originalY,
                    size: resp.data.size,
                    sizeQuiz: resp.data.sizeQuiz,
                    x: resp.data.x,
                    y: resp.data.y
                  };
                  this.elements = res.elements;
                  this.idCurso = this.dataList[indexCurso].id;
                  this.idMapa = this.dataList[indexCurso].children[indexTarget].id;
                  //=== ===
                  this.idSelected = 2; //Para mostrar el quiz simple
                }
              })
          }
        })
    }
    //si type = 2 es un quiz multiple
    else if(select.type == 2){
      this.quizService.getQuizMultiple(select.id, this.dataList[indexCurso].id, this.dataList[indexCurso].children[indexTarget].id)
			.subscribe(res => {
        if(res){
          this.quizService.getQuizFromTargetByModeList(this.dataList[indexCurso].id, this.dataList[indexCurso].children[indexTarget].id, select.id)
          .subscribe(resp => {
            if(resp){
              //===datos para el quiz multiple===
              this.quiz = {
                ...res.quiz,
                user: resp.data.user,
                idOriginal: resp.data.idOriginal,
                id: resp.data.id,
                ordinal: resp.data.ordinal,
                originalX: resp.data.originalX,
                originalY: resp.data.originalY,
                size: resp.data.size,
                sizeQuiz: resp.data.sizeQuiz,
                x: resp.data.x,
                y: resp.data.y
              };
              this.elements = res.elements;
              this.quizFiles = res.quizFiles;
              this.quizzes = res.quizzes;
              this.idCurso = this.dataList[indexCurso].id;
              this.idMapa = this.dataList[indexCurso].children[indexTarget].id;
              //=== ===
              this.idSelected = 3; //Para mostrar el quiz multiple
            }
          })
        }
      })
    }
  }

  hideData(hide: boolean){
    this.idSelected = 0; //para ocultar nodo o quiz
    this.selectedLabel = null;
    this.getCoursesList(hide);
  }

  openCurso(curso: any){
    const modalRef = this.modalService.open(ModalCursoEditDetailComponent, {
      scrollable: false,
      windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
    });
    modalRef.componentInstance.id= curso.id;
		modalRef.result.then(
			(result) => {
				this.clearFilters();
				this.clearSearch();
				switch (result) {
					case "Curso borrado":
						this.alertService.success(this.translateService.instant("CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						this.alertService.success(this.translateService.instant("CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}
				//refrescamos el listado de cursos
				this.getCoursesList(false);
			}
		)
  }

  newTarget(indexCurso, idCurso) {
	this.i_selected = indexCurso;
    this.j_selected = null;
    this.k_selected = null;
	this.coursesService.getCourseById(idCurso)
	.subscribe(res => {
		if(res.data){
			const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
				scrollable: true,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});
			modalRef.componentInstance.id = "nuevo";
			modalRef.componentInstance.curso = res.data.courses;
			modalRef.componentInstance.challengesExclusive = false;

			modalRef.result.then(
				(result) => {
					if (result) {
						//refrescamos el listado de cursos
						this.getCoursesList(true);
					}
				},
				(reason) => {}
			);
		}
	})
  }

  newNode(indexCurso, indexTarget, idTarget) {
	this.i_selected = indexCurso;
    this.j_selected = indexTarget;
    this.k_selected = null;
	this.nodeService.createNodeFromModeList(idTarget)
	.subscribe(res => {
		if(res.data){

			//refrescamos el listado de cursos
			this.getCoursesList(true);
		}
	})
  }

  newQuiz(indexCurso, indexTarget, indexNode, idCourse: number, idTarget: number, idNode: number) {
	this.i_selected = indexCurso;
    this.j_selected = indexTarget;
    this.k_selected = indexNode;
	this.quizService.createQuizFromModeList(idCourse, idTarget, idNode)
	.subscribe(res => {
		if(res.data){
			//refrescamos el listado de cursos
			this.getCoursesList(true);
		}
	})
  }

  /** ============================================== */
  /** =============== INICIO FILTROS =============== */
  changeOrder(order) {
		switch (order) {
			case 1:
        this.dataList = this.dataList.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
		break;
			case 2:
        this.dataList = this.dataList.sort((a, b) =>
          b.title.localeCompare(a.title)
        );
		break;
			case 3:
        this.dataList = this.dataList.sort(
          (a, b) =>
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
        );
		break;
			case 4:
        this.dataList = this.dataList.sort(
          (a, b) =>
            new Date(a.creationDate).getTime() -
            new Date(b.creationDate).getTime()
        );
		break;
			case 5:
        this.dataList = this.dataList.sort(
          (a, b) =>
            new Date(b.editDate).getTime() - new Date(a.editDate).getTime()
        );
		break;
			default:
		break;
		}
		this.activeOrder = order;
	}

  historyFilter() {
    if (this.historyFilterActive == true) {
      this.dataList = [];
      this.dataList = this.dataList_aux;
      this.historyFilterActive = !this.historyFilterActive;
      this.clearFilters();
      return;
    } else {
      this.clearFilters();
    }
    this.historyFilterActive = !this.historyFilterActive;
    let localStoredHistory_editor = JSON.parse(
      localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
    );
    this.dataList = [];
    this.dataList = this.dataList_aux
      .filter((curso) => localStoredHistory_editor.includes(curso.id))
      .sort((a, b) => {
        const indexA = localStoredHistory_editor.indexOf(a.id);
        const indexB = localStoredHistory_editor.indexOf(b.id);
        return indexB - indexA;
      });
		this.dataList_historial = this.dataList;
	}

  openFiltersPanel() {
		this.fromButtom = true;
		if (this.popover.isShown == false) {
			this.iconSelected = !this.iconSelected;
			this.popover.show();
		}
	}

  closeFiltersPanel() {
		if (this.popover.isShown && this.fromButtom == false) {
			this.iconSelected = !this.iconSelected;
			this.popover.hide();
		}
		this.fromButtom = false;
	}

  clearFilters() {
		this.formulario.setValue({ filtrado: [""] });
		this.selectedProjectType = null;
		this.selectedLang = null;
		this.selectedSubject = null;
		this.selectedCountry = null;
		this.selectedLevelCountry = null;
		this.trasversalProjects = false;
		this.dataList = this.dataList_aux;

		this.filtersList[0].value = null;
		this.filtersList[1].value = null;
		this.filtersList[2].value = null;
		this.filtersList[3].value = null;
		this.filtersList[4].value = null;
		this.filtersList[5].value = false;

		this.activeFiltering = false;
	}

  applyFilters(value, filter) {
		switch (filter) {
			case 0:
				this.filtersList[0].value = value;
				break;
			case 1:
				this.filtersList[1].value = value;
				break;
			case 2:
				this.filtersList[2].value = value;
				break;
			case 3:
				this.filtersList[3].value = value;
				break;
			case 4:
				this.filtersList[4].value = value;
				break;
			case 5:
				this.filtersList[5].value = value.target.checked;
				break;
			default:
				break;
		}
		this.activeFiltering = true;
		this.filterCourses();
		this.filtrarPorTitulo();
	}

  filterCourses(optionalCoursesToFilter?) {
		let batchToFilter;
		if (optionalCoursesToFilter != null) {
			batchToFilter = optionalCoursesToFilter;
		} else if (this.historyFilterActive == true) {
			batchToFilter = this.dataList_historial;
		} else {
			batchToFilter = this.dataList_aux;
		}
		this.dataList = batchToFilter.filter((curso) => {
			return (
				(!this.filtersList[0].value ||
					curso.type === this.filtersList[0].value) &&
				(!this.filtersList[1].value ||
					curso.idLanguage === this.filtersList[1].value) &&
				(!this.filtersList[2].value ||
					curso.subjectsList.some(
						(subject) => subject.idSubject === this.filtersList[2].value
					)) &&
				(!this.filtersList[3].value ||
					curso.idCountry === this.filtersList[3].value) &&
				(!this.filtersList[4].value ||
					curso.level === this.filtersList[4].value) &&
				(!this.filtersList[5].value || curso.subjectsList.length > 1)
			);
		});
		if (this.activeOrder != 0) {
			this.changeOrder(this.activeOrder);
		}
	}

	filtrarPorTitulo() {
		let searchTerm;
		if (this.formulario.get("filtrado").value != "") {
		  searchTerm = this.normalizeText(
			this.formulario.get("filtrado").value.toLowerCase()
		  );
		}
		this.dataList = [...this.dataList_aux];
		this.filterCourses();
		if (searchTerm) {
		  const searchTerms = searchTerm.split(" "); // Divide el término en palabras
		  this.dataList = this.dataList.filter((curso) => {
			const normalizedTitle = this.normalizeText(curso.title.toLowerCase());
			return searchTerms.every((term) => normalizedTitle.includes(term)); // Verifica todas las palabras
		  });
		}
	}
	
	// Función para normalizar el texto eliminando tildes
	normalizeText(text) {
		return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	clearSearch() {
		this.formulario.get('filtrado')?.setValue('');
	}	  

  searchLevels(country) {
		let country_aux = country == "" || country == null ? "ESP" : country;
		this.levelsOfCountry = [];
		const level_all = {
			idCountry: null,
			idCountryLevel: 0,
			countryLevel: this.translateService.instant("CURSOAUTORES.TODOS"),
			contryLevelColor: "bcbcbc",
		};
		this.coursesService
			.getCountryLevelsByCountry(country_aux)
			.subscribe((countryLevels) => {
				this.filterCountrySelected = true;
				if (countryLevels.data.length == 0) {
					this.notCountryLevelsForThisCountry = true;
					this.formulario.patchValue({ idCountryLevel: 0 });
				} else {
					this.notCountryLevelsForThisCountry = false;
					this.formulario.patchValue({
						idCountryLevel: countryLevels.data[0].idCountryLevel,
					});
				}
				this.levelsOfCountry = countryLevels.data;
				this.levelsOfCountry.unshift(level_all);
			});
	}

  setupSearchListener() {
		this.formulario
			.get("filtrado")
			.valueChanges.pipe(debounceTime(800)) //TIEMPO QUE TENEMOS PARA ESCRIBIR EN EL FILTRO EN MILISEGUNDOS
			.subscribe(() => {
				this.filtrarPorTitulo();
			});
	}
  /** =============== FIN FILTROS =============== */
  /** =========================================== */

	@HostListener("window:resize", ["$event"])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	toggleFilters() {
		this.showElement = !this.showElement;
		this.buttonText = this.showElement ? "Cerrar" : "Filtros";
	}
}
