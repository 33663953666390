import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $: any;

@Component({
    selector: 'app-modal-asistencia-estudiante',
    templateUrl: './modal-asistencia-estudiante.component.html',
    styleUrls: ['./modal-asistencia-estudiante.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalAsistenciaEstudianteComponent implements OnInit {

	idUser: number = 0;
	idGrupo: number = 0;
	name: string = "";
	fecha: string = "";
	fechaCorta: string = "";
	status: number = 0;
	motivo: string = "";
	screen: number = 1;
	dato: any[] = [];
  isMobileView: boolean = false;
  selectedTab: number = 0;
	fechaInicio: string = '';
  fechaFin: string = '';
	summary: any;
	validacionFecha: boolean = false;

	form: UntypedFormGroup;
	forms: UntypedFormGroup[] = [];

	constructor(
		public activeModal: NgbActiveModal,
		private groupService: GruposService,
		private toaster: ToasterService,
		public translateService: TranslateService,
		public loginService: LoginService,
		private modalService: NgbModal,
		private formBuild: UntypedFormBuilder,
		private fb: FormBuilder,
	){}

	ngOnInit() {
		this.checkScreenSize();
		this.fechaCorta = this.fecha.substring(0, 10);
		this.dato.forEach(item => {
      this.forms.push(this.formBuild.group({
        status: [item.status, Validators.required],
        motivo: [item.motivo, Validators.maxLength(250)]
      }));
    });
	}

	ngAfterViewInit() {
  }

	closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

  justify(index: number) {
    const form = this.forms[index];
    if (form.valid) {
      const fv = form.value;
      this.groupService.changeAttendance(this.idGrupo, fv.status, this.idUser, this.dato[index].fecha, fv.motivo).subscribe(
        resp => {
          if(resp != null){
            this.toaster.success(this.translateService.instant("ASISTENCIA.ATTENDANCEREPONSEOK"));
            this.closeModal();
          }
        }
      );
    }
  }

	validarFechas() {
    if (this.fechaInicio && this.fechaFin) {
      const fechaInicioTimestamp = new Date(this.fechaInicio).getTime();
      const fechaFinTimestamp = new Date(this.fechaFin).getTime();

      if (fechaInicioTimestamp > fechaFinTimestamp) {
        this.validacionFecha = true;
      } else {
				this.groupService.summary(this.idGrupo, this.idUser, this.fechaInicio, this.fechaFin).subscribe(res => {
					this.summary = res.data;
				});
      }
    }
  }

  adjustToUserTime(utcTime: string): string {
		if(utcTime == ''){
			return '';
		} else{
			// Crear un objeto Date con la hora UTC
			const date = new Date(`1970-01-01T${utcTime}Z`);
			// Obtener la hora local ajustada en formato HH:mm
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');
			return `${hours}:${minutes}`;
		}
	}

	getTabClass(status: number): string {
		switch (status) {
			case 1: // Pendiente
				return 'tab-pendiente';
			case 2: // Ausente
				return 'tab-ausente';
			case 3: // Presente
				return 'tab-presente';
			case 4: // Con retraso
				return 'tab-retraso';
			case 5: // Justificado
				return 'tab-justificado';
			default:
				return '';
		}
	}

	@HostListener('window:resize', [])
  checkScreenSize() {
    this.isMobileView = window.innerWidth <= 1024;
  }
}
