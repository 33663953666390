import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';

@Component({
    selector: 'app-quiz-play-body-data-preview',
    templateUrl: './quiz-play-body-data.component.html',
    styleUrls: ['./quiz-play-body-data.component.scss'],
    standalone: false
})
export class QuizPreviewBodyDataComponent implements OnInit {
    @Input() questions: any[];

    public dataForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {
        this.dataForm = this.fb.group({
            questions: this.fb.array([])
        });
    }

    ngOnInit() {
        this.questionsArray.clear();
        this.questions.forEach(q => {
            this.addQuestion(q);
        });
    }

    public get questionsArray(): UntypedFormArray {
        return this.dataForm.get('questions') as UntypedFormArray;
    }

    public get questionsArrayData(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get questionsArrayType(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get questionsArrayWidth(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get questionsArrayHeight(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    private addQuestion(question: any): void {
        const newQuestion: UntypedFormGroup = this.createQuestion(question);
        this.questionsArray.push(newQuestion);
    }

    private createQuestion(question: any): UntypedFormGroup {
        return this.fb.group({
            id: { value: question.idQuizzesDataElements, disabled: true },
            data: { value: question.data, disabled: true },
            type: { value: question.elementType, disabled: true },
            order: { value: question.yPosition, disabled: true },
            width: question ? question.xSize : 0,
            height: question ? question.ySize : 0
        });
    }
}
