import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ChallengesCreateRoomComponent } from "../challenges-create-room/challenges-create-room.component";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { Subscription, finalize, take } from "rxjs";
import { QuizzesService } from "src/app/core/services/quizzes";
import { CourseListModel } from "../cursos/modal-cursos-listado/interface/modal-cursos-listado";
import { Utils } from "src/app/core/utils/utils";
import { User } from "src/app/core/models/users/user.models";
import { LoginService } from "src/app/core/services/login";
import {
	CountdownConfig,
	CountdownEvent,
	CountdownComponent,
} from "ngx-countdown";
import { CompetitionsService } from "src/app/core/services/competitions/competitions.service";

@Component({
    selector: "app-challenges-host-waiting",
    templateUrl: "./challenges-host-waiting.component.html",
    styleUrls: ["./challenges-host-waiting.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ChallengesHostWaitingComponent {
	@Input() onlineUsers: number;
	@Input() cameFromLobby: boolean = false;
	@Input() timeBeforeStart: number;
	@Input() target: any;
	teams: any;
	group: any;
	usersOfTheGroup: any;
	hex: any;
	idTarget: number;
	selectedType = SOCKETMESSAGES.OPEN;
	option: number;
	private subscriptions: Subscription[] = [];
	listQuizzes: CourseListModel[] = [];
	isLoading = true;
	idCourse: number;
	currentIdArrayQuiz: number = 0;
	user: User;

	configClock: CountdownConfig;
	isClockShown: boolean = false;

	usuariosOnline: any[] = [];
	hostUser: boolean = false;
	@ViewChild("cd", { static: false }) private countdown: CountdownComponent;
	usersConnectedToTheGame: any[] = [];
	constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private socketService: SocketService,
		private groupServices: GruposService,
		private quizzesService: QuizzesService,
		public loginService: LoginService,
		public utils: Utils,
		private competitionsService: CompetitionsService
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		if (this.cameFromLobby === true) {
			this.competitionsService
				.getCompetitionSession(this.hex)
				.subscribe((data) => {
					this.teams = data.data.teams;
					this.hostUser = data.data.roomProps.hostUser === this.user.idUser;
					let roomProps = JSON.parse(data.data.roomProps);
					this.usersConnectedToTheGame = roomProps.connectedUsers;
					this.usersOfTheGroup = this.usersConnectedToTheGame;
				});
			//this.getSocketData();
		}else{
			this.competitionsService
				.getCompetitionSession(this.hex)
				.subscribe((data) => {
					this.teams = data.data.teams;
					this.hostUser = data.data.roomProps.hostUser === this.user.idUser;
					let roomProps = JSON.parse(data.data.roomProps);
					this.usersConnectedToTheGame = roomProps.connectedUsers;
					this.usersOfTheGroup = this.usersConnectedToTheGame;
				});
		}
		this.getSocketData();
		this.user = this.loginService.getUser();

		this.configClock = {
			leftTime: this.timeBeforeStart,
			demand: false,
		};
	}
	closeModal(sendData?: any) {
		if (sendData === "manualClosed") {
			this.competitionsService
				.getCompetitionSession(this.hex)
				.subscribe((data) => {
					let {
						idCompetitionSession,
						creationDate,
						idGroup,
						idSession,
						idUser,
						nameSession,
						teams,
						roomProps,
						teacher,
					} = data.data;
					let modifyRoomProps = JSON.parse(roomProps);

					//remove this.user.idUser from connectedUsers
					modifyRoomProps.connectedUsers =
						modifyRoomProps.connectedUsers.filter(
							(user) => user.id != this.user.idUser
						);

					if (this.user.idUser == modifyRoomProps.hostUser) {
						modifyRoomProps.gameIsActive = false;
					}
					roomProps = JSON.stringify(modifyRoomProps);

					this.competitionsService
						.updateCompetitionSession(
							idCompetitionSession,
							creationDate,
							idGroup,
							idSession,
							idUser,
							teacher,
							nameSession,
							teams,
							roomProps
						)
						.subscribe((result) => {
							this.socketService.setForceCloseQuizzPreviewComponent(true);
						});
				});
			//this._removeSocket(0);
		}
		//this.destroy$.next(true);
		this.activeModal.close(sendData);

	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupServices.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	subscribeToOnlineUsersInRoom() {}

	subscribeToRoomCode() {}

	startClock() {
		this.isClockShown = true;
		this.countdown.begin();
	}

	cancelStartClock() {
		this.isClockShown = false;
		this.timeBeforeStart = this.timeBeforeStart;
		this.countdown.pause();
	}

	startGame() {
		this.socketService.configChallenge = {
			option: "3",
			challengeTime: 40,
			synchronizedStudents: false,
			randomOrder: true,
		};
		const configChallenge = this.socketService.configChallenge;

		this.groupServices
			.sendModAutoChallengeSocket(this.target.idTarget, this.hex, 3)
			.subscribe((data) => {
				this.closeModal("");
			});
			this.competitionsService
				.getCompetitionSession(this.hex)
				.subscribe((data) => {
					let {
						idCompetitionSession,
						creationDate,
						idGroup,
						idSession,
						idUser,
						nameSession,
						teams,
						roomProps,
						teacher,
					} = data.data;
					let modifyRoomProps = JSON.parse(roomProps);
					if (this.user.idUser == modifyRoomProps.hostUser) {
						modifyRoomProps.gameIsActive = false;
					}
					roomProps = JSON.stringify(modifyRoomProps);

					this.competitionsService
						.updateCompetitionSession(
							idCompetitionSession,
							creationDate,
							idGroup,
							idSession,
							idUser,
							teacher,
							nameSession,
							teams,
							roomProps
						)
						.subscribe((result) => {});
				});

	}

	private getSocketData() {
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			switch (type) {
				case SOCKETMESSAGES.OPEN:
					this.socketService.setansweredBySocket(false);
					//this.answered = false;
					//this.answeredBySocket = false;
					const idQuiz = parseInt(dataArray[1]);
					const idTarget = parseInt(dataArray[2]);
					const idCourse = parseInt(dataArray[3]);
					const typeQuiz = dataArray[4];
					const multi = typeQuiz === "multiple" ? true : false;
					//this.getDataQuiz(idQuiz, idTarget, idCourse, [], multi); //Recupero el quiz
					break;
				case SOCKETMESSAGES.CLOSE:
					//Mando las respuestas del quiz al back
					//this.socketService.setansweredBySocket(true);
					//this.answeredBySocket = true; //Le digo al componente que tiene que mandar las respuestas al back
					break;
				case SOCKETMESSAGES.FINISH:
					break;
				case SOCKETMESSAGES.CLOSESOCKET:
					//this.close();
					break;
				case SOCKETMESSAGES.USERSCOUNTER:
					const onlineUsers = parseInt(dataArray[1]);
					this.onlineUsers = onlineUsers;
						setTimeout(() => {
							this.competitionsService
								.getCompetitionSession(this.hex)
								.subscribe((data) => {
									let roomProps = JSON.parse(data.data.roomProps);
									this.usersConnectedToTheGame = roomProps.connectedUsers;
									this.usersOfTheGroup = this.usersConnectedToTheGame;
								});
						}, 250);
					break;
				case SOCKETMESSAGES.ORDERMODAUTO:
					const idTargetModAuto = parseInt(dataArray[1]);
					this.idTarget = parseInt(dataArray[1]);
					const idCase = parseInt(dataArray[2]);
					this.option = idCase;
					this.selectedType = SOCKETMESSAGES.ORDERMODAUTO;
					this.getModAutoList(idTargetModAuto, idCase);
					this.closeModal("");
					break;
			}
		});
		this.subscriptions.push(msgSubs); // Añadimos susbcribe para poder destruirlo al cerrar el modal
	}

	private getModAutoList(idTarget: number, idCase: number): void {
		this.quizzesService
			.getModeAutoList(idTarget, idCase)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(result) => {
					this.listQuizzes = result.data as CourseListModel[];
					this.idCourse =
						this.listQuizzes[
							this.currentIdArrayQuiz == this.listQuizzes.length
								? this.currentIdArrayQuiz - 1
								: this.currentIdArrayQuiz
						].idCourse;
					//this.nextQuiz();
				},
				(err) => {}
			);
	}

	getUserAvatar(pictureUser): string {
		return this.utils.getUserAvatar(pictureUser);
	}

	close() {
		this.socketService.setSyncChallenge(false);
		this.activeModal.close();
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
	}

	handleCountDownEvent(e: CountdownEvent) {
		if (e.action === "done") {
			this.startGame();
		}
	}
}
