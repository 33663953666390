import { SigmaCanvasService } from './../../nodes/sigma-canvas/sigma-canvas.service';
import { MastersService } from "src/app/core/services/masters";
import { Component, OnInit, ViewEncapsulation, TemplateRef, HostListener } from "@angular/core";
import { UntypedFormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { Router } from "@angular/router";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { finalize, take } from "rxjs/operators";
import { CourseModel, CourseTargetModel } from "src/app/core/models/courses";
import {
	IdiomaModel,
	MateriaModel,
	NivelModel,
} from "src/app/core/models/masters";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { DEFAULTCLOSEPADS } from "src/app/core/models/masters/masters.enum";
import { User } from "src/app/core/models/users/user.models";
import { CoursesService } from "src/app/core/services/courses";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { LoginService } from "src/app/core/services/login";
import { NodeService } from "src/app/core/services/node/node.service";
import { QuizzesService } from "src/app/core/services/quizzes";
import { AlertService } from "src/app/core/services/shared";
import { TargetsService } from "src/app/core/services/targets";
import { UsersService } from "src/app/core/services/users";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { Utils } from "src/app/core/utils/utils";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { environment } from "src/environments/environment";
import { ModalCelebrationComponent } from "../../modal-celebration/modal-celebration.component";
import { CourseListModel } from "../modal-cursos-listado/interface/modal-cursos-listado";
import { ModalEditarCursoMapaComponent } from "../modal-editar-curso-mapa";
import { ModalInformationCourseComponent } from "../modal-information-course/modal-information-course.component";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { ModalAlertFlashComponent } from "../../modal-profile-actions/modal-alert-flash/modal-alert-flash.component";
import { RecordarQuizPlayMultipleComponent } from "../../quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { RecordarQuizPlayComponent } from "../../quiz-open/quiz-play/quiz-play.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { ActividadesFlashComponent } from "../../nodes/actividades-flash/app-actividades-flash.component";
import { LocalStorage } from "src/app/core/utils";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import { NbPopoverDirective, NbWindowService, NbDialogService } from "@nebular/theme";
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ReportingComponent } from '../../reporting/reporting.component';

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

const ELEMENTTYPE = {
	COURSE: "COURSE",
	GRAPH: "GRAPH",
	NODE: "NODE",
	QUIZ: "QUIZ",
};

const URLCELEBRATIONS: string = environment.celebrations;

export class ParentGroupValidationStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const invalidCtrl = !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched)
		);
		const invalidParent = !!(
			control &&
			(control.dirty || control.touched) &&
			control.parent &&
			control.parent.invalid &&
			control.parent.dirty
		);

		return invalidCtrl || invalidParent;
	}
}

@Component({
    selector: "app-modal-curso-detail",
    templateUrl: "./modal-curso-detail.component.html",
    styleUrls: ["./modal-curso-detail.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalCursoDetailComponent implements OnInit {
	cursoDatos = {
		title: "",
		description: "",
		subject: "",
		lang1: {},
		level: "",
		countryData: {},
		languageData: {},
		image: "",
		user: {
			name: "",
			surname: "",
			image: "",
		},
		averageKnowledge: "",
		barColor: "",
		progress: 0,
	};

	user: User;
	buscarNodo: any;
	cargando: boolean;
	materias: MateriaModel[] = [];
	niveles: NivelModel[] = [];
	idiomas: IdiomaModel[] = [];
	courseTargets: CourseTargetModel[] = [];
	exclusiveCourseTargets: CourseTargetModel[] = [];
	idImage: string = "";
	id: string;
	saving: boolean = false;
	matcher = new ParentGroupValidationStateMatcher();
	curso: CourseModel;
	modoTraerNodo: any;
	modoTraerActividad: any;
	public graphView: string = "gridList";
	treeGraphs: CourseListModel[] = [];
	quiz: any = null;
	idSelectedCourse: number = null;
	idSelectedTarget: number = null;
	selectedCourse: any = null;
	answered: boolean = false;
	isLoading: boolean = false;
	idGraph: number = null;
	elements: any[] = [];
	isOpenInfoCourse = true;
	node: any = null;
	mouseInterval: any = "";
	isShowFiles: boolean = true;
	isShowPads: boolean = false;
	isShowLabels: boolean = false;
	viewQuiz: boolean = false;
	saveClicked: Subject<any> = new Subject<any>();
	saveClicked$: Observable<any> = this.saveClicked.asObservable();

	idGroup: number;
	profileTeacher: false;
	countryLevelName: any;

	actividadesFlash: boolean = false;
	markedItems: any[] = [];
	maxBigQuizzes: number;
	maxAllQuizzes: number;
	indice: number;
	targetsList: any[] = [];
	sizeDeck: any;
	includeSmall: any;
	iconGraphActive: boolean = false;
	listQM: any[];
	selectedButtonIndex: number = 0;
	idCurso: number = 0;
	fromSticker_grafo: boolean = false; //viene true cuando quierer copiar el enlace de un grafo
	networkSpeed = 1;
	modePractice = false;
	filtroInicial = "";
	isMobile: boolean = false;
	infoGrupo: any;
	inGroup: number = 0;
	isMenuOpen: boolean = false;

	cameFromCalendar: boolean = false;
	verIdeasEnLista: boolean;
	page: number = 1;

	constructor(
		private utils: Utils,
		public loginService: LoginService,
		private getDataService: GetDataService,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private imagePipe: ImagenPipe,
		public userService: UsersService,
		public router: Router,
		private quizService: QuizzesService,
		private nodeService: NodeService,
		private graphServ: GraphService,
		private masterService: MastersService,
		private toaster: ToasterService,
		private localStorage: LocalStorage,
		private toolsService: SigmaToolbarsService,
		private sigmaCanvasService: SigmaCanvasService,
		private dialogService: NbDialogService,
		private groupService: GruposService,
	) {}

	ngOnInit() {
		//definimos variable para tutoriales
		let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");		
		let idTutorialContext = 0;
		switch (this.loginService.getProfile()) {
			case 'PROFESOR':
				if (this.filtroInicial == "explicacionAsociativa") {
					idTutorialContext = 16;
				} else if(this.filtroInicial == "practicar"){
					idTutorialContext = 25;
				} else if (this.filtroInicial == "pruebaNivel") {
					idTutorialContext = 29;
				} else if (this.filtroInicial == "abpCasos") {
					idTutorialContext = 33;
				} else if (this.filtroInicial == "pensarCreando") {
					idTutorialContext = 38;
				} else if (this.filtroInicial == "pensarCreando(ver)") {
					idTutorialContext = 41;
				} else if(this.filtroInicial == "explorar"){
				}				
				if(lastSelectedProfile === "AUTOR"){
					idTutorialContext = 85;
				}
				this.masterService.setIdTutorialContext(idTutorialContext);
				break;
			case 'ESTUDIANTE':
				if (this.filtroInicial == "explicacionAsociativa") {
				} else if(this.filtroInicial == "practicar"){
				} else if (this.filtroInicial == "pruebaNivel") {
				} else if (this.filtroInicial == "abpCasos") {
				} else if (this.filtroInicial == "pensarCreando") {
				} else if (this.filtroInicial == "pensarCreando(ver)") {
					idTutorialContext = 62;
				} else if(this.filtroInicial == "explorar"){
				}
				if(this.localStorage.getItem("TutorialesON") == "true"){
					this.masterService.openModalTutorial(idTutorialContext);
				}
				break;
			default:
				break;
		}
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.modePractice = this.localStorage.getItem("modePractice") ? true : false;
		this.cargando = true;
		this.user = this.loginService.getUser();
		this.materias = this.getDataService.appSubjects;
		this.indice = 0;

		// Obtengo los maestros
		this.obtenerDatosMaestros();
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		if(this.inGroup == 0){
			this.idGroup = 0;
		} else {
			this.idGroup = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		}

		if ((this.loginService.getProfile() === "PROFESOR" || this.loginService.getProfile() === "CENTRO") && this.filtroInicial === undefined) {
			this.obtenerDatosCursoGrupo(this.id);
		} else if ((this.loginService.getProfile() === "PROFESOR" || this.loginService.getProfile() === "CENTRO") && this.filtroInicial !== undefined){
			this.obtenerDatosCursoEstudianteGrupo(this.id, this.idGroup);
		}	else if (this.loginService.getProfile() !== "PROFESOR" && this.idGroup !== 0 && this.filtroInicial !== "pensarCreando(ver)") {
			this.obtenerDatosCursoEstudianteGrupo(this.id, this.idGroup); //---solo se ejecuta cuando SI se vaya a tener en cuenta el grupo seleccionado---
		} else if (this.loginService.getProfile() !== "PROFESOR" && this.idGroup !== 0 && this.filtroInicial === "pensarCreando(ver)") {
			this.obtenerDatosCurso(this.id); //---solo se ejecuta cuando SI se vaya a tener en cuenta el grupo seleccionado---
		} else if (this.loginService.getProfile() !== "PROFESOR" && this.idGroup === 0) {
			this.obtenerDatosCurso(this.id); //---solo se ejecuta cuando NO se vaya a tener en cuenta el grupo seleccionado---
		} else if (
			this.loginService.getProfile() !== "PROFESOR" &&
			!this.actividadesFlash
		) {
			this.obtenerDatosCurso(this.id);
		} else if (
			this.loginService.getProfile() !== "PROFESOR" &&
			this.actividadesFlash
		) {
			this.obtenerDatosCurso(this.id);
			//definimos variable para tutoriales
			this.masterService.setIdTutorialContext(65);
		}

		this.isMobile = window.innerWidth < 1200;
		//Creamos el log
		const bodyLog1 = {
			action: "proyectos",
			actionData: "Ha entrado en el Proyecto con id: " + this.id,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: storedGroupInfo ? storedGroupInfo.idGroup : 0,
		};
		this.masterService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.cursoDatos.user.image);
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	obtenerDatosMaestros() {
		this.idiomas = this.getDataService.appLanguages;
		this.niveles = this.getDataService.appLevels;
	}

	obtenerDatosCurso(id) {
		this.cargando = true;
		this.coursesService.getCourseById(id).subscribe((response) => {
			this.curso = response.data.courses as CourseModel; // Course detail
			if(this.graphView === "inlineList"){
				if(this.loginService.getProfile() === "AUTOR"){
					let grafo = JSON.parse(this.localStorage.getItem('lastTarget_Editor'))[0];
					//let idGrafo = grafo.idGrafo;
					this.getTreeData(grafo);
				} else{
					let grafo = JSON.parse(this.localStorage.getItem('lastTarget'))[0];
					//let idGrafo = grafo.idGrafo;
					this.getTreeData(grafo);
				}
			}
			this.coursesService
				.getCountryLevelsById(this.curso.countryLevel)
				.subscribe((countryLevel) => {
					this.countryLevelName = countryLevel.data[0].countryLevel;
				});
			const country = this.getDataService.appCountries.find(
				(country) => country.idCountry === this.curso.idCountry
			);
			this.curso.averageKnowledge = this.curso.averageKnowledge.toFixed(1);
			//Tengo que filtrarlo si es profesor o si es otro rol
			if (this.loginService.esProfesor()) {
				this.orderCoursesTargets(response.data.coursesTarget);
			} else if (!this.loginService.esProfesor() && !this.actividadesFlash) {
				const lista = response.data.coursesTarget;
				if(this.filtroInicial === "pensarCreando(ver)"){
					this.targetsService.gettargetpensarcreandobyidcourse(this.curso.idCourse).subscribe(
						res => {
							for (let index = 0; index < lista.length; index++) {
								const element = lista[index];
								for (let index2 = 0; index2 < res.data.length; index2++) {
									const element2 = res.data[index2];
									if(element.target.idTarget === element2.idTarget){
										this.courseTargets.push(element);
									}
								}
							}
						}
					);
				} else{
					this.courseTargets = lista.filter((element: any) => {
						return (
							element.target.published !== null && !element.target.exclusive
						);
					})
					.sort((a: any, b: any) => {
						if (a.target.tittle < b.target.tittle) {
							return -1;
						} else if (a.target.tittle > b.target.tittle) {
							return 1;
						} else {
							return 0;
						}
					});
				}
				for (let index = 0; index < this.courseTargets.length; index++) {
					let element = this.courseTargets[index];
					element.averageKnowledge = element.averageKnowledge.toFixed(1);
				}
			} else if (!this.loginService.esProfesor() && this.actividadesFlash) {
				this.targetsService.getTargetListResolved(id).subscribe((respo) => {
					this.courseTargets = respo.data
						.filter((element: any) => {
							return (
								element.target.published !== null && !element.target.exclusive
							);
						})
						.sort((a: any, b: any) => {
							if (a.target.tittle < b.target.tittle) {
								return -1;
							} else if (a.target.tittle > b.target.tittle) {
								return 1;
							} else {
								return 0;
							}
						});
				});
			}
			this.cursoDatos = {
				title: this.curso.courseTittle,
				description: this.curso.description,
				subject: "",
				lang1: "",
				level: "",
				countryData: country.country_es,
				languageData: this.curso.language.language,
				image: this.curso.cpicture
					? this.imagePipe.transform(this.curso.cpicture, "cursos")
					: "",
				user: {
					name: this.curso.user.firstName,
					surname: this.curso.user.surname,
					image: this.curso.user.pictureUser,
				},
				averageKnowledge: this.curso.averageKnowledge,
				barColor: this.curso.barColor,
				progress: this.curso.progress,
			};
			this.cargando = false;
		});
	}

	obtenerDatosCursoEstudianteGrupo(idCourse, idGrupo) {
		this.cargando = true;
		this.coursesService
			.getCourseByIdCourseIdGroup(idCourse, idGrupo)
			.subscribe((response) => {
				this.curso = response.data.courses as CourseModel; // Course detail
				if(this.graphView === "inlineList"){
					if(this.loginService.getProfile() === "AUTOR"){
						let grafo = JSON.parse(this.localStorage.getItem('lastTarget_Editor'))[0];
						//let idGrafo = grafo.idGrafo;
						this.getTreeData(grafo);
					} else{
						let grafo = JSON.parse(this.localStorage.getItem('lastTarget'))[0];
						//let idGrafo = grafo.idGrafo;
						this.getTreeData(grafo);
					}
				}
				this.coursesService
					.getCountryLevelsById(this.curso.countryLevel)
					.subscribe((countryLevel) => {
						this.countryLevelName = countryLevel.data[0].countryLevel;
					});
				const country = this.getDataService.appCountries.find(
					(country) => country.idCountry === this.curso.idCountry
				);
				this.curso.averageKnowledge = this.curso.averageKnowledge.toFixed(1);
				this.courseTargets = response.data.coursesTarget
					.filter((element: any) => {
						return (
							element.target.published !== null && !element.target.exclusive
						);
					})
					.sort((a: any, b: any) => {
						if (a.target.tittle < b.target.tittle) {
							return -1;
						} else if (a.target.tittle > b.target.tittle) {
							return 1;
						} else {
							return 0;
						}
					});
				for (let index = 0; index < this.courseTargets.length; index++) {
					let element = this.courseTargets[index];
					element.averageKnowledge = element.averageKnowledge.toFixed(1);
				}
				this.cursoDatos = {
					title: this.curso.courseTittle,
					description: this.curso.description,
					subject: "",
					lang1: "",
					level: "",
					countryData: country.country_es,
					languageData: this.curso.language.language,
					image: this.curso.cpicture
						? this.imagePipe.transform(this.curso.cpicture, "cursos")
						: "",
					user: {
						name: this.curso.user.firstName,
						surname: this.curso.user.surname,
						image: this.curso.user.pictureUser,
					},
					averageKnowledge: this.curso.averageKnowledge,
					barColor: this.curso.barColor,
					progress: this.curso.progress,
				};
				this.cargando = false;
			});
	}

	obtenerDatosCursoGrupo(id) {
		this.cargando = true;

		this.coursesService
			.getGraphsByIdCourseForProfesor(id)
			.subscribe((response) => {
				this.curso = response.data.courses as CourseModel; // Course detail
				const country = this.getDataService.appCountries.find(
					(country) => country.idCountry === this.curso.idCountry
				);
				this.coursesService
					.getCountryLevelsById(this.curso.countryLevel)
					.subscribe((countryLevel) => {
						this.countryLevelName = countryLevel.data[0].countryLevel;
					});
				//Tengo que filtrarlo si es profesor o si es otro rol
				let isTeacher =
					this.loginService.getProfile() === "PROFESOR" ? true : false;
				if (isTeacher) {
					this.orderCoursesTargets(response.data.coursesTarget);
				} else {
					this.curso.averageKnowledge = this.curso.averageKnowledge.toFixed(1);
					this.courseTargets = response.data.coursesTarget
						.filter((element: any) => {
							return (
								element.target.published !== null && !element.target.exclusive
							);
						})
						.sort((a: any, b: any) => {
							if (a.target.tittle < b.target.tittle) {
								return -1;
							} else if (a.target.tittle > b.target.tittle) {
								return 1;
							} else {
								return 0;
							}
						});
					for (let index = 0; index < this.courseTargets.length; index++) {
						let element = this.courseTargets[index];
						element.averageKnowledge = element.averageKnowledge.toFixed(1);
					}
				}

				this.cursoDatos = {
					title: this.curso.courseTittle,
					description: this.curso.description,
					subject: "",
					lang1: "",
					level: "",
					countryData: country.country_es,
					languageData: this.curso.language.language,
					image: this.curso.cpicture
						? this.imagePipe.transform(this.curso.cpicture, "cursos")
						: "",
					user: {
						name: this.curso.user.firstName,
						surname: this.curso.user.surname,
						image: this.curso.user.pictureUser,
					},
					averageKnowledge: this.curso.averageKnowledge,
					barColor: this.curso.barColor,
					progress: this.curso.progress,
				};
				this.cargando = false;
			});
	}

	closeModal(sendData?: any) {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(sendData);
	}

	listaGrafosVisitados(grafo: any) {
    // Obtenemos el array de los últimos grafos visitados.
    let graphVisited_Student = JSON.parse(this.localStorage.getItem('graphVisited_Student')) || [];
    const newElement = {
        title: grafo.target.tittle,
        idGrafo: grafo.target.idTarget,
        idCurso: grafo.cours.idCourse
    };
    // Validar si el nuevo elemento ya está en el array
    const isDuplicate = graphVisited_Student.some(element =>
        element.idGrafo === newElement.idGrafo && element.idCurso === newElement.idCurso
    );
    // Si no es un duplicado, lo agregamos
    if (!isDuplicate) {
        // Si ya hay 10 elementos, eliminamos el ultimo.
        if (graphVisited_Student.length >= 10) { // con esto determinamos el tamaño del array
            graphVisited_Student.pop();
        }
        // Agregamos el nuevo elemento.
        graphVisited_Student.unshift(newElement);
        // Actualizamos el local storage.
        this.localStorage.setItem('graphVisited_Student', JSON.stringify(graphVisited_Student));
    }
	}

	navigateToGraph(idMapa) {
		this.router
			.navigate([`/course/${this.curso.idCourse}/graph/${idMapa}`], {
				state: { dontLoadMenu: true },
			})
			.then(() => {
				this.utils.loadMenu = false;
				// this.sigmaCanvasService.setTriggerInitSigma(true);
			});
	}

	// MAPAS
	verDetalleMapa(mapa: any) {
		if(this.cameFromCalendar == true){
			console.log("entro aca", mapa);
			this.closeModal(mapa);
		}else{
			//this.localStorage.removeItem('lastNodeElement');
		if(this.filtroInicial == "pensarCreando"){

			const idCursoOrig = this.curso.idCourse;
			//si se crea el curso procedemos a crear las copias (tareas)
			//mostramos el mensaje de que se han creado las tareas correctamente
			this.targetsService.createTaskPensarCreando(this.idGroup, mapa.target.idTarget, idCursoOrig, 0).subscribe();
			this.toaster.success(
				this.translateService.instant("PENSARCREANDO.CREATETASKOK")
			);
			this.closeModal('');
			return;
		} else if(this.filtroInicial == "pensarCreando(ver)"){
			//forzamos que se cambie el perfil a editor
			this.loginService.setProfile('AUTOR');
		} else if(this.filtroInicial == "practicar"){
			this.practicarIdeografo(mapa);
		}
		this.listaGrafosVisitados(mapa);
		if (!this.actividadesFlash && !this.fromSticker_grafo && this.filtroInicial != "practicar") {
			console.log("o accaaaa");
			//Creamos el log
			let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
			const bodyLog1 = {
				action: "ideografos",
				actionData: "Ha entrado en el Ideografo con id: " + mapa.target.idTarget,
				startDate: new Date(),
				endingDate: null,
				role: this.loginService.getProfile(),
				idGroup: storedGroupInfo ? storedGroupInfo.idGroup : 0,
			};
			this.masterService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
			const idMapa = mapa.target.idTarget;
			this.localStorage.setItem('lastTarget', idMapa);
			this.localStorage.removeItem('fromEditor')
			const idCourse = mapa.idCourse_Target;
			localStorage.setItem('dontLoadMenu', 'true') //Este provoca que no se muestre el menú al cambiar de perfil
			this.utils.loadMenu = false;
			this.graphServ.getGraphInfo(idMapa).subscribe((res) => {
				const viewType = res.data.viewType;
				this.graphServ.changedefaultTypeView(res.data.viewType);
				//ver grafo en modo lista
				if (viewType == 1 && !this.iconGraphActive) {
					this.getTreeData(idMapa);
				} else {
					//ver grafo con nodos y actividades
					if (viewType == 2) {
						this.toolsService.changeVisibility({ nodes: true, quizzes: true });
					//ver grafos solo con ideas
					} else if (viewType == 3) {
						this.toolsService.changeVisibility({ nodes: true, quizzes: false });
					//ver grafo solo con actividades
					} else if (viewType == 4){
						this.toolsService.changeVisibility({ nodes: false, quizzes: true });
					}
					this.buscarNodo = JSON.parse(localStorage.getItem("buscarActividad"));
					this.navigateToGraph(idMapa);

					if(this.loginService.esAutor()){
						let historialGrafos_editor = [];
						let localStoredHistory_editor = JSON.parse(
							localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
						);
						if (localStoredHistory_editor == null) {
							historialGrafos_editor.push(this.curso.idCourse);
						} else {
							if (localStoredHistory_editor.length > 15) {
								localStoredHistory_editor.shift(); // Elimina el primer elemento
							}
							localStoredHistory_editor.push(this.curso.idCourse);
						}
						historialGrafos_editor.push(this.curso.idCourse);
						this.localStorage.setItem(
							LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
							JSON.stringify(
								localStoredHistory_editor == null ? historialGrafos_editor : localStoredHistory_editor
							)
						);
					} else{
						let historialGrafos = [];
						let localStoredHistory = JSON.parse(
							localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY)
						);
						if (localStoredHistory == null) {
							historialGrafos.push(this.curso.idCourse);
						} else {
							if (localStoredHistory.length > 15) {
								localStoredHistory.shift(); // Elimina el primer elemento
							}
							localStoredHistory.push(this.curso.idCourse);
						}
						historialGrafos.push(this.curso.idCourse);
						this.localStorage.setItem(
							LOCALSTORAGESTRINGS.GRAPHSHISTORY,
							JSON.stringify(
								localStoredHistory == null ? historialGrafos : localStoredHistory
							)
						);
					}

					this.closeModal("Abrir mapa");
					this.modalService.dismissAll();
					this.close();
				}
			});
			this.targetsService.registercountopengraph().subscribe((res) => {});
		} else if(this.actividadesFlash && !this.fromSticker_grafo && this.filtroInicial != "practicar") {
			this.marcarGrafo(mapa);
		} else if(this.fromSticker_grafo && this.filtroInicial != "practicar"){
			//esto es para copiar la direccion del grafo y mandarlo a la config de stickers
			const toSticker = {
				msg: "url",
				idCurso: this.curso.idCourse,
				idGrafo: mapa.target.idTarget,
			};
			this.closeModal(toSticker);
			this.close();
		}
		}
	}

	practicarIdeografo(mapa: any) {
		const modalRef = this.modalService.open(ReportingComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.idGrupo = this.idGroup;
		modalRef.componentInstance.group = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		modalRef.componentInstance.claseInvertida = false;
		modalRef.componentInstance.practicar = true;
		modalRef.componentInstance.idCourse_practicar = this.curso.idCourse;
		modalRef.componentInstance.idTarget_practicar = mapa.target.idTarget;
		//Creamos el log
		let info = "Practicar Ideografo.";
		const bodyLog = {
			action: "practicar ideografo",
			actionData: "Ha entrado en el menú de " + info,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGroup,
		};
		this.masterService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
		this.close();
	}

	getImageBackground(image: string | null): string {
		let noImg = "../../assets/images/no-image.png";
		if (!image) return `url('${noImg}')`;
		return `url(${this.imagePipe.transform(image, "mapas")})`;
	}

	nuevoMapa() {
		if (!this.loginService.esAutor()) {
			return;
		}

		const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalRef.componentInstance.id = "nuevo";
		modalRef.componentInstance.curso = this.curso;

		modalRef.result.then(
			(result) => {
				if (result) {
					this.targetsService
						.getCourseTargetByIdCourse(this.curso.idCourse)
						.subscribe((result) => {
							this.courseTargets = result.data;
						});
				}
			},
			(reason) => {}
		);
	}

	openInformationCourse() {
		const modalRef = this.modalService.open(ModalInformationCourseComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.curso = this.curso;

		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}
				// Refresco el listado
			},
			(reason) => {}
		);
	}

	getTreeData(idMapa: number): void {
		this.graphView = "inlineList";
		const rol: string = this.loginService.esAutor()
			? Profiles.Editor.toLowerCase()
			: Profiles.Student.toLowerCase();

		if (this.filtroInicial == "pensarCreando(ver)") {
			this.coursesService
				.childrensListModePensarCreando(
					Number(this.id) ? Number(this.id) : this.curso.idCourse,
					0, 0, rol)
				.subscribe((result) => {
					idMapa = idMapa === undefined ? JSON.parse(localStorage.getItem("lastTarget")) : idMapa;
					if (idMapa === 0) {
						this.treeGraphs = result;
						this.iconGraphActive = false;
					} else {
						for (let index = 0; index < result.length; index++) {
							const element = result[index];
							if (element.idTarget === idMapa) {
								this.treeGraphs = [element];
								this.iconGraphActive = true;
								break;
							}
						}
					}
				});
		} else if (this.idGroup == 0) {
			this.coursesService
				.childrensListMode(
					Number(this.id) ? Number(this.id) : this.curso.idCourse, 0, 0, rol)
				.subscribe((result) => {
					idMapa = idMapa === undefined ? JSON.parse(localStorage.getItem("lastTarget")) : idMapa;
					if (idMapa === 0) {
						this.treeGraphs = result;
						this.iconGraphActive = false;
					} else {
						for (let index = 0; index < result.length; index++) {
							const element = result[index];
							if (element.idTarget === idMapa) {
								this.treeGraphs = [element];
								this.iconGraphActive = true;
								break;
							}
						}
					}
					if (this.verIdeasEnLista == true) {
						this.coursesService.childrensListMode(this.treeGraphs[0].idCourse, this.treeGraphs[0].idTarget, 0, rol).subscribe(resp => {
							if (resp != null && resp.length > 0) {
								this.treeGraphs[0].children = resp;
							}
						});
					}
				});
		} else if (this.idGroup != 0) {
			this.coursesService
				.childrensListModeGroup(
					Number(this.id) ? Number(this.id) : this.curso.idCourse, 0, 0, rol, this.idGroup)
				.subscribe((result) => {
					idMapa = idMapa === undefined ? JSON.parse(localStorage.getItem("lastTarget")) : idMapa;
					if (idMapa === 0) {
						this.treeGraphs = result;
						this.iconGraphActive = false;
					} else {
						for (let index = 0; index < result.length; index++) {
							const element = result[index];
							if (element.idTarget === idMapa) {
								this.treeGraphs = [element];
								this.iconGraphActive = true;
								break;
							}
						}
					}
					if (this.verIdeasEnLista == true) {
						this.coursesService.childrensListModeGroup(this.treeGraphs[0].idCourse, this.treeGraphs[0].idTarget, 0, rol, this.idGroup).subscribe(resp => {
							if (resp != null && resp.length > 0) {
								this.treeGraphs[0].children = resp;
							}
						});
					}
				});
		}
	}

	clickGraphIcon(){
		if(this.iconGraphActive){
			this.coursesService.getCourseById(parseInt(this.id)).subscribe((response) => {
					this.courseTargets = response.data.coursesTarget;
					for (let index = 0; index < this.courseTargets.length; index++) {
						const elemento = this.courseTargets[index];
						if (elemento.target.idTarget === this.treeGraphs[0].idTarget) {
							//this.iconGraphActive = false;
							this.verDetalleMapa(elemento);
							break;
						}
					}
			});
		}
	}

	//Función que recibe los valores del elemento seleccionado en el listado de los cursos
	clickElement(element: CourseListModel) {
		const isAuthor: boolean = this.loginService.esAutor();

		// De forma provisional, sólo las acciones se contemplan desde el rol de estudiante para poder visualizar los nodos y actividades
		if (!isAuthor) {
			this.node = this.quiz = this.idSelectedCourse = null;
			this.answered = false;

			switch (element.type) {
				case ELEMENTTYPE.COURSE:
					break;
				case ELEMENTTYPE.GRAPH:
					break;
				case ELEMENTTYPE.NODE:
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.nodeService
						.getNode(element.idNode, element.idCourse, element.idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((result) => {
							this.node = result.data[0];
						});
					break;
				case ELEMENTTYPE.QUIZ:
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.getQuiz(
						element.idQuiz,
						element.idCourse,
						element.idTarget,
						element.multi
					);
					break;
			}
		} else{
			switch (element.type) {
				case ELEMENTTYPE.COURSE:
					break;
				case ELEMENTTYPE.GRAPH:
					break;
				case ELEMENTTYPE.NODE:
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.idSelectedTarget = element.idTarget;
					this.selectedCourse = this.sigmaCanvasService.course;
					this.nodeService
						.getNode(element.idNode, element.idCourse, element.idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((result) => {
							this.node = result.data[0];
						});
					break;
				case ELEMENTTYPE.QUIZ:
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.idSelectedTarget = element.idTarget;
					this.getQuiz(
						element.idQuiz,
						element.idCourse,
						element.idTarget,
						element.multi
					);
					break;
			}
		}
	}

	close(): void {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close();
	}

	onAnswered(): void {
		this.answered = true;
	}

	private getQuiz(
		idQuiz: number,
		idCourse: number,
		idTarget: number,
		isMultiplexed: boolean
	) {
		this.idCurso = idCourse;
		this.idGraph = idTarget;
		if (isMultiplexed && this.loginService.esEstudiante()) {
			let quizM = this.quizService
				.getNextQuizMultiple(idCourse, idTarget, this.idGroup, idQuiz)
				.subscribe((res) => {
					const idQuizM = res.data.idOriginal;
					this.quizService
						.getQuizMultipleCanvasQuizMultiple(idQuizM, idCourse, idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							this.quiz = res.quiz;
							this.elements = res.elements;
						});
				});
		} else if (isMultiplexed && this.loginService.esProfesor()) {
			// cuando sea profe traemos todos los hijos del quiz padre y mostramos el hijo 1
			let quizM = this.quizService
				.getQuizMultiple(idQuiz, idCourse, idTarget)
				.subscribe((res) => {
					this.listQM = res.quizzes;
					const idQuizM = res.quizzes[0].quizMultiple.idQuizMultiple;
					this.quizService
						.getQuizMultipleCanvasQuizMultiple(idQuizM, idCourse, idTarget)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							this.quiz = res.quiz;
							this.elements = res.elements;
						});
				});
		} else if(!isMultiplexed) {
			this.quizService
				.getQuiz(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe((res: any) => {
					this.quiz = {
						...res.quiz,
						user: res.quiz.user,
						idOriginal: res.quiz.idOriginal,
						id: res.quiz.idQuiz,
						originalX: res.quiz.originalX,
						originalY: res.quiz.originalY,
						size: res.quiz.size,
						sizeQuiz: res.quiz.sizeQuiz,
						x: res.quiz.x,
						y: res.quiz.y,
					};
					this.elements = res.elements;
				});
		}
	}

	selectButton(index: number, idQuizM: number) {
		this.isLoading = true;
    this.selectedButtonIndex = index;
		this.quizService
						.getQuizMultipleCanvasQuizMultiple(idQuizM, this.idCurso, this.idGraph)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							this.quiz = res.quiz;
							this.elements = res.elements;
						});
  }

	showFiles(value) {
		let interval: number = 1;
		if (value) clearInterval(this.mouseInterval);

		this.mouseInterval = setInterval(() => {
			interval++;
			if (interval === 4) {
				this.isShowFiles = false;
				this.isShowPads = false;
				this.isShowLabels = false;
				clearInterval(this.mouseInterval);
			}
		}, DEFAULTCLOSEPADS / 3);

		this.isShowFiles = true; //Change variable value

		//QUENTAL
		if (this.viewQuiz) {
			if (this.utils.padsStatus.showPadsQuiz) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsQuiz) this.isShowLabels = true;
		} else {
			if (this.utils.padsStatus.showPadsNode) this.isShowPads = true;
			if (this.utils.labelsStatus.showLabelsNode) this.isShowLabels = true;
		}
	}

	onViewGif(result: boolean) {
		let url: string = "";

		//Si result es true, gif ok; si es false, gif KO
		const numRandon = this.getRandomInt(1, 40);
		const numRandonNeg = this.getRandomInt(1, 19);

		if (result) url = URLCELEBRATIONS + "/positive/image-" + numRandon + ".gif";
		else url = URLCELEBRATIONS + "/negative/image-" + numRandonNeg + ".gif";

		//Mostrar un modal con el gif y que se cierre en 3 segundos o lo haga el usuario
		const modalRef = this.modalService.open(ModalCelebrationComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W80 + " celebration-modal-window",
			backdropClass: "celebration-modal-backdrop",
		});

		modalRef.componentInstance.url = url;

		modalRef.result.then(
			(res) => {
				modalRef.close();
			},
			(err) => {}
		);

		setTimeout(() => {
			modalRef.close();
		}, 3000);
	}

	hidenGif(event) {}

	private getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}

	private orderCoursesTargets(data: CourseTargetModel[]) {
		this.courseTargets = data
			.filter((element: CourseTargetModel) => {
				return element.target.published !== null && !element.target.exclusive;
			})
			.sort((a: any, b: any) => {
				if (a.target.tittle < b.target.tittle) {
					return -1;
				} else if (a.target.tittle > b.target.tittle) {
					return 1;
				} else {
					return 0;
				}
			});

		this.exclusiveCourseTargets = data.filter(
			(element: CourseTargetModel, index) => {
				return element.target.exclusive;
			}
		);
	}

	showInfoCourse() {
		this.isOpenInfoCourse = !this.isOpenInfoCourse;
	}

	marcarGrafo(mapa: any) {
		// Toggle the 'isMarked' property of the mapa object
		mapa.isMarked = !mapa.isMarked;

		// Add logic to handle adding/removing the item to/from the list
		// For simplicity, let's assume there's a 'markedItems' array in your component
		if (mapa.isMarked) {
			// Add to the list
			this.markedItems.push(mapa);
		} else {
			// Remove from the list
			const index = this.markedItems.findIndex((item) => item === mapa);
			if (index !== -1) {
				this.markedItems.splice(index, 1);
			}
		}
	}

	iniciarFlash() {
		if (this.markedItems.length === 0) {
			return false;
		} else {
			let targets: number[] = [];
			for (let index = 0; index < this.markedItems.length; index++) {
				const element = this.markedItems[index].target.idTarget;
				targets.push(element);
			}
			this.quizService
				.getCantidadQuizzesForActividadesFlash(this.id, targets)
				.subscribe((res) => {
					this.maxBigQuizzes = res.data[0];
					this.maxAllQuizzes = res.data[1];
					const modalRef = this.modalService.open(ModalAlertFlashComponent, {
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W30,
					});
					modalRef.componentInstance.maxBigQuizzes = this.maxBigQuizzes;
					modalRef.componentInstance.maxAllQuizzes = this.maxAllQuizzes;
					modalRef.result.then((result: any) => {
						if (result.value.resp) {
							this.targetsList = targets;
							this.sizeDeck = result.value.sizeDeck;
							this.includeSmall = result.value.includeSmall;
							this.abrirListadoQuizes(
								this.id,
								this.targetsList,
								this.sizeDeck,
								this.includeSmall
							);
						}
					});
				});
		}
	}

	asignarProyecto(){
		this.groupService
		.agregarCurso(this.infoGrupo.idGroup, Number(this.id))
		.subscribe(
			(res) => {
				if(res){
					this.toaster.success(this.translateService.instant("MODALLISTCOURSES.ADDEDCOURSE"));
					this.inGroup = 1;
				}
			});
	}

	abrirListadoQuizes(idCourse, targets, sizeDeck, includeSmall) {
		this.quizService
			.getQuizzesForActividadesFlash(idCourse, targets, sizeDeck, includeSmall)
			.subscribe((res: any) => {
				if (res.data.length > 0) {
					this.abrirQuiz(
						idCourse,
						res.data[this.indice].idTarget,
						res.data[this.indice]
					);
					//this.closeModal('Abrir mapa');
					//this.close();
				} else {
					this.toaster.success(this.translateService.instant("CURSOS.ERROR1"));
				}
			});
	}

	abrirQuiz(idCurso, idMapa, quiz) {
		this.cargando = false;
		const modalRef = this.modalService.open(ActividadesFlashComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W100,
			backdrop: "static",
		});
		modalRef.componentInstance.idCurso = idCurso;
		modalRef.componentInstance.idMapa = idMapa;
		modalRef.componentInstance.quiz = quiz;
		modalRef.componentInstance.indice = this.indice;
		modalRef.componentInstance.sizeDeck = this.sizeDeck;

		modalRef.result
			.then((res) => {
				if (res) {
					this.indice += 1;
					if (this.indice < this.sizeDeck) {
						this.abrirListadoQuizes(
							this.id,
							this.targetsList,
							this.sizeDeck,
							this.includeSmall
						);
					} else {
						this.indice = 0;
						this.close();
						this.toaster.success(
							this.translateService.instant("ACTIVIDADESFLASH.FINISH")
						);
					}
				}
			})
			.catch((res) => {});
	}

	open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

	@HostListener("window:resize", ["$event"])
	onResize(event: any): void {
		if (window.innerWidth <= 1200) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}
}
