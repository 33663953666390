import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { RecordarQuizPlayMultipleComponent } from '../../quiz-open/quiz-play-multiple/quiz-play-multiple.component';

@Component({
    selector: 'app-modal-quizes-multiples',
    templateUrl: './modal-quizes-multiples.component.html',
    styleUrls: ['./modal-quizes-multiples.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalQuizesMultiplesComponent implements OnInit {

  public quizesMultiplesList = [];
  isLoading: boolean;
  idUser: number
  constructor(private quizService: QuizzesService, private modalService: NgbModal,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  // #61a1e9;
  openQuizMultipleHijo(quiz:any){

    this.quizService.getQuizInformMultiple(quiz.idQuizMultiple, this.idUser).pipe(finalize(() => this.isLoading = false)).subscribe((res:any) => {

      const modalRef = this.modalService.open(RecordarQuizPlayMultipleComponent, {scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
      modalRef.componentInstance.quiz = { ...res.quiz };
      modalRef.componentInstance.elements = res.elementsQuestion;
      modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer[0];

      modalRef.result.then(res => {
        if (res === true) {
          for (let index = 0; index < this.quizesMultiplesList.length; index++) {
            const element = this.quizesMultiplesList[index];
            if (element.idQuizMultiple === quiz.idQuizMultiple) {
              element.isChecked = 1;
              break;
            }
          }
        }

  })


    });
  }

  close(){
    this.activeModal.close(true);
   }


}
