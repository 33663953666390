import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NodeFilesFormat } from 'src/app/core/models/nodes/node-files-format.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-modal-youtube-video',
    templateUrl: './modal-youtube-video.component.html',
    styleUrls: ['./modal-youtube-video.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalYoutubeVideoComponent implements OnInit {
	public format: NodeFilesFormat;

	validationMessages = {
		url: [],
		start : [],
		end: []
	};

	form:UntypedFormGroup

	constructor(private fb: UntypedFormBuilder,
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private toaster: ToasterService
	) {
		this.form = this.fb.group({
			url: ['', Validators.required],
			start: [''],
			end: [''],
			nameFileVideo: [null],
			isDelete: [0],
			idNodeFile: [0]
		});
	}

	traducirOpciones():void{
		this.translateService.get('YOUTUBEVIDEO.LINKREQUIRED').subscribe((res: string) => {
				this.validationMessages.url.push({ type: 'required', message: res });
		});
		this.translateService.get('YOUTUBEVIDEO.PATTERN').subscribe((res: string) => {
			this.validationMessages.start.push({ type: 'pattern', message: res });
			this.validationMessages.end.push({ type: 'pattern', message: res });
		});
	}

	get urlControl() {
		if (this.form) {
				return (this.form.get('url') as UntypedFormControl);
		} else {
				return null;
		}
	}

	get startControl() {
		if (this.form) {
				return (this.form.get('start') as UntypedFormControl);
		} else {
				return null;
		}
	}

	get endControl() {
		if (this.form) {
				return (this.form.get('end') as UntypedFormControl);
		} else {
				return null;
		}
	}

	ngOnInit() {
		this.traducirOpciones()
		this.getFormatValues()
	}

	postVideo() {
		if (this.form.valid) {
			const cleanedUrl = this.cleanUrl(this.form.get('url')?.value);
			this.form.get('url')?.setValue(cleanedUrl);
			const regExp = /(?:youtu\.be\/|youtube\.com\/(?:.*v=|.*\/|.*embed\/|.*shorts\/|.*live\/))([\w-]{11})/;
			const match = cleanedUrl.match(regExp);
	
			if (match && match[1]) {
				this.form.get('idNodeFile')?.setValue(this.format.idNodeFile);
				this.closeModal(this.form.value);
			} else {
				this.toaster.error(this.translateService.instant('YOUTUBEVIDEO.ERRORID'));
			}
		}
	}
	
	cleanUrl(url: string): string {
		return url.replace(/app=desktop&/, '');
	}

	getFormatValues(){
		this.form.get('url').setValue(this.format.url)
		this.form.get('start').setValue(this.format.start)
		this.form.get('end').setValue(this.format.end)
	}

	delete(){
		if(this.form.valid){
			this.form.get('isDelete').setValue(1)
			this.form.get('idNodeFile').setValue(this.format.idNodeFile)
			this.closeModal(this.form.value)
		}
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

}
