import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import printJS from 'print-js';
import { LoginService } from 'src/app/core/services/login';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { ModalSelectRecipientComponent } from '../../modal-select-recipient/modal-select-recipient.component';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizFormComponent } from '../../../forms/quiz-form/quiz-form.component';
import { PatternsComponent } from '../../../patterns/patterns.component';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
    selector: 'app-head-quizzes',
    templateUrl: './head-quizzes.component.html',
    styleUrls: ['./head-quizzes.component.scss'],
    standalone: false
})

export class HeadQuizzesComponent implements OnInit {

  @ViewChild('quizForm') private quizForm: QuizFormComponent;

  @Input() nomQuiz: string;
  @Input() nomNode: string;
  @Input() nomGrafo: string;
  @Input() type: string;
  informacionQuiz: string;

  buttons = [
    {
      id: 1, icon: 'fab fa-instagram', color: '#517fa4'
    },
    {
      id: 2, icon: 'fab fa-whatsapp', color: '#4dc247'
    },
    {
      id: 3, icon: 'fab fa-twitter', color: '#55acee'
    },
    {
      id: 4, icon: 'fab fa-facebook-f', color: '#3b5998'
    },
    {
      id: 5, icon: 'salware', color: 'rgb(33, 76, 152)'
    },
    {
      id: 6, icon: 'fab fa-linkedin-in', color: '#007bb5'
    },
    {
      id: 7, icon: 'fab fa-youtube', color: '#bb0000'
    },
    {
      id: 8, icon: 'fab fa-pinterest', color: '#cb2027'
    }
  ];

  // A.G.
  private quiz;
  @Input() node;
  @Input() idCurso: number;
  @Input() idMapa: number;
  @Output() newQuiz = new EventEmitter<gNode>();
  template:any;
  private pendingChanges = false;
  fabTogglerState = 'inactive';

  setPendingChanges($event) {
    this.pendingChanges = $event;
  }

  receiveNewNodeData($event) {
    this.newQuiz.emit($event);
  }

  @ViewChild('quizzes', { static: true }) printEl: ElementRef;

  constructor(public activeModal: NgbActiveModal,
    public loginService: LoginService,
    private modalService: NgbModal,
    private nodeService: NodeService,
    private quizService: QuizzesService) {

    this.nodeService.currentNode.subscribe((n) => this.node = n);
    this.quizService.currentQuiz.subscribe( (q) => {this.quiz = q});
  }

  ngOnInit() {}

  public closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  public enviarRedesSociales(id) {
  }

  public imprimir() {
    printJS({
      printable: 'quiz', type: 'html', targetStyles: ['*']
    });
  }

  public save() {
    this.quizForm.save();
    this.activeModal.close();
  }

  public seleccionarDestinatario() {
    const modalRef = this.modalService.open(ModalSelectRecipientComponent,
      {
        scrollable: true,
        windowClass: MODAL_DIALOG_TYPES.W55
      })
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  public openSelectPattern() {
    this.activeModal.close();
    const modalRef = this.modalService.open(PatternsComponent,
      {
        scrollable: true,
        windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}`
      });
       modalRef.componentInstance.quiz = this.quiz;
       modalRef.result.then((result) => {
      }, (reason) => {
      });
  }
}
