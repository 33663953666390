import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login';
import { Utils } from 'src/app/core/utils/utils';
import { InfoEstudianteService } from './info-estudiante.service';
import { IInfoEstudiante } from './interfaces/info-estudiante-interface';
import { debounceTime, finalize, switchMap } from 'rxjs/operators';
import { ReportingService } from '../reporting/service/reporting.service';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ChallengeListModel } from 'src/app/core/models/groups/groups-model';
import { IReporting, TypeChlidrenReporting } from '../reporting/interfaces/reporting';
import { Subject } from 'rxjs';
import { ImagenPipe } from '../../pipes/imagen.pipe';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import moment from "moment-timezone";

interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	id: string;
	icon: string;
	level: number;
}

enum FILTER_MODE {
	LIST_MODE = "list_mode",
	CHALLENGE_MODE = "challenge_mode",
}

enum SELECTED_TYPE {
	GRAPH = "graph",
	CHALLENGE = "challenge",
}

const NOIMAGE = "../../../../../assets/images/icons/account_circle.svg"

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
	name: string;
	children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
	{
		name: 'Fruit',
		children: [{ name: 'Apple' }, { name: 'Banana' }, { name: 'Fruit loops' }],
	},
	{
		name: 'Vegetables',
		children: [
			{
				name: 'Green',
				children: [{ name: 'Broccoli' }, { name: 'Brussels sprouts' }],
			},
			{
				name: 'Orange',
				children: [{ name: 'Pumpkins' }, { name: 'Carrots' }],
			},
		],
	},
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
	expandable: boolean;
	name: string;
	level: number;
}

@Component({
    selector: 'app-info-estudiante',
    templateUrl: './info-estudiante.component.html',
    styleUrls: ['./info-estudiante.component.scss'],
    standalone: false
})

export class InfoEstudianteComponent implements OnInit {
	public formulario: UntypedFormGroup;
	public formularioCombo: UntypedFormGroup;

	public bodyTable: any;
	public headTable: any;
	public idCourse: any;
	public idGrafo: any;
	public idNode: any;
	COURSE = "COURSE"
	GRAFO = "GRAFO"
	public idGrupo: any;
	public group: any;
	titulo: string = "";
	loading: boolean = false;
	isLoading: boolean = true;
	selectedFilterMode: string = FILTER_MODE.LIST_MODE;
	listChallengeSelected: number[] = [];
	listGraphSelected: number[] = [];
	filterModeList = FILTER_MODE;
	selectedType = SELECTED_TYPE;
	challenges: ChallengeListModel[] = [];
	public idHijo: any;

	downloadIcon: boolean = false;
	selectedNode: any = null;
	reportGenerated: boolean = false;

	showReportButton = false;
	usersInscritos: any;
	$toogle = new Subject<void>();
	nameSession:string;

	reportTextButtonRefresh: string = this.translateService.instant("INFORMES.REFRESH");
	reportTextButton: string = this.translateService.instant("INFORMES.SHOWREPORT");

	quizes = [
		{ value: 1, name: "Todos los quizes" },
		{ value: 2, name: "No realizados" },
		{ value: 3, name: "Realizados" }
	]
	// this.quizes = [
	//   {value: 1, name: this.translateService.instant("INFORME.TODOSQUIZES") },
	//   {value: 2, name:  this.translateService.instant("INFORME.NOREALIZADOSQUIZES")},
	//   {value: 3, name: this.translateService.instant("INFORME.REALIZADOS")}
	// ]
	private _transformer = (node: IInfoEstudiante, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			id: node.id,
			icon: node.icon,
			level: level,
			type: node.type,
			idCourse: node.idCourse,
			idTarget: node.idTarget,
		};
	};

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		node => node.level,
		node => node.expandable,
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		node => node.level,
		node => node.expandable,
		node => node.children,
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	user: any;
	node: any;


	constructor(
		private formBuild: UntypedFormBuilder,
		private infoEstudianteService: InfoEstudianteService,
		public activeModal: NgbActiveModal,
		private utils: Utils, private loginService: LoginService,
		private translateService: TranslateService,
		private reportingService: ReportingService,
		private groupService: GruposService,
		private imagePipe: ImagenPipe,
		private toaster: ToasterService
	) {
		// this.dataSource.data = TREE_DATA;
		this.getMasterDataCourses();
		this.user = this.loginService.getUser()
		this.crearFormulario();

	}

	// getMasterDataCourses() {
	//   this.infoEstudianteService.getDataInfoCursosQuizesGrafro().subscribe((res: any) => {
	//     this.dataSource.data = res.data.courses;
	//   }, err => console.log(err))
	// }
	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

	ngOnInit() {
		this.isLoading = true;
		this.getMasterDataCourses();
		if(this.idGrupo !== 0){
			this.getListadoEstudiantes();
			this.titulo = " - " + this.group.title;
		}
		this.$toogle
		.pipe(
			debounceTime(1000),
			switchMap(() =>
				this.reportingService.getDataTableFromChallengesStudent(
					this.idGrupo,
					this.listChallengeSelected
				)
			)
		)
		.subscribe(
			(result) => {
				this.headTable = result.data.headTable;
				this.bodyTable = result.data.bodyTable.length > 0 ? result.data.bodyTable : undefined;
				this.reportingService.reportingSusbcribe.next({ ...result.data });
			},
			(err) =>
				this.toaster.error(
					this.translateService.instant("INFORMES.ERRORQUIZZES")
				)
		);

	}
	crearFormulario() {

		// default data and form groups
		this.formulario = this.formBuild.group({
			filtrado: [''],
		});
		this.formularioCombo = this.formBuild.group({
			tiposQuizes: [''],
		});
	}
	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	nodoClick(node: any) {
		this.node = node;
		this.infoEstudianteService.getDataTable(node.idCourse, node.idTarget, node.id, "1").subscribe((res: any) => {

			this.headTable = res.data.headTable;
			this.bodyTable = res.data.bodyTable.length > 0 ? res.data.bodyTable : undefined;
		}, err => console.log(err))
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.user.pictureUser)
	}

	keyFunc(press: any) {
		const data = this.formulario.value;
		this.infoEstudianteService.getDataInfoCursosQuizesGrafro(data.filtrado).subscribe((res: any) => {
			this.dataSource.data = res.data.courses;
		}, err => console.log(err))
	}

	showFontSize(event: Event) {
		let select = (event.target as HTMLSelectElement).value;
		// this.nodoClick(this.node);
		this.infoEstudianteService.getDataTable(this.node.idCourse, this.node.idTarget, this.node.id, select.toString()).subscribe((res: any) => {

			this.headTable = res.data.headTable;
			this.bodyTable = res.data.bodyTable.length > 0 ? res.data.bodyTable : undefined;
		}, err => console.log(err))

	}

	getMasterDataCourses() {
		this.selectedFilterMode = FILTER_MODE.LIST_MODE;
		this.resetData();
		if (this.idGrupo == undefined) return;
		this.reportingService
			.getReportingByGroupId(this.idGrupo, '2')
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(res: any) => {
					this.dataSource.data = res.data.courses;
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCOURSES")
					);
					this.dataSource.data = [];
				}
			);
	}

	private resetData() {
		this.reportingService.reportingSusbcribe.next({ data: [] });
		this.showReportButton = false;
		this.listChallengeSelected = this.listGraphSelected = [];
	}

	getChallenges() {
		this.selectedFilterMode = FILTER_MODE.CHALLENGE_MODE;
		this.resetData();
		this.groupService
			.getChallenges(this.idGrupo)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe(
				(result) => {
					result.data.forEach((element) => {
						moment.locale("es");
						let serverDate = moment.tz(element.nameSession, "UTC");
						let localDate = serverDate.local();
						element.nameSession = localDate.format("LLLL");
					});
					this.challenges = result.data as ChallengeListModel[];
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORCHALLENGES")
					);
					this.challenges = [];
				}
			);
	}

	generateGraphsReport() {
		if(this.loginService.esPadre){
			this.reportingService
				.getDataTableFromGraphsByUser(this.idGrupo, this.listGraphSelected, this.idHijo)
				.subscribe(
					(result) => {
						this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.downloadIcon = false;
						this.reportGenerated = true;
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
		} else {
			let rol = '2'; //estudiante
			this.reportingService
				.getDataTableFromGraphs(this.idGrupo, this.listGraphSelected, rol)
				.subscribe(
					(result) => {
						this.reportingService.reportingSusbcribe.next({ ...result.data });
						this.downloadIcon = false;
						this.reportGenerated = true;
					},
					(err) =>
						this.toaster.error(
							this.translateService.instant("INFORMES.ERRORQUIZZES")
						)
				);
		}
	}

	getListadoEstudiantes() {
		this.groupService.getListEstudiantes(this.group.idGroup).subscribe((res: any) => {
			this.usersInscritos = res.data;
		});
	}

	toggleChallenge(ev: boolean, data: any, type: string) {
		if (type === SELECTED_TYPE.CHALLENGE) {
			const obj = data as ChallengeListModel;
			if (ev) this.listChallengeSelected.push(obj.idSession);
			else
				this.listChallengeSelected = this.listChallengeSelected.filter(
					(element) => element !== obj.idSession
				);
			this.$toogle.next();
		} else {
			const obj = data as IReporting;
			const id = Number(obj.id);

			if (ev) this.listGraphSelected.push(id);
			else
				this.listGraphSelected = this.listGraphSelected.filter(
					(element) => element !== id
				);
			if (this.listGraphSelected.length) this.showReportButton = true;
			else {
				this.showReportButton = false;
				this.resetData();
			}
		}
	}

	resourse = (node: IReporting) => {
		if (node.type === TypeChlidrenReporting.NODE) {
			this.selectedNode = node;
			this.loading = true;
			this.idNode = node.id;
			this.idCourse = node.idCourse;
			this.idGrafo = node.idTarget;
			this.downloadIcon = true;
			this.infoEstudianteService.getDataTable(node.idCourse, node.idTarget, this.idNode, "1").subscribe((res: any) => {
				this.loading = false;
				this.headTable = res.data.headTable;
				this.bodyTable = res.data.bodyTable.length > 0 ? res.data.bodyTable : undefined;
			}, err => console.log(err))
		}
	};

	getUserAvatarInfo(imagen):string{
    let image:string = ''
    imagen !== null && imagen !== "" ? image = this.imagePipe.transform(imagen, 'userAvatar') : image = NOIMAGE
    return `url('${image}')`
  }

}
