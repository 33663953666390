import { Component, OnInit } from '@angular/core';
import { TemplateElementModel } from './../../../../core/models/quizzes/template.model';
import { TemplateModel } from 'src/app/core/models/quizzes/template.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';

const PIXELSY: number = 30
const PIXELSX: number = 30
const SIDESCROLL: number = 10

export interface DragEventModel {
    x: number,
    y: number
}

@Component({
    selector: 'app-pattern-draft',
    templateUrl: './pattern-draft.component.html',
    styleUrls: ['./pattern-draft.component.scss'],
    standalone: false
})
export class PatternDraftComponent implements OnInit {
    public mouse: { x: number, y: number }
    cargando = false;
    init = false;
    private quiz: any;
    private idTemplate: number;
    public template: TemplateModel;
    public elements: TemplateElementModel[] = [];

    private templateTypes = ['VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];

    private elementIcons = ['videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

	constructor(private activeModal: NgbActiveModal,
		private quizService: QuizzesService
	) {
		this.quizService.currentQuiz.subscribe((q) => { this.quiz = q })
	}

    ngOnInit() {
        this.idTemplate = this.template.idTemplate;
    }

    onMoveEnd($ev: DragEventModel, element: TemplateElementModel) {
        element.newxposition = $ev.x
        element.newyposition = $ev.y
    }

    onResizeStop($ev: IResizeEvent, element: TemplateElementModel) {
        element.xSize = $ev.size.width
        element.ySize = $ev.size.height
    }

    saveElements() {

        this.elements.forEach(e => {
            e.xPosition = e.newxposition ? (e.newxposition + e.xPosition) : e.xPosition
            e.yPosition = e.newyposition ? (e.newyposition + e.yPosition) : e.yPosition
            document.getElementById(e.idTemplateElement.toString()).style.transform = 'none'
        })
    }

    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }
}
