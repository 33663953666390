import { DEFAULTFONTFAMILY } from '../../../../../../core/models/masters/masters.enum';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/core/services/shared/utils.service';
import { Time } from '../quiz-play-body/quiz-play-body.component';
import { LoginService } from 'src/app/core/services/login';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LocalStorage } from 'src/app/core/utils';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalReportQuizComponent } from '../modal-report-quiz/modal-report-quiz.component';
import { NbDialogService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { ModalRecordQuizzesComponent } from './modal-record-quizzes/modal-record-quizzes.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: "app-quiz-play-body-info",
    templateUrl: "./quiz-play-body-info.component.html",
    styleUrls: ["./quiz-play-body-info.component.scss"],
    standalone: false
})
export class QuizPlayBodyInfoComponent implements OnInit {
	@Input() quiz: any;
	@Input() courseId: number;
	@Input() graphId: number;
	@Input() stopTime: boolean;
	@Input() timer$: Observable<any>;
	@Input() viewQuizDuration?: boolean = false;
	@Output() timeEnded = new EventEmitter();
	public infoForm: UntypedFormGroup;

	@ViewChild("canvas", { static: true }) private clockCanvas: ElementRef;
	@ViewChild("cd", { static: false }) private countdown: CountdownComponent;
	config: CountdownConfig;
	notify: string;
	showClock: boolean = false;
	challengeOn: boolean = false;
	modePractice = false;
	loading: boolean = false;
	constructor(
		private fb: UntypedFormBuilder,
		private utilsService: UtilsService,
		public loginService: LoginService,
		private localStorage: LocalStorage,
		private nebularDialogService: NbDialogService,
		private http: HttpClient,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private groupService: GruposService,
		private modalService: NgbModal,
	) {
		this.infoForm = this.fb.group({
			title: [{ value: "", disabled: true }],
			duration: [{ value: "", disabled: true }],
			instructions: [{ value: "", disabled: true }],
		});
	}

	ngOnInit() {
		this.modePractice = this.localStorage.getItem("modePractice") ? true : false;
		this.challengeOn = JSON.parse(localStorage.getItem('challengeOn'));
		this.infoForm.get("title").patchValue(this.quiz.quizTittle);
		this.infoForm.get("instructions").patchValue(this.quiz.quizInstructions);
		this.infoForm
			.get("duration")
			.patchValue(this.utilsService.minutesToTime(this.quiz.duration));
		this.timer$.subscribe((res) => {
			if (res) {
				if (this.quiz.duration > 0) {
					this.showClock = true;
				}
				this.config = {
					leftTime: this.quiz.duration,
				};
				//this.paintClock(res.time, res.max);
			}
		});

	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
		if(this.stopTime === true && this.countdown){
			this.countdown.stop();
		}
	}

	private get2dCanvas(canvas) {
		if (canvas) {
			var lienzo = canvas.getContext("2d");
			return lienzo;
		} else {
			return false;
		}
	}

	handleCountDownEvent(e: CountdownEvent) {
		if (this.quiz.duration == 0) {
			return;
		} else {
			if (e.action === "done") {
				this.timeEnded.emit(e);
			}
		}
	}

	private paintClock(currentTime: Time, maxSeconds: number): void {
		var lienzo = this.get2dCanvas(this.clockCanvas.nativeElement);

		if (lienzo) {
			var segundo = currentTime.minutes * 60 + currentTime.seconds;
			var radio = 25;
			// Defino límites en pí­xeles
			var minx = 10;
			var maxx = 45;
			var miny = 10;
			var maxy = 45;
			// Centro del recuadro
			var cx = minx + (maxx - minx) / 2;
			var cy = miny + (maxy - miny) / 2;
			// Borrar lienzo
			lienzo.clearRect(0, 0, 300, 300);
			lienzo.strokeStyle = "rgb(124,161,254)";
			lienzo.lineWidth = 3;
			// Dibujar el círculo del reloj
			lienzo.beginPath();
			lienzo.arc(cx, cy, radio, 0, Math.PI * 2, false);
			lienzo.stroke();
			// Dibujar los segundos restantes
			lienzo.beginPath();
			lienzo.fillStyle = "rgb(0,0,0)";
			lienzo.moveTo(cx, cy); // posiciono el lápiz en el centro del recuadro
			var angulo = 2 * Math.PI * (segundo / maxSeconds); // calculo el ángulo del segundero
			lienzo.arc(cx, cy, radio, -Math.PI / 2, -Math.PI / 2 - angulo, false);
			lienzo.lineTo(cx, cy);
			lienzo.fill();
			//Mostrar la hora
			lienzo.beginPath();
			lienzo.font = "12pt " + DEFAULTFONTFAMILY;
			//lienzo.fillStyle = "rgb(255,255,255)";
			lienzo.fillStyle = "rgb(117, 108, 108)";
			var minute =
				currentTime.minutes <= 9
					? "0" + currentTime.minutes
					: currentTime.minutes;
			var second =
				currentTime.seconds <= 9
					? "0" + currentTime.seconds
					: currentTime.seconds;
			lienzo.fillText(minute + ":" + second, cx / 2 - 10, cx + 5);
			//color de fonde de lienzo
			//lienzo.beginPath();
			//lienzo.fillStyle = "rgb(255,255,255)";
		}
	}

	openModalReport() {
		const modalRef = this.nebularDialogService.open(ModalReportQuizComponent, {
			dialogClass: MODAL_DIALOG_TYPES.W60,
		});
		modalRef.onClose.subscribe((result) => {
			if (result) {
				this.loading = true;
				let data = {
					comentary: result.comentary,
					shareMail: result.shareMail,
					idQuiz: this.quiz.idQuiz,
					idTarget: this.graphId,
					idCourse: this.courseId,
				}
				this.sendFormData(data).subscribe( res => {
					if(res){
						this.toaster.success(this.translateService.instant('REPORTBUTTON.SENDINGOK'));
					} else {
						this.toaster.error(this.translateService.instant('REPORTBUTTON.SENDINGERROR'));
					}			
					this.loading = false;
				})
			}
		});
	}
	sendFormData(data):Observable<any> {
		return this.http.post<any>("user/reportQuiz",data)
	}

	openModalRecord() {
		this.groupService.quizsimpleinformestudent(this.quiz.idQuiz).subscribe(res => {
			console.log(res.data);
			const modalRef = this.modalService.open(ModalRecordQuizzesComponent,
				{ scrollable: false, windowClass: `${MODAL_DIALOG_TYPES.W90} h-100` });

			modalRef.componentInstance.tree = res.data;

			modalRef.result.then(() => { }, () => { });
		})
	}
}
