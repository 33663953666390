import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import printJS from 'print-js';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { QuizEditInfoComponent } from '../../../quiz-edit/components/quiz-edit-info/quiz-edit-info.component';
import { TimerService } from 'src/app/core/services/timer/timer.service';
import { QuizHistorialModel } from 'src/app/core/models/quizzes/quiz-historial.model';
import { LocalStorage } from 'src/app/core/utils';
import { QuizzesService } from 'src/app/core/services/quizzes';

@Component({
    selector: 'app-quiz-play-header',
    templateUrl: './quiz-play-header.component.html',
    styleUrls: ['./quiz-play-header.component.scss'],
    standalone: false
})
export class QuizPlayHeaderComponent implements OnInit {
    @Input() quiz: QuizModel;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() modeAuto: boolean = false;
    @Input() hideBar: boolean = false
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor(
        private modalService: NgbModal,
        private timerService: TimerService,
        private localStorage: LocalStorage,
        private quizService: QuizzesService,
    ) {}

    ngOnInit() {
        this.timerService.startTimer();//con esto contabilizamos el tiempo de estudio
    }

    public get quizTypes() {
        return QuizTypes;
    }

    onCloseModal(sendData?: any) {
        this.saveStudyTime();
        this.close.next(true);
    }

    showInfo() {
        const modalRef = this.modalService.open(QuizEditInfoComponent,
            {
                scrollable: false,
                windowClass: MODAL_DIALOG_TYPES.W95
            }
        );
        modalRef.componentInstance.quiz = this.quiz;
        modalRef.componentInstance.courseId = this.idCurso;
        modalRef.componentInstance.graphId = this.idMapa;
    }

    saveStudyTime(){
        const tiempoTranscurrido = this.timerService.stopTimer();
        let quizHistorial: QuizHistorialModel = new QuizHistorialModel();
        let graphVisited_Student = JSON.parse(this.localStorage.getItem('graphVisited_Student'))
        const soloDigitos = String(this.quiz.id).replace(/\D/g, "");
        const idQuiz = parseInt(soloDigitos, 10);
        if(tiempoTranscurrido > 3000){ //si el tiempo fue menor a 3seg no se guardará registro
            quizHistorial.idCourse = graphVisited_Student ? graphVisited_Student[0].idCurso : this.idCurso;
            quizHistorial.idTarget = graphVisited_Student ? graphVisited_Student[0].idGrafo : this.idMapa;
            quizHistorial.idQuiz = idQuiz;
            quizHistorial.quizType = parseInt(this.quiz.quizType);
            quizHistorial.idUser = 0;
            quizHistorial.fecha = null;
            quizHistorial.tiempoTotalQuiz = tiempoTranscurrido < 3600000 ? tiempoTranscurrido : 3600000; //el tiempo mayor que puede tener es de 1h

            this.quizService.createQuizHistorial(quizHistorial).subscribe();
        }
    }
}
