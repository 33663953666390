import { MatomoAnalyticsUtils } from "./../../../../core/utils/matomo-analytics.utils";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { UsersService } from "./../../../../core/services/users/users.service";
import { AuthorModel } from "./../../../../core/models/masters/author-response.model";
import { MateriaModel } from "src/app/core/models/masters";
import { IdiomaModel } from "./../../../../core/models/masters/idioma.model";
import { PaisModel } from "./../../../../core/models/masters/pais.model";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

// Models
import { FilterCourseModel, CourseModel } from "src/app/core/models/courses";

// Services
import { TranslateService } from "@ngx-translate/core";
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { AlertService } from "src/app/core/services/shared";

// Components
import { ModalEditarCursoComponent } from "src/app/shared/components/cursos/modal-editar-curso";

import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
	DynamicDatabase,
	DynamicDataSource,
	DynamicFlatNode,
} from "src/app/core/utils/treeView.utils";
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { SortByPipe } from "src/app/shared/pipes/sort-by.pipe";
import { Router } from "@angular/router";
import { User } from "src/app/core/models/users/user.models";
import { APP_LANGUAGES, LocalStorage } from "src/app/core/utils";
import { ModalVerCursoComponent } from "../modal-ver-curso/modal-ver-curso.component";
import { finalize } from "rxjs/operators";

import { ModalCursoEditDetailComponent } from "../modal-curso-edit-detail/modal-curso-edit-detail.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { RecordarQuizPlayMultipleComponent } from "../../quiz-open/quiz-play-multiple/quiz-play-multiple.component";
import { RecordarQuizPlayComponent } from "../../quiz-open/quiz-play/quiz-play.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
const USERTYPE = {
	STUDENT: "estudiante",
	EDITOR: "editor",
	TEACHER: "profesor",
};
@Component({
    selector: "app-modal-cursos",
    templateUrl: "./modal-cursos.component.html",
    styleUrls: ["./modal-cursos.component.scss"],
    providers: [SliceStringPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalCursosComponent implements OnInit {
	buscarNodo = false;
	public modoTraerNodo = false;
	public modoTraerActividad = false;

	authorControl: UntypedFormControl = new UntypedFormControl("");
	isMyCourses: UntypedFormControl = new UntypedFormControl(true);
	filteredOptions: Observable<AuthorModel[]>;
	IDIOMAS = APP_LANGUAGES;
	cursos: CourseModel[] = [];
	filtro: FilterCourseModel = new FilterCourseModel();

	// Almacena la opcion que ha elegido el estudiante (Estudiar - Enseñar - Competir)
	opcion: string;

	materias: MateriaModel[];
	idiomas: IdiomaModel[];
	paises: PaisModel[];
	isGuest: boolean = false;

	/////////////////////
	public viewList: boolean = false; //cambio de vista a modo Arbol o Mosaico
	public treeControl: FlatTreeControl<DynamicFlatNode>;
	public dataSource: DynamicDataSource;
	getLevel = (node: DynamicFlatNode) => node.level;
	isExpandable = (node: DynamicFlatNode) => node.expandable;
	hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
	LIST_COURSE: string[] = [];
	SEARCH_STRING: string = "|-|";
	asc = new SortByPipe();
	idCourse: string;
	idGrafo: string;
	//////////////////

	cargando = false;
	tutorialSW: any;
	user: User;
	language: any;
	rol: any;
	recordar: any;
	ModoAuto: any;
	quizService: any;
	isLoading: boolean;

	constructor(
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public loginService: LoginService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public database: DynamicDatabase,
		public router: Router,
		private localStorage: LocalStorage,
		public userService: UsersService,
		private ds: GetDataService,
		private ma: MatomoAnalyticsUtils,
		private toaster: ToasterService
	) {}

	displayFn(author?: AuthorModel): string {
		return author ? author.name : "";
	}

	private _filter(name: string): void {
		if (name) {
			this.isMyCourses.setValue(false);
			this.isMyCourses.disable();
		} else {
			this.isMyCourses.enable();
		}
	}

	ngOnInit() {
		this.user = this.loginService.getUser();
		this.language = this.localStorage.getItem("language");
		this.materias = this.ds.appSubjects;
		this.idiomas = this.ds.appLanguages;
		this.paises = this.ds.appCountries;
		this.cargando = true;
		this.isGuest = this.loginService.isGuestUser();
		switch (this.loginService.getProfile()) {
			case "ESTUDIANTE":
				this.rol = USERTYPE.STUDENT;
				break;
			case "PROFESOR":
				this.rol = USERTYPE.TEACHER;
				break;
			case "AUTOR":
				this.rol = USERTYPE.EDITOR;
				break;
			default:
				break;
		}
		this.isMyCourses.patchValue(this.loginService.esAutor());
		if (this.loginService.esAutor()) this.authorControl.disable();

		// this.filtrarCursos();
		this.filtrarCursosNode();
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	checkValue() {
		if (this.isMyCourses.value) {
			this.authorControl.setValue("");
			this.authorControl.disable();
		} else this.authorControl.enable();
	}

	filtrarCursos() {
		this.cargando = true;
		const USER = this.loginService.getUser();
		const FILTERUSER = this.authorControl.value;
		const FILTERMYCOURSES = this.isMyCourses.value;

		if (FILTERMYCOURSES) {
			this.filtro.user.idUser = USER.idUser;
			this.filtro.user.firstName = null;
			this.filtro.user.surname = null;
		} else if (FILTERUSER) {
			this.filtro.user.idUser = FILTERUSER.idUser;
			this.filtro.user.firstName = FILTERUSER.name.split(", ")[1];
			this.filtro.user.surname = FILTERUSER.name.split(", ")[0];
		} else this.filtro.user.idUser = 0;

		this.coursesService.getAllCourses(this.filtro).subscribe(
			(data) => {
				this.cursos = data;
				if (this.viewList) {
					this.changeView();
				}
				this.cargando = false;
			},
			(error) => {
				// tslint:disable-next-line: max-line-length
				this.alertService.error(
					this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
					AlertService.AlertServiceContextValues.ModalCursoLenguaje
				);
				this.cargando = false;
			}
		);
	}

	filtrarCursosNode() {
		this.cargando = true;
		const data = { filter: this.filtro.courseTittle, myCourses: false };
		this.coursesService
			.filtradoCursosNew(data.filter, data.myCourses, this.rol)
			.subscribe(
				(data) => {
					this.cursos = data;
					if (this.viewList) {
						this.changeView();
					}
					this.cargando = false;
				},
				(error) => {
					// tslint:disable-next-line: max-line-length
					this.alertService.error(
						this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
						AlertService.AlertServiceContextValues.ModalCursoLenguaje
					);
					this.cargando = false;
				}
			);
	}
	// verDetalleCurso(idCurso: number) {
	// 	this.opcion === "editar"
	// 		? this.openCursoEditor(idCurso)
	// 		: this.openCursoEstudiante(idCurso);
	// }
	verDetalleCurso(idCurso) {
		if (this.recordar) this.abrirListadoQuizes(idCurso);
		else
			this.opcion === "editar"
				? this.openCursoEditor(idCurso)
				: this.openCursoEstudiante(idCurso);
	}
	abrirListadoQuizes(idCurso) {
		this.coursesService.recordarQuizesListado(idCurso).subscribe((res: any) => {
			if (res.data.length > 0) {
				if (res.data.length < 20) {
					this.toaster.success(
						this.translateService.instant("CURSOS.ERROR1")
					);
				} else {
					this.ModoAuto = res.data;
					let indice = 0;
					this.openModalModeAuto(indice);
				}
			} else
				this.toaster.success(
					this.translateService.instant("CURSOS.ERROR1")
				);
		});
	}
	openModalModeAuto(indice) {
		if (indice < this.ModoAuto.length - 1) {
			if (
				this.ModoAuto[indice].idQuiz !== this.ModoAuto[indice].idQuizOriginal
			) {
				this.quizService
					.getQuizMultipleCanvasQuizMultiple(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(
							RecordarQuizPlayMultipleComponent,
							{
								scrollable: true,
								windowClass: MODAL_DIALOG_TYPES.W100,
								backdrop: "static",
							}
						);
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			} else {
				this.quizService
					.getQuiz(
						this.ModoAuto[indice].idQuiz,
						this.ModoAuto[indice].idCourse,
						this.ModoAuto[indice].idTarget
					)
					.pipe(finalize(() => (this.isLoading = false)))
					.subscribe((res: any) => {
						const modalRef = this.modalService.open(RecordarQuizPlayComponent, {
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W100,
							backdrop: "static",
						});
						modalRef.componentInstance.quiz = {
							...res.quiz,
							user: res.quiz.user,
							idOriginal: res.quiz.idOriginal,
							id: res.quiz.idQuiz,
							originalX: res.quiz.originalX,
							originalY: res.quiz.originalY,
							size: res.quiz.size,
							sizeQuiz: res.quiz.sizeQuiz,
							x: res.quiz.x,
							y: res.quiz.y,
						};
						modalRef.componentInstance.elements = res.elements;
						modalRef.componentInstance.listQuiz = this.ModoAuto;

						modalRef.result.then(
							(res) => {
								if (res < this.ModoAuto.length - 1) {
									this.openModalModeAuto(res);
								} else {
									this.toaster.success(
										this.translateService.instant("CURSOS.OKALLQUIZZES")
									);
								}
							},
							(err) => {
								this.toaster.success(
									this.translateService.instant("CURSOS.OKALLQUIZZES")
								);
							}
						);
					});
			}
		} else
			this.toaster.success(
				this.translateService.instant("CURSOS.OKALLQUIZZES")
			);
	}

	openCursoEstudiante(idCurso: number) {
		this.ma.event("click", "view_item", "Curso");
		const modalRef = this.modalService.open(ModalVerCursoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W100,
		});

		modalRef.componentInstance.id = idCurso;
		modalRef.componentInstance.modoTraerNodo = this.modoTraerNodo;
		modalRef.componentInstance.modoTraerActividad = this.modoTraerActividad;

		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}

				// Refresco el listado
				this.filtrarCursos();
			},
			(reason) => {}
		);
	}
	openCursoEditor(idCurso: number) {
		const modalRef = this.modalService.open(ModalCursoEditDetailComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		modalRef.componentInstance.id = idCurso;
		modalRef.result.then(
			(result) => {
				switch (result) {
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}

				// Refresco el listado
				this.filtrarCursos();
			},
			(reason) => {}
		);
	}

	nuevoCurso() {
		const modalRef = this.modalService.open(ModalEditarCursoComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}`,
		});
		modalRef.componentInstance.clickSidebar = true;
		modalRef.componentInstance.id = "nuevo";
		modalRef.result.then(
			(result) => {
				this.filtrarCursos();
			},
			(reason) => {}
		);
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	////////////////////////////////////////////////////
	////////////

	changeView() {
		this.viewList = true;
		this.treeControl = new FlatTreeControl<DynamicFlatNode>(
			this.getLevel,
			this.isExpandable
		);
		this.database = new DynamicDatabase();
		this.cursos = this.asc.transform(this.cursos, "asc", "courseTittle");

		this.cursos.forEach((element) => {
			//Se debe llenar el array de string con sus hijos
			this.database.rootLevelNodes.push(element.courseTittle);
			this.database.dataMap.set(
				element.courseTittle,
				(this.LIST_COURSE = this.obtenerDatosCurso(element.idCourse)) //Metodo para completar el array de los nodos padres.
			);
		});
		this.dataSource = new DynamicDataSource(this.treeControl, this.database);
		this.dataSource.data = this.database.initialData();
	}

	obtenerDatosCurso(id) {
		this.cargando = true;
		const TREE = [];
		this.coursesService.getCourseById(id).subscribe((response: any) => {
			// Ya que solo se puede poner arrays de strings
			//En los elementos de los hijos concatenamos el id del curso y el id del grafo,
			//Necesitaremos para movernos al grafo seleccionado.
			if (response.data.coursesTarget.length >= 1) {
				for (
					let index = 0;
					index < response.data.coursesTarget.length;
					index++
				) {
					TREE.push(
						response.data.coursesTarget[index].target.tittle +
							this.SEARCH_STRING +
							id +
							this.SEARCH_STRING +
							response.data.coursesTarget[index].target.idTarget
					);
				}
			}
		});
		this.cargando = false;
		return TREE;
	}

	//Se obtiene el elemento @itemGrafo que contiene concatenado id del curso y del grafo
	verGrafo(itemGrafo: string) {
		let slice = itemGrafo.indexOf(this.SEARCH_STRING);
		if (slice >= 0) {
			this.idCourse = itemGrafo.substring(slice + 3, itemGrafo.length);
			itemGrafo = this.idCourse;
			this.idCourse = this.idCourse.substring(
				0,
				this.idCourse.indexOf(this.SEARCH_STRING)
			);
			slice = -1;
			slice = itemGrafo.indexOf(this.SEARCH_STRING);
			if (slice >= 0) {
				this.idGrafo = itemGrafo.substring(slice + 3, itemGrafo.length);
				this.router.navigate([
					`/course/${this.idCourse}/graph/${this.idGrafo}`,
				]);
				this.closeModal("Abrir mapa");
			}
		}
	}
}
