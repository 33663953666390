<form [formGroup]="optionForm" *ngIf="QuizTypes.TEXT && quiz.idMultiplexQuiz === 0">
	<ng-container formArrayName="options">
		<div class="d-flex flex-column justify-content-center gap-5">
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZ DE INTRODUCIR DATOS -->
				<div class="d-flex justify-content-center" [formGroupName]="1" *ngSwitchCase="QuizTypes.TEXT">
					<form class="w-100" [formGroup]="optionFormTexto">
						<textarea (blur)="readText()" autocomplete="off" nbInput fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}" formControlName="answer" [disabled]="answered"
							maxlength="250">
						</textarea>
					</form>
				</div>

				<div class="d-flex justify-content-center" [formGroupName]="1" *ngSwitchCase="QuizTypes.IA">
					<form class="w-100" [formGroup]="optionFormTexto">
						<textarea (blur)="readText()" autocomplete="off" nbInput fullWidth
							placeholder="{{ 'QUIZZES.RESPUESTA' | translate }} {{ 1 }}" formControlName="answer" [disabled]="answered"
							maxlength="250">
						</textarea>
					</form>
				</div>

				<div class="d-flex gap-5 flex-column flex-md-row justify-content-center" *ngSwitchCase="QuizTypes.TEXT">
					<ng-container *ngFor="let option of optionsArray.controls; let i = index">
						<!-- Inputs Ocultos -->
						<input *ngIf="option.value.type === O_AUDIOS" type="file" id="{{ i }}" accept="audio/mp3"
							(change)="onFileSelected(i, $event, O_AUDIOS)" hidden />
						<input *ngIf="option.value.type === O_VIDEOS" type="file" id="{{ i }}" accept="video/mp4"
							(change)="onFileSelected(i, $event, O_VIDEOS)" hidden />
						<input *ngIf="option.value.type === O_PICTURES" type="file" id="{{ i }}" accept="image/*"
							(change)="onFileSelected(i, $event, O_PICTURES)" hidden />
						<input *ngIf="option.value.type === O_PDFS" type="file" id="{{ i }}"
							accept="application/pdf,application/vnd.ms-excel" (change)="onFileSelected(i, $event, O_PDFS)" hidden />

						<ng-container *ngIf="arrayFileView[i].view; else elseTemplateCargar">
							<div class="d-flex justify-content-center align-items-center" style="flex: 1;">
								<div class="d-flex justify-content-center align-items-center flex-column position-relative w-100">
									<!-- preview del archivo cargado  -->
									<div class="d-flex justify-content-center align-items-center container-QuizText" [ngClass]="{disabled: hideBoton}">
										<!-- Fotografica -->
										<img *ngIf="option.value.type === O_PICTURES" #imgElement id="{{ i }}" [src]="arrayFileView[i].url | safe"
											class="file-container-view" />
										<!-- Audio -->
										<audio *ngIf="arrayFileView[i].view && option.value.type === O_AUDIOS" class="file-container-view"
											id="{{ i }}" [src]="arrayFileView[i].url | safe" controls>
										</audio>
										<!-- Video -->
										<video *ngIf="arrayFileView[i].view && option.value.type === O_VIDEOS" class="file-container-view"
											[src]="arrayFileView[i].url | safe" controls>
										</video>
										<!-- Archivo PDF -->
										<iframe *ngIf="arrayFileView[i].view && option.value.type === O_PDFS" #pdfElement
											class="file-container-view" [src]="arrayFileView[i].url | safe" allowfullscreen>
										</iframe>
										<!-- Boton para eliminar el archivo pre cargado -->
										<div class="position-btn-delete">
											<button *ngIf="option.get('answer').value && !answered" nbButton size="medium" (click)="deleteUploadedElement(i)"
												class="btn-red">
												<nb-icon icon="trash-outline"></nb-icon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #elseTemplateCargar>
							<div class="d-flex justify-content-center gap-4 flex-wrap" style="flex: 1;"
								*ngIf="option.value.type === O_VIDEOS || option.value.type === O_PICTURES || option.value.type === O_PDFS || option.value.type === O_AUDIOS">

								<ng-container *ngIf="option.value.type === O_VIDEOS">
									<div class="d-flex w-100 align-items-center justify-content-center gap-4"
										[ngClass]="
										{
											disabled: hideResponse || hideBoton
										}">
										<!-- SUBIR VIDEO -->
										<div class="opt-quiz-icon" nbTooltip="Cargar video" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.VIDEO" | translate }}</span>
												<nb-icon icon="video-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- GRABAR VIDEO -->
										<div *ngIf="!isIOS()" class="opt-quiz-icon" nbTooltip="Grabar video" (click)="!answered ? grabarVideo(i, option.value) : ''">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.GRABAR" | translate }}</span>
												<nb-icon icon="camera-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_PICTURES">
									<div class="d-flex w-100 align-items-center justify-content-center gap-4"
										[ngClass]="
										{
											disabled: hideResponse || hideBoton
										}">
										<!-- CARGAR IMAGEN -->
										<div class="opt-quiz-icon" nbTooltip="Cargar imagen" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.IMAGEN" | translate }}</span>
												<nb-icon icon="image-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- CAPTURAR IMAGEN -->
										<div *ngIf="!isIOS()" class="opt-quiz-icon" nbTooltip="Sacar foto" (click)="!answered ? tomarFoto(i, option.value) : ''">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.FOTO" | translate }}</span>
												<nb-icon icon="camera-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_PDFS">
									<div class="d-flex align-items-center justify-content-center"
										[ngClass]="
										{
											disabled: hideResponse || hideBoton
										}">
										<!-- CARGAR PDF -->
										<div class="opt-quiz-icon" nbTooltip="Cargar PDF" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.DOCUMENTO" | translate }}</span>
												<nb-icon icon="file-add-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="option.value.type === O_AUDIOS">
									<div *ngIf="!viewAudio" class="d-flex w-100 align-items-center justify-content-center gap-4"
										[ngClass]="{ disabled: hideResponse }">
										<!-- CARGAR AUDIO -->
										<div class="opt-quiz-icon" nbTooltip="Cargar audio" (click)="openFile(i, option.value)">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.AUDIO" | translate }}</span>
												<nb-icon icon="camera-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
										<!-- GRABAR AUDIO -->
										<div class="opt-quiz-icon" nbTooltip="Grabar audio" (click)="!answered ? _grabarAudio(i, option.value) : ''">
											<div class="d-flex flex-column align-items-center gap-1">
												<span>{{ "QUIZZES.GRABAR" | translate }}</span>
												<nb-icon icon="mic-outline" id="mat-icon-{{ i }}">
												</nb-icon>
											</div>
										</div>
									</div>
									<!-- Validacion cuando hay problemas con el microfono -->
									<ng-template #dialog let-data let-ref="dialogRef">
										<nb-card class="position-relative no-edge-card mb-0">
											<nb-card-header class="border-bottom-0">
												<div class="pr-5">
													<h5>{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
												</div>
											</nb-card-header>
											<nb-card-body>
												<div class="container pb-4 pr-4 pl-4 pt-0">
													<div class="alert alert-warning mb-0">
														{{ mensajeError }}
													</div>
												</div>
											</nb-card-body>
											<div class="closeModal">
												<nb-icon class="m-1" icon="close-circle" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
													nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
												</nb-icon>
											</div>
										</nb-card>
									</ng-template>
									<!-- Flujo normal para grabar el audio -->
									<div *ngIf="viewAudio" class="d-flex justify-content-center flex-column gap-2">
										<audio-component [viewElement]="viewAudio" (emitAudio)="emitAudioEvent($event, i)"
											(close)="closeElement($event, i)" [answered]="answered" [fromQuiz]="true">
										</audio-component>
									</div>
								</ng-container>
							</div>
						</ng-template>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>

<form class="quiz-options d-flex flex-column gap-3" [formGroup]="optionForm">
	<ng-container formArrayName="options">
		<div class="row justify-content-center" *ngFor="let option of optionsArray.controls; let i = index">
			<ng-container [ngSwitch]="quizType">
				<!-- QUIZZES DE SELECCIÓN ÚNICA -->
				<div class="col-sm-12 col-md-10 flex align-items-start mb-4 justify-content-center" [formGroupName]="i"
					*ngSwitchCase="QuizTypes.SINGLE">
					<div class="count mr-3 align-self-center" *ngIf="answered && !hideCounter && !loadingResponses">
						{{ option.get("totalResponses").value }}
					</div>
					<nb-radio-group class="w-100 option-bordered" [formControl]="correctOptionControl" [ngClass]="{
							'bg-correct': answered == true && optionsArrayIsCorrect[i].value,
							'bg-incorrect':
								answered == true && !optionsArrayIsCorrect[i].value,
							'radio-selected':
								correctOptionControl.value === option.get('id').value
						}" [class.bg-correct]="answered && optionsArrayIsCorrect[i].value" (click)="
							clickedOptionDiv(
								correctOptionControl,
								option.get('id'),
								option.get('id').value
							)
						">
						<nb-radio class="mr-2 pl-2" [disabled]="answered || hideResponse" [value]="option.get('id').value"
							[checked]="option.get('id').touched">
							<ng-container [ngSwitch]="optionsArrayType[i].value">
								<div *ngSwitchCase="elementTypes.O_TEXTS">
									<p class="option">
										{{ optionsArrayData[i].value }}
									</p>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PICTURES">
									<img class="mx-auto img-fluid" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" [src]="
											optionsArrayData[i].value | quizElements : 'image' | safe
										" *ngIf="optionsArrayData[i].value" />
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_VIDEOS">
									<video class="w-100 mw-100" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" controls [src]="
											optionsArrayData[i].value | quizElements : 'video' | safe
										" *ngIf="optionsArrayData[i].value"></video>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_AUDIOS">
									<audio class="w-100 d-block" controls [src]="
											optionsArrayData[i].value | quizElements : 'audio' | safe
										" *ngIf="optionsArrayData[i].value"></audio>
								</div>

								<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
									*ngSwitchCase="elementTypes.O_PDFS">
									<iframe #pdfElement class="w-100 h-100" [style.width]="
											optionsArrayWidth[i].value
												? optionsArrayWidth[i].value + 'px'
												: 'fit-content'
										" [style.height]="
											optionsArrayHeight[i].value
												? optionsArrayHeight[i].value + 'px'
												: '100%'
										" [src]="
											optionsArrayData[i].value | quizElements : 'pdf' | safe
										" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px"></iframe>
								</div>
							</ng-container>
						</nb-radio>
					</nb-radio-group>
				</div>

				<!-- QUIZZES MULTIPLES -->
				<div class="col-sm-12 col-md-10 d-flex align-items-center" [formGroupName]="i"
					*ngSwitchCase="QuizTypes.MULTIPLE">
					<nb-checkbox status="primary" class="mr-2 w-100 largerCheckbox" [disabled]="answered"
						formControlName="checked" [ngClass]="{
							'bg-correct': answered && optionsArrayIsCorrect[i].value,
							'bg-incorrect': answered && !optionsArrayIsCorrect[i].value
						}">
						<ng-container [ngSwitch]="optionsArrayType[i].value">
							<div *ngSwitchCase="elementTypes.O_TEXTS">
								<p class="option">
									{{ optionsArrayData[i].value }}
								</p>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PICTURES">
								<img class="mx-auto img-fluid" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" [src]="
										optionsArrayData[i].value | quizElements : 'image' | safe
									" *ngIf="optionsArrayData[i].value" />
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_VIDEOS">
								<video class="w-100 mw-100" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" controls [src]="
										optionsArrayData[i].value | quizElements : 'video' | safe
									" *ngIf="optionsArrayData[i].value"></video>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_AUDIOS">
								<audio class="w-100 d-block" controls [src]="
										optionsArrayData[i].value | quizElements : 'audio' | safe
									" *ngIf="optionsArrayData[i].value"></audio>
							</div>

							<div class="w-100 h-100 p-2 flex justify-content-between align-items-end"
								*ngSwitchCase="elementTypes.O_PDFS">
								<iframe #pdfElement class="w-100 h-100" [style.width]="
										optionsArrayWidth[i].value
											? optionsArrayWidth[i].value + 'px'
											: 'fit-content'
									" [style.height]="
										optionsArrayHeight[i].value
											? optionsArrayHeight[i].value + 'px'
											: '100%'
									" [src]="
										optionsArrayData[i].value | quizElements : 'pdf' | safe
									" allowfullscreen *ngIf="optionsArrayData[i].value" style="min-height: 920px"></iframe>
							</div>
						</ng-container>
					</nb-checkbox>
				</div>
			</ng-container>
		</div>
	</ng-container>
</form>
