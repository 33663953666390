import { version } from 'process';
import { APIURL, APIURLLOCAL, ApiEnvironments, ImageEnvironments, IMAGESASSETS, LoginImage, DomainTypes, UrlWebDomain, WebSocketEnvironment, PrivacyPolicies, HomeLogo, AppVersions} from 'src/app/core/models/masters/masters.enum';


const MATOMO_URL = '//matomo.salware.com/'

//localenvironmentpro simula que esta en salware o en el centro X dependiendo de lo que se ponga en la prop domain y que tu api local apunta a la bbdd de pro
export const localenvironmentpro = {
  production: false,
  apiUrl: `${APIURLLOCAL}${ApiEnvironments.LOCAL}`,
  localUrl: APIURLLOCAL,
  imagesCourses: `${APIURL}${ImageEnvironments.PRO}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.PRO}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.PRO}/users/`,
	agent: `${APIURL}${ImageEnvironments.PRO}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.PRO}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.PRO}/`,
  nodeImages: `${APIURL}${ImageEnvironments.PRO}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.PRO}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.PRO}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.PRO}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.PRO}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.PRO}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.PRO}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.PRO}/nodeContent/picture/`,
  bgLogin: LoginImage.PRO,
  domain : DomainTypes.FOUNDATION,
  logoLogin : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/logo/`,
	logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.LOCAL,
  tutorial : `${APIURL}${ImageEnvironments.PRO}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.PRO}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: window.location.hostname === 'localhost' ? 3 : 4,
  socketIp : 'localhost',
  socketPort: 8082,
  socketUrl : WebSocketEnvironment.LOCAL,
  ws : 'ws',
	privacyPDF : PrivacyPolicies.FOUNDATION,
	version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

//localenvironmentdev simula que esta en salware o en el centro X dependiendo de lo que se ponga en la prop domain y que tu api local apunta a la bbdd de dev
export const localenvironmentdev = {
  production: false,
  apiUrl: `${APIURLLOCAL}${ApiEnvironments.LOCAL}`,
  localUrl: APIURLLOCAL,
  imagesCourses: `${APIURL}${ImageEnvironments.DEV}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.DEV}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.DEV}/users/`,
	agent: `${APIURL}${ImageEnvironments.DEV}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.DEV}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.DEV}/`,
  nodeImages: `${APIURL}${ImageEnvironments.DEV}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.DEV}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.DEV}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.DEV}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.DEV}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.DEV}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.DEV}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.DEV}/nodeContent/picture/`,
  bgLogin: LoginImage.DEV,
  domain : DomainTypes.DEV,
  logoLogin : `${APIURL}${ImageEnvironments.DEV}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.DEV}/imagesCenters/logo/`,
	logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.LOCAL,
  tutorial : `${APIURL}${ImageEnvironments.DEV}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.DEV}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: window.location.hostname === 'localhost' ? 3 : 4,
  socketIp : 'localhost',
  socketPort: 8082,
  socketUrl : WebSocketEnvironment.LOCAL,
  ws : 'ws',
	privacyPDF : PrivacyPolicies.FOUNDATION,
	version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

export const devenvironment = {
  production: true,
  apiUrl: `${APIURL}${ApiEnvironments.TEST}`,
  localUrl: APIURL,
  imagesCourses: `${APIURL}${ImageEnvironments.DEV}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.DEV}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.DEV}/users/`,
	agent: `${APIURL}${ImageEnvironments.DEV}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.DEV}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.DEV}/`,
  nodeImages: `${APIURL}${ImageEnvironments.DEV}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.DEV}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.DEV}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.DEV}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.DEV}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.DEV}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.DEV}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.DEV}/nodeContent/picture/`,
  bgLogin: LoginImage.DEV,
  domain : DomainTypes.DEV,
  logoLogin : `${APIURL}${ImageEnvironments.DEV}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.DEV}/imagesCenters/logo/`,
  logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.DEV,
  tutorial : `${APIURL}${ImageEnvironments.DEV}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.DEV}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: window.location.hostname === 'localhost' ? 3 : 4,
  socketIp : 'salware.com',
  socketPort: 8080,
  socketUrl : WebSocketEnvironment.DEV,
  ws : 'wss',
  privacyPDF : PrivacyPolicies.FOUNDATION,
  version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

export const prodenvironment = {
  production: true,
  apiUrl: `${APIURL}${ApiEnvironments.PRO}`,
  localUrl: APIURL,
  imagesCourses: `${APIURL}${ImageEnvironments.PRO}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.PRO}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.PRO}/users/`,
	agent: `${APIURL}${ImageEnvironments.PRO}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.PRO}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.PRO}/`,
  nodeImages: `${APIURL}${ImageEnvironments.PRO}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.PRO}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.PRO}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.PRO}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.PRO}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.PRO}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.PRO}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.PRO}/nodeContent/picture/`,
  bgLogin: LoginImage.PRO,
  domain : DomainTypes.SALWARE,
  logoLogin : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/logo/`,
  logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.PRO,
  tutorial : `${APIURL}${ImageEnvironments.PRO}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.PRO}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: 1,
  socketIp : 'salware.com',
  socketPort: 8080,
  socketUrl : WebSocketEnvironment.PRO,
  ws : 'wss',
  privacyPDF : PrivacyPolicies.FOUNDATION,
  version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

export const preenvironment = {
  production: true,
  apiUrl: `${APIURL}${ApiEnvironments.PRO}`,
  localUrl: APIURL,
  imagesCourses: `${APIURL}${ImageEnvironments.PRO}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.PRO}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.PRO}/users/`,
	agent: `${APIURL}${ImageEnvironments.PRO}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.PRO}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.PRO}/`,
  nodeImages: `${APIURL}${ImageEnvironments.PRO}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.PRO}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.PRO}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.PRO}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.PRO}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.PRO}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.PRO}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.PRO}/nodeContent/picture/`,
  bgLogin: LoginImage.PRO,
  domain : DomainTypes.PRE,
  logoLogin : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/logo/`,
  logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.PRE,
  tutorial : `${APIURL}${ImageEnvironments.PRO}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.PRO}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: 3,
  socketIp : 'salware.com',
  socketPort: 8080,
  socketUrl : WebSocketEnvironment.PRO,
  ws : 'wss',
  privacyPDF : PrivacyPolicies.FOUNDATION,
  version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

export const foundationenvironment = {
  production: true,
  apiUrl: `${APIURL}${ApiEnvironments.PRO}`,
  localUrl: APIURL,
  imagesCourses: `${APIURL}${ImageEnvironments.PRO}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.PRO}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.PRO}/users/`,
	agent: `${APIURL}${ImageEnvironments.PRO}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.PRO}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.PRO}/`,
  nodeImages: `${APIURL}${ImageEnvironments.PRO}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.PRO}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.PRO}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.PRO}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.PRO}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.PRO}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.PRO}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.PRO}/nodeContent/picture/`,
  bgLogin: LoginImage.PRO,
  domain : DomainTypes.FOUNDATION,
  logoLogin : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/logo/`,
  logoHome : HomeLogo.FOUNDATION,
  domainUrl: UrlWebDomain.FOUNDATION,
  tutorial : `${APIURL}${ImageEnvironments.PRO}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.PRO}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: 7,
  socketIp : 'salware.com',
  socketPort: 8080,
  socketUrl : WebSocketEnvironment.PRO,
  ws : 'wss',
  privacyPDF : PrivacyPolicies.FOUNDATION,
  version: AppVersions.PROD,
  versionAPI: AppVersions.API
}

export const demoenvironment = {
  production: true,
  apiUrl: `${APIURL}${ApiEnvironments.DEMO}`,
  localUrl: APIURL,
  imagesCourses: `${APIURL}${ImageEnvironments.DEMO}/courses/`,
  imagesTargets: `${APIURL}${ImageEnvironments.DEMO}/target/`,
  imagesUsers: `${APIURL}${ImageEnvironments.DEMO}/users/`,
	agent: `${APIURL}${ImageEnvironments.DEV}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.DEMO}/surveys/`,
  imagesUrl: IMAGESASSETS,
  remoteFilesUrl: `${APIURL}${ImageEnvironments.DEMO}/`,
  nodeImages: `${APIURL}${ImageEnvironments.DEMO}/nodes/`,
  quizzesContent: `${APIURL}${ImageEnvironments.DEMO}/quizzesContent/`,
  quizzesStack: `${APIURL}${ImageEnvironments.DEMO}/quizzesstack/`,
  graphBackground: `${APIURL}${ImageEnvironments.DEMO}/targets/BackGround/`,
  nodeContent: `${APIURL}${ImageEnvironments.DEMO}/nodeContent/`,
  stickers : `${APIURL}${ImageEnvironments.DEMO}/imageTargets/`,
  groupImagen: `${APIURL}${ImageEnvironments.DEMO}/groups/image/`,
  nodeContentPicture: `${APIURL}${ImageEnvironments.DEMO}/nodeContent/picture/`,
  bgLogin: LoginImage.DEMO,
  domain : DomainTypes.DEMO,
  logoLogin : `${APIURL}${ImageEnvironments.DEMO}/imagesCenters/login/`,
  logoCenter : `${APIURL}${ImageEnvironments.DEMO}/imagesCenters/logo/`,
  logoHome : HomeLogo.SALWARE,
  domainUrl: UrlWebDomain.DEMO,
  tutorial : `${APIURL}${ImageEnvironments.DEMO}/tutorial/`,
  celebrations : `${APIURL}${ImageEnvironments.DEMO}/celebrations/`,
  matomoUrl: MATOMO_URL,
  idMatomo: 3,
  socketIp : 'salware.com',
  socketPort: 8080,
  socketUrl : WebSocketEnvironment.DEMO,
  ws : 'wss',
  privacyPDF : PrivacyPolicies.FOUNDATION,
  version: AppVersions.PROD,
  versionAPI: AppVersions.API
}


export const psycast = {
	production: true,
	apiUrl: `${APIURL}${ApiEnvironments.PRO}`,
	localUrl: APIURL,
	imagesCourses: `${APIURL}${ImageEnvironments.PRO}/courses/`,
	imagesTargets: `${APIURL}${ImageEnvironments.PRO}/target/`,
	imagesUsers: `${APIURL}${ImageEnvironments.PRO}/users/`,
	agent: `${APIURL}${ImageEnvironments.DEV}/agent/`,
	imagesSurveys: `${APIURL}${ImageEnvironments.PRO}/surveys/`,
	imagesUrl: IMAGESASSETS,
	remoteFilesUrl: `${APIURL}${ImageEnvironments.PRO}/`,
	nodeImages: `${APIURL}${ImageEnvironments.PRO}/nodes/`,
	quizzesContent: `${APIURL}${ImageEnvironments.PRO}/quizzesContent/`,
	quizzesStack: `${APIURL}${ImageEnvironments.PRO}/quizzesstack/`,
	graphBackground: `${APIURL}${ImageEnvironments.PRO}/targets/BackGround/`,
	nodeContent: `${APIURL}${ImageEnvironments.PRO}/nodeContent/`,
	stickers : `${APIURL}${ImageEnvironments.PRO}/imageTargets/`,
	groupImagen: `${APIURL}${ImageEnvironments.PRO}/groups/image/`,
	nodeContentPicture: `${APIURL}${ImageEnvironments.PRO}/nodeContent/picture/`,
	bgLogin: LoginImage.PRO,
	domain : DomainTypes.PSYCAST,
	logoLogin : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/login/`,
	logoCenter : `${APIURL}${ImageEnvironments.PRO}/imagesCenters/logo/`,
	logoHome : HomeLogo.FOUNDATION,
	domainUrl: UrlWebDomain.PSYCAT,
	tutorial : `${APIURL}${ImageEnvironments.PRO}/tutorial/`,
	celebrations : `${APIURL}${ImageEnvironments.PRO}/celebrations/`,
	matomoUrl: MATOMO_URL,
	idMatomo: 5,
	socketIp : 'salware.com',
	socketPort: 8080,
	socketUrl : WebSocketEnvironment.PRO,
  ws : 'wss',
	privacyPDF : PrivacyPolicies.PSYCAST,
	version: AppVersions.PROD,
  versionAPI: AppVersions.API
}



