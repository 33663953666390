import { ModalEliminarEditorComponent } from "./modal-eliminar-editor/modal-eliminar-editor.component";
import { ModalListadoEditoresComponent } from "./modal-listado-editores/modal-listado-editores.component";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	FormGroupDirective,
	NgForm,
	Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { Router } from "@angular/router";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CourseModel, CourseTargetModel } from "src/app/core/models/courses";
import {
	IdiomaModel,
	MateriaModel,
	NivelModel,
	PaisModel,
} from "src/app/core/models/masters";
import { User } from "src/app/core/models/users/user.models";
import { CoursesService } from "src/app/core/services/courses";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import { LoginService } from "src/app/core/services/login";
import { AlertService } from "src/app/core/services/shared";
import { TargetsService } from "src/app/core/services/targets";
import { UsersService } from "src/app/core/services/users";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { Utils } from "src/app/core/utils/utils";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { ModalAceptarCancelarComponent } from "../../modal";
import { ModalEliminarCursoComponent } from "../../modal/modal-eliminar-curso/modal-eliminar-curso.component";
import { ModalEditarCursoMapaComponent } from "../modal-editar-curso-mapa";
import { ModalInformationCourseComponent } from "../modal-information-course/modal-information-course.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { SigmaToolbarsService } from "src/app/core/services/sigma-toolbars/sigma-toolbars.service";
import { LocalStorage } from "src/app/core/utils";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { QuizzesService } from "src/app/core/services/quizzes";
import { NodeService } from "src/app/core/services/node/node.service";
import { map } from 'rxjs/operators';
import { MastersService } from "src/app/core/services/masters";
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { SigmaCanvasService } from "../../nodes/sigma-canvas/sigma-canvas.service";
import { CenterService } from "src/app/core/services/center/center.service";

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

export class ParentGroupValidationStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const invalidCtrl = !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched)
		);
		const invalidParent = !!(
			control &&
			(control.dirty || control.touched) &&
			control.parent &&
			control.parent.invalid &&
			control.parent.dirty
		);

		return invalidCtrl || invalidParent;
	}
}

@Component({
    selector: "app-modal-curso-edit-detail",
    templateUrl: "./modal-curso-edit-detail.component.html",
    styleUrls: ["./modal-curso-edit-detail.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalCursoEditDetailComponent implements OnInit {
	@Input() id: string;
	@Input() showModalFormat: boolean = true;

	cursoDatos = {
		title: "",
		description: "",
		subject: "",
		lang1: "",
		project_type: "",
		idCenter: "",
		level: "",
		image: "",
		user: {
			name: "",
			surname: "",
			image: "",
		},
	};
	user: User;

	buscarNodo: any;
	isOpenInfoCourse: boolean;

	materias: MateriaModel[] = [];
	niveles: NivelModel[] = [];
	idiomas: IdiomaModel[] = [];
	courseTargets: CourseTargetModel[] = [];
	exclusiveCourseTargets: CourseTargetModel[] = [];
	idImage: string = "";
	saving: boolean = false;

	matcher = new ParentGroupValidationStateMatcher();
	public formulario: UntypedFormGroup;

	public formularioInfo: UntypedFormGroup;

	options: any;
	exclusiveOptions: any;

	curso: CourseModel;
	modoTraerNodo: any;
	modoTraerActividad: any;
	public graphView: string = "gridList";
	progress: number;
	clickSidebar: any;
	levelsOfCountry: any;
	countriesList: PaisModel[];

	langList = [
		{
			nombre: "Español",
			idLang: 38,
		},
		{
			nombre: "Ingles",
			idLang: 36,
		},
		{
			nombre: "Alemán",
			idLang: 31,
		},
		{
			nombre: "Francés",
			idLang: 46,
		},
		{
			nombre: "Italiano",
			idLang: 71,
		},
		{
			nombre: "Portugués",
			idLang: 127,
		},
	];

	projectsTypes = [
		{
			id: 1,
			descripcion: this.translateService.instant("EDITARCURSO.ESTUDIO"),
		},
		{
			id: 2,
			descripcion: this.translateService.instant("EDITARCURSO.PRACTICO"),
		},
		{
			id: 3,
			descripcion: this.translateService.instant("EDITARCURSO.PISA"),
		},
		/*
		{
			id: 4,
			descripcion: this.translateService.instant("MENUACTIONS.TEACHERTITLE18"),
		},
		*/
	];

	centros = [];

	notCountryLevelsForThisCountry: boolean = false;

	dataList: any[] = []; //listado de grafos
	cargando: boolean = false; //para spinner
	networkSpeed = 1; //velocidad de internet
	modeList: boolean = false; //para cambiar entre modo lista y modo grilla
	isMobile: boolean = false; //si el dispositivo es movil o escritorio
	selectedLabel = null; // Variable para guardar el elemento seleccionado
	idSelected: number = 0; // nodo = 1; quizSimple = 2; quizMulti = 3
	i_selected: number = null; //para saber el index del curso abierto
	j_selected: number = null; //para saber el index del target abierto
	idTarget: number = 0; //para mostrar solo un elemento

	//===== variables para editar nodos =====
	node: any;
	idCurso: number;
	idMapa: number;
	canEdit: boolean = false;
	course: CourseModel;
	isNewNode: boolean = false;
	//===== =====

	//===== variables para editar quizzes simples =====
	quiz: any;
	elements: any;
	//===== =====

	//===== variables para editar quizzes multiples =====
	quizFiles: any;
	quizzes: any;
	//===== =====
	
	page: number = 1;

	constructor(
		private utils: Utils,
		public loginService: LoginService,
		private getDataService: GetDataService,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		private imagePipe: ImagenPipe,
		public userService: UsersService,
		public router: Router,
		private toaster: ToasterService,
		private toolsService: SigmaToolbarsService,
		private localStorage: LocalStorage,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		private masterService: MastersService,
		private sigmaCanvasService: SigmaCanvasService,
		private centerService: CenterService,
	) {
		this.formularioInfo = this.formBuild.group({
			title: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				],
			],
			description: [
				"",
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(500),
				],
			],
			subject: ["", [Validators.required]],
			level: [""],
			lang1: ["", [Validators.required]],
			project_type: ["", [Validators.required]],
			idCenter: ["", [Validators.required]],
			published: [false],
			idCountry: [""],
			idCountryLevel: [0],
			deckSize: [
				5,
				[Validators.required, Validators.min(5), Validators.max(100)],
			],
		});

		this.createForm();

		this.options = {
			draggable: ".draggable",
			onUpdate: (event: any) => {
				this.saveChanges(event, false);
			},
		}; //Drag&Drop update function

		this.exclusiveOptions = {
			draggable: ".draggable",
			onUpdate: (event: any) => {
				// this.saveChanges(event, true);
			},
		}; //Drag&Drop update function
	}

	ngOnInit() {
		this.networkSpeed = this.getNetworkSpeed(); //primero verificamos la velocidad de internet
		this.cargando = true;
		this.user = this.loginService.getUser();
		this.materias = this.getDataService.appSubjects;

		// Obtengo los maestros
		this.obtenerDatosMaestros();
		this.obtenerDatosCurso(this.id);
		this.countriesList = this.getDataService.appCountries;
		if (this.loginService.esAutor()) {
			this.projectsTypes.push({
					id: 4,
					descripcion: this.translateService.instant("MENUACTIONS.TEACHERTITLE18"),
			},
			{
				id: 5,
				descripcion: this.translateService.instant("EDITARCURSO.CONTROL"),
			},);
			//traemos los centros
			this.centerService.getCentersAsignedEditor().pipe().subscribe(res => {
				if(res){
					this.centros = res;
					let centro = {
						authorization: 0,
						centerAddress: "",
						centerMail: "",
						centerName: "Sin centro",
						centerPhone: "666666666",
						centerPicture: null,
						centerUrl: "",
						country: "ESP",
						extension: "34",
						idCenter: 0,
						province: null,
						remarks: ""
					};
					this.centros.push(centro);
					console.log(this.centros);
				}
			});
		}
		//definimos variable para tutoriales
		let lastSelectedProfile = this.localStorage.getItem("lastSelectedProfile");
		let idTutorialContext = 0;
		if(lastSelectedProfile === "PROFESOR"){
			idTutorialContext = 45;
		} else if(lastSelectedProfile === "AUTOR"){
			idTutorialContext = 90;
		} else if(lastSelectedProfile === "ESTUDIANTE"){
			idTutorialContext = 76;
		}
		this.masterService.setIdTutorialContext(idTutorialContext);
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
  }

	createForm() {
		this.formulario = this.formBuild.group({
			image: [{ value: "", disabled: false }],
			title: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				],
			],
			description: [
				"",
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(500),
				],
			],
			subject: ["", [Validators.required]],
			level: [""],
			lang1: ["", [Validators.required]],
			project_type: ["", [Validators.required]],
			idCenter: ["", [Validators.required]],
			idCountry: [""],
			idCountryLevel: [0],
			published: [false],
			isVisible: [false],
			courseType: [false],
		});
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.cursoDatos.user.image);
	}

	updateUrl(event: any) {
		event.target.src = "../../assets/images/no-image.png";
	}

	obtenerDatosMaestros() {
		this.idiomas = this.getDataService.appLanguages;
		this.niveles = this.getDataService.appLevels;
	}

	searchLevels(country) {
		this.coursesService
			.getCountryLevelsByCountry(country)
			.subscribe((countryLevels) => {
				if (countryLevels.data.length == 0) {
					this.notCountryLevelsForThisCountry = true;
					this.formulario.patchValue({ idCountryLevel: 0 });
				} else {
					this.notCountryLevelsForThisCountry = false;
					this.formulario.patchValue({
						idCountryLevel: countryLevels.data[0].idCountryLevel,
					});
				}
				this.levelsOfCountry = countryLevels.data;
			});
	}

	obtenerDatosCurso(id) {
		this.cargando = true;
		this.coursesService.getCourseById(id).subscribe((response) => {
			this.curso = response.data.courses as CourseModel; // Course detail
			this.coursesService
				.getCountryLevelsByCountry(this.curso.idCountry)
				.subscribe((countryLevels) => {
					this.levelsOfCountry = countryLevels.data;
				});
			//this.orderCoursesTargets(response.data.coursesTarget);
			this.formulario.patchValue({
				title: this.curso.courseTittle,
				description: this.curso.description,
				subject: this.curso.subject.idSubject,
				level: this.curso.courseSWLevel,
				lang1: this.curso.language.idLanguage,
				project_type: this.curso.project_type,
				idCenter: this.curso.idCenter,
				image: this.curso.cpicture
					? this.imagePipe.transform(this.curso.cpicture, "cursos")
					: "",
				published: this.curso.published ? true : false,
				isVisible: this.curso.isVisible === 1 ? true : false,
				courseType: this.curso.courseType === 1 ? true : false,
				idCountry: this.curso.idCountry,
				idCountryLevel: this.curso.countryLevel,
				sonsNumber: this.curso.sonsNumber,
			});
			this.getListadoGrafos(false);
			this.cursoDatos.user.name = this.curso.user.firstName;
			this.cursoDatos.user.surname = this.curso.user.surname;
			this.cursoDatos.user.image = this.curso.user.pictureUser;
		});
	}

	getListadoGrafos(fromHide: boolean){
		this.cargando = true;
		this.coursesService.getListadoGrafosEditor(Number(this.id)).subscribe(res => {
		  if(res.data){
			this.dataList = res.data;
			if(fromHide){
			  this.viewNodes(this.i_selected);
			  if(this.j_selected != null){
				this.viewQuizzes(this.i_selected, this.j_selected);
			  }
			}
			//para mostrar solo un elemento
			if(this.idTarget != 0){
				this.showOnlyOneTarget(this.idTarget);
			}
			this.cargando = false;
		  }
		})
	  }

	closeModal(sendData?: any) {
		if (
			this.curso !== undefined &&
			this.formulario.valid &&
			this.changues() == true
		) {
			this.savData();
		} else{
			this.masterService.getPreviousIdTutorialContext();
			this.activeModal.close(sendData);
		}
	}

	close() {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close();
	}

	changues(): boolean {
		const v = this.formulario.value;
		const cursoPublished = this.curso.published ? true : false;
		const cursoVisible = this.curso.isVisible ? true : false;
		if (
			this.curso.courseTittle !== v.title ||
			this.curso.description !== v.description ||
			cursoPublished !== v.published ||
			cursoVisible !== v.isVisible ||
			this.curso.idCountry !== v.idCountry ||
			this.curso.countryLevel !== v.idCountryLevel ||
			this.curso.language.idLanguage !== v.lang1 ||
			this.curso.project_type !== v.project_type ||
			this.curso.idCenter !== v.idCenter ||
			this.imagePipe.transform(this.curso.cpicture, "cursos") !== v.image
		) {
			return true;
		}
		return false;
	}

	/**
	 * Create/update Course
	 */

	savData(): void {
		let image: File | string = "";
		this.saving = true;
		const v = this.formulario.value;
		let request: CourseModel = new CourseModel(
			v.level,
			v.title,
			v.subject,
			v.description,
			v.lang1,
			null,
			v.project_type,
			v.idCenter,
			this.curso.user.idUser,
			this.curso.idCourse,
			v.idCountry,
			v.idCountryLevel,
			null,
			this.curso.sonsNumber
		);
		if (this.id !== "nuevo") {
			request.idCourse = this.curso.idCourse;
			//Si el curso no tiene imagen
			if (this.curso.cpicture == "" || this.curso.cpicture == null) {
				if (typeof v.image === "object") image = v.image;
			} else {
				//Si el curso tiene imagen
				if (typeof v.image == "string" && v.image != "")
					request.cpicture = this.curso.cpicture;
				else if (typeof v.image == "object") {
					request.cpicture = this.curso.cpicture;
					image = v.image;
				} else if (typeof v.image == "string" && v.image == "") {
					request.cpicture = null;
				}
			}
		} else {
			image = v.image;
		}

		request.published = v.published ? Date.now() : null;
		request.isVisible = v.isVisible ? 1 : 0;
		request.courseType = v.courseType ? 1 : 2;

		this.coursesService.setCourse(request, image).subscribe(
			(event: HttpEvent<any>) => {
				switch (event.type) {
					case HttpEventType.UploadProgress:
						this.progress = Math.round((event.loaded / event.total) * 100);
						break;
					case HttpEventType.Response:
						setTimeout(() => {
							this.progress = 0;
							if (this.id !== "nuevo")
								this.toaster.success(
									this.translateService.instant("EDITARCURSO.OKSAVE")
								);
							this.saving = false;
							this.obtenerDatosCurso(event.body.data.idCourse);
							this.id = event.body.data.idCourse.toString();
							if (this.clickSidebar) {
								this.clickSidebar = false;
							} else {
								this.toolsService.changeUpdateGraph(true)
								this.masterService.getPreviousIdTutorialContext();
								this.activeModal.close(
									this.translateService.instant("EDITARCURSO.OKSAVE")
								);
							}
						}, 500);
				}
			},
			(error) => {
				this.masterService.getPreviousIdTutorialContext();
				this.activeModal.close(
					this.translateService.instant("EDITARCURSO.CANCELSAVE")
				);
				this.toaster.info(
					this.translateService.instant("EDITARCURSO.CANCELSAVE")
				);
				this.saving = false;
			}
		);
	}

	// MAPAS
	verDetalleMapa(idMapa: number, translating: number) {
		if(translating == 0){
			const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
				scrollable: true,
				windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			});

			modalRef.componentInstance.id = idMapa;
			modalRef.componentInstance.curso = this.curso;

			modalRef.result.then(
				(result) => {

					if (result && result === "closeAll") {
						this.obtenerDatosCurso(this.id);
						this.localStorage.setItem('lastTarget_Editor', idMapa);
						this.closeModal("Editar nodos mapa");
					} else if (result && result.msg === "fromCopy") {
						this.obtenerDatosCurso(result.idCurso);
						/**
						this.targetsService
						.getCourseTargetByIdCourse(result.idCurso)
						.subscribe((res) => {
							this.orderCoursesTargets(res.data);
						});
						*/
					}
					else if (result) {
						this.obtenerDatosCurso(this.id);
						/**
						this.targetsService
							.getCourseTargetByIdCourse(this.curso.idCourse)
							.subscribe((result) => {
								this.orderCoursesTargets(result.data);
							});
							*/
					}
				},
				(err) => {}
			);
		}
	}

	getImageBackground(image: string | null): string {
		let noImg = "../../assets/images/no-image.png";
		if (!image) return `url('${noImg}')`;
		return `url(${this.imagePipe.transform(image, "mapas")})`;
	}

	nuevoMapa(exclusive?: boolean) {
		if (!this.loginService.esAutor()) {
			return;
		}

		const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, {
			scrollable: true,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});

		modalRef.componentInstance.id = "nuevo";
		modalRef.componentInstance.curso = this.curso;
		modalRef.componentInstance.challengesExclusive = exclusive;

		modalRef.result.then(
			(result) => {
				if (result) {
					this.getListadoGrafos(false);
				}
			},
			(reason) => {}
		);
	}

	openInformationCourse() {
		const modalRef = this.modalService.open(ModalInformationCourseComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.curso = this.curso;
		modalRef.componentInstance.editarInfo = true;

		modalRef.result.then(
			(result) => {
				switch (result) {
					case this.formularioInfo:
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso borrado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Curso modificado":
						// tslint:disable-next-line: max-line-length
						this.alertService.success(
							this.translateService.instant(
								"CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE"
							),
							AlertService.AlertServiceContextValues.ModalCurso
						);
						break;
					case "Abrir mapa":
						// Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
						this.closeModal(result);
						break;
					case "borrarcurso":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.borrarCurso();
						break;
					case "Editar nodos mapa":
						// Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
						this.closeModal(result);
						break;
				}
				// Refresco el listado
				this.obtenerDatosCurso(this.id);
			},
			(reason) => {}
		);
	}

	// BORRAR CURSO
	borrarCurso() {
		if (!this.loginService.esAutor()) {
			return;
		}
		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalEliminarCursoComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.mensaje = this.translateService.instant(
			"EDITARCURSO.DESEABORRARCURSO"
		);
		modalRef.result.then((result: boolean) => {
			// Si devuelve true lo borro y si devuelve false no hago nada
			if (result) {
				this.cargando = true;
				this.coursesService.deleteCourse(this.curso.idCourse).subscribe(
					(resp: any) => {
						// Cierro la modal del detalle del curso
						this.masterService.getPreviousIdTutorialContext();
						this.activeModal.close("Curso borrado");
						setTimeout(() => {
							this.cargando = false;
						}, 300);
						this.toaster.success(
							this.translateService.instant("EDITARCURSO.OKDELETE")
						);
					},
					(error) => {
						this.toaster.error(
							this.translateService.instant("EDITARCURSO.KODELETE")
						);
					}
				);
			}
		});
	}
	// FIN BORRAR CURSO

	/**
	 * Publish or unpublish course
	 * @param $ev Click event in the switch component
	 */
	publish($ev) {
		$ev.preventDefault();
		$ev.stopImmediatePropagation();
		let currentValue: boolean = !this.formulario.get("published").value;
		let modalMessage: string = currentValue
			? this.translateService.instant("EDITARCURSO.PUBLISHMSG")
			: this.translateService.instant("EDITARCURSO.UNPUBLISHMSG");
		let errorMessage: string = currentValue
			? this.translateService.instant("GENERAL.KOPUBLISH")
			: this.translateService.instant("GENERAL.KOUNPUBLISH");
		let okMessage: string = currentValue
			? this.translateService.instant("GENERAL.OKPUBLISH")
			: this.translateService.instant("GENERAL.OKUNPUBLISH");

		//Open modal message alert to confirm the selection

		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.mensaje = modalMessage;

		modalRef.result.then((result: boolean) => {
			if (result) {
				this.getDataService
					.setPublishType("course", Number(this.id), currentValue)
					.subscribe(
						(result) => {
							if (result.data) {
								this.toaster.success(okMessage);
								this.formulario.get("published").setValue(currentValue); // Update form value
							} else
								this.toaster.error(
									this.translateService.instant("EDITARCURSO.KOPUBLISHCONTENT")
								);
						},
						(err) => {
							this.toaster.error(errorMessage);
						}
					);
			}
		});
	}

	makeVisibleCourse() {
		let currentValue: boolean = !this.formulario.get("isVisible").value;
		let isVisible = currentValue ? 1 : 0;

		this.coursesService
			.makeVisibleCourse(this.curso.idCourse, isVisible)
			.subscribe((result) => {
				if (result.error.code === 0) {
					this.toaster.success(this.translateService.instant(result.error.msg));
					this.formulario.get("isVisible").setValue(currentValue);
				} else {
					this.toaster.error(this.translateService.instant(result.error.msg));
					this.formulario.get("isVisible").setValue(currentValue);
				}
			});
	}

	changeCourseType() {
		let currentValue: boolean = !this.formulario.get("courseType").value;
		let courseType = currentValue ? 1 : 2;

		this.coursesService
			.changeCourseType(this.curso.idCourse, courseType)
			.subscribe((result) => {
				if (result.error.code === 0) {
					this.toaster.success(this.translateService.instant(result.error.msg));
					this.formulario.get("courseType").setValue(currentValue);
				} else {
					this.toaster.error(this.translateService.instant(result.error.msg));
					this.formulario.get("courseType").setValue(currentValue);
				}
			});
	}

	saveChanges(event: any, isExclusive: boolean) {
		let currentOrdinal: number = null;
		const newOrdinal: number = event.newIndex + 1;

		// if (isExclusive)
		// 	currentOrdinal =
		// 		this.exclusiveCourseTargets[event.newIndex].ordinalExclusive;
		// else currentOrdinal = this.dataList[event.newIndex].ordinal;

		currentOrdinal = this.dataList[event.newIndex].ordinal;

		this.targetsService
			.updateGraphOrder(
				Number(this.id),
				currentOrdinal,
				newOrdinal,
				isExclusive
			)
			.subscribe(
				(result) => {
					// this.orderCoursesTargets(result.data.coursesTarget);
				},
				(err) => {
					this.toaster.error(
						this.translateService.instant("EDITARCURSO.KOMOVEGRAPH")
					);
				}
			);
	}

	listadoEditores() {
		const modalRef = this.modalService.open(ModalListadoEditoresComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W95} h-100`,
		});
		modalRef.componentInstance.id = this.curso.idCourse;
		modalRef.componentInstance.curso = this.curso;

		modalRef.result.then(
			(result) => {
				this.ngOnInit();
			},
			(err) => {}
		);
	}

	borrarEditor() {
		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalEliminarEditorComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W40,
		});

		modalRef.componentInstance.mensaje = this.translateService.instant(
			"EDITARCURSO.DESEABORRAREDITOR"
		);

		modalRef.result.then((result: boolean) => {
			// Si devuelve true lo borro y si devuelve false no hago nada

			if (result) {
				this.coursesService
					.deleteEditor(this.curso.idCourse, this.user.idUser)
					.subscribe(
						(resp: any) => {
							// Cierro la modal del detalle del curso
							this.masterService.getPreviousIdTutorialContext();
							this.activeModal.close("Editor borrado");
							this.toaster.success(
								this.translateService.instant("EDITARCURSO.OKDELETEEDITOR")
							);
						},
						(error) => {
							this.toaster.error(
								this.translateService.instant("EDITARCURSO.KODALETEEDITOR")
							);
						}
					);
			}
		});
	}

	private orderCoursesTargets(data: CourseTargetModel[]) {
		this.courseTargets = data.filter((element: CourseTargetModel) => {
			if (this.loginService.esAutor()) {
				return element.target && !element.target.exclusive;
			} else {
				return element.target.published !== null && !element.target.exclusive;
			}
		});
		this.exclusiveCourseTargets = data.filter(
			(element: CourseTargetModel, index) => {
				return element.target.exclusive;
			}
		);
	}

	verNodosMapa(idTarget: any) {
		//primero obtenemos el target
		let target = null;
		this.targetsService.getCourseTargetByIdTarget(idTarget)
		  .subscribe(res => {
			if(res.data){
				target = res.data.target;
				//si no es una prueba de control elminamos la variable global
				if(this.curso.project_type == 5){
					localStorage.setItem("proyectoControl", "true")
				} else{
					localStorage.removeItem("proyectoControl");
				}
				// Si soy estudiante le mando al mapa directamente
				localStorage.setItem("lastSelectedProfile", Profiles.Author);
				this.loginService.setProfile(Profiles.Author);
				if (this.loginService.esAutor() || this.loginService.esEstudiante()) {
					this.listaGrafosVisitados(target);
					// Verifica si la URL actual es la misma que la que intentas navegar
					const currentUrl = this.router.url;
					const targetUrl = `/course/${this.curso.idCourse}/graph/${target.idTarget}`;
					if (currentUrl === targetUrl) {
						localStorage.setItem('dontLoadMenu', 'true')
						this.utils.loadMenu = false;
						// Si la URL es la misma, realiza un reload
						this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
								this.router.navigate([`/course/${this.curso.idCourse}/graph/${target.idTarget}`])
								.then(() => {
									this.closeModal("Editar nodos mapa");
									this.masterService.getPreviousIdTutorialContext();
									this.activeModal.close("closeAll");
							});;
						});
					} else {
						localStorage.setItem('dontLoadMenu', 'true')
						this.utils.loadMenu = false;
						// Si la URL es diferente, navega normalmente
						this.router.navigate([`/course/${this.curso.idCourse}/graph/${target.idTarget}`])
								.then(() => {
										this.closeModal("Editar nodos mapa");
										this.masterService.getPreviousIdTutorialContext();
										this.activeModal.close("closeAll");
								});
					}
					let historialGrafos_editor = [];
					let localStoredHistory_editor = JSON.parse(
						localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR)
					);
					if (localStoredHistory_editor == null) {
						historialGrafos_editor.push(this.curso.idCourse);
					} else {
						if (localStoredHistory_editor.length > 15) {
							localStoredHistory_editor.shift(); // Elimina el primer elemento
						}
						localStoredHistory_editor.push(this.curso.idCourse);
					}
					historialGrafos_editor.push(this.curso.idCourse);
					this.localStorage.setItem(
						LOCALSTORAGESTRINGS.GRAPHSHISTORY_EDITOR,
						JSON.stringify(
							localStoredHistory_editor == null ? historialGrafos_editor : localStoredHistory_editor
						)
					);
					return;
				}
			}
		  })
	}

	cambiarEditor() {
		this.sigmaCanvasService.setIsLoading(true);
		localStorage.setItem("dontLoadMenu", "true");
		this.utils.loadMenu = false;
		this.toolsService.changeUpdateGraph(true);
		setTimeout(() => {
			this.sigmaCanvasService.setIsLoading(false);
		}, 200);
		this.loginService.setProfile(Profiles.Author);
	}

	listaGrafosVisitados(target: any) {
		// Obtenemos el array de los últimos grafos visitados.
		let graphVisited_Editor = JSON.parse(this.localStorage.getItem('graphVisited_Editor')) || [];
		const newElement = {
			title: target.tittle,
			idGrafo: target.idTarget,
			idCurso: this.curso.idCourse
		};
		// Validar si el nuevo elemento ya está en el array
		const isDuplicate = graphVisited_Editor.some(element =>
			element.idGrafo === newElement.idGrafo && element.idCurso === newElement.idCurso
		);
		// Si no es un duplicado, lo agregamos
		if (!isDuplicate) {
			// Si ya hay 10 elementos, eliminamos el ultimo.
			if (graphVisited_Editor.length >= 10) { // con esto determinamos el tamaño del array
				graphVisited_Editor.pop();
			}
			// Agregamos el nuevo elemento.
			graphVisited_Editor.unshift(newElement);
			// Actualizamos el local storage.
			this.localStorage.setItem('graphVisited_Editor', JSON.stringify(graphVisited_Editor));
		}
	}

	/** ======================================================== */
	/** =============== INICIO LISTADO DE GRAFOS =============== */

	changeView(change: boolean){
		if(!change){
			this.idSelected = 0;
		}
		this.modeList = change;
		this.closeTree();
	}

	viewNodes(i: number){
		if(!this.dataList[i].childrenVisible && this.dataList[i].children.length == 0){
			this.dataList[i].childrenVisible = !this.dataList[i].childrenVisible;
			this.coursesService.getListadoNodosEditor(Number(this.id), this.dataList[i].id).subscribe(res => {
				this.dataList[i].children = res.data;
				for (let index = 0; index < this.dataList[i].children.length; index++) {
						this.dataList[i].children[index].childrenVisible = false;
				}
			})
		} else{
			this.dataList[i].childrenVisible = !this.dataList[i].childrenVisible;
			for (let index = 0; index < this.dataList[i].children.length; index++) {
					this.dataList[i].children[index].childrenVisible = false;
			}
		}
	}

	viewQuizzes(i: number, j: number){
		this.dataList[i].children[j].childrenVisible = !this.dataList[i].children[j].childrenVisible;
	}

	closeTree(){
		if(this.dataList != null && this.dataList.length > 0){
			for (let i = 0; i < this.dataList.length; i++) {
				this.dataList[i].childrenVisible = false;
				if(this.dataList[i].children != null && this.dataList[i].children.length > 0){
					for (let j = 0; j < this.dataList[i].children.length; j++) {
						this.dataList[i].children[j].childrenVisible = false;
					}
				}
			}
		}
	}

	viewData(indexTarget: number, indexNode: number, select: any){
		this.idSelected = 0; //Ocultamos el nodo que este abierto
		this.selectedLabel = select;
		this.i_selected = indexTarget;
		this.j_selected = select.type == 0 ? null : indexNode;
		//si type = 0 es un nodo
		if(select.type == 0){
		this.nodeService.getNode(select.id, Number(this.id), this.dataList[indexTarget].id)
			.pipe(map((res: any) => res.data[0]))
			.subscribe(res => {
			//obtenemos el curso y mostramos el nodo
			if(res){
				this.coursesService.getCourseById(Number(this.id))
				.subscribe(resp => {
					if(resp.data){
					//===datos para el nodo===
					this.node = res;
					this.idCurso = Number(this.id);
					this.idMapa = this.dataList[indexTarget].id;
					this.canEdit = false;
					this.course = resp.data.courses;
					this.isNewNode = false;
					//=== ===
					this.idSelected = 1; //Para mostrar el nodo
					}
				})
			}
		})
		}
		//si type = 1 es un quiz simple
		else if(select.type == 1){
		this.quizService.getQuiz(select.id, Number(this.id), this.dataList[indexTarget].id)
			.subscribe(res => {
			if(res){
				this.quizService.getQuizFromTargetByModeList(Number(this.id), this.dataList[indexTarget].id, select.id)
				.subscribe(resp => {
					if(resp){
					//===datos para el quiz simple===
					this.quiz = {
						...res.quiz,
						user: resp.data.user,
						idOriginal: resp.data.idOriginal,
						id: resp.data.id,
						ordinal: resp.data.ordinal,
						originalX: resp.data.originalX,
						originalY: resp.data.originalY,
						size: resp.data.size,
						sizeQuiz: resp.data.sizeQuiz,
						x: resp.data.x,
						y: resp.data.y
					};
					this.elements = res.elements;
					this.idCurso = Number(this.id);
					this.idMapa = this.dataList[indexTarget].id;
					//=== ===
					this.idSelected = 2; //Para mostrar el quiz simple
					}
				})
			}
			})
		}
		//si type = 2 es un quiz multiple
		else if(select.type == 2){
		this.quizService.getQuizMultiple(select.id, Number(this.id), this.dataList[indexTarget].id)
				.subscribe(res => {
			if(res){
			this.quizService.getQuizFromTargetByModeList(Number(this.id), this.dataList[indexTarget].id, select.id)
			.subscribe(resp => {
				if(resp){
				//===datos para el quiz multiple===
				this.quiz = {
					...res.quiz,
					user: resp.data.user,
					idOriginal: resp.data.idOriginal,
					id: resp.data.id,
					ordinal: resp.data.ordinal,
					originalX: resp.data.originalX,
					originalY: resp.data.originalY,
					size: resp.data.size,
					sizeQuiz: resp.data.sizeQuiz,
					x: resp.data.x,
					y: resp.data.y
				};
				this.elements = res.elements;
				this.quizFiles = res.quizFiles;
				this.quizzes = res.quizzes;
				this.idCurso = Number(this.id);
				this.idMapa = this.dataList[indexTarget].id;
				//=== ===
				this.idSelected = 3; //Para mostrar el quiz multiple
				}
			})
			}
		})
		}
	}

	hideData(hide: boolean){
		this.idSelected = 0; //para ocultar nodo o quiz
		this.selectedLabel = null;
		this.getListadoGrafos(hide);
	}

	showOnlyOneTarget(idTarget: number){
		let aux_dataList: any[] = this.dataList.filter(item => item.id === idTarget);
		this.dataList = aux_dataList;
	}

	newNode(indexTarget, idTarget) {
		this.i_selected = indexTarget;
		this.j_selected = null;
		this.nodeService.createNodeFromModeList(idTarget)
		.subscribe(res => {
			if(res.data){
				//refrescamos el listado de cursos
				this.getListadoGrafos(true);
			}
		})
	}

	newQuiz(indexTarget, indexNode, idTarget: number, idNode: number) {
		this.i_selected = indexTarget;
		this.j_selected = indexNode;
		this.quizService.createQuizFromModeList(this.curso.idCourse, idTarget, idNode)
		.subscribe(res => {
			if(res.data){
				//refrescamos el listado de cursos
				this.getListadoGrafos(true);
			}
		})
	}

	changeOrdinalQuiz(idNode: number, i: number, j: number, k: number, direction: string){
		let from = k + 1; //ordinal origen
		let to = 0; //ordinal destino
		let k2 = 0; //indice destino
		switch (direction) {
			case 'up':
				to = k;
				k2 = k - 1;
				break;
			case 'down':
				to = k + 2;
				k2 = k + 1;
				break;		
			default:
				break;
		}
		// Intercambiar elementos
		[this.dataList[i].children[j].children[k], this.dataList[i].children[j].children[k2]] = [this.dataList[i].children[j].children[k2], this.dataList[i].children[j].children[k]];
		this.dataList[i].children[j].children[k].ordinal = to;
		this.dataList[i].children[j].children[k2].ordinal = from;
		this.quizService.changeOrdinalQuizzesNode(idNode, from, to).subscribe( res => {
			if(res.data){}
		})
	}
	/** =============== FIN LISTADO DE GRAFOS =============== */
	/** ===================================================== */

}
