import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { QuizElement } from 'src/app/core/models/quizzes/quiz-element.model';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';
import { TEMPLATE_ELEMENTS_TYPES } from 'src/app/core/utils/template-elements-types';
import { QuizPreviewBodyOptionComponent } from './quiz-play-body-option/quiz-play-body-option.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-open-quiz-preview',
    templateUrl: './open-quiz-preview.component.html',
    styleUrls: ['./open-quiz-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class OpenQuizPreviewComponent implements OnInit {
    @ViewChild('copiarText', {static: true}) copiarText: ElementRef;
    private questionTypes: QuizElementTypes[] = [
        QuizElementTypes.Q_TEXTS,
        QuizElementTypes.Q_AUDIOS,
        QuizElementTypes.Q_PDFS,
        QuizElementTypes.Q_PICTURES,
        QuizElementTypes.Q_VIDEOS
    ];

    private optionTypes: QuizElementTypes[] = [
        QuizElementTypes.O_TEXTS,
        QuizElementTypes.O_AUDIOS,
        QuizElementTypes.O_PDFS,
        QuizElementTypes.O_PICTURES,
        QuizElementTypes.O_VIDEOS
    ];

    private answerTypes: QuizElementTypes[] = [
        QuizElementTypes.A_TEXTS,
        QuizElementTypes.A_AUDIOS,
        QuizElementTypes.A_PDFS,
        QuizElementTypes.A_PICTURES,
        QuizElementTypes.A_VIDEOS
    ];

    @Output() answeredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() quiz: QuizModel;
    @Input() elements: any[];
    @Input() courseId: number;
    @Input() graphId: number;
    public questions: any[] = [];

  private automatic: boolean = false;

  answered: boolean = false;

  private _newColor: string;


  evaluate: Subject<boolean> = new Subject<boolean>();
  evaluate$: Observable<boolean> = this.evaluate.asObservable();

  playNext: Subject<boolean> = new Subject<boolean>();
  playNext$: Observable<boolean> = this.playNext.asObservable();

  tryClose: Subject<boolean> = new Subject<boolean>();
  tryClose$: Observable<boolean> = this.tryClose.asObservable();
  quizType: number;
  openTime: number;
  options: any[];
  answers: any[];
  disabledButton: boolean  = true;
  elementsCorrect: { idTemplateElement: any; idTemplate: any; elementsType: any; xPosition: any; yPosition: any; xSize: any; ySize: any; style: string; icon: string; data: any; }[];
  elementsQP: { idTemplateElement: any; idTemplate: any; elementsType: any; xPosition: any; yPosition: any; xSize: any; ySize: any; style: string; icon: string; data: any; responseCheck: any; }[];
  template: any;
  iniBlock: boolean;
  @ViewChild(QuizPreviewBodyOptionComponent) optionsComponent: QuizPreviewBodyOptionComponent;

	constructor(private activeModal: NgbActiveModal, private toaster: ToasterService, private translateService: TranslateService,) { }
  ngOnInit() {
    this.quizType = Number(this.quiz.quizType)
    this.openTime = Date.now();
    if(this.elements !== undefined){
        this.questions = this.elements.filter(
            e => this.questionTypes.includes(e.elementType)
        ).sort((a, b) => a.yPosition - b.yPosition);

        this.options = this.elements.filter(
            e => this.optionTypes.includes(e.elementType)
        ).sort((a, b) => a.yPosition - b.yPosition);

        this.answers = this.elements.filter(
            e => this.answerTypes.includes(e.elementType)
        ).sort((a, b) => a.yPosition - b.yPosition);
    }
    // this.showClock(this.quiz);
  }


  coiparId(action: string){
        var content =  this.copiarText.nativeElement.value;

        navigator.clipboard.writeText(content)
            .then(() => {
                this.toaster.success('Identificador copiado en el portapeles');
        })
            .catch(err => {
							this.toaster.error('Identificador copiado en el portapeles');
        })

  }


  onEvaluate(result: boolean) {
      this.evaluate.next(result);
  }

  onPlayNext() {
      this.playNext.next(true);
  }

  onAnswered() {
      this.answered = true;
  }

  onColorChanged(newColor: string) {
      this._newColor = newColor;
  }

  onClose() {
      if (this.answered) {
          // this.activeModal.close(this._newColor);
          return;
      }

      this.tryClose.next(true);
  }

    discoverAnswer(event) {
      this.answered = true;
      this.answeredChange.emit(true);
    }

    evaluationNegative() {
        this.answeredChange.emit(true);
        this.evaluateResponse(false);
    }

    evaluationPositive() {
        this.answeredChange.emit(true);

        this.evaluateResponse(true);
    }

    evaluateResponse(result: boolean) {
        this.disabledButton = true;
        const files = this.getAnswerFiles();
    }

    showQuiz(quiz: any) {
        this.answered = false;
        this.answeredChange.emit(false);
        this.quiz = quiz;
        this.template = undefined;

        this.elementsQP = [...quiz.template].filter(e => e.elementsType.idElementType <= 9).map(e => ({
            idTemplateElement: e.idTemplateElement,
            idTemplate: e.idTemplate,
            elementsType: e.elementsType,
            xPosition: e.xPosition,
            yPosition: e.yPosition,
            xSize: e.xSize,
            ySize: e.ySize,
            style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
            icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
            data: e.templateElementQuizz[0] ? e.templateElementQuizz[0].data : undefined,
            responseCheck: e.templateElementQuizz[0] ? e.templateElementQuizz[0].responseCheck : undefined,
        }));

        this.elementsCorrect = [...quiz.template].filter(e => e.elementsType.idElementType > 9).map(e => ({
            idTemplateElement: e.idTemplateElement,
            idTemplate: e.idTemplate,
            elementsType: e.elementsType,
            xPosition: e.xPosition,
            yPosition: e.yPosition,
            xSize: e.xSize,
            ySize: e.ySize,
            style: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].name,
            icon: TEMPLATE_ELEMENTS_TYPES[e.elementsType.idElementType].icon,
            data: e.templateElementQuizz[0] ? e.templateElementQuizz[0].data : undefined
        }));

        this.template = {
            idTemplate: this.elementsQP[0] ? this.elementsQP[0].idTemplate : undefined,
            idQuiz: quiz.template.idQuiz,
            templateTittle: quiz.quizTittle,
            quizInstructions: quiz.quizInstructions,
            writable: quiz.writable,
            compuCorrect: quiz.compuCorrect,
            multiplexed: quiz.multiplexed,
            elementsQP: this.elementsQP,
            elementsCorrect: this.elementsCorrect
        };
    }


    private getAnswerFiles(): any {
    }




    closeModal(sendData?: any) {
        this.activeModal.close('');
    }

    onQuizPlayElementChecked(value: boolean, idTemplateElement: number) {
        if (value) {
            // this.optionCheckedId = [idTemplateElement, ...this.optionCheckedId];
        } else {
            // this.optionCheckedId = [...this.optionCheckedId.filter(e => e !== idTemplateElement)];
        }
    }

    onQuizPlayElementFileUploaded(element, eventFile) {
        element.data = URL.createObjectURL(eventFile.target.files[0]);
        element.file = eventFile.target.files[0];
    }



    resumeInterval() {
        let date = new Date();

    }

    saveTxt(text){
    }

    public onSaveOption(option: any) {


    }

    //Obtener extencion del archivo
    getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    private elementToQuizElement(element: any): QuizElement {
        const quizElement: QuizElement = {
            idQuizzesDataElements: element.id,
            data: element.data,
            idQuiz: this.quiz.idOriginal,
            elementType: element.type,
            responseCheck: +element.isCorrect,
            xPosition: 0,
            xSize: 0,
            yPosition: element.order,
            ySize: 0
        };

        return quizElement;
    }

    successOrNotSuccessful(succes: boolean){
        this.answeredChange.emit(true);

        succes? this.toaster.success(this.translateService.instant('QUIZZES.MANUALOK')): this.toaster.success(this.translateService.instant('QUIZZES.MANUALNOK'));
        this.evaluateResponse(succes);
    }

    public get quizTypes() {
        return QuizTypes;
    }

}
