import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DEFAULT_TEACHER_PROMPT } from 'src/app/core/models/masters/masters.enum';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';

@Component({
    selector: 'app-quiz-prompt-edit',
    templateUrl: './quiz-prompt-edit.component.html',
    styleUrls: ['./quiz-prompt-edit.component.scss'],
    standalone: false
})
export class QuizPromptEditComponent implements OnInit, OnDestroy {


    public form: UntypedFormGroup;
    isLoading: boolean = false;
		idCourse: number;
		idTarget: number;
		idQuiz: number;
		idQuizPrompt : number = 0;
		private destroy$ = new Subject();


	constructor(
		public graphServ: GraphService,
		public nodeService: NodeService,
		public quizService: QuizzesService,
		private fb: UntypedFormBuilder,
		public activeModal: NgbActiveModal,) {
		this.form = this.fb.group(
			{
				promptText: [DEFAULT_TEACHER_PROMPT, Validators.required],
			}
		);
	}

    ngOnInit() {
			this.quizService.getQuizPrompt(this.idCourse, this.idTarget, this.idQuiz).pipe(takeUntil(this.destroy$), take(1)).subscribe((response: any) => {
				if (response.data != null) {
					this.form.get('promptText').setValue(response.data.promptText);
					this.idQuizPrompt = response.data.idQuizPrompt;
				}
			 } );
    }

    ngOnDestroy(): void {
			this.destroy$.next(true);
    }


		descargarPDF() {
			const fileName = 'InstruccionesParaPrompts.pdf';
			const filePath = 'assets/files/' + fileName;

			const link = document.createElement('a');
			link.href = filePath;
			link.download = fileName;

			// Trigger the download
			link.click();
		}


    public save() {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            this.isLoading = true;
						const data = {

							"idQuizPrompt":this.idQuizPrompt,

							"idCourse": this.idCourse,

							"idTarget": this.idTarget,

							"idQuiz":this.idQuiz,

							"promptText":this.form.get('promptText').value,

							}
            this.quizService.createUpdatePrompt(data).pipe(takeUntil(this.destroy$), take(1)).subscribe( (response: any) => {
								this.activeModal.close(true);
						}
						, (error: any) => {
								console.log(error);
						});
        }
    }


    closeModal(sendData?: any) {
				this.save();
    }


}
