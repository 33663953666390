import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-conectar-nodo',
    templateUrl: './modal-conectar-nodo.component.html',
    styleUrls: ['./modal-conectar-nodo.component.scss'],
    standalone: false
})
export class ModalConectarNodoComponent implements OnInit {
    public lines:string[]=["line","curve","arrow","arrowCurve","branch"];
    public selectedOption: string = '0';
    public line: string = '0';
    public size:number;
    public color:string="#448eca";
    public label:string;
    public datos:any[]=[];
    @Output() conectarNodo = new EventEmitter<any[]>();

      // HTML vars
  public connectN1 = { id: '', url: '', label: ''};
  public connectN2 = { id: '', url: '', label: ''};

  constructor(public modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  Cancelar() {
    this.modalService.dismissAll();
  }

  Conectar(){
    this.datos['line'] = this.line;
    this.datos['size'] = this.size;
    this.datos['color'] = this.color;
    this.datos['label'] = this.label;
    this.ConectarNodos(this.datos);
    /*this.conectarNodo.emit(this.datos);
    this.modalService.dismissAll();*/
  }

  Mostrar(){
    this.line = this.selectedOption;
  }

  changeConnectOrderItems(){
    var n1 = this.connectN1;
    this.connectN1 = this.connectN2;
    this.connectN2 = n1;
  }

  ConectarNodos(datos:any){
    const response = {
        result: 'OK',
                datos
      };
      this.closeModal(response);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

}
