import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from 'src/app/core/services/task';

@Component({
    selector: 'app-head-competition-groups',
    templateUrl: './head-competition-groups.component.html',
    styleUrls: ['./head-competition-groups.component.scss'],
    standalone: false
})
export class HeadCompetitionGroupsComponent implements OnInit {
    @Input() idCurso:any;
    task:any;
    constructor(public activeModal: NgbActiveModal,
                public taskService:TaskService) {
                  this.taskService.task.subscribe((t) => {
                  this.task = t;
         }); }

    ngOnInit() {
    }

    closeModal(sendData?: any) {
      this.activeModal.close(sendData);
    }
}
