import { uiState } from './../../../../store/models/ui-state.model';
import { graphData } from './../../../../store/models/graph-data.model';
import { selectGraphData } from './../../../../store/selectors/graph-data.selector';
import { url } from 'inspector';

import { catchError, concatMap, delay, filter, takeUntil } from 'rxjs/operators';
import { SigmaToolbarsService } from './../../../../core/services/sigma-toolbars/sigma-toolbars.service';
import { CourseEditorsModel } from './../../../../core/models/courses/course-editors.model';
import { gEdge } from './../../../../core/models/graph/gEdge.model';
import { BackgroundUtils } from './utils/background.utils';
import { NodeOnTopResponse, QuizModel } from 'src/app/core/models/quizzes';
import { NodeUtils } from './utils/node.utils';
import { QuizUtils } from './utils/quiz.utils';
import { StickerUtils } from './utils/sticker.utils';
import { VisCanvasUtils } from './utils/canvas.utils';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { SigmaUtils } from 'src/app/core/utils/sigma-utils';
import { GraphService } from 'src/app/core/services/graph/graph.service';
// Components
import { gNode, ObjetoNodoAuto } from 'src/app/core/models/graph/gNode.model';
import { NodeService } from 'src/app/core/services/node/node.service';
import { PowerService } from 'src/app/core/services/power/power.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';
import { MatDialog } from '@angular/material/dialog';
import { ModalAceptarCancelarComponent, ModalComponent } from 'src/app/shared/components/modal';
import { finalize, map, take } from 'rxjs/operators';
import { NodeLinkComponent } from '../node-link/node-link.component';
import { TranslateService } from '@ngx-translate/core';
import { SIGMA_CONSTANTS, MULTIPLEXEDQUIZZES } from 'src/app/core/utils/sigma-constants';
import { VisCanvasService } from './vis-canvas.service';
import { forkJoin, from, Observable, of, Subject, Subscription } from 'rxjs';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { NodeCoverPipe } from 'src/app/shared/pipes/node-cover.pipe';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActionModel } from 'src/app/core/models/shared/actions.model';
import { NodeModeAutoComponent } from '../../../../shared/components/nodes/node-auto/node-mode-auto.component';
import { StickersService } from 'src/app/core/services/stickers/stickers.service';
import { QuizEditMultipleComponent } from '../quiz-edit-multiple/quiz-edit-multiple.component';
import { CoursesService } from 'src/app/core/services/courses';
import { User } from 'src/app/core/models/users/user.models';
import { MastersService } from 'src/app/core/services/masters';
import { PointNodeService } from 'src/app/core/services/point-node/point-node.service';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { CanvasData, Estado } from 'src/app/core/models/tuturial/tutorial.model';
import { Utils } from 'src/app/core/utils/utils';
import {
	ActivatedRoute,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	Event,
} from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { ModalCursosListadoComponent } from 'src/app/shared/components/cursos/modal-cursos-listado';
import { environment } from 'src/environments/environment';
import { DomainTypes } from 'src/app/core/models/masters/masters.enum';
import { NodeConnectModel, OptionsNodeConnectModel } from './vis-canvas.model';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ACTIONS } from 'src/app/core/utils/actions';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { ModalProfileActionsComponent } from '../../../../shared/components/modal-profile-actions/modal-profile-actions.component';
import { UsersService } from 'src/app/core/services/users';
import { LocalStorage } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { InactivityService } from 'src/app/core/services/inactivity/inactivity.service';
import { settings } from 'cluster';
import { TargetsService } from 'src/app/core/services/targets';
import { SosService } from 'src/app/core/services/sos/sos.service';
import { MeetingsComponent } from 'src/app/pages/meetings/meetings.component';
import { NbWindowService } from '@nebular/theme';
import { ModalIdeasOrderComponent } from '../../modal-ideas-order/modal-ideas-order.component';
import { ModalCursoDetailComponent } from '../../cursos/modal-curso-detail/modal-curso-detail.component';
import { SalwizarContenidoComponent } from '../../salwizar-contenido/salwizar-contenido.component';
import { ModalGeneratedIdeasComponent } from '../../salwizar-contenido/modal-generated-ideas/modal-generated-ideas.component';
import { SalwizarQuizzesComponent } from '../../salwizar-quizzes/salwizar-quizzes.component';
import { ControlPanelComponent } from '../../control-panel/control-panel.component';
import { DataSet, Network, Options } from "vis-network/standalone";
import { image } from 'html2canvas/dist/types/css/types/image';
declare var $: any;

import { loadGraphData } from "src/app/store/actions/graph-data.action";
import { Store } from '@ngrx/store';
import { State } from "src/app/store/models/store.model";
import { updateUiState } from 'src/app/store/actions/ui-state.action';
import { ManageGraphShapeComponent } from '../../manage-graph-shape/manage-graph-shape.component';
import { ManageIdeasOptionsComponent } from '../../manage-ideas-options/manage-ideas-options.component';
@Component({
	selector: "app-vis-canvas",
	templateUrl: "./vis-canvas.component.html",
	styleUrls: ["./vis-canvas.component.scss"],
	providers: [ImagenPipe],
	standalone: false
})
export class VisCanvasComponent implements OnInit, OnChanges {
	@ViewChild("modalConnect") modalConnect: ModalComponent;
	@ViewChild("modalAlert") modalAlert: ModalComponent;
	@Output() nextEvenCopilot = new EventEmitter<number>();

	@Input() idCurso: number;
	@Input() tutorialGrafo: CanvasData;
	@Input() idMapa: number;
	@Input() canEdit: boolean = false;
	@Input() isGuest: boolean = false;
	@Input() background: string;
	@Input() changedToEditor: boolean;
	@Input() graphTitle: string;
	@Input() defaultViewType: number;
	nodeWasSelected: boolean = false;
	isDemo: boolean = environment.domain === DomainTypes.DEMO ? true : false;
	loginRoute = "/auth/login";
	register = "/auth/sign-up";

	//private sigmaUtils: SigmaUtils;
	private eventSubscriptions = [];
	public currentGraph;
	private selection = [];
	listQuiz: any = [];
	public ModoAuto: ObjetoNodoAuto;
	public powerListString = {
		power0: "power0",
		power1: "power1",
		power2: "power2",
		power3: "power3",
		powerNegative1: "powerNegative1",
		powerNegative2: "powerNegative2",
		powerNegative3: "powerNegative3",
	};
	public nextBackNode = { avanzar: false, regresar: false };
	zoomNode: number = 0;
	isFooterOpen = true;
	explicacionesQuizzes: any = [];
	ordenSeleccionado: number[] = [0];
	paginaSeleccionada: number = 0;
	usuario: User;
	tutorialSW: any;
	version: string = "";
	imagePlaceHolder: any;
	description: string;
	infoNodeTitlePlaceHolder: string;
	idElements: gNode[];
	anteriorNodo: gNode;
	nodeClickPopover: gNode;
	countHelp: number = 0;
	mostrarAyuda: boolean;
	mostrarAyudaQuiz: boolean;
	countHelpQuiz: number = 0;
	buscarNodo: boolean = false;
	isNode: boolean = false;
	isRented: boolean = false;
	isModalOpen: boolean = false;
	modoTraerNodo: boolean = false;
	modoTraerActividad: boolean = false;
	public formulario: UntypedFormGroup;
	lastNodeId: any = "";
	viewTypeGraph = { viewType: 0, set: false };
	user: User;
	userIsEditor: boolean;
	nextEnabled: boolean = true;
	backEnabled: boolean = false;
	selectedGraph: any;
	userIsBeingQuestioned: boolean = false;
	keepCheckingSos: boolean;
	intervalId: NodeJS.Timeout;
	savedInterval: any;
	generatedQuizzes: {
		Numero: number;
		Titulo: string;
		Pregunta: string;
		Opciones: { Correcta: string; Incorrecta1: string; Incorrecta2: string };
	}[];
	previewNodesSubscription: Subscription;
	designIdeasWindowOpen: boolean = false;
	openedWindow: import("@nebular/theme").NbWindowRef<any, any>;
	realIndex = [];
	isSOSClicked: boolean = false;
	generatedIdeasExists: boolean = false;
	treeData: { nodes: any[]; edges: any; };
	visOptions: Options;
	uiState: uiState;
	actualGraphData: { nodes: any; edges: any; };
	userIsAutor: boolean = false;
	lastClickedCoords: any;
	courseIdSubject: number;
	courseInfo: any;
	public get selectedNodes(): any[] {
		return this.selection;
	}

	// Mode
	addMode: boolean = false;
	infoMode: boolean = false;
	selectionMode: boolean = true;
	connectMode: boolean = false;
	disconnectMode: boolean = false;

	infoNodeTitle: string = "";
	infoNodeDesc: string = "";
	overNode: boolean = false;
	clickNode: boolean = false;
	rightclickNode: boolean = false;
	clickEdge: boolean = false;
	overEdge: boolean = false;

	startDragCoords: any;
	private showStaircase: boolean = false;

	isShowPads: boolean = false;
	isShowLabels: boolean = false;

	// Detectar si se hace drag del fondo del canvas.
	isDragging: boolean = false;
	clickStageCoords: any;

	// Dibujar Ejes
	drawAxis: boolean = false;

	public viewType = {
		nodes: true,
		quizzes: true,
	};

	private hiddedSticker = null;
	public progress: number = 0;

	// HTML vars
	public connectN1: NodeConnectModel = {
		id: "",
		idOriginal: 0,
		url: "",
		label: "",
	};
	public connectN2: NodeConnectModel = {
		id: "",
		idOriginal: 0,
		url: "",
		label: "",
	};

	public connectIcon = "link";
	public loading = false;
	public g;

	//INI QUIZ TIENE TEMPLATE
	templateDatos: any[] = [];
	isLoading: boolean = false;
	courseTitle: string = "";
	actualProfile: string;
	profiles = Profiles;
	private _profileSubscription: Subscription;
	private _showModalSubscription: Subscription;
	private _updateGraph: Subscription;

	private _sizeInterval: any;
	modePractice = false;
	public loadInitialGraph: boolean;
	initialGraphs = [889797, 888958];
	proyectoControl: boolean = false;
	public network: Network;
	public selectedNode: gNode;
	public nodes: any[];
	public edges: any[];
	private destroy$ = new Subject();

	@ViewChild("visContainer", { static: true })
	sigmaContainer: ElementRef<HTMLElement>;
	@ViewChild("nodeQuizzEditPopover", { static: true })
	nqEditPopover: ElementRef<HTMLElement>;
	@ViewChild("nodeQuizzPlaceHolderPopover", { static: true })
	nqPlaceholderPopover: ElementRef<HTMLElement>;
	@ViewChild("pActions", { static: true }) pActions: ElementRef<HTMLElement>;
	constructor(
		private graphServ: GraphService,
		private powerServ: PowerService,
		private pointNodeService: PointNodeService,
		private nodeService: NodeService,
		private quizService: QuizzesService,
		private courseService: CoursesService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private toolsService: SigmaToolbarsService,
		public loginService: LoginService,
		public dialog: MatDialog,
		private toaster: ToasterService,
		private translateService: TranslateService,
		private sigmaCanvasService: VisCanvasService,
		private nodeCoverPipe: NodeCoverPipe,
		private stickersServices: StickersService,
		private masterServices: MastersService,
		private utils: Utils,
		public scUtils: VisCanvasUtils,
		public nodeUtils: NodeUtils,
		private stickerUtils: StickerUtils,
		public quizUtils: QuizUtils,
		public bgUtils: BackgroundUtils,
		private formBuild: UntypedFormBuilder,
		private padsUtils: PadsUtils,
		public usersService: UsersService,
		private localStorage: LocalStorage,
		private inactivityService: InactivityService,
		private sosService: SosService,
		public targetsService: TargetsService,
		private windowService: NbWindowService,
		private quizzesService: QuizzesService,
		private cdr: ChangeDetectorRef,
		private store: Store<State>,
	) //public discoverOrder: DiscoverOrder
	{
		this.user = this.loginService.getUser();
		this.inactivityService.startInactivityTimer();
	}
	// Escuchar eventos de actividad en el componente y reiniciar el temporizador.
	@HostListener("document:click", ["$event"])
	//@HostListener('document:mousemove', ['$event'])
	@HostListener("document:keypress", ["$event"])
	onActivity(event: MouseEvent | KeyboardEvent) {
		this.inactivityService.resetInactivityTimer();
	}

	ngOnInit() {
		this.proyectoControl = this.localStorage.getItem("proyectoControl")
			? JSON.parse(this.localStorage.getItem("proyectoControl"))
			: false;
		this.designIdeasWindowOpen = false;
		this.loadInitialGraph = JSON.parse(
			this.localStorage.getItem("loadInitialGraph")
		);
		this.sigmaCanvasService.getIsLoading().subscribe((res: boolean) => {
			this.isLoading = res;
			this.cdr.detectChanges();
		});
		//this.initComponent();
		this.formulario = this.formBuild.group({ certificadoQuiz: [false] });
		//this.showProfileActionsModal();
		this.actualProfile = this.loginService.getProfile();
		this.nodeService.getRedirectedNode().subscribe((node: gNode) => {
			if (Object.keys(node).length != 0) {
				//this.playNode(node);
			}
		});

		this.nodeService.getRedirectedQuiz().subscribe((quiz: any) => {
			if (Object.keys(quiz).length != 0) {
				//this.playQuiz(quiz);
			}
		});

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show loading indicator
			}

			if (event instanceof NavigationEnd) {
				this.powerServ.emitPowerActive([-3, -2, -1, 0, 1, 2, 3]);
				//this.initComponent()
			}

			if (event instanceof NavigationError) {
				// Hide loading indicator

				// Present error to user
				console.log(event.error);
			}
		});
		this.checkIfUserHasPendingPetitions();
		this.previewNodesSubscription = this.sigmaCanvasService
			.getPreviewNode()
			.subscribe((res: string) => {
				if (res == "none") {
					return;
				}

				if (res != "none") {
					if (res == "single") {
						//this.addSelectedIANodeToCanvas();
					} else {
						//this.previewNodesInCanvas(res);
					}
				}
			});

		this.sigmaCanvasService
			.getOpenDesignIdeasWindow()
			.subscribe((res: boolean) => {
				if (res == true) {
					//this.openDesignIdeas();
				}
			});

		this.generatedIdeasExists = this.localStorage.getItem("IAGeneratedIdeas")
			? true
			: false;
		this._getInfoCourse();
		this.getOptions();
		this.getUiState();
		this.getTreeData();

		this.userIsAutor = this.loginService.esAutor();

	}

	loadVisTree(treedata) {
		var canvas = document.getElementById("vis-container");
		this.network = new Network(canvas, treedata, this.visOptions);
		//Si tenemos zoom guardado, lo aplicamos.
		if (this.selectedGraph.zoomX != 0) {
			this.network.moveTo({
				position: { x: this.selectedGraph.zoomX, y: this.selectedGraph.zoomY },
				scale: this.network.getScale() * this.selectedGraph.zoomRatio - 0.5,
			});
		}

		const nodesDataSet: any = new DataSet(treedata.nodes);
		const edgesDataSet: any = new DataSet(treedata.edges);

		this.network.on('beforeDrawing', function (ctx) {
			edgesDataSet.forEach((edge: any) => {
				if (edge.type === 'branch') {
					const fromNode = nodesDataSet.get(edge.from);
					const toNode = nodesDataSet.get(edge.to);
					const fromX = fromNode.x;
					const fromY = fromNode.y;
					const toX = toNode.x;
					const toY = toNode.y;

					// Set the initial and end widths
					const initialWidth = edge.width || 25;
					const endWidth = edge.endWidth || 1;
					const numSegments = 20; // Number of segments for smoothness

					// Calculate the step decrease in width
					const widthStep = (initialWidth - endWidth) / numSegments;

					// Calculate control point for Bézier curve (adjust curvature with offset values)
					const controlX = (fromX + toX) / 2 + (toY - fromY) * 0.3; // Adjust 0.3 as needed
					const controlY = (fromY + toY) / 2 + (fromX - toX) * 0.3;

					for (let i = 0; i < numSegments; i++) {
						// Get position along the curve at this segment (t ranges from 0 to 1)
						const t1 = i / numSegments;
						const t2 = (i + 1) / numSegments;

						const segmentX1 = (1 - t1) * (1 - t1) * fromX + 2 * (1 - t1) * t1 * controlX + t1 * t1 * toX;
						const segmentY1 = (1 - t1) * (1 - t1) * fromY + 2 * (1 - t1) * t1 * controlY + t1 * t1 * toY;

						const segmentX2 = (1 - t2) * (1 - t2) * fromX + 2 * (1 - t2) * t2 * controlX + t2 * t2 * toX;
						const segmentY2 = (1 - t2) * (1 - t2) * fromY + 2 * (1 - t2) * t2 * controlY + t2 * t2 * toY;

						// Set gradient color for each segment
						const gradient = ctx.createLinearGradient(segmentX1, segmentY1, segmentX2, segmentY2);
						gradient.addColorStop(0, edge.color);
						gradient.addColorStop(1, edge.color);

						// Draw each segment with decreasing width
						ctx.beginPath();
						ctx.moveTo(segmentX1, segmentY1);
						ctx.lineTo(segmentX2, segmentY2);
						ctx.lineWidth = initialWidth - i * widthStep;
						ctx.strokeStyle = gradient;
						ctx.stroke();
					}
				}
			});
		});
	}

	getOptions() {
		this.store
			.select((store) => store.uiState.visConfig)
			.pipe(takeUntil(this.destroy$))
			.subscribe((options) => {
				this.visOptions = options;
			});
	}

	getUiState() {
		this.store
			.select((store) => store.uiState)
			.pipe(takeUntil(this.destroy$))
			.subscribe((uiState) => {
				this.uiState = uiState;
				if (this.network) {
					console.log("UISTATE: ", this.uiState);
					this.network.setOptions(this.uiState.visConfig);
					this.network.redraw();
				}

			});
	}

	getTreeData() {

		this.loading = true;
		this.isLoading = true;
		this.cdr.detectChanges();
		this.sigmaCanvasService.courseId = this.idCurso;
		this.sigmaCanvasService.graphId = this.idMapa;
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		this.courseService.getCourseById(this.idCurso).subscribe((res) => {
			this.canEdit = this.scUtils.userCanEditGraph(res.data.editores);
			this.sigmaCanvasService.course = res.data.courses;
		});

		//this.sigmaCanvasService.sigmaUtils = this.sigmaUtils;

		this.sigmaCanvasService
			.getGraphData(this.idCurso, this.idMapa, idGrupo)
			.subscribe(
				([o1, o2, o3, o4, o5]) => {
					const res = {
						nodes: o1.nodes.concat(o2.nodes),
						edges: o1.edges.concat(o2.edges),
						stickers: o3,
						selectedGraph: o5.data,
					};
					this.actualGraphData = { nodes: res.nodes, edges: res.edges };
					this.store.dispatch(new loadGraphData({ nodes: res.nodes, edges: res.edges }));

					this.currentGraph = { ...res };
					this.listQuiz = { ...this.currentGraph };
					this.modAutoPrototype(this.listQuiz);
					this.localStorage.setItem("nodesInCanvas", JSON.stringify(o1.nodes));
					this.version = o4.data;
					this.selectedGraph = { ...res };

					if (this.loginService.esEstudiante()) {
						let view = { nodes: true, quizzes: true };
						this.toolsService.changeVisibility(view);
						this.quizService.setAutomatic(true);
						this.setViewType();
					} else if (
						this.loginService.esProfesor() &&
						JSON.parse(this.localStorage.getItem("modePractice"))
					) {
						let view = { nodes: false, quizzes: true };
						this.toolsService.changeVisibility(view);
						this.quizService.setAutomatic(true);
						this.defaultViewType = 4;
						this.setViewType();
					}

					if (
						this.currentGraph.nodes.length ||
						this.currentGraph.stickers.length
					) {
						this.selectedGraph = res.selectedGraph;
						let clientResolution =
							window.screen.height * window.devicePixelRatio;
						let userThatFixedZoomResolution = res.selectedGraph.userResolution;
						let zommRatioCorrection =
							(Number(userThatFixedZoomResolution) / clientResolution) * 165;
						let zoomRatio;
						if (Number(userThatFixedZoomResolution) == clientResolution) {
							zoomRatio = res.selectedGraph.zoomRatio;
						} else if (Number(userThatFixedZoomResolution) < clientResolution) {
							zoomRatio =
								(res.selectedGraph.zoomRatio * zommRatioCorrection) / 100;
						} else if (Number(userThatFixedZoomResolution) > clientResolution) {
							zoomRatio = res.selectedGraph.zoomRatio * 1.4;
						}
						if (res.selectedGraph.zoomX != 0) {
							this.sigmaCanvasService.zoomX = res.selectedGraph.zoomX;
							this.sigmaCanvasService.zoomY = res.selectedGraph.zoomY;
							this.sigmaCanvasService.zoomRatio = zoomRatio;

						}
					}

					this.treeData = {
						nodes: [...res.nodes, ...res.stickers],
						edges: res.edges,
					};



					this.treeData.nodes.forEach((node) => {
						if (node.nodeType == "Node") {
							node.group = "images";
							node.size = 60;
							node.label = node.description;
							node.font = {
								size: 30,
								color: "red",
								face: "Arial",
								margin: { top: 10, right: 50, bottom: 30, left: 0 },
								background: "white",
								strokeWidth: 3,
								strokeColor: "#ffffff",
							};
						} else if (node.nodeType == "Quiz" && this.actualProfile == "AUTOR") {
							node.group = "Node";
							node.label = node.quizTittle;
							node.size = 160;
							node.heightConstraint = { minimum: 100, valign: "middle" };
							node.font = {
								size: 30,
								color: "black",
								face: "Arial",
								background: "white",
								strokeWidth: 3,
								strokeColor: "#ffffff",
							};
						} else if (node.nodeType == "Quiz" && this.actualProfile == "ESTUDIANTE") {
							console.log("Node is a quiz for student", node);
							node.group = "quiz";
							node.size = node.size;
						} else if (node.nodeType == "Sticker") {
							node.group = "sticker";
							node.image = node.url;
							node.size = node.sizeImg * 7.5;
						} else if (node.nodeType == "Text") {
							node.group = "sticker";
							node.image = node.url;
							node.size = node.sizeImg * 7.5;
						}
					});

					this.treeData.edges.forEach((edge) => {
						if (edge.type == 'curve') {
							edge.smooth = { type: "curvedCW", roundness: 0.5 };
							edge.font = { align: "middle" };
						}

						if (edge.type == 'arrow') {
							edge.arrows = { to: { enabled: true, scaleFactor: 1 } };
							edge.font = { align: "middle" };
						}
						if (edge.type == 'curvedArrow') {
							edge.smooth = { type: "curvedCW", roundness: 0.5 };
							edge.arrows = { to: { enabled: true, scaleFactor: 1 } };
							edge.font = { align: "middle" };
						}
						if (edge.type == 'branch') {
							edge.customDraw = true;
							edge.hidden = true; // Hide the edge because we gonna use a custom draw
							edge.font = { align: "middle" };
						}
						edge.from = edge.source;
						edge.to = edge.target;
						edge.value = edge.size
					});

					console.log("TREE DATA: ", this.treeData);
					this.loadVisTree(this.treeData); // RENDER STANDARD NODES WITH TEXT LABEL
					this.setNodeEvents();



				},
				(err) => {
					console.error("ERROR LOADING GRAPH: ", err);
					this.currentGraph = { nodes: [], edges: [], stickers: [] };
					this.loading = false;
				},
				() => {
					setTimeout(() => {
						this.loading = false;
						this.isLoading = false;
						this.cdr.detectChanges();
						this.isShowPads = this.utils.padsStatus.showPadsGraph;
						this.isShowLabels = this.utils.labelsStatus.showLabelsGraph;
					}, 100);
				}
			);

	}

	getSelectedNode(nodeID: number) {
		const nodeAry = this.actualGraphData.nodes.filter((node) => {
			return node.id === nodeID;
		});
		return nodeAry[0] || [];
	}

	setNodeEvents() {
		const that = this;

		// *** ON SELECT NODE EVENT ***
		this.network.on("selectNode", function (params) {
			console.log("SELECT NODE: ", params);
			that.nodeWasSelected = true; // Add this line
			if (params.nodes != undefined && params.nodes.length > 0) {
					const nodeID = params.nodes[0];
					that.selectedNode = that.getSelectedNode(nodeID);
					that.nodeClickPopover = that.selectedNode;

					if (that.selectedNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
							$(".pActions").hide();
							$(".popover3").hide();
							if (!params.event.srcEvent.ctrlKey) {
									that.openPopoverInfoNodo(that.selectedNode, params.pointer.DOM);
							}
					} else if (that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
							if (that.canEdit) {
									//ASI Sabemos si el quiz lo ha creado el dueño del grafo o es un quiz alquilado

									//if (e.data.node.user.idUser !== this.loginService.getUser().idUser) {
									//Alex: recoger la lista de editores y permitir editar, si no, entonces si es alquilado
									//se hardcodea en false hasta que se cambie
									if (false) {
											/**
											 * Mientras se desallora el backen se desarrolla Si el
											 * quiz es alquilado de momento mostraremos un mensaje  que no se puede editar
											 **/
											this.toaster.success(
													this.translateService.instant(
															"SIGMACOMPONENT.ACTIVITYSTUDENTMODE"
													)
											);
											that.playQuiz(that.selectedNode);
									} else {
											if (
													that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
													that.selectedNode.isMultiplexed ===
													MULTIPLEXEDQUIZZES.ISMULTIPLEXED
											) {
													that.editQuizMultiple(that.selectedNode);
											} else if (
													that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
													that.selectedNode.isMultiplexed ===
													MULTIPLEXEDQUIZZES.ISNOTMULTIPLEXED
											) {
													that.isModalOpen = true;
													that.quizUtils
															.editQuiz(that.selectedNode, that.countHelpQuiz)
															.finally(() => (that.isModalOpen = false));
											}
									}
							} else {
									if (
											that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
											that.selectedNode.isMultiplexed ===
											MULTIPLEXEDQUIZZES.ISMULTIPLEXED
									) {
											that.playQuiz(that.selectedNode);
									} else if (
											that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE &&
											that.selectedNode.isMultiplexed ===
											MULTIPLEXEDQUIZZES.ISNOTMULTIPLEXED
									) {
											that.playQuiz(that.selectedNode);
									}
							}
					} else if (
							that.selectedNode.nodeType === SIGMA_CONSTANTS.STICKER_TYPE ||
							that.selectedNode.nodeType === SIGMA_CONSTANTS.TEXT_TYPE
					) {
							const link: string = that.selectedNode.link;
							if (link) {
									const url = new URL(link);
									const courseRegex = /\/course\/(\d+)$/; // Expresión regular para encontrar el ID del curso
									const match = link.match(courseRegex); // Buscar el patrón en la URL

									if (match) {
											const idCurso = parseInt(match[1]); // Extraer el ID del curso del resultado de la coincidencia
											const modalRef = this.modalService.open(
													ModalCursoDetailComponent,
													{
															scrollable: false,
															windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
													}
											);
											modalRef.componentInstance.id = idCurso;
											modalRef.componentInstance.modoTraerNodo = undefined;
											modalRef.componentInstance.modoTraerActividad = undefined;
											modalRef.componentInstance.profileTeacher = false;
											modalRef.componentInstance.idGroup = 0;
											modalRef.componentInstance.actividadesFlash = false;
											modalRef.componentInstance.fromSticker_grafo = false;
											modalRef.result.then(
													(result) => {
															// Manejar el resultado del modal si es necesario
													},
													(reason) => {
															// Manejar la razón del cierre del modal si es necesario
													}
											);
									} else {
											if (window.location.hostname === url.hostname) {
													window.open(link, "_self");
											} else {
													window.open(link, "_blank");
											}
									}
							}
					}

					//that.selectNodeEmitter.emit({ selectedNode: that.selectedNode });
			}
	});
	this.network.on("click", function (params) {
			console.log("CLICK EVENT: ", params);
			// Add this condition
			if (that.nodeWasSelected) {
					that.nodeWasSelected = false;
					return;
			}

			that.lastClickedCoords = params.pointer.canvas;
			that.rightclickNode = false;
			that.connectMode = false;

			if (!that.isDragging && that.loginService.esAutor() && that.canEdit) {

					var node = that.graphServ.emptygNode();

					//add the temporal node to VIS data
					if (!that.treeData.nodes.some(n => n.id === 'temp')) {
							that.treeData.nodes.push(node);
					}
					//that.network.setData(that.treeData);

					that.clickNode = false;

					localStorage.setItem("mouseClick", JSON.stringify(pos));
			}



			if (that.loginService.esAutor() && that.canEdit) {
					$(".popover, .popover2").hide();
					$(".popover3").hide();
					if (!that.isDragging && !that.clickNode && !that.clickEdge) {
							that.addMode = true;
							// if ((this.addMode) && (!this.isDragging) && (!this.clickNode)) {    // ADD NEW NODE PANEL
							var pos = $("canvas").offset(); // $('canvas').position();
							var left = params.pointer.DOM.x //e.pageX;
							var top = params.pointer.DOM.y; //e.pageY;
							var height = $("#pActions").height();

							$("#pActions").css("left", left - pos.left + 15 + "px");
							$("#pActions").css("top", top - height / 2 - 10 + "px");

							$("#pActions").show();

							var width = $("#pActions").width();
							that._resizePopover(width);
					} else {
							that.addMode = false;
							//that.sigmaUtils.dropNode("temp");
							//if (that.showStaircase) that.sigmaUtils.drawAxis();
					}
			}
	});

		this.network.on("oncontext", (params) => {
			params.event.preventDefault(); // Prevent the default context menu from appearing

			const nodeId = this.network.getNodeAt(params.pointer.DOM);
			that.selectedNode = that.getSelectedNode(Number(nodeId));
			if (nodeId) {
				console.log(`Right-clicked on node ${nodeId}`);
				if (that.canEdit) {
					$(".popover").hide();
					$(".popover3").hide();
					window.oncontextmenu = function () {
						return false;
					};
					that.clickNode = false;
					that.overNode = false;
					that.connectMode = false;
					that.rightclickNode = true;
					that.isNode = false;
					if (!that.isDragging && that.selectedNode.id !== "temp") {
						var nqEditPopoverElement = that.nqEditPopover.nativeElement;
						nqEditPopoverElement.style.display = "block";

						if (that.selectedNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
							that.isNode = true;
							that.isRented =
								that.idMapa === that.selectedNode.idTargetCreation ? false : true; // asi sabemos si el nodo es alquilado
							that.infoNodeTitle =
								!that.selectedNode.label ||
									that.selectedNode.label == "" ||
									that.selectedNode.label.length < 1
									? that.translateService.instant("SIGMACOMPONENT.COURSENONAME")
									: that.translateService.instant("SIGMACOMPONENT.COURSE") +
									"'" +
									that.selectedNode.label +
									"'";
						} else if (that.selectedNode.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
							that.infoNodeTitle =
								!that.selectedNode.quizTittle ||
									that.selectedNode.quizTittle == "" ||
									that.selectedNode.quizTittle.length < 1
									? that.translateService.instant("SIGMACOMPONENT.QUIZNONAME")
									: that.translateService.instant("SIGMACOMPONENT.QUIZ") +
									"'" +
									that.selectedNode.quizTittle +
									"'";
							that.formulario.patchValue({
								certificadoQuiz: that.selectedNode.certifiedQuiz === 1 ? true : false,
							});
						} else if (that.selectedNode.nodeType === SIGMA_CONSTANTS.STICKER_TYPE) {
							that.infoNodeTitle = SIGMA_CONSTANTS.STICKER_TYPE;
						}

						var pos = params.pointer.DOM;
						nqEditPopoverElement.style.left = pos.x + "px";
						nqEditPopoverElement.style.top = pos.y + "px";
						$(".popover").placement = "auto";
						localStorage.setItem("nodeData", JSON.stringify(that.selectedNode));
					}
				}
			}
		});

		this.network.on("selectEdge", function (params) {
			//console.log("selectEdge Event:", params);
		});

		// Controla cuando se suelta un nodo, si está encima de otro al cual no está conectado muestra un modal para añadir la conexión
		this.network.on("dragEnd", (params) => {

			console.log("DRAG END EVENT: ", params);

			if (params.nodes.length > 0) {
				const draggedNodeId = params.nodes[0];
				const draggedNodePosition = that.network.getPositions(draggedNodeId)[draggedNodeId];
				const allNodes = new DataSet(that.treeData.nodes).get();
				const draggedNode = allNodes.find((node) => node.id === draggedNodeId);
				for (const node of allNodes) {
					if (node.id !== draggedNodeId) {
						const nodePosition = that.network.getPositions(node.id)[node.id];
						if (that.isOverlapping(draggedNodePosition, nodePosition)) {
							// Show modal to connect nodes or perform other actions
							that.showConnectNodesModal(draggedNodeId, node.id);
							break;
						}

					}
				}

				if (that.canEdit) {
					that.sigmaCanvasService.savePosition(
						draggedNode.idOriginal,
						draggedNodePosition.x,
						draggedNodePosition.y,
						draggedNode.nodeType,
						that.loginService.getUser().idUser,
						draggedNode.node
					);
				}
			}
		});

		this.network.on("zoom", function (params) {
			//when user zooms in or out, and if the canvas has backgorund
			// we going to adapt the background to the new zoom level applying css to give the sensation of zooming the background
			if (params.scale < 2) {
				that.bgUtils.adaptBackgroundToZoom(params.scale, params.pointer);
			}

		});




		// this.network.on("beforeDrawing", function(params){  });  // 'before/afterDrawing' fires too many times
		this.network.on("stabilized", function (params) {
			// returns Number of iterations it took; it's the closest thing to a rebuild complete event.
			console.log("REBUILT THE TREE - STABILIZED EVENT OCCURRED...");
		});
	}

	private _resizePopover(width: number) {
		let currentClass = "";
		$("#pActions").find(".w-20").length !== 0
			? (currentClass = ".w-20")
			: (currentClass = ".w-25");

		if (width >= 250 && width < 300) {
			$(`#pActions ${currentClass}`).addClass("w-50");
			$(`#pActions ${currentClass}`).removeClass("w-100");
		} else if (width < 250) {
			$(`#pActions ${currentClass}`).removeClass("w-50");
			$(`#pActions ${currentClass}`).addClass("w-100");
		} else $(`#pActions ${currentClass}`).removeClass("w-100 w-50");
	}

	isOverlapping(pos1, pos2) {
		const distance = Math.sqrt(Math.pow(pos1.x - pos2.x, 2) + Math.pow(pos1.y - pos2.y, 2));
		const threshold = 50; // Adjust this value as needed
		return distance < threshold;
	}

	showConnectNodesModal(node1Id, node2Id) {
		const node1 = this.actualGraphData.nodes.find((node) => node.id === node1Id);
		const node2 = this.actualGraphData.nodes.find((node) => node.id === node2Id);

		this.connectN1 = {
			id: node1.id,
			idOriginal: node1.idOriginal,
			url: node1.url,
			label: node1.label,
		};
		this.connectN2 = {
			id: node2.id,
			idOriginal: node2.idOriginal,
			url: node2.url,
			label: node2.label,
		};
		console.log(`Show modal to connect nodes ${node1Id} and ${node2Id}`);
	}

	sosIntervalHandler() {
		this.intervalId = setInterval(() => {
			if (this.userIsBeingQuestioned == false) {
				//this.checkIfSomeoneWannaHelp();
			}
		}, 3000);
	}

	public registrarNodo() {
		//tempNode get node with id 'temp' from VIS data
		var tempNode = this.treeData.nodes.find((n) => n.id === "temp");
		tempNode.user = this.loginService.getUser()
		tempNode.subject = { idSubject: this.courseIdSubject };
		tempNode.label = this.translateService.instant('NODOS.DEFAULTTITLE');
		tempNode.published = Date.now()
		tempNode.description = '';
		tempNode.x = this.lastClickedCoords.x;
		tempNode.y = this.lastClickedCoords.y;
		tempNode.originalX = this.lastClickedCoords.x;
		tempNode.originalY = this.lastClickedCoords.y;
		tempNode.type = SIGMA_CONSTANTS.NODE_TYPE_CIRCLE;
		tempNode.group = 'images';
		tempNode.size = 30;
		tempNode.originalColor = "#c9c9c9";
		tempNode.color = "#c9c9c9";
		tempNode.image = {
			url: this.nodeCoverPipe.transform(tempNode.pictureNode),
			clip: SIGMA_CONSTANTS.IMAGE_CLIP,
			scale: SIGMA_CONSTANTS.IMAGE_SCALE,
			unselected: this.nodeCoverPipe.transform(tempNode.pictureNode),
		};
		tempNode.url = this.nodeCoverPipe.transform(tempNode.pictureNode);

		this.addNewNodeToVis(tempNode)

		this.nodeUtils.registrarNodo(tempNode).subscribe(
			(res) => {
				switch (res.type) {
					case HttpEventType.Response:
						//remove temporal node from VIS data

						if (
							this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE
						) {
							this.countHelp++;
							if (this.countHelp === 2) {
								this.mostrarAyuda = true;
							}
						}
						this.addNewNodeToVis(res.body.data)
						this.editNode(res.body.data, true);
				}
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("NODEFORMCOMPONENT.ERROR")
				);
			}
		);
	}

	addNewNodeToVis(node) {
		//remove node with id 'temp' from VIS data
		this.treeData.nodes = this.treeData.nodes.filter((n) => n.id !== "temp");
		this.actualGraphData.nodes.push(
			node
		)
		this.network.setData(this.actualGraphData);
		this.network.redraw();
	}

	public editNode(node: gNode, isNewNode?: boolean) {
		console.log("EDIT NODE: ", node);
		this.isModalOpen = true;
		this.nodeUtils
			.editNode(
				node,
				isNewNode,
				this.canEdit,
				this.mostrarAyuda,
				this.tutorialGrafo
			)
			.then((value) => {
				if (value) this.tutorialCanvasGrafo(value);
			})
			.finally(() => (this.isModalOpen = false));
	}

	checkIfUserHasPendingPetitions() {
		this.sosService.userHasPendingPetitions().subscribe((res: any) => {
			this.sosIntervalHandler();
			if (res.data == true) {
				this.keepCheckingSos = res.data;
				if (this.keepCheckingSos == true) {
				} else if (this.keepCheckingSos == false) {
					clearInterval(this.intervalId);
				}
			} else if (res.data == false) {
				clearInterval(this.intervalId);
			}
		});
	}

	addSticker() {
		this.stickerUtils.addSticker();
	}

	addText() {
		this.stickerUtils.addText();
	}


	tutorialCanvasGrafo(step: number): void {
		this.nextEvenCopilot.emit(step);

		if (step === 5) {
			this.tutorialGrafo.nodoNodo = true;
		}
		if (step === 6) {
			this.tutorialGrafo.quizQuiz = true;
		}
	}

	setViewType() {
		switch (this.defaultViewType) {
			case 1:
				setTimeout(() => {
					this.toolsService.setHideElements();
				}, 500);
				this.viewTypeGraph.set = true;
				break;
			case 2:
				setTimeout(() => { }, 500);
				this.viewTypeGraph.set = true;
				break;
			case 3:
				setTimeout(() => {
					this.toolsService.changeVisibility({ nodes: true, quizzes: false });
				}, 200);
				this.viewTypeGraph.set = true;
				break;
			case 4:
				setTimeout(() => {
					this.toolsService.changeVisibility({ nodes: false, quizzes: true });
				}, 200);
				this.viewTypeGraph.set = true;
				break;
		}
	}

	private _getInfoCourse(): void {
		this.courseService.getCourseById(this.idCurso).subscribe((course) => {
			this.courseIdSubject = course.data.courses.subject.idSubject;
			//se hace que si el titulo es LanguageRounded, se acorta y se ponen ... suspensivos
			const title = course.data.courses.courseTittle;
			if (title.length > 24) this.courseTitle = title.substring(0, 22) + "...";
			else this.courseTitle = title;
		});
	}

	modAutoPrototype(listQuiz: any) {
		let currentPower = this.powerServ.getPower();

		//devuelve el objeto ya ordenado para recorrer en modo auto

		this.ModoAuto = new ObjetoNodoAuto(listQuiz.nodes, currentPower);

		this.idElements = this.ModoAuto.explicacionesPower0;
		this.graphServ.getFlickNode(this.idCurso, this.idMapa).subscribe(
			(res) => {
				if (res.data.idNode !== 0) {
					this.lastNodeId = "n" + res.data.idNode;
				}
				//this.susbcribeChangedPointNode();
			},
			(err) => {
				//this.susbcribeChangedPointNode();
			}
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.userIsEditor = this.loginService.esAutor();
		if (changes.background) {
			this.bgUtils.background = changes.background.currentValue;
			this.bgUtils.setGraphBackground(changes.background.currentValue);
		} else if (changes.canEdit) {
			this.scUtils.hidePopWindows();
			$("#pActions").hide();
		}
	}

	ngOnDestroy() {
		// this.previewNodesSubscription.unsubscribe();
		// this.loginService.currentProfile.next(null);
		// this._profileSubscription.unsubscribe();
		// this._updateGraph != undefined ? this._updateGraph.unsubscribe() : "";
		// this._showModalSubscription != undefined
		// 	? this._showModalSubscription.unsubscribe()
		// 	: "";
		// this.eventSubscriptions.forEach((s) => s.unsubscribe());
		this.destroy$.next(true);
	}

	updateViewType(data) {
		this.uiState.nodesVisibility = data;
		this.changeView(data);
		this.updateUiState();
	}

	/**
	 * Updates the visibility of nodes in the graph based on the provided data.
	 *
	 * @param data - An object containing visibility flags for different node types.
	 *   - `nodes`: A boolean indicating whether nodes of type "Node" should be visible.
	 *   - `quizzes`: A boolean indicating whether nodes of type "Quiz" should be visible.
	 *
	 * The method iterates over the nodes in `actualGraphData` and updates their `hidden` property
	 * based on the provided visibility flags. After updating the nodes, it sets the new data to the
	 * network and triggers a redraw.
	 */
	changeView(data) {
		this.actualGraphData.nodes = this.actualGraphData.nodes.map((node) => {
			if (node.nodeType == "Node") {
				node.hidden = !data.nodes;
			} else if (node.nodeType == "Quiz") {
				node.hidden = !data.quizzes;
			}
			return node;
		});

		this.network.setData(this.actualGraphData);
		this.network.redraw();
	}

	private triggerInitSigma() {
		this.sigmaCanvasService.triggerInitSigma.subscribe((res) => {
			if (res == true) {
				//this.initComponent(true);
			}
		});
	}

	/**
	 * Opens a popover with information about the selected node.
	 *
	 * @param selectedNode - The node that has been selected.
	 * @param pos - The position where the popover should be displayed.
	 *
	 * @remarks
	 * - Hides any existing popovers with the class "popover3".
	 * - Disables the default context menu.
	 * - If the selected node's ID is "temp", the function returns early.
	 * - If the selected node's type matches `SIGMA_CONSTANTS.NODE_TYPE`, it displays a popover with the node's information.
	 * - The popover's position is adjusted to ensure it stays within the bounds of the container.
	 *
	 * @internal
	 */
	openPopoverInfoNodo(selectedNode, pos) {
		$(".popover3").hide();
		window.oncontextmenu = () => false;

		if (selectedNode.id === "temp") return;

		if (selectedNode.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
			const nqPlaceholderElement = this.nqPlaceholderPopover.nativeElement;
			nqPlaceholderElement.style.display = "block";
			this.imagePlaceHolder = `url(${selectedNode.url})`;

			this.description = selectedNode.description.trim()
				? selectedNode.description
				: this.translateService.instant("SIGMACOMPONENT.WITHOUTDESCRIPTION");

			this.infoNodeTitlePlaceHolder = selectedNode.label?.trim()
				? selectedNode.label
				: this.translateService.instant("SIGMACOMPONENT.WITHOUTTITLE");

			const containerHeight = this.sigmaContainer.nativeElement.clientHeight;
			const containerWidth = this.sigmaContainer.nativeElement.clientWidth;

			nqPlaceholderElement.style.top = `${Math.min(Math.max(pos.y, 20), containerHeight - 150)}px`;
			nqPlaceholderElement.style.left = `${Math.min(pos.x + 20, containerWidth - nqPlaceholderElement.clientWidth - 30)}px`;
		}
	}

	private playQuiz(quiz) {
		this.scUtils.hidePopWindows();
		this.customModeAuto("quiz", quiz);
	}

	private editQuizMultiple(quiz: any) {
		this.isModalOpen = true;
		this.scUtils.hidePopWindows();
		this.quizService
			.getQuizMultiple(quiz.idOriginal, this.idCurso, this.idMapa)
			.subscribe((res) => {
				const modalRef = this.modalService.open(QuizEditMultipleComponent, {
					scrollable: false,
					windowClass: MODAL_DIALOG_TYPES.W100,
					backdrop: "static",
				});

				modalRef.componentInstance.quiz = {
					...res.quiz,
					user: quiz.user,
					idOriginal: quiz.idOriginal,
					id: quiz.id,
					ordinal: quiz.ordinal,
					originalX: quiz.originalX,
					originalY: quiz.originalY,
					size: quiz.size,
					sizeQuiz: quiz.sizeQuiz,
					x: quiz.x,
					y: quiz.y,
				};
				modalRef.componentInstance.elements = res.elements;
				modalRef.componentInstance.quizFiles = res.quizFiles;
				modalRef.componentInstance.quizzes = res.quizzes;
				modalRef.componentInstance.courseId = this.idCurso;
				modalRef.componentInstance.graphId = this.idMapa;

				modalRef.result.then().finally(() => (this.isModalOpen = false));
			});
	}

	actionInCanvas(idNode: string, anteriorNodo: gNode) {
		// if (this.lastNodeId !== "") {
		// 	if (anteriorNodo !== undefined && anteriorNodo.id !== idNode) {
		// 		// idNode = this.lastNodeId;
		// 		this.lastNodeId = idNode;
		// 	} else {
		// 		idNode = this.lastNodeId;
		// 	}
		// }
		// if (anteriorNodo !== undefined) {
		// 	this.sigmaUtils
		// 		.allNodes()
		// 		.filter((sigmaNode) => sigmaNode.id === anteriorNodo.id)
		// 		.map((value) => {
		// 			this.sigmaUtils.decrementNodeSize(value);
		// 		});
		// }
		// this.sigmaUtils
		// 	.allNodes()
		// 	.filter((sigmaNode) => sigmaNode.id === idNode)
		// 	.map(
		// 		(value) => {
		// 			clearInterval(this._sizeInterval);
		// 			this._sizeInterval = this.sigmaUtils.nodeAnimation(value);
		// 			this.anteriorNodo = { ...value };
		// 		},
		// 		(err) => {
		// 			console.log(err);
		// 		}
		// 	);
	}


	getActionFromPads(event: ActionModel) {
		switch (event.name) {
			case ACTIONS.ZOOMIN:
				console.log("ZOOMIN");
				this.network.moveTo({
					scale: this.network.getScale() * 1.1,
				});
				console.log("ZOOMIN", this.network.getViewPosition());
				break;
			case ACTIONS.ZOOMOUT:
				this.network.moveTo({
					scale: this.network.getScale() * 0.9,
				});
				break;
			case ACTIONS.CENTERGRAPH:
				if (event.value != true) {
					//TODO ajustar bien sobre todo el ratio para que encaje bien con el zoom anterior.
					this.network.moveTo({
						position: { x: event.value.xx, y: event.value.y },
						scale: this.network.getScale() * event.value.ratio - 0.5,
					});
				} else {
					this.network.fit();
				}
				break;
		}
	}


	onRightClick() {
		// Disable context menu.
		return false;
	}

	toggleFooter() {
		this.uiState.footerBarOpen = !this.uiState.footerBarOpen;
		this.updateUiState();
	}

	updateUiState() {
		this.store.dispatch(updateUiState({ uiState: this.uiState }));
	}

	openModalModeAuto() {
		this.scUtils.openModalModeAuto(
			this.ModoAuto,
			this.ordenSeleccionado,
			this.viewType
		);
	}

	public editElement() {
		// var e: any = JSON.parse(localStorage.getItem("nodeData"));
		// if (!this.isLoading) {
		// 	if (e.nodeType === SIGMA_CONSTANTS.NODE_TYPE) {
		// 		this.editNode(e);
		// 	} else if (e.nodeType === SIGMA_CONSTANTS.QUIZ_TYPE) {
		// 		if (this.canEdit) {
		// 			if (e.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED) {
		// 				this.editQuizMultiple(e);
		// 			} else {
		// 				this.isModalOpen = true;
		// 				this.quizUtils
		// 					.editQuiz(e, this.countHelpQuiz)
		// 					.finally(() => (this.isModalOpen = false));
		// 			}
		// 		} else {
		// 			this.playQuiz(e);
		// 		}
		// 	} else if (e.nodeType === SIGMA_CONSTANTS.STICKER_TYPE) {
		// 		if (this.canEdit) this.stickerUtils.editSticker(e);
		// 	} else if (e.nodeType === SIGMA_CONSTANTS.TEXT_TYPE) {
		// 		if (this.canEdit) this.stickerUtils.editText(e);
		// 	}
		// }
	}


	public customModeAuto(type: string, value: gNode | any) {
		let listQuiz = [];

		if (this.viewType.nodes && this.viewType.quizzes) {
			//Obtener listado de un power especifico.
			listQuiz = this.ModoAuto.obtenerListado(this.ordenSeleccionado);

			//para poder abris solo los quizzes cuando no hay nodos en el grafo
			if (listQuiz.length === 0) {
				listQuiz = this.ModoAuto.listadoSoloQuiz();
			}
		} else if (this.viewType.nodes) {
			listQuiz = this.ModoAuto.listSoloExplicaciones();
		} else if (this.viewType.quizzes) {
			listQuiz = this.ModoAuto.listadoSoloQuiz();
		}

		if (listQuiz.length > 0) {
			if (type == SIGMA_CONSTANTS.NODE_TYPE.toLowerCase()) {
				this.nodeService
					.getNode(value.idOriginal, this.idCurso, this.idMapa)
					.pipe(
						map((res: any) => res.data[0]),
						finalize(() => (this.isLoading = false))
					)
					.subscribe((res) => {
						const modalRef = this.modalService.open(NodeModeAutoComponent, {
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W100,
							backdrop: "static",
						});

						modalRef.componentInstance.listQuiz = [];
						modalRef.componentInstance.listQuiz = listQuiz;
						modalRef.componentInstance.node = res;
						modalRef.componentInstance.hideBar = true;
						modalRef.componentInstance.idCurso = this.idCurso;
						modalRef.componentInstance.idMapa = this.idMapa;
						modalRef.componentInstance.viewQuiz =
							this.viewType.quizzes && !this.viewType.nodes ? true : false;
						modalRef.componentInstance.soloQuiz =
							this.viewType.quizzes && !this.viewType.nodes ? true : false;
						modalRef.componentInstance.soloNodos =
							!this.viewType.quizzes && this.viewType.nodes ? true : false;
						modalRef.componentInstance.autoTodo =
							this.viewType.quizzes && this.viewType.nodes ? true : false;
						modalRef.componentInstance.currentGraph = listQuiz;
						modalRef.componentInstance.type =
							SIGMA_CONSTANTS.NODE_TYPE.toLowerCase();
						modalRef.componentInstance.course = this.sigmaCanvasService.course;
						modalRef.componentInstance.adjustWindow = true;
						modalRef.componentInstance.allItems = this.ModoAuto;
						modalRef.componentInstance.quiz = QuizModel;

						modalRef.result
							.then((res) => {
								if (res !== undefined) {
									this.lastNodeId = res.id;
									this.actionInCanvas(
										this.lastNodeId.toString(),
										(this.anteriorNodo = undefined)
									);
								}
							})
							.catch((res) => { });
					});
			} else if (type == SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase()) {
				if (value.isMultiplexed === MULTIPLEXEDQUIZZES.ISMULTIPLEXED) {
					if (this.loginService.esProfesor()) {
						// cuando sea profe traemos todos los hijos del quiz padre y mostramos el hijo 1
						let quizM = this.quizService
							.getQuizMultiple(value.idOriginal, this.idCurso, this.idMapa)
							.subscribe((res) => {
								//this.listQM = res.quizzes;
								const idQuizM = res.quizzes[0].quizMultiple.idQuizMultiple;
								this.quizService
									.getQuizMultipleCanvasQuizMultiple(
										idQuizM,
										this.idCurso,
										this.idMapa
									)
									.pipe(finalize(() => (this.isLoading = false)))
									.subscribe((res) => {
										const modalRef = this.modalService.open(
											NodeModeAutoComponent,
											{
												scrollable: true,
												windowClass: MODAL_DIALOG_TYPES.W100,
												backdrop: "static",
											}
										);
										modalRef.componentInstance.quiz = {
											...res.quiz,
											user: value.user,
											idOriginal: value.idOriginal,
											id: value.id,
											originalX: value.originalX,
											originalY: value.originalY,
											size: value.size,
											sizeQuiz: value.sizeQuiz,
											x: value.x,
											y: value.y,
										};
										modalRef.componentInstance.elements = res.elements;
										modalRef.componentInstance.courseId = this.idCurso;
										modalRef.componentInstance.graphId = this.idMapa;
										modalRef.componentInstance.hideBar = true;
										modalRef.componentInstance.listQuiz = [];
										modalRef.componentInstance.listQuiz = listQuiz;
										modalRef.componentInstance.firstQuiz = 1;
										modalRef.componentInstance.idCurso = this.idCurso;
										modalRef.componentInstance.idMapa = this.idMapa;
										modalRef.componentInstance.viewQuiz = true;
										modalRef.componentInstance.soloQuiz =
											this.viewType.quizzes && !this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.soloNodos =
											!this.viewType.quizzes && this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.autoTodo =
											this.viewType.quizzes && this.viewType.nodes
												? true
												: false;
										modalRef.componentInstance.currentGraph = listQuiz;
										modalRef.componentInstance.type =
											SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
										modalRef.componentInstance.adjustWindow = true;
										modalRef.componentInstance.course =
											this.sigmaCanvasService.course;
										modalRef.componentInstance.allItems = this.ModoAuto;
										modalRef.result
											.then((color) => {
												// do things after closing
											})
											.catch((res) => { });
									});
							});
					} else {
						this.quizService
							.getQuizMultipleCanvasQuizMultiple(
								value.idOriginal,
								this.idCurso,
								this.idMapa
							)
							.pipe(finalize(() => (this.isLoading = false)))
							.subscribe((res) => {
								const modalRef = this.modalService.open(NodeModeAutoComponent, {
									scrollable: true,
									windowClass: MODAL_DIALOG_TYPES.W100,
									backdrop: "static",
								});

								modalRef.componentInstance.quiz = {
									...res.quiz,
									user: value.user,
									idOriginal: value.idOriginal,
									id: value.id,
									originalX: value.originalX,
									originalY: value.originalY,
									size: value.size,
									sizeQuiz: value.sizeQuiz,
									x: value.x,
									y: value.y,
								};
								modalRef.componentInstance.elements = res.elements;
								modalRef.componentInstance.courseId = this.idCurso;
								modalRef.componentInstance.graphId = this.idMapa;
								modalRef.componentInstance.hideBar = true;
								modalRef.componentInstance.listQuiz = [];
								modalRef.componentInstance.listQuiz = listQuiz;
								modalRef.componentInstance.firstQuiz = 1;
								modalRef.componentInstance.idCurso = this.idCurso;
								modalRef.componentInstance.idMapa = this.idMapa;
								modalRef.componentInstance.viewQuiz = true;
								modalRef.componentInstance.soloQuiz =
									this.viewType.quizzes && !this.viewType.nodes ? true : false;
								modalRef.componentInstance.soloNodos =
									!this.viewType.quizzes && this.viewType.nodes ? true : false;
								modalRef.componentInstance.autoTodo =
									this.viewType.quizzes && this.viewType.nodes ? true : false;
								modalRef.componentInstance.currentGraph = listQuiz;
								modalRef.componentInstance.type =
									SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
								modalRef.componentInstance.adjustWindow = true;
								modalRef.componentInstance.course =
									this.sigmaCanvasService.course;
								modalRef.componentInstance.allItems = this.ModoAuto;

								modalRef.result
									.then((color) => {
										// do things after closing
									})
									.catch((res) => { });
							});
					}
				} else {
					this.quizService
						.getQuizSimpleCanvasQuizSimple(
							value.idQuiz ? value.idQuiz : value.idOriginal,
							this.sigmaCanvasService.courseId,
							this.sigmaCanvasService.graphId
						)
						.pipe(finalize(() => (this.isLoading = false)))
						.subscribe((res) => {
							const modalRef = this.modalService.open(NodeModeAutoComponent, {
								scrollable: true,
								windowClass: MODAL_DIALOG_TYPES.W100,
								backdrop: "static",
							});

							modalRef.componentInstance.quiz = {
								...res.quiz,
								promptText: res.promptText,
								user: value.user,
								idOriginal: value.idOriginal,
								id: value.id,
								originalX: value.originalX,
								originalY: value.originalY,
								size: value.size,
								sizeQuiz: value.sizeQuiz,
								x: value.x,
								y: value.y,
							};
							modalRef.componentInstance.rawQuiz = res;
							modalRef.componentInstance.elements = res.elements;
							modalRef.componentInstance.courseId = this.idCurso;
							modalRef.componentInstance.graphId = this.idMapa;
							modalRef.componentInstance.hideBar = true;
							modalRef.componentInstance.listQuiz = [];
							modalRef.componentInstance.listQuiz = listQuiz;
							modalRef.componentInstance.firstQuiz = 1;
							modalRef.componentInstance.idCurso = this.idCurso;
							modalRef.componentInstance.idMapa = this.idMapa;
							modalRef.componentInstance.viewQuiz = true;
							modalRef.componentInstance.soloQuiz =
								this.viewType.quizzes && !this.viewType.nodes ? true : false;
							modalRef.componentInstance.soloNodos =
								!this.viewType.quizzes && this.viewType.nodes ? true : false;
							modalRef.componentInstance.autoTodo =
								this.viewType.quizzes && this.viewType.nodes ? true : false;
							modalRef.componentInstance.currentGraph = listQuiz;
							modalRef.componentInstance.type =
								SIGMA_CONSTANTS.QUIZ_TYPE.toLowerCase();
							modalRef.componentInstance.adjustWindow = true;
							modalRef.componentInstance.course =
								this.sigmaCanvasService.course;
							modalRef.componentInstance.allItems = this.ModoAuto;
							modalRef.result
								.then((color) => {
									//do things after closing
								})
								.catch((res) => { });
						});
				}
			}
		} else
			this.toaster.success(
				this.translateService.instant("SIGMACOMPONENT.ERRORCONFIG")
			);
	}

	manageShapeGraph() {
		this.scUtils.hidePopWindows();
		const modalRef = this.modalService.open(ManageGraphShapeComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W50,
		});

		modalRef.result.then(
			(data: any) => {
				//this.initComponent(true);
			},
			(reason) => {
				//console.error("Can not select the sticker");
			}
		);
	}

	openDesignIdeas() {

		this.scUtils.hidePopWindows();

		let modalSelection = this.modalService.open(ManageIdeasOptionsComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalSelection.result.then((data: any) => {
			if (data == 'single') {
				//single
				this.registrarNodo()
			} else if (data == 'all') {
				this.sigmaCanvasService.setOpenDesignIdeasWindow(false)
				this.scUtils.hidePopWindows();

				if (this.openedWindow) {
					this.openedWindow.close()
				}

				let modalGenerateIdeas = this.modalService.open(ModalGeneratedIdeasComponent, {
					scrollable: true,
					windowClass: MODAL_DIALOG_TYPES.W30,
				});

				modalGenerateIdeas.componentInstance.curso = this.courseInfo.data.courses

			}
		});

	}

	autoLinkIdeas() {
		this.sigmaCanvasService.setPreviewNode("none");
		this.scUtils.hidePopWindows();


	}

	public createQuiz() {
		this.quizUtils.createQuiz().subscribe((res) => {
			this.addNewNodeToVis(res);
			if (this.loginService.getUser().tutorialSW.estado === Estado.PENDIENTE) {
				this.countHelp++;
				this.countHelpQuiz++;
				if (this.countHelp === 2) {
					this.mostrarAyuda = true;
				}
			}
			this.isModalOpen = true;
			this.quizUtils.editQuiz(res, this.countHelpQuiz).finally(() => this.isModalOpen = false)
		})
	}

	public createQuizMultiple() {
		this.scUtils.hidePopWindows();


		// tempSigmaNode.isMultiplexed = MULTIPLEXEDQUIZZES.ISMULTIPLEXED;
		// this.quizService
		// 	.createQuiz(this.idCurso, this.idMapa, tempSigmaNode)
		// 	.pipe(map((res: any) => res.data))
		// 	.subscribe((res) => {
		// 		this.addNewNodeToVis(res);
		// 		this.editQuizMultiple(res);
		// 	});
	}

	changeIdeasOrder(selectedGraph) {
			this.scUtils.hidePopWindows();
			const modalRef = this.modalService.open(ModalIdeasOrderComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W50,
			});

			modalRef.componentInstance.ideas = selectedGraph.nodes.filter(
				(node) => node.nodeType == "Node"
			);
			modalRef.componentInstance.idCurso = this.idCurso;
			modalRef.componentInstance.idMapa = this.idMapa;
			modalRef.result.then(
				(data: any) => {
					this.network.redraw();
					//window.location.reload()
				},
				(reason) => {
					console.error("Can not select the sticker");
				}
			);
		}


}
