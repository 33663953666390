import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { Subject } from 'rxjs';
import { SnapshotService } from 'src/app/core/services/snapshot/snapshot.service';
import { LoginService } from 'src/app/core/services/login';
import { ImagenPipe } from '../../pipes/imagen.pipe';

@Component({
    selector: 'app-modal-reproducir',
    templateUrl: './modal-reproducir.component.html',
    styleUrls: ['./modal-reproducir.component.scss'],
    providers: [ImagenPipe],
    standalone: false
})
export class ModalReproducirComponent implements OnInit {
    @Input() fileFrom: string;
    private element: any;
    public cargando: boolean;
    public format = "";
    private videoSource: any;
    private imageSource: any;
    private audioSource: any;
    private pdfSource: any;
    private textSource: any;
    @ViewChild('videoElement') videoElement: ElementRef;
    @ViewChild('imgElement') imgElement: ElementRef;
    @ViewChild('audioElement') audioElement: ElementRef;
    @ViewChild('pdfElement') pdfElement: ElementRef;
    @ViewChild('textoElement') textoElement: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    @ViewChild('downloadLink') downloadLink: ElementRef;

    public snapshotName: string = 'sample_snapshot';
    public userImageType: string = 'JPG';
    public imageTypes = ['JPG', 'PNG', 'BMP', 'TIFF', 'GIF', 'PPM', 'PGM', 'PBM', 'PNM', 'WebP', 'HEIF', 'BPG', 'ECW', 'FITS', 'FLIP', 'PAM', 'CD5', 'CPT', 'PSD', 'PSP', 'XCF', 'PDN'];

    public imagen = new Subject();

    constructor(public activeModal: NgbActiveModal,
        public quizService: QuizzesService,
        public snapshotService: SnapshotService,
        public loginServer: LoginService,
        private imagePipe: ImagenPipe) { }

    ngOnInit() {
        this.cargando = false;
        this.reproducirRecurso(this.element);
    }

    private reproducirRecurso(element: any) {
        this.cargando = true;
        this.obtenerRecurso(element);
        this.cargando = false;
        /* this.cargando=true;
         var subscribe = this.quizService.getTemplatesElementQuizzesByIdElement(element.idTemplateElement).subscribe(
         (data)=>{
             if(data.data.templatesElementQuizzes.nodes.length>0){
                 var dataElement=data.data.templatesElementQuizzes.nodes[0];
                 this.obtenerRecurso(element,dataElement);
             }
             this.cargando=false;
             subscribe.unsubscribe();
         },
         (error)=>{console.log("Error : ", error)})*/

        //this.takeSnapshot();
        /*
        this.captureService.getImage(this.screen.nativeElement, true).pipe(
            tap(img => {
            })).subscribe();*/
    }

    /*ngAfterViewInit(){
      this.takeSnapshot();
    }*/

    private obtenerRecurso(element: any) {
        if (element.icon == "videocam") {
            this.format = 'video';
            this.videoSource = this.imagePipe.transform(element.data, this.fileFrom);
        }
        if (element.icon == "image") {
            this.format = 'imagen';
            this.imageSource = this.imagePipe.transform(element.data, this.fileFrom);
        }
        if (element.icon == "volume_up") {
            this.format = 'audio';
            this.audioSource = this.imagePipe.transform(element.data, this.fileFrom);
        }
        if (element.icon == "picture_as_pdf") {
            this.format = 'pdf';
            this.pdfSource = this.imagePipe.transform(element.data, this.fileFrom);
        }
        // if (element.icon == "subject") {
        //     this.format = 'texto';
        //     this.textSource = '../../../assets/document/' + element.data;
        // }
    }

    takeSnapshot(element: any) {

        const canvasElement = <HTMLCanvasElement>document.createElement('CANVAS');
        const imagen = element.nativeElement;
        const context = canvasElement.getContext('2d');
        let w: number, h: number, ratio: number;
        imagen.width = 650;
        imagen.height = 500;
        ratio = imagen.width / imagen.height;
        w = imagen.width - 100;
        h = w / ratio;
        canvasElement.width = w;
        canvasElement.height = h;
        context.fillRect(0, 0, w, h);
        context.drawImage(imagen, 0, 0, w, h);
        const link = document.createElement('a');
        this.snapshotName = this.snapshotName !== '' ? this.snapshotName : 'snapshot';
        this.userImageType = this.imageTypes.indexOf(this.userImageType.toUpperCase()) >= 0 ? this.userImageType.toUpperCase() : 'PNG';
        link.setAttribute('download', this.snapshotName + '.' + this.userImageType);
        const dataURL = canvasElement.toDataURL();
        this.quizService.setCurrenImagen(dataURL);
        /*link.href = dataURL;
        document.body.appendChild(link);
        link.click();*/
        const base64 = dataURL.split(';base64,')[1];
        const imageName = 'archivo' + Date.now() + '.png';
        const imageBlob = this.dataURItoBlob(base64);
        const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

        //Call Service
        let formData = new FormData();
        formData.append("file", imageFile, imageFile.name)
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    closeModal(sendData?: any) {
        if (this.loginServer.esAutor()) {
            if (this.format == 'video') {
                //this.takeSnapshot(this.videoElement);
                this.snapshotService.takeSnapshot(this.videoElement);
            }
            if (this.format == 'imagen') {
                //this.takeSnapshot(this.imgElement);
                this.snapshotService.takeSnapshot(this.imgElement);
            }
            if (this.format == 'audio') {
                //this.takeSnapshot(this.audioElement);
                this.snapshotService.takeSnapshot(this.audioElement);
            }
            if (this.format == 'pdf') {
                //this.takeSnapshot(this.pdfElement);
                this.snapshotService.takeSnapshot(this.pdfElement);
            }
            if (this.format == 'texto') {
                //this.takeSnapshot(this.textoElement);
                this.snapshotService.takeSnapshot(this.textoElement);
            }
        }
        this.activeModal.close();
    }
}
