import { CenterService } from "src/app/core/services/center/center.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

// Utils
import { LocalStorage } from "src/app/core/utils";

// Services
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "src/app/core/services/shared";
import { UsersService } from "src/app/core/services/users";
import { CenterById } from "src/app/core/models/masters/center.model";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ModalListCentersComponent } from "../modal-list-centers/modal-list-centers.component";
import { finalize } from "rxjs/operators";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ToasterService } from "src/app/core/services/shared/toaster.service";

@Component({
    selector: "app-user-data",
    templateUrl: "./user-data-profesor.component.html",
    styleUrls: ["../user-data-general/user-data-general.component.scss"],
    standalone: false
})
export class UserDataProfesorComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	centersAssignedToUser: MatTableDataSource<CenterById> = null;
	displayedColumns: string[] = ["centerName", "province", "delete"];
	isLoading: boolean = true;

	constructor(
		public translateService: TranslateService,
		private titleService: TitleService,
		private localStorage: LocalStorage,
		private activeModal: NgbActiveModal,
		private centerService: CenterService,
		public userService: UsersService,
		private modalService: NgbModal,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		this.traducirOpciones();
		this.getAssignmentCenters();
	}

	private traducirOpciones(): void {
		// Recupero el lenguaje
		const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(lang);

		// Titulo pagina
		this.translateService
			.get("USERDATA.CONFIGURACIONPROFESOR")
			.subscribe((res: string) => {
				this.titleService.setHTMLTitle(res);
				this.titleService.setBarTitle(res);
			});
	}

	closeModal(sendData?: any): void {
		this.activeModal.close(sendData);
	}

	deleteCenterToUser(index: number, idCenter: number): void {
		this.centerService.deleteCenterAssign(idCenter).subscribe(
			(result) => {
				this.centersAssignedToUser.data.splice(index, 1); //Elimino el registro de la tabla
				this.centersAssignedToUser._updateChangeSubscription(); //Actualizo la tabla con el borrado del elemento
				this.toaster.success(
					this.translateService.instant("TEACHERSETTINGS.OKDELETEASSIGNMENT")
				);
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("TEACHERSETTINGS.KODELETEASSIGNMENT")
				);
			}
		);
	}

	openModalCenters(): void {

		let options = {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		};

		const modalRef = this.modalService.open(ModalListCentersComponent, options);
		// modalRef.componentInstance.format = this.for

		modalRef.result.then(
			(res: boolean) => {
				if (res) this.getAssignmentCenters(); //Debemos actualizar la lista si nos hemos asignado un centro
			},
			(err) => {}
		);
	}

	private getAssignmentCenters() {
		this.centerService
			.getListCenterByIdUser()
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((result) => {
				//Obtengo los centros asociados al usuario actual
				this.centersAssignedToUser = new MatTableDataSource<CenterById>(result);
				this.centersAssignedToUser.paginator = this.paginator;
			});
	}
}
