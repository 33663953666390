import { ModalConfigSocketCoursesComponent } from "./modal-config-socket-courses/modal-config-socket-courses.component";
import { SocketService } from "./../../../../core/services/socket/socket-service.service";
import {
	ConfigChallengeModel,
	GroupModel,
} from "src/app/core/models/groups/groups-model";
import { CourseTargetModel } from "./../../../../core/models/courses/course-target.model";
import {
	Input,
	OnInit,
	ViewEncapsulation,
	OnDestroy,
	ChangeDetectionStrategy,
	ViewChild,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
// Models
import { CourseModel } from "src/app/core/models/courses";
// Services
import {
	CHALLENGEFILTERTYPE,
	CoursesService,
} from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { QuizzesService } from "src/app/core/services/quizzes";
import { Component } from "@angular/core";
import { Subscription, Subject, interval } from "rxjs";
import { CourseListModel } from "../../cursos/modal-cursos-listado/interface/modal-cursos-listado";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import {
	debounceTime,
	distinctUntilChanged,
	finalize,
	take,
	takeUntil,
} from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ModalAceptarCancelarComponent } from "../../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";
import { Time } from "../../socket-quiz-preview/socket-quiz-preview.component";
import countdown from 'countdown';
import { ReportingService } from "../../reporting/service/reporting.service";
import { ModalFinishSocketCoursesComponent } from "./modal-finish-socket-courses/modal-finish-socket-courses.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import {
	CountdownComponent,
	CountdownConfig,
	CountdownEvent,
} from "ngx-countdown";
import { ReportingComponent } from "../../reporting/reporting.component";
import { MastersService } from "src/app/core/services/masters";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { LocalStorage } from "src/app/core/utils";

const enum ELEMENTTYPE {
	COURSE = "COURSE",
	GRAPH = "GRAPH",
	NODE = "NODE",
	QUIZ = "QUIZ",
}

const enum CHALLENGEMODES {
	TIME = "1",
	ALONE = "2",
	AUTOMATIC = "3",
}

@Component({
    selector: "app-modal-socket-courses",
    templateUrl: "./modal-socket-courses.component.html",
    styleUrls: ["./modal-socket-courses.component.scss"],
    providers: [SliceStringPipe],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ModalSocketCoursesComponent implements OnInit, OnDestroy {
	@Input() group: GroupModel;
	@Input() onlineUsers: number;
	@Input() fromNode: boolean;
	@Input() idCurso?: number;
	@Input() idGrafo?: number;
	@Input() idNodo?: number;
	@Input() onlineUsersIds?: [] = [];

	viewList: boolean = true;
	formulario: UntypedFormGroup;
	isLoading: boolean = false;
	cargando = false;
	cursos: CourseModel[] = [];
	options: any;
	opcion: string;
	ordenSeleccionado: number[] = [0];
	buscarNodo = false;
	courseTargets: CourseTargetModel[] = [];
	curso: CourseModel;
	treeCourses: CourseListModel[] = [];
	viewQuiz: boolean = false;
	idCourse: number = null;
	idSelectedCourse: number = null;
	idGraph: number = null;
	quiz: any = null;
	elements: any[] = [];
	answered: boolean = false;
	rol = Profiles.Teacher;
	showOnlyGraphs = true;
	showOnlyExclusive = false;
	nameSession = "";
	nameSessionUpdate = new Subject<string>();
	optionSelected = CHALLENGEFILTERTYPE.BOTH;
	isFullscreen = false;
	showFullscreen = false;

	timerId: number = null;
	time: Time;
	iniBlock: boolean;
	countSeconds: number;

	resultsSubscription: Subscription;
	private destroy$ = new Subject();

	private subscriptions: Subscription[] = [];
	showReport: boolean = false;
	headTableReport: [];
	bodyTableReport: [];
	usersInGroupCounter: number = 0;
	usersInGroup: any[];
	usuariosOnline: any[] = [];
	usuariosOffline: any[] = [];
	offlineNames: any[] = [];
	configClock: CountdownConfig;
	configClockQuiz: CountdownConfig;
	challengeOn: boolean = true;

	cameFromCalendar: boolean = false;
	idEventFromCalendar: number = null;
	targetFromCalendar: number = null;
	nameSessionFromCalendar: string = null;
	isClockShown: boolean = false;
	@ViewChild("cd", { static: false }) private countdown: CountdownComponent;
	graphfromCalendar: any;
	selectedCourseFromCalendar: any;
	constructor(
		private coursesService: CoursesService,
		private loginService: LoginService,
		private activeModal: NgbActiveModal,
		private formBuild: UntypedFormBuilder,
		private quizService: QuizzesService,
		private groupServices: GruposService,
		private socketService: SocketService,
		private modalService: NgbModal,
		private translateService: TranslateService,
		private reportingService: ReportingService,
		private toaster: ToasterService,
		private mastersService: MastersService,
		private graphServ: GraphService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		this.formulario = this.formBuild.group({ filtrado: [""] });
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((s) => s.unsubscribe());
		this.socketService.configChallenge = null;
		this.destroy$.next(true);
	}

	ngOnInit() {
		localStorage.removeItem("challengeOn");
		localStorage.setItem("challengeOn", JSON.stringify(this.challengeOn));
		this.getDataToTree(this.optionSelected);
		this.groupServices.sendMsgToRoomClients(this.group.idGroup,
			'act_about_to_start',
			this.group.idGroup
		).subscribe();
		//SI VIENE DESDE UN NODO NO ABRIMOS CONFIGURACION, SINO QUE LO SETEAMOS COMO CASE 3
		if (this.fromNode) {
			const data = {
				challengeTime: 40,
				option: "2",
				synchronizedStudents: true,
				randomOrder: false,
			} as ConfigChallengeModel;
			this.socketService.configChallenge = data;
		} else {

			this.openModalConfig();
		}

		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					this.onlineUsers = onlineUsers;
					setTimeout(() => {
						this.checkoutConnectedUsers();
					}, 2000);

					break;
			}
		});



		this.subscriptions.push(msgSubs); // Añadimos susbcribe para poder destruirlo al cerrar el modal

		const updateNameSession = this.nameSessionUpdate
			.pipe(
				//Cuando escribimos en el input para cambiar el nombre de la sesión y hacer la llamada
				debounceTime(1000),
				distinctUntilChanged()
			)
			.subscribe((value) => {
				if (value) {
					this.groupServices
						.updateNameSession(
							this.socketService.idSession,
							value,
							this.group.idGroup
						)
						.subscribe(
							(result) => {
								this.toaster.success(
									this.translateService.instant(
										"SOCKETQUIZPREVIEW.OKNAMESESSIONUPDATE"
									)
								);
							},
							(err) => {
								this.toaster.error(
									this.translateService.instant(
										"SOCKETQUIZPREVIEW.KONAMESESSIONUPDATE"
									)
								);
							}
						);
				}
			});
		this.subscriptions.push(updateNameSession); // Añadimos susbcribe para poder destruirlo al cerrar el modal

		this.groupServices
			.getListEstudiantes(this.group.idGroup)
			.subscribe((members) => {
				this.usersInGroupCounter = members.data.length;
				this.usersInGroup = members.data;
			});

		setTimeout(() => {
			if (this.cameFromCalendar == true) {
				this.idSelectedCourse = this.idCourse;
				this.getDataToTree(3);
				this.nameSessionUpdate.next(this.nameSessionFromCalendar);
				this.groupServices
					.sendModAutoChallengeSocket(
						this.targetFromCalendar,
						this.group.idGroup,
						3
					)
				.subscribe();

				this.groupServices
					.sendDataToServerSocket(
						this.idEventFromCalendar,
						this.group.idGroup,
						SOCKETMESSAGES.CONTROL,
						this.targetFromCalendar,
						this.idCurso,
						""

					)
					.subscribe();

				setTimeout(() => {
					this.setupReporting();
				}, 1000);
			}
		}, 800);
		//definimos variable para tutoriales
		let idTutorialContext = 0;
		let challengeType = this.localStorage.getItem("challengeType");
		if(challengeType == 1){
			idTutorialContext = 20;
		} else if(challengeType == 2){
			idTutorialContext = 22;
		} else{
			idTutorialContext = 21;
		}
		this.masterService.setIdTutorialContext(idTutorialContext);
	}

	checkoutConnectedUsers(){

		// this.groupServices.getClientsInRoom(this.group.idGroup).subscribe((res) => {
		// 	this.usuariosOnline = res.data;

		// 	if (this.usersInGroupCounter > 0) {
		// 		this.onlineUsers = this.usuariosOnline.length;
		// 		// Remove the students from offline users array
		// 		this.usuariosOffline = this.usersInGroup.filter(
		// 			(user) => !this.usuariosOnline.includes(user.idUser.toString())
		// 		);
		// 		// this.usuariosOnline is an array of this.usersInGroup but
		// 		// only with the users that are in this.onlineUsers
		// 		this.usuariosOnline = this.usersInGroup.filter(
		// 			(user) => this.usuariosOnline.includes(user.idUser.toString())
		// 		);
		// 	}
		// }
		// );


		this.groupServices
			.getGroupSession(this.group.idGroup)
			.pipe(take(1))
			.subscribe((sessions) => {
				this.usuariosOnline = [];
				this.usuariosOffline = [];
				this.offlineNames = [];
				this.usersInGroup.forEach((usuario: any) => {
					// Verificar si el usuario está presente en alguna sesión
					const usuarioEnSesion = sessions.data.some(
						(sesion) => sesion.idUser === usuario.idUser
					);

					// Agregar el usuario al array correspondiente
					if (usuarioEnSesion) {
						//si eres profe no deberias salir tu mismo en esta lista
						let userLog = JSON.parse(localStorage.getItem("user"));
						if(this.loginService.esProfesor() && usuario.idUser != userLog.idUser){
							this.usuariosOnline.push(usuario);
						} else if(this.loginService.esProfesor() && usuario.idUser == userLog.idUser){
							this.usuariosOffline.push(usuario);
						}
					} else {
						this.offlineNames.push(`${usuario.firstName} ${usuario.surname}`);
						this.usuariosOffline.push(usuario);
					}
				});
			});
	}

	prefillOfflineUsers() {
		this.checkoutConnectedUsers();
		if (this.usuariosOffline.length == 0) {
			this.usersInGroup.forEach((usuario: any) => {
				// Agregar el usuario al array correspondiente
				this.offlineNames.push(`${usuario.firstName} ${usuario.surname}`);
				this.usuariosOffline.push(usuario);
			});
		}
	}

	closeModal(sendData?: any): void {
		this.groupServices
			.sendDataToServerSocket(
				0,
				this.group.idGroup,
				SOCKETMESSAGES.CLOSESOCKET,
				0,
				0,
				""
			)
			.subscribe();
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(true);
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
	}

	getDataToTree(option: number): void {
		this.cargando = true;
		const idGroup = this.group.idGroup;

		this.coursesService
			.coursesTreeListByChallenge(idGroup, option)
			.pipe(finalize(() => (this.cargando = false)))
			.subscribe(
				(result) => {
					this.treeCourses = result.data.courseslist;
					if (this.cameFromCalendar == true) {
						this.graphServ
							.getGraphInfo(this.targetFromCalendar)
							.subscribe((res) => {
								this.graphfromCalendar = res.data;
							});

						this.coursesService.getCourseById(this.idCurso).subscribe( curso => {
							this.selectedCourseFromCalendar = curso.data.courses;
						});

					}
					this.nameSession = result.data.nameSession;
				},
				(err) => {
					this.treeCourses = [];
				}
			);
	}

	//Función que recibe los valores del elemento seleccionado en el listado de los cursos
	clickElement(element: CourseListModel): void {
		this.answered = false;
		const isAuthor: boolean = this.loginService.esAutor();
		this.groupServices.sendMsgToRoomClients(this.group.idGroup,
			'quiz_about_to_start',
			this.group.idGroup
		).subscribe();
		//De forma provisional, sólo las acciones se contemplan desde el rol de estudiante para poder visualizar los nodos y actividades
		if (!isAuthor) {
			switch (element.type) {
				case ELEMENTTYPE.COURSE:
					break;
				case ELEMENTTYPE.GRAPH:
					//Si pincho en el grafo, necesito mostrar un prompt
					if (
						this.socketService.configChallenge.option === CHALLENGEMODES.ALONE
					)
						return;

					const modalRef = this.modalService.open(
						ModalAceptarCancelarComponent,
						{
							scrollable: true,
							windowClass: MODAL_DIALOG_TYPES.W30,
						}
					);

					let modalMessage = "";
					if (this.offlineNames.length) {
						modalMessage = `${this.translateService.instant(
							"SOCKETQUIZPREVIEW.SELECTEDGRAPH"
						)} ${this.translateService.instant(
							"SOCKETQUIZPREVIEW.STUDENTSOFFLINE"
						)}\n ${this.offlineNames.join(
							", "
						)} ${this.translateService.instant(
							"SOCKETQUIZPREVIEW.WANNASTARTANYWAY"
						)} `;
					} else {
						modalMessage = `${this.translateService.instant(
							"SOCKETQUIZPREVIEW.SELECTEDGRAPH"
						)}`;
					}

					modalRef.componentInstance.mensaje = modalMessage;
					modalRef.result.then((result: boolean) => {
						if (result) {
							const configChallenge = this.socketService.configChallenge;
							this.showFullscreen = true;
							//Si acepta el profesor, tenemos que mirar qué opción ha seleccionado para mandar un evento u otro
							if (configChallenge.option === CHALLENGEMODES.TIME) {
								//Mando evento a los alumnos al socket
								this.groupServices
									.sendChallengeListModeSocket(
										element.idTarget,
										this.group.idGroup,
										this.socketService.configChallenge.challengeTime
									)
									.subscribe();
								//Tenemos que mostrar el contador si el reto es del tipo 1, donde el profesor elige un grafo para hacerlo en un tiempo determinado
								this.configClock = {
									leftTime:
										this.socketService.configChallenge.challengeTime * 60,
								};
								this.isClockShown = true;
								this.showClock();
							} else if (configChallenge.option === CHALLENGEMODES.AUTOMATIC) {
								let idCase: number = null;
								//1. Por defecto, sincronizado y aleatorio - 2. Sincronizado y no aleatorio - 3. No sincronizado y si aleatorio - 4. No sincronizado y no aleatorio)
								if (
									configChallenge.synchronizedStudents &&
									configChallenge.randomOrder
								)
									idCase = 1;
								else if (
									configChallenge.synchronizedStudents &&
									!configChallenge.randomOrder
								)
									idCase = 2;
								else if (
									!configChallenge.synchronizedStudents &&
									configChallenge.randomOrder
								)
									idCase = 3;
								else if (
									!configChallenge.synchronizedStudents &&
									!configChallenge.randomOrder
								)
									idCase = 4;

								this.groupServices
									.sendModAutoChallengeSocket(
										element.idTarget,
										this.group.idGroup,
										idCase
									)
									.subscribe();
							}
						}
					});
					break;
				case ELEMENTTYPE.NODE:
					break;
				case ELEMENTTYPE.QUIZ:
					if (
						this.socketService.configChallenge.option !== CHALLENGEMODES.ALONE
					)
						return;

					//Tenemos que comprobar que los quizzes que selecciona el profesor si tiene elegida la opción 2, tienen que ser del mismo curso, no pueden ser de otro diferente
					if (
						this.idSelectedCourse !== element.idCourse &&
						this.idSelectedCourse &&
						this.socketService.configChallenge.option === "2"
					) {
						this.toaster.error(
							this.translateService.instant(
								"SOCKETQUIZPREVIEW.ERRORSELECTEDGRAPHFROMOTHERCOURSE"
							)
						);
						return;
					}

					this.quiz = null;
					this.isLoading = true;
					this.idSelectedCourse = element.idCourse;
					this.getQuiz(
						element.idQuiz,
						element.idCourse,
						element.idTarget,
						element.multi
					);
					//Cuando seleccione el profesor el quiz, tenemos que mandar mensaje por socket a los usuarios
					this.groupServices
						.sendDataToServerSocket(
							element.idQuiz,
							this.group.idGroup,
							SOCKETMESSAGES.OPEN,
							element.idTarget,
							element.idCourse,
							element.multi ? "multiple" : "simple"
						)
						.subscribe();
					break;
			}
		}
	}

	private getQuiz(
		idQuiz: number,
		idCourse: number,
		idTarget: number,
		isMultiplexed: boolean
	): void {
		this.idGraph = idTarget;

		if (isMultiplexed) {
			this.quizService
				.getQuizMultipleCanvasQuizMultiple(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe((res) => {
					this.quiz = res.quiz;
					this.elements = res.elements;
					if (this.quiz.duration != null && this.quiz.duration > 0) {
						this.configClockQuiz = {
							leftTime: this.quiz.duration,
						};
					}
				});
		} else {
			this.quizService
				.getQuiz(idQuiz, idCourse, idTarget)
				.pipe(finalize(() => (this.isLoading = false)))
				.subscribe((res: any) => {
					this.quiz = {
						...res.quiz,
						user: res.quiz.user,
						idOriginal: res.quiz.idOriginal,
						id: res.quiz.idQuiz,
						originalX: res.quiz.originalX,
						originalY: res.quiz.originalY,
						size: res.quiz.size,
						sizeQuiz: res.quiz.sizeQuiz,
						x: res.quiz.x,
						y: res.quiz.y,
					};
					this.elements = res.elements;
					if (this.quiz.duration != null && this.quiz.duration > 0) {
						this.configClockQuiz = {
							leftTime: this.quiz.duration,
						};
					}
				});
		}
	}

	// FUNCIONES DE LOS COMPONENTES DE QUIZ Y NODO
	onAnswered(): void {
		this.answered = true;
	}

	filterGraphsTree(): void {
		this.optionSelected = CHALLENGEFILTERTYPE.NONE;

		if (this.showOnlyExclusive && this.showOnlyGraphs)
			this.optionSelected = CHALLENGEFILTERTYPE.BOTH;
		else if (this.showOnlyGraphs)
			this.optionSelected = CHALLENGEFILTERTYPE.NOEXCLUSIVES;
		else if (this.showOnlyExclusive)
			this.optionSelected = CHALLENGEFILTERTYPE.EXCLUSIVES;

		this.getDataToTree(this.optionSelected);
	}

	private openModalConfig(): void {
		const modalRef = this.modalService.open(ModalConfigSocketCoursesComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
			backdrop: "static",
			keyboard: false,
		});
		modalRef.result.then(
			(res) => {
				//Si tiene datos el res, lo almaceno en la configuración del socket
				if (res) {
					const data = res as ConfigChallengeModel;
					this.socketService.configChallenge = data;
					//this.getDataToTree(Number(data.option));
					if (data.option === "3" || data.option === "1") {
						this.setupReporting();
					}
					localStorage.setItem("challengeType", data.option); // esto es solo para ocultar temporalmente los multiples en los desafios que se escogen los quizzes uno a uno (option 2)
					this.toaster.success(
						this.translateService.instant("SOCKETQUIZPREVIEW.CONFIGOK")
					);
				} else this.closeModal();
			},
			(err) => {
				//Mostramos error en la configuracióm y hay que volver a mostrar el modal
				this.toaster.error(
					this.translateService.instant("SOCKETQUIZPREVIEW.CONFIGKO")
				);
				this.openModalConfig();
			}
		);
	}

	setupReporting() {
		const isProfesor: boolean = this.loginService.esProfesor();
		if (isProfesor) {
			const source = interval(5000);
			this.resultsSubscription = source
				.pipe(takeUntil(this.destroy$))
				.subscribe((val) => {
					if (this.cameFromCalendar == true) {
						// Call another method if cameFromCalendar is true
						this.reportingService
							.getDataTableFromControls(
								this.group.idGroup,
								[this.socketService.idSession],
								true,
								[]
							)
							.pipe(take(1))
							.subscribe((res: any) => {
								if (res) {
									this.showReport = true;
									this.headTableReport = res.data.headTable;
									this.bodyTableReport = res.data.bodyTable.slice(1);
								}
							});
					} else {
						this.reportingService
							.getDataTableFromChallenges(
								this.group.idGroup,
								[this.socketService.idSession],
								true,
								[]
							)
							.pipe(take(1))
							.subscribe((res: any) => {
								if (res) {
									this.showReport = true;
									this.headTableReport = res.data.headTable;
									this.bodyTableReport = res.data.bodyTable.slice(1);
								}
							});
					}
				});
		}
	}

	finalizeChallenge(): void {
		//Abrimos modal para finalizar o anular el desafio
		const modalRef = this.modalService.open(ModalFinishSocketCoursesComponent, {
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.result.then((result) => {
			switch (result) {
				case "finalizar":
					this.groupServices
						.sendDataToServerSocket(
							0,
							this.group.idGroup,
							SOCKETMESSAGES.CLOSESOCKET,
							0,
							0,
							""
						)
						.subscribe();
					this.closeModal();
					break;
				case "anular":
					//Eliminar el reto
					this.groupServices
						.deleteChallenge(this.group.idGroup, this.socketService.idSession)
						.subscribe((result) => {});
					this.closeModal();
					break;
				default:
					break;
			}
		});
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha finalizado el desafio.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.group.idGroup,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog1.action,
				bodyLog1.actionData,
				bodyLog1.startDate,
				bodyLog1.endingDate,
				bodyLog1.role,
				bodyLog1.idGroup
			)
			.subscribe();
	}

	//Mostramos un reloj con los minutos/segundos para completar el examen
	private showClock(): void {
		if (this.timerId) {
			clearInterval(this.timerId);
		}
		this.iniBlock = false;
		let date = new Date();
		date.setSeconds(
			date.getSeconds() + this.socketService.configChallenge.challengeTime * 60
		);

		this.isClockShown = true;

		// this.timerId = countdown(
		// 	date,
		// 	(timeStamp) => {
		// 		this.time = timeStamp;

		// 		if (!this.iniBlock) {
		// 			this.iniBlock = true;
		// 			this.countSeconds = this.time.seconds + this.time.minutes * 60;
		// 		}

		// 		if (this.time.minutes === 0 && this.time.seconds === 0) {
		// 			clearInterval(this.timerId);
		// 			this.time = null;
		// 		}
		// 	},
		// 	countdown.HOURS | countdown.MINUTES | countdown.SECONDS
		// );
	}

	handleCountDownEvent(e: CountdownEvent) {
		if (e.action === "done") {
			clearInterval(this.timerId);
			this.time = null;
		}
	}

	toggleFullscreen() {
		this.isFullscreen = !this.isFullscreen;
	}

	openReportModal() {
		const modalRef = this.modalService.open(ReportingComponent, {
			windowClass: MODAL_DIALOG_TYPES.W90,
		});

		modalRef.componentInstance.cameFromCalendar = this.cameFromCalendar;
		modalRef.componentInstance.hotView = true;
		modalRef.componentInstance.hotViewIdGroup = this.group.idGroup;
		modalRef.componentInstance.hotViewIdSession = [
			this.socketService.idSession,
		];
	}
}
