<nb-card class="mb-0 no-edge-card modal-height-100-62">

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal(comentario)">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="row h-170" [ngClass]="{ 'h-230 mt-4': loginService.esProfesor() }">
			<div class="col-12 col-xl-4 background_themecolor">
				<!-- TITULO DE TAREAS -->
				<div class="d-flex align-items-center pr-5">
					<div class="modal-title">
						<h5 class="m-0" *ngIf="fromNode">{{ 'TASKCALENDAR.SELECT' | translate }}</h5>
						<h5 class="m-0" *ngIf="!fromNode">{{ 'TASKCALENDAR.TASKLIST' | translate }} ({{selectedDate}})</h5>
					</div>
				</div>
				<!-- SELECTOR DE FECHA Y BOTON DE SUBMIT -->
				<div *ngIf="fromNode" class="row justify-content-center mt-3">
					<div class="col-12 col-xl-4">
						<div class="d-flex gap-3">
							<input type="text" hidden>
							<!-- calendario -->
							<input nbInput status="basic" fieldSize="small" name="date" class="form-control"
								[(ngModel)]="selectedDate" placeholder="{{ 'GLOBALS.SELECTDATE' | translate }}" type="date"
								maxlength="10" (ngModelChange)="onDateChange($event)" />
							<!-- evento para asignar -->
							<button nbButton status="success" size="small" [disabled]="selectedQuizzes.length === 0"
								(click)="sendSelectedQuizzes()">
								{{ 'TASKCALENDAR.ASIGNAR' | translate }}
							</button>
						</div>
					</div>
				</div>
	
				<div *ngIf="!fromNode" class="row justify-content-center mt-3">
					<!-- BOTON PARA AGREGAR NUEVAS TAREAS -->
					<div class="col-12 col-xl-4">
						<div *ngIf="!fromNode && loginService.esProfesor()" class="d-flex">
							<button nbButton status="primary" size="small" (click)="newTask()">
								{{ 'TASKCALENDAR.NEWTASK' | translate }}
							</button>
						</div>
					</div>
				</div>
				<!--LISTA PARA VER LAS TAREAS POR ASIGNAR-->
				<div *ngIf="fromNode"
					class="flex flex-column background_themecolor h-230 pl-3 pt-3 pb-3 pr-0 overflow-auto">
					<h6 *ngIf="tree.length == 0">({{ 'TASKCALENDAR.NOHAYTAREAS' | translate }})</h6>
					<!--PRIMER NIVEL DEL ARBOL (NODO)-->
					<ul class="m-0 pt-0 pb-0 pl-0 pr-3" style="list-style: none;">
						<li *ngFor="let node of tree" class="py-2">
							<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
								[class.selected]="selectedLabel === node" (click)="clickElement(node)">
								<nb-icon icon="checkmark-square-2-outline"
									*ngIf="loginService.esProfesor() && node.checked"></nb-icon>
								<nb-icon status="basic" icon="square-outline"
									*ngIf="loginService.esProfesor() && !node.checked"></nb-icon>
								<mat-icon svgIcon="ideasvg" class="mat-icon pointer icon-green"></mat-icon>
								<span> {{ node.title }} </span>
							</div>
							<!--SEGUNDO NIVEL DEL ARBOL (QUIZZES)-->
							<ul *ngIf="node.children.length > 0" class="" style="list-style: none;"
								[ngStyle]="{'display': node.childrenVisible ? 'block' : 'none'}">
								<li *ngFor="let quiz of node.children" class="py-2 d-flex">
									<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
										[class.selected]="selectedLabel === quiz" (click)="clickElement(quiz)">
										<nb-icon icon="checkmark-square-2-outline" *ngIf="quiz.checked"></nb-icon>
										<nb-icon status="basic" icon="square-outline" *ngIf="!quiz.checked"></nb-icon>
										<mat-icon svgIcon="act" class="icon-animate pointer icon-red"></mat-icon>
										<span> {{ quiz.title }} </span>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<!--LISTA PARA VER LAS TAREAS ASIGNADAS-->
				<div *ngIf="!fromNode"
					class="flex flex-column background_themecolor h-170 pl-3 pt-3 pb-3 pr-0 overflow-auto"
					[ngClass]="{ 'h-230': loginService.esProfesor() }">					
					<h6 *ngIf="tree.length == 0">({{ 'TASKCALENDAR.NOHAYTAREAS' | translate }})</h6>
					<!--PRIMER NIVEL DEL ARBOL (CURSOS)-->
					<ul class="m-0 pt-0 pb-0 pl-0 pr-3" style="list-style: none;">
						<li *ngFor="let curso of tree" class="py-2">
							<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
								[class.selected]="selectedLabel === curso" (click)="clickElement2(curso)">
								<mat-icon svgIcon="ProyectoComoMuchosGrafos" class="mat-icon pointer"></mat-icon>
								<span> {{ curso.title }} </span>
							</div>
							<!--SEGUNDO NIVEL DEL ARBOL (GRAFOS)-->
							<ul *ngIf="curso.children.length > 0" class="pl-2" style="list-style: none;"
								[ngStyle]="{'display': curso.childrenVisible ? 'block' : 'none'}">
								<li *ngFor="let grafo of curso.children">
									<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
										[class.selected]="selectedLabel === grafo" (click)="clickElement2(grafo)">
										<mat-icon svgIcon="ideografo" class="mat-icon pointer"></mat-icon>
										<span> {{ grafo.title }} </span>
										<nb-icon icon="navigation-2-outline" (click)="openGrafo(curso.id, grafo.id)"
											*ngIf="loginService.esEstudiante()" status="basic"
											nbTooltip="{{ 'HEADER.OBJETO_ABRIRGRAFO' | translate }}"></nb-icon>
									</div>
									<!--TERCER NIVEL DEL ARBOL (NODOS)-->
									<ul *ngIf="grafo.children.length > 0" class="pl-2" style="list-style: none;"
										[ngStyle]="{'display': grafo.childrenVisible ? 'block' : 'none'}">
										<li *ngFor="let node of grafo.children">
											<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
												[class.selected]="selectedLabel === node" (click)="clickElement2(node)">
												<mat-icon svgIcon="ideasvg" class="mat-icon pointer"></mat-icon>
												<span> {{ node.title }} </span>
												<nb-icon icon="trash-outline"
													(click)="deleteTask(node.idTaskCalendar, true)"
													*ngIf="loginService.esProfesor()"></nb-icon>
											</div>
											<!--CUERTO NIVEL DEL ARBOL (QUIZZES)-->
											<ul *ngIf="node.children.length > 0" class="pl-2" style="list-style: none;"
												[ngStyle]="{'display': node.childrenVisible ? 'block' : 'none'}">
												<li *ngFor="let quiz of node.children">
													<div class="d-flex pointer py-2 px-3 gap-2 align-items-center"
														[class.selected]="selectedLabel === quiz"
														(click)="clickElement2(quiz)">
														<mat-icon svgIcon="act" class="mat-icon pointer"></mat-icon>
														<span> {{ quiz.title }} </span>
														<nb-icon icon="trash-outline"
															(click)="deleteTask(quiz.idTaskCalendar, false)"
															*ngIf="loginService.esProfesor()"></nb-icon>
													</div>
												</li>
											</ul>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-12 col-xl-8 h-170 overflow-auto" [ngClass]="{ 'h-230': loginService.esProfesor() }"
				*ngIf="!fromNode">
				<div class="background_themecolor" style="height: 25%;">
					<!-- MENSAJE DEL PROFE -->
					<div class="d-flex flex-column">
						<h5>{{ "MODALCALENDAR.TEACHERCOMENTARY" | translate }}</h5>
						<textarea class="form-control mx-5" nbInput fullWidth rows="3" [readOnly]="loginService.esEstudiante()"
							[(ngModel)]="comentario" placeholder="{{ 'MODALCALENDAR.ANOTACION' | translate }}"
							maxlength="250">
							</textarea>
					</div>
				</div>
				<div class="d-flex flex-column background_themecolor mt-5" style="height: 75%;">
					<h5>{{ "SCHEDULES.EVENTSTHISDAY" | translate }}</h5>
					<h6 *ngIf="dayEvents.length == 0">({{ 'SCHEDULES.NOEVENTSTHISDAY' | translate }})</h6>
					<div *ngFor="let event of dayEvents">
						<div class="d-flex align-items-center justify-content-between w-100 gap-3" *ngIf="!event?.scheduleType && !event?.taskType">
							<div class="d-flex justify-content-center align-items-center">
								<p class="m-0">{{ event.title }} ({{ event.start | date: 'shortTime':'':'es' }} - {{ event.end | date: 'shortTime':'':'es' }})</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-3" *ngIf="loginService.esProfesor()">
								<button nbButton status="primary" size="small">
									<nb-icon icon="play-circle-outline"></nb-icon>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
