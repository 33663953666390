import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupModel } from 'src/app/core/models/groups/groups-model';
import { User } from 'src/app/core/models/users/user.models';
import { GruposService } from 'src/app/core/services/groups/grupos.service';
import { LoginService } from 'src/app/core/services/login';
import { Profiles } from 'src/app/core/utils/profiles.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { debounceTime, interval, Subscription  } from 'rxjs';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-modal-listado-emails',
    templateUrl: './modal-listado-emails.component.html',
    styleUrls: ['./modal-listado-emails.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalListadoEmailsComponent implements OnInit {
  group: GroupModel;
  public users: any[] = [];
  public users_aux: any[] = [];
  public formulario: UntypedFormGroup;
  cargando: boolean;
  selectedEmails: string = '';
  selectAllChecked = false;

  constructor(
    public activeModal: NgbActiveModal,
    private groupService: GruposService,
    private formBuild: UntypedFormBuilder,
    private loginService: LoginService,
    private translateService: TranslateService,
		private modalService: NgbModal,
		private toaster: ToasterService

  ) {
    this.formulario = this.formBuild.group({ filtrado: [""] });
    this.setupSearchListener();
   }

  ngOnInit() {
		this.cargando = true;
		this.getListadoEstudiantes();
	}

	ngOnDestroy() {}

  filtrarEstudiantes(){
  }

  getListadoEstudiantes() {
    this.groupService.getmailsbygroup(this.group.idGroup).subscribe((res:any) => {
      this.users = res.data;
      this.users_aux = res.data;
			this.cargando = false;
    });
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  setupSearchListener() {
    this.formulario
      .get("filtrado")
      .valueChanges.pipe(debounceTime(800)) //TIEMPO QUE TENEMOS PARA ESCRIBIR EN EL FILTRO EN MILISEGUNDOS
      .subscribe(() => {
        this.filtrarPorTitulo();
      });
  }

  filtrarPorTitulo() {
    let searchTerm;
    if (this.formulario.get("filtrado").value != "") {
      searchTerm = this.normalizeText(
      this.formulario.get("filtrado").value.toLowerCase()
      );
    }
    this.users = [...this.users_aux]; 
    if (searchTerm) {
      const searchTerms = searchTerm.split(" "); // Divide el término en palabras      
      // Filtra la lista de cursos
      this.users = this.users.filter((user) => {
      const normalizedTitle = this.normalizeText(user.name.toLowerCase());
      return searchTerms.every((term) => normalizedTitle.includes(term)); // Verifica todas las palabras
      });
    }
  }
    
  // Función para normalizar el texto eliminando tildes
  normalizeText(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  clearSearch() {
    this.formulario.get('filtrado')?.setValue('');
  }

  updateSelectedEmails() {
    const selectedUsers = this.users.filter(user => user.selected);
    this.selectedEmails = selectedUsers.map(user => user.mail).join('; ');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.selectedEmails).then(() => {
      this.toaster.success(this.translateService.instant("ACEPTARCANCELAR.COPYMAILSUCCESS"));
    })
  }

  toggleSelectAll(event: any) {
    this.selectAllChecked = event.target.checked;
    this.users.forEach(user => user.selected = this.selectAllChecked);
    this.updateSelectedEmails();
  }

}
