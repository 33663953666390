<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<!-- contenido con IA -->
	<ng-container *ngIf="loading == true; else default">
		<nb-card-body>
			<nb-progress-bar [value]="value" [status]="status" [displayValue]="true"></nb-progress-bar>
			<h5 style="font-weight: bold; text-align: center;">Generando contenido con IA</h5>
			<p style="text-align: center; font-style: italic;">Puede tardar unos segundos</p>
		</nb-card-body>
	</ng-container>
	<!-- contenido del node -->
	<ng-template #default>
		<!-- titulo -->
		<nb-card-header class="flex border-bottom-0">
			<div class="col-10 modal-title">
				<h2 *ngIf="node.id !== 'temp'; else newNodeTitle">
					{{ "NODOS.NODE" | translate }}: {{ node.label }}
				</h2>
				<ng-template #newNodeTitle>
					<h2>
						{{ "NODOS.REGISTRARNODO" | translate }}
					</h2>
				</ng-template>
			</div>
		</nb-card-header>
		<!-- icono para cerrar el modal -->
		<div class="closeModal">
			<nb-icon class="m-1" icon="checkmark-circle-2" nbTooltip="{{ 'GLOBALS.SAVEANDCLOSE' | translate }}"
				nbTooltipPlacement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
			</nb-icon>
		</div>
		<nb-card-body>
			<!-- <div class="sticky-top-header w-100">
					<div class="tittle py-3">
						<div class="row d-flex justify-content-between align-items-center">
							<div class="col-12 text-center">
								<span *ngIf="node.id !== 'temp'; else newNodeTitle"
										class="font-weight-bold span-text-center">
									{{ 'NODOS.NODE' | translate }}: {{node.label}}
								</span>
								<ng-template #newNodeTitle>
									<span class="font-weight-bold span-text-center">
										{{'NODOS.REGISTRARNODO' | translate}}
									</span>
								</ng-template>
							</div>
						</div>
						<div data-dismiss="modal" ngbAutofocus (click)="closeModal()" class="close-button">
							<mat-icon>close</mat-icon>
						</div>
					</div>
				</div> -->
			<form [formGroup]="form">
				<div class="row background_themecolor">
					<div class="col-12 col-md-10">
						<div class="container">
							<!-- Info de la idea -->
							<div class="row">
								<!-- NODE IMAGE -->
								<div class="col-12 col-md-4 image-node">
									<nb-card class="project-info pointer background_themecolor no-edge-card mb-0">
										<div class="imagecontainer">
											<app-imgupload [node]="node" [isShowDelete]="true" formControlName="image"></app-imgupload>
										</div>
										<!-- Generar imagen del nodo con IA -->
										<nb-icon (click)="generateImage($event)" icon="bulb-outline"
											nbTooltip="Generar imagen del nodo con IA" class="pointer medios">
										</nb-icon>
									</nb-card>
								</div>
								<!-- END NODE IMAGE -->
								<div class="col-12 col-md-8 mt-4 mt-md-0 py-3">
									<nb-card class="mb-0 background_themecolor pointer no-edge-card gap-2">
										<!-- TITLE -->
										<nb-card-header class="p-0">
											<label for="exampleFormControlInput1">Título</label>
											<input #title nbInput fullWidth [placeholder]=" 'NODEFORMCOMPONENT.NODENAME' | translate "
												formControlName="name" [readonly]="!canEdit" maxlength="250" class="form-control" />
											<div *ngFor="let validation of validationMessages.title">
												<div class="text-danger mt-1"
													*ngIf="titleControl?.hasError(validation.type) && (titleControl?.dirty || titleControl?.touched)">
													{{ validation.message }}
												</div>
											</div>
										</nb-card-header>
										<!-- DESCRIPTION -->
										<nb-card-body class="p-0">
											<label for="exampleFormControlTextarea1">{{ 'EDITARCURSO.DESCRIPCION' | translate }}</label>
											<textarea nbInput fullWidth rows="3" [placeholder]="'NODEFORMCOMPONENT.DESCRIPTION' | translate"
												formControlName="description" [readonly]="!canEdit" maxlength="500" class="form-control" >
											</textarea>
										</nb-card-body>
									</nb-card>
								</div>
							</div>
							<!-- opciones para configuracion -->
							<div class="row">
								<div class="col-12 pb-3 pt-1">
									<div class="d-flex justify-content-between align-items-center gap-3">
										<div class="d-flex align-items-center gap-4">
											<!-- TOGGLE -->
											<div class="d-flex gap-3">
												<!-- visible -->
												<div class="d-flex flex-column gap-1 w-toggle">
													<nb-toggle class="item-profile" *ngIf="canEdit" formControlName="published" (change)="publish($event)"
														nbTooltip='{{ "EDITARCURSO.TOGGLEONE" | translate }}' nbTooltipPlacement="top">
													</nb-toggle>
													<span class="label-header">
														{{
														form.get("published").value
														? ("EDITARCURSO.VISIBLE" | translate)
														: ("EDITARCURSO.NOVISIBLE" | translate)
														}}
													</span>
												</div>
												<!-- visible -->
												<div class="d-flex flex-column gap-1 w-toggle" *ngIf="!canEdit">
													<nb-toggle class="item-profile" formControlName="published"
														nbTooltip='{{ "EDITARCURSO.TOGGLEONE" | translate }}' nbTooltipPlacement="top">
													</nb-toggle>
													<span class="label-header">
														{{
														form.get("published").value
														? ("EDITARCURSO.VISIBLE" | translate)
														: ("EDITARCURSO.NOVISIBLE" | translate)
														}}
													</span>
												</div>
												<!-- solo profesores -->
												<div class="d-flex flex-column gap-1 w-toggle">
													<nb-toggle class="item-profile" nbTooltip='{{ "EDITARCURSO.TOGGLETWO" | translate }}'
														nbTooltipPlacement="top">
													</nb-toggle>
													<span class="label-header">
														{{ "EDITARCURSO.TEACHERSONLY" | translate }}
													</span>
												</div>
											</div>
											<!-- POWERS -->
											<div class="d-flex">
												<button type="button" size="small" nbButton [nbPopover]="templateRef" nbPopoverPlacement="bottom" disabled>
													{{ "EDITARCURSO.POWERS" | translate }}
												</button>
											</div>
										</div>

										<div class="d-flex align-items-center gap-3">
											<!-- generar actividad -->
											<div class="d-flex">
												<button type="button" nbButton class="btn-themecolor" size="small" (click)="generateQuizzes()">
													{{ "SALWIZAR.GENERATEACTIVITY" | translate }}
												</button>
											</div>
											<!-- DELETE -->
											<div class="d-flex">
												<button type="button" nbButton size="small" class="btn-red" (click)="deleteNode()" *ngIf="loginService.esAutor()">
													{{ "EDITARCURSO.ELIMINARIDEA" | translate }}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- previw de los archivos cargados -->
						<div class="row background_white">
							<div class="col-12 container-files">
								<!-- YOUTUBE VIDEO-->
								<div class="full-video" *ngIf="youtubeSource">
									<app-youtube-player [videoId]="videoId" [startSeconds]="startSeconds"
										[endSeconds]="endSeconds">
									</app-youtube-player>
								</div>
								<!-- END YOUTUBE VIDEO-->

								<!-- INSTAGRAM VIDEO -->
								<ng-container *ngIf="instagramSource">
									<iframe [src]="instagramSource" class="iframeVideo"></iframe>
								</ng-container>
								<!--END  INSTAGRAM VIDEO -->

								<!-- TIKTOK VIDEO -->
								<ng-container *ngIf="tiktokSource">
									<iframe [src]="tiktokSource" class="iframeVideo"></iframe>
								</ng-container>
								<!-- END TIKTOK VIDEO -->

								<!-- SPOTIFY AUDIO -->
								<ng-container *ngIf="spotifySource">
									<iframe [src]="spotifySource" class="iframeVideo"></iframe>
								</ng-container>
								<!-- END SPOTIFY AUDIO -->

								<!-- GENERIC MESSAGE -->
								<div *ngIf="format === '' && canEdit" class="d-flex no-data">
									<p>{{ "NODEDETAIL.SELECTFILE" | translate }}</p>
									<mat-icon svgIcon="arrow_forward"></mat-icon>
								</div>
								<!-- END GENERIC MESSAGE -->

								<!-- VIDEO -->
								<div class="full-video" *ngIf="videoSource">
									<video #videoElement controls [src]="videoSource | safe" type="video/quicktime"></video>
								</div>
								<!-- END VIDEO -->

								<!-- IMAGE -->
								<div class="full-image" *ngIf="imageSource"
									[style.background-image]="'url(' + imageSource + ')'">
								</div>
								<!-- END IMAGE -->

								<!-- AUDIO -->
								<div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="audioSource">
									<audio [src]="audioSource | safe" controls></audio>
								</div>
								<!-- END AUDIO -->

								<!-- PDF -->
								<div *ngIf="pdfSource" class="h-100 p-0 d-flex align-items-center justify-content-center position-relative">
									<ng-container *ngIf="ext === 'docx'; else embedPdf">
										<ngx-doc-viewer [url]="pdfSource" viewer="office" class="w-100 h-100 position-relative"></ngx-doc-viewer>
									</ng-container>
									<ng-template #embedPdf>
										<embed *ngIf="sanitizedUrl" [src]="sanitizedUrl" class="w-100 h-100" />
									</ng-template>
								</div>
								<!-- END PDF -->

								<!-- texto -->
								<div *ngIf="format === 'text'" class="h-100">
									<app-node-detail-files-text-edit *ngIf="!node.rented" [(value)]="textSource"
										(valueChange)="saveText($event)"></app-node-detail-files-text-edit>
									<p *ngIf="node.rented" [innerHTML]="textSource"></p>
								</div>
							</div>
						</div>
					</div>
					<!-- Panel lateral -->
					<div class="col-12 col-md-2">
						<app-node-column-files [node]="node" [onSaveClicked$]="saveClicked$" [canEdit]="canEdit"
							(nodeFilesTypeChanged)="onNodeFilesTypeChanged($event)" (nodeFileAdded)="onNodeFileAdded($event)"
							(nodeFilePlayed)="onFilePlayed($event)">
						</app-node-column-files>
					</div>
				</div>
				<!-- POWERS -->
				<ng-template #templateRef>
					<ng-container *ngIf="canEdit || canEditPower">
						<div class="p-4">
							<div class="row mt-4 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkP2" formControlName="power3"></mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="power3">
										<p class="chkP2">P<small>+3</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPower3" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkP2" formControlName="power2">
								P<small>+2</small>

								</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="power2">
										<p class="chkP2">P<small>+2</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPower2" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkP1" formControlName="power1">
								P<small>+1</small>
								</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="power1">
										<p class="chkP1">P<small>+1</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPower1" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkP0" formControlName="power0">
								P<small>0</small>
								</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="power0">
										<p class="chkP0">P<small>0</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPower0" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkPNegative1" formControlName="powerNegative1">
								P<small>-1</small>
								</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="powerNegative1">
										<p class="chkPNegative1">P<small>-1</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPowerNegative1" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkPNegative2" formControlName="powerNegative2">
								P<small>-2</small>
								</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="powerNegative2">
										<p class="chkPNegative2">P<small>-2</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPowerNegative2" maxlength="2" />
								</div>
							</div>

							<div class="row mt-3 align-items-center">
								<div class="col-5">
									<!-- <mat-checkbox class="chkPNegative2" formControlName="powerNegative3">
									P<small>-3</small>
									</mat-checkbox> -->
									<nb-checkbox status="primary" formControlName="powerNegative3">
										<p class="chkPNegative2">P<small>-3</small></p>
									</nb-checkbox>
								</div>
								<div class="col-7">
									<input nbInput fullWidth [placeholder]="'NODEDETAIL.ORDINAL' | translate" type="number"
										formControlName="ordinalPowerNegative3" maxlength="2" />
								</div>
							</div>
							<input type="hidden" formControlName="power" />

							<div *ngFor="let validation of validationMessages.power">
								<div class="text-danger mt-1" *ngIf="powerControl?.hasError(validation.type)">
									{{ validation.message }}
								</div>
							</div>
						</div>
					</ng-container>
				</ng-template>
				<!-- END POWERS -->
			</form>
		</nb-card-body>
	</ng-template>
</nb-card>
