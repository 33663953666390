import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-torta-grupos',
    templateUrl: './torta-grupos.component.html',
    styleUrls: ['./torta-grupos.component.scss'],
    standalone: false
})
export class TortaGruposComponent implements OnInit{
	@Input() parametroID: number | null = null;
	@Input() consultaID: number | null = null;
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];
	auxData: any[] = [];
	step: number = 0;
	title1: string = '';
	title2: string = '';

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
		if (this.consultaID !== null) {
			if(this.parametroID == 1){
				//this.datasets.push(this.calcularPromedios(this.datasetsImported));
				//this.auxData = this.datasetsImported;
				//this.labels = this.labelsImported;
				this.labels = this.obtenerLabels(this.datasetsImported, 0);
				this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0)];
				switch (this.consultaID){
					case 1:
						this.title1 = 'Niveles';
						this.title2 = 'Profesores';
						break;
					case 2:
						this.title1 = 'Asignaturas';
						this.title2 = 'Profesores';
						break;
					case 3:
						this.title1 = 'Niveles';
						this.title2 = 'Asignaturas';
						break;
					case 4:
						this.title1 = 'Niveles';
						this.title2 = 'Grupos';
						break;
					case 5:
						this.title1 = 'Asignaturas';
						this.title2 = 'Grupos';
						break;
					case 6:
						this.title1 = 'Grupos';
						this.title2 = 'Alumnos';
						break;
					case 7:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
					case 8:
						this.title1 = 'Grupos';
						this.title2 = 'Alumnos';
						break;
					case 9:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
				}
			} else if(this.parametroID == 2){
				switch (this.consultaID){
					case 9:
						this.title1 = 'Asignaturas';
						this.title2 = 'Alumnos';
						break;
				}
			}
    }
		setTimeout(() =>	{
			if(this.parametroID == 1){
				this.createChartAciertos();
			}
			this.step = 1; //mostramos el primer paso del informe
		}, 200);
  }

	calcularPromedios(dataSets) {
    const numDatasets = dataSets.length;
    const numDataPoints = dataSets[0].data.length;
    const promedios = new Array(numDataPoints).fill(null).map(() => 0);
    const count = new Array(numDataPoints).fill(0);
    dataSets.forEach(profesor => {
        profesor.data.forEach((value, index) => {
            if (value !== null && value !== 0) {
                promedios[index] += value;
                count[index] += 1;
            }
        });
    });
    const promedioData = promedios.map((total, index) => {
        return count[index] > 0 ? parseFloat((total / count[index]).toFixed(1)) : null;
    });
    const backgroundColor = promedioData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: promedioData,
        backgroundColor: backgroundColor,
    };
	}

	colorKnowledge(valor: number): string {
    let color = "grey";
    if (valor == 0) color =  "#9A9B9C";
    else if (valor > 0 && valor < 11) color = "#FB0D1C";
    else if (valor < 21) color = "#FC5C20";
    else if (valor < 31) color = "#FC9A27";
    else if (valor < 41) color = "#FEE533";
    else if (valor < 51) color = "#FFFD38";
    else if (valor < 61) color = "#D4FD35";
    else if (valor < 71) color = "#9CFD32";
    else if (valor < 81) color = "#29F82E";
    else if (valor < 91) color = "#2AD52D";
    else if (valor < 101) color = "#1AAF54";
    return color;
  }

	obtenerLabels(dataSets, index) {
		const filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
    const filteredData = dataSets
        .map(info => info.data[index])
        .filter(value => value !== null);
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}


	createChartAciertos(){
    var canvas = document.getElementById('myChartControlGrupos') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (this.chart) {
        this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: this.labels,
            datasets: this.datasets
        },
				options: {
					responsive: true,
					plugins: {
						legend: {
							display: false,
							position: 'right',
						},
						title: {
							display: false,
							text: this.title2,
							font: {
								size: 20  // Tamaño de la fuente del título
							},
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									let label = context.dataset.label || ' ';
									label += context.raw + '%'; // Formatea los valores del tooltip como porcentajes
									return label;
								}
							}
						}
					},
					/**
					onClick: (event, elements) => {
						if (elements.length > 0) {
							if(this.step == 1){
								this.actualizarChart(elements[0].index);
							}
						}
					}
					*/
				},
    });
	}

}
