import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { finalize, Subject, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/core/services/login';
import { State } from 'src/app/store/models/store.model';
import { ReportingService } from '../service/reporting.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { MastersService } from 'src/app/core/services/masters';
import { ModalQuizesMultiplesComponent } from '../modal-quizes-multiples/modal-quizes-multiples.component';
import { RecordarQuizPlayComponent } from '../../quiz-open/quiz-play/quiz-play.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalInformationStudentComponent } from './modal-information-course/modal-information-student.component';

@Component({
  selector: 'app-tablaSW',
  templateUrl: './tablaSW.component.html',
  styleUrls: ['./tablaSW.component.scss'],
	standalone: false,
})
export class TablaSWComponent implements OnInit, AfterViewInit {
  @ViewChild('tablaGrid', { static: false }) tablaGrid: ElementRef;
  columnas: string[] = ['Preguntas', 'Estudiante 1', 'Estudiante 2', 'Estudiante 3'];
  datosSimulados: any[] = [];

  @Input() dataTable: any[] = [];
  @Input() reportType?: string = '';
  @Input() idGrupo: any;
  @Input() idGrafo?: any;
  @Input() idCourse?: any;
  @Input() listGraphSelected?: any[] = [];
  @Input() listChallengeSelected?: number[] = [];
  @Input() verActividades?: boolean = false;
  @Input() verAciertos?: boolean = false;
  @Input() verFotos?: boolean = false;
  @Input() verK?: boolean = false;
  @Output() reload: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild("aciertos") aciertos: ElementRef;

  public rowStudent: any[] = [];
  public rowAciertos: any[] = [];
  public rowtiempo: any[] = [];
  public bodyTable: any[] = [];
	public listUsers: any [] = [];

  public verTabla: boolean = true;

  public dataTable_AZ: any[] = [];
	public dataTable_ZA: any[] = [];
	public dataTable_aciertosDEC: any[] = [];
	public dataTable_aciertosASC: any[] = [];
	public dataTable_tiempoTotalDEC: any[] = [];
	public dataTable_tiempoTotalASC: any[] = [];

  loading: boolean = false;
  private destroy$ = new Subject();
  NOIMAGE: string = "../../../../../assets/images/icons/account_circle.svg";

  profile: any;
	profileTitles = {
		ESTUDIANTE: 'Estudiante',
		AUTOR: 'Editor',
		PROFESOR: 'Profesor',
		PADRE: 'Padre',
		ADMIN: 'Administrador'
	}
	profileTitle: string;

  selectedRowIndex: number | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<State>,
    public loginService: LoginService,
    private reportingService: ReportingService,
    public translateService: TranslateService,
    private toaster: ToasterService,
    private imagePipe: ImagenPipe,
    private quizService: QuizzesService,
    private modalService: NgbModal,
    private mastersService: MastersService,
  ) { }

  ngOnInit() {
    this.rowStudent = this.dataTable[0];
    this.rowAciertos = this.dataTable[1];
    if (this.reportType == 'target') {
      this.rowtiempo = this.dataTable[2];
      this.bodyTable = this.dataTable.slice(3);
    } else if (this.reportType == 'challenge') {
      this.bodyTable = this.dataTable.slice(2);
    } else if (this.reportType == 'control') {
      this.bodyTable = this.dataTable.slice(1);
    }
    this._listUsers();
    this.precargarFiltrosTabla();

    //obtenemos el perfil que esta activo
    this.store.select(store => store.selectedProfile).pipe(takeUntil(this.destroy$)).subscribe((selectedProfile) => {
      this.profile = selectedProfile['selectedProfile']
    });
    this.profile = this.loginService.getProfile();
    this.profileTitle = this.profileTitles[this.profile];
  }

  ngAfterViewInit() {
    this.generarColumnas();
  }

  generarColumnas() {
    if (this.tablaGrid && this.tablaGrid.nativeElement) {
      this.tablaGrid.nativeElement.style.setProperty('--num-columnas', (this.rowStudent.length - 1).toString());
    }
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  _listUsers(){
		//USAMOS ESTAS LISTAS PARA ORDENAR DESPUES LA TABLA
		this.listUsers = [];
		let listUsersName = [];
		let listUsersId = [];
		let listAciertos = [];
		let listTiempoTotal = [];
		for (let index = 2; index < this.dataTable[0].length; index++) {
			const element = this.dataTable[0][index];
			const element2 = this.dataTable[1][index];
			const element3 = this.dataTable[2][index];
			listUsersName.push(element.nameUser + ' ' + element.surnameUser);
			listUsersId.push(element.idUser);
			listAciertos.push(element2.porcentajeAciertosUser);
			if(this.reportType == 'target'){
				const tiempoString: string = element3.tiempo;
				// Dividir el string en horas, minutos y segundos
				const tiempoArray: string[] = tiempoString.split(':');
				// Convertir cada parte del tiempo a un número entero
				const horas: number = parseInt(tiempoArray[0], 10);
				const minutos: number = parseInt(tiempoArray[1], 10);
				const segundos: number = parseInt(tiempoArray[2], 10);
				// Calcular el tiempo total en segundos
				const tiempoTotalSegundos: number = horas * 3600 + minutos * 60 + segundos;
				listTiempoTotal.push(tiempoTotalSegundos);
			}
		}
		for (let index = 0; index < listUsersId.length; index++) {
			const user = {
				name: listUsersName[index],
				id: listUsersId[index],
				aciertos: listAciertos[index],
				tiempoTotal: this.reportType == 'target' ? listTiempoTotal[index]: null,
			};
			this.listUsers.push(user);
		}
	}

  precargarFiltrosTabla() {
		const list = ['A-Z', 'Z-A', 'aciertosDEC', 'aciertosASC', 'tiempoTotalDEC', 'tiempoTotalASC'];
		const orderFunctions = {
			'A-Z': (a, b) => a.name.localeCompare(b.name),
			'Z-A': (a, b) => b.name.localeCompare(a.name),
			'aciertosDEC': (a, b) => b.aciertos - a.aciertos,
			'aciertosASC': (a, b) => a.aciertos - b.aciertos,
			'tiempoTotalDEC': (a, b) => b.tiempoTotal - a.tiempoTotal,
			'tiempoTotalASC': (a, b) => a.tiempoTotal - b.tiempoTotal,
		};
		for (const order of list) {
			this.listUsers.sort(orderFunctions[order]);
			this.filtrosTabla(this.listUsers, order);
		}
	}

  filtrosTabla(listUsers: any [], order: any){
		//this.dataTable = [];
		this.loading = true;
		let rol = this.profileTitle === 'Estudiante' ? 2 : 3;
		let usersOrder = [];
		for (let index = 0; index < listUsers.length; index++) {
			usersOrder.push(listUsers[index].id);
		}
		if(this.reportType == 'target'){
			this.reportingService
				.informeDataTableTargets(this.idGrupo, this.listGraphSelected, rol, usersOrder)
				.subscribe(
					(result) => {
						if(order == 'A-Z'){
							this.dataTable_AZ = Object.values(result.data);
						} else if(order == 'Z-A'){
							this.dataTable_ZA = Object.values(result.data);
						} else if(order == 'aciertosDEC'){
							this.dataTable_aciertosDEC = Object.values(result.data);
						} else if(order == 'aciertosASC'){
							this.dataTable_aciertosASC = Object.values(result.data);
						} else if(order == 'tiempoTotalDEC'){
							this.dataTable_tiempoTotalDEC = Object.values(result.data);
						} else if(order == 'tiempoTotalASC'){
							this.dataTable_tiempoTotalASC = Object.values(result.data);
						}
						this.loading = false;
					},
				(err) =>
					this.toaster.error(
						this.translateService.instant("INFORMES.ERRORQUIZZES")
					)
			);
		} else if(this.reportType == 'challenge'){
			this.reportingService
			.informeDataTableChallenges(this.idGrupo, this.listChallengeSelected, true, usersOrder)
			.subscribe(
				(result) => {
					if(order == 'A-Z'){
						this.dataTable_AZ = Object.values(result.data);
					} else if(order == 'Z-A'){
						this.dataTable_ZA = Object.values(result.data);
					} else if(order == 'aciertosDEC'){
						this.dataTable_aciertosDEC = Object.values(result.data);
					} else if(order == 'aciertosASC'){
						this.dataTable_aciertosASC = Object.values(result.data);
					} else if(order == 'tiempoTotalDEC'){
						this.dataTable_tiempoTotalDEC = Object.values(result.data);
					} else if(order == 'tiempoTotalASC'){
						this.dataTable_tiempoTotalASC = Object.values(result.data);
					}
					this.loading = false;
				},
			(err) =>
				this.toaster.error(
					this.translateService.instant("INFORMES.ERRORQUIZZES")
				)
			);
		}
	}

  formatoTiempo(tiempo: string){
		let formatedTime = "0s";
		//dividimos el tiempo en horas, minutos y segundos
		const partes = tiempo.split(':');
		const horas = Number(partes[0]);
		const minutos = Number(partes[1]);
		const segundos = Number(partes[2]);
		//aproximamos las horas y minutos
		if(horas > 0 && minutos > 30){
			const hours = horas + 1;
			formatedTime = hours + "h";
		} else if(horas > 0 && minutos <= 30){
			formatedTime = horas + "h";
		} else if(horas == 0 && minutos > 30){
			formatedTime = "1h";
		} else if(horas == 0 && minutos <= 30 && segundos > 30){
			const minutes = minutos + 1;
			formatedTime = minutes + "m";
		} else if(horas == 0 && minutos <= 30 && minutos > 0 && segundos <= 30){
			formatedTime = minutos + "m";
		} else if(horas == 0 && minutos == 0 && segundos > 30){
			formatedTime = "1m";
		} else if(horas == 0 && minutos == 0 && segundos <= 30 && segundos > 0){
			formatedTime = segundos + "s";
		}
		return formatedTime;
	}

  getUserAvatar(imagen):string{
    let image:string = ''
    imagen!== null && imagen !== ""? image = this.imagePipe.transform(imagen, 'userAvatar') : image = this.NOIMAGE
    return `url('${image}')`
  }

  changeVerAcierto(){
		this.verAciertos = !this.verAciertos;
	}

  aplicarFiltro(order) {
    this.verTabla = false;
    switch (order) {
      case 'A-Z':
        this.dataTable = this.dataTable_AZ;
        break;
      case 'Z-A':
        this.dataTable = this.dataTable_ZA;
        break;
      case 'aciertosDEC':
        this.dataTable = this.dataTable_aciertosDEC;
        break;
      case 'aciertosASC':
        this.dataTable = this.dataTable_aciertosASC;
        break;
      case 'tiempoTotalDEC':
        this.dataTable = this.dataTable_tiempoTotalDEC;
        break;
      case 'tiempoTotalASC':
        this.dataTable = this.dataTable_tiempoTotalASC;
        break;
    }
    this.rowStudent = this.dataTable[0];
    this.rowAciertos = this.dataTable[1];
    if (this.reportType == 'target') {
      this.rowtiempo = this.dataTable[2];
      this.bodyTable = this.dataTable.slice(3);
    } else if (this.reportType == 'challenge') {
      this.bodyTable = this.dataTable.slice(2);
    }
    setTimeout(() => {
      this.verTabla = true;
    }, 100)
  }

  viewQuiz(node: any) {
    if (this.profile == 'PROFESOR' && node.isNode == 0) {
      //Creamos el log
      let storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
      const bodyLog1 = {
        action: "informes",
        actionData: "Ha revisado el quiz con id " + node.idQuiz + ".",
        startDate: new Date(),
        endingDate: null,
        role: this.loginService.getProfile(),
        idGroup: storedGroupInfo ? storedGroupInfo.idGroup : 0,
      };
      this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
      if (node.multiple === 1) {
        let storedGroupInfo = JSON.parse(localStorage.getItem('selectedGroupInfo'));
        const idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
        this.quizService.getQuizInformMultipleHijos(node.idQuiz, node.idUser, idGrupo).pipe(finalize(() => this.loading = false)).subscribe((res: any) => {

          const modalRef = this.modalService.open(ModalQuizesMultiplesComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W35, backdrop: 'static' })
          modalRef.componentInstance.quizesMultiplesList = res;
          modalRef.componentInstance.idUser = node.idUser;

          modalRef.result.then(res => {
            if (res) {
              this.reload.emit(this.listUsers);
            }
          })
        });
      } else {
        this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.loading = false)).subscribe((res: any) => {
          const modalRef = this.modalService.open(RecordarQuizPlayComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
          //modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
          modalRef.componentInstance.quiz = res.quiz;
          modalRef.componentInstance.elements = res.elementsQuestion;
          modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
          modalRef.componentInstance.courseId = node.idCourse;
          modalRef.componentInstance.graphId = node.idTarget;
          modalRef.componentInstance.isEvaluation = true;
          modalRef.componentInstance.fechaUltimaRespuesta = node.fechaUltimaRespuesta;

          modalRef.result.then(res => {
            if (true) {
              this.reload.emit(this.listUsers);
            }
          })
        });
      }
    } else if (this.profile == 'ESTUDIANTE' && node.isNode == 0) {
      if (node.isMultiplex != 0) {
        const curso = node.idCourse === 0 ? this.idCourse : node.idCourse;
        const grafo = node.idTarget === 0 ? this.idGrafo : node.idTarget;
        this.quizService.getQuiz(node.idQuiz, curso, grafo).subscribe(res => {

          if (res.quiz.quizType === 1) {
            this.quizService.getQuizInforme(node.idQuiz, node.idUser).pipe(finalize(() => this.loading = false)).subscribe((res: any) => {
              const modalRef = this.modalService.open(RecordarQuizPlayComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W100, backdrop: 'static' })
              modalRef.componentInstance.quiz = { ...res.quiz, user: res.quiz.user, idOriginal: res.quiz.idOriginal, id: res.quiz.idQuiz, originalX: res.quiz.originalX, originalY: res.quiz.originalY, size: res.quiz.size, sizeQuiz: res.quiz.sizeQuiz, x: res.quiz.x, y: res.quiz.y };;
              modalRef.componentInstance.elements = res.elementsQuestion;
              modalRef.componentInstance.respuestaEstudiante = res.elementsAnswer;
              modalRef.componentInstance.courseId = node.idCourse;
              modalRef.componentInstance.graphId = node.idTarget;
              modalRef.componentInstance.isEvaluation = false;
              modalRef.componentInstance.fechaUltimaRespuesta = node.fechaUltimaRespuesta;

              modalRef.result.then(res => {
                // Falta enviar la data para cambiar los valores y actualizar el backend
                this.reload.emit(this.listUsers);
              })
            });
          } else {
            this.toaster.error(this.translateService.instant("INFORMES.ONLYOPENANSWER")
            )
          }
        })
      }
    } else if (this.profile == 'PADRE') {
      // si es un padre no debe hacer nada
    }
  }

  selectRow(index: number) {
    this.selectedRowIndex = index;
  }

  viewStudent(student: any) {
    console.log(student);
    const modalRef = this.modalService.open(ModalInformationStudentComponent, { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W30, backdrop: 'static' })
    modalRef.componentInstance.student = student;

    modalRef.result.then(res => { });
  }

  viewElement(element: any){
    console.log(element);
  }

}
