import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NodeFilesFormat } from 'src/app/core/models/nodes/node-files-format.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-modal-spotify-audio',
    templateUrl: './modal-spotify-audio.component.html',
    styleUrls: ['./modal-spotify-audio.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalSpotifyAudioComponent implements OnInit {

	public format: NodeFilesFormat;
	public node: any;

	validationMessages = {
		url: []
	};

	form:UntypedFormGroup

	constructor(private fb: UntypedFormBuilder,
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private toaster: ToasterService
	) {
		this.form = this.fb.group({
			url: ['', Validators.required],
			isDelete: [false],
			idNodeFile: [0]
		});
	}

	traducirOpciones():void{
		this.translateService.get('SPOTIFYAUDIO.LINKREQUIRED').subscribe((res: string) => {
				this.validationMessages.url.push({ type: 'required', message: res });
		});
	}

	get urlControl() {
		if (this.form) {
				return (this.form.get('url') as UntypedFormControl);
		} else {
				return null;
		}
	}

	ngOnInit() {
		this.traducirOpciones()
		this.getFormatValues()
	}

	postAudio(){
    if(this.form.valid){
        const regExp = /^https:\/\/open\.spotify\.com\/(?:[^\/?]+\/)?(?:[^\/?]+\/)?(?:[^\/?]+\/)?([a-zA-Z0-9]+)(?:\?.*)?$/;
        const match = this.form.get('url').value.match(regExp);
        if (match && match[1]) {
            this.form.get('idNodeFile').setValue(this.format.idNodeFile);
            this.closeModal(this.form.value);
        } else {
            this.toaster.error(this.translateService.instant('SPOTIFYAUDIO.ERRORID'));
        }
    }
	}

	getFormatValues(){
		this.form.get('url').setValue(this.node.nodesFiles.spotifyAudio);
	}

	delete(){
		if(this.form.valid){
			this.form.get('isDelete').setValue(true);
			this.form.get('idNodeFile').setValue(this.format.idNodeFile)
			this.closeModal(this.form.value)
		}
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

}
