import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-help-component',
    templateUrl: './modal-help.component.html',
    styleUrls: ['./modal-help.component.scss'],
    standalone: false
})
export class ModalHelpComponent implements OnInit {

  images: Array<string> = []
  currentImage: number = 0

  constructor(
    public activeModal: NgbActiveModal
  )
  {
    this.images = [
      '../../../../assets/images/demo_images/help.png',
      '../../../../assets/images/demo_images/help1.png',
      '../../../../assets/images/demo_images/help2.png'
    ]

  }

  ngOnInit() {
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  changeImage(){
    if(this.currentImage != this.images.length -1)
      this.currentImage++
  }

}
