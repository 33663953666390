import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { QuestionModel } from 'src/app/core/models/surveys/question.model';
import { OptionsModel } from 'src/app/core/models/surveys/options.model';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';

@Component({
    selector: 'app-encuestas-preguntas-edit',
    templateUrl: './encuestas-preguntas-edit.component.html',
    styleUrls: ['./encuestas-preguntas-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EncuestasPreguntasEditComponent implements OnInit {
	@Input() question: any;
	@Input() idEncuesta: number;

	cargando = false;
	questionTypeButton: number = 1;

	constructor(
		public activeModal: NgbActiveModal,
		private fb: FormBuilder,
		public surveysService: SurveysService,
		public translateService: TranslateService,
		private toaster: ToasterService,
	){}

  ngOnInit(): void {
		this.questionTypeButton = this.question ? this.question.type : 1;
		this.question.encuestas = {idEncuestas: this.idEncuesta, title: null};
  }

	closeModal(sendData?: any) {
		this.saveQuestion(true);
	}

	changeQuestionType(type: number){
		this.questionTypeButton = type;
		this.question.type = type;
	}

	saveQuestion(closeAfterSave = false) {
		/**
		let q: QuestionModel = new QuestionModel(
			this.question.idEncuestasPreguntas,
			this.idEncuesta,
			this.question.title,
			this.question.type,
			1
		);
		setTimeout(() => {
			this.surveysService.createUpdateQuestion(q).subscribe(res => {
				if (res.data) {
					//ahora actualizamos las opciones
					this.surveysService.createUpdateOptionsList(this.question.options).subscribe(re => {
						if(re.data){
							this.question.options = re.data;
						}
						this.toaster.success(this.translateService.instant('ENCUESTAS.QUESTIONEDITEDOK'));
					});
					// Si se indica cerrar el modal después de guardar
					if (closeAfterSave) {
						this.activeModal.close(q);
					}
				} else {
					this.toaster.error(this.translateService.instant('ENCUESTAS.QUESTIONEDITEDKO'));
				}
			});
		}, 300);
		*/
	}

	newOption(){
		/**
		let opt: OptionsModel = new OptionsModel(
			0,
			this.question.idEncuestasPreguntas,
			this.translateService.instant('ENCUESTAS.OPTIONNEW')
		);
		this.question.options.push(opt);
	}

	deleteOption(option: any){
		this.question.options = this.question.options.filter(
			(opt: any) => opt !== option
		);
		if(option.idEncuestasPreguntasOpciones != 0){

		}
		*/
	}

}
