import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-modal-eliminar-editor',
    templateUrl: './modal-eliminar-editor.component.html',
    styleUrls: ['./modal-eliminar-editor.component.scss'],
    standalone: false
})
export class ModalEliminarEditorComponent implements OnInit {
  mensaje: string;

  constructor(
    public translateService: TranslateService,
              private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }


  aceptar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.close(false);
  }

  closeModal(sendData?: any) {
    this.activeModal.close(false);
  }

}
