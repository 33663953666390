import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const PDF_URL = 'https://salware.com/images/homepage/pdf/';

@Component({
    selector: 'app-dialog-policy',
    templateUrl: './dialog-policy.component.html',
    styleUrls: ['./dialog-policy.component.scss'],
    standalone: false
})
export class DialogPolicyComponent implements OnInit {

	privacyPolicy: string

  constructor() { }

  ngOnInit() {
		this.privacyPolicy = `${PDF_URL}${environment.privacyPDF}`

		const archivoPDF = document.getElementById("archivoPDF").setAttribute("data", this.privacyPolicy);
  }

}
