import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NbAccordionModule, NbActionsModule, NbCardModule, NbFormFieldModule, NbIconModule, NbSelectModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-modal-teachers-list",
    imports: [
        CommonModule,
        NbCardModule,
        NbIconModule,
        NbTooltipModule,
        NbSelectModule,
        NbActionsModule,
        NbFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NbAccordionModule,
    ],
    templateUrl: "./modal-teachers-list.component.html",
    styleUrls: ["./modal-teachers-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTeachersListComponent {
	formulario: UntypedFormGroup;
	constructor(
		public activeModal: NgbActiveModal,
		private formBuild: FormBuilder,
		public translateService: TranslateService
	) {
		//this.inactivityService.startInactivityTimer();
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.formulario = this.formBuild.group({ filtrado: [""] });
	}
	cameFromCenter = false;
	selectedOrder = 1;
	iconSelected = false;
	historyFilterActive: boolean;
	orderList = [
		{
			nombre: "A - Z",
			idOrder: 1,
		},
		{
			nombre: "Z - A",
			idOrder: 2,
		},
		{
			nombre: "Más recientes",
			idOrder: 3,
		},
		{
			nombre: "Más antiguos",
			idOrder: 4,
		},
		{
			nombre: "Editados Recientemente",
			idOrder: 5,
		},
	];

	teachers = [
    {
        "nombre": "Roberto",
        "apellido": "Alonso",
        "materia": "Matemáticas",
        "avatar": "https://i.pravatar.cc/150?img=70",
        "color": "#FC9A27"
    },
    {
        "nombre": "Marta",
        "apellido": "Fernández",
        "materia": "Lengua",
        "avatar": "https://i.pravatar.cc/150?img=69",
        "color": "#FEE533"
    },
    {
        "nombre": "Antonio",
        "apellido": "García",
        "materia": "Historia",
        "avatar": "https://i.pravatar.cc/150?img=68",
        "color": "#FFFD38"
    },
    {
        "nombre": "Pilar",
        "apellido": "Martínez",
        "materia": "Geografía",
        "avatar": "https://i.pravatar.cc/150?img=67",
        "color": "#D4FD35"
    },
    {
        "nombre": "Luis",
        "apellido": "Sánchez",
        "materia": "Física",
        "avatar": "https://i.pravatar.cc/150?img=66",
        "color": "#9CFD32"
    },
    {
        "nombre": "Rosa",
        "apellido": "Rodríguez",
        "materia": "Química",
        "avatar": "https://i.pravatar.cc/150?img=65",
        "color": "#FC5C20"
    },
    {
        "nombre": "Javier",
        "apellido": "Gómez",
        "materia": "Biología",
        "avatar": "https://i.pravatar.cc/150?img=64",
        "color": "#FEE533"
    },
    {
        "nombre": "Carmen",
        "apellido": "Hernández",
        "materia": "Filosofía",
        "avatar": "https://i.pravatar.cc/150?img=63",
        "color": "#FFFD38"
    },
    {
        "nombre": "José",
        "apellido": "Ruiz",
        "materia": "Educación Física",
        "avatar": "https://i.pravatar.cc/150?img=62",
        "color": "#D4FD35"
    },
    {
        "nombre": "María",
        "apellido": "Díaz",
        "materia": "Arte",
        "avatar": "https://i.pravatar.cc/150?img=61",
        "color": "#9CFD32"
    },
    {
        "nombre": "Miguel",
        "apellido": "López",
        "materia": "Música",
        "avatar": "https://i.pravatar.cc/150?img=60",
        "color": "#FC9A27"
    },
    {
        "nombre": "Teresa",
        "apellido": "Martín",
        "materia": "Tecnología",
        "avatar": "https://i.pravatar.cc/150?img=59",
        "color": "#FEE533"
    },
    {
        "nombre": "Juan",
        "apellido": "Jiménez",
        "materia": "Inglés",
        "avatar": "https://i.pravatar.cc/150?img=58",
        "color": "#FFFD38"
    },
    {
        "nombre": "Laura",
        "apellido": "Moreno",
        "materia": "Francés",
        "avatar": "https://i.pravatar.cc/150?img=57",
        "color": "#D4FD35"
    },
    {
        "nombre": "Fernando",
        "apellido": "Pérez",
        "materia": "Economía",
        "avatar": "https://i.pravatar.cc/150?img=56",
        "color": "#9CFD32"
    },
    {
        "nombre": "Inés",
        "apellido": "Gutiérrez",
        "materia": "Informática",
        "avatar": "https://i.pravatar.cc/150?img=55",
        "color": "#FC5C20"
    },
    {
        "nombre": "Ricardo",
        "apellido": "Castro",
        "materia": "Religión",
        "avatar": "https://i.pravatar.cc/150?img=54",
        "color": "#FEE533"
    },
    {
        "nombre": "Sara",
        "apellido": "Ramos",
        "materia": "Ciencias Sociales",
        "avatar": "https://i.pravatar.cc/150?img=53",
        "color": "#FFFD38"
    },
    {
        "nombre": "Francisco",
        "apellido": "Ortega",
        "materia": "Ciencias Naturales",
        "avatar": "https://i.pravatar.cc/150?img=52",
        "color": "#D4FD35"
    },
    {
        "nombre": "Isabel",
        "apellido": "Gil",
        "materia": "Literatura",
        "avatar": "https://i.pravatar.cc/150?img=51",
        "color": "#9CFD32"
    },
    {
        "nombre": "Ramón",
        "apellido": "Molina",
        "materia": "Educación Cívica",
        "avatar": "https://i.pravatar.cc/150?img=50",
        "color": "#FC9A27"
    },
    {
        "nombre": "Alicia",
        "apellido": "Castillo",
        "materia": "Psicología",
        "avatar": "https://i.pravatar.cc/150?img=49",
        "color": "#FEE533"
    },
    {
        "nombre": "Guillermo",
        "apellido": "Romero",
        "materia": "Filosofía",
        "avatar": "https://i.pravatar.cc/150?img=48",
        "color": "#FFFD38"
    },
    {
        "nombre": "Elena",
        "apellido": "Suárez",
        "materia": "Sociología",
        "avatar": "https://i.pravatar.cc/150?img=47",
        "color": "#D4FD35"
    },
    {
        "nombre": "Ángel",
        "apellido": "Hidalgo",
        "materia": "Matemáticas",
        "avatar": "https://i.pravatar.cc/150?img=46",
        "color": "#9CFD32"
    },
    {
        "nombre": "Raquel",
        "apellido": "Torres",
        "materia": "Física",
        "avatar": "https://i.pravatar.cc/150?img=45",
        "color": "#FC5C20"
    },
    {
        "nombre": "Vicente",
        "apellido": "Vargas",
        "materia": "Química",
        "avatar": "https://i.pravatar.cc/150?img=44",
        "color": "#FEE533"
    },
    {
        "nombre": "Ana",
        "apellido": "León",
        "materia": "Biología",
        "avatar": "https://i.pravatar.cc/150?img=43",
        "color": "#FFFD38"
    },
    {
        "nombre": "Julio",
        "apellido": "García",
        "materia": "Historia",
        "avatar": "https://i.pravatar.cc/150?img=42",
        "color": "#D4FD35"
    },
    {
        "nombre": "Victoria",
        "apellido": "Vega",
        "materia": "Geografía",
        "avatar": "https://i.pravatar.cc/150?img=41",
        "color": "#9CFD32"
    },
    {
        "nombre": "Alberto",
        "apellido": "Santos",
        "materia": "Educación Física",
        "avatar": "https://i.pravatar.cc/150?img=40",
        "color": "#FC9A27"
    },
    {
        "nombre": "Cristina",
        "apellido": "Mora",
        "materia": "Arte",
        "avatar": "https://i.pravatar.cc/150?img=39",
        "color": "#FEE533"
    },
    {
        "nombre": "Jorge",
        "apellido": "Cabrera",
        "materia": "Música",
        "avatar": "https://i.pravatar.cc/150?img=38",
        "color": "#FFFD38"
    },
    {
        "nombre": "Dolores",
        "apellido": "Reyes",
        "materia": "Tecnología",
        "avatar": "https://i.pravatar.cc/150?img=37",
        "color": "#D4FD35"
    },
    {
        "nombre": "Manuel",
        "apellido": "Luna",
        "materia": "Inglés",
        "avatar": "https://i.pravatar.cc/150?img=36",
        "color": "#9CFD32"
    },
    {
        "nombre": "Susana",
        "apellido": "Navarro",
        "materia": "Francés",
        "avatar": "https://i.pravatar.cc/150?img=35",
        "color": "#FC5C20"
    },
    {
        "nombre": "Emilio",
        "apellido": "Aguilar",
        "materia": "Economía",
        "avatar": "https://i.pravatar.cc/150?img=34",
        "color": "#FEE533"
    },
    {
        "nombre": "Rafael",
        "apellido": "Pérez",
        "materia": "Informática",
        "avatar": "https://i.pravatar.cc/150?img=33",
        "color": "#FFFD38"
    },
    {
        "nombre": "Victoria",
        "apellido": "Jiménez",
        "materia": "Religión",
        "avatar": "https://i.pravatar.cc/150?img=32",
        "color": "#D4FD35"
    },
    {
        "nombre": "Gonzalo",
        "apellido": "Mendoza",
        "materia": "Ciencias Sociales",
        "avatar": "https://i.pravatar.cc/150?img=31",
        "color": "#9CFD32"
    },
    {
        "nombre": "Gloria",
        "apellido": "Ortega",
        "materia": "Ciencias Naturales",
        "avatar": "https://i.pravatar.cc/150?img=30",
        "color": "#FC5C20"
    },
    {
        "nombre": "Ricardo",
        "apellido": "Marín",
        "materia": "Literatura",
        "avatar": "https://i.pravatar.cc/150?img=29",
        "color": "#FEE533"
    },
    {
        "nombre": "Eva",
        "apellido": "Guzmán",
        "materia": "Educación Cívica",
        "avatar": "https://i.pravatar.cc/150?img=28",
        "color": "#FFFD38"
    },
    {
        "nombre": "Alfonso",
        "apellido": "Rivas",
        "materia": "Psicología",
        "avatar": "https://i.pravatar.cc/150?img=27",
        "color": "#D4FD35"
    },
    {
        "nombre": "María",
        "apellido": "Flores",
        "materia": "Sociología",
        "avatar": "https://i.pravatar.cc/150?img=26",
        "color": "#9CFD32"
    },
    {
        "nombre": "Jesús",
        "apellido": "Crespo",
        "materia": "Filosofía",
        "avatar": "https://i.pravatar.cc/150?img=25",
        "color": "#FC5C20"
    },
    {
        "nombre": "Lorena",
        "apellido": "Núñez",
        "materia": "Geografía",
        "avatar": "https://i.pravatar.cc/150?img=24",
        "color": "#FEE533"
    },
    {
        "nombre": "Hugo",
        "apellido": "Román",
        "materia": "Historia",
        "avatar": "https://i.pravatar.cc/150?img=23",
        "color": "#FFFD38"
    },
    {
        "nombre": "Clara",
        "apellido": "López",
        "materia": "Lengua",
        "avatar": "https://i.pravatar.cc/150?img=22",
        "color": "#D4FD35"
    },
    {
        "nombre": "Carlos",
        "apellido": "Medina",
        "materia": "Matemáticas",
        "avatar": "https://i.pravatar.cc/150?img=21",
        "color": "#9CFD32"
    },
    {
        "nombre": "Isabel",
        "apellido": "Moreno",
        "materia": "Ciencias Naturales",
        "avatar": "https://i.pravatar.cc/150?img=20",
        "color": "#FC5C20"
    }
]


	teachersByLevel = [
    {
        "nivel": "Educación Infantil",
        "profesores": [
            {
                "nombre": "Ana",
                "apellido": "López",
                "asignatura": "Desarrollo Personal",
                "avatar": "https://i.pravatar.cc/150?img=70",
                "color": "#FFFD38"
            },
            {
                "nombre": "Carlos",
                "apellido": "Martínez",
                "asignatura": "Juego y Expresión",
                "avatar": "https://i.pravatar.cc/150?img=69",
                "color": "#D4FD35"
            },
            {
                "nombre": "Elena",
                "apellido": "García",
                "asignatura": "Motricidad",
                "avatar": "https://i.pravatar.cc/150?img=68",
                "color": "#9CFD32"
            },
            {
                "nombre": "Javier",
                "apellido": "Fernández",
                "asignatura": "Educación Emocional",
                "avatar": "https://i.pravatar.cc/150?img=67",
                "color": "#FC9A27"
            },
            {
                "nombre": "Laura",
                "apellido": "Sánchez",
                "asignatura": "Exploración del Entorno",
                "avatar": "https://i.pravatar.cc/150?img=66",
                "color": "#FEE533"
            }
        ]
    },
    {
        "nivel": "Educación Primaria",
        "profesores": [
            {
                "nombre": "Miguel",
                "apellido": "Rodríguez",
                "asignatura": "Matemáticas",
                "avatar": "https://i.pravatar.cc/150?img=65",
                "color": "#FFFD38"
            },
            {
                "nombre": "Sofía",
                "apellido": "Hernández",
                "asignatura": "Lengua y Literatura",
                "avatar": "https://i.pravatar.cc/150?img=64",
                "color": "#D4FD35"
            },
            {
                "nombre": "Pablo",
                "apellido": "Pérez",
                "asignatura": "Ciencias Naturales",
                "avatar": "https://i.pravatar.cc/150?img=63",
                "color": "#9CFD32"
            },
            {
                "nombre": "María",
                "apellido": "González",
                "asignatura": "Ciencias Sociales",
                "avatar": "https://i.pravatar.cc/150?img=62",
                "color": "#FC9A27"
            },
            {
                "nombre": "Raúl",
                "apellido": "Jiménez",
                "asignatura": "Educación Física",
                "avatar": "https://i.pravatar.cc/150?img=61",
                "color": "#FEE533"
            }
        ]
    },
    {
        "nivel": "Educación Secundaria Obligatoria (ESO)",
        "profesores": [
            {
                "nombre": "Fernando",
                "apellido": "Ruiz",
                "asignatura": "Matemáticas",
                "avatar": "https://i.pravatar.cc/150?img=60",
                "color": "#FFFD38"
            },
            {
                "nombre": "Inés",
                "apellido": "Díaz",
                "asignatura": "Lengua Castellana y Literatura",
                "avatar": "https://i.pravatar.cc/150?img=59",
                "color": "#D4FD35"
            },
            {
                "nombre": "David",
                "apellido": "Vázquez",
                "asignatura": "Física y Química",
                "avatar": "https://i.pravatar.cc/150?img=58",
                "color": "#9CFD32"
            },
            {
                "nombre": "Rocío",
                "apellido": "Moreno",
                "asignatura": "Biología y Geología",
                "avatar": "https://i.pravatar.cc/150?img=57",
                "color": "#FC9A27"
            },
            {
                "nombre": "Jorge",
                "apellido": "Romero",
                "asignatura": "Geografía e Historia",
                "avatar": "https://i.pravatar.cc/150?img=56",
                "color": "#FEE533"
            }
        ]
    },
    {
        "nivel": "Bachillerato",
        "profesores": [
            {
                "nombre": "Antonio",
                "apellido": "Torres",
                "asignatura": "Matemáticas II",
                "avatar": "https://i.pravatar.cc/150?img=55",
                "color": "#FFFD38"
            },
            {
                "nombre": "Carmen",
                "apellido": "Lorenzo",
                "asignatura": "Filosofía",
                "avatar": "https://i.pravatar.cc/150?img=54",
                "color": "#D4FD35"
            },
            {
                "nombre": "Luis",
                "apellido": "Muñoz",
                "asignatura": "Historia de España",
                "avatar": "https://i.pravatar.cc/150?img=53",
                "color": "#9CFD32"
            },
            {
                "nombre": "Eva",
                "apellido": "Ramírez",
                "asignatura": "Lengua Extranjera (Inglés)",
                "avatar": "https://i.pravatar.cc/150?img=52",
                "color": "#FC9A27"
            },
            {
                "nombre": "Teresa",
                "apellido": "Ortega",
                "asignatura": "Dibujo Técnico",
                "avatar": "https://i.pravatar.cc/150?img=51",
                "color": "#FEE533"
            }
        ]
    },
    {
        "nivel": "Formación Profesional",
        "profesores": [
            {
                "nombre": "Alberto",
                "apellido": "Sanz",
                "asignatura": "Administración y Finanzas",
                "avatar": "https://i.pravatar.cc/150?img=50",
                "color": "#FFFD38"
            },
            {
                "nombre": "Beatriz",
                "apellido": "Molina",
                "asignatura": "Sistemas Microinformáticos y Redes",
                "avatar": "https://i.pravatar.cc/150?img=49",
                "color": "#D4FD35"
            },
            {
                "nombre": "Gonzalo",
                "apellido": "Vidal",
                "asignatura": "Desarrollo de Aplicaciones Multiplataforma",
                "avatar": "https://i.pravatar.cc/150?img=48",
                "color": "#9CFD32"
            },
            {
                "nombre": "Lucía",
                "apellido": "Navarro",
                "asignatura": "Gestión Administrativa",
                "avatar": "https://i.pravatar.cc/150?img=47",
                "color": "#FC9A27"
            },
            {
                "nombre": "Ramón",
                "apellido": "Castro",
                "asignatura": "Mecatrónica Industrial",
                "avatar": "https://i.pravatar.cc/150?img=46",
                "color": "#FEE533"
            }
        ]
    },
    {
        "nivel": "Universidad",
        "profesores": [
            {
                "nombre": "Ignacio",
                "apellido": "Herrera",
                "asignatura": "Ingeniería Informática",
                "avatar": "https://i.pravatar.cc/150?img=45",
                "color": "#FFFD38"
            },
            {
                "nombre": "Patricia",
                "apellido": "Serrano",
                "asignatura": "Medicina",
                "avatar": "https://i.pravatar.cc/150?img=44",
                "color": "#D4FD35"
            },
            {
                "nombre": "Tomás",
                "apellido": "Rivas",
                "asignatura": "Derecho",
                "avatar": "https://i.pravatar.cc/150?img=43",
                "color": "#9CFD32"
            },
            {
                "nombre": "Marta",
                "apellido": "Román",
                "asignatura": "Arquitectura",
                "avatar": "https://i.pravatar.cc/150?img=42",
                "color": "#FC9A27"
            },
            {
                "nombre": "Federico",
                "apellido": "Iglesias",
                "asignatura": "Biología",
                "avatar": "https://i.pravatar.cc/150?img=41",
                "color": "#FEE533"
            }
        ]
    }
]


	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	changeOrder(order: any) {
	}
	historyFilter() {}

	openFiltersPanel() {}
	closeFiltersPanel() {}
}
