import { Component, Input, OnInit } from '@angular/core';
import { Operator } from 'src/app/core/models/operators/operator.model';

@Component({
    selector: 'app-operator-icon',
    templateUrl: './operator-icon.component.html',
    styleUrls: ['./operator-icon.component.scss'],
    standalone: false
})
export class OperatorIconComponent implements OnInit {
    @Input() operator: Operator;

    constructor() { }

    ngOnInit() {
    }

}
