<div class="w-100 h-100">
    <div class="d-flex flex-column">
        <div >
            <app-recordar-quiz-play-body-info class="mt-4" [quiz]="quiz" [timer$]="time$"
                (timeEnded)="onTimeEnded($event)" [stopTime]="stopTime"></app-recordar-quiz-play-body-info>
        </div>
        <div class="mt-2">
            <!-- <h4>{{'QUIZPLAY.DATA' | translate}}</h4> -->
            <app-recordar-quiz-play-body-data [questions]="questions"></app-recordar-quiz-play-body-data>
        </div>
        <div class="mt-2">
            <!-- <h4 class="mb-4">{{'QUIZPLAY.RESPONSE' | translate}}</h4> -->
            <app-recordar-quiz-play-body-option *ngIf="quiz" [options]="options" [respuestaEstudiante]="respuestaEstudiante" [fechaUltimaRespuesta]="fechaUltimaRespuesta"  (saveOption)="onSaveOption($event)" (saveOptionMultiple)="saveOptionMultiple($event)"  (saveOptionText)="saveTxt($event)" [quiz]="quiz" [quizType]="quizType" [answered]="answered"
            (reavelRespuesta)="discoverAnswer($event)"></app-recordar-quiz-play-body-option>
        </div>

        <div *ngIf="!answered" class="col-12 d-flex justify-content-center solution-button">
            <button mat-icon-button matTooltip="Mostrar solución" matTooltipClass="black-tooltip" class="circle-button d-flex justify-content-center" (click)="discoverAnswer()" [disabled]="answered">
                <mat-icon svgIcon="done" class="icon-color-blue" [ngClass]=" answered? 'hidden': 'visible' "></mat-icon>
            </button>
        </div>
        <div *ngIf="!answered" class="col-12 col-12 d-flex justify-content-center solution-button">
            <span class="comprobar">{{'QUIZPLAY.CHECK' | translate}}</span>
        </div>

        <div class="mt-4 mb-4" *ngIf="answered">
            <!-- <h4>{{'QUIZPLAY.ANSWER' | translate}}</h4> -->
            <p><strong>{{'QUIZPLAY.ANSWER' | translate}}</strong></p>
            <app-recordar-quiz-play-body-answer [answers]="answers"  ></app-recordar-quiz-play-body-answer>
        </div>

        <!-- <div class="row  mt-5 flex justify-content-center">
            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center" *ngIf="quizType == quizTypes.TEXT || quiz.idMultiplexQuiz === 1">
                <button type="button" class="btnDelete  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton" (click)="successOrNotSuccessful(false)">
                    <mat-icon class="icon-color-blue">close</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.ERROR' | translate}}</span>
                </button>
            </div>
            <div *ngIf="answered " class="col-4 col-sm-12 col-md-4 col-lg-4"></div>

            <div class="col-4 col-sm-12 col-md-4 col-lg-4  flex align-items-center justify-content-center" *ngIf="quizType == quizTypes.TEXT || quiz.idMultiplexQuiz === 1">
                <button type="button" class="btnPlayGreen-cardTarget  flex align-items-center justify-content-center" *ngIf="answered" [disabled]="disabledButton" (click)="successOrNotSuccessful(true)">
                    <mat-icon class="icon-color-blue">done</mat-icon><span class="span-icon">&nbsp;{{'QUIZPLAY.DONE' | translate}}</span>
                </button>
            </div>
        </div> -->

				<div class="d-flex justify-content-center mt-4" *ngIf="quizType === 1">
					<div class="w-100" *ngIf="loginService.esProfesor()">
						<div class="d-flex justify-content-center gap-4">
							<button type="button" nbButton status="success" size="small" shape="rectangle"
								(click)="repetirOrCorrecto(true, false)">
								<nb-icon icon="checkmark-outline"></nb-icon><span>{{'QUIZPLAY.OK' | translate}}</span>
							</button>
							<button type="button" nbButton status="danger" size="small" shape="rectangle"
								(click)="repetirOrCorrecto(false, false)">
								<nb-icon icon="close-outline"></nb-icon>
								<span>{{'QUIZPLAY.FAIL' | translate}}</span>
							</button>
							<button type="button" nbButton status="warning" size="small" shape="rectangle"
								(click)="repetirOrCorrecto(false, true)">
								<nb-icon icon="sync-outline"></nb-icon>
								<span>{{'QUIZPLAY.REPEAT' | translate}}</span>
							</button>
						</div>
						<!-- INPUT O COMBO PARA CALIFICACION -->
						<div class="d-flex flex-column justify-content-center align-items-center mt-4 gap-3" *ngIf="center">
							<div
								*ngIf="center.idCalificaciones === 1 || center.idCalificaciones === 2 || center.idCalificaciones === 3 || center.idCalificaciones === 4 || center.idCalificaciones === 8"
								class="d-flex gap-2 justify-content-center align-items-center">
								<label class="m-0">{{'CALIFICACIONES.CALIFICACION' | translate}}:</label>
								<input type="number" [min]="0"
									[max]="center.idCalificaciones === 1 ? 10 : center.idCalificaciones === 2 ? 5 : center.idCalificaciones === 3 ? 20 : 100"
									step="0.1" [(ngModel)]="calificacion" (ngModelChange)="onCalificacionChange($event)" class="form-control">
								<label *ngIf="center.idCalificaciones === 8">%</label>
							</div>
							<div
								*ngIf="center.idCalificaciones === 5 || center.idCalificaciones === 6 || center.idCalificaciones === 7 || center.idCalificaciones === 9"
								class="d-flex gap-2 justify-content-center align-items-center">
								<label class="m-0">{{'CALIFICACIONES.CALIFICACION' | translate}}:</label>
								<nb-select placeholder="  ---  " class="SelectForm w-100" hero size="small" style="max-width: 100%;"
									[(ngModel)]="calificacion" (selectedChange)="selectCalificacion($event)">
									<nb-option class="SelectFormOpt" *ngFor="let opc of opcionesCalificacion" [value]="opc.title">
										{{opc.title}}
									</nb-option>
								</nb-select>
							</div>
							<label class="text-danger">({{subtitleQualification}})</label>
						</div>
						<div class="w-100 mt-4">
							<div class="form-group">
								<form [formGroup]="form">
									<label for="exampleFormControlTextarea1">{{'QUIZPLAY.TEACHERCOMMENTS' | translate}}</label>
									<textarea class="form-control" id="exampleFormControlTextarea1" formControlName="coments" rows="3"></textarea>
								</form>
							</div>
						</div>
					</div>
					<div class="w-100" *ngIf="loginService.esEstudiante()">
						<div class="w-100 mt-4">
							<div class="form-group">
								<form [formGroup]="form">
									<label for="exampleFormControlTextarea1">{{'QUIZPLAY.TEACHERCOMMENTS' | translate}}</label>
									<textarea class="form-control" id="exampleFormControlTextarea1" formControlName="coments" rows="3"
										readonly>
									</textarea>
								</form>
							</div>
						</div>
						<div class="d-flex justify-content-center gap-3" *ngIf="respuestaEstudiante[0].isChecked == 2">
							<button type="button" nbButton status="warning" size="small" shape="rectangle" (click)="repetirQuiz()">
								<nb-icon icon="sync-outline"></nb-icon>
								<span>{{'QUIZPLAY.REPEAT' | translate}}</span>
							</button>
						</div>
					</div>
				</div>
    </div>
</div>
