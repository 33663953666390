<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<div class="container">
			<div class="card-container">
				<!-- ***Creación de contenido*** -->
				<div class="card-item" (click)="verInforme(1, 9)">
					<h6>{{ "CENTRO.CONTENIDO" | translate }}</h6>
					<div class="chart-container">
						<img src="../../../../../assets/images/control-panel/administrador.png">
					</div>
				</div>
				<!-- ***Registro y actividad*** -->
				<div class="card-item" (click)="verInforme(1, 1)">
					<h6>{{ "CENTRO.ACTIVIDAD" | translate }}</h6>
					<div class="chart-container">
						<img src="../../../../../assets/images/control-panel/registrado.png">
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>

</nb-card>
