<div #menuIzquierdo>
	<ng-container>
		<div (click)="openNav()">
			<mat-icon svgIcon="menu_close" class="icon-hamburguesa"
				ngbTooltip="{{ 'SIDEBARDERECHO.ABRIRMENU' | translate }}"></mat-icon>
		</div>
	</ng-container>
</div>

<div #mySidebar class="sidebar">

  <mat-icon class="icon-color-blue closebtn pointer" ngbTooltip="{{ 'SIDEBARDERECHO.CERRAR' | translate }}" (click)="closeNav()">close</mat-icon>
	<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showHidePads()" ngbTooltip="{{ isShowPads  ? ('SIDEBARDERECHO.DESACTIVARMANDOS' | translate) :  ('SIDEBARDERECHO.ACTIVARMANDOS' | translate)}}">
		<nb-toggle class="" [checked]="loadInitialGraph" (checkedChange)="switchLoadInitialGraph()"></nb-toggle>
    <span class="ml-2">
			&nbsp;{{ 'SIDEBARDERECHO.LOADINITIALGRAPH' | translate }}
    </span>
	</a>
	<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showHidePads()" ngbTooltip="{{ isShowPads  ? ('SIDEBARDERECHO.DESACTIVARMANDOS' | translate) :  ('SIDEBARDERECHO.ACTIVARMANDOS' | translate)}}">
		<nb-toggle class="" [checked]="this.uiState.visConfig.physics.enabled" (checkedChange)="switchPhysics()"></nb-toggle>
    <span class="ml-2">
			&nbsp;{{ 'SIDEBARDERECHO.GRAPHPHYSICS' | translate }}
    </span>
	</a>
  <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showFullscreen()" ngbTooltip="{{ 'SIDEBARDERECHO.PANTALLACOMPLETA' | translate }}">
    <mat-icon svgIcon="{{isFullscreen ? 'fullscreen_exit' : 'fullscreen'}}" class="icon-color-blue ml-4"></mat-icon>
    <span class="ml-2">
			&nbsp;{{ 'SIDEBARDERECHO.PANTALLACOMPLETA' | translate }}
    </span>
  </a>

  <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)='openContact()' ngbTooltip="{{ 'SIDEBARDERECHO.OPINAR' | translate }}" >
    <mat-icon svgIcon="emoji_emotions" class="icon-color-gold ml-4" ></mat-icon>
    <span class="ml-2">
      &nbsp;{{ 'SIDEBARDERECHO.OPINAR' | translate }}
    </span>
  </a>
  <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disable"  (click)='openModal("share","Compartir")' ngbTooltip="{{ 'SIDEBARDERECHO.COMPARTIR' | translate }}">
    <mat-icon svgIcon="share" class="icon-color-blue ml-4" ></mat-icon>
    <span class="ml-2">
      &nbsp;{{ 'SIDEBARDERECHO.COMPARTIR' | translate }}
    </span>
  </a>
  <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disable" ngbTooltip="{{ 'SIDEBARDERECHO.INFORMACION' | translate }}">
    <mat-icon svgIcon="information" class="icon-color-blue ml-4"></mat-icon>
    <span class="ml-2">
      &nbsp;{{ 'SIDEBARDERECHO.INFORMACION' | translate }}
    </span>
  </a>
  <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer disable" (click)='print()' ngbTooltip="{{ 'SIDEBARDERECHO.IMPRIMIR' | translate }}" >
    <mat-icon svgIcon="print" class="icon-color-blue ml-4" ></mat-icon>
    <span class="ml-2">
      &nbsp;{{ 'SIDEBARDERECHO.IMPRIMIR' | translate }}
    </span>
  </a>
	<a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-2 pointer" (click)="showDialogPolicy($event)" ngbTooltip="{{ 'FOOTER.PRIVACY' | translate }}" >
    <nb-icon icon="shield-outline" [options]="{ animation: { type: 'pulse' } }" class="icon-color-blue ml-4"></nb-icon>
    <span class="ml-2">
      &nbsp;{{ 'FOOTER.PRIVACY' | translate }}
    </span>
  </a>
  <!-- <a  class="items-menu-rigth flex justify-content-start align-items-center mt-2 mb-4 pointer" (click)='showHideLabels()' ngbTooltip="{{ isShowLabels ? ('SIDEBARDERECHO.DISABLELABELS' | translate) : ('SIDEBARDERECHO.ENABLELABELS' | translate)}}" >
    <mat-icon svgIcon="bocadillo" class="icon-color-blue ml-4" ></mat-icon>
    <span class="ml-2">
      &nbsp;{{ isShowLabels ? ('SIDEBARDERECHO.DISABLELABELS' | translate) : ('SIDEBARDERECHO.ENABLELABELS' | translate)}}
    </span>
  </a> -->

</div>

