import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-certify',
    templateUrl: './modal-certify.component.html',
    styleUrls: ['./modal-certify.component.scss'],
    standalone: false
})
export class ModalCertifyComponent implements OnInit {

  images: Array<string> = []

  constructor(    public activeModal: NgbActiveModal) {
    this.images = [
      '../../../../assets/images/demo_images/schedule.png',
    ]
  }

  ngOnInit() {
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

}
