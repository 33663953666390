import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { InformesCentroComponent } from '../../informes-centro/informes-centro.component';
import { ReportService } from 'src/app/core/services/report/report.service';
import { forkJoin } from 'rxjs';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';
import { TranslateService } from '@ngx-translate/core';
import { InformeAdminComponent } from '../informe-admin/informe-admin.component';

@Component({
    selector: 'app-second-step-admin',
    templateUrl: './second-step-admin.component.html',
    styleUrls: ['./second-step-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SecondStepAdminComponent implements OnInit {

    consultas: any[] = [
		{ id: 1, title: this.translateService.instant("CENTRO.CONTENIDO") },
		{ id: 2, title: this.translateService.instant("CENTRO.ACTIVIDAD") },
	];

    constructor(
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private reportService: ReportService,
		public surveysService: SurveysService,
        private translateService: TranslateService,
	) { }

	ngOnInit() {}

    closeModal(sendData?: any) {
		this.activeModal.close();
	}

    verInforme(role: number, consulta: number){
        this.reportService.getInformeAdmin(role, consulta).subscribe(
            res => {
                if(res.data){
                    const modalRef = this.modalService.open(InformeAdminComponent,
                        {
                            scrollable: true,
                            windowClass: MODAL_DIALOG_TYPES.W90,
                        }
                    );
                    modalRef.componentInstance.allDatasets = res.data;
                    modalRef.componentInstance.consulta = consulta;
                    modalRef.componentInstance.role = role;
                    modalRef.result.then((result: string) => {
                    });
                }
            }
        )
    }

}