import { TemplateElementModel } from './../../../../core/models/quizzes/template.model';
import { LoginService } from './../../../../core/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { TemplateModel } from 'src/app/core/models/quizzes/template.model';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PatternDraftComponent } from '../pattern-draft/pattern-draft.component';
import { QuizzesService } from 'src/app/core/services/quizzes/quizzes.service';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';

interface Result {
    value: boolean;
    viewValue: string;
}

@Component({
    selector: 'app-pattern-form',
    templateUrl: './pattern-form.component.html',
    styleUrls: ['./pattern-form.component.scss'],
    standalone: false
})
export class PatternFormComponent implements OnInit {
   idTemplate:number;
   private idTemplates:any;
   private cargando = false;
   private templates =[];
   private elements = [];
   private quiz:any;
   private template:TemplateModel;
   private items: Array<any>;
   private currentQuiz: number;
   public templateTittle:boolean;
   public typeOption:boolean;
   public questions=false;
   public options=false;
   public answers=false;
   public form: UntypedFormGroup;

   validationMessages = {
      templateTitle: [],
      options : []
   };

   constructor(private activeModal: NgbActiveModal,
               private modalService: NgbModal, private quizService: QuizzesService, private fb: UntypedFormBuilder, private translateService: TranslateService, private loginService: LoginService ) {
                   this.quizService.currentQuiz.subscribe( (q) => {
                      this.quiz = q});
    }

    Result: Result[] = [
        {value: true, viewValue: 'Correct'},
        {value: false, viewValue: 'Wrong'},
    ];


  async ngOnInit() {
    this.cargando=false
    this.templateTittle=false
    this.typeOption=false
    this.questions=false
    this.options=false
    this.answers=false
    this._generateForm()
    this._translateText()
  }

  private _generateForm(){
    this.form = this.fb.group({
      templateTittle : ['', Validators.required],
      quizTitle : [''],
      quizInstructions : [''],
      writable : ['', Validators.required], // 1->Writable - 0->Option
      compuCorrect : ['', Validators.required], // 1->Compucorrect - 0->Manual
      multiplexed : [{value: '0', disabled: true}], // 0->No multiplexed - 1->Multiplexed
      qVideos : [0],
      qPictures : [0],
      qAudios : [0],
      qPdfs : [0],
      qTexts : [0],
      oVideos : [0],
      oPictures : [0],
      oAudios : [0],
      oPdfs : [0],
      oTexts : [0],
      aVideos : [0],
      aPictures : [0],
      aAudios : [0],
      aPdfs : [0],
      aTexts : [0]
    })

  }

  private _translateText() {
    this.translateService.get('VALIDACIONES.TITLEREQUIRED').subscribe((res: string) => {
        this.validationMessages.templateTitle.push({ type: 'required', message: res });
    });

    this.translateService.get('PATTERNS.OPTIONMESSAGE').subscribe((res: string) => {
      this.validationMessages.options.push({ type: 'required', message: res });
  });
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }

  async createTemplate(){
    if(this.getValidations() && this.form.valid){
      this.cargando = true;
      const IDUSER = this.loginService.getUser().idUser

      const f = this.form.getRawValue()
      let template:TemplateModel = new TemplateModel(f.templateTittle,parseInt(f.compuCorrect),IDUSER,parseInt(f.multiplexed),parseInt(f.writable),f.quizTitle,f.quizInstructions)

      template.videoQuestions = f.qVideos
      template.imageQuestions = f.qPictures
      template.audioQuestions = f.qAudios
      template.pdfQuestions = f.qPdfs
      template.textQuestions = f.qTexts
      template.videoOptions = f.oVideos
      template.imageOptions = f.oPictures
      template.audioOptions = f.oAudios
      template.pdfOptions = f.oPdfs
      template.textOptions = f.oTexts
      template.videoAnswer = f.aVideos
      template.imageAnswer = f.aPictures
      template.audioAnswer = f.aAudios
      template.pdfAnswer = f.aPdfs
      template.textAnswer = f.aTexts


    }
  }


  changeRadio(ev, value:string){
    const currentValue:number = Number(ev.value) ? 0 : 1
    value === 'writable' ? this.form.controls.compuCorrect.setValue(currentValue.toString()) : this.form.controls.writable.setValue(currentValue.toString())
  }

  private openPattern() {
    this.activeModal.close();
    const modalRef = this.modalService.open(PatternDraftComponent,
      {
        scrollable: true,
        windowClass: `${MODAL_DIALOG_TYPES.W100} ${MODAL_DIALOG_TYPES.w100NEW}`
      });

      modalRef.componentInstance.quiz = this.quiz;
      modalRef.componentInstance.template = this.template;

  }

  procesarElementos(): TemplateElementModel[]{

    let templateElements: TemplateElementModel[] = []


    for (let i = 0; i < this.form.value.qVideos; i++) {
      templateElements.push(this._generateElement(0))
    }
    for (let i = 0; i < this.form.value.qPictures; i++) {
      templateElements.push(this._generateElement(1))
    }
    for (let i = 0; i < this.form.value.qAudios; i++) {
      templateElements.push(this._generateElement(2))
    }
    for (let i = 0; i < this.form.value.qPdfs; i++) {
      templateElements.push(this._generateElement(3))
    }
    for (let i = 0; i < this.form.value.qTexts; i++) {
      templateElements.push(this._generateElement(4))
    }
    for (let i = 0; i < this.form.value.oVideos; i++) {
      templateElements.push(this._generateElement(5))
    }
    for (let i = 0; i < this.form.value.oPictures; i++) {
      templateElements.push(this._generateElement(6))
    }
    for (let i = 0; i < this.form.value.oAudios; i++) {
      templateElements.push(this._generateElement(7))
    }
    for (let i = 0; i < this.form.value.oPdfs; i++) {
      templateElements.push(this._generateElement(8))
    }
    for (let i = 0; i < this.form.value.oTexts; i++) {
      templateElements.push(this._generateElement(9))
    }
    for (let i = 0; i < this.form.value.aVideos; i++) {
      templateElements.push(this._generateElement(10))
    }
    for (let i = 0; i < this.form.value.aPictures; i++) {
      templateElements.push(this._generateElement(11))
    }
    for (let i = 0; i < this.form.value.aAudios; i++) {
      templateElements.push(this._generateElement(12))
    }
    for (let i = 0; i < this.form.value.aPdfs; i++) {
      templateElements.push(this._generateElement(13))
    }
    for (let i = 0; i < this.form.value.aTexts; i++) {
      templateElements.push(this._generateElement(14))
    }

    return templateElements

  }

  private _generateElement(type:number): TemplateElementModel{
    return new TemplateElementModel(0, type, 0, 0, 100, 100, this.idTemplate)
  }

  enterValue(event:any, valor:any){
   var array = [];
   if(valor=="qVideos" || valor=="qPictures" || valor=="qAudios" || valor=="qPdfs" || valor=="qTexts"){
    array = ["qVideos","qPictures","qAudios","qPdfs","qTexts"];
   }
   if(valor=="oVideos" || valor=="oPictures" || valor=="oAudios" || valor=="oPdfs" || valor=="oTexts"){
    array = ["oVideos","oPictures","oAudios","oPdfs","oTexts"];
   }
   if(valor=="aVideos" || valor=="aPictures" || valor=="aAudios" || valor=="aPdfs" || valor=="aTexts"){
          array = ["aVideos","aPictures","aAudios","aPdfs","aTexts"];
   }
   var i = array.indexOf(valor);
   array.splice(i,1);

   if(event != null && event != 0){
    array.forEach(e=>{
        this.form.get(e).disable();
    })
   }else{
    this.form.get(valor);
    array.forEach(e=>{ this.form.get(e).enable(); })
   }
  }

  getValidations(){
    var validation=true;

     if((this.form.value.qVideos==0 || this.form.value.qVideos==null) &&
        (this.form.value.qPictures==0 ||this.form.value.qPictures==null) &&
        (this.form.value.qAudios==0 || this.form.value.qAudios==null) &&
        (this.form.value.qPdfs==0 || this.form.value.qPdfs==null) &&
        (this.form.value.qTexts==0 || this.form.value.qTexts==null)){
         this.questions=true;
         validation=false;
     }else{
       this.questions=false;
     }

     if((this.form.value.oVideos==0 || this.form.value.oVideos==null) &&
        (this.form.value.oPictures==0 || this.form.value.oPictures==null) &&
        (this.form.value.oAudios==0 || this.form.value.oAudios==null) &&
        (this.form.value.oPdfs==0 || this.form.value.oPdfs==null)  &&
        (this.form.value.oTexts==0 || this.form.value.oTexts==null)){
         this.options=true;
         validation=false;
     }else{
         this.options=false;
     }

     if((this.form.value.aVideos==0 || this.form.value.aVideos==null) &&
        (this.form.value.aPictures==0 || this.form.value.aPictures==null) &&
        (this.form.value.aAudios==0 || this.form.value.aAudios==null) &&
        (this.form.value.aPdfs==0 || this.form.value.aPdfs==null) &&
        (this.form.value.aTexts==0 || this.form.value.aTexts==null)){
          this.answers=true;
          validation=false;
        }else{
          this.answers=false;
      }
     return validation
    }
}


