import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/core/services/login';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigChallengeModel } from '../../models/groups/groups-model';
import { ToasterService } from '../shared/toaster.service';

@Injectable({
	providedIn: "root",
})
export class SocketService {
	public msgFromServer = new Subject<string>();
	public closeSocket = new Subject<CloseEvent>();
	public openSocket = new Subject<Event>();
	public answeredBySocket: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public forceCloseQuizzPreviewComponent: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public competitionStarted: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public studentsConnected: Subject<any[]> = new Subject<any[]>();

	private _socket: WebSocket;
	private _idSession: number;
	private _configChallenge: ConfigChallengeModel;
	public _syncChallenge: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public getForceCloseQuizzPreviewComponent() {
		return this.forceCloseQuizzPreviewComponent.asObservable();
	}

	public setForceCloseQuizzPreviewComponent(value: boolean): void {
		this.forceCloseQuizzPreviewComponent.next(value);
	}

	public getansweredBySocket() {
		return this.answeredBySocket.asObservable();
	}

	public setansweredBySocket(value: boolean): void {
		this.answeredBySocket.next(value);
	}

	public getacompetitionStarted() {
		return this.competitionStarted.asObservable();
	}

	public setcompetitionStarted(value: boolean): void {
		this.competitionStarted.next(value);
	}

	public getStudentsConnected() {
		return this.studentsConnected.asObservable();
	}

	public setStudentsConnected(value: any): void {
		this.studentsConnected.next(value);
	}

	public get configChallenge(): ConfigChallengeModel {
		return this._configChallenge;
	}
	public set configChallenge(value: ConfigChallengeModel) {
		this._configChallenge = value;
	}

	public get idSession(): number {
		return this._idSession;
	}
	public set idSession(value: number) {
		this._idSession = value;
	}

	public get socket() {
		return this._socket;
	}
	public set socket(value) {
		this._socket = value;
	}

	public getSyncChallenge() {
		return this._syncChallenge.asObservable();
	}

	public setSyncChallenge(value: boolean): void {
		this._syncChallenge.next(value);
	}

	constructor(
		private loginService: LoginService,
		private toaster: ToasterService,
	) {}

	createSocket(idGroup: number, typeOfActivity?: string): void {
		const idUser = this.loginService.getUser().idUser;
		try {
			this._socket = new WebSocket(
				`${environment.ws}://${environment.socketIp}:${environment.socketPort}${environment.socketUrl}${idUser}/${idGroup}`
			);

			// Agregar una propiedad personalizada al objeto WebSocket
			(this._socket as any).typeActivity = typeOfActivity;

			//Si en 5 segundos no se abre la conexión, es posible que el puerto esté bloqueado.
			const connectionTimeout = setTimeout(() => {
				if (this._socket.readyState !== WebSocket.OPEN) {
					console.error(
						"Error: No se pudo establecer la conexión de socket. Es posible que el puerto 8080 esté bloqueado por la red."
					);
					// Aquí se puede emitir un mensaje para la UI, por ejemplo:
					alert(
						'Error al conectar. Es posible que la red esté bloqueando el puerto 8080.\n\nSi este error persite, Por favor contacte al administrador de la red.'
					);

					this.closeSocket.next(null);
					this._socket.close();
				}
			}, 5000);

			this._socket.onopen = (ev) => {
				//clearTimeout(connectionTimeout);
				this.openSocket.next(ev);
			};

			this._socket.onclose = (ev) => {
				this.closeSocket.next(ev);
			};

			this._socket.onmessage = (msg) => {
				this.msgFromServer.next(msg.data);
			};

			this._socket.onerror = (err) => {
				//clearTimeout(connectionTimeout);
				console.error("ERROR EN EL WS: ", err);



			};
		} catch (e) {
			console.error("ERROR AL ABRIR EL SOCKET: ", e);
			this.toaster.error(
				'Error inesperado al intentar conectar al servidor de sockets.'
			);
		}
	}

	// Function to check if the socket is connected
  isSocketConnected(): boolean {
    // Check if the socket is connected
    if (this.socket && this.socket.OPEN) {
			return true;
    }
    return false;
  }

	createSocketForPlayRoom(hex: string): void {
		const idUser = this.loginService.getUser().idUser;
		try {
			this._socket = new WebSocket(
				`${environment.ws}://${environment.socketIp}:${environment.socketPort}${environment.socketUrl}${idUser}/${hex}`
			);
			this._socket.onopen = (ev) => {
				this.openSocket.next(ev);
			};
			this._socket.onclose = (ev) => {
				this.competitionStarted.next(false);
				this.closeSocket.next(ev);
			};

			this._socket.onmessage = (msg) => {
				this.msgFromServer.next(msg.data);
			};
			this._socket.onerror = (err) => {
				console.error("ERROR EN EL WS: ", err);
			};
		} catch (e) {
			console.error("ERROR AL ABRIR EL SOCKET: ", e);
		}
	}

	removeSocket(): void {
		if (this._socket) this._socket.close();
	}

	sendDataToServer(msg: string) {
		this._socket.send(msg);
	}
}
