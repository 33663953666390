import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CourseModel, DetailCourseTargetModel } from 'src/app/core/models/courses';
import { LoginService } from 'src/app/core/services/login';
import { TargetsService } from 'src/app/core/services/targets';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

@Component({
    selector: 'app-seleccion-multiples',
    templateUrl: './seleccion-multiples.component.html',
    styleUrls: ['./seleccion-multiples.component.scss'],
    standalone: false
})
export class SeleccionMultiplesComponent implements OnInit {

	@Input() listMultiples: any;
	@Input() configMultiples: any;
	@Input() target: DetailCourseTargetModel;
	@Input() curso: CourseModel;
	@Input() idGroup: number;
	@Output() action: EventEmitter<number> = new EventEmitter<number>();

	duracionTotal: number = 0;
	cantidadQM: number = 0;
	public form: UntypedFormGroup;
	private fb: UntypedFormBuilder;
	cargando: boolean = false;
	modalImageSrc: string;
	activeToggleCount: number = 0;

    constructor(
			public targetsService: TargetsService,
			public translateService: TranslateService,
			private loginService: LoginService,
			private toaster: ToasterService
		) {
			this.fb = new UntypedFormBuilder();
		}

    ngOnInit() {
			if(!this.loginService.esProfesor){this.idGroup = 0};
			this.calculoDuracionTotal();
			this.generateForm();
		}

		back(){
			this.action.emit(1);
		}

		calculoDuracionTotal(){
			this.duracionTotal = 0;
			this.cantidadQM = 0;
			this.targetsService.getListMultiplesByTarget(this.curso.idCourse, this.target.idTarget).subscribe(res => {
				this.listMultiples = res.data;
				if(this.listMultiples != null){
					this.targetsService.getListTargetQuizzesMultiplesActiveByTarget(this.target.idTarget, this.idGroup).subscribe(res => {
						this.configMultiples = res.data;
						for (let index = 0; index < this.listMultiples.length; index++) {
							const element = this.listMultiples[index];
							switch (element.quizTitle) {
								case "Pregunta 1":
									if(this.configMultiples.qm1 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 2":
									if(this.configMultiples.qm2 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 3":
									if(this.configMultiples.qm3 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 4":
									if(this.configMultiples.qm4 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 5":
									if(this.configMultiples.qm5 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 6":
									if(this.configMultiples.qm6 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 7":
									if(this.configMultiples.qm7 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 8":
									if(this.configMultiples.qm8 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 9":
									if(this.configMultiples.qm9 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								case "Pregunta 10":
									if(this.configMultiples.qm10 === 1){
										this.cantidadQM = this.cantidadQM + 1;
										this.duracionTotal = this.duracionTotal + element.duration;
									}
									break;
								default:
									break;
							}

						}
					})
				}
			});
		}

		formatDuration(duracion: number): string {
			const horas = Math.floor(duracion / 3600);
			const minutos = Math.floor((duracion % 3600) / 60);
			const segundos = duracion % 60;
			return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
		}

		generateForm() {
			this.form = this.fb.group({
				qm1: [this.configMultiples.qm1 === 1 || this.activeToggleCount === 1],
				qm2: [this.configMultiples.qm2 === 1],
				qm3: [this.configMultiples.qm3 === 1],
				qm4: [this.configMultiples.qm4 === 1],
				qm5: [this.configMultiples.qm5 === 1],
				qm6: [this.configMultiples.qm6 === 1],
				qm7: [this.configMultiples.qm7 === 1],
				qm8: [this.configMultiples.qm8 === 1],
				qm9: [this.configMultiples.qm9 === 1],
				qm10: [this.configMultiples.qm10 === 1],
				solveIA: [this.configMultiples.solveWithIA === 1 ? true : false],
			});
			//Este for revisará los que hay activos para poder sumarlo al this.activeToggleCount y asi luego poder editarlo
			let int = 0;
			for (const controlName in this.form.controls) {
				int++;
				if (this.form.controls.hasOwnProperty(controlName)) {
				  const control = this.form.controls[controlName];
				  if(int<11){
					if(control.value === true) {
						this.activeToggleCount++;
					}
				  }
				}
			  }
		}

		change(campo: string, state: boolean) {
			this.cargando = true;
			const change = state ? 1 : 0;
			if (campo !== 'solveIA') {
				if (change === 1) {
					this.activeToggleCount++;
				} else if (this.activeToggleCount > 1) {
					this.activeToggleCount--;
				} else {
					// Si se intenta desactivar el último toggle, vuelve a activarlo.
					this.form.get(campo)?.setValue(true, { emitEvent: false });
					this.cargando = false; // Evita continuar con el proceso
					return; // Detiene la ejecución de la función
				}
			}
			this.targetsService
				.changeTargetQuizzesMultiplesActiveByTarget(campo, change, this.target.idTarget, this.curso.idCourse, this.idGroup)
				.subscribe((res) => {
					this.cargando = false;
					this.toaster.success(this.translateService.instant('GLOBALS.CHANGESSAVEDOK'));
					this.calculoDuracionTotal();
				});
		}


		openImage(image: string) {
			this.modalImageSrc = image;
			const modal = document.getElementById('myModal');
			modal.style.display = 'block';
		}

		closeImage() {
			const modal = document.getElementById('myModal');
			modal.style.display = 'none';
		}

}
