<div #scrollableParent class="tree pr-4">
	<!-- LISTADO DE CURSOS-->
	<ul class="tree-list" *ngIf="treeCourses && !type">
		<li *ngFor="let course of treeCourses; let c = index" data-id="{{course.idCourse}}"
			[class.noChildren]="!course.hasChildren" [ngStyle]="{'display':
					(loginService.esAutor() && course.isEditor == 1 && course.project_type != 4) ||
					(myCourses && coursesInGroup && course.inGroup == 1 && course.isEditor == 1) ||
					(myCourses && !coursesInGroup && course.isEditor == 1) ||
					(!myCourses && coursesInGroup && course.inGroup == 1) ||
					(!myCourses && !coursesInGroup)
					? 'block' : 'none'}">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center pointer" style="flex: 1;"
					*ngIf="course.dataColorBar?.targetStackDto && loginService.esEstudiante()">
					<div class="progress half-width">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
							aria-valuemin="0" aria-valuemax="100"
							[style.backgroundColor]="course.dataColorBar.targetStackDto.targetKnowledgeColor"
							[style.width]="course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK / course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 + '%'">
						</div>
					</div>
					<div class="averageKnowledge ml-1">
						<b *ngIf="course.dataColorBar.totalQuizzesGrandes > 0">
							{{ course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
							course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
						</b>
						<ng-container *ngIf="course.dataColorBar.totalQuizzesGrandes == 0">
							{{ course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
							course.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
						</ng-container>
					</div>
				</div>

				<div class="d-flex align-items-center pointer" style="flex: 1;"
					*ngIf="!course.dataColorBar?.targetStackDto && loginService.esEstudiante()">
					<div class="progress half-width">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
							aria-valuemin="0" aria-valuemax="100" [style.width]="0 + '%'"></div>
					</div>
					<div class="averageKnowledge ml-1">
						<b>0%</b>
					</div>
				</div>

				<div class="col-10 d-flex align-items-center my-2" (click)="loadMoreContent(course, course.idCourse)">
					<mat-icon svgIcon="chevron_right" class="pointer"
						[ngClass]="{'invisible-arrow-icon': !course.hasChildren, 'icon-color-blue': course.project_type == 1, 'icon-color-green': course.project_type == 2, 'icon-color-red': course.project_type == 3, 'icon-color-yellow': course.project_type == 4, 'icon-color-violet': course.project_type == 5}"
						*ngIf="!fromMover"></mat-icon>
					<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="course.project_type == 1"></mat-icon>
					<mat-icon class="custom-icon_green" svgIcon="ProyectoComoMuchosGrafos"
						*ngIf="course.project_type == 2"></mat-icon>
					<mat-icon class="custom-icon_red" svgIcon="ProyectoComoMuchosGrafos"
						*ngIf="course.project_type == 3"></mat-icon>
					<mat-icon class="custom-icon_yellow" svgIcon="ProyectoComoMuchosGrafos"
						*ngIf="course.project_type == 4"></mat-icon>
					<mat-icon class="custom-icon_violet" svgIcon="ProyectoComoMuchosGrafos"
						*ngIf="course.project_type == 5"></mat-icon>
					<span (click)="goTo(course)">{{course.name}}</span>
				</div>
			</div>
			<!-- LISTADO DE GRAFOS -->
			<ul class="tree-inside graphs hide">
				<li *ngFor="let graph of course.children; let g=index" [class.noChildren]="!graph.hasChildren"
					data-id="{{graph.idTarget}}">
					<div class="d-flex align-items-center">
						<div class="d-flex align-items-center pointer" style="flex: 1;"
							*ngIf="graph.dataColorBar?.targetStackDto && loginService.esEstudiante()">
							<div class="progress half-width">
								<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
									aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
									[style.backgroundColor]="graph.dataColorBar.targetStackDto.targetKnowledgeColor"
									[style.width]="graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK / graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 + '%'">
								</div>
							</div>
							<div class="averageKnowledge ml-1">
								<b *ngIf="graph.dataColorBar.totalQuizzesGrandes > 0">
									{{ graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
									graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
								</b>
								<ng-container *ngIf="graph.dataColorBar.totalQuizzesGrandes == 0">
									{{ graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
									graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
								</ng-container>
							</div>
						</div>

						<div class="d-flex align-items-center pointer" style="flex: 1;"
							*ngIf="!graph.dataColorBar?.targetStackDto && loginService.esEstudiante()">
							<div class="progress half-width">
								<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
									aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style.width]="0 + '%'"></div>
							</div>
							<div class="averageKnowledge ml-1">
								<b>0%</b>
							</div>
						</div>

						<div class="d-flex align-items-center my-2 col-10" (click)="loadMoreContent(graph, graph.idTarget)">
							<mat-icon svgIcon="chevron_right" class="icon-color-blue pointer" [ngClass]="{'invisible-arrow-icon': !graph.hasChildren,
													'icon-color-blue': course.project_type == 1,
													'icon-color-green': course.project_type == 2,
													'icon-color-red': course.project_type == 3,
													'icon-color-yellow': course.project_type == 4,
													'icon-color-violet': course.project_type == 5}">
							</mat-icon>
							<mat-icon class="custom-icon_blue2" svgIcon="ideografo" *ngIf="course.project_type == 1"></mat-icon>
							<mat-icon class="custom-icon_green2" svgIcon="ideografo" *ngIf="course.project_type == 2"></mat-icon>
							<mat-icon class="custom-icon_red2" svgIcon="ideografo" *ngIf="course.project_type == 3"></mat-icon>
							<mat-icon class="custom-icon_yellow2" svgIcon="ideografo" *ngIf="course.project_type == 4"></mat-icon>
							<mat-icon class="custom-icon_violet2" svgIcon="ideografo" *ngIf="course.project_type == 5"></mat-icon>
							<span (click)="goTo(graph)">{{graph.name}}</span>
						</div>
					</div>
					<!-- LISTADO DE NODOS / EN DESAFIOS, PUEDE CONTENER QUIZZES SUELTOS -->
					<ul class="tree-inside nodes hide">
						<li *ngFor="let node of graph.children; let n=index" [class.noChildren]="!node.hasChildren"
							data-id="{{node.idNode || node.idQuiz}}">
							<div class="d-flex align-items-center">
								<div *ngIf="loginService.esEstudiante()"
									class="col-2">
								</div>
								<div class="d-flex align-items-center my-2 col-10" (click)="loadMoreContent(node, node.idNode)">
									<mat-icon svgIcon="chevron_right" class="icon-color-blue pointer" [ngClass]="{'invisible-arrow-icon': !node.hasChildren,
																'icon-color-blue': course.project_type == 1,
																'icon-color-green': course.project_type == 2,
																'icon-color-red': course.project_type == 3,
																'icon-color-yellow': course.project_type == 4,
																'icon-color-violet': course.project_type == 5}">
									</mat-icon>
									<mat-icon svgIcon="{{node.idNode ? 'ideasvg' : 'act'}}"
										class="{{node.idNode ? 'icon-green' : node.color +' '+node.sizeQuiz}} mat-icon pointer"
										(click)="goTo(node)"></mat-icon>
									<span (click)="goTo(node)">{{node.name}}</span>
								</div>
							</div>
							<!-- LISTADO DE QUIZZES -->
							<ul class="tree-inside quizzes hide">
								<li *ngFor="let quiz of node.children; let i=index" data-id="{{quiz.idQuiz}}" class="lastChild">
									<div class="d-flex align-items-center">
										<div *ngIf="loginService.esEstudiante()"
											class="col-2">
										</div>
										<div class="col-10">
											<div *ngIf="quiz.multi; else templateQuizMultiple">
												<div class="d-flex align-items-center my-2" (click)="goTo(quiz);setSelectedQuizAlt(c,g,n,i)"
													[ngClass]="{
														'hasResponse' : quiz.hasResponse,
														'isSelected': quiz.isSelected,
														'isSelected_prev': quiz.isSelected_prev && !quiz.isSelected
														}"
													*ngIf="challengeType != '2'">
													<!--de momento no mostramos los multiples en el desafio option 2-->
													<div class="d-flex p-2" *ngIf="quiz.ordinalType == 0 || quiz.ordinalType == undefined">
														<mat-icon svgIcon="{{quiz.multi ? 'QuizMulti' : 'act'}}"
															class="pointer mat-icon {{quiz.color}} {{quiz.sizeQuiz}}"></mat-icon>
													</div>
													<div class="d-flex" *ngIf="quiz.ordinalType == 1">
														<mat-icon svgIcon="go" class="pointer mat-icon"></mat-icon>
													</div>
													<div class="d-flex" *ngIf="quiz.ordinalType == 2">
														<mat-icon svgIcon="goal" class="pointer mat-icon"></mat-icon>
													</div>
													<span>{{quiz.statement}}</span>
												</div>
											</div>
											<ng-template #templateQuizMultiple>
												<div class="d-flex align-items-center my-2" (click)="goTo(quiz);setSelectedQuizAlt(c,g,n,i)"
													[ngClass]="{
														'hasResponse' : quiz.hasResponse,
														'isSelected': quiz.isSelected,
														'isSelected_prev': quiz.isSelected_prev && !quiz.isSelected
														}">
													<div class="d-flex p-2" *ngIf="quiz.ordinalType == 0 || quiz.ordinalType == undefined">
														<mat-icon svgIcon="{{quiz.multi ? 'QuizMulti' : 'act'}}"
															class="pointer mat-icon {{quiz.color}} {{quiz.sizeQuiz}}"></mat-icon>
													</div>
													<div class="d-flex" *ngIf="quiz.ordinalType == 1">
														<mat-icon svgIcon="go" class="pointer mat-icon"></mat-icon>
													</div>
													<div class="d-flex" *ngIf="quiz.ordinalType == 2">
														<mat-icon svgIcon="goal" class="pointer mat-icon"></mat-icon>
													</div>
													<span>{{quiz.statement}}</span>
												</div>
											</ng-template>
										</div>
									</div>
								</li>
								<li *ngIf="isAuthor">
									<div class="d-flex align-items-center my-2 new-element" (click)="newElement('QUIZ')">
										<mat-icon class="icon-color-blue" svgIcon="add"></mat-icon>
										<span>{{'TREECOMPONENT.NEWQUIZ' | translate}}</span>
									</div>
								</li>
							</ul> <!-- FIN LISTADO DE QUIZZES -->
						</li>
						<li *ngIf="isAuthor">
							<div class="d-flex align-items-center my-2 new-element" (click)="newElement('NODE')">
								<mat-icon class="icon-color-blue" svgIcon="add"></mat-icon>
								<span>{{'TREECOMPONENT.NEWNODE' | translate}}</span>
							</div>
						</li>
					</ul><!-- FIN LISTADO DE GRAFOS -->
				</li>
				<li *ngIf="isAuthor">
					<div class="d-flex align-items-center my-2 new-element" (click)="newElement('GRAPH')">
						<mat-icon class="icon-color-blue" svgIcon="add"></mat-icon>
						<span>{{'TREECOMPONENT.NEWGRAPH' | translate}}</span>
					</div>
				</li>
			</ul><!-- FIN LISTADO DE GRAFOS -->
		</li>
		<li *ngIf="isAuthor && graphView == 'inlineList'">
			<div class="d-flex align-items-center my-2 new-element" (click)="newElement('COURSE')">
				<mat-icon class="icon-color-blue" svgIcon="add"></mat-icon>
				<span>{{'TREECOMPONENT.NEWCOURSE' | translate}}</span>
			</div>
		</li>
	</ul> <!-- FIN  LISTADO DE CURSOS-->


	<!-- LISTADO DE GRAFOS-->
	<ul class="tree-list" *ngIf="treeGraphs && !type">
		<li *ngFor="let graph of treeGraphs" [class.noChildren]="!graph.hasChildren" data-id="{{graph.idTarget}}">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center pointer" style="flex: 1;"
					*ngIf="graph.dataColorBar?.targetStackDto && loginService.esEstudiante()">
					<div class="progress half-width">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
							aria-valuemin="0" aria-valuemax="100"
							[style.backgroundColor]="graph.dataColorBar.targetStackDto.targetKnowledgeColor"
							[style.width]="graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK / graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 + '%'">
						</div>
					</div>
					<div class="averageKnowledge ml-1">
						<b *ngIf="graph.dataColorBar.totalQuizzesGrandes > 0">
							{{ graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
							graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
						</b>
						<ng-container *ngIf="graph.dataColorBar.totalQuizzesGrandes == 0">
							{{ graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolvedOK /
							graph.dataColorBar.targetStackDto.totalQuizzesOriginalesSolved * 100 | number:'1.0-0'}}%
						</ng-container>
					</div>
				</div>

				<div class="d-flex align-items-center pointer" style="flex: 1;"
					*ngIf="!graph.dataColorBar?.targetStackDto && loginService.esEstudiante()">
					<div class="progress half-width">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0"
							aria-valuemin="0" aria-valuemax="100" [style.width]="0 + '%'"></div>
					</div>
					<div class="averageKnowledge ml-1">
						<b>0%</b>
					</div>
				</div>

				<div class="d-flex align-items-center my-2 col-10" (click)="loadMoreContent(graph, graph.idTarget)">
					<mat-icon svgIcon="chevron_right" class="icon-color-blue pointer"
						[ngClass]="{'invisible-arrow-icon': !graph.hasChildren}"></mat-icon>
					<mat-icon svgIcon="ideografo"></mat-icon>
					<span (click)="goTo(graph)">{{graph.name}}</span>
				</div>
			</div>
			<!-- LISTADO DE NODOS -->
			<ul class="tree-inside nodes hide">
				<li *ngFor="let node of graph.children; let e = index" [class.noChildren]="!node.hasChildren"
					data-id="{{node.idNode}}">
					<div class="d-flex align-items-center">
						<div *ngIf="loginService.esEstudiante()"
							class="col-2">
						</div>
						<div class="d-flex align-items-center my-2 col-10" (click)="loadMoreContent(node, node.idNode)">
							<mat-icon svgIcon="chevron_right" class="icon-color-blue pointer"
								[ngClass]="{'invisible-arrow-icon': !node.hasChildren}"></mat-icon>
							<mat-icon svgIcon="ideasvg" class="icon-green pointer" (click)="goTo(node)"></mat-icon>
							<span (click)="goTo(node)">{{node.name}}</span>
						</div>
					</div>
					<!-- LISTADO DE QUIZZES -->
					<ul class="tree-inside quizzes hide">
						<li *ngFor="let quiz of node.children; let i = index" data-id=" {{quiz.idQuiz}}" class="lastChild"
							(click)="setSelectedQuiz(e,i)"
							[ngClass]="{
								'hasResponse' : quiz.hasResponse,
								'isSelected': quiz.isSelected,
								'isSelected_prev': quiz.isSelected_prev && !quiz.isSelected
								}">
							<div class="d-flex align-items-center">
								<div *ngIf="loginService.esEstudiante()"
									class="col-2">
								</div>
								<div class="col-10">
									<div *ngIf="quiz.multi; else templateQuizMultiple">
										<div class="d-flex align-items-center my-2" (click)="goTo(quiz);setSelectedQuizAlt(c,g,n,i)"
											[ngClass]="{
												'hasResponse' : quiz.hasResponse,
												'isSelected': quiz.isSelected,
												'isSelected_prev': quiz.isSelected_prev && !quiz.isSelected
												}"
											*ngIf="challengeType != '2'"> <!--de momento no mostramos los multiples en el desafio option 2-->
											<div class="d-flex p-2" *ngIf="quiz.ordinalType == 0 || quiz.ordinalType == undefined">
												<mat-icon svgIcon="{{quiz.multi ? 'QuizMulti' : 'act'}}"
													class="pointer mat-icon {{quiz.color}} {{quiz.sizeQuiz}}"></mat-icon>
											</div>
											<div class="d-flex" *ngIf="quiz.ordinalType == 1">
												<mat-icon svgIcon="go" class="pointer mat-icon"></mat-icon>
											</div>
											<div class="d-flex" *ngIf="quiz.ordinalType == 2">
												<mat-icon svgIcon="goal" class="pointer mat-icon"></mat-icon>
											</div>
											<span>{{quiz.statement}}</span>
										</div>
									</div>
									<ng-template #templateQuizMultiple>
										<div class="d-flex align-items-center my-2" (click)="goTo(quiz);setSelectedQuizAlt(c,g,n,i)"
											[ngClass]="{
												'hasResponse' : quiz.hasResponse,
												'isSelected': quiz.isSelected,
												'isSelected_prev': quiz.isSelected_prev && !quiz.isSelected
												}">
											<div class="d-flex p-2" *ngIf="quiz.ordinalType == 0 || quiz.ordinalType == undefined">
												<mat-icon svgIcon="{{quiz.multi ? 'QuizMulti' : 'act'}}"
													class="pointer mat-icon {{quiz.color}} {{quiz.sizeQuiz}}"></mat-icon>
											</div>
											<div class="d-flex" *ngIf="quiz.ordinalType == 1">
												<mat-icon svgIcon="go" class="pointer mat-icon"></mat-icon>
											</div>
											<div class="d-flex" *ngIf="quiz.ordinalType == 2">
												<mat-icon svgIcon="goal" class="pointer mat-icon"></mat-icon>
											</div>
											<span>{{quiz.statement}}</span>
										</div>
									</ng-template>
								</div>
							</div>
						</li>
					</ul> <!-- FIN LISTADO DE QUIZZES -->
				</li>
			</ul><!-- FIN LISTADO DE GRAFOS -->
		</li>
	</ul><!-- FIN LISTADO DE GRAFOS -->

	<ng-container *ngIf="type === 'onlyQuizzes'">
		<ul class="tree-list" *ngIf="treeCourses">
			<li *ngFor="let quiz of treeCourses; let i = index" class="lastChild" data-id="{{quiz.idQuiz}}"
				[class.hasResponse]="quiz.hasResponse">
				<div class="d-flex align-items-center my-2" (click)="goTo(quiz)">
					<mat-icon svgIcon="{{quiz.multi ? 'QuizMulti' : 'act'}}" class="pointer"></mat-icon>
					<span>{{quiz.name}}</span>
				</div>
			</li>
		</ul><!-- FIN LISTADO DE GRAFOS -->
	</ng-container>

</div>
