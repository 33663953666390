import { Action, createAction, props } from '@ngrx/store';
import { uiState } from "../models/ui-state.model"

export enum ActionTypes {
  register = '[UI STATE ACTION] register UI STATE',
}

export const updateUiState = createAction(
	"[UI State] Update",
	props<{ uiState: any }>()
);

export class register implements Action {
	readonly type = ActionTypes.register;
	constructor(public payload: uiState) {}
}


export type ActionsUnion = register;
