import { Component, ViewEncapsulation, EventEmitter, OnInit, Output } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
	NgbModal,
	NgbActiveModal,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, timer } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { GroupModel } from "src/app/core/models/groups/groups-model";
import { IdiomaModel } from "src/app/core/models/masters/idioma.model";
import { User } from "src/app/core/models/users/user.models";
import { CoursesService } from "src/app/core/services/courses";
import { GetDataService } from "src/app/core/services/get-data/get-data.service";
import {
	GruposService,
	SOCKETMESSAGES,
} from "src/app/core/services/groups/grupos.service";
import { LoginService } from "src/app/core/services/login";
import { MastersService } from "src/app/core/services/masters";
import { AlertService } from "src/app/core/services/shared";
import { TargetsService } from "src/app/core/services/targets";
import { UsersService } from "src/app/core/services/users";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { environment } from "src/environments/environment";
import { ModalListadoCursosComponent } from "../modal-listado-cursos/modal-listado-cursos.component";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalListadoEstudiantesComponent } from "../modal-listado-estudiantes/modal-listado-estudiantes.component";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import { ModalSocketCoursesComponent } from "../modal-socket-courses/modal-socket-courses.component";
import { ReportingComponent } from "../../reporting/reporting.component";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { CenterService } from "src/app/core/services/center/center.service";
import { MateriaModel } from "src/app/core/models/masters";
import { CountryLevelModel } from "src/app/core/models/masters/countryLevel.model";
import { LocalStorage } from "src/app/core/utils";
import { ModalAceptarCancelarComponent } from "../../modal/modal-aceptar-cancelar/modal-aceptar-cancelar.component";

@Component({
    selector: "app-modal-editar-grupo",
    templateUrl: "./modal-editar-grupo.component.html",
    styleUrls: ["./modal-editar-grupo.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalEditarGrupoComponent implements OnInit {
	@Output() saveGroups = new EventEmitter<GroupModel>();

	id: string;
	public formGroup: UntypedFormGroup;
	validationMessages = {
		title: [],
		description: [],
		image: [],
		nivel: [],
		letra: [],
		idSubject: [],
	};
	idiomas: IdiomaModel[] = [];
	saving: boolean;
	user: User;
	autor: User;
	group: GroupModel;
	cargando: boolean;
	timer$ = timer(3000);
	private destroy$ = new Subject();

	private subscriptions: Subscription[] = [];

	terminarNuevoGrupo: boolean = false;
	endUpdateCall$ = new Subject();

	letras = ["Sin letra específica", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"];
	subjects: MateriaModel[] = [];
	countryLevels: CountryLevelModel[] = [];

	centerTeacher: any;

	constructor(
		public router: Router,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		public loginService: LoginService,
		private alertService: AlertService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private fb: UntypedFormBuilder,
		private imagePipe: ImagenPipe,
		private groupService: GruposService,
		private userService: UsersService,
		private getDataService: GetDataService,
		private socketService: SocketService,
		private toaster: ToasterService,
		private centerService: CenterService,
		private localStorage: LocalStorage,
		private masterService: MastersService,
	) {
		this.formGroup = this.fb.group({
			image: [{ value: "", disabled: false }],
			title: [""],
			description: ["", Validators.required],
			langGroup: this.fb.group({
				lang1: [38, Validators.required],
			}),
			published: [false],
			nivel: ["", Validators.required],
			letra: [""],
			idSubject: ["", Validators.required],
		});
	}

	ngOnInit() {
		this.obtenerDatosMaestros();
		this.user = this.loginService.getUser();
		this.traducirOpciones();

		if (this.id !== "nuevo") {
			this.obtenerAutorOriginal(this.group.idProfessor);
			this.pachValues();
		}

		// this.formGroup.get('description').patchValue(this.group.description);
		this.getAssignmentCenters();
		//definimos variable para tutoriales
		this.masterService.setIdTutorialContext(7);
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}
	obtenerAutorOriginal(idProfessor: number) {
		this.userService
			.getUserByIdUser(idProfessor)
			.pipe(takeUntil(this.destroy$), take(1))
			.subscribe((res) => {
				this.autor = res as User;
			});
	}

	pachValues() {
		this.formGroup.patchValue({
			title: this.group.title || "",
			description: this.group.description || "",
			langGroup: {
				lang1: this.group.idLanguage || 0,
			},
			image: this.group.imagen
				? this.imagePipe.transform(this.group.imagen, "group")
				: "",
			nivel: this.group.nivel || "",
			letra: this.group.letras || "",
			idSubject: this.group.idSubject || "",
		});

	}

	obtenerDatosMaestros() {
		//Idiomas
		this.getDataService
			.getAllLanguages()
			.pipe(takeUntil(this.destroy$), take(1))
			.subscribe((data) => (this.idiomas = data));
		this.subjects = this.getDataService.appSubjects;
		//Niveles
		this.getDataService.getCountryLevels("ESP").subscribe((data) => {
			if(data){
				this.countryLevels = data;
				console.log(data);
			}
		});
	}

	closeModal(sendData?: any) {
		if (sendData == "delete") {
			this.masterService.getPreviousIdTutorialContext();
			this.activeModal.close(sendData);
			return;
		}
		if (this.id == "nuevo" && !sendData) {
			const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
						scrollable: true,
						windowClass: MODAL_DIALOG_TYPES.W30,
					});

					let modalMessage = `${this.translateService.instant(
						"MODALGROUPSEDIT.EXITNOSAVE"
					)}`;
					modalRef.componentInstance.mensaje = modalMessage;

					modalRef.result.then((result: boolean) => {
						if (result == true) {
							this.masterService.getPreviousIdTutorialContext();
							this.activeModal.close('cancel');
						}else{
							return;
						}
					});
		} else {
			this.masterService.getPreviousIdTutorialContext();
			this.activeModal.close();
		}
	}

	get imageControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("image") as UntypedFormControl;
		else return null;
	}

	get titleControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("title") as UntypedFormControl;
		else return null;
	}

	get descriptionControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("description") as UntypedFormControl;
		else return null;
	}

	get langGroup(): UntypedFormGroup {
		if (this.formGroup) {
			return this.formGroup.get("langGroup") as UntypedFormGroup;
		} else {
			return null;
		}
	}

	get lang1Control(): UntypedFormControl {
		if (this.langGroup) {
			return this.langGroup.get("lang1") as UntypedFormControl;
		} else {
			return null;
		}
	}

	get nivelControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("nivel") as UntypedFormControl;
		else return null;
	}

	get letrasControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("letra") as UntypedFormControl;
		else return null;
	}

	get subjectsControl(): UntypedFormControl {
		if (this.formGroup) return this.formGroup.get("idSubject") as UntypedFormControl;
		else return null;
	}

	grabarGrupo() {
		if(this.formGroup.valid){
			let image: File | string = "";
			this.saving = true;
			const v = this.formGroup.value;

			let guardarGroup = new GroupModel();
			if (this.id === "nuevo") {
				guardarGroup.creationDate = null;
				guardarGroup.description = v.description;
				guardarGroup.title = v.title;
				guardarGroup.editDate = null;
				guardarGroup.idCenter = this.centerTeacher;
				guardarGroup.idGroup = 0;
				guardarGroup.idLanguage = v.langGroup.lang1;
				guardarGroup.imagen = "";
				guardarGroup.share = "";
				image = v.image;
				guardarGroup.created_from_role = "teacher";
				guardarGroup.nivel = v.nivel;
				if(v.nivel == "Sin nivel específico"){
					guardarGroup.letras = "";
				} else if(v.nivel != "Sin nivel específico" && v.letra === "Sin letra específica"){
					guardarGroup.letras = "";
				} else {
					guardarGroup.letras = v.letra;
				}
				guardarGroup.idSubject = v.idSubject;

				this.groupService
					.createGroup(guardarGroup, image)
					.pipe(takeUntil(this.destroy$), take(1))
					.subscribe(
						(res: any) => {
							this.group = { ...res.data } as GroupModel;
							this.id = this.group.idGroup.toString();
							//this.closeModal('');
							this.toaster.success(this.translateService.instant('GRUPOS.OKSAVE'))

							const modalRef = this.modalService.open(
								ModalListadoCursosComponent,
								{
									scrollable: false,
									windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
								}
							);
							modalRef.componentInstance.id = this.group.idGroup;
							modalRef.componentInstance.group = this.group;
							modalRef.componentInstance.newGroup = true;

							modalRef.result.then((result) => {
								if (result) {
									this.listadoAlumnos(true);
								}
							});
						},
						(err) => {
							console.log(err);
							this.cargando = false;
						}
					);
			} else {
				this.group.title = v.title;
				this.group.description = v.description;
				this.group.idLanguage = v.langGroup.lang1;
				this.group.created_from_role = "teacher";
				this.group.nivel = v.nivel;
				if(v.nivel == "Sin nivel específico"){
					this.group.letras = "";
				} else if(v.nivel != "Sin nivel específico" && v.letra === "Sin letra específica"){
					this.group.letras = "";
				} else {
					this.group.letras = v.letra;
				}
				this.group.idSubject = v.idSubject;

				if (typeof v.image == "object") {
					image = v.image;
				} else if (typeof v.image == "string") {
					image = "";
					if (v.image.length === 0) {
						this.group.imagen = image;
					}
				}
				this.groupService
					.updateGroup(this.group, image)
					.pipe(takeUntil(this.endUpdateCall$), take(1))
					.subscribe(
						(res: any) => {
							this.group = { ...res.data } as GroupModel;
							this.closeModal(this.group);
							this.toaster.success(
								this.translateService.instant("GRUPOS.OKSAVE")
							);
						},
						(err) => {
							console.log(err);
							this.cargando = false;
						}
					);
			}
		}
	}

	deleteGroup() {
		this.groupService.deleteGroup(this.group).subscribe((res) => {
			if (res.data) {
				this.toaster.success(
					this.translateService.instant("GRUPOS.ELIMINADO")
				);
				this.closeModal("delete");
			}
		});
	}

	isMine(): boolean {
		if (
			this.loginService.esAutor() &&
			this.user.idUser === this.group.idProfessor
		)
			return true;
		return false;
	}

	//----- TARJETAS -----

	listadoCursos() {
		const modalRef = this.modalService.open(ModalListadoCursosComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.id = this.group.idGroup;
		modalRef.componentInstance.group = this.group;
	}

	listadoAlumnos(newGroup) {
		const modalRef = this.modalService.open(ModalListadoEstudiantesComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.id = this.group.idGroup;
		modalRef.componentInstance.group = this.group;
		modalRef.componentInstance.newGroup = newGroup;
	}

	instantActivity(): void {
		let modal: NgbModalRef;
		const idGroup = this.group.idGroup;

		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);

			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					modal = this.modalService.open(ModalSocketCoursesComponent, {
						scrollable: true,
						windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
					}); // Modal con el listado de cursos, para que el profesor pueda elegir los quizzes a realizar
					modal.componentInstance.group = this.group;
					modal.componentInstance.onlineUsers = onlineUsers;

					modal.result
						.then(
							() => {},
							(err) => {}
						)
						.finally(() => {
							this._removeSocket(idGroup);
						});
					msgSubs.unsubscribe();

					break;
			}
		});
		this.subscriptions.push(msgSubs);

		this.socketService.createSocket(idGroup); //Creo el socket

		const openSocketSub = this.socketService.openSocket.subscribe((ev) => {
			//Cuando se cree el socket, procedemos a guardar la sesion en el servidor
			this.groupService.createGroupSession(idGroup).subscribe(
				(result) => {
					//Si se guarda correctamente la sesion, tenemos que abrir el modal con los cursos en modo lista
					this.socketService.idSession = result.data.idSession;
					openSocketSub.unsubscribe();
				},
				(err) => {
					modal.close();
				}
			);
		});
		this.subscriptions.push(openSocketSub);
	}

	private _removeSocket(idGroup) {
		this.socketService.removeSocket(); //Si ha habido error al guardar la sesión, tenemos que destruir el socket y cerrar el modal
		this.groupService.deleteGroupSession(idGroup).subscribe(); //Elimino la sesión de la bd
		this.subscriptions.forEach((s) => s.unsubscribe()); //Elimino las suscripciones a eventos
	}

	informesGrupo() {
		const modalRef = this.modalService.open(ReportingComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.idGrupo = this.group.idGroup;
		modalRef.componentInstance.group = this.group;
	}

	traducirOpciones() {
		this.translateService.get('VALIDACIONES.ELTITULOESOBLIGATORIO').subscribe((res: string) => {
				this.validationMessages.title.push({ type: 'required', message: res });
		});

		this.translateService.get('VALIDACIONES.DESCRIPTIONREQUIRED').subscribe((res: string) => {
				this.validationMessages.description.push({ type: 'required', message: res });
		});

		this.translateService.get('VALIDACIONES.LEVELREQUIRED').subscribe((res: string) => {
				this.validationMessages.nivel.push({ type: 'required', message: res });
		});

		this.translateService.get('VALIDACIONES.SUBJECTREQUIRED').subscribe((res: string) => {
			this.validationMessages.idSubject.push({ type: 'required', message: res });
	});
	}

	private getAssignmentCenters() {
		this.centerService
			.getListCenterByIdUser()
			.pipe()
			.subscribe((result) => {
				this.centerTeacher = result[0].idCenter;
			});
	}

}
