import { filter } from 'rxjs/operators';
import { Profiles } from "src/app/core/utils/profiles.enum";
import { LoginService } from "src/app/core/services/login";
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
	Inject,
	PLATFORM_ID,
	ChangeDetectorRef,
	TemplateRef,
	ViewChild
} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { QuizElementTypes } from "src/app/core/models/quizzes/quiz-element-types.enum";
import { QuizTypes } from "src/app/core/models/quizzes/quiz-types.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { ModalMicrofonoAudioComponent } from "src/app/shared/components/modal-microfono-audio/modal-microfono-audio.component";
import { QuizElementsPipe } from "src/app/shared/pipes/quiz-elements.pipe";
import { environment } from "src/environments/environment";
import { HypermediaTypes } from "../../../quiz-hypermedia/hypermedia-types.enum";
import { QuizHypermediaComponent } from "../../../quiz-hypermedia/quiz-hypermedia.component";
import { SOCKETMESSAGES } from "src/app/core/services/groups/grupos.service";
import { ListQuizzesStackChallengesModel, QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { LocalStorage } from 'src/app/core/utils';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-quiz-play-body-option",
    templateUrl: "./quiz-play-body-option.component.html",
    styleUrls: ["./quiz-play-body-option.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class QuizPlayBodyOptionComponent implements OnInit, OnChanges {

	@ViewChild('dialog') dialogTemplate!: TemplateRef<any>;
	@Input() quiz;
	@Input() quizType: QuizTypes;
	@Input() options: any[] = [];
	@Input() loadingResponses: any;
	@Input() answered: boolean = false;
	@Input() modeSocket: boolean;
	@Input() selectedType: string = SOCKETMESSAGES.OPEN;
	@Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveOptionChallenge: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	quizElementsPipe: QuizElementsPipe = new QuizElementsPipe();
	@Input() filesUploadsViewContent = {
		answersImagen: [],
		answersDocs: [],
		answersAudio: [],
		answersVideo: [],
	};
	@Input() arrayFileView = [];
	@Output() emitAudio: EventEmitter<any> = new EventEmitter<any>();

	public optionForm: UntypedFormGroup;
	public optionFormTexto: UntypedFormGroup;
	@Output() saveOption: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionVideoCamera: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSaveOptionWebCam: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionMultiple: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveOptionText: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitText: EventEmitter<string> = new EventEmitter<string>();

	public O_VIDEOS = QuizElementTypes.O_VIDEOS;
	public O_PICTURES = QuizElementTypes.O_PICTURES;
	public O_AUDIOS = QuizElementTypes.O_AUDIOS;
	public O_PDFS = QuizElementTypes.O_PDFS;
	public O_TEXTS = QuizElementTypes.O_TEXTS;
	public O_MICRO = QuizElementTypes.O_MICRO;
	imagePlay: string;
	isLoading: boolean;
	viewAudio: boolean;
	tieneMicrofono: boolean = false;
  permisosConcedidos: boolean = false;
  mensajeError: string = '';
	hideResponse = false; //Si el user es profesor, tenemos que ocultar que pueda contestar el quiz
	hideCounter = true; // Si el user es profesor y está en modo Socket, tengo que mostarle el contador de lo que ha respondido la gente
	// variable para saber si carga o toma un video
	typeResp: string = '';
	hideBoton: boolean = false;

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private fb: UntypedFormBuilder,
		private modalService: NgbModal,
		private loginService: LoginService,
		private quizzesstackService: QuizzesstackService,
		private localStorage: LocalStorage,
		private cdr: ChangeDetectorRef,
		private dialogService: NbDialogService,
		public translateService: TranslateService,
	) {}

	ngOnInit() {
		if (this.loginService.getProfile() === Profiles.Teacher) {
			this.hideResponse = true;
			this.answered = false;
		}
		if (
			this.loginService.getProfile() === Profiles.Teacher &&
			JSON.parse(this.localStorage.getItem("modePractice"))
		) {
			this.hideResponse = false;
			this.answered = false;
		}

		if (
			this.loginService.getProfile() === Profiles.Teacher &&
			this.modeSocket
		) {
			this.hideCounter = false;
		}

		this.optionForm = this.fb.group({
			options: this.fb.array([]),
			correctOptionId: null,
		});
		this.optionFormTexto = this.fb.group({
			answer: [{ value: "", disabled: this.hideResponse }],
		});

		if (this.quizType === QuizTypes.SINGLE) {
			this.optionForm.get("correctOptionId").valueChanges.subscribe((id) => {
				const control: UntypedFormControl = this.optionsArray.controls.filter(
					(c) => c.get("id").value === id
				)[0] as UntypedFormControl;

				if (control) {
					control.get("checked").patchValue(!control.get("checked").value);
					this.optionsArray.controls
						.filter((c) => c.get("id").value !== id)
						.forEach((c) => {
							c.get("checked").patchValue(false);
						});
				}
			});
		}

		this.optionsArray.clear();
		this.options.forEach((a) => {
			let val: ListQuizzesStackChallengesModel[] = [];
			if (this.selectedType === SOCKETMESSAGES.ORDERMODLIST)
				val = this.quiz.selectedResponses.filter(
					(e) => e === a.idQuizzesDataElements
				) as ListQuizzesStackChallengesModel[]; //Esto sólo vale para os checkbox
			this.addOption(a, val.length ? true : false);
		});

		if (
			this.quizType === this.QuizTypes.SINGLE &&
			this.selectedType === SOCKETMESSAGES.ORDERMODLIST
		) {
			this.correctOptionControl.setValue(this.quiz.selectedResponses[0]);
		}

		if (
			this.selectedType === SOCKETMESSAGES.ORDERMODLIST ||
			this.selectedType === SOCKETMESSAGES.ORDERMODAUTO
		) {
			this.optionForm.valueChanges.subscribe((data) => {
				this.saveOptionChallenge.emit(true);
			});
		}

		this.vewFileContent();
		this.verificarMicrofono();
	}

	// Función para detectar iOS
	isIOS(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const ua = navigator.userAgent;
			return (
				/iPad|iPhone|iPod/.test(ua) ||
				(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
			);
		}
		return false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.answered && changes.answered.currentValue) {
			this.optionForm.disable();
		}
		//Cuando cambia el array de opciones con los resultados marcados por los alumnos
		if (changes.options && changes.options.previousValue) {
			this.optionsArray.clear();
			this.options = changes.options.previousValue;
			this.options.forEach((a) => {
				this.addOption(a);
			});
		}
	}

	public get optionsArray(): UntypedFormArray {
		return this.optionForm.get("options") as UntypedFormArray;
	}

	public get optionsArrayData(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("data") as UntypedFormControl
		);
	}

	public get optionsArrayIsCorrect(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("isCorrect") as UntypedFormControl
		);
	}

	public get optionsArrayType(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("type") as UntypedFormControl
		);
	}

	public get optionsArrayChecked(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("checked") as UntypedFormControl
		);
	}

	public get optionsArrayAnswer(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("answer") as UntypedFormControl
		);
	}

	public get optionsArrayWidth(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("width") as UntypedFormControl
		);
	}

	public get optionsArrayHeight(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("height") as UntypedFormControl
		);
	}

	public get optionsArrayTotalResponses(): UntypedFormControl[] {
		return this.optionsArray.controls.map(
			(q) => q.get("totalResponses") as UntypedFormControl
		);
	}

	public get correctOptionControl(): UntypedFormControl {
		return this.optionForm.get("correctOptionId") as UntypedFormControl;
	}

	public get QuizTypes() {
		return QuizTypes;
	}

	public get elementTypes() {
		return QuizElementTypes;
	}

	private addOption(option: any, isChecked = false): void {
		const newOption: UntypedFormGroup = this.createOption(option, isChecked);
		this.optionsArray.push(newOption);
	}

	clickedOptionDiv(correctOption: any, clickedFromControl, value) {
		if (correctOption.value != value) {
			correctOption.setValue(value, { onlySelf: false, emitEvent: true });
			clickedFromControl.setValue(value, { onlySelf: false, emitEvent: true });
			clickedFromControl.markAsDirty();
			clickedFromControl.markAllAsTouched();
			clickedFromControl.updateValueAndValidity({
				onlySelf: false,
				emitEvent: true,
			});
		}
	}

	private createOption(option: any, isChecked: boolean): UntypedFormGroup {
		return this.fb.group({
			id: option ? option.idQuizzesDataElements : "",
			auxId: this.optionsArray.controls.length,
			data: {
				value: option.data,
				disabled:
					this.quizType !== QuizTypes.TEXT ||
					option.elementType !== this.elementTypes.O_TEXTS,
			},
			originalData: option.data,
			type: option.elementType,
			isCorrect: {
				value: !!option.responseCheck,
				disabled: !option || !option.idQuizzesDataElements,
			},
			order: option.yPosition,
			answer: null,
			checked: isChecked,
			width: option ? option.xSize : 0,
			height: option ? option.ySize : 0,
			totalResponses: option.responsesArray ? option.responsesArray.length : 0,
		});
	}

	public emitAnswer() {}

	public onOpenHypermedia(
		event: MouseEvent,
		index: number,
		insert: boolean = true,
		type?: number
	) {
		event.stopPropagation();
		const currentControl = this.optionsArray.controls[
			index
		] as UntypedFormGroup;

		const modalRef = this.modalService.open(QuizHypermediaComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W95,
		});
		modalRef.componentInstance.insert = insert;

		modalRef.componentInstance.fileSelected.subscribe((data: any) => {
			modalRef.close();
			const file = data.file;
			const id = currentControl.get("id").value;

			currentControl.get("answer").patchValue(file);
			currentControl.get("data").patchValue(URL.createObjectURL(file));

			this.saveOption.emit({
				element: { ...currentControl.getRawValue() },
				file: data.file,
			});
		});
	}

	closeModal(sendData?: any) {
		this.close.next(true);
	}

	public onFileSelected(index: number, event: any, type: HypermediaTypes) {
		if (+type === this.O_TEXTS) {
			const currentControl = this.optionFormTexto.value.answer;
			this.saveOptionText.emit(currentControl);
		} else {
			const currentControl = this.optionsArray.controls[
				index
			] as UntypedFormGroup;
			const file = event.target.files[0];
			currentControl.get("answer").patchValue(file);
			currentControl.get("data").patchValue(URL.createObjectURL(file));
			this.saveOption.emit({
				element: { ...currentControl.getRawValue() },
				file,
				index,
			});
		}
	}

	//activar click de los botones de hypermedia para los compu-correct
	openFile(i: number, type) {
		this.typeResp = "cargar";
		let current = this.optionsArray.controls[i];
		if (!this.answered) {
			if (
				type.type === this.O_VIDEOS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
			else if (type.type === this.O_PICTURES)
				document.getElementById("" + i).click();
			else if (
				type.type === this.O_AUDIOS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
			else if (
				type.type === this.O_PDFS &&
				this.optionsArray.controls[i].value.answer === null
			)
				document.getElementById("" + i).click();
		}
	}

	vewFileContent() {
		let url = `${environment.quizzesContent}`;
		for (let index = 0; index < this.optionsArray.controls.length; index++) {
			const element = this.optionsArray.controls[index];
			if (index !== 0) {
				if (element.value.type === this.O_PICTURES) {
					this.arrayFileView.push({ url: url + "picture/", view: false });
				} else if (element.value.type === this.O_VIDEOS) {
					this.arrayFileView.push({ url: url + "video/", view: false });
				} else if (element.value.type === this.O_PDFS) {
					this.arrayFileView.push({ url: url + "pdf/", view: false });
				} else if (element.value.type === this.O_AUDIOS) {
					this.arrayFileView.push({ url: url + "audio/", view: false });
				}
			} else {
				this.arrayFileView.push("");
			}
		}
	}

	//Remplazar contenido
	onFileSelectedReplace(i: number, type) {
		let current = this.optionsArray.controls[i];
		if (!this.answered) {
			if (type.type === this.O_VIDEOS) document.getElementById("" + i).click();
			else if (type.type === this.O_PICTURES)
				document.getElementById("" + i).click();
			else if (type.type === this.O_AUDIOS)
				document.getElementById("" + i).click();
			else if (type.type === this.O_PDFS)
				document.getElementById("" + i).click();
		}
	}

	//activar click de los botones de hypermedia para los compu-correct
	grabarAudio(index: number, type) {
		const openModal = () => {
			const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});
			modalRef.componentInstance.soloAudio = true;
			modalRef.result
				.then((res) => {
					// Lógica normal de guardado
					if (res?.files) {
						const currentControl = this.optionsArray.controls[
							index
						] as UntypedFormGroup;
						currentControl.get("answer").patchValue(res);
						currentControl.get("data").patchValue(res);
						this.saveOption.emit({
							element: { ...currentControl.getRawValue() },
							res,
							index,
						});
					}
				})
				.catch(() => {});
		};
		// Iniciar flujo por primera vez
		openModal();
	}

	_grabarAudio(i: any, value: any) {
		this.hideBoton = true;
    if (!this.tieneMicrofono) {
      this.mensajeError = this.translateService.instant("QUIZZES.MICROPHONEDETECTED");
      this.openModal(); // Abre el modal con el mensaje de error
      return;
    }

    if (!this.permisosConcedidos) {
      this.mensajeError = this.translateService.instant("QUIZZES.MICROPHONEPERMISSIONS");
      this.openModal(); // Abre el modal con el mensaje de error
      return;
    }
		this.typeResp = "audio";
    this.viewAudio = true;
    this.mensajeError = ''; // Restablece el mensaje de error
  }

	openModal() {
    this.dialogService.open(this.dialogTemplate);
  }

	private async verificarMicrofono() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.tieneMicrofono = true;
      this.permisosConcedidos = true;
      stream.getTracks().forEach(track => track.stop()); // Detener el stream inmediatamente
    } catch (error: any) {
      this.tieneMicrofono = false;
      this.permisosConcedidos = error.name !== 'NotAllowedError'; // Permiso denegado
    }
  }

	//activar click de los botones de hypermedia para los compu-correct
	grabarVideo(index: number, type) {
		this.typeResp = "video";
		const openModal = () => {
			const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
				scrollable: true,
				windowClass: MODAL_DIALOG_TYPES.W30,
			});

			modalRef.componentInstance.soloVideo = true;

			modalRef.result
				.then((res) => {
					// Manejar recarga del modal
					if (res?.reload) {
						openModal(); // Reabrir el modal recursivamente
						return;
					}

					// Lógica normal de guardado
					if (res?.files) {
						const currentControl = this.optionsArray.controls[
							index
						] as UntypedFormGroup;
						currentControl.get("answer").patchValue(res.files);
						currentControl.get("data").patchValue(res.files);

						this.saveOptionVideoCamera.emit({
							element: { ...currentControl.getRawValue() },
							res,
							index,
						});
					}
				})
				.catch(() => {});
		};

		// Iniciar flujo por primera vez
		openModal();
	}

	//activar click de los botones de hypermedia para los compu-correct
	tomarFoto(index: number, type) {
		this.typeResp = "foto";
		const modalRef = this.modalService.open(ModalMicrofonoAudioComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});
		modalRef.componentInstance.soloFoto = true;
		modalRef.result.then((res) => {
			const currentControl = this.optionsArray.controls[
				index
			] as UntypedFormGroup;
			currentControl.get("answer").patchValue(res.file);
			currentControl.get("data").patchValue(res.file);
			this.onSaveOptionWebCam.emit({
				element: { ...currentControl.getRawValue() },
				res,
				index,
			});
		});
	}

	closeElement(event: any, index: number) {
		this.hideBoton = false;
		this.deleteEmitir(index, null);
		this.viewAudio = event;
	}

	emitAudioEvent($event: File, index: number) {
		this.hideBoton = false;
		this.emitAudio.emit({
			audio: $event,
			index: index,
		});
	}

	readText() {
		let text = this.optionFormTexto.value;
		this.emitText.emit(text);
	}

	deleteUploadedElement(index: number): void {
		const control = this.optionsArray.controls[index] as UntypedFormGroup;
		const elementType = control.get('type').value;

		// 1. Restablecer valores del formulario
		control.get('answer').setValue(null);
		control.get('data').setValue(control.get('originalData').value);

		// 2. Actualizar filesUploadsViewContent según el tipo (opcional)
		switch (elementType) {
			case this.O_PICTURES:
				this.filesUploadsViewContent.answersImagen.splice(index, 1);
				break;
			case this.O_VIDEOS:
				this.filesUploadsViewContent.answersVideo.splice(index, 1);
				break;
			case this.O_AUDIOS:
				this.filesUploadsViewContent.answersAudio.splice(index, 1);
				break;
			case this.O_PDFS:
				this.filesUploadsViewContent.answersDocs.splice(index, 1);
				break;
		}

		// 3. Emitir evento de eliminación
		this.deleteEmitir(index, control);

		// 4. Reiniciar el input file
		const fileInput = document.getElementById(index.toString()) as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}

		// 5. **DESPUÉS** de emitir el evento, actualiza la vista (con setTimeout)
		setTimeout(() => {
			this.arrayFileView[index].view = false;
			this.arrayFileView[index].url = null;
			this.cdr.detectChanges(); // Fuerza la detección de cambios (si es necesario)
		}, 0);
	}

	deleteEmitir(index: number, control: any){
		if (this.typeResp === "cargar") {
			this.saveOption.emit({
				element: { ...control.getRawValue() },
				file: null,
				index,
				action: 'delete'
			});
		} else if (this.typeResp === "video") {
			this.saveOptionVideoCamera.emit({
				element: { ...control.getRawValue() },
				file: null,
				index,
				action: 'delete'
			});
		} else if (this.typeResp === "foto") {
			this.onSaveOptionWebCam.emit({
				element: { ...control.getRawValue() },
				file: null,
				index,
				action: 'delete'
			});
		} else if (this.typeResp === "audio") {
			this.emitAudio.emit({
				audio: null,
				index,
				action: 'delete'
			});
		}

	}
}
