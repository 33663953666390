
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NbCardModule, NbIconModule, NbTooltipModule } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalStudentsListComponent } from '../../modal-students-list/modal-students-list.component';
import { ModalTeachersListComponent } from '../../modal-teachers-list/modal-teachers-list.component';
import { ModalGruposEditarComponent } from '../../modal-grupos-editar/modal-grupos-editar.component';

@Component({
    selector: "app-modal-structure",
    imports: [CommonModule, NbCardModule, NbIconModule, NbTooltipModule, TranslateModule],
    templateUrl: "./actions-of-center.component.html",
    styleUrls: ["./actions-of-center.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsOfCenterComponent {
	constructor(
		public activeModal: NgbActiveModal,
		public translateService: TranslateService,
		private modalService: NgbModal
	) {
		//this.inactivityService.startInactivityTimer();
	}

	closeModal(sendData?: any) {
		this.activeModal.close(sendData);
	}

	studentsList() {
		this.modalService.open(ModalStudentsListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
	}

	teachersList(){
		let modal = this.modalService.open(ModalTeachersListComponent, {
			scrollable: false,
			windowClass: MODAL_DIALOG_TYPES.W90,
		});
		modal.componentInstance.cameFromCenter = true;
	}

	groupsList(){
		const modalGrupos = this.modalService.open(ModalGruposEditarComponent, {
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
	}
}
