import { Observable } from 'rxjs';
import { CanvasData } from 'src/app/core/models/tuturial/tutorial.model';
import { gNode } from './../../../../../core/models/graph/gNode.model';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/core/services/login";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { ModalAceptarCancelarComponent } from "src/app/shared/components/modal";
import { SigmaCanvasUtils } from "./canvas.utils";
import { map } from 'rxjs/operators';
import { NodeDetailInfoComponent } from '../../node-detail/components/node-detail-info/node-detail-info.component';
import { NodeService } from 'src/app/core/services/node/node.service';
import { ModalAssociationsEditNodosComponent } from 'src/app/shared/components/modal-associations-edit-nodos/modal-associations-edit-nodos.component';
import { SigmaCanvasService } from '../sigma-canvas.service';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';

declare var $
@Injectable({
  providedIn: 'root'
})
export class NodeUtils {

	constructor(public loginService: LoginService,
		private scUtils: SigmaCanvasUtils,
		private modalService: NgbModal,
		private nodeService: NodeService,
		private translateService: TranslateService,
		private router: Router,
		private sigmaCanvasService: SigmaCanvasService,
		private toaster: ToasterService
	) { }
    openModalNodeAlquilarConfirm (e: any) {
      const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
      {
        scrollable: true,
        windowClass: MODAL_DIALOG_TYPES.W30
      });
      modalRef.componentInstance.mensaje = this.translateService.instant('RENTNODEQUIZ.RENTNODE');
      modalRef.result.then((result: boolean) => {
      // Si devuelve true lo borro y si devuelve false no hago nada
        if (result) {

          e.x = JSON.parse(localStorage.getItem('nodeX'));
          e.y = JSON.parse(localStorage.getItem('nodeY'))
          this.nodeService.alquilarNodo(e).subscribe(res => {
            this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTNODEOK'));
            let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
            let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
            localStorage.setItem('modoTraerNodo', 'false')
            this.loginService.setProfile(Profiles.Author);
            this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);
          },
          err => {
            this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTNODEKO'));
            let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
            let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
            localStorage.setItem('modoTraerNodo', 'false')
            this.loginService.setProfile(Profiles.Author);
            this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);
            console.log(err)
          })
        }
        else
          this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTNODECANCEL'));
      });
  }


  openModalActividadAlquilarConfirm (e: any) {
    const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
    {
      scrollable: true,
      windowClass: MODAL_DIALOG_TYPES.W30
    });
    modalRef.componentInstance.mensaje = this.translateService.instant('RENTNODEQUIZ.RENTACTIVITY');
    modalRef.result.then((result: boolean) => {

      if (result) {
          e.x = JSON.parse(localStorage.getItem('nodeX'));
          e.y = JSON.parse(localStorage.getItem('nodeY'))
          this.nodeService.alquilarQuiz(e).subscribe(res => {
            ////**
            let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
            let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
            localStorage.setItem(LOCALSTORAGESTRINGS.GETACTIVITY, 'false')
            this.loginService.setProfile(Profiles.Author);
            this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);
            this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTACTIVITYOK'));

          },
          err => {
            let idNode_Course:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.COURSEID))
            let idNode_Target:number = JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.GRAPHID))
            localStorage.setItem(LOCALSTORAGESTRINGS.GETACTIVITY, 'false')
            this.loginService.setProfile(Profiles.Author);
            this.router.navigate([`/course/${idNode_Course}/graph/${idNode_Target}`]);
            this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTACTIVITYKO'));
            console.log(err)})
        }
      else {
        this.toaster.success(this.translateService.instant('RENTNODEQUIZ.RENTACTIVITYCANCEL'));
      }
    });
}


    editNode(node:gNode, isNewNode:boolean, canEdit:boolean, showHelp:boolean, tutorialGrafo:CanvasData):Promise<number>{

        return new Promise((resolve, error) => {

            this.scUtils.hidePopWindows()
            this.nodeService.getNode(node.idOriginal, this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId).pipe(map((res: any) => res.data[0])).subscribe(res => {
                const modalRef = this.modalService.open(NodeDetailInfoComponent,
                    {
                        scrollable: true,
                        windowClass: `${MODAL_DIALOG_TYPES.W90}`
                    }
                );

                modalRef.componentInstance.node = res;
                modalRef.componentInstance.idCurso = this.sigmaCanvasService.courseId;
                modalRef.componentInstance.idMapa = this.sigmaCanvasService.graphId;
                modalRef.componentInstance.canEdit = canEdit;
                modalRef.componentInstance.course = this.sigmaCanvasService.course;
                modalRef.componentInstance.isNewNode = isNewNode || false;

                modalRef.result.then().finally(() => resolve(0))
            })
        });

    }

    associateNode(node:any, idCourse:number, idGraph:number, canEdit:boolean){
        localStorage.setItem(LOCALSTORAGESTRINGS.N1, JSON.stringify(node))
        localStorage.setItem(LOCALSTORAGESTRINGS.COURSEID, JSON.stringify(idCourse))
        localStorage.setItem(LOCALSTORAGESTRINGS.GRAPHID, JSON.stringify(idGraph))
        this.scUtils.hidePopWindows()

        const modalRef = this.modalService.open(ModalAssociationsEditNodosComponent,
            {
                windowClass: MODAL_DIALOG_TYPES.W100
            }
        );
        modalRef.componentInstance.canEdit = canEdit;
        modalRef.componentInstance.listQuiz = [];
    }

    conectarNodo(datos: any, sigmaUtils: any) {
        const type = datos['line'];
        const settings = { size: datos['size'], color: datos['color'], label: datos['label'] };
        const nodeFrom = { ...datos['from'] };
        const nodeTo = { ...datos['to'] };
        this.okConnect(nodeFrom, nodeTo, type, settings, sigmaUtils);
    }

    private okConnect(nodeFrom, nodeTo, type, settings,sigmaUtils) {
        if (nodeFrom && nodeTo) {
            sigmaUtils.connectNodes(nodeFrom.id, nodeTo.id, type, settings);
        }
    }

    public registrarNodo():Observable<any>{
        this.scUtils.hidePopWindows()
        var tempNode = this.sigmaCanvasService.sigmaUtils.sigma.graph.nodes('temp');
        tempNode.subject = { idSubject: this.sigmaCanvasService.course.subject.idSubject };
        tempNode.label = this.translateService.instant('NODOS.DEFAULTTITLE');
        tempNode.published = Date.now()
        tempNode.description = '';
        return this.nodeService.createNode(this.sigmaCanvasService.courseId, this.sigmaCanvasService.graphId, tempNode, undefined)
    }

		public registrarNodoCustom(nodo):Observable<any>{
			this.scUtils.hidePopWindows();

			nodo.subject = {
				idSubject: this.sigmaCanvasService.course.subject.idSubject,
			};
			//nodo.label = this.translateService.instant("NODOS.DEFAULTTITLE");
			nodo.published = Date.now();
			//nodo.description = "";
			return this.nodeService.createNode(
				this.sigmaCanvasService.courseId,
				this.sigmaCanvasService.graphId,
				nodo,
				undefined
			);
		}
}
