import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isThisHour } from 'date-fns';
import { User } from 'src/app/core/models/users/user.models';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { LoginService } from 'src/app/core/services/login';
import { PROMPT_AGENT_GPT_INITIAL } from 'src/app/core/models/masters/masters.enum';
import { SpeechToTextService } from './speech-to-text.service';
import { AgentService } from 'src/app/core/services/agents/agent.service';
@Component({
    selector: "app-agent-gpt",
    templateUrl: "./agent-gpt.component.html",
    styleUrls: ["./agent-gpt.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AgentGptComponent implements OnInit {
	 @Output() close = new EventEmitter();
		public showSettings = false;
		// ...other properties and methods...

		user: User;
		messages: { sender: string; text: string }[] = [];
		userInput = "";
		context = "";
		loading = false; // Add loading variable
	initialPrompt: string = "";

	isRecording = false;
  transcribedText: string = '';
	userAgentInfo: any;

		constructor(
				private chatService: ChatService,
				private loginService: LoginService,
				public activeModal: NgbActiveModal,
				private speechService: SpeechToTextService,
				private agentService: AgentService
		) {
				this.user = this.loginService.getUser();
		}

		ngOnInit(): void {
				this.getAngentInformation();
		}

		toggleSettings(): void {
			this.showSettings = !this.showSettings;
		}

		getAngentInformation(){
			this.agentService.getAgentByIdUser(this.user.idUser).subscribe(response => {

				this.userAgentInfo = response;
				this.buildInitialPrompt();
			});
		}

		async toggleRecording(): Promise<void> {
			if (this.isRecording) {
				this.speechService.stopRecording();
				this.isRecording = false;
			} else {
				this.isRecording = true;
				try {
					const transcribedText = await this.speechService.startRecording();
					console.log("Transcribed text:", transcribedText);
					if (transcribedText && transcribedText.trim().length > 0) {
						this.userInput = transcribedText;
						await this.sendMessage(); // Call sendMessage only when we received valid text
					} else {
						console.error("No transcribed text was returned.");
					}
				} catch (error) {
					console.error("Error during transcription:", error);
				} finally {
					this.isRecording = false;
				}
			}
		}

		async buildInitialPrompt(): Promise<void> {
			let userContext = ''
			let userData = '';
			this.agentService.getInitialInfo().subscribe(response => {
				userContext = JSON.stringify(response.data);
				userData = JSON.stringify(this.loginService.getUserContext());
				this.initialPrompt = `${this.userAgentInfo.prompt} esta es la informacion de el usuario: ${userData} ademas de eso aqui hay incluso mas informacion de el usuario como los grupos a los que pertenece, materias, etc ${userContext}`;
				this.initializeChat();
			});

		}

		async initializeChat(): Promise<void> {
			this.loading = true; // Set loading to true
			try {
				// Await the sendDirectMessage call so that loading is held until it finishes
				const response = await this.chatService.sendDirectMessage(this.initialPrompt, '');
				const chatMessage = { sender: "chatgpt", text: response };
				this.loadMessageHistory(); // Load message history from localStorage
				this.messages.push(chatMessage);
				// Optionally process the response here
			} catch (error) {
				console.error("Error sending direct message:", error);
			} finally {
				this.loading = false; // Set loading to false once complete
			}

		}



		async sendMessage(): Promise<void> {
			if (this.userInput.trim() && !this.loading) {
				this.loading = true; // Set loading to true
				const userMessage = { sender: "user", text: this.userInput };
				this.messages.push(userMessage);

				// Actualizar el contexto con el nuevo mensaje y la respuesta de ChatGPT
				this.context += `\nUsuario: ${this.userInput}`;

				const response = await this.chatService.sendDirectMessage(
					this.initialPrompt,
					this.context
				);

				const chatMessage = { sender: "chatgpt", text: response };
				this.messages.push(chatMessage);

				// const utterance = new SpeechSynthesisUtterance(response);

    		// window.speechSynthesis.speak(utterance);

				this.speechService.speak(response, this.userAgentInfo.voice);


				this.userInput = "";
				this.loading = false; // Set loading to false
				this.saveMessageHistory(); // Save message history to localStorage
			}
		}

		saveMessageHistory(): void {
				localStorage.setItem('chatMessages', JSON.stringify(this.messages));
		}

		loadMessageHistory(): void {
				const savedMessages = localStorage.getItem('chatMessages');
				if (savedMessages) {
						this.messages = JSON.parse(savedMessages);
				}
		}

		closeModal(sendData?: any) {
			this.close.emit(sendData);
			this.activeModal.close();
		}
}
