<nb-card class="mb-0 no-edge-card m-auto max-w-900">
	<!-- input oculto para que las opciones de los quiz no tengan el focus -->
	<input type="text" hidden>
	<div *ngIf="loadingResponses && loginService.esProfesor()" class="overlay">
		<b class="m-4">{{'LOADING.CARGANDORESPUESTAS' | translate }}</b>
		<div class="loaderBar"></div>
	</div>
	<div *ngIf="loadingResponses && loginService.esEstudiante() && challengeOn" class="overlay">
		<b class="m-4">{{'SOCKETQUIZPREVIEW.WAITACTIVITY' | translate }}</b>
		<div class="loaderBarInfinite"></div>
	</div>
	<nb-card-header class="border-bottom-0 p-0">
		<app-quiz-play-body-info [courseId]="courseId" [graphId]="graphId" [quiz]="quiz" [timer$]="time$" [viewQuizDuration]="viewQuizDuration"
			(timeEnded)="onTimeEnded($event)" [stopTime]="stopTime">
		</app-quiz-play-body-info>
	</nb-card-header>
		<div class="mt-4 p-2">
			<app-quiz-play-body-data [questions]="questions"></app-quiz-play-body-data>
		</div>
		<div class="mt-4 p-2"
			[ngClass]="{
				'hidden' : isLoading
			}">
			<app-quiz-play-body-option *ngIf="quiz" [options]="options" [selectedType]="selectedType" [loadingResponses]="loadingResponses"
				(saveOption)="onSaveOption($event)"
				(saveOptionVideoCamera)="saveOptionVideoCamera($event)"
				(onSaveOptionWebCam)="onSaveOptionWebCam($event)"
				(saveOptionMultiple)="saveOptionMultiple($event)"
				(saveOptionChallenge)="onSaveOptionChallenge($event)"
				(emitText)="emitText($event)" (emitAudio)="enviarAudioRecorder($event)"
				(saveOptionText)="saveTxt($event)" [quiz]="quiz" [quizType]="quizType" [answered]="answered" [modeSocket]="modeSocket">
			</app-quiz-play-body-option>
	</div>
	<div class="upload-container" *ngIf="isLoading && !uploadComplete">
		<div *ngIf="showGif !== true" class="upload-progress-bar">
			<div class="upload-progress" [style.width.%]="uploadProgress"></div>
		</div>
		<div *ngIf="showGif">
			<div class="loaderBar"></div>
		</div>
		<div class="upload-message" *ngIf="showUploadComplete == false">
			{{'SOCKETQUIZPREVIEW.UPLOADINGFILES' | translate }} {{uploadProgress}}%
		</div>
	</div>
	<div class="upload-complete-message" *ngIf="uploadComplete || showUploadComplete == true">
		<mat-icon>cloud_done</mat-icon>
    {{'SOCKETQUIZPREVIEW.UPLOADCOMPLETE' | translate }}
  </div>
	<div class="mt-4" *ngIf="(answered && answers.length)">
		<app-quiz-play-body-answer [answers]="answers"></app-quiz-play-body-answer>
	</div>

	<nb-card-footer class="border-top-0">

		<div class="row justify-content-center" *ngIf="loginService.esEstudiante() || loginService.esProfesor() || challengeOn || modePractice">
			<div *ngIf="!answered && !hideCheckButton" class="col-sm-12 col-md-10 d-flex justify-content-center py-3">
				<button nbButton
					[ngClass]="{
					'disabled' : isButtonDisabled && quiz.quizType == '1',
					'btn-themecolor' : !isButtonDisabled || !responseByFile,
					}"
					(click)="discoverAnswer()">
					<div *ngIf="!responseByFile"><nb-icon icon="checkmark-circle-2"></nb-icon>{{'QUIZPLAYBODY.CHECK' | translate}}</div>
					<div *ngIf="responseByFile"><nb-icon icon="checkmark-circle-2"></nb-icon>{{'TASK.ENVIAR' | translate}}</div>
				</button>
			</div>
		</div>

		<!-- <div *ngIf="mostrarMensajeTimeAcabado && loginService.esEstudiante()" class="alert alert-danger m-4" role="alert">
				{{'QUIZZES.DIALOGS.TIEMPOFUERA' | translate}}
		</div> -->
	</nb-card-footer>
</nb-card>
