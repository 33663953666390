<nb-card class="no-edge-card mb-0 modal-height-100-62">
	<nb-card-header class="border-bottom-0 background_themecolor">
		<div class="container">
			<div class="px-2 px-sm-5 py-2">
				<div class="d-flex justify-content-between gap-4 container-item-small">
					<!-- Asistencia IA -->
					<div class="item-style item-small">
						<h6>Asistencia IA</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/experta ia.png" width="70" alt='Asistencia IA'>
						</div>
					</div>
					<!-- Familia -->
					<div class="item-style item-small">
						<h6>Familia</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/familia.png" width="70" alt='Familia'>
						</div>
					</div>
					<!-- Mensajes -->
					<div class="item-style item-small">
						<h6>Mensajes</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/mensajes.png" width="70" alt='Mensajes'>
						</div>
					</div>
					<!-- Servicios -->
					<div class="item-style item-small">
						<h6>Servicios</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/public-service.png" width="70" alt='Servicios'>
						</div>
					</div>
					<!-- Explorar -->
					<div class="item-style item-small">
						<h6>{{ "MENUACTIONS.EXPLORE" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/magnifying-glass.png" width="70" alt='{{ "MENUACTIONS.TEACHERSUBTITLE6" | translate }}'>
						</div>
					</div>
					<!-- Configuracion -->
					<div class="item-style item-small">
						<h6>{{ "SIDEBAR.SETTINGS" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/gear.png" width="70" alt='{{ "USERDATA.CONFIGURACION" | translate }}'>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-body class="px-0 px-sm-2">
		<div class="container">
			<div class="px-2 px-sm-5 h-325">
				<!-- ===============Fila superior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-2 h-30">
					<!-- ***Informes*** -->
					<div class="item-style item-medium flex-column gap-0">
						<h6>Informes</h6>
						<div class="d-flex gap-3 h-100">
							<!-- imagen -->
							<div class="d-flex" style="flex: 1; max-width: 100%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/grafica_7.png" width="70" alt='Informes'>
								</div>
							</div>
							<!-- grafico -->
							<!-- <div class="item-graphic">
								<app-barras-profesor></app-barras-profesor>
								<div class="layer"></div>
							</div> -->
						</div>
					</div>
					<!-- ***Trabajo*** -->
					<div class="item-style item-medium">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 1; max-width: 100%;">
							<h6>Trabajo</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Trabajo.jpg" style="max-width: 140px !important;" alt='Trabajo'>
							</div>
						</div>
						<!-- grafico -->
						<!-- <div class="item-graphic">
							<app-torta-grupos></app-torta-grupos>
							<div class="layer"></div>
						</div> -->
					</div>
					<!-- ***Asistencia*** -->
					<div class="item-style item-medium">
						<!-- grafico -->
						<!-- <div class="item-graphic">
							<app-lista-alumnos></app-lista-alumnos>
							<div class="layer"></div>
						</div> -->
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 1; max-width: 100%;">
							<h6 class="text-right">Asistencia</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/asistencia.png" width="70" alt='Asistencia'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila intermedia================== -->
				<div class="d-flex justify-content-between flex-wrap item-style-large gap-4 mt-4 h-30">
					<!-- ***Proyectos*** -->
					<div class="item-large flex-column gap-0">
						<h6>Proyectos</h6>
						<div class="d-flex gap-3 h-100">
							<!-- imagen -->
							<div class="d-flex" style="flex: 1; max-width: 100%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/Proyectos-prof.png" width="100" alt='Proyectos'>
								</div>
							</div>
							<!-- grafico -->
							<!-- <div class="item-graphic">
								<app-dispersion-asignaturas></app-dispersion-asignaturas>
								<div class="layer"></div>
							</div> -->
						</div>
					</div>
					<!-- ***Grafica torta*** -->
					<div class="item-chart">
						<!-- <div class="text-absolute">
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;">
							</nb-tag>
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;">
							</nb-tag>
						</div> -->
						<!-- <div class="pie_chart">
							<app-solar-centro></app-solar-centro>
						</div> -->
						<!-- imagen -->
						<div class="d-flex" style="flex: 1; max-width: 100%;">
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/grafico.png" style="max-width: 80% !important;" alt='Proyectos'>
							</div>
						</div>
					</div>
					<!-- ***Peligros*** -->
					<div class="item-large">
						<!-- grafico -->
						<!-- este comentario y la clase temporal se deben quitar cuando se sustituya la imagen por el grafico -->
						<!-- <div class="item-graphic clase-temporal">
							<img src="../../../../assets/images/control-panel/grafica_2.png">
							<div class="layer"></div>
						</div> -->
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 1; max-width: 100%;">
							<h6 class="text-right">Peligros</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/peligros.png" width="70" alt='Peligros'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila inferior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-4 h-30">
					<!-- ***Social*** -->
					<div class="item-style item-medium flex-column position-relative">
						<!-- texto e imagen -->
						<h6>Social</h6>
						<div class="d-flex gap-3">
							<div class="d-flex">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/sos.png"
									style="
									max-width: 50px !important;
									position: absolute;
									left: 30px;
									bottom: 30px;" alt='Social'>
								</div>
							</div>
							<div class="d-flex" style="flex: 1; max-width: 100%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/social-competicìón.jpg" style="max-width: 50px !important;" alt='Social'>
								</div>
							</div>
							<div class="d-flex">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/grupos.png"
									style="
									max-width: 50px !important;
									position: absolute;
									right: 30px;
									top: 30px;" alt='Social'>
								</div>
							</div>
						</div>
						<!-- grafico -->
						<!-- <div class="item-graphic">
							<app-radial-niveles></app-radial-niveles>
							<div class="layer"></div>
						</div> -->
					</div>
					<!-- ***Psicológico*** -->
					<div class="item-style item-medium">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 1; max-width: 100%;">
							<h6>Psicológico</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/psicológico.jpg" width="70" alt='Psicológico'>
							</div>
						</div>
						<!-- grafico -->
						<!-- este comentario y la clase temporal se deben quitar cuando se sustituya la imagen por el grafico -->
						<!-- <div class="item-graphic clase-temporal">
							<img src="../../../../assets/images/control-panel/grafica_3.png">
							<div class="layer"></div>
						</div> -->
					</div>
					<!-- ***Encuestas*** -->
					<div class="item-style item-medium flex-column gap-0">
						<h6 class="text-right">Encuestas</h6>
						<div class="d-flex gap-3 h-100">
							<!-- grafico -->
							<!-- <div class="item-graphic">
								<app-barras-padres></app-barras-padres>
								<div class="layer"></div>
							</div> -->
							<!-- imagen -->
							<div class="d-flex" style="flex: 1; max-width: 100%;">
								<div class="item-image">
									<img src="../../../../assets/images/control-panel/encuestas.png" width="70" alt='Encuestas'>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
