import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-barras-admin',
    templateUrl: './barras-admin.component.html',
    styleUrls: ['./barras-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class BarrasAdminComponent implements OnInit {
	@Input() datasetsImported: any = null;
	@Input() labelsImported: any = null;
	@Input() consultaID: any = null;
	@Input() role: any = null;
	@Output() verDetalle = new EventEmitter<{ datasetIndex: number; index: number }>();

	chart: any = null;
	labels: any[] = [];
	datasets: any[] = [];

	titulos = [
		{
			id: 1,
			title: this.translateService.instant("REPORT.EDITORSSIGNED"),
		},
		{
			id: 2,
			title: this.translateService.instant("REPORT.STUDENTSSIGNED"),
		},
		{
			id: 3,
			title: this.translateService.instant("REPORT.TEACHERSSIGNED"),
		},
		{
			id: 4,
			title: this.translateService.instant("REPORT.PARENTSSIGNED"),
		},
		{
			id: 5,
			title: this.translateService.instant("REPORT.EDITORSACTIVITY"),
		},
		{
			id: 6,
			title: this.translateService.instant("REPORT.STUDENTSACTIVITY"),
		},
		{
			id: 7,
			title: this.translateService.instant("REPORT.TEACHERSACTIVITY"),
		},
		{
			id: 8,
			title: this.translateService.instant("REPORT.PARENTSACTIVITY"),
		},
	];
	titulo: string = '';

  constructor(
		private translateService: TranslateService,
	) { }

  ngOnInit() {
	if (this.chart) {
		this.chart.destroy();
	}
	this.datasets = [this.obtenerLabelsConEstructura(this.datasetsImported, 0)];
	this.labels = this.obtenerLabels(this.datasetsImported, 0);
	setTimeout(() =>	{
		this.createChart();
	}, 200);
  }

	obtenerLabels(dataSets, index) {
		let filteredData;
		filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    return dataSets.map(info => info.label);
	}
	obtenerLabelsConEstructura(dataSets, index) {
		let filteredData;
		filteredData = dataSets
		.map(info => info.data[index])
		.filter(value => value !== null);
    const backgroundColor = filteredData.map(value => this.colorKnowledge(value));
    return {
        label: '',
        data: filteredData,
        backgroundColor: backgroundColor,
    };
	}
	colorKnowledge(valor: number): string {
		let color = "rgba(154, 155, 156, 0.5)"; // gris con 50% de transparencia por defecto
		color = this.generateHexCode();
		return color;
	}
	generateHexCode() {
		const letters = "89ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 8)];
		}
		return color;
	}

	createChart(){
		// Primero creamos el título
		this.titulo = this.titulos.find(t => t.id === this.consultaID)?.title || '';
		var canvas = document.getElementById('myChart') as HTMLCanvasElement;
		var ctx = canvas.getContext('2d');
		if (this.chart) {
			this.chart.destroy();
		}
		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: this.labels, // Etiquetas para las barras
				datasets: this.datasets
			},
			options: {
				//indexAxis: 'y',
				responsive : true,
				plugins: {
					title: { 
						display: true,
						text: this.titulo, // Aquí se mostrará el título arriba de la tabla
						font: {
							size: 18, // Ajusta el tamaño de la fuente
							weight: 'bold' // Puedes hacerlo en negrita
						},
						padding: {
							top: 10,
							bottom: 20
						}
					},
					legend: {
						display: false,
						position: 'right',
					},
					filler: {
						propagate: true,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: false,
								//modifierKey: 'ctrl', // Se activa con la tecla Control
							},
							pinch: {
								enabled: false,
							},
							mode: 'x',
						},
						pan:{
							enabled: true,
							mode: 'x',
							scaleMode: 'x',
						},
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								label += context.raw; 
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						stacked: false,
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						title: {
							display: false,
							text: this.titulo,
						},
					},
					y: {
						stacked: false,
						suggestedMin: 0,
						position: 'top',
						border: {
							display: true,
							color: '#000', // Color del eje x
							width: 3 // Grosor del eje x
						},
						ticks: {
							callback: function(value) {
								return value;
							}
						}
					}
				},
				onClick: (event, elements) => {
					if (elements.length > 0) {
						const index = elements[0].index; // Índice de la barra clickeada
						const datasetIndex = elements[0].datasetIndex; // Índice del dataset	
						// Emitimos el evento con los datos
						this.verDetalle.emit({ datasetIndex, index });
					}
				}
			}
		});
	}

}
