import { LoginService } from 'src/app/core/services/login';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';

@Component({
    selector: 'app-quiz-play-body-answer',
    templateUrl: './quiz-play-body-answer.component.html',
    styleUrls: ['./quiz-play-body-answer.component.scss'],
    standalone: false
})
export class QuizPlayBodyAnswerComponent implements OnInit {
    @Input() answers: any[];
    answerForm: UntypedFormGroup;
    mostrarBotones = false;

    constructor(private fb: UntypedFormBuilder, public activeModal: NgbActiveModal) {
        this.answerForm = this.fb.group({
            answers: this.fb.array([])
        });
    }

    ngOnInit() {
        this.answersArray.clear();
        this.answers.forEach(q => {
            this.addAnswer(q);
        });
    }

    public get answersArray(): UntypedFormArray {
        return this.answerForm.get('answers') as UntypedFormArray;
    }

    public get answersArrayData(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get answersArrayType(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get answersArrayWidth(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get answersArrayHeight(): UntypedFormControl[] {
        return this.answersArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    private addAnswer(answer: any): void {
        const newAnswer: UntypedFormGroup = this.createAnswer(answer);
        this.answersArray.push(newAnswer);
    }

    private createAnswer(answer: any): UntypedFormGroup {
        return this.fb.group({
            id: { value: answer.idQuizzesDataElements, disabled: true },
            data: { value: answer.data, disabled: true },
            type: { value: answer.elementType, disabled: true },
            order: { value: answer.yPosition, disabled: true },
            width: answer ? answer.xSize : 0,
            height: answer ? answer.ySize : 0
        });
    }


    successOrNotSuccessful(result: boolean): void{
        this.activeModal.close(result)
    }
}
