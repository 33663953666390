import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AcceptDialogComponent } from 'src/app/shared/components/accept-dialog/accept-dialog.component';
import { ConditionsDialogComponent } from 'src/app/shared/components/conditions-dialog/conditions-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogContactComponent } from 'src/app/shared/components/dialog-contact/dialog-contact.component';
import { DialogCookiesComponent } from 'src/app/shared/components/dialog-cookies/dialog-cookies.component';
import { DialogPolicyComponent } from 'src/app/shared/components/dialog-policy/dialog-policy.component';
import { DialogWeComponent } from 'src/app/shared/components/dialog-we/dialog-we.component';
import { PrivacyDialogComponent } from 'src/app/shared/components/privacy-dialog/privacy-dialog.component';

@Injectable({
    providedIn: "root",
})
export class DialogService {
    constructor(private dialog: MatDialog) {}

    openConfirmDialog(
        isValid: boolean,
        title: string,
        message: string,
        panelClasses: string[] = [],
        messageArray: string[] = [],
    ): MatDialogRef<ConfirmDialogComponent> {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title,
                message,
                messageArray,
            },
            panelClass: ["salware-dialog", ...panelClasses, isValid ? "ok" : "nok"],
        });

        return dialog;
    }

    openAcceptDialog(
        isValid: boolean,
        title: string,
        message: Record<string, string>,
        panelClasses: string[] = [],
        okButton?: string,
        koButton?: string
    ): MatDialogRef<AcceptDialogComponent> {
        const dialog = this.dialog.open(AcceptDialogComponent, {
            data: {
                title,
                message,
                okButton,
                koButton,
            },
            panelClass: ["salware-dialog", ...panelClasses, isValid ? "ok" : "nok"],
        });

        return dialog;
    }

    openPrivacyDialog() {
        const dialog = this.dialog.open(PrivacyDialogComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }

    openConditionsDialog(): MatDialogRef<ConditionsDialogComponent> {
        const dialog = this.dialog.open(ConditionsDialogComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }

    /*TODO Alex Poner aqui que en funcion del dominio abra unos dados u otros*/
    openDialogPolicy() {
        const dialog = this.dialog.open(DialogPolicyComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }

    openDialogWe() {
        const dialog = this.dialog.open(DialogWeComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }

    openDialogCookie() {
        const dialog = this.dialog.open(DialogCookiesComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }

    openDialogContact() {
        const dialog = this.dialog.open(DialogContactComponent, {
            panelClass: ["salware-dialog", "ok"],
        });
        return dialog;
    }
}
