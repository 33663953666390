import { User } from '../../../core/models/users/user.models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, } from '@angular/forms';

// Utils
import { LocalStorage } from 'src/app/core/utils';

// Models
import { UserRegisterModel, UserProfileValidationModel, UserParentModel, UserSettingsProfileModel } from 'src/app/core/models/users';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AlertService, TitleService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Mapper
import { UsersRegisterMapperService, UsersParentsMapperService, UsersProfileValidationMapperService } from 'src/app/core/services/mapper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data-padre.component.html',
    styleUrls: ['../user-data-general/user-data-general.component.scss'],
    standalone: false
})
export class UserDataPadreComponent implements OnInit {

  public formularioDatos: UntypedFormGroup;
  public formularioDatosHijo: UntypedFormGroup;

  validationMessages = {
    creditCard: [],
    expirationDateCard: [],
    cvv: []
  };

  verDetalleHijo = false;

  userData: User;
  usersParentData: UserParentModel;

  usersProfileValidationData: UserProfileValidationModel[];

  sonsData: UserRegisterModel[] = [];
  sonData: UserRegisterModel;
  sonValidated = false;
  idSonSelected: number;

  usersSettingsProfileData: UserSettingsProfileModel[];

  public loading = false;

  constructor(private formBuild: UntypedFormBuilder,
              public translateService: TranslateService,
              private titleService: TitleService,
              private alertService: AlertService,
              private localStorage: LocalStorage,
              public loginService: LoginService,
              private usersService: UsersService,
              private usersRegisterMapperService: UsersRegisterMapperService,
              private usersParentsMapperService: UsersParentsMapperService,
              private usersProfileValidationMapperService: UsersProfileValidationMapperService,
              public activeModal: NgbActiveModal
              ) {

  }

  ngOnInit(): void {

    // Lanzo las traducciones
    this.traducirOpciones();

    // Cargo las validaciones del formulario
    this.cargarValidacionesFormularios();

    // Cargo los formularios
    this.crearFormularios();

    // Y obtengo la informacion del usuario
    this.obtenerDatosUsuario();
  }

  private traducirOpciones() {
    // Recupero el lenguaje
    const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
    this.translateService.use(lang);

    // Titulo pagina
    this.translateService.get('USERDATA.CONFIGURACIONPADRE').subscribe((res: string)  => {
      this.titleService.setHTMLTitle(res);
      this.titleService.setBarTitle(res);
    });
  }

  private cargarValidacionesFormularios() {

    this.translateService.get('VALIDACIONES.LATARJETADECREDITONOESVALIDA').subscribe((res: string) => {
      this.validationMessages.creditCard.push({type: 'pattern', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER16CARACTERES').subscribe((res: string) => {
      this.validationMessages.creditCard.push({type: 'minlength', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER5CARACTERES').subscribe((res: string) => {
      this.validationMessages.expirationDateCard.push({type: 'minlength', message: res});
    });

    this.translateService.get('VALIDACIONES.DEBETENER3CARACTERES').subscribe((res: string) => {
      this.validationMessages.cvv.push({type: 'minlength', message: res});
    });
  }

  // Mostrar Aviso
  public mostrarMensajeAviso() {

    // Para poder hacer las comparaciones
    //this.userData.usersProfileValidation = this.usersProfileValidationData;

    const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, 'padre');

    if (resp.result !== true) {
        this.alertService.info(this.translateService.instant(resp.mensaje),
                               AlertService.AlertServiceContextValues.UserData);
    } else {
      this.alertService.clear();
    }
  }
  // Fin mostrar aviso

  private crearFormularios() {

    // Creo el formulario de los datos del padre
    this.formularioDatos = this.formBuild.group({
      idUser: [this.loginService.getUser().idUser],
      idUserParent: [0],
      creditCard: [''],
      expirationDateCard: [''],
      cvv: ['']
    });

    // Y de los datos del hijo
    this.formularioDatosHijo = this.formBuild.group({
      idUser: [''],
      firstName: [''],
      surname: [''],
      pictureUser: [''],
      mail: [''],
      birthdate: [''],
      mobile: ['']
    });
  }

  private cargarDatosFormulario() {

    if (this.usersParentData !== null && this.usersParentData != undefined) {
      this.formularioDatos.controls.idUser.setValue(this.userData.idUser);
      this.formularioDatos.controls.idUserParent.setValue(this.usersParentData.idUserParent);
      this.formularioDatos.controls.creditCard.setValue(this.usersParentData.creditCard);
      this.formularioDatos.controls.expirationDateCard.setValue(this.usersParentData.expirationDateCard);
      this.formularioDatos.controls.cvv.setValue(this.usersParentData.cvv);
    }
  }

  private registrarUserSettingProfile() {

    // Y si era la primera vez que grababa algo siendo estudiante lo registro en users_settings_profile
    if (this.usersSettingsProfileData.length === 0 ||
        this.usersSettingsProfileData.filter(x => x.profile === 'padre').length === 0) {

      let userSettingProfile = new UserSettingsProfileModel();

      userSettingProfile.idUserSettingProfile = 0;
      userSettingProfile.idUser = this.userData.idUser;
      userSettingProfile.profile = 'padre';
    }
  }

  private obtenerDatosUsuario() {

    this.loading = true;

    this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
                      .subscribe( resp => {
                        if (resp.data.users.nodes.length !== 0) {

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos padre - Datos de usuario padre`, 'OK');

                          // Mapeo los datos del usuario
                          this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);

                          // Mapeo los datos del usuario-padre
                          if (resp.data.users.nodes[0].usersParents.length > 0) {
                            this.usersParentData = this.usersParentsMapperService.transform(resp.data.users.nodes[0].usersParents[0]);
                          }

                          // Los de las validaciones del usuario (si las tiene)
                          if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                            // tslint:disable-next-line: max-line-length
                            this.usersProfileValidationData = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                          }

                          // Cargo los user settings profile para ver que menus van en gris
                          this.usersSettingsProfileData = resp.data.users.nodes[0].usersSettingsProfiles;

                          if (this.usersSettingsProfileData === undefined) {
                            this.usersSettingsProfileData = [];
                          }

                          // Cargo los datos
                          this.cargarDatosFormulario();

                          // creo el formulario y cargo los datos
                          this.obtenerDatosHijos();

                          // Y el mensaje de aviso en funcion de si ha sido validado o no.
                          this.mostrarMensajeAviso();

                          this.loading = false;
                        } else {
                          // tslint:disable-next-line: max-line-length
                          this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                          // tslint:disable-next-line: max-line-length
                          //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos padre - Datos de usuario padre`, 'ERROR');
                        }
    }, (error) => {
      this.loading = false;

      // tslint:disable-next-line: max-line-length
      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

      // tslint:disable-next-line: max-line-length
      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos padre - Datos de usuario padre - ERROR: ${error}`, 'ERROR');
    });
  }

  public grabarDatos() {

    if (this.formularioDatos.invalid) {
      return;
    }

    // Recupero los datos del formulario
    this.loading = true;

    const campos = this.formularioDatos.value;

    // Y restauramos los datos del usuario en base al formulario
    // Los campos que no van a ser modificados iran a undefined y no se actualizaran
    this.userData = this.usersRegisterMapperService.transform(campos);

    // El telefono no se actualiza
    this.userData.mobile = null;

    this.usersService.updateUser(this.userData)
                      .subscribe((resp: any) => {

                        // tslint:disable-next-line: max-line-length
                        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario padre`, 'OK');

                        // Grabo los datos del padre (tarjeta bancaria)
                        this.usersParentData = this.usersParentsMapperService.transform(campos);
                        this.usersParentData.idUser = this.userData.idUser;
                    }, (error) => {
                      console.log(error);

                      this.loading = false;

                      // tslint:disable-next-line: max-line-length
                      this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.UserData);

                      // tslint:disable-next-line: max-line-length
                      //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario padre - ERROR: ${error}`, 'ERROR');
                    });
  }

  private obtenerDatosHijos() {}

  private cargarDatosFormularioDatosHijo() {
    this.idSonSelected = this.sonData.idUser;

    this.formularioDatosHijo.controls.pictureUser.setValue(this.sonData.pictureUser);
    this.formularioDatosHijo.controls.firstName.setValue(this.sonData.firstName);
    this.formularioDatosHijo.controls.surname.setValue(this.sonData.surname);
    this.formularioDatosHijo.controls.mail.setValue(this.sonData.mail);
    this.formularioDatosHijo.controls.birthdate.setValue(this.sonData.birthdate);
    this.formularioDatosHijo.controls.mobile.setValue(this.sonData.mobile);

    this.sonValidated = this.sonData.sonValidated;
  }

  public mostrarDatosHijo(idHijo) {

    this.verDetalleHijo = true;

    // Y cargo los datos
    if (idHijo !== undefined) {
      this.sonData = this.sonsData.find(x => x.idUser === idHijo);

      this.cargarDatosFormularioDatosHijo();
    }
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }
}
