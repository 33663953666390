import { Component, ElementRef, ViewChild, Input } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    standalone: false
})
export class ModalComponent {

  @Input() titulo: string;
  @ViewChild('content') modal: ElementRef;

  public modalClose: NgbActiveModal;

  constructor(public modalService: NgbModal) { }

  open(properties) {
    this.modalClose = this.modalService.open(this.modal, properties);
  }

  close() {
    this.modalClose.close();
  }
}
