<nb-card class="no-edge-card mb-0 background_themecolor modal-height-100-62" (click)="closeFiltersPanel()">
	<nb-card-header class="px-3 py-0 flex flex-column border-bottom-0">
		<!-- Titulo de la pantalla -->
		<div class="row p-4 background_white">
			<div class="col-12 col-md-7 col-lg-8 modal-title px-2">
				<h4>{{'CURSOS.TITLE' | translate}}</h4>
			</div>
		</div>
		<!-- Filtros -->
		<div class="row px-3 py-2 mt-3">
			<div class="col-12 col-xl-9">
				<!-- ====Version movil==== -->
				<div *ngIf="isMobile">
					<!-- Botones que agrupan las diferentes opciones -->
					<div class="d-flex align-items-center justify-content-between gap-4">
						<!-- Boton que agrupa los filtros -->
						<button nbButton *ngIf="!viewList" class="btn-themecolor" size="small" (click)="toggleFilters()">
							{{ buttonText}}
						</button>
						<!-- Boton para abrir el listado de proyectos cuando estamos en el modo lista -->
						<nb-action *ngIf="viewList" icon="menu-outline" nbTooltip="{{ 'INFORMES.EXPANDIRVISTA' | translate }}"
							nbTooltipPlacement="top" (click)="toggleMenu()">
						</nb-action>
						<!-- Boton para cambiar al modo grilla -->
						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)">
						</nb-action>
						<!-- Boton para cambiar al modo lista -->
						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)">
						</nb-action>
						<!-- Boton para crear un nuevo proyecto -->
						<nb-action class="icon-option icon-proyecto-muchos-grafos position-relative pointer"
							nbTooltip="{{ 'CURSOS.NUEVOCURSO' | translate }}" nbTooltipPlacement="top" *ngIf="loginService.esAutor()"
							(click)="nuevoCurso()">
							<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
						</nb-action>
					</div>
					<!-- Listado de proyectos y filtros en modo lista -->
					<div *ngIf="viewList" class="left-files" [ngClass]="{'show-files': isMenuOpen}">
						<div class="flex flex-column gap-4">
							<div class="closeModal">
								<nb-icon class="m-1 pointer" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
									(click)="toggleMenu()" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
								</nb-icon>
							</div>

							<div class="d-flex flex-column gap-3 pr-5">
								<!-- Boton para abrir y cerrar los filtros -->
								<div>
									<button nbButton class="btn-themecolor" size="small" (click)="toggleFilters()">
										{{ buttonText}}
									</button>
								</div>
								<!-- Buscador -->
								<nb-form-field [formGroup]="formulario" class="w-100">
									<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
										placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
									<button nbSuffix nbButton ghost size="small" class="pointer">
										<nb-icon *ngIf="!formulario.get('filtrado')?.value" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
										<nb-icon *ngIf="formulario.get('filtrado')?.value" (click)="clearSearch()" icon="close-outline"></nb-icon>
									</button>
								</nb-form-field>
							</div>
							<!-- Titulo e infografia -->
							<h5>
								{{ 'INFORMES.EXPANDIRVISTA' | translate }}
								<nb-icon icon="info-outline" class="pointer m-1" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
									nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
								</nb-icon>
							</h5>
							<!-- panel que contiene los filtros -->
							<div class="filtros" [ngClass]="{ 'show-filters': showElement }">
								<div class="item-filtros">
									<!-- Select para ordenar los proyectos -->
									<div class="d-flex w-100">
										<nb-select name="lang1" placeholder="small" class="SelectForm" hero size="small" style="width: 100%; max-width: 100%;"
											[(selected)]="selectedOrder" (selectedChange)="changeOrder($event)">
											<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">
												{{order.nombre }}
											</nb-option>
										</nb-select>
									</div>
									<div class="d-flex">
										<nb-actions size="small">
											<!-- Historial de proyectos -->
											<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
												nbTooltipPlacement="top" (click)="historyFilter()">
											</nb-action>
											<!-- Icono que abre los filtros -->
											<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate"
												nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
												(click)="openFiltersPanel()">
											</nb-action>
											<!-- panel que contiene los filtros -->
											<ng-template #filtersTemplate>
												<nb-card class="m-0 background_themecolor filtersTemplate">
													<nb-card-header class="border-bottom-0">
														<p>{{'FILTER.FILTER' | translate}}</p>
														<div class="d-flex justify-content-center">
															<button nbButton size="small" class="btn-themecolor" type="button"
																(click)="clearFilters();
																	clearFiltersList(4);
																	clearFilters();">
																<span>{{'FILTER.CLEARFILTER' | translate}}</span>
															</button>
														</div>
													</nb-card-header>
													<nb-card-body class="container-filters">

														<div class="column-filters">
															<!-- Tipo de proyecto -->
															<nb-select #selectProjectType name="idProjectsTypes" class="SelectForm" hero size="small"
																placeholder="{{'FILTER.PROJECTTYPE' | translate}}" [(selected)]="selectedProjectType"
																(selectedChange)="applyFilters($event,0)" *ngIf="filtroInicial == 'explorar' || filtroInicial == 'practicar' || loginService.esAutor()">
																<nb-option class="SelectFormOpt" *ngFor="let projectType of projectTypeList"
																	[value]="projectType.id">{{ projectType.descripcion }}
																</nb-option>
															</nb-select>
															<!-- Idioma -->
															<nb-select #selectLang name="lang1" class="SelectForm" hero size="small"
																placeholder="{{'FILTER.LANGUAGE' | translate}}" [(selected)]="selectedLang"
																(selectedChange)="applyFilters($event,1)">
																<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">
																	{{lang.nombre }}
																</nb-option>
															</nb-select>
															<!-- Asignatura -->
															<nb-select #selectSubject name="subject" class="SelectForm" hero size="small"
																placeholder="{{'FILTER.SUBJECT' | translate}}" [(selected)]="selectedSubject"
																(selectedChange)="applyFilters($event,2)">
																<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
																	[value]="subject.idSubject">{{ subject.subject }}</nb-option>
															</nb-select>
															<!-- Pais -->
															<nb-select #selectCountry name="idCountry" class="SelectForm" hero size="small"
																[(selected)]="selectedCountry" placeholder="{{'FORM.SPAIN' | translate}}"
																(selectedChange)="searchLevels($event);applyFilters($event,3);">
																<nb-option class="SelectFormOpt" *ngFor="let country of countriesList"
																	value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
															</nb-select>
															<!-- Nivel escolar -->
															<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
																[(selected)]="selectedLevelCountry"
																[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
																[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
																<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
																	[value]="level.idCountryLevel">
																	{{ level.countryLevel }}
																</nb-option>
																<nb-option class="SelectFormOpt" *ngIf="notCountryLevelsForThisCountry">
																	{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' | translate}}
																</nb-option>
															</nb-select>
															<!-- Proyectos transversales -->
															<nb-toggle labelPosition="end" class="item-profile" id="mycourses" [(ngModel)]="trasversalProjects"
																(change)="applyFilters($event, 5)">
																{{'CURSOS.TRANSVERSAL' | translate}}
															</nb-toggle>
														</div>
													</nb-card-body>
												</nb-card>
											</ng-template>
										</nb-actions>
									</div>
									<!-- Opcion para mostrar solo los proyectos creados por el estudiante -->
									<div class="d-flex flex-column gap-2 align-items-center" *ngIf="loginService.esEstudiante()">
										<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses"
											(change)="changeMyCourses($event)">
										</nb-toggle>
										<span class="text-center label-header">
											{{'CURSOS.MYCOURSES' | translate}}
										</span>
									</div>
									<!-- Opciones para mostrar los proyectos del grupo -->
									<div class="d-flex flex-column gap-2 align-items-center"
										*ngIf="(loginService.esEstudiante() || loginService.esProfesor()) && infoGrupo != null && filtroInicial != 'pensarCreando(ver)' && filtroInicial != 'pensarCreando'">
										<nb-toggle class="item-profile" id="coursesInGroup" (change)="changeCoursesInGroup($event)"
											[checked]="loginService.esEstudiante() && this.filtroInicial == 'estudiar'">
										</nb-toggle>
										<span class="text-center label-header">
											{{'MENUACTIONS.STUDENTTITLE2b' | translate}}
										</span>
									</div>
									<!-- Opcion para mostrar el listado de ayudas -->
									<div class="d-flex flex-column gap-2 align-items-center" *ngIf="viewList">
										<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
											(change)="helpListToggle(activateHelpList.value)">
										</nb-toggle>
										<span class="text-center label-header">
											{{ 'SOS.HELPLIST' | translate}}
										</span>
									</div>
								</div>
							</div>
							<!-- Listado de proyectos -->
							<app-mat-tree-cursos [isHelpList]="activateHelpList.value" [idCourse]="idCourse"
								[treeCourses]="treeCourses" [myCourses]="myCourses" [coursesInGroup]="coursesInGroup" (clickElement)="clickElement($event)"
								(createNewElement)="createNewElement($event)">
							</app-mat-tree-cursos>
						</div>
					</div>
					<!-- Filtros en el modo grilla -->
					<div *ngIf="!viewList" class="filtros" [ngClass]="{ 'show-filters': showElement }">
						<div class="item-filtros">
							<!-- Buscador -->
							<nb-form-field [formGroup]="formulario" class="w-100">
								<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
									placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
								<button nbSuffix nbButton ghost size="small" class="pointer">
									<nb-icon *ngIf="!formulario.get('filtrado')?.value" class="pointer" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
									<nb-icon *ngIf="formulario.get('filtrado')?.value" (click)="clearSearch()" icon="close-outline"></nb-icon>
								</button>
							</nb-form-field>
							<!-- Select para ordenar los proyectos -->
							<div class="d-flex w-100">
								<nb-select name="lang1" placeholder="small" class="SelectForm" hero size="small" style="width: 100%; max-width: 100%;"
									[(selected)]="selectedOrder" (selectedChange)="changeOrder($event)">
									<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">
										{{order.nombre }}
									</nb-option>
								</nb-select>
							</div>
							<div class="d-flex">
								<nb-actions size="small">
									<!-- Historial de proyectos -->
									<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
										nbTooltipPlacement="top" (click)="historyFilter()">
									</nb-action>
									<!-- Icono que abre los filtros -->
									<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate"
										nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
										(click)="openFiltersPanel()">
									</nb-action>
									<!-- panel que contiene los filtros -->
									<ng-template #filtersTemplate>
										<nb-card class="m-0 background_themecolor filtersTemplate">
											<nb-card-header class="border-bottom-0">
												<p>{{'FILTER.FILTER' | translate}}</p>
												<div class="d-flex justify-content-center">
													<button nbButton size="small" class="btn-themecolor" type="button"
														(click)="clearFilters();
															clearFiltersList(4);
															clearFilters();">
														<span>{{'FILTER.CLEARFILTER' | translate}}</span>
													</button>
												</div>
											</nb-card-header>
											<nb-card-body class="container-filters">

												<div class="column-filters">
													<!-- Tipo de proyecto -->
													<nb-select #selectProjectType name="idProjectsTypes" class="SelectForm" hero size="small"
														placeholder="{{'FILTER.PROJECTTYPE' | translate}}" [(selected)]="selectedProjectType"
														(selectedChange)="applyFilters($event,0)" *ngIf="filtroInicial == 'explorar' || filtroInicial == 'practicar' || loginService.esAutor()">
														<nb-option class="SelectFormOpt" *ngFor="let projectType of projectTypeList"
															[value]="projectType.id">{{ projectType.descripcion }}
														</nb-option>
													</nb-select>
													<!-- Idioma -->
													<nb-select #selectLang name="lang1" class="SelectForm" hero size="small"
														placeholder="{{'FILTER.LANGUAGE' | translate}}" [(selected)]="selectedLang"
														(selectedChange)="applyFilters($event,1)">
														<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">
															{{lang.nombre }}
														</nb-option>
													</nb-select>
													<!-- Asignatura -->
													<nb-select #selectSubject name="subject" class="SelectForm" hero size="small"
														placeholder="{{'FILTER.SUBJECT' | translate}}" [(selected)]="selectedSubject"
														(selectedChange)="applyFilters($event,2)">
														<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
															[value]="subject.idSubject">{{ subject.subject }}</nb-option>
													</nb-select>
													<!-- Pais -->
													<nb-select #selectCountry name="idCountry" class="SelectForm" hero size="small"
														[(selected)]="selectedCountry" placeholder="{{'FORM.SPAIN' | translate}}"
														(selectedChange)="searchLevels($event);applyFilters($event,3);">
														<nb-option class="SelectFormOpt" *ngFor="let country of countriesList"
															value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
													</nb-select>
													<!-- Nivel escolar -->
													<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
														[(selected)]="selectedLevelCountry"
														[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
														[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
														<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
															[value]="level.idCountryLevel">
															{{ level.countryLevel }}
														</nb-option>
														<nb-option class="SelectFormOpt" *ngIf="notCountryLevelsForThisCountry">
															{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' | translate}}
														</nb-option>
													</nb-select>
													<!-- Proyectos transversales -->
													<nb-toggle labelPosition="end" class="item-profile" id="mycourses" [(ngModel)]="trasversalProjects"
														(change)="applyFilters($event, 5)">
														{{'CURSOS.TRANSVERSAL' | translate}}
													</nb-toggle>
												</div>
											</nb-card-body>
										</nb-card>
									</ng-template>
								</nb-actions>
							</div>
							<!-- Opcion para mostrar solo los proyectos creados por el estudiante -->
							<div class="d-flex flex-column gap-2 align-items-center" *ngIf="loginService.esEstudiante()">
								<nb-toggle class="item-profile" id="mycourses" [formControl]="isMyCourses"
									(change)="changeMyCourses($event)">
								</nb-toggle>
								<span class="text-center label-header">
									{{'CURSOS.MYCOURSES' | translate}}
								</span>
							</div>
							<!-- Opciones para mostrar los proyectos del grupo -->
							<div class="d-flex flex-column gap-2 align-items-center"
								*ngIf="(loginService.esEstudiante() || loginService.esProfesor()) && infoGrupo != null && filtroInicial != 'pensarCreando(ver)' && filtroInicial != 'pensarCreando'">
								<nb-toggle class="item-profile" id="coursesInGroup" (change)="changeCoursesInGroup($event)"
									[checked]="loginService.esEstudiante() && this.filtroInicial == 'estudiar'">
								</nb-toggle>
								<span class="text-center label-header">
									{{'MENUACTIONS.STUDENTTITLE2b' | translate}}
								</span>
							</div>
							<!-- Opcion para mostrar el listado de ayudas -->
							<div class="d-flex flex-column gap-2 align-items-center" *ngIf="viewList">
								<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
									(change)="helpListToggle(activateHelpList.value)">
								</nb-toggle>
								<span class="text-center label-header">
									{{ 'SOS.HELPLIST' | translate}}
								</span>
							</div>
						</div>
					</div>
				</div>
				<!-- ====Version desktop==== -->
				<div *ngIf="!isMobile;" class="d-flex align-items-center gap-3">
					<!-- tipos de proyecto -->
					<div class="d-flex gap-4 w-100" *ngIf="filtroInicial == 'explorar' || filtroInicial == 'practicar' || loginService.esAutor()">
						<div *ngFor="let projectType of projectTypeList; let i= index" class="d-flex align-items-center flex-column gap-1 filter-buttons"
							nbTooltip="{{projectType.descripcion}}" nbTooltipPlacement="top">
							<div>
								<button (click)="applyFilters(i, 0)" nbButton status="basic" size="tiny" shape="rectangle" class="d-flex align-items-center">
									<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == null"></mat-icon>
									<mat-icon svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 1"></mat-icon>
									<mat-icon class="custom-icon_green" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 2"></mat-icon>
									<mat-icon class="custom-icon_red" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 3"></mat-icon>
									<mat-icon class="custom-icon_yellow" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 4"></mat-icon>
									<mat-icon class="custom-icon_violet" svgIcon="ProyectoComoMuchosGrafos" *ngIf="projectType.id == 5"></mat-icon>
								</button>
							</div>
							<label class="label-header m-0">
								{{projectType.descripcion}}
							</label>
						</div>
					</div>

					<!-- Select para ordenar los proyectos -->
					<div class="d-flex flex-column align-items-center">
						<nb-select name="lang1" class="SelectForm" [(selected)]="selectedOrder" placeholder="small" hero
							size="small" (selectedChange)="changeOrder($event)">
							<nb-option class="SelectFormOpt" *ngFor="let order of orderList" [value]="order.idOrder">
								{{ order.nombre}}
							</nb-option>
						</nb-select>
					</div>
					<!-- Opcion para mostrar solo los proyectos creados por el estudiante -->
					<div class="d-flex gap-2 flex-column align-items-center" *ngIf="loginService.esEstudiante()">
						<nb-toggle class="item-profile" id="mycourses"
							(change)="changeMyCourses($event)">
						</nb-toggle>
						<span class="text-center label-header">{{'CURSOS.MYCOURSES' | translate}}</span>
					</div>
					<!-- Opciones para mostrar los proyectos del grupo -->
					<div class="d-flex gap-2 flex-column align-items-center"
						*ngIf="(loginService.esEstudiante() || loginService.esProfesor()) && infoGrupo != null && filtroInicial != 'pensarCreando(ver)' && filtroInicial != 'pensarCreando'">
						<nb-toggle class="item-profile" id="coursesInGroup" (change)="changeCoursesInGroup($event)"
							[checked]="loginService.esEstudiante() && this.filtroInicial == 'estudiar'">
						</nb-toggle>
						<span class="text-center label-header">
							{{'MENUACTIONS.STUDENTTITLE2b' | translate}}
						</span>
					</div>
					<!-- Opcion para mostrar el listado de ayudas -->
					<div style="gap: 0.5rem;" class="d-flex flex-column align-items-center" *ngIf="viewList">
						<nb-toggle class="item-profile" id="activateHelpList" [formControl]="activateHelpList"
							(change)="helpListToggle(activateHelpList.value)">
						</nb-toggle>
						<span class="text-center label-header">
							{{ 'SOS.HELPLIST' | translate}}
						</span>
					</div>

					<nb-actions size="small">
						<!-- Historial de proyectos -->
						<nb-action icon="clock-outline" [ngClass]="{'selected-icons': historyFilterActive}" nbTooltip="Historial"
							nbTooltipPlacement="top" (click)="historyFilter()">
						</nb-action>
						<!-- Icono que abre los filtros -->
						<nb-action icon="funnel-outline" [ngClass]="{'selected-icons': iconSelected}" [nbPopover]="filtersTemplate"
							nbPopoverPlacement="bottom" nbTooltip="Filtros" nbTooltipPlacement="top" nbPopoverTrigger="noop"
							(click)="openFiltersPanel()">
						</nb-action>
						<!-- panel que contiene los filtros -->
						<ng-template #filtersTemplate>
							<nb-card class="m-0 background_themecolor filtersTemplate">
								<nb-card-header class="border-bottom-0">
									<p>{{'FILTER.FILTER' | translate}}</p>
									<div class="d-flex justify-content-center">
										<button nbButton size="small" class="btn-themecolor" type="button"
											(click)="clearFilters();
												clearFiltersList(4);
												clearFilters();">
											<span>{{'FILTER.CLEARFILTER' | translate}}</span>
										</button>
									</div>
								</nb-card-header>
								<nb-card-body class="container-filters">
									<div class="column-filters">
										<!-- Idioma -->
										<nb-select #selectLang name="lang1" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.LANGUAGE' | translate}}" [(selected)]="selectedLang"
											(selectedChange)="applyFilters($event,1)">
											<nb-option class="SelectFormOpt" *ngFor="let lang of langList" [value]="lang.idLang">
												{{lang.nombre }}
											</nb-option>
										</nb-select>
										<!-- Asignatura -->
										<nb-select #selectSubject name="subject" class="SelectForm" hero size="small"
											placeholder="{{'FILTER.SUBJECT' | translate}}" [(selected)]="selectedSubject"
											(selectedChange)="applyFilters($event,2)">
											<nb-option class="SelectFormOpt" *ngFor="let subject of subjectsList"
												[value]="subject.idSubject">{{ subject.subject }}</nb-option>
										</nb-select>
										<!-- Pais -->
										<nb-select #selectCountry name="idCountry" class="SelectForm" hero size="small"
											[(selected)]="selectedCountry" placeholder="{{'FORM.SPAIN' | translate}}"
											(selectedChange)="searchLevels($event);applyFilters($event,3);">
											<nb-option class="SelectFormOpt" *ngFor="let country of countriesList"
												value="{{ country.idCountry }}">{{ country.country_es }}</nb-option>
										</nb-select>
										<!-- Nivel escolar -->
										<nb-select name="idCountryLevel" class="SelectForm" hero size="small"
											[(selected)]="selectedLevelCountry"
											[placeholder]="(!filterCountrySelected ? 'FILTER.SELECTED_COUNTRY' : 'FILTER.LEVEL') | translate"
											[disabled]="!filterCountrySelected" (selectedChange)="applyFilters($event,4)">
											<nb-option class="SelectFormOpt" *ngFor="let level of levelsOfCountry"
												[value]="level.idCountryLevel">
												{{ level.countryLevel }}
											</nb-option>
											<nb-option class="SelectFormOpt" *ngIf="notCountryLevelsForThisCountry">
												{{'MODALINFORMATIONCOURSE.NOTLEVELSFORCOUNTRY' | translate}}
											</nb-option>
										</nb-select>
										<!-- Proyectos transversales -->
										<nb-toggle labelPosition="end" class="item-profile" id="mycourses" [(ngModel)]="trasversalProjects"
											(change)="applyFilters($event, 5)">
											{{'CURSOS.TRANSVERSAL' | translate}}
										</nb-toggle>
									</div>
								</nb-card-body>
							</nb-card>
						</ng-template>
						<!-- Boton para cambiar al modo grilla -->
						<nb-action *ngIf="viewList" icon="grid-outline" nbTooltip="{{ 'CURSOS.VERMOSAICO' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(false)">
						</nb-action>
						<!-- Boton para cambiar al modo lista -->
						<nb-action *ngIf="!viewList" icon="list-outline" nbTooltip="{{ 'CURSOS.VERARBOL' | translate }}"
							nbTooltipPlacement="top" (click)="changeView(true)">
						</nb-action>
						<!-- Boton para crear un nuevo proyecto -->
						<nb-action class="icon-option icon-proyecto-muchos-grafos position-relative pointer"
							nbTooltip="{{ 'CURSOS.NUEVOCURSO' | translate }}" nbTooltipPlacement="top" *ngIf="loginService.esAutor()"
							(click)="nuevoCurso()">
							<nb-icon class="plus-editor" icon="plus-outline"></nb-icon>
						</nb-action>
					</nb-actions>
				</div>
			</div>
			<!-- Buscador -->
			<div *ngIf="!isMobile" class="col-12 col-xl-3 d-flex align-items-center">
				<nb-form-field [formGroup]="formulario" class="w-100">
					<input type="search" nbInput fullWidth fieldSize="small" class="form-control"
						placeholder="{{ 'CURSOS.SEARCH' | translate }}" formControlName="filtrado">
					<button nbSuffix nbButton ghost size="small" class="pointer">
						<nb-icon *ngIf="!formulario.get('filtrado')?.value" icon="search" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
						<nb-icon *ngIf="formulario.get('filtrado')?.value" (click)="clearSearch()" icon="close-outline"></nb-icon>
					</button>
				</nb-form-field>
			</div>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- VISTA MODO GRILLA -->
		<div *ngIf="!viewList" class="row background_white d-flex justify-content-center py-4 px-2 min-h-100">
			<div class="col-12">
				<app-loading [isVisible]="cargando"></app-loading>
				<div *ngIf="cursos.length === 0">
					<nb-card class="no-edge-card">
						<nb-card-body>
							<h4 class="text-center">{{ 'CURSOS.FILTERNOTMATCH' | translate }}</h4>
						</nb-card-body>
					</nb-card>
				</div>
				<div *ngIf="cursos.length !== 0" class="d-flex flex-column gap-5">
					<div class="d-flex flex-wrap gap-5 justify-content-center" *ngIf="!viewList">
						<div *ngFor="let curso of cursos | paginate: { itemsPerPage: 16, currentPage: page }" class="card-projects">
							<div [ngClass]="{'nb-card-dark-overlay' : curso.inGroup == 0 && loginService.esProfesor()}">
								<nb-card (click)="verDetalleCurso(curso)" class="pointer mb-0"
								[ngClass]="{'project-info-1': curso.project_type == 1, 'project-info-2': curso.project_type == 2, 'project-info-3': curso.project_type == 3, 'project-info-4': curso.project_type == 4, 'project-info-5': curso.project_type == 5}">
									<nb-card-header class="p-0 border-bottom-0" nbTooltip="{{curso.courseTittle}}"
										nbTooltipPlacement="top">
										<!--ocultamos la imagen si la conexion es lenta-->
										<div class="img-container" nbTooltip="{{curso.description}}" nbTooltipPlacement="top">
											<img [src]="curso.cpicture | imagen:'cursos'" class="img-fluid" (error)="updateUrl($event)"
												*ngIf="networkSpeed >= 1">
											<img [src]="'../../assets/images/no-image.png'" class="img-fluid" *ngIf="networkSpeed < 1">
										</div>
									</nb-card-header>
									<nb-card-body class="p-2 d-flex justify-content-center align-items-center"
										[ngClass]="{'project-background-1': curso.project_type == 1, 'project-background-2': curso.project_type == 2, 'project-background-3': curso.project_type == 3, 'project-background-4': curso.project_type == 4, 'project-background-5': curso.project_type == 5}">
										<p *ngIf="isStudent == true" class="courseTittle">
											{{ curso.courseTittle }} <span style="font-weight: bold;">({{ curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] }})</span>
										</p>
										<p *ngIf="isStudent == false" class="courseTittle-teacher">
											{{ curso.courseTittle }} <span style="font-weight: bold;">({{ curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] }})</span>
										</p>
									</nb-card-body>
									<nb-card-footer *ngIf="!actividadesFlash" class="border-top-0"
										[ngClass]="{'project-background-1': curso.project_type == 1, 'project-background-2': curso.project_type == 2, 'project-background-3': curso.project_type == 3, 'project-background-4': curso.project_type == 4, 'project-background-5': curso.project_type == 5}">
										<div class="d-flex justify-content-center flex-wrap pb-2 gap-1 position-relative">
											<div *ngFor="let subject of curso.courseSubjects">
												<nb-tag size="tiny" class="tag-style" [style.background]="subject.color" appearance="filled"
													[text]="subject.acronym" nbTooltip="{{subject.subject}}" nbTooltipPlacement="top">
												</nb-tag>
											</div>
											<!--
											<nb-tag size="tiny" [style.background]="'#'+curso.countryLevelObj[0][3]" appearance="filled"
												[text]="curso.countryLevelObj[0][2] ? curso.countryLevelObj[0][2] : 'Sin nivel especifico' | truncate:[15, '...'] "></nb-tag>
											<button [nbPopover]="listOfSubjects" nbPopoverPlacement="bottom"
												style="position: relative; z-index: 99999;" nbPopoverTrigger="click" size="tiny" nbButton
												status="basic" (click)="preventDetalle($event)">
												<nb-icon icon="more-horizontal-outline"></nb-icon>
											</button>
											<ng-template #listOfSubjects>
												<nb-card class="mb-0 no-edge-card background_themecolor" style="box-shadow: 0px 0px 20px #000 !important;">
													<nb-card-header class="border-bottom-0">
														{{ 'CURSOS.SUBJECTS' | translate }}
													</nb-card-header>
													<nb-card-body>
														<nb-list>
															<nb-list-item *ngFor="let subject of curso.courseSubjects" class="border-bottom-0 border-top-0">
																<nb-tag size="tiny" [style.background]="getSubjectColor(subject.subject)" appearance="filled"
																	[text]="subject.subject"></nb-tag>
															</nb-list-item>
														</nb-list>
													</nb-card-body>
												</nb-card>
											</ng-template>
											-->
										</div>
										<div class="footer d-flex justify-content-center align-items-center mr-1 ml-1" *ngIf="isStudent == true">
											<div class="averageKnowledge mr-1">{{curso.averageKnowledge }}</div>
											<div class="progress half-width">
												<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
													aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
													[style.backgroundColor]="curso.barColor" [style.width]="curso.progress+'%'"></div>
											</div>
											<div class="averageKnowledge ml-1">{{curso.progress+'%'}}</div>
										</div>
									</nb-card-footer>
								</nb-card>
							</div>
						</div>
					</div>
					<!-- Controles de paginación -->
					<div class="d-flex justify-content-center align-items-center">
						<pagination-controls (pageChange)="page = $event" previousLabel="" nextLabel=""></pagination-controls>
					</div>
				</div>
				<div class="d-flex justify-content-center mt-5">
					<nb-card class="no-edge-card mb-0">
						<nb-card-body>
							<h5 class="text-center pointer" (click)="showGuiaModal()">
								{{ 'CURSOS.NOTFOUND' | translate }}
							</h5>
						</nb-card-body>
					</nb-card>
				</div>
			</div>
		</div>
		<!-- VISTA MODO LISTA -->
		<div *ngIf="viewList" class="row min-h-100 gap-4">

			<div *ngIf="!isMobile;" class="col-12 col-xl-4 py-4 pl-4 pr-0 container-tree background_white">
				<div class="d-flex align-items-center pr-5">
					<h5>
						{{ 'INFORMES.EXPANDIRVISTA' | translate }}
						<nb-icon icon="info-outline" class="pointer m-1" nbTooltip="{{ 'CURSOS.INFOGRAPHIC' | translate }}"
							nbTooltipPlacement="top" (click)="open(dialog)" *ngIf="loginService.esEstudiante()">
						</nb-icon>
					</h5>
				</div>
				<app-mat-tree-cursos [isHelpList]="activateHelpList.value"  [idCourse]="idCourse" [filtroInicial]="filtroInicial" [treeCourses]="treeCourses"
					[myCourses]="myCourses" [coursesInGroup]="coursesInGroup" (clickElement)="clickElement($event)" (createNewElement)="createNewElement($event)">
				</app-mat-tree-cursos>
			</div>

			<div class="col py-4 pl-xl-4 pr-xl-0 container-tree background_white">
				<!-- BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->
				<div id="botones-multiples" *ngIf="!loginService.esEstudiante() && (quiz && quiz.idMultiplexQuiz === 1)"
					class="d-flex justify-content-center align-items-center flex-wrap">
					<button *ngFor="let hijo of listQM; let i = index"
						[ngClass]="{'boton-qM': true, 'boton-qM-selected': i === selectedButtonIndex}" [class.flex-grow-1]="true"
						(click)="selectButton(i, hijo.quizMultiple.idQuizMultiple)">
						{{ i + 1 }}
					</button>
				</div>
				<!-- END BOTONES PARA IR CAMBIANDO ENTRE QUIZZES MULTIPLES COMO PROFESOR -->

				<!-- <div class="row justify-content-center">
					<div class="w-100 col-sm-12 col-md-10 mb-3">

						<h5 class="text-title text-center font-weight-bold">
							{{ 'INFORMES.TABLA_DATOS_QUIZES' | translate }}
						</h5>

					</div>
				</div> -->

				<div class="w-100 height-max">
					<div *ngIf="!(node || quiz)" class="d-flex align-items-center justify-content-center h-100">
						<h6>
							{{ 'CURSOS.SELECTPROJECT' | translate }}
						</h6>
					</div>
					<!-- CONTAINER CON LAS OPCIONES SELECCIONADAS EN LA COLUMNA DE LA IZQUIERDA-->
					 <!--
					<div class="d-flex align-items-center justify-content-center pt-5" *ngIf="isLoading && !idCourse">
						  <mat-spinner [diameter]="50" ></mat-spinner>
					</div>
					-->
					<!-- VISUALIZAR NODO -->
					<div (mousemove)="showFiles(true)" (click)="showFiles(true)" *ngIf="node && !isLoading">
						<app-node-detail-files [showInContainer]="true" [showFilesContainer]="isShowFiles" [node]="node"
							[onSaveClicked$]="saveClicked$" [canEdit]="false" (fileSelected)="onFileSelected($event)"
							(onHoverElements)="showFiles($event)"></app-node-detail-files>
					</div>
					<!--FIN VISUALIZAR NODO -->
					<!-- VISUALIZAR QUIZ -->
					<div *ngIf="quiz && !isLoading">
						<!-- SIMPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 0" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 pr-xl-4 hide-scrollbars" id="container">
								<app-quiz-play-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse" [graphId]="idGraph"
									[quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true" [modeSocket]="false"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()">
								</app-quiz-play-body>
							</div>
						</div>
						<!-- END SIMPLE QUIZ -->
						<!-- MULTIPLE QUIZ -->
						<div *ngIf="quiz && quiz.idMultiplexQuiz === 1" class="quiz-container" id="quiz"
							(mousemove)="showFiles(true)" (click)="showFiles(true)">
							<div class="body-container p-3 pr-xl-4 hide-scrollbars">
								<app-quiz-play-multiple-body [disabledButton]="disabledButton" [courseId]="idSelectedCourse"
									[graphId]="idGraph" [quiz]="quiz" [elements]="elements" [answered]="answered" [modeAuto]="true"
									(result)="onViewGif($event)" (notResponseGif)="hidenGif($event)" (answeredChange)="onAnswered()"
									[listQM]="listQM">
								</app-quiz-play-multiple-body>
							</div>
						</div>
						<!-- END MULTIPLE QUIZ -->
					</div>
					<!-- FIN VISUALIZAR QUIZ -->
				</div>
			</div>

			<ng-template #dialog let-data let-ref="dialogRef">
				<nb-card class="position-relative">
					<nb-card-body>
						<div class="d-flex">
							<img src="assets/images/leyenda-ideografo.png" class="img-info">
						</div>
					</nb-card-body>
					<div class="closeModal">
						<nb-icon class="m-1" icon="close-circle" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}"
							placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="ref.close()">
						</nb-icon>
					</div>
				</nb-card>
			</ng-template>

		</div>
		<!-- FIN VISTA MODO ARBOL -->
	</nb-card-body>
</nb-card>
