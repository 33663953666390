<!-- Modal para grabar un audio -->
<nb-card *ngIf="soloAudio" class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="pr-5">
			<h5 *ngIf="!grabando">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
			<h5 *ngIf="grabando">{{'MODALAUDIOMICRO.LISTENING' | translate}}</h5>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<!-- Validacion cuando hay problemas con la camra -->
		<div class="container" *ngIf="errorDispositivos || !requiredDevicesAvailable">
			<div class="alert alert-danger" *ngIf="errorDispositivos">
				{{ errorDispositivos }}
			</div>

			<div class="alert alert-warning" *ngIf="!requiredDevicesAvailable">
				{{ 'MODALAUDIOMICRO.NOAVAILABLEMICRO' | translate }}
			</div>
		</div>
		<!-- Flujo normal para cargar un audio -->
		<div class="container" *ngIf="requiredDevicesAvailable">
			<ng-container *ngIf="!grabando && audioFiles.length == 0">
				<div class="d-flex justify-content-center py-4 w-100">
					<!-- Evento para iniciar grabacion -->
					<div class="d-flex gap-2 flex-column justify-content-center align-items-center">
						<img [src]="Recording" width="40" height="40" class="pointer" (click)="startRecording()"/>
						<span>{{ 'MODALAUDIOMICRO.PRESSTOSTART' | translate }}</span>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="grabando">
				<div class="d-flex flex-column justify-content-center w-100 gap-2">
					<!-- animacion que indica el inicio de la grabacion -->
					<!-- <div class="d-flex justify-content-center align-items-center">
						<img src="/assets/images/onda-sonora.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
					</div> -->
					<!-- minutos y segundos transcurridos de la grabacion -->
					<div class="d-flex flex-column justify-content-center align-items-center w-100">
						<div class="d-flex justify-content-center align-items-center">
							<span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span>
						</div>
						<span>{{ 'MODALAUDIOMICRO.RECORDING' | translate }}</span>
					</div>
					<!-- Evento para detener grabacion -->
					<div class="d-flex justify-content-center align-items-center">
						<img [src]="Stop" width="50px" height="50px" class="pointer" nbTooltip="Parar" (click)="stopRecording()" />
					</div>
				</div>
			</ng-container>
		</div>
		<nb-card-footer class="border-top-0" *ngIf="audioFiles.length > 0">
			<div class="container d-flex flex-column gap-5">
				<div class="d-flex justify-content-center">
					<audio *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
				</div>
				<div class="d-flex flex justify-content-center align-items-center gap-4">
					<button type="button" nbButton size="medium" class="btn-themecolor" (click)="aceptar()">
						{{'MODALAUDIOMICRO.OK' | translate}}
					</button>
					<button type="button" nbButton size="medium" class="btn-red" (click)="salir()" >
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>
		</nb-card-footer>
	</nb-card-body>
</nb-card>

<!-- Modal para grabar un video -->
<nb-card *ngIf="soloVideo" class="no-edge-card modal-max-height-100-65 mb-0">
  <nb-card-header class="border-bottom-0">
    <div class="pr-5">
			<h5 *ngIf="!grabando">{{'MODALAUDIOMICRO.STARTRECORD' | translate}}</h5>
    	<h5 *ngIf="grabando">{{'MODALAUDIOMICRO.RECORDING' | translate}}</h5>
	</div>
  </nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
  <nb-card-body>
		<!-- Validacion cuando hay problemas con la camra o el micro -->
		<div class="container" *ngIf="errorDispositivos || !requiredDevicesAvailable">
			<div class="alert alert-danger" *ngIf="errorDispositivos">
				{{ errorDispositivos }}
			</div>

			<div class="alert alert-warning" *ngIf="!requiredDevicesAvailable">
				{{'MODALAUDIOMICRO.REQUIRESCAMERA' | translate}}
			</div>
		</div>
		<!-- Flujo normal para cargar un video -->
    <div class="container" *ngIf="requiredDevicesAvailable">
			<ng-container *ngIf="videoFiles.length > 0">
				<div class="d-flex justify-content-center">
					<div class="d-flex">
						<video class="d-flex w-100" style="max-height: 400px;" *ngFor="let audio of videoFiles" controls='true' [src]="audio" muted playsinline></video>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="videoFiles.length === 0">
				<div class="d-flex justify-content-center">
					<div class="d-flex">
						<video class="d-flex w-100 container-video-before" id="videoRef" autoplay muted playsinline></video>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!grabando">
				<div class="d-flex justify-content-center py-4 w-100">
					<!-- Evento para iniciar grabacion -->
					<div *ngIf="videoFiles.length === 0" class="d-flex gap-2 flex-column justify-content-center align-items-center">
						<img [src]="Recording" width="40" height="40" class="pointer" (click)="startCamera()"/>
						<span>{{ 'MODALAUDIOMICRO.PRESSTOSTART' | translate }}</span>
					</div>
					<!-- Repetir grabacion -->
					<div *ngIf="videoFiles.length > 0" class="d-flex flex-column justify-content-center align-items-center gap-3">
						<mat-icon ngbTooltip="{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}" (click)="reloadVid()"
							class="icon-mat-type vid_on pointer mt-2" color="red" style="color: #dc3545;">restart_alt
						</mat-icon>
						{{'MODALAUDIOMICRO.REPEATRECORD' | translate}}
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="grabando">
				<div class="d-flex flex-column justify-content-center py-4 w-100 gap-3">
					<!-- animacion que indica el inicio de la grabacion -->
					<!-- <div class="d-flex justify-content-center align-items-center">
						<img src="/assets/images/onda-sonora.gif" width="50px" height="50px" class="loading-gif" alt="{{ 'LOADING.CARGANDO' | translate }}" />
					</div> -->
					<!-- minutos y segundos transcurridos de la grabacion -->
					<div class="d-flex flex-column justify-content-center align-items-center w-100">
						<div class="d-flex justify-content-center align-items-center">
							<span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span>
						</div>
						<span>{{ 'MODALAUDIOMICRO.RECORDING' | translate }}</span>
					</div>
					<!-- Evento para detener grabacion -->
					<div class="d-flex justify-content-center align-items-center">
						<img [src]="Stop" width="50px" height="50px" class="pointer" nbTooltip="Parar" (click)="stopCamera()" />
					</div>
				</div>
			</ng-container>

			<nb-card-footer *ngIf="videoFiles.length > 0" class="border-top-0">
				<div class="d-flex justify-content-center align-items-center gap-5">
					<button type="button" nbButton size="medium" class="btn-themecolor" (click)="aceptar()">
						{{'MODALAUDIOMICRO.UPLOADVIDEO' | translate}}
					</button>
					<button type="button" nbButton size="medium" class="btn-red" [disabled]="grabando" (click)="salir()" >
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</nb-card-footer>
		</div>
  </nb-card-body>
</nb-card>

<!-- modal para capturar una foto -->
<nb-card *ngIf="soloFoto" class="no-edge-card modal-max-height-100-65 mb-0">
	<nb-card-header class="border-bottom-0">
		<div class="pr-5">
			<h5>{{'MODALAUDIOMICRO.USECAMERA' | translate}}</h5>
		</div>
	</nb-card-header>

	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>

	<nb-card-body>
		<!-- Validacion cuando hay problemas con la camra -->
		<div class="container" *ngIf="errorDispositivos || !requiredDevicesAvailable">
			<div class="alert alert-danger" *ngIf="errorDispositivos">
				{{ errorDispositivos }}
			</div>

			<div class="alert alert-warning" *ngIf="!requiredDevicesAvailable">
				{{'MODALAUDIOMICRO.REQUIRESCAMERA' | translate}}
			</div>
		</div>
		<!-- Flujo normal para cargar una imagen -->
		<div class="container" *ngIf="requiredDevicesAvailable">
			<div class="d-flex flex-column gap-3" *ngIf="webcamImage">
				<div class="d-flex justify-content-center align-items-center">
					<img class="container-img" [src]="webcamImage.imageAsDataUrl" alt="Card image cap">
				</div>
				<div class="d-flex flex-wrap justify-content-center align-items-center gap-4">
					<button nbButton size="medium" class="btn-themecolor" (click)="savePicture();">
						{{'MODALAUDIOMICRO.SAVEPHOTO' | translate}}
					</button>

					<button nbButton size="medium" class="btn-red" (click)="newFoto();">
						{{'MODALAUDIOMICRO.NEWPHOTO' | translate}}
					</button>

					<!-- <button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="medium"
						class="btn-themecolor" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">
						{{'MODALAUDIOMICRO.CHANGECAMERA' | translate}}
					</button> -->

					<button type="button" nbButton size="medium" class="btn-red" [disabled]="grabando" (click)="salir()">
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>

			<div class="d-flex flex-column gap-3" *ngIf="newPicture">
				<div class="d-flex justify-content-center align-items-center">
					<webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
						*ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
						[videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
						(initError)="handleInitError($event)">
					</webcam>
				</div>
				<div class="d-flex flex-wrap justify-content-center align-items-center gap-4">
					<button [disabled]="!showWebcam" nbButton size="medium" class="btn-themecolor" (click)="triggerSnapshot();">
						{{'MODALAUDIOMICRO.TAKEPHOTO' | translate}}
					</button>

					<!-- <button *ngIf="showWebcam" nbButton size="small" (click)="toggleWebcam();">
							{{'MODALAUDIOMICRO.HIDECAMERA' | translate}}
					</button>
					<button *ngIf="!showWebcam" nbButton size="small" (click)="toggleWebcam();">
						{{'MODALAUDIOMICRO.SHOWCAMERA' | translate}}
					</button>

					<button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="small"
						(click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">
						{{'MODALAUDIOMICRO.CHANGECAMERA' | translate}}
					</button> -->

					<button type="button" nbButton size="medium" class="btn-red" [disabled]="grabando" (click)="salir()">
						{{'MODALAUDIOMICRO.Cancel' | translate}}
					</button>
				</div>
			</div>

			<!-- <nb-card-footer class="border-top-0">
				<div class="d-flex flex-column gap-3">
					<div class="d-flex justify-content-center align-items-center">
						<div class="d-flex justify-content-center align-items-center gap-5">
							<div *ngIf="mediaDevices" class="d-flex flex-column gap-3">
								<div class="d-flex flex-column">
									<label for="exampleFormControlSelect1">{{'MODALAUDIOMICRO.MEDIADEVICES' | translate}}</label>
									<select [(ngModel)]="deviceId" class="form-control" id="exampleFormControlSelect1">
										<option *ngFor="let media of mediaDevices">{{media.label}}</option>
									</select>
								</div>
								<button ngbTooltip="{{'MODALAUDIOMICRO.CHANGECAMERAINFO' | translate}}" nbButton size="small"
									class="btn-themecolor" (click)="showNextWebcam(deviceId);">
									{{'MODALAUDIOMICRO.ACTIVE' | translate}}
								</button>
							</div>
						</div>
					</div>

					<div class="d-flex justify-content-center align-content-center">
						<input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch">
						<label for="cameraSwitchCheckbox">{{'MODALAUDIOMICRO.ALLOWCAMERASWITCH' | translate}}</label>
					</div>

					<div class="d-flex flex-column">
						<h4 *ngIf="errors.length > 0">{{'MODALAUDIOMICRO.MESSAGES' | translate}}</h4>
						<ul *ngFor="let error of errors">
							<li>{{error | json}}</li>
						</ul>
					</div>
				</div>
			</nb-card-footer> -->
		</div>
	</nb-card-body>
</nb-card>
