import { ACTIONS } from 'src/app/core/utils/actions';
import { LoginService } from './../../../core/services/login/login.service';
import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
	ChangeDetectorRef,
} from "@angular/core";
import { ActionModel } from 'src/app/core/models/shared/actions.model';
import { PowerService } from 'src/app/core/services/power/power.service';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { PointNodeService } from 'src/app/core/services/point-node/point-node.service';
import { Subscription, take } from 'rxjs';
import { ObjetoNodoAuto } from 'src/app/core/models/graph/gNode.model';
import { SigmaCanvasService } from '../nodes/sigma-canvas/sigma-canvas.service';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { SigmaUtils } from 'src/app/core/utils/sigma-utils';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { LocalStorage } from 'src/app/core/utils';

const MAXVALUE: number = 3
const MINVALUE: number = -3

@Component({
    selector: "app-pad-controller",
    templateUrl: "./pad-controller.component.html",
    styleUrls: ["./pad-controller.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PadControllerComponent implements OnInit, OnDestroy {
	hasDownPowers: boolean;
	hasUpPowers: boolean;
	discoverMode: boolean;
	private sigmaUtils: SigmaUtils;
	@HostListener("window:keydown", ["$event"]) onKeydownHandler(
		event: KeyboardEvent
	) {
		if (!this.isModalOpen) this.getCurrentKeyboardKey(event.key);
	}

	@Output() action = new EventEmitter<string>();
	@Input() isShow: boolean;
	@Input() isShowLabels: boolean = false;
	@Input() canEdit: boolean;
	@Input() NodeViewPads: number;
	@Input() listNodeAuto: ObjetoNodoAuto;
	@Input() isModalOpen: boolean = false;
	@Input() upAndDownOff: boolean = false;
	@Input() nodeView: boolean = false;
	@Input() nextEnabled: boolean = true;
	@Input() backEnabled: boolean = true;
	@Input() idCurso: number;
	@Input() idMapa: number;
	@Input() node; // :gnode
	@Output() nodeViewStatus = new EventEmitter<boolean>();
	public showPowerSelector: boolean = false;
	public arrayValues: number[] = [];
	private _totalSup: number = 0;
	private _totalInf: number = 0;
	isEnablePowerUp: boolean = false;
	isEnablePowerDown: boolean = false;
	nodos: any;
	flickNode: number;
	indexNode: number;
	nowIndex: number = 0;
	discoverIndex: number = 1;
	finishIndex: number;
	showDiscoverButton: boolean = false;
	discoverModeOn: boolean = false;
	private subs = new Subscription();

	constructor(
		private powerService: PowerService,
		private pointNodeService: PointNodeService,
		private toolsService: SigmaToolbarsService,
		private loginService: LoginService,
		private sigmaCanvasService: SigmaCanvasService,
		private padsUtils: PadsUtils,
		private ref: ChangeDetectorRef,
		private graphServ: GraphService,
		private localStorage: LocalStorage,
	) {
		this.subs = this.toolsService.hideElements.subscribe((val) => {
			this.showPowerSelector = false;
		});
	}

	ngOnInit() {
		//Si el usuario es autor, marcadas todas las potencias
		if (this.loginService.esAutor()) {
			this.arrayValues.push(-3, -2, -1, 0, 1, 2, 3);
			this.powerService.emitPowerActive(this.arrayValues);
		} else {
			this.arrayValues.push(this.powerService.actualLevel);
			let valMax: number = Math.max.apply(Math, this.arrayValues);
			this._updateMoreLessValue(valMax, MAXVALUE, 0);
		}
		this._getCurrentPowers();
		//para activar y desactivar los botones laterales
		//this.sigmaCanvasService.getBackEnabled();
		if (this.node != undefined) {
			this.idCurso = this.node.idCourseCreation;
			this.idMapa = this.node.idTargetCreation;
		}

		if (this.idCurso !== undefined && this.idMapa !== undefined) {
			this.graphServ
				.getFlickNode(this.idCurso, this.idMapa)
				.subscribe((res) => {
					this.flickNode = res.data.idNode;

					this.graphServ
						.getNodesFromCourseGraph(this.idCurso, this.idMapa)
						.subscribe((res) => {
							this.nodos = res.nodes;
							this.nodos.sort((a, b) => a.ordinalPower0 - b.ordinalPower0); //lo ordenamos por ordinal
							//le doy valor al total de los nodos
							this.finishIndex = this.nodos.length - 1;
							this.nowIndex =
								this.node === undefined
									? this.nodos.findIndex(
											(node) => node.idOriginal === this.flickNode
									  )
									: this.nodos.findIndex(
											(node) => node.idOriginal === this.node.idOriginal
									  );
							//esto pasa cuando solo hay un nodo o no hay
							if (this.nowIndex < 0) {
								this.nowIndex = 0;
							}

							//ocultamos la flecha de atras si no hay o la de adelante o ninguna
							if (this.nowIndex === 0) {
								this.backEnabled = false;
							}
							// Verificar si this.nodos.flickNode es igual al idOriginal del último nodo en el array
							if (this.nowIndex === this.finishIndex) {
								this.nextEnabled = false;
							}
						});
				});
		}

		this.sigmaCanvasService.discoverModeOn.subscribe(
			(discoverMode: boolean) => {
				this.discoverMode = discoverMode;
				this.hasUpPowers = !discoverMode;
				this.hasDownPowers = !discoverMode;
			}
		);
		//para que al navegar por los nodos, el index se actualice
		this.pointNodeService.nowIndex$.subscribe(value => {
			this.nowIndex = value;
		});
	}

	discoverModeSwitch() {
		let auxDiscoverMode = !this.discoverModeOn;
		this.discoverModeOn = auxDiscoverMode;
		this.localStorage.setItem("discoverMode", auxDiscoverMode);
		this.sigmaCanvasService.setDiscoverModeOn(auxDiscoverMode);
		if (auxDiscoverMode == true) {
			this.backEnabled = false;
			this.nowIndex = 0;
			this.sigmaCanvasService.activeOrdinals = [1];
			const nextBackNode = { avanzar: false, regresar: true, initialDiscover: true };
			for (var i = 1; i < 100; i++){
				this.pointNodeService.emitNextPointNodeActive(nextBackNode);
			}
			//this.logigOrdinal(false);
		} else{
			//ocultamos la flecha de atras si no hay o la de adelante o ninguna
			if (this.nowIndex === 0) {
				this.backEnabled = false;
			} else {
				this.backEnabled = true;
			}
			// Verificar si this.nodos.flickNode es igual al idOriginal del último nodo en el array
			if (this.nowIndex === this.finishIndex) {
				this.nextEnabled = false;
			} else{
				this.nextEnabled = true;
			}
		}
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.sigmaCanvasService._showDiscoverButton.subscribe((res) => {
			this.showDiscoverButton = res;
			this.ref.detectChanges();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		this.comparePowersToDetermineShowVariables();
	}

	comparePowersToDetermineShowVariables() {
		let actualLevel = this.arrayValues[0];

		if (this.listNodeAuto !== undefined) {
			this.hasDownPowers =
				this.listNodeAuto.explicacionesPowerMinus1.length > 0 ||
				this.listNodeAuto.explicacionesPowerMinus2.length > 0 ||
				this.listNodeAuto.explicacionesPowerMinus3.length > 0 ||
				this.arrayValues[0] > 0;

			this.hasUpPowers =
				this.listNodeAuto.explicacionesPower1.length > 0 ||
				this.listNodeAuto.explicacionesPower2.length > 0 ||
				this.listNodeAuto.explicacionesPower3.length > 0 ||
				this.arrayValues[0] < 0;

			if (
				actualLevel == -1 &&
				this.listNodeAuto.explicacionesPowerMinus2.length == 0 &&
				this.listNodeAuto.explicacionesPowerMinus3.length == 0
			) {
				this.hasDownPowers = false;
			}

			if (
				actualLevel == -2 &&
				this.listNodeAuto.explicacionesPowerMinus3.length == 0
			) {
				this.hasDownPowers = false;
			}

			if (
				actualLevel == 1 &&
				this.listNodeAuto.explicacionesPower2.length == 0 &&
				this.listNodeAuto.explicacionesPower3.length == 0
			) {
				this.hasUpPowers = false;
			}

			if (
				actualLevel == 2 &&
				this.listNodeAuto.explicacionesPower3.length == 0
			) {
				this.hasUpPowers = false;
			}

			if (this.arrayValues[0] == -3) {
				this.hasDownPowers = false;
			}

			if (this.arrayValues[0] == 3) {
				this.hasUpPowers = false;
			}
		}
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}

	public get maxPower(): number {
		return Math.max.apply(Math, this.arrayValues);
	}

	/** LATERAL BUTTONS */

	/** POWER RIGHT PAD */

	addPower() {
		let valMax: number = Math.max.apply(Math, this.arrayValues);

		if (this.arrayValues.length === 7) valMax -= 1;

		this._updateMoreLessValue(valMax, MAXVALUE, 1);
		this._vibratePad();
		this.comparePowersToDetermineShowVariables();
	}
	removePower() {
		let valMin: number = Math.min.apply(Math, this.arrayValues);
		if (this.arrayValues.length === 7) valMin += 1;
		this._updateMoreLessValue(valMin, MINVALUE, -1);
		this._vibratePad();
		this.comparePowersToDetermineShowVariables();
	}

	private _updateMoreLessValue(
		value: number,
		limitValue: number,
		nextValue: number
	): void {
		let currentValue: number = value;

		if (this.arrayValues.length === 0) {
			this.arrayValues.push(0);
		} else if (this.arrayValues.length === 1) {
			if (value !== limitValue) {
				currentValue += nextValue;
				this.arrayValues.pop();
				this.arrayValues.push(currentValue);
			}
		} else {
			if (value !== limitValue) {
				currentValue += nextValue;

				this.arrayValues = [];
				this.arrayValues.push(currentValue);
			} else {
				let tmp: Array<number> = [];
				if (nextValue < 0)
					tmp = this.arrayValues.splice(1, this.arrayValues.length - 1);
				else tmp = this.arrayValues.splice(0, this.arrayValues.length - 1);
			}
		}

		this.arrayValues = [...this.arrayValues];

		if (this.arrayValues.length === 1) {
			if (this._totalSup && !this._totalInf) {
				if (this.arrayValues[0] > 0) this.isEnablePowerDown = true;
				else this.isEnablePowerDown = false;
			} else if (!this._totalSup && this._totalInf) {
				if (this.arrayValues[0] < 0) this.isEnablePowerUp = true;
				else this.isEnablePowerUp = false;
			}
		}

		if (value !== limitValue) {
			this.powerService.emitPowerActive(this.arrayValues);
		} //Lanza el evento al padre para recoger los valores del power.
	}

	private _vibratePad(): void {
		this.padsUtils.vibratePad();
	}

	onArrayValuesChanged(newValues: number[]) {
		this.arrayValues = [...newValues];
		if (this.arrayValues.length > 1) this._getCurrentPowers();
		this.powerService.emitPowerActive(this.arrayValues);
	}

	/**
	 * Moverse al siguiente nodo o el anterior
	 */
	pointNodeAction(avanzar, regresar) {
		if (this.discoverMode == true) {
			if (avanzar == true) {
				this.nowIndex++
				//ocultamos la flecha de atras si no hay o la de adelante o ninguna
				if (this.nowIndex === 0) {
					this.backEnabled = false;
				}
				// Verificar si this.nodos.flickNode es igual al idOriginal del último nodo en el array
				if (this.nowIndex === this.finishIndex) {
					this.nextEnabled = false;
				}
				const nextBackNode = { avanzar: true, regresar: false };
				this.pointNodeService.emitNextPointNodeActive(nextBackNode);
				this.sigmaCanvasService.nextDiscoverStep();
				this.backEnabled = true;
				this.nowIndex--// esto es para que no sume dos veces
				this.logigOrdinal(true);
				this._vibratePad();
				if (
					this.sigmaCanvasService.countNodes.length ==
					this.sigmaCanvasService.activeOrdinals.length
				) {
					this.nextEnabled = false;
				}
			} else {
				this.nowIndex--
				//ocultamos la flecha de atras si no hay o la de adelante o ninguna
				if (this.nowIndex === 0) {
					this.backEnabled = false;
				} else{
					this.backEnabled = true;
				}
				// Verificar si this.nodos.flickNode es igual al idOriginal del último nodo en el array
				if (this.nowIndex === this.finishIndex) {
					this.nextEnabled = false;
				} else{
					this.nextEnabled = true;
				}
				const nextBackNode = { avanzar: false, regresar: true };
				this.pointNodeService.emitNextPointNodeActive(nextBackNode);
				this.sigmaCanvasService.beforeDiscoverStep();
				this.nowIndex++ // esto es para que no sume dos veces
				this.logigOrdinal(false);
				this._vibratePad();
				if (this.sigmaCanvasService.activeOrdinals.length == 1) {
					//this.backEnabled = false;
				}
				if (
					this.sigmaCanvasService.countNodes.length !=
					this.sigmaCanvasService.activeOrdinals.length
				) {
					//this.nextEnabled = true;
				}
			}
		} else {
			const nextBackNode = { avanzar: avanzar, regresar: regresar };
			if(this.node == undefined){
				this.pointNodeService.emitNextPointNodeActive(nextBackNode);
			}
			this._vibratePad();
			this.logigOrdinal(avanzar);
		}
	}

	private logigOrdinal(avanzar: boolean) {
		if (avanzar == true) {
			this.action.emit("next");
			//avanzo por lo tanto habilita el anterior para volver
			this.backEnabled = true;
			//necesito saber si habra mas para mostrar u ocultar la flecha de next
			//sumo 1 al actual
			this.nowIndex++;
			//comparo el actual con el ultimo
			if (this.nowIndex === this.finishIndex) {
				this.nextEnabled = false;
			} else{
				this.nextEnabled = true;
			}
		} else {
			this.action.emit("back");
			//retrocedo por lo tanto habilita el siguiente para volver
			this.nextEnabled = true;
			//necesito saber si habra mas para mostrar u ocultar la flecha de back
			//sumo 1 al actual
			this.nowIndex--;
			if (this.nowIndex === 0) {
				this.backEnabled = false;
			} else{
				this.backEnabled = true;
			}
		}
	}

	private _getCurrentPowers() {
		if (this.listNodeAuto != undefined) {
			let power1 = this.listNodeAuto.explicacionesPower1.length;
			let power2 = this.listNodeAuto.explicacionesPower2.length;
			let power3 = this.listNodeAuto.explicacionesPower3.length;
			let powerminus1 = this.listNodeAuto.explicacionesPowerMinus1.length;
			let powerminus2 = this.listNodeAuto.explicacionesPowerMinus2.length;
			let powerminus3 = this.listNodeAuto.explicacionesPowerMinus3.length;

			this._totalSup = power1 + power2 + power3;
			this._totalInf = powerminus1 + powerminus2 + powerminus3;

			if (this._totalSup > 0) this.isEnablePowerUp = true;
			if (this._totalInf > 0) this.isEnablePowerDown = true;
		}
	}

	private getCurrentKeyboardKey(key: string): void {
		switch (key) {
			case ACTIONS.ARROWUP:
				this.isEnablePowerUp ? this.addPower() : null;
				break;
			case ACTIONS.ARROWDOWN:
				this.isEnablePowerDown ? this.removePower() : null;
				break;
			case ACTIONS.ARROWRIGHT:
				this.pointNodeAction(true, false);
				break;
			case ACTIONS.ARROWLEFT:
				this.pointNodeAction(false, true);
				break;
		}
	}

	public viewNodes() {
		this.toolsService.changeVisibility({ nodes: true, quizzes: false });
		this.padsUtils.vibratePad();
	}

	public viewQuizzes() {
		this.toolsService.changeVisibility({ nodes: false, quizzes: true });
		this.padsUtils.vibratePad();
	}
}
