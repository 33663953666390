import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-quiz-play-footer',
    templateUrl: './quiz-play-footer.component.html',
    styleUrls: ['./quiz-play-footer.component.scss'],
    standalone: false
})
export class QuizPlayFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
