import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'profileSettings',
    standalone: false
})
export class ProfileSettingsPipe implements PipeTransform {

  transform(profileSettings: any, opcion: string): any {

    if (profileSettings !== undefined && profileSettings.length > 0) {

      const profileSetting = profileSettings.filter(x => x.profile.toLowerCase() === opcion.toLowerCase());

      if (profileSetting.length > 0) {
        return 'colorTextoActivado';
      } else {
        return 'colorTextoDesactivado';
      }
    }

    return 'colorTextoDesactivado';
  }
}
