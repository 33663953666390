import { SocketService } from "./../../../../core/services/socket/socket-service.service";
import { ConfigChallengeModel, GroupModel } from "src/app/core/models/groups/groups-model";
import { Input, OnInit,	ViewEncapsulation, OnDestroy,	ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
// Services
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { UntypedFormBuilder } from "@angular/forms";
import { SliceStringPipe } from "src/app/shared/pipes/slice-string.pipe";
import { QuizzesService } from "src/app/core/services/quizzes";
import { Component } from "@angular/core";
import { Subscription, Subject, interval } from "rxjs";
import { GruposService,	SOCKETMESSAGES } from "src/app/core/services/groups/grupos.service";
import { Profiles } from "src/app/core/utils/profiles.enum";
import { MODAL_DIALOG_TYPES } from "src/app/core/utils/modal-dialog-types";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ReportingService } from "../../reporting/service/reporting.service";
import { ToasterService } from "src/app/core/services/shared/toaster.service";
import { CountdownComponent } from "ngx-countdown";
import { MastersService } from "src/app/core/services/masters";
import { GraphService } from "src/app/core/services/graph/graph.service";
import { ModalFinishSocketCoursesComponent } from "../modal-socket-courses/modal-finish-socket-courses/modal-finish-socket-courses.component";
import { SurveysService } from "src/app/core/services/surveys/surveys.service";

@Component({
    selector: 'app-modal-socket-surveys',
    templateUrl: './modal-socket-surveys.component.html',
    styleUrls: ['./modal-socket-surveys.component.scss'],
    providers: [SliceStringPipe],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ModalSocketSurveysComponent implements OnInit, OnDestroy {
	@Input() group: GroupModel;
	@Input() onlineUsers: number;
	@Input() idCurso?: number;
	@Input() idGrafo?: number;
	@Input() idNodo?: number;
	@Input() onlineUsersIds?: [] = [];

	allDatasets: any[] = [];
	datasetsImported: any[] = [];
	labelsImported: any[] = [];

	cargando = false;
	idCourse: number = null;
	idSelectedCourse: number = null;
	quiz: any = null;
	elements: any[] = [];
	rol = Profiles.Teacher;
	showOnlyGraphs = true;
	showOnlyExclusive = false;
	nameSession = "";
	nameSessionUpdate = new Subject<string>();

	resultsSubscription: Subscription;
	private destroy$ = new Subject();

	private subscriptions: Subscription[] = [];
	showReport: boolean = false;
	headTableReport: [];
	bodyTableReport: [];
	usersInGroupCounter: number = 0;
	usersInGroup: any[];
	usuariosOnline: any[] = [];
	usuariosOffline: any[] = [];
	offlineNames: any[] = [];
	challengeOn: boolean = true;

	cameFromCalendar: boolean = false;
	surveyFromCalendar: number = null;
	idEvent: number = null;
	nameSessionFromCalendar: string = null;
	@ViewChild("cd", { static: false }) private countdown: CountdownComponent;
	graphfromCalendar: any;
	selectedCourseFromCalendar: any;
	constructor(
		private loginService: LoginService,
		private activeModal: NgbActiveModal,
		private groupServices: GruposService,
		private socketService: SocketService,
		private modalService: NgbModal,
		private translateService: TranslateService,
		private toaster: ToasterService,
		private mastersService: MastersService,
		public surveysService: SurveysService,
	) {
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((s) => s.unsubscribe());
		this.socketService.configChallenge = null;
		this.destroy$.next(true);
	}

	ngOnInit() {
		//this.cargando = true;
		localStorage.removeItem("challengeOn");
		localStorage.setItem("challengeOn", JSON.stringify(this.challengeOn));
		const data = {
			challengeTime: 40,
			option: "2",
			synchronizedStudents: true,
			randomOrder: false,
		} as ConfigChallengeModel;
		this.socketService.configChallenge = data;
		const msgSubs = this.socketService.msgFromServer.subscribe((data) => {
			//Cuando llega un mensaje del socket, lo estamos escuchando aqui y procedemos
			const dataArray = data.split(":");
			const type = dataArray[0];
			const onlineUsers: number = parseInt(dataArray[1]);
			switch (type) {
				case SOCKETMESSAGES.USERSCOUNTER:
					this.onlineUsers = onlineUsers;
					setTimeout(() => {
						this.groupServices
							.getGroupSession(this.group.idGroup)
							.pipe(take(1))
							.subscribe((sessions) => {
								this.usuariosOnline = [];
								this.usuariosOffline = [];
								this.offlineNames = [];
								this.usersInGroup.forEach((usuario: any) => {
									// Verificar si el usuario está presente en alguna sesión
									const usuarioEnSesion = sessions.data.some(
										(sesion) => sesion.idUser === usuario.idUser
									);

									// Agregar el usuario al array correspondiente
									if (usuarioEnSesion) {
										this.usuariosOnline.push(usuario);
									} else {
										this.offlineNames.push(
											`${usuario.firstName} ${usuario.surname}`
										);
										this.usuariosOffline.push(usuario);
									}
								});
							});
					}, 2000);
					break;
			}
		});
		this.subscriptions.push(msgSubs); // Añadimos susbcribe para poder destruirlo al cerrar el modal
		const updateNameSession = this.nameSessionUpdate
			.pipe(
				//Cuando escribimos en el input para cambiar el nombre de la sesión y hacer la llamada
				debounceTime(1000),
				distinctUntilChanged()
			)
			.subscribe((value) => {
				if (value) {
					this.groupServices
						.updateNameSession(
							this.socketService.idSession,
							value,
							this.group.idGroup
						)
						.subscribe(
							(result) => {
								this.toaster.success(
									this.translateService.instant(
										"SOCKETQUIZPREVIEW.OKNAMESESSIONUPDATE"
									)
								);
							},
							(err) => {
								this.toaster.error(
									this.translateService.instant(
										"SOCKETQUIZPREVIEW.KONAMESESSIONUPDATE"
									)
								);
							}
						);
				}
			});
		this.subscriptions.push(updateNameSession); // Añadimos susbcribe para poder destruirlo al cerrar el modal
		this.groupServices
			.getListEstudiantes(this.group.idGroup)
			.subscribe((members) => {
				this.usersInGroupCounter = members.data.length;
				this.usersInGroup = members.data;
			});
		setTimeout(() => {
			if (this.cameFromCalendar == true) {
				this.nameSessionUpdate.next(this.nameSessionFromCalendar);
				this.groupServices
					.sendMsgToRoomClientsSurvey(
						this.surveyFromCalendar,
						this.idEvent,
						0, //no anonimo de momento
						this.group.idGroup,
					)
					.subscribe();
				this.setupReporting();
			}
		}, 800);
	}

	prefillOfflineUsers() {
		if (this.usuariosOffline.length == 0) {
			this.usersInGroup.forEach((usuario: any) => {
				// Agregar el usuario al array correspondiente
				this.offlineNames.push(`${usuario.firstName} ${usuario.surname}`);
				this.usuariosOffline.push(usuario);
			});
		}
	}

	closeModal(sendData?: any): void {
		this.groupServices
			.sendDataToServerSocket(
				0,
				this.group.idGroup,
				SOCKETMESSAGES.CLOSESOCKET,
				0,
				0,
				""
			)
			.subscribe();
		this.activeModal.close(true);
		localStorage.removeItem("challengeOn");
		localStorage.removeItem("challengeType");
	}

	finalizeChallenge(): void {
		//Abrimos modal para finalizar o anular el desafio
		const modalRef = this.modalService.open(ModalFinishSocketCoursesComponent, {
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.result.then((result) => {
			switch (result) {
				case "finalizar":
					this.groupServices
						.sendDataToServerSocket(
							0,
							this.group.idGroup,
							SOCKETMESSAGES.CLOSESOCKET,
							0,
							0,
							""
						)
						.subscribe();
					this.closeModal();
					break;
				case "anular":
					//Eliminar el reto
					this.groupServices
						.deleteChallenge(this.group.idGroup, this.socketService.idSession)
						.subscribe((result) => {});
					this.closeModal();
					break;
				default:
					break;
			}
		});
		//Creamos el log
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha finalizado el desafio.",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.group.idGroup,
		};
		this.mastersService
			.createActivityHistory(
				bodyLog1.action,
				bodyLog1.actionData,
				bodyLog1.startDate,
				bodyLog1.endingDate,
				bodyLog1.role,
				bodyLog1.idGroup
			)
			.subscribe();
	}

	setupReporting() {
		this.showReport = false;
		this.allDatasets = [];
		this.datasetsImported = [];
		this.labelsImported = [];
    this.cargando = false;
    const isProfesor: boolean = this.loginService.esProfesor();
    if (isProfesor) {
			this.surveysService.getSocialInformeCenterByEvent(this.surveyFromCalendar, this.idEvent)
				.pipe(take(1)) // La llamada se hace una vez y se completa.
				.subscribe((res: any) => {
						if (res.data != null && res.data.length > 0) {
								this.showReport = true;
								this.allDatasets = res.data;
								this.datasetsImported = res.data[0].data;
								this.labelsImported = [res.data[0]];
						}
				});
    }
	}


}
