import { Injectable } from '@angular/core';
import axios from 'axios';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';

@Injectable({
  providedIn: 'root'
})
export class SpeechToTextService {

	private openaiApiKey = API_KEY_CHAT_GTP;
  private mediaRecorder!: MediaRecorder;
  private audioChunks: Blob[] = [];

  constructor() {}

  startRecording(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			navigator.mediaDevices.getUserMedia({ audio: true })
				.then(stream => {
					this.mediaRecorder = new MediaRecorder(stream);
					this.audioChunks = [];

					this.mediaRecorder.ondataavailable = (event) => {
						this.audioChunks.push(event.data);
					};

					this.mediaRecorder.onstop = async () => {
						const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
						try {
							// Assume uploadAudio now returns the transcribed text
							const transcribedText = await this.uploadAudio(audioBlob);
							resolve(transcribedText);
						} catch (error) {
							reject(error);
						}
					};

					this.mediaRecorder.start();
				})
				.catch(reject);
		});
	}

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  private async uploadAudio(audioBlob: Blob) {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.webm");
    formData.append("model", "whisper-1");
    formData.append("language", "es");

    try {
      const response = await axios.post("https://api.openai.com/v1/audio/transcriptions", formData, {
        headers: {
          "Authorization": `Bearer ${this.openaiApiKey}`,
          "Content-Type": "multipart/form-data"
        }
      });

      
      return response.data.text;
    } catch (error) {
      console.error("Error en STT:", error);
      return null;
    }
  }

	private ttsCache: Map<string, string> = new Map();

async speak(text: string, voice: string): Promise<void> {

	let possibleVoices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
	if (!possibleVoices.includes(voice)) {
		voice = 'echo';
	}

  try {
    // Check if this text has already been processed
    if (this.ttsCache.has(text)) {
      const cachedAudioUrl = this.ttsCache.get(text);
      const audio = new Audio(cachedAudioUrl);
      audio.play();
      
      return;
    }

    const response = await axios.post(
      'https://api.openai.com/v1/audio/speech',
      {
        model: 'tts-1',
        voice: voice, // Opciones: 'alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'
        input: text,
      },
      {
        headers: {
          Authorization: `Bearer ${this.openaiApiKey}`,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer', // Recibir audio como buffer
      }
    );

    // Convertir el audio en un Blob reproducible
    const audioBlob = new Blob([response.data], { type: 'audio/mp3' });
    const audioUrl = URL.createObjectURL(audioBlob);

    // Cache the audioUrl for future requests
    this.ttsCache.set(text, audioUrl);

    const audio = new Audio(audioUrl);
    audio.play();

   
  } catch (error) {
    console.error('Error en TTS:', error);
  }
}
}
