import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, HostListener, ElementRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { API_KEY_CHAT_GTP } from 'src/app/core/models/masters/masters.enum';
import { MastersService } from 'src/app/core/services/masters';
import { LocalStorage } from 'src/app/core/utils';

@Component({
    selector: 'app-imgupload',
    templateUrl: './imgupload.component.html',
    styleUrls: ['./imgupload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ImguploadComponent,
            multi: true
        }
    ],
    standalone: false
})
export class ImguploadComponent implements ControlValueAccessor {
  imgUrl:string;
  file:File;
  onChange: Function
  isEdit: boolean

  @Input() image: string
  @Input() isShowDelete: boolean
	@Input() css:string;
	@Input() node:any;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const reader:FileReader = new FileReader()
    const file = event && event.item(0);
    this.file = file;
    this.onChange(file)

    reader.onloadend = (event: any) => {
      this.imgUrl = event.target.result;
    }
    reader.readAsDataURL(this.file);
  }

	private httpClient: HttpClient;
  constructor(private host: ElementRef<HTMLInputElement>, private sanitizer: DomSanitizer,
		public mastersService: MastersService,
		handler: HttpBackend,
		public http: HttpClient,
		private localStorage: LocalStorage,
	) {
		this.httpClient = new HttpClient(handler);
    this.isEdit = true
  }

  writeValue( value: string ) {
    this.imgUrl = value
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {}

  deleteImage(){
    this.imgUrl = ''
    this.onChange('')
  }

  setDisabledState( isDisabled : boolean ) : void {
    if(isDisabled)
      this.isEdit = false
  }

	sanitizeCss(){
		return this.sanitizer.bypassSecurityTrustStyle(this.css)
	}

	async generateImage(event) {
		const endpoint = 'https://api.openai.com/v1/images/generations';
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${API_KEY_CHAT_GTP}`,
		});
		const body = {
			model: "dall-e-3",
			prompt: this.node.description,
			n: 1,
			size: '1024x1024',
		}
		try {
			const response = await this.httpClient
				.post<any>(endpoint, body,  { headers })
				.toPromise();
			if (response) {
				//Captura la URL de la imagen generada
			const imageUrl = response.data[0].url;

			this.mastersService.uploadimageiatoftp(
				this.node.idCourseCreation,
				this.node.idNodeTarget,
				this.node.idOriginal,
				imageUrl,
				1
			).subscribe((response) => {
				if (response) {
					//reload page
					this.localStorage.setItem('dontLoadMenu', 'true');
					window.location.reload();
				}

			});


			}
		} catch (error) {

			console.error("Error al hacer la consulta a ChatGPT:", error.message);
		}
		try {

			//console.log('Imagen subida exitosamente:', uploadResponse.data);
		} catch (error) {
			console.error('Error generando o subiendo la imagen:', error);
		}
	}

}
