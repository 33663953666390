import { User } from '../../../core/models/users/user.models';
import { CenterById, CenterModel } from '../../../core/models/masters/center.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Utils
import { LocalStorage } from 'src/app/core/utils';

// Models
import { UserProfileValidationModel, UserCenterModel, UserSettingsProfileModel } from 'src/app/core/models/users';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AlertService, TitleService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { MastersService } from 'src/app/core/services/masters';

// Mapper
import { UsersRegisterMapperService, UsersCentersMapperService, UsersProfileValidationMapperService,
         UsersSettingsProfileMapperService } from 'src/app/core/services/mapper';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { LOCALSTORAGESTRINGS } from 'src/app/core/models/masters/localstorage.enum';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs';
import { CenterService } from 'src/app/core/services/center/center.service';
import { MatPaginator } from '@angular/material/paginator';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ModalListCentersComponent } from '../modal-list-centers/modal-list-centers.component';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { ModalCentersComponent } from '../modal-centers';

@Component({
    selector: "app-user-data",
    templateUrl: "./user-data-centro.component.html",
    styleUrls: ["../user-data-general/user-data-general.component.scss"],
    standalone: false
})
export class UserDataCentroComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	centersAssignedToUser: MatTableDataSource<CenterById> = null;
	displayedColumns: string[] = ["centerName", "province", "delete"];
	isLoading: boolean = true;

	public formularioDatos: UntypedFormGroup;

	userData: User;

	usersCenterData: UserCenterModel;

	usersProfileValidationData: UserProfileValidationModel[];

	usersSettingsProfileData: UserSettingsProfileModel[];

	centers: CenterModel[] = [];

	public loading = false;
	userCenter: any;
	userCentro: any = null;

	realData: boolean = false; //true cuando la info que se verá sea real

	constructor(
		private formBuild: UntypedFormBuilder,
		public translateService: TranslateService,
		private titleService: TitleService,
		private alertService: AlertService,
		private localStorage: LocalStorage,
		public loginService: LoginService,
		private usersService: UsersService,
		private mastersService: GetDataService,
		private usersRegisterMapperService: UsersRegisterMapperService,
		private usersCentersMapperService: UsersCentersMapperService,
		private usersSettingsProfileMapperService: UsersSettingsProfileMapperService,
		private usersProfileValidationMapperService: UsersProfileValidationMapperService,
		private activeModal: NgbActiveModal,
		private centerService: CenterService,
		private modalService: NgbModal,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		//obtenemos el userCenter
		this.centerService.getUsersCenter().subscribe(res => {
			if(res.data){
				this.userCentro = res.data;
				this.realData = res.data.realData == 1 ? true : false;
			}
		});

		// Obtengo los maestros
		this.obtenerDatosMaestros();

		// Lanzo las traducciones
		this.traducirOpciones();

		// Cargo los formularios
		this.crearFormularios();

		// Y obtengo la informacion del usuario
		this.obtenerDatosUsuario();

		this.getAssignmentCenters();
	}

	private getAssignmentCenters() {
		this.centerService
			.getListCenterByIdUserForUser()
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe((result) => {
				this.userCenter = result;
				//Obtengo los centros asociados al usuario actual
				// this.centersAssignedToUser = new MatTableDataSource<CenterById>(result);
				// this.centersAssignedToUser.paginator = this.paginator;
			});
	}

	private obtenerDatosMaestros() {
		this.centers = this.mastersService.appCenters;
	}

	private traducirOpciones() {
		// Recupero el lenguaje
		const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(lang);

		// Titulo pagina
		this.translateService
			.get("USERDATA.CONFIGURACIONCENTRO")
			.subscribe((res: string) => {
				this.titleService.setHTMLTitle(res);
				this.titleService.setBarTitle(res);
			});
	}

	// Mostrar Aviso
	public mostrarMensajeAviso() {
		// Para poder hacer las comparaciones
		//this.userData.usersProfileValidation = this.usersProfileValidationData;

		const resp = this.usersService.checkIfUserDataAreRegistered(
			this.userData,
			"centro"
		);

		if (resp.result !== true) {
			this.alertService.info(
				this.translateService.instant(resp.mensaje),
				AlertService.AlertServiceContextValues.UserData
			);
		} else {
			this.alertService.clear();
		}
	}
	// Fin mostrar aviso

	private crearFormularios() {
		// default data and form groups
		this.formularioDatos = this.formBuild.group({
			idUser: [this.loginService.getUser().idUser],
			// centro
			idUserCenter: [0],
			idCenter: [""],
		});
	}

	private cargarDatosFormulario() {
		if (this.usersCenterData != null) {
			this.formularioDatos.controls.idUser.setValue(this.userData.idUser);
			this.formularioDatos.controls.idUserCenter.setValue(
				this.usersCenterData.idUserCenter
			);
			this.formularioDatos.controls.idCenter.setValue(
				this.usersCenterData.idCenter
			);
		}
	}

	private registrarUserSettingProfile() {
		// Y si era la primera vez que grababa algo siendo estudiante lo registro en users_settings_profile
		if (
			this.usersSettingsProfileData.length === 0 ||
			this.usersSettingsProfileData.filter((x) => x.profile === "centro")
				.length === 0
		) {
			let userSettingProfile = new UserSettingsProfileModel();

			userSettingProfile.idUserSettingProfile = 0;
			userSettingProfile.idUser = this.userData.idUser;
			userSettingProfile.profile = "centro";
		}
	}

	openModalCenters(): void {
		let options = {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W90,
		};

		const modalRef = this.modalService.open(ModalListCentersComponent, options);
		// modalRef.componentInstance.format = this.for

		modalRef.result.then(
			(res: boolean) => {
				if (res) this.getAssignmentCenters(); //Debemos actualizar la lista si nos hemos asignado un centro
			},
			(err) => {}
		);
	}

	private obtenerDatosUsuario() {
		this.loading = true;

		this.usersService
			.getUserByIdUser(this.loginService.getUser().idUser)
			.subscribe(
				(resp) => {
					if (resp) {
						// tslint:disable-next-line: max-line-length
						//this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario centro`, 'OK');

						// Mapeo los datos del usuario
						// this.userData = this.usersRegisterMapperService.transform(resp.data.users.nodes[0]);

						 // Mapeo los datos del usuario-centro
						// if (resp.data.users.nodes[0].UsersCenters.length > 0) {
						//   this.usersCenterData = this.usersCentersMapperService.transform(resp.data.users.nodes[0].UsersCenters[0]);
						// }

						// // Los de las validaciones del usuario (si las tiene)
						// if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
						//   // tslint:disable-next-line: max-line-length
						//   this.usersProfileValidationData = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
						// }

						// // Cargo los user settings profile para ver que menus van en gris
						// this.usersSettingsProfileData = resp.data.users.nodes[0].usersSettingsProfiles;

						if (this.usersSettingsProfileData === undefined) {
							this.usersSettingsProfileData = [];
						}

						// Cargo los datos
						this.cargarDatosFormulario();

						// Y el mensaje de aviso en funcion de si ha sido validado o no.
						this.mostrarMensajeAviso();

						this.loading = false;
					} else {
						// tslint:disable-next-line: max-line-length
						this.alertService.error(
							this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
							AlertService.AlertServiceContextValues.UserData
						);

						// tslint:disable-next-line: max-line-length
						//this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario centro`, 'ERROR');
					}
				},
				(error) => {
					this.loading = false;

					// tslint:disable-next-line: max-line-length
					this.alertService.error(
						this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
						AlertService.AlertServiceContextValues.UserData
					);

					// tslint:disable-next-line: max-line-length
					//this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear formulario datos generales - Datos de usuario centro - ERROR: ${error}`, 'ERROR');
				}
			);
	}

	public grabarDatos() {
		if (this.formularioDatos.invalid) {
			return;
		}

		// Recupero los datos del formulario
		this.loading = true;

		const campos = this.formularioDatos.value;

		// Y restauramos los datos del usuario en base al formulario
		// Los campos que no van a ser modificados iran a undefined y no se actualizaran
		this.userData = this.usersRegisterMapperService.transform(campos);

		// El telefono no se actualiza
		this.userData.mobile = null;

		this.usersService.updateUser(this.userData).subscribe(
			(resp: any) => {
				// tslint:disable-next-line: max-line-length
				//this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario centro`, 'OK');

				// Grabo los datos del centro al que corresponde
				this.usersCenterData = this.usersCentersMapperService.transform(campos);
				this.usersCenterData.idUser = this.userData.idUser;
			},
			(error) => {
				console.log(error);

				this.loading = false;

				// tslint:disable-next-line: max-line-length
				this.alertService.error(
					this.translateService.instant("ERROR.HAOCURRIDOUNERROR"),
					AlertService.AlertServiceContextValues.UserData
				);

				// tslint:disable-next-line: max-line-length
				//this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar datos generales - Datos de usuario centro - ERROR: ${error}`, 'ERROR');
			}
		);
	}

	closeModal(sendData?: any) {
		//intercambiamos los datos entre ficticios a reales, cualquiera que sea el caso
		let real = this.realData ? 1 : 0;
		this.centerService.changeRealDataCenter(real).subscribe();
		this.activeModal.close(sendData);
		// reload app
		window.location.reload();
	}

	deleteCenterToUser(index: number, idCenter: number): void {
		this.centerService.deleteCenterAssign(idCenter).subscribe(
			(result) => {
				this.centersAssignedToUser.data.splice(index, 1); //Elimino el registro de la tabla
				this.centersAssignedToUser._updateChangeSubscription(); //Actualizo la tabla con el borrado del elemento
				this.toaster.success(
					this.translateService.instant("TEACHERSETTINGS.OKDELETEASSIGNMENT")
				);
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("TEACHERSETTINGS.KODELETEASSIGNMENT")
				);
			}
		);
	}

	deleteAssign(idCenter){
		this.centerService.deleteCenterAssignUser(idCenter).subscribe(
			(result) => {
				this.getAssignmentCenters();
				this.toaster.success(
					this.translateService.instant("TEACHERSETTINGS.OKDELETEASSIGNMENT")
				);
			},
			(err) => {
				this.toaster.error(
					this.translateService.instant("TEACHERSETTINGS.KODELETEASSIGNMENT")
				);
			}
		);
	}

	editCenter(){
		let options = { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W30 }

    const modalRef = this.modalService.open(ModalCentersComponent , options );

		modalRef.componentInstance.center = this.userCenter;
		modalRef.componentInstance.isEdit = true;

    modalRef.result.then(
			(res: boolean) => {
				// if (res) {
				// 	//Actualizo los datos de la tabla con el nuevo centro creado
				// 	this.dataServ.getAllCenters().subscribe((res) => {
				// 		this.listCenters.data = this.dataServ.appCenters = res;
				// 		this.listCenters._updateChangeSubscription();
				// 		this.reloadData = true;
				// 	});
				// }
			},
			(err) => console.error("Error al actualizar los centros")
		);
	}

	onToggleRealData(isReal: boolean){
		this.realData = isReal;
	}

}
