import { MastersService } from "src/app/core/services/masters";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CoursesService } from "src/app/core/services/courses";
import { LoginService } from "src/app/core/services/login";
import { TargetsService } from "src/app/core/services/targets";
import { UsersService } from "src/app/core/services/users";
import { QuizModel } from "src/app/core/models/quizzes";

interface NavigatorWithConnection extends Navigator {
  connection?: {
    downlink: number;
  };
}

@Component({
    selector: "app-modal-record-quizzes",
    templateUrl: "./modal-record-quizzes.component.html",
    styleUrls: ["./modal-record-quizzes.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class ModalRecordQuizzesComponent implements OnInit {
	cargando: boolean = false;
	tree: any[] = [];

	selectedLabel: any;
	selectedDate: string = new Date().toISOString().split('T')[0];
	selectedQuizzes: number[] = [];

	comentario: string = '';

	quiz: QuizModel;
	courseId: number;
	graphId: number;
	elements: any[];
	respuestaEstudiante: any;
	fechaUltimaRespuesta: Date;

	constructor(
		public loginService: LoginService,
		public translateService: TranslateService,
		public coursesService: CoursesService,
		public targetsService: TargetsService,
		public activeModal: NgbActiveModal,
		public userService: UsersService,
		public router: Router,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		let storedGroupInfo = JSON.parse(localStorage.getItem("selectedGroupInfo"));	
		let idGrupo = storedGroupInfo ? storedGroupInfo.idGroup : 0;
		//Creamos el log
		const bodyLog1 = {
			action: "tareas calendario",
			actionData: "Ha entrado en la pantalla para asignar o visualizar las tareas del calendario",
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: idGrupo,
		};
		this.masterService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();
	}

	getNetworkSpeed() {
		const navigatorWithConnection = navigator as NavigatorWithConnection;
		if (navigatorWithConnection.connection) {
			return navigatorWithConnection.connection.downlink;
		} else {
			return 10; // No se pudo obtener la velocidad de conexión
		}
	}

	closeModal(sendData?: any) {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(sendData);
	}

	clickElement(element: any) {
		this.selectedLabel = element;
	}

	verQuiz(data){
		this.cargando = true;
		this.quiz = null;
		setTimeout(() => {
			this.quiz = data.quiz;
			this.courseId = 0;
			this.graphId = 0;
			this.elements = data.elementsQuestion;
			this.respuestaEstudiante = data.elementsAnswer;
			this.fechaUltimaRespuesta = data.elementsAnswer[0].lastDate;
			this.cargando = false;
		}, 300);
	}
}
