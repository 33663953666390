import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from 'src/app/core/services/task';

@Component({
    selector: 'app-head-competition-couples',
    templateUrl: './head-competition-couples.component.html',
    styleUrls: ['./head-competition-couples.component.scss'],
    standalone: false
})
export class HeadCompetitionCouplesComponent implements OnInit {
    @Input() idCurso: any;
    task: any;
    constructor(public activeModal: NgbActiveModal,
        public taskService: TaskService) {
        this.taskService.task.subscribe((t) => {
            this.task = t;
        });
    }

    ngOnInit() {
    }
    closeModal(sendData?: any) {
        this.activeModal.close(sendData);
    }


}
