import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { NodeDetailInfoComponent } from '../node-detail/components/node-detail-info/node-detail-info.component';


declare var document;

@Component({
    selector: 'app-node-menu',
    templateUrl: './node-menu.component.html',
    styleUrls: ['./node-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class NodeMenuComponent implements OnInit {
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(!this.eRef.nativeElement.contains(event.target))
          this.closeNav()
    }

    @ViewChild("mySidebar2") mySidebar2: ElementRef;
    @ViewChild("menuIzquierdo2") menuIzquierdo2: ElementRef;

    @Input() node;
    @Input() quiz;
    @Input() course;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() canEdit: boolean = false;
    @Input() modeAuto: boolean = false;
    @Input() showFiles: boolean = true;
    @Input() padStatus: boolean
    @Input() labelStatus: boolean
    @Output() showPads: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() showLabels:  EventEmitter<boolean> = new EventEmitter<boolean>()

    isOpenMenu:boolean = false

    constructor(public loginService:LoginService, private modalService: NgbModal, private eRef: ElementRef) { }

    ngOnInit() {}

    showInfo() {
        const modalRef = this.modalService.open(NodeDetailInfoComponent,
            {
                scrollable: true,
                windowClass: `${MODAL_DIALOG_TYPES.W90}`
            }
        );

        modalRef.componentInstance.node = this.node;
        modalRef.componentInstance.idCurso = this.idCurso;
        modalRef.componentInstance.idMapa = this.idMapa;
        modalRef.componentInstance.canEdit = this.canEdit;
        modalRef.componentInstance.course = this.course;
    }

    showHidePads(){
        this.padStatus = !this.padStatus
        this.showPads.emit(this.padStatus)
    }


    showHideLabels(){
        this.labelStatus = !this.labelStatus
        this.showLabels.emit(this.labelStatus)
    }


    openNav(){
        this.menuIzquierdo2.nativeElement.style.display = "none";
        this.mySidebar2.nativeElement.style.width = "300px";
        this.mySidebar2.nativeElement.style.heigth = "500px";
    }

  closeNav(){
    this.mySidebar2.nativeElement.style.heigth = "0";
    this.mySidebar2.nativeElement.style.width = "0";
      setTimeout(() => {
        this.menuIzquierdo2.nativeElement.style.display = "block";
      }, 400);
  }

  imprimir(){
  }

}

