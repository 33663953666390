import { CenterService } from 'src/app/core/services/center/center.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from "@angular/material/table";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CenterModel } from 'src/app/core/models/masters';
import { GetDataService } from 'src/app/core/services/get-data/get-data.service';
import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';
import { ModalCentersComponent } from '../modal-centers/modal-centers.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { LoginService } from 'src/app/core/services/login';

interface SelectedRow{
    centerName: string
    idCenter: number
    province: string
}


@Component({
    selector: "app-modal-list-centers",
    templateUrl: "./modal-list-centers.component.html",
    styleUrls: ["./modal-list-centers.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalListCentersComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	listCenters: MatTableDataSource<CenterModel> = null;
	displayedColumns: string[] = ["centerName", "province"];
	reloadData: boolean = false;

	constructor(
		public translateService: TranslateService,
		public activeModal: NgbActiveModal,
		private dataServ: GetDataService,
		private centerService: CenterService,
		private modalService: NgbModal,
		private dataService: GetDataService,
		private toaster: ToasterService,
		private loginService: LoginService
	) {}

	ngOnInit() {
		this.getCenters();
	}

	private getCenters() {
		//si eres editor (o autor) debemos cargar los centros a los que NO pertenece o no tiene asignado
		if(this.loginService.esAutor()){
			this.centerService.getCentersNotAsignedEditor().subscribe((res) => {
				this.dataService.appCenters = res;
				this.listCenters = new MatTableDataSource<CenterModel>(res);
				this.listCenters.paginator = this.paginator;
			});
		} else {
			this.dataService.getAllCenters().subscribe((res) => {
				this.dataService.appCenters = res;
				this.listCenters = new MatTableDataSource<CenterModel>(res);
				this.listCenters.paginator = this.paginator;
			});
		}
	}

	/**
	 * Función que filtra el contenido de la tabla
	 * @param filterValue Valor del input del filtro
	 */
	applyFilter(filterValue: string): void {
		this.listCenters.filter = filterValue.trim().toLowerCase();
	}

	/**
	 * Funcion para cerrar el modal
	 */
	closeModal(sendData?: any): void {
		this.activeModal.close(this.reloadData);
	}

	/**
	 * Función que permite asignar un centro seleccionado de la tabla al usuario actual
	 * @param selectedRow Fila selecciona
	 * @returns Devuelve si se ha asociado correctamente el usuario a ese centro, o si ya lo estaba o si ha ocurrido un error en el proceso
	 */
	setCenterToUser(selectedRow: SelectedRow): void {
		if (!selectedRow) return;

		// Abro un modal preguntando si desea borrar el curso o no
		const modalRef = this.modalService.open(ModalAceptarCancelarComponent, {
			scrollable: true,
			windowClass: MODAL_DIALOG_TYPES.W30,
		});

		modalRef.componentInstance.mensaje = this.translateService.instant(
			"TEACHERSETTINGS.MODALACCEPTASSIGNMENT"
		);

		modalRef.result.then((result: boolean) => {
			if (result) {
				if (this.loginService.getProfile() === "PROFESOR") {
					this.centerService.setCenterToTeacher(selectedRow.idCenter).subscribe(
						(response) => {
							if (response.data) {
								this.toaster.success(
									this.translateService.instant(
										"TEACHERSETTINGS.OKASSIGNCENTERTOTEACHER"
									)
								);
							} else {
								this.toaster.error(
									this.translateService.instant(
										"TEACHERSETTINGS.YETASSIGNCENTERTOTEACHER"
									)
								);
							}
							this.reloadData = true;
							this.activeModal.close(this.reloadData);
						},
						(err) => {
							this.toaster.error(
								this.translateService.instant(
									"TEACHERSETTINGS.KOASSIGNCENTERTOTEACHER"
								)
							);
						}
					);
				} else if (this.loginService.getProfile() === "AUTOR") {
					this.centerService.asignCenterToEditor(selectedRow.idCenter).subscribe(
						(response) => {
							if (response.data) {
								this.toaster.success(
									this.translateService.instant(
										"TEACHERSETTINGS.OKASSIGNCENTERTOTEACHER"
									)
								);
							} else {
								this.toaster.error(
									this.translateService.instant(
										"TEACHERSETTINGS.YETASSIGNCENTERTOTEACHER"
									)
								);
							}
							this.reloadData = true;
							this.activeModal.close(this.reloadData);
						},
						(err) => {
							this.toaster.error(
								this.translateService.instant(
									"TEACHERSETTINGS.KOASSIGNCENTERTOTEACHER"
								)
							);
						}
					);
				} else if (this.loginService.getProfile() === "CENTRO") {
					this.centerService.setCenterToUser(selectedRow.idCenter).subscribe(
						(response) => {
							if (response.data) {
								this.toaster.success(
									this.translateService.instant(
										"TEACHERSETTINGS.OKASSIGNCENTERTOTEACHER"
									)
								);
							} else {
								this.toaster.error(
									this.translateService.instant(
										"TEACHERSETTINGS.YETASSIGNCENTERTOTEACHER"
									)
								);
							}
							this.reloadData = true;
							this.activeModal.close(this.reloadData);
						},
						(err) => {
							this.toaster.error(
								this.translateService.instant(
									"TEACHERSETTINGS.KOASSIGNCENTERTOTEACHER"
								)
							);
						}
					);
				}
			}
		});
	}

	newCenterModal(): void {
		let options = { scrollable: true, windowClass: MODAL_DIALOG_TYPES.W30 };

		const modalRef = this.modalService.open(ModalCentersComponent, options);

		modalRef.result.then(
			(res: boolean) => {
				if (res) {
					//Actualizo los datos de la tabla con el nuevo centro creado
					this.dataServ.getAllCenters().subscribe((res) => {
						this.listCenters.data = this.dataServ.appCenters = res;
						this.listCenters._updateChangeSubscription();
						this.reloadData = true;
						this.closeModal();
					});
				}
			},
			(err) => console.error("Error al actualizar los centros")
		);
	}
}
