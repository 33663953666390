import { Component, Input, OnInit, ViewEncapsulation, HostListener } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ReportService } from "src/app/core/services/report/report.service";

@Component({
    selector: "app-informe-admin",
    templateUrl: "./informe-admin.component.html",
    styleUrls: ["./informe-admin.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('accordion', [
            state('open', style({
                width: 'auto',
                transform: 'translateY(0)',
                overflow: 'initial',
                right: '10px'
            })),
            state('closed', style({
                width: '0',
                overflow: 'hidden',
                transform: 'translateY(-500px)',
                right: '-200px'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-out')
            ]),
        ]),
        trigger('accordion2', [
            state('in', style({
                width: 'auto',
                transform: 'translateX(0px)',
                overflow: 'initial',
                left: '10px'
            })),
            state('out', style({
                width: '0',
                overflow: 'hidden',
                transform: 'translateX(-500px)',
                left: '-200px'
            })),
            transition('in <=> out', [
                animate('0.3s ease-in-out')
            ]),
        ])
    ],
    standalone: false
})
export class InformeAdminComponent implements OnInit {
    @Input() allDatasets: any[] = [];
    @Input() datasetsImported: any;
    @Input() labelsImported: any;

    selectedLabel = null; // Variable para guardar el elemento seleccionado
    verBotonesGraficas: number = 0;
    consultas: any[] = [
		{ id: 1, title: this.translateService.instant("REPORT.EDITORSSIGNED")},
		{ id: 2, title: this.translateService.instant("REPORT.STUDENTSSIGNED")},
		{ id: 3, title: this.translateService.instant("REPORT.TEACHERSSIGNED")},
		{ id: 4, title: this.translateService.instant("REPORT.PARENTSSIGNED")},
		{ id: 5, title: this.translateService.instant("REPORT.EDITORSACTIVITY")},
		{ id: 6, title: this.translateService.instant("REPORT.STUDENTSACTIVITY")},
		{ id: 7, title: this.translateService.instant("REPORT.TEACHERSACTIVITY")},
		{ id: 8, title: this.translateService.instant("REPORT.PARENTSACTIVITY")},
	];
    consulta: number = 0;
    consultaID: number | null = null;
    role: number = 0;

    isMobile: boolean = false;
    isMobile1: boolean = false;
    isMenuOpen: boolean = false;
    accordionOpen: boolean = false;
    accordionOpen2: boolean = false;

    step: number = 1;

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService,
        private reportService: ReportService,
    ) { }

    ngOnInit() {
        this.consultaID = this.consulta;
        this.isMobile = window.innerWidth < 1200;
        this.isMobile1 = window.innerWidth < 1350;
        if(this.consulta < 9){
            this.verBotonesGraficas = 1;
        } else if(this.consulta == 9){
            this.verBotonesGraficas = 2;
        }        
        this.viewData(this.allDatasets[0].data, this.allDatasets[0], 1);
        this.viewChildren2(0);
    }

    ngOnDestroy() {
    }

    closeModal(sendData?: any) {
        this.activeModal.close();
    }

    @HostListener("window:resize", ["$event"])
    onResize(event: any): void {
        if (window.innerWidth <= 1200) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        if (window.innerWidth <= 1350) {
            this.isMobile1 = true;
        } else {
            this.isMobile1 = false;
        }
    }

    viewChildren2(i: number){
        this.allDatasets[i].childrenVisible = !this.allDatasets[i].childrenVisible;
        for (let index = 0; index < this.allDatasets[i].children.length; index++) {
            this.allDatasets[i].children[index].childrenVisible = false;
        }
    }
    viewChildren3(i: number, j: number){
        this.allDatasets[i].children[j].childrenVisible = !this.allDatasets[i].children[j].childrenVisible;
    }
    closeTree(){
        if(this.allDatasets != null && this.allDatasets.length > 0){
            for (let i = 0; i < this.allDatasets.length; i++) {
                this.allDatasets[i].childrenVisible = false;
                if(this.allDatasets[i].children != null && this.allDatasets[i].children.length > 0){
                    for (let j = 0; j < this.allDatasets[i].children.length; j++) {
                        this.allDatasets[i].children[j].childrenVisible = false;
                    }
                }
            }
        }
    }

    ocultarTodo(){
		//ocultamos la grafica de barras
		const div = document.getElementById("barras");
		if(div){
			div.style.display = "none";
		}
        //ocultamos la grafica de barras agrupadas
		const div2 = document.getElementById("barras_group");
		if(div2){
			div2.style.display = "none";
		}
	}

    viewData(data: any, select: any, level: number){
        console.log(data);
        console.log(select);
        this.step = level;
        const aux = this.verBotonesGraficas;
        this.ocultarTodo();
        this.selectedLabel = select;
        this.labelsImported = [this.selectedLabel];
        this.datasetsImported = data;
        this.verBotonesGraficas = 0;
        setTimeout(() => {
            if(aux == 1){
                this.verBarras();
            } else if(aux == 2){
                this.verBarrasGroup();
            }
		}, 100);
    }

    getDatasets(dataIn: any[]) {
		//primero creamos los labels
        let datasets = [];
		let labels = [];
        let cant = 0;
		if(this.consultaID == 9){
			labels = ['Proyectos', 'Ideografos', 'Ideas', 'Actividades'];
            cant = 4;
		}
		//ahora creamos el dataset
		for (let index = 0; index < cant; index++) {
			const data = [];
			for (let index2 = 0; index2 < dataIn.length; index2++) {
				const element = dataIn[index2].data[index];
				data.push(element);
			}
			const newDataset = {
				label: labels[index],
				borderWidth: 1,
				data: data,
			};
			datasets.push(newDataset);
		}
        return datasets;
	}

    verBarras(){
        //mostramos la grafica de barras
        const div = document.getElementById("barras");        
        if(div){
            div.style.display = "block";
        }
        //mostramos la grafica de barras agrupadas
        const div2 = document.getElementById("barras_group");
        if(div2){
			div2.style.display = "none";
		}
        this.verBotonesGraficas = 1;
		this.accordionOpen = false;
    }
    verBarrasGroup(){
        //mostramos la grafica de barras agrupadas
        const div = document.getElementById("barras_group");
        if(div){
            div.style.display = "block";
        }
        //mostramos la grafica de barras
        const div2 = document.getElementById("barras");
        if(div2){
			div2.style.display = "none";
		}
        this.verBotonesGraficas = 2;
		this.accordionOpen = false;
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.accordionOpen = false;
        this.accordionOpen2 = false;
    }

    toggleAccordion() {
        this.isMenuOpen = false;
        this.accordionOpen = !this.accordionOpen;
        this.accordionOpen2 = false;
    }

    toggleAccordion2() {
        this.isMenuOpen = false;
        this.accordionOpen = false;
        this.accordionOpen2 = !this.accordionOpen2;
    }

    onConsultaChange(){
        this.datasetsImported = null;
        this.allDatasets = [];
        if(this.consultaID == 1 || this.consultaID == 5){
            this.role = 1;
        } else if(this.consultaID == 2 || this.consultaID == 6){
            this.role = 2;
        } else if(this.consultaID == 3 || this.consultaID == 7){
            this.role = 3;
        } else if(this.consultaID == 4 || this.consultaID == 8){
            this.role = 4;
        }
        this.reportService.getInformeAdmin(this.role, this.consultaID).subscribe(
            res => {
                if(res.data){
                    this.allDatasets = res.data;
                    this.viewData(res.data[0].data, res.data[0], 1);
                    this.viewChildren2(0);
                }
            }
        )
    }

    verDetalle(event: { datasetIndex: number; index: number }) {
        if(this.step == 1){
            this.step++;
            this.viewData(this.allDatasets[event.datasetIndex].children[event.index].data, this.allDatasets[event.datasetIndex].children[event.index], 2);
            //this.viewChildren3(event.datasetIndex, event.index);
        }
    }
}
