<div class="tabla-grid" #tablaGrid>

  <ng-container *ngIf="verTabla">

    <!--PRIMERA FILA: DATOS ESTUDIANTES-->
    <div *ngFor="let student of rowStudent; let i = index"
      [ngStyle]="{'background-color': student.colorFondo, 'color': student.colorTexto}">
      <div class="celda sticky-left" *ngIf="i == 0"
        [ngStyle]="{'background-color': student.colorFondo, 'color': student.colorTexto}">
        &nbsp;
      </div>
      <div class="celda sticky-left" *ngIf="i == 1 && verAciertos"
        [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff'}">
        &nbsp;
      </div>
      <div class="avatar" *ngIf="i > 1 && !verFotos" nbTooltip="{{student.nameUser}}&nbsp;{{student.surnameUser}}"
        nbTooltipPlacement="top" (click)="viewStudent(student)"
        [ngStyle]="{'background-color': student.colorFondo, 'color': student.colorTexto, 'background-image': getUserAvatar(student.pictureUser)}">
      </div>
      <div class="celda text-title-avatar" *ngIf="i > 1 && verFotos"
        [ngStyle]="{'background-color': student.colorFondo, 'color': student.colorTexto}"
        nbTooltip="{{student.nameUser}}&nbsp;{{student.surnameUser}}" nbTooltipPlacement="top">
        {{ student.nameUser }}
      </div>
    </div>

    <!--SEGUNDA FILA: PORCENTAJE DE ACIERTO-->
    <div *ngFor="let acierto of rowAciertos; let j = index">
      <div class="celda sticky-left" *ngIf="j == 0 && verAciertos"
        [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff', }">
        %&nbsp;{{'COMPETITION.ACIERTOS' | translate}}
      </div>
      <div class="celda sticky-left" *ngIf="j == 1 && verAciertos"
        [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff'}">
        &nbsp;
      </div>
      <div class="celda" *ngIf="j > 1 && verAciertos" [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff'}">
        {{ acierto.porcentajeAciertosUser }}%
      </div>
    </div>

    <!--TERCERA FILA: TIEMPO TOTAL-->
    <div *ngFor="let tiempo of rowtiempo; let k = index">
      <div class="celda sticky-left" *ngIf="k == 0"
        [ngStyle]="{'background-color': tiempo.colorFondo, 'color': tiempo.colorTexto}">
        {{'GLOBALS.TOTALTIME' | translate}}
      </div>
      <div class="celda sticky-left" *ngIf="k == 1 && verAciertos"
        [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff'}">
        -
      </div>
      <div class="celda" *ngIf="k > 1" [title]="tiempo.tiempo"
        [ngStyle]="{'background-color': tiempo.colorFondo, 'color': tiempo.colorTexto}">
        <p class="m-0">{{ formatoTiempo(tiempo.tiempo) }}</p>
      </div>
    </div>

    <!--FILAS DE NODOS Y QUIZZES-->
    <div *ngFor="let row of bodyTable; let rowIndex = index" class="fila">
      <div *ngFor="let cell of row ; let cellIndex = index" [ngClass]="{'selected-row': rowIndex === selectedRowIndex}">
        <div class="celda sticky-left gap-1" *ngIf="cellIndex == 0" (click)="selectRow(rowIndex)"
          [ngStyle]="{'background-color': cell.colorFondo, 'color': cell.colorTexto}"
          [ngClass]="{'width-VerActividades': !verActividades, 'width-OcultarActividades': verActividades}">
          <p class="m-0" nbTooltip="{{cell.titleQuiz}}" nbTooltipPlacement="top">
            {{ cell.titleQuiz }}
          </p>          
          <nb-icon icon="eye-outline" *ngIf="rowIndex === selectedRowIndex" status="basic" ngbTooltip="{{ 'SIDEBAR.EXPXONTENIDO' | translate }}" (click)="viewElement(cell)"></nb-icon>
        </div>
        <div class="celda" *ngIf="cellIndex == 1 && verAciertos"
          [ngStyle]="{'background-color': '#b6c7fb', 'color': '#ffffff'}">
          <div *ngIf="cell.isNode == 1">
            {{ cell.titleQuiz }}
          </div>
          <div *ngIf="cell.isNode == 0">
            <div *ngIf="cell.porcentajeAciertoActividad < 100">
              &nbsp;{{ cell.porcentajeAciertoActividad }}%&nbsp;/&nbsp;{{ cell.totalRespuestas }}
            </div>
            <div *ngIf="cell.porcentajeAciertoActividad == 100">
              {{ cell.porcentajeAciertoActividad }}%&nbsp;/&nbsp;{{ cell.totalRespuestas }}
            </div>
          </div>
        </div>
        <div class="celda" *ngIf="cellIndex > 1" (click)="viewQuiz(cell)"
          [ngStyle]="{'background-color': cell.colorFondo, 'color': cell.colorTexto}">
          <div *ngIf="cell.isNode == 1" [title]="cell.titleQuiz">
            <p class="m-0">{{ formatoTiempo(cell.titleQuiz) }}</p>
          </div>
          <div *ngIf="cell.isNode == 0" class="d-flex flex-column"
            [title]="'--' + cell.nameUser + ' ' + cell.surnameUser + '--' + '\nAct: ' + cell.titleQuiz + '\nKnowledge: ' + cell.knowledge + '\nReps: ' + cell.repeticionesQuiz + '\nSOS: ' + cell.sos">
            <mat-icon *ngIf="cell.repeticionesQuiz > 0 && cell.colorFondo != '#61A1E9'"
              [svgIcon]="cell.result == 1 ? 'checknuevo' : 'equisCirculoBlanco'"></mat-icon>
            <mat-icon *ngIf="cell.repeticionesQuiz > 0 && cell.colorFondo == '#61A1E9'" class="icon-animate"
              svgIcon="act"></mat-icon>
            <div class="d-flex justify-content-center align-items-center flex-column gap-1"
              *ngIf="cell.repeticionesQuiz > 0 && verK">
              <p class="m-0 text-white" style="font-size: 12px; width: max-content;">
                {{cell.knowledge}} / {{cell.repeticionesQuiz}}
              </p>
              <mat-icon class="custom-icon" *ngIf="cell.sos > 0 && verK" svgIcon="sos"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

</div>