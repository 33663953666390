import { environment } from 'src/environments/environment';
import { PadModel, LabelModel } from './../models/pads/pad-label.model';
import { Injectable } from "@angular/core";
import { ImagenPipe } from "src/app/shared/pipes/imagen.pipe";
import { LoginService } from "../services/login";
import { LocalStorage } from "./local-storage";
import { CenterService } from '../services/center/center.service';
import { AccessZones } from '../models/masters/masters.enum';
import { LOCALSTORAGESTRINGS } from '../models/masters/localstorage.enum';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

const NOIMAGE = "../../../assets/images/icons/account_circle.svg"

@Injectable()
export class Utils{

    private _labelsStatus: LabelModel
    private _padsStatus: PadModel
		private _loadMenu: Boolean = true;
		private _showGifResponse: Boolean = true;

    public get padsStatus(): PadModel {
        return this._padsStatus;
    }
    public set padsStatus(value: PadModel) {
        this._padsStatus = value;
    }

    public get labelsStatus(): LabelModel {
        return this._labelsStatus;
    }
    public set labelsStatus(value: LabelModel) {
        this._labelsStatus = value;
    }

		public get loadMenu(): Boolean {
			return this._loadMenu;
		}
		public set loadMenu(value: Boolean) {
				this._loadMenu = value;
		}

		public get showGifResponse(): Boolean {
			return this._showGifResponse;
		}
		public set showGifResponse(value: Boolean) {
				this._showGifResponse = value;
		}


    public constructor( private imagePipe: ImagenPipe, private localStorage:LocalStorage, private login:LoginService, private centerService: CenterService) {}



    /**
     *
     * @param url
     * @returns
     */
    public getYoutubeId(url:string):string{
        try{
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?)|(shorts\/))\??(v=)?([^#\&\?]*).*/;
            const match = url.match(regExp);
                if (match && match[9].length == 11) {
            return match[9]
                } else {
                    return ''
                }

        }catch(err){
            return ''
        }
    }

		public getInstagramId(url:string):string{
			try{
				const regExp = /((?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel)\/([^/?#&]+)).*/
				const match = url.match(regExp);
				return (match && match[2].length == 11) ? match[2] : ''

			}catch(err){
					return ''
			}
		}

		public getTiktokId(url:string):string{
			try{
				const regExp = /(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)([\d]*)/;
				const match = url.match(regExp);
				return (match && match[3].length == 19) ? match[3] : ''
			}catch(err){
					return ''
			}
		}

		public getSpotifyId(url:string):string{
			try{
					const regExp = /^https:\/\/open\.spotify\.com\/(?:[^\/]+\/)?(?:[^\/]+\/)?([a-zA-Z0-9]+)$/;
					const match = url.match(regExp);
					return (match && match[1]) ? match[1] : '';
			}catch(err){
					return '';
			}
	}

    public hoursToSeconds(minuts:string):number{
        let values: string[] = []
        let seconds = 0
        try{
            values = minuts.split(':')
            return seconds = parseInt(values[0])*3600 + parseInt(values[1])*60 +  parseInt(values[2])

        }catch(err){
            return null
        }
    }

    public getUserAvatar(imageValue:string):string{
        let image:string = ''
        imageValue ? image = this.imagePipe.transform(imageValue, 'userAvatar') : image = NOIMAGE
        return `url('${image}')`
    }

		public getAgentAvatar(imageValue:string):string{
			let image:string = ''
			imageValue ? image = this.imagePipe.transform(imageValue, 'agent') : image = NOIMAGE
			return `url('${image}')`
	}

    public getPadsLabelsStatus():void{

        let vLabels:LabelModel = JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY))
        let vPads:PadModel = JSON.parse(this.localStorage.getItem(LOCALSTORAGESTRINGS.PADSLOCALCATEGORY))

        if(vPads === null){
            vPads = new PadModel(true, true, true)
            this.localStorage.setItem(LOCALSTORAGESTRINGS.PADSLOCALCATEGORY, JSON.stringify(vPads))
        }
        if(vLabels === null){
            vLabels = new LabelModel(true, true, true)
            this.localStorage.setItem(LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY, JSON.stringify(vLabels))
        }

        this.padsStatus = vPads
        this.labelsStatus = vLabels
    }

    public savePadsLabelsStatus(category:string):void{
        if(category === LOCALSTORAGESTRINGS.PADSLOCALCATEGORY)
            this.localStorage.setItem(category, JSON.stringify(this.padsStatus))
        else if(category === LOCALSTORAGESTRINGS.LABELSLOCALCATEGORY)
            this.localStorage.setItem(category, JSON.stringify(this.labelsStatus))
    }

    /**
     *
     * @param zone Zona que queremos consultar su acceso
     * @returns Devuelve un boleano, para permitir o no la visualización de la zona según el entorno y el id del usuario
     */
    getAccessZones(zone:string):boolean{

        let value:boolean = false
        let idUser:number = this.login.getUser().idUser

        //Si estamos en DESARROLLO, siempre tendremos acceso a todo
        if(!environment.production)
            value = true
        else
            //Recuperamos la zona a la que se está intentando acceder y miramos si está el id del usuario
            try{
                value = AccessZones.filter(e => e.name === zone)[0].validUsers.includes(idUser)
            }
            catch(e){
                value = false
            }
        return value
    }

		checkOnline$(): Observable<any> {
			return merge<any>(
				fromEvent(window, 'offline').pipe(map(() => false)),
				fromEvent(window, 'online').pipe(map(() => true)),
				new Observable((sub: Observer<boolean>) => {
					sub.next(navigator.onLine);
					sub.complete();
				}));
		}
}
