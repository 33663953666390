import { CoursesService } from 'src/app/core/services/courses';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/core/services/login';
import { PowerService } from 'src/app/core/services/power/power.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';
import { PadsUtils } from 'src/app/core/utils/pads.utils';
import { DialogService } from 'src/app/core/services/shared/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'src/app/core/utils';
import { SigmaCanvasService } from '../nodes/sigma-canvas/sigma-canvas.service';
import { ReportingComponent } from '../reporting/reporting.component';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { MastersService } from 'src/app/core/services/masters';

@Component({
    selector: "app-pad-buttons",
    templateUrl: "./pad-buttons.component.html",
    styleUrls: ["./pad-buttons.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PadButtonsComponent implements OnInit, OnDestroy {
	@Output() modeAuto = new EventEmitter<boolean>();
	@Input() isShow: boolean;
	@Input() isShowLabels: boolean = false;
	@Input() idCourse: number;
	@Input() courseName: string;
	@Input() alertCloseQuiz: boolean = false;
	@Input() viewQuiz?: boolean = false;

	_colors: Array<string> = ["grey", "red", "yellow", "lgreen", "dgreen"];

	_topButton: string = "";
	_leftButton: string = "";
	_rightButton: string = "check_graph";
	_bottomButton: string = "ActividadIdea";
	viewCopilotPadLeft: boolean;
	discoverModeOn: boolean = false;
	courseId: number;
	graphId: number;
	@Input() canEdit: boolean;
	showDiscoverButton: boolean;

	@Input() idCurso: number = 0;
	@Input() idMapa: number = 0;

	public esAutor() {
		return this.loginService.esAutor();
	}
	public esEstudiante() {
		return this.loginService.esEstudiante();
	}

	constructor(
		private toolsService: SigmaToolbarsService,
		private quizService: QuizzesService,
		public loginService: LoginService,
		private padsUtils: PadsUtils,
		private powerServ: PowerService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		public activeModal: NgbActiveModal,
		private localStorage: LocalStorage,
		private sigmaCanvasService: SigmaCanvasService,
		private modalService: NgbModal,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		this.powerChanged();
		this.subscribeProfile();
		// if (this.localStorage.getItem("discoverMode") != null) {
		// 	this.discoverModeOn = this.localStorage.getItem("discoverMode");
		// }
		if (this.loginService.esEstudiante()) this._bottomButton = "ActividadIdea";
		this.sigmaCanvasService._showDiscoverButton.subscribe(res =>{
			this.showDiscoverButton = res;
		})
	}

	discoverModeSwitch() {
		let auxDiscoverMode = !this.discoverModeOn;
		this.discoverModeOn = auxDiscoverMode;
		this.localStorage.setItem("discoverMode", auxDiscoverMode);
		this.sigmaCanvasService.setDiscoverModeOn(auxDiscoverMode);
	}

	powerChanged() {
		// this.powerServ.powerActive.subscribe((res) => {
		// 	this._bottomButton = "ActividadIdea";
		// });
	}

	subscribeProfile() {
		this.loginService.currentProfile.subscribe((val) => {
			this._bottomButton = "ActividadIdea";
		});
	}

	/** LEFT PAD */

	setKnowledge(color: string) {
		this._topButton = color;
		this.padsUtils.vibratePad();
	}

	public viewNodes() {
		this.toolsService.changeVisibility({ nodes: true, quizzes: false });
		this._bottomButton = "ideasvg";
		this.padsUtils.vibratePad();
	}

	public viewQuizzes() {
		this.toolsService.changeVisibility({ nodes: false, quizzes: true });
		this._bottomButton = "inte";
		this.padsUtils.vibratePad();
	}

	public viewAll() {
		this.toolsService.changeVisibility({ nodes: true, quizzes: true });
		this._bottomButton = "ActividadIdea";
		this.padsUtils.vibratePad();
	}

	selectAtomatic() {
		this.quizService.setAutomatic(true);
		this._rightButton = "automatic";
		this.modeAuto.emit(true);
		this.padsUtils.vibratePad();
		this.toolsService.setHideElements();
	}

	selectManual() {
		this.quizService.setAutomatic(false);
		this._rightButton = "manual";
	}

	emitshowModalCourse(value: string) {
		if (this.alertCloseQuiz == true) {
			const dialogRef = this.dialogService.openAcceptDialog(
				true,
				"¿Salir de la actividad?",
				this.translateService.instant("QUIZZES.CLOSEQUESTION"),
				[],
				this.translateService.instant("QUIZZES.EXIT"),
				this.translateService.instant("QUIZZES.RESPONSE")
			);

			dialogRef.afterClosed().subscribe((result) => {
				//this.tryClose.next(result);

				if (result) {
					this.activeModal.close();
					this.toolsService.changeShowModalCourses(value);
					this.padsUtils.vibratePad();
					this.toolsService.setHideElements();
				}
			});
		} else {
			this.toolsService.changeShowModalCourses(value);
			this.padsUtils.vibratePad();
			this.toolsService.setHideElements();
		}
	}

	ngOnDestroy() {}

	verInforme() {
		let group = JSON.parse(localStorage.getItem("selectedGroupInfo"));
		const modalRef = this.modalService.open(ReportingComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W90} h-100`,
		});
		modalRef.componentInstance.idGrupo = group.idGroup;
		modalRef.componentInstance.group = group;
		modalRef.componentInstance.claseInvertida = false;
		modalRef.componentInstance.practicar = false;
		modalRef.componentInstance.fromGraph = true;
		modalRef.componentInstance.idCourse_fromGraph = this.idCurso;
		modalRef.componentInstance.idTarget_fromGraph = this.idMapa;
		//Creamos el log
		let info = "Practicar Ideografo.";
		const bodyLog = {
			action: "practicar ideografo",
			actionData: "Ha entrado en el menú de " + info,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: group.idGroup,
		};
		this.masterService
			.createActivityHistory(
				bodyLog.action,
				bodyLog.actionData,
				bodyLog.startDate,
				bodyLog.endingDate,
				bodyLog.role,
				bodyLog.idGroup
			)
			.subscribe();
	}
}
