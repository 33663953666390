<div class="pad-center-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">
	<div class="cont-pad-center">
		<!-- boton que lleva a los proyectos -->
		<div class="d-flex align-items-center flex-column gap-1" [ngClass]="{'btn-select-style': selectedButton === 'proyectos'}">
			<div class="d-flex" (click)="goModule(gotoText, 'proyectos')">
				<mat-icon svgIcon="salware"></mat-icon>
			</div>
			<div class="not-showTitle-mobile">
				<p class="info-course">{{('PADSLABELS.SALWARE' | translate)}}</p>
			</div>
		</div>
		<!-- Flechas -->
		<div class="not-show-mobile">
			<mat-icon svgIcon="flecha_fina_white"></mat-icon>
		</div>
		<!-- Boton para ir al ideografo del proyecto seleccionado -->
		<div class="d-flex align-items-center flex-column gap-2" [ngClass]="{'btn-select-style': selectedButton === 'ideografo'}">
			<div class="d-flex" (click)="emitshowModalCourse('gridList', 'ideografo')">
				<mat-icon svgIcon="proyecto-3-grafos"></mat-icon>
			</div>
			<div class="not-showTitle-mobile">
				<p class="info-course">{{courseName}}</p>
			</div>
		</div>
		<!-- Flechas -->
		<div class="not-show-mobile">
			<mat-icon svgIcon="flecha_fina_white"></mat-icon>
		</div>
		<!-- Boton para ver todos los elementos -->
		<div class="d-flex align-items-center flex-column gap-1" [ngClass]="{'btn-select-style': selectedButton === 'todos'}">
			<div class="d-flex" (click)="viewAll()">
				<mat-icon svgIcon="graph"></mat-icon>
			</div>
			<div class="not-showTitle-mobile">
				<p class="info-course">{{graphTitle}}</p>
			</div>
		</div>
		<!-- Flechas -->
		<div class="not-show-mobile">
			<mat-icon svgIcon="flecha_fina_white"></mat-icon>
		</div>
		<!-- Boton para ver solo las ideas -->
		<div class="d-flex align-items-center flex-column gap-1" [ngClass]="{'btn-select-style': selectedButton === 'ideas'}">
			<div class="d-flex">
				<mat-icon svgIcon="IdeaVerde" (click)="viewNodes()" class="icon-color-blue round-icon"></mat-icon>
			</div>
			<div class="not-showTitle-mobile">
				<ng-container *ngIf="alertCloseQuiz; else nodeTitle">
					<p class="info-course">{{lessPowerNodeTittle | truncate:[40, '...']}}</p>
				</ng-container>
				<ng-template #nodeTitle>
					<p class="info-course">{{('PADSLABELS.IDEAS' | translate)}}</p>
				</ng-template>
			</div>
		</div>
		<!-- Flechas -->
		<div class="not-show-mobile">
			<mat-icon svgIcon="flecha_fina_white"></mat-icon>
		</div>
		<!-- Boton para ver solo las actividades -->
		<div class="d-flex align-items-center flex-column gap-1" [ngClass]="{'btn-select-style': selectedButton === 'actividades'}">
			<div class="d-flex">
				<mat-icon svgIcon="actividad_barra" (click)="viewQuizzes()" class="icon-actividad-barra"></mat-icon>
			</div>
			<div class="not-showTitle-mobile">
				<ng-container *ngIf="alertCloseQuiz; else quizTitle">
					<ng-container *ngIf="nodeView; else notNode">
						<p class="info-course">{{lessOrdinalQuizzTittle | titlecase | truncate:[40, '...']}}</p>
					</ng-container>
					<ng-template #notNode>
						<p class="info-course">{{quizTitleBar | titlecase | truncate:[40, '...']}}</p>
					</ng-template>
				</ng-container>
				<ng-template #quizTitle>
					<p class="info-course">{{('PADSLABELS.ACTIVIDADES' | translate)}}</p>
				</ng-template>
			</div>
		</div>
	</div>
</div>
