import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { Subscription } from 'rxjs';
import { QuizElementTypes } from 'src/app/core/models/quizzes/quiz-element-types.enum';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { HypermediaTypes } from '../../../quiz-hypermedia/hypermedia-types.enum';
import { QuizHypermediaComponent } from '../../../quiz-hypermedia/quiz-hypermedia.component';

@Component({
    selector: 'app-quiz-edit-body-data',
    templateUrl: './quiz-edit-body-data.component.html',
    styleUrls: ['./quiz-edit-body-data.component.scss'],
    standalone: false
})
export class QuizEditBodyDataComponent implements OnInit, OnChanges {
    @Input() questions: any[];
    @Output() saveData: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteData: EventEmitter<number> = new EventEmitter<number>();

    public dataForm: UntypedFormGroup;
    private lastQuestionValueChanges: Subscription;

    constructor(private fb: UntypedFormBuilder, private modalService: NgbModal) {
        this.dataForm = this.fb.group({
            questions: this.fb.array([])
        });
    }

    ngOnInit() {
        if (this.questionsArray.controls.length <= 0) {
            this.addQuestion();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.questions && changes.questions.currentValue && changes.questions.currentValue.length > 0) {
            this.questionsArray.clear();
            this.questions.forEach(q => {
                this.addQuestion(q);
            });
            this.addQuestion();
        }
    }

    public get questionsArray(): UntypedFormArray {
        return this.dataForm.get('questions') as UntypedFormArray;
    }

    public get questionsArrayData(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('data') as UntypedFormControl);
    }

    public get questionsArrayType(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('type') as UntypedFormControl);
    }

    public get questionsArrayWidth(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('width') as UntypedFormControl);
    }

    public get questionsArrayHeight(): UntypedFormControl[] {
        return this.questionsArray.controls.map(q => q.get('height') as UntypedFormControl);
    }

    public get elementTypes() {
        return QuizElementTypes;
    }

    public onTextBlur(index: number, onUpdate?:boolean) {
        if (this.dataForm.valid && this.questionsArray.controls[index].get('originalData').value !== this.questionsArray.controls[index].get('data').value || onUpdate ) {
            this.saveData.emit({ element: this.questionsArray.controls[index].value });
        }
    }

    public onOpenHypermedia(event: MouseEvent, index: number) {
        event.stopPropagation();
        const currentControl = this.questionsArray.controls[index];

        const modalRef = this.modalService.open(QuizHypermediaComponent,
            {
                scrollable: true,
                windowClass: MODAL_DIALOG_TYPES.W95
            }
        );

        modalRef.componentInstance.fileSelected.subscribe(data => {
            modalRef.close();
            const id = currentControl.get('id').value;

            currentControl.get('data').patchValue('');
            currentControl.get('type').patchValue(this.mapFileTypeToElementType(data.type));
            this.saveData.emit({ element: currentControl.value, file: data.file });
        });
    }

    private mapFileTypeToElementType(fileType: HypermediaTypes): QuizElementTypes {
        switch (fileType) {
            case HypermediaTypes.IMAGE:
                return QuizElementTypes.Q_PICTURES;
            case HypermediaTypes.VIDEO:
                return QuizElementTypes.Q_VIDEOS;
            case HypermediaTypes.AUDIO:
                return QuizElementTypes.Q_AUDIOS;
            case HypermediaTypes.PDF:
                return QuizElementTypes.Q_PDFS;
            default:
                return QuizElementTypes.Q_TEXTS;
        }
    }

    private addQuestion(question?: any): void {
        const newQuestion: UntypedFormGroup = this.createQuestion(question);
        this.questionsArray.push(newQuestion);

        if (this.lastQuestionValueChanges) {
            this.lastQuestionValueChanges.unsubscribe();
        }

        this.lastQuestionValueChanges = newQuestion.get('data').valueChanges.subscribe(value => {
            if (value) {
                this.addQuestion();
            }
        });
    }

    public deleteQuestion(index: number): void {
        const id: number = this.questionsArray.controls[index].get('id').value;
        if (id) {
            this.deleteData.emit(id);
            if (this.questionsArray.controls.length === 2) {
                this.questionsArray.removeAt(index);
            }
        } else {
            this.questionsArray.removeAt(index);
        }
    }

    private createQuestion(question?: any): UntypedFormGroup {
        return this.fb.group({
            id: question ? question.idQuizzesDataElements : '',
            auxId: this.questionsArray.controls.length,
            data: question ? question.data : '',
            originalData: question ? question.data : '',
            type: question ? question.elementType : QuizElementTypes.Q_TEXTS,
            order: question ? question.yPosition : Math.max.apply(null, this.questionsArray.controls.map(c => c.get('order').value)) + 1,
            width: question ? question.xSize : 0,
            height: question ? question.ySize : 0
        });
    }


    public onResizeStop(event:IResizeEvent, index:number){

        //Set the width and the height of the image
        let width:number = event.size.width
        let height:number = event.size.height

        //Update the height and the width
        this.questionsArray.controls[index].get('width').setValue(width)
        this.questionsArray.controls[index].get('height').setValue(height)

        //Call to function to update the data in the backend
        this.onTextBlur(index, true)
    }

    trackBy(index, item) {
        return item.get('auxId').value;
    }
}
