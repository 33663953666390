<nb-card class="mb-0 no-edge-card modal-height-100-62">
	<nb-card-header class="flex border-bottom-0">
		<div class="col-10 modal-title">
			<h2>{{ "SETTINGS.EDITORTITLE" | translate }}</h2>
		</div>
	</nb-card-header>
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle"
			ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left" [options]="{ animation: { type: 'zoom' } }" (click)="closeModal('')">
		</nb-icon>
	</div>
	<nb-card-body>
		<div class="teacher-settings p-2" [hidden]="isLoading">
			<div class="col-12">
				<app-alert context="UserData"></app-alert>
			</div>

			<div class="row" *ngIf="!centersAssignedToUser?.data.length">
				<div class="col-12 px-4 py-2">
					<ngb-alert type="info" [dismissible]="false">{{"TEACHERSETTINGS.NOTCENTERSASSOCIATED" | translate}}</ngb-alert>
				</div>
			</div>
			<div class="row">
				<div
					class="col-12 px-4 py-2"
					[hidden]="!centersAssignedToUser?.data.length"
				>
					<div class="mat-elevation-z8">
						<table mat-table [dataSource]="centersAssignedToUser">
							<!-- Center Name -->
							<ng-container matColumnDef="centerName">
								<th mat-header-cell *matHeaderCellDef>
									{{ "TEACHERSETTINGS.CENTERNAME" | translate }}
								</th>
								<td mat-cell *matCellDef="let element">
									{{ element.centerName }}
								</td>
							</ng-container>

							<!-- Province -->
							<ng-container matColumnDef="province">
								<th mat-header-cell *matHeaderCellDef>
									{{ "TEACHERSETTINGS.PROVINCENAME" | translate }}
								</th>
								<td mat-cell *matCellDef="let element">
									{{ element.province }}
								</td>
							</ng-container>

							<!-- Deleted icon -->
							<ng-container matColumnDef="delete">
								<th mat-header-cell *matHeaderCellDef>
									{{ "TEACHERSETTINGS.DELETEASSIGNMENT" | translate }}
								</th>
								<td mat-cell *matCellDef="let element; let i = index">
									<button
										mat-icon-button
										color="primary"
										(click)="deleteCenterToUser(i, element.idCenter)"
										ngbTooltip="{{'TEACHERSETTINGS.DELETEASSIGNMENT' | translate }}">
										<mat-icon class="pointer color-red-text">delete</mat-icon>
									</button>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
						</table>
						<mat-paginator [pageSize]="[5]"></mat-paginator>
					</div>
				</div>
				<div class="col-12 py-2 px-4 d-flex justify-content-center align-center">
					<button nbButton class="btn-themecolor" size="small" (click)="openModalCenters()">
						<span>{{ "TEACHERSETTINGS.BUTTONASSIGNMENT" | translate }}</span>
					</button>
				</div>
			</div>
		</div>
	</nb-card-body>

</nb-card>
