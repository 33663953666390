<nb-card class="no-edge-card">
	<nb-card-header class="flex border-bottom-0 position-relative">
		<div class="col-12 col-sm-10 col-md-10 text-center text-sm-left text-md-left">
			<h5>{{ "USERDATA.CONTACTTITLE" | translate }}</h5>
		</div>
		<div class="closeModal">
			<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
				(click)="closeModal('')" ngbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" placement="left">
			</nb-icon>
		</div>
	</nb-card-header>

	<nb-card-body>
		<div class="row d-flex justify-content-center cards-container">
			<div class="col-12 col-md-12">
				<div class="row d-flex justify-content-center align-items-center p-2">
					<div class="col-12 col-md-12">
						<div class="col-12 col-md-12">
							<div class="form-group">
								<label for="comentary">
									{{"USERDATA.COMMENTARY" | translate}}
								</label>
								<textarea nbInput fullWidth [(ngModel)]="comentary" name="comentary"
									class="form-control" id="comentary" rows="3" maxlength="500"></textarea>
							</div>
						</div>
						<div class="row justify-content-between">
							<nb-checkbox status="primary" [(ngModel)]="shareMail" name="shareMail">
								{{ "USERDATA.SHAREMAIL" | translate }}
							</nb-checkbox>
						</div>						
					</div>
					<div class="col-12 col-md-12 d-flex justify-content-end">
						<button nbButton class="btn-themecolor" (click)="sendComments()"
							[ngClass]="{
								'disabled' : comentary.length < 10,
							}"
							[disabled]="comentary.length < 10">
							<span>{{ "USERDATA.SEND" | translate }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
	
</nb-card>