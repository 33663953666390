import { DEFAULTFONTFAMILY } from '../../../../core/models/masters/masters.enum';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/core/services/shared/utils.service';

@Component({
    selector: 'app-preview-quiz-play-body-info',
    templateUrl: './preview-quiz-play-body-info.component.html',
    styleUrls: ['./preview-quiz-play-body-info.component.scss'],
    standalone: false
})
export class QuizPreviewBodyInfoComponent implements OnInit {
    @Input() quiz: any;
    @Input() timer$: number = 4;

    public infoForm: UntypedFormGroup;

    @ViewChild('canvas', { static: true }) private clockCanvas: ElementRef;
    constructor(private fb: UntypedFormBuilder, private utilsService: UtilsService) {
        this.infoForm = this.fb.group({
            title: [{ value: '', disabled: true }],
            duration: [{ value: '', disabled: true }],
            instructions: [{ value: '', disabled: true }],
        });
    }

    ngOnInit() {
        this.infoForm.get('title').patchValue(this.quiz.quizTittle);
        this.infoForm.get('instructions').patchValue(this.quiz.quizInstructions);
        this.infoForm.get('duration').patchValue(this.utilsService.minutesToTime(this.quiz.duration));
    }

    private get2dCanvas(canvas) {
        if (canvas) {
            var lienzo = canvas.getContext('2d');
            return lienzo;
        } else {
            return false;
        }
    }

    private paintClock(currentTime, maxSeconds: number): void {
        var lienzo = this.get2dCanvas(this.clockCanvas.nativeElement);

        if (lienzo) {
            var segundo = currentTime.minutes * 60 + currentTime.seconds;
            var radio = 25;
            // Defino límites en pí­xeles
            var minx = 10;
            var maxx = 45;
            var miny = 10;
            var maxy = 45;
            // Centro del recuadro
            var cx = minx + (maxx - minx) / 2;
            var cy = miny + (maxy - miny) / 2;
            // Borrar lienzo
            lienzo.clearRect(0, 0, 300, 300);
            lienzo.strokeStyle = "rgb(124,161,254)";
            lienzo.lineWidth = 3;
            // Dibujar el círculo del reloj
            lienzo.beginPath();
            lienzo.arc(cx, cy, radio, 0, Math.PI * 2, false);
            lienzo.stroke();
            // Dibujar los segundos restantes
            lienzo.beginPath();
            lienzo.fillStyle = "rgb(0,0,0)";
            lienzo.moveTo(cx, cy); // posiciono el lápiz en el centro del recuadro
            var angulo = 2 * Math.PI * (segundo / maxSeconds); // calculo el ángulo del segundero
            lienzo.arc(cx, cy, radio, -Math.PI / 2, -Math.PI / 2 - angulo, false);
            lienzo.lineTo(cx, cy);
            lienzo.fill();
            //Mostrar la hora
            lienzo.beginPath();
            lienzo.font = "12pt " + DEFAULTFONTFAMILY
            //lienzo.fillStyle = "rgb(255,255,255)";
            lienzo.fillStyle = "rgb(117, 108, 108)";
            var minute = (currentTime.minutes <= 9) ? "0" + currentTime.minutes : currentTime.minutes;
            var second = (currentTime.seconds <= 9) ? "0" + currentTime.seconds : currentTime.seconds
            lienzo.fillText(minute + ":" + second, (cx / 2) - 10, cx + 5);
            //color de fonde de lienzo
            //lienzo.beginPath();
            //lienzo.fillStyle = "rgb(255,255,255)";
        }
    }
}
