import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-dialog',
    templateUrl: './privacy-dialog.component.html',
    styleUrls: ['./privacy-dialog.component.scss'],
    standalone: false
})
export class PrivacyDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
