import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { AppAccessZone } from './app-access-zones.model';
//export const APIURL:string = 'https://api.salware.net'
//export const CDNURL:string = 'https://cdn.salware.net'
export const APIURL:string = 'https://salware.com'
export const APIURLLOCAL:string = 'http://localhost:8082'
export const IMAGESASSETS:string = '/assets/images/'
export const CAPTCHAAPIKEY:string = '6Lfkd20gAAAAABesT1OjJ8FzxyQkiRsZBl9V__H_'
export const DEFAULTMOBILEPREFIX:string = '+34'
export const DEFAULTFONTFAMILY:string = 'Poppins'
export const DEFAULTCLOSEPADS: number = 600000
export const DEMOUSER = { USER: '700000000', PASS : '12345678' }
export const TINYAPYKEY: string = 've3uuc3idtgi6w1an4rfakpqbubya9ih6gibkr8jd3fmpfbn'
export const URL_TIKTOK = "https://www.tiktok.com/embed/v2/"
export const API_KEY_CHAT_GTP = "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";
export const PINECONE_API_KEY = "de640230-af55-46b0-8dda-6c973a949b3f";

export const PROMPT_AGENT_GPT_INITIAL:string = `"¡Hola! Soy tu profesor virtual favorito, especializado en Ciencias, Matemáticas, Historia, . ¡Estoy aquí para hacerte aprender y comprender conceptos de la manera más divertida y fácil posible!

Información del Estudiante: la información de el estudiante se te proporcionara mas adelante en un formato json
Características Clave
Personalización Top: ¡Me sé tu nombre y tu nivel de aprendizaje! Mis explicaciones y recomendaciones serán hechas a tu medida.
Claridad Cristalina: ¡Adiós a los conceptos confusos! Te explicaré todo con ejemplos y analogías que te harán decir "¡Ajá!".
Paciencia Infinita: ¡Pregunta sin miedo! No importa cuántas veces necesites que te explique algo. ¡Estoy aquí para ayudarte!
Motivación a Tope: ¡Aprender es un juego! Te animaré a seguir adelante y superar cualquier desafío. ¡Vamos tú puedes!
Recomendaciones Personalizadas: ¡Sé lo que necesitas! Usaré la información de la aplicación para recomendarte recursos y actividades que te harán un experto.
Seguimiento de Campeón: ¡No se me olvida nada! Recordaré tus preguntas anteriores y seguiré tu progreso para que no te pierdas ni un detalle.
Instrucciones Adicionales
Respuestas Detalladas: Cuando me hagas una pregunta, te responderé hasta el último detalle, explicándote el concepto de diferentes maneras y con ejemplos que te encantarán.
Recursos Extra: Si te atoras en un tema, ¡no te preocupes! Te daré videos, artículos y ejercicios para que te conviertas en un crack.
Plan de Estudio Personalizado: ¿No sabes por dónde empezar? ¡Yo te ayudo! Te recomendaré un plan de estudio basado en tus necesidades y progreso.
Tono Amigable: ¡Soy tu amigo virtual! Te hablaré de manera cercana y divertida, pero siempre con respeto. ¡Nada de jerga técnica aburrida!
Mi Objetivo: ¡Tu éxito! Quiero que aprendas y te diviertas al mismo tiempo. ¡Juntos lo lograremos!
Para la API de TTS (Text-to-Speech)
Pausas: Utiliza pausas naturales en las oraciones para que la voz suene más humana. Puedes usar comas, puntos y puntos suspensivos para indicar pausas.
Expresiones: Incluye expresiones como "¡Ajá!", "¡Wow!", "¡Genial!", "¡Exacto!", etc. para darle más emoción a la voz.
Sonidos: Puedes agregar sonidos onomatopéyicos como "¡Boom!", "¡Clic!", "¡Ding!", etc. para hacer la conversación más dinámica.
Entonación: Varía la entonación de la voz para que no suene monótona. Puedes usar signos de exclamación e interrogación para indicar diferentes tonos.`


export const PROMPT_SHAPE_GENERATIONS:string = `
Estoy desarrollando un nuevo sistema educativo y he innovado en cómo presentar el contenido educativo de forma gráfica.

Quiero que actúes como un psicólogo especialista en neuroeducación que es capaz de pensar en términos orgánicos y de emociones. Debes ser capaz de visualizar las ideas, no de forma textual sino como un concepto mental que se puede dibujar.

Quiero que estudies la materia que te voy a dar y que me recomiendes una o varias formas de explicar esos contenidos gráficamente de acuerdo con estas indicaciones que te voy a dar. No juzgues si la materia que te doy está bien o mal, simplemente acepta que esto es lo que tengo que enseñar a los alumnos.

Lo primero que quiero es que entiendas que un mismo concepto o contenido se puede explicar de muchas formas, todas ellas válidas, pero cada una de esas formas tiene unas ventajas y unos inconvenientes.
Tradicionalmente el conocimiento se ha expresado a través de libros o textos académicos o artículos científicos. Es una forma de expresar los conceptos muy precisa que no da lugar a error y puede transmitir una alta complejidad de los contenidos. Esta forma es la más segura para un académico porque no se equivoca y porque es la tradicional.
Nuestro objetivo no es ser precisos, porque nuestros alumnos no son científicos, sino estudiantes de secundaria tomen una idea general visual de cómo es un tema, en qué ideas se separa y que pueda asociar el contenido con algo que tenga sentido visual.  No importa que no sea ortodoxa la imagen, sino que rápidamente la asocien con el tema y les sea fácil el recuerdo.

Hemos diseñado un software que dibuja mapas mentales (que normalmente son en estrella) y que los unimos también por sus nodos formando si es preciso una malla, una línea, un círculo, etc., Es decir, no tiene las limitaciones de un mapa mental. A este tipo de mapas mentales les hemos bautizado como IDEOGRAFOS, porque representan ideas en los nodos. Posteriormente de estas ideas saldrán preguntas que tendrán que resolver los estudiantes.

Toma las ideas que estaran al final del todo en forma de array, analízalo en términos visuales y emocionales y elige de la siguiente tabla que también te adjunto, el tipo de Ideografo que crees que más se corresponde para representar las ideas generales del tema que te he introducido. Dame varias opciones ordenadas por idoneidad y dime por qué has elegido cada una. Para cada opción que me propongas, dime el número de la primera columna y el tipo. He ordenado la tabla de propuestas por orden de mi preferencia, de tal forma que he puesto primero aquellas que creo que son más fáciles para recordar y asociar de la forma que hace el cerebro. Las he ordenado según mi criterio, pero si encuentras alguna que no es de las primeras y que encaja perfectamente en cómo explicar el tema, propónmela.

Si tienes dudas, no se trata de que hagas un enfoque más atractivo o más sencillo, sino de que el cerebro identifique bien los aspectos del tema y sea capaz después de estudiarlo de recordar perfectamente lo que había en el grafo.
El software sólo permite gráficos estáticos que se presentan en pantalla de una vez. Luego el software permite ir enfocándose en ideas consecutivas según el profesor va explicando cada idea.
Para cada una de las tres opciones que me propongas es obligatorio que me hagas una lista de cuál serían los títulos de las ideas principales y su desglose en ideas hijas, nietas, etc. de acuerdo con el tema, sin dar ejemplos, sino textos concretos sacados del tema. Pon en cada propuesta la ideas por orden de importancia según tu criterio, y ordénalas según se vayan desglosando unas de las otras hasta que estén todas incluidas.
Enfócate lo más posible en la información que hay en el documento porque no podemos ampliar la materia, pero si ves algo que puedes poner añadido que ayuda a entender o a motivar a los estudiantes, añádelo.

Tampoco podemos dejar de meter información que viene en el documento porque es el currículum oficial de las leyes educativas del país, pero ten en cuenta que para dibujar un ideograma no hay que entrar en detalle. Luego en cada idea, que será un nodo del ideograma, se van a explicar los detalles, pero esos detalles no van a influir en la estructura general del ideografo.

No sólo tienes que usar los tipos de ideografos de la tabla, sino que puedes combinarlos, por ejemplo, puedes usar una secuencia y de cada nodo puedes sacar un árbol, o usar una estructura cíclica y en cada nodo hacer una pequeña estructura jerárquica.

Piensa que un ideografo debe tener entre 5 y 20 nodos, no más porque si ponemos pocos no hay Ideografo, y si ponemos muchos es tan complejo que no se entenderá a simple vista y será demasiado complicado.

Un ideografo consume una pantalla de ordenador normal y se tiene que ver suficientemente sin hacer zoom.



La información que necesitas para elegir la forma del ideografo es esta:


SALWIZAR ESTRUCTURA DE UN IDEOGRAFO
(Proceso por el que el contenido se pretende ajustar más a la estructura del cerebro)

Un ideografo tiene una forma gráfica que está compuesta por Ideas y asociaciones entre ellas. Una asociación es una línea que simboliza un predicado que une dos ideas y explica al estudiante cómo se va de una idea a otra. El conjunto forma visualmente el dibujo del ideografo.

Para usar el cerebro humano de la forma que es más productivo utilizamos la Torre de Operadores, que no se explica en profundidad aquí, y que básicamente establece un orden para buscar explicaciones didácticas para dar prioridad a aquellas formas de representar la información que mejor procesa el cerebro. Para esto, seguimos un orden en el intento de explicar:

Primero intentamos asociar las ideas por los sentidos, si lo vemos difícil por nuestra actividad motora y si lo vemos difícil por las emociones que despierta el tema a explicar.
Segundo, si no podemos explicarlo al nivel primero buscamos cualquier tipo de asociación.
Tercero, si no podemos lo intentamos explicar introduciendo el tiempo como secuencia, o como causa y consecuencia.
Cuarto, si no podemos recurrimos a la abstracción, que es más potente pero más lenta y se recuerda menos.



OPCIONES DE FORMAS BÁSICAS DEL IDEOGRAFO

LO QUE VOY A CONTAR
“TIPO DE ASOCIACIÓN”
FORMA DEL IDEOGRAFO
    1.
El contenido se puede asociar a una imagen que lo ilustra: mapa, foto, diagrama, plano, paisaje, foto de acción, etc.
Imagen, foto.
Ilustración
    2.
Se puede organizar en características del tema. Es una descomposición
Jerarquía
Estrella con ramas (como un mapa mental)
    3.
Cuando algo parte de otra cosa. Es una descomposición
Jerarquía
Lo principal abajo: Árbol con las ramas para arriba
    4.
Se trata de esquematizar: Ramas, esquema,… Es una descomposición
Jerarquía
Lo principal a la izquierda: Desglose de forma que hacia la derecha se amplían las ramas o subramas.
    5.
Conclusiones o consecuencias.
Jerarquía
Lo principal arriba: Raíz
    6.
Muchas cosas que concluyen o se resumen en una sola
Jerarquía
Conclusiones resumen:  a la derecha
    7.
Se trata de un proceso que se repite siempre igual.
Cíclica
Círculo, óvalo o circuito (de carreras)
    8.
Una Idea que representa un sitio físico se relaciona con la siguiente hasta completar el espacio físico que trata el tema.
Secuencia física lineal
Línea que une sitios físicos
    9.
Es una secuencia temporal inespecífica o general. (sólo si una cosa ocurre antes que otra en cadena)
Secuencia temporal
Línea (temporales siempre)
Si me cabe, horizontal
    10.
Es una secuencia temporal pero que consigue mejora o ir más allá. (sólo si una cosa ocurre antes que otra en cadena)
Secuencia temporal
Rampa hacia arriba
    11.
Hacia arriba si es algo que mejora o avanza en el tiempo. (sólo si una cosa ocurre antes que otra en cadena)
Secuencia temporal
Rampa descendente. Esta rampa es la que mejor se lee porque nuestra escritura es de arriba abajo y de izquierda a derecha.
    12.
Cuando es una secuencia, pero no nos cabe en una sola línea (sólo si una cosa ocurre antes que otra en cadena)
Secuencia temporal
Serpiente

    13.
Cuando no nos cabe en una sola línea, pero llegamos a un fin concreto y central. (sólo si una cosa ocurre antes que otra en cadena)
Secuencia temporal
Caracol
    14.
Se trata de un proceso que se repite siempre igual.
Cíclica
Círculo, óvalo o circuito (de carreras)
    15.
Una Idea que representa un sitio físico se relaciona con la siguiente hasta completar una figura cerrada y así sucesivamente con todo el tema.
Secuencia física cerrada
Círculo, óvalo o circuito (de carreras)
    16.
Las ideas son de importancia parecida y tienen mucha interrelación entre ellas.
Este tipo de Ideografo es complejo de entender, y sólo conviene hacerlo cuando son pocas ideas. En otro caso, mejor no usarlo.
Interrelación. Malla
Malla (jugar con grosores de línea distintos).
    17.
Cuando es indiferente el orden y no hay nada más importante que otro
Anarquía
Salpicado distribuido uniformamente, (splittered)
    18.
Cuando es algo complejo sin sentido
Anarquía
Anárquico no uniformemente distribuido
    19.
Cuando importa más que sea atractivo al contenido concreto
Estético
Artístico (sobre fondo con significado, etc.)
    20.
Cuando el concepto es mezcla de los anteriores
Mixto
El ideografo tiene una forma en la que hay partes de un tipo y partes de otro, que pueden estar conectados o no.
    21.
Cuando después de una repetición se llega al fin esperado. (sólo si una cosa ocurre antes que otra en cadena)
Secuencia  temporal
Muelle
    22.
Cuando se trata de un desafío que sólo tiene una o ninguna idea.
Desafío
Sin ideas. No hay estructura. Sólo hay actividades.

Por ultimo aclarar que a tu respuesta tambien debes generarme un JSON con las 3 formas que hayas elegido con la siguiente estructura:
Donde Estructura_Ideas es un array con las ideas que has generado en el orden que has considerado necesario para explicar el tema, y ademas con la estuctura de la forma que has elegido.
siendo que si es jerarquica tendra tanto ideas como sub-ideas y si es lineal solo tendra ideas.
el codigo es un numero que identifica la forma que has elegido
basado en la tabla anterior.
{
		"Tipo_Forma": "Jerarquía en estrella",
		"codigo": number,
		"Razon": "Razon por la que se ha elegido esta forma",
		"Estructura_Ideas": "Estructura de las ideas en forma de array"
}

`


export const initialGraphsDEV = [889797, 888958];
export const initialGraphsPRO = [889797];


export const DEFAULT_TEACHER_PROMPT:string = 'Para evaluar tu decisión\nNo consideres faltas de ortografía\nNo consideres espacios ni signos de puntuación.\nLas respuestas con abreviaturas son equivalente a las respuestas sin abreviaturas.'

export enum UrlWebDomain{
	LOCAL = 'http://localhost:4200/',
	DEV = 'https://dev.salware.com',
	PRE = 'https://pre.salware.com',
	DEMO = 'https://demo.salware.com',
	TEST = 'https://test.salware.com',
	PRO = 'https://salware.com',
	PSYCAT = 'https://psycast.salware.com',
	FOUNDATION = 'https://app.fundacionsalware.org'
}

/**
 * Versiones de la app
 */
export enum AppVersions{
	PROD = '1.01.48',
	API = '4.05.39',
}


/**
 * Centros de la aplicación
 */
export enum DomainTypes{
    DEV = 'dev',
    LOCAL = 'local',
    SALWARE = 'salware',
    OMS = 'oms',
    FOUNDATION = 'fundacionsalware',
    PRE = 'pre',
    DEMO = 'demo',
	PSYCAST = 'psycast'
}


/**
 * Carpetas de las imágenes según el entorno en el que nos encontremos
 */
export enum ImageEnvironments{
    DEV = '/images-dev',
    PRO = '/images',
    DEMO = '/images-demo'
}


/**
 * Entornos de la api
 */
export enum ApiEnvironments{
    LOCAL = '/api/rest/',
    DEV = '/api-dev/rest/',
    TEST = '/api-dev-pruebas/rest/',
    PRO = '/api/rest/',
    DEMO = '/api-demo/rest/'
}

export enum WebSocketEnvironment{
	LOCAL = '/api/websocket/',
	DEV = '/api-dev/websocket/',
	TEST = '/api-dev-pruebas/websocket/',
	PRO = '/api/websocket/',
	DEMO = '/api-demo/websocket/'
}

/**
 * Imágenes de fondo del login según los centros creados
 */
export enum LoginImage{
    DEV = 'fondoLogin.png',
    PRO = 'fondoLogin.png',
    OMS = 'fondoLogin.png',
    PRE = 'fondoLogin.png',
    DEMO = 'fondoLogin.png'
}

/**
 * Direccion de documentos de politicas de privacidad según los centros creados
 */
export enum PrivacyPolicies{
	FOUNDATION = 'politica_de_privacidad_salware.pdf',
	PSYCAST = 'politica_de_privacidad_psycast.salware.pdf'
}

/**
 * Logos de salware según los centros creados
 */
export enum HomeLogo{
	FOUNDATION = 'logofundacionsalware.png',
	SALWARE = 'logo-azul.png'
}

/**
 * Registramos las zonas de la app donde vamos a poner restricciones
 */
export enum AppZones{
    QUIZMULTIPLEBUTTON = 'multipleQuizzesButton'
}


/**
 * Aqui tenemos las zonas con los ids de acceso en cada una de las zonas/botones que creamos
 */
export const AccessZones: AppAccessZone[] = [
    {
        id: 1,
        name: AppZones.QUIZMULTIPLEBUTTON,
        validUsers : [62,63,64,87] //Ids de los usuarios que tienen acceso a la zona
    }
]

export const nodePositionsCircular: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: 126, y: -487 },
			{ x: -19, y: -365 },
			{ x: 7, y: -156 },
			{ x: 280, y: -149 },
			{ x: 291, y: -372 }
		]
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 },
			{ x: 271, y: -122 },
			{ x: 454, y: -185 },
			{ x: 580, y: -295 },
			{ x: 601, y: -452 },
			{ x: 506, y: -603 }
		]
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 },
			{ x: 271, y: -122 },
			{ x: 454, y: -185 },
			{ x: 580, y: -295 },
			{ x: 601, y: -452 },
			{ x: 506, y: -603 },
			{ x: 355, y: -685 },
			{ x: 189, y: -658 },
			{ x: 54, y: -551 },
			{ x: -3, y: -406 },
			{ x: 94, y: -223 }
		]
	}
]


export const nodePositionsRectangular: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: 1, y: -593 },
			{ x: 1, y: -195 },
			{ x: 727, y: -195 },
			{ x: 727, y: -593 },
			{ x: 368, y: -593 },
		],
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
			{ x: 724, y: -183 },
			{ x: 724, y: -362 },
			{ x: 724, y: -523 },
			{ x: 535, y: -535 },
			{ x: 271, y: -535 },
		],
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
			{ x: 724, y: -183 },
			{ x: 724, y: -362 },
			{ x: 724, y: -523 },
			{ x: 535, y: -535 },
			{ x: 271, y: -535 },
			{ x: 15, y: -537 },
			{ x: 15, y: -372 },
			{ x: 15, y: -195 },
			{ x: 264, y: -183 },
			{ x: 529, y: -183 },
		],
	},
];

export const nodePositionsAscendentLinear: any[] = [
	{
		numberOfIdeas: 5,
		positions: [
			{ x: -168, y: -53 },
			{ x: 126, y: -200 },
			{ x: 421, y: -336 },
			{ x: 704, y: -489 },
			{ x: 989, y: -631 },
		],
	},
	{
		numberOfIdeas: 10,
		positions: [
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
			{ x: 722, y: -507 },
			{ x: 877, y: -602 },
			{ x: 1025, y: -697 },
			{ x: 1147, y: -801 },
			{ x: 1304, y: -934 },
		],
	},
	{
		numberOfIdeas: 15,
		positions: [
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
			{ x: 722, y: -507 },
			{ x: 877, y: -602 },
			{ x: 1025, y: -697 },
			{ x: 1147, y: -801 },
			{ x: 1304, y: -934 },
			{ x: -298, y: -30 },
			{ x: -48, y: -130 },
			{ x: 172, y: -237 },
			{ x: 375, y: -339 },
			{ x: 559, y: -426 },
		],
	},
];

