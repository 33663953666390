<nb-card class="no-edge-card mb-0 modal-height-100-62" *ngIf="initialData">
	<div class="closeModal">
		<nb-icon class="m-1" icon="close-circle" [options]="{ animation: { type: 'zoom' } }"
			(click)="closeModal('')" nbTooltip="{{ 'SIGMACOMPONENT.CERRAR' | translate }}" nbTooltipPlacement="left">
		</nb-icon>
	</div>
	<nb-card-header class="border-bottom-0 background_themecolor">
		<div class="container">
			<div class="px-2 px-sm-5 py-2">
				<div class="d-flex justify-content-between gap-4 container-item-small">
					<!-- Inteligencia artificial -->
					<div class="item-style item-small">
						<h6>{{ "CENTROS.ARTIFICIALINTELLIGENCE" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/inteligencia artificial.png" width="70" alt='{{ "CENTROS.ARTIFICIALINTELLIGENCE" | translate }}'>
						</div>
					</div>
					<!-- Datos Globales -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.DASHBOARDSUB1' | translate }}"
						nbTooltipPlacement="top" (click)="openTable()">
						<h6>{{ "MENUACTIONS.DASHBOARD1" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/global.png" width="50" />
						</div>
					</div>
					<!-- Datos Individuales -->
					<div class="item-style item-small" nbTooltip="{{ 'MENUACTIONS.DASHBOARDSUB2' | translate }}"
						nbTooltipPlacement="top" (click)="openTableIndividual()">
						<h6>{{ "MENUACTIONS.DASHBOARD2" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/pyme.png" width="50" />
						</div>
					</div>
					<!-- Estructura -->
					<div class="item-style item-small" (click)="openModalStructure()">
						<h6>Estructura</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/estructura-de-organizacion.png" width="50" />
						</div>
					</div>
					<!-- Tutorial -->
					<div class="item-style item-small" (click)="openModalTutorial()">
						<h6>{{ "COPILOTTUTORIAL.TUTORIAL" | translate }}</h6>
						<div class="item-image-small">
							<img src="../../../../assets/images/control-panel/estructura-de-organizacion.png" width="50" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-header>
	<nb-card-body class="px-0 px-sm-2">
		<div class="container">
			<div class="px-2 px-sm-5 h-325">
				<!-- ===============Fila superior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-2 h-30">
					<!-- ***Profesores*** -->
					<div class="item-style item-medium" (click)="verInforme(3, 3)">
						<!-- titulo e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "TASK.PROFESORES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/profesor.png" width="70" alt='{{ "TASK.PROFESORES" | translate }}'>
							</div>
						</div>
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.teachersRegistered}}</h2>
								<p class="title-card-registers">Registrados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.teachersRegisteredLastMonth}}</h5>
									<p>Nuevos en el último mes</p>
								</div>
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.teachersOnlineLastWeek}}</h5>
									<p>Activos la última semana</p>
								</div>
							</div>
						</div>
					</div>
					<!-- ***Alumnos*** -->
					<div class="item-style item-medium" (click)="verInforme(2, 2)">
						<!-- titulo e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "GRUPOS.ALUMNOS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/alumnos.png" width="70" alt='{{ "GRUPOS.ALUMNOS" | translate }}'>
							</div>
						</div>
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.studentsRegistered}}</h2>
								<p class="title-card-registers">Registrados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.studentsRegisteredLastMonth}}</h5>
									<p>Nuevos en el último mes</p>
								</div>
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.studentsOnlineLastWeek}}</h5>
									<p>Activos la última semana</p>
								</div>
							</div>
						</div>
					</div>
					<!-- ***Editores*** -->
					<div class="item-style item-medium" (click)="openEditor()">
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.editorsRegistered}}</h2>
								<p class="title-card-registers">Registrados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.editorsRegisteredLastMonth}}</h5>
									<p>Nuevos en el último mes</p>
								</div>
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.editorsOnlineLastWeek}}</h5>
									<p>Activos la última semana</p>
								</div>
							</div>
						</div>
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6 class="text-right">{{ "EDITARCURSO.AUTORES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Niveles.png" width="70" alt='{{ "EDITARCURSO.AUTORES" | translate }}'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila intermedia================== -->
				<div class="d-flex justify-content-between flex-wrap item-style-large gap-4 mt-4 h-30">
					<!-- ***ASIGNATURAS*** -->
					<div class="item-large">
						<!-- titulo e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 50%; max-width: 50%;">
							<h6>{{ "CURSOS.SUBJECTS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/tasks.png" width="100" alt='{{ "GRUPOS.TAREAS" | translate }}'>
							</div>
						</div>
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.subjectsTotal}}</h2>
								<p class="title-card-registers">Registradas</p>
							</div>
						</div>
					</div>
					<!-- ***Grafica torta*** -->
					<div class="item-chart">
						<div class="text-absolute">
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [innerHTML]="texto1Centro.title"
							[style.background]="texto1Centro.backgroundColor" [style.color]="texto1Centro.color">
							</nb-tag>
							<nb-tag size="tiny" appearance="filled" style="position: relative; z-index: 99999;" [innerHTML]="texto2Centro.title"
							[style.background]="texto2Centro.backgroundColor" [style.color]="texto2Centro.color">
							</nb-tag>
						</div>
						<div class="pie_chart clase-temporal">
						</div>
					</div>
					<!-- ***Centros*** -->
					<div class="item-large justify-content-end">
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.centersTotal}}</h2>
								<p class="title-card-registers">Registradas</p>
							</div>
						</div>
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 50%; max-width: 50%;">
							<h6 class="text-right">{{ "USERDATA.CENTROS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/indicadores sociales.png" width="70" alt='{{ "USERDATA.CENTROS" | translate }}'>
							</div>
						</div>
					</div>
				</div>
				<!-- ===============Fila inferior================== -->
				<div class="d-flex justify-content-between flex-wrap gap-4 mt-4 h-30">
					<!-- ***Grupos*** -->
					<div class="item-style item-medium">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "TASK.GROUPS" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/grupos.png" width="70" alt='{{ "TASK.GROUPS" | translate }}'>
							</div>
						</div>
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.groupsTotal}}</h2>
								<p class="title-card-registers">Registrados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.groupsAverageStudentsPerGroup}}</h5>
									<p>Estudiantes en promedio</p>
								</div>
							</div>
						</div>
					</div>
					<!-- ***Cursos*** -->
					<div class="item-style item-medium">
						<!-- texto e imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6>{{ "EDITARCURSO.COURSES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Proyectos.png" width="70" alt='{{ "EDITARCURSO.COURSES" | translate }}'>
							</div>
						</div>
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.projectsTotal}}</h2>
								<p class="title-card-registers">Creados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.projectsCreatedLastMonth}}</h5>
									<p>Nuevos en el último mes</p>
								</div>
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.projectsUsedLastWeek}}</h5>
									<p>Revisados la última semana</p>
								</div>
							</div>
						</div>
					</div>
					<!-- ***Padres*** -->
					<div class="item-style item-medium" (click)="verInforme(4, 4)">
						<!-- Datos -->
						<div class="d-flex flex-column style-registers">
							<div class="d-flex flex-column align-items-center">
								<h2>{{initialData.parentsRegistered}}</h2>
								<p class="title-card-registers">Registrados</p>
							</div>
							<div class="d-flex justify-content-center align-items-center gap-2">
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.parentsRegisteredLastMonth}}</h5>
									<p>Nuevos en el último mes</p>
								</div>
								<div class="d-flex flex-column align-items-center gap-1">
									<h5>{{initialData.parentsOnlineLastWeek}}</h5>
									<p>Activos la última semana</p>
								</div>
							</div>
						</div>
						<!-- imagen -->
						<div class="d-flex flex-column" style="flex: 0 0 33%; max-width: 33%;">
							<h6 class="text-right">{{ "USERDATA.PADRES" | translate }}</h6>
							<div class="item-image">
								<img src="../../../../assets/images/control-panel/Padres.png" width="70" alt='{{ "USERDATA.PADRES" | translate }}'>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nb-card-body>
</nb-card>
