import { Language } from './../shared/language.model';
import { Country } from './../masters/region.model';
export class CourseModel {
	idCourse: number;
	courseSWLevel: number;
	courseTittle: string;
	creationDate: string;
	deleteDate: string;
	description: string;
	editDate: string;
	user: UserModel;
	language: LanguageModel;
	modeType: string;
	viewType: string;
	nodeStart: number;
	normalAge: number;
	published: number;
	isVisible: number;
	courseType: number;
	project_type: number;
	idCenter: number;
	inGroup: number;

	quality: number;
	secondaryLanguage: number;
	sonsNumber: number;
	cpicture: string;
	subject: SubjectModel;
	idCountry: string;
	isPensarCreando?: number;
	languageData?: any;
	countryData?: any;
	secondaryLanguageData?: any;
	courseSubjects?: [];

	averageKnowledge: any;
	barColor: string;
	progress: number;
	countryLevel: number;

	constructor(
		level: number,
		title: string,
		idSubject: number,
		description: string,
		principalLang: number,
		secondaryLang: number,
		project_type: number,
		idCenter: number,
		idUser: number,
		idCourse?: number,
		idCountry?: string,
		countryLevel?: number,
		cpicture?: string,
		sonsNumber?: number
	) {
		(this.idCourse = idCourse || 0),
			(this.courseSWLevel = level || 0),
			(this.courseTittle = title || ""),
			(this.description = description || ""),
			(this.subject = {
				idSubject: idSubject || null,
			}),
			(this.language = {
				idLanguage: principalLang || null,
				language: null,
			}),
			(this.secondaryLanguage = secondaryLang || null),
			(this.project_type = project_type || null),
			(this.idCenter = idCenter || null),
			(this.user = {
				idUser: idUser,
				firstName: "",
				surname: "",
				pictureUser: "",
			}),
			(this.idCountry = idCountry),
			(this.countryLevel = countryLevel);
		(this.sonsNumber = sonsNumber), (this.cpicture = cpicture || null);
	}
}

export interface SubjectModel {
    idSubject: number
}

export interface LanguageModel {
    idLanguage: number;
		language: string
}
export interface UserModel {
    idUser: number;
    firstName: string;
    surname: string;
    pictureUser: string
}
