import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ConfigChallengeModel } from "src/app/core/models/groups/groups-model";
import { LOCALSTORAGESTRINGS } from "src/app/core/models/masters/localstorage.enum";
import { LoginService } from "src/app/core/services/login";
import { MastersService } from "src/app/core/services/masters";
import { SocketService } from "src/app/core/services/socket/socket-service.service";
import { LocalStorage } from "src/app/core/utils";

const DEFAULTCONFIG = {
	OPTION: 1,
	TIME: 40,
};

const enum TIMES {
	MIN = 1,
	MAX = 120,
}

@Component({
    selector: "app-modal-config-socket-courses",
    templateUrl: "./modal-config-socket-courses.component.html",
    styleUrls: ["./modal-config-socket-courses.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalConfigSocketCoursesComponent implements OnInit {
	form: UntypedFormGroup;
	validationMessages = { minutes: [] };
	selectedGroupInfo: any;
	idGrupo: number;

	constructor(
		private fb: UntypedFormBuilder,
		private activeModal: NgbActiveModal,
		private localStorage: LocalStorage,
		private translateService: TranslateService,
		private socketService: SocketService,
		private mastersService: MastersService,
		public loginService: LoginService,
		private masterService: MastersService,
	) {}

	ngOnInit() {
		this.generateForm();
		this.traducirOpciones();

		this.form.get("option").valueChanges.subscribe((selectedOption) => {

			document.querySelectorAll("nb-radio").forEach((element) => {
				element.classList.remove("btn_desafio-active");
			});

			const selectedRadio = document.querySelector(`nb-radio[value="${selectedOption}"]`);
			if (selectedRadio) {
				selectedRadio.classList.add("btn_desafio-active");
			}
		});
		this.selectedGroupInfo = JSON.parse(
			localStorage.getItem("selectedGroupInfo")
		);
		this.idGrupo = this.selectedGroupInfo	? this.selectedGroupInfo.idGroup : null;
		//definimos variable para tutoriales
		this.masterService.setIdTutorialContext(19);
	}

	closeModal(sendData?: any): void {
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(sendData);
	}

	get challengeTimeControl(): UntypedFormControl {
		if (this.form) {
			return this.form.get("challengeTime") as UntypedFormControl;
		} else {
			return null;
		}
	}

	private traducirOpciones() {
		// Recupero el lenguaje
		const lang = this.localStorage.getItem(LOCALSTORAGESTRINGS.LANG);
		this.translateService.use(lang);

		this.translateService
			.get("VALIDACIONES.MINUTES")
			.subscribe((res: string) => {
				this.validationMessages.minutes.push({
					type: "required",
					message: res,
				});
			});
		this.translateService
			.get("VALIDACIONES.MINMINUTES", { value: TIMES.MIN })
			.subscribe((res: string) => {
				this.validationMessages.minutes.push({
					type: "min",
					message: res,
				});
			});
		this.translateService
			.get("VALIDACIONES.MAXMINUTES", { value: TIMES.MAX })
			.subscribe((res: string) => {
				this.validationMessages.minutes.push({
					type: "max",
					message: res,
				});
			});
	}

	private generateForm() {
		this.form = this.fb.group({
			option: [
				DEFAULTCONFIG.OPTION.toString(),
				{ validators: [Validators.required] },
			],
			challengeTime: [
				DEFAULTCONFIG.TIME,
				{
					validators: [
						Validators.required,
						Validators.min(TIMES.MIN),
						Validators.max(TIMES.MAX),
					],
				},
			],
			synchronizedStudents: [false, { validators: [Validators.required] }],
			randomOrder: [true, { validators: [Validators.required] }],
		});
	}

	saveConfig() {
		if (!this.form.valid) return;
		if(this.form.get("synchronizedStudents").value == true){
			this.socketService.setSyncChallenge(true);
		}
		if (
			this.form.get("option").value == DEFAULTCONFIG.OPTION.toString() &&
			!this.challengeTimeControl.value
		) {
			this.challengeTimeControl.setErrors({ required: true });
			return;
		}
		this.challengeTimeControl.clearValidators();

		//Creamos el log
		let info = this.form.value.option == 1 ? "ORDEN LIBRE." : this.form.value.option == 2 ? "ACTIVIDADES SUELTAS." : "ORDEN SECUENCIAL."
		const bodyLog1 = {
			action: "informes",
			actionData: "Ha iniciado un desafio de " + info,
			startDate: new Date(),
			endingDate: null,
			role: this.loginService.getProfile(),
			idGroup: this.idGrupo,
		};
		this.mastersService.createActivityHistory(bodyLog1.action, bodyLog1.actionData, bodyLog1.startDate, bodyLog1.endingDate, bodyLog1.role, bodyLog1.idGroup).subscribe();

		//Cierro el modal y mando los datos
		this.masterService.getPreviousIdTutorialContext();
		this.activeModal.close(this.form.value as ConfigChallengeModel);

	}
}
