import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dialog-contact',
    templateUrl: './dialog-contact.component.html',
    styleUrls: ['./dialog-contact.component.scss'],
    standalone: false
})
export class DialogContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
