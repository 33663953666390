import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    inputs: ['activeColor', 'baseColor', 'overlayColor', 'backgroundColor', 'image'],
    standalone: false
})
export class FileUploaderComponent implements OnInit {

    activeColor: string = 'green';
    baseColor: string = '#ccc';
    backgroundColor = '#f4f4f4'
    overlayColor: string = 'rgba(255,255,255,0.5)';
    image: string;

    dragging: boolean = false;
    loaded: boolean = false;
    imageLoaded: boolean = false;
    imageSrc: string = '';
    iconColor: string;
    borderColor: string;

    @Output() file = new EventEmitter();
    @Output() newImage = new EventEmitter();
    @Output() newBackground = new EventEmitter();
    @Output() closeBgEdit = new EventEmitter();

    ngOnInit() {

        if (this.image) {
            this.imageSrc = this.image;
        }

        of(this.backgroundColor).subscribe(data => {
            this.newBackground.emit(data);
        });
    }

    handleDragEnter() {
        this.dragging = true;
    }

    handleDragLeave() {
        this.dragging = false;
    }

    handleDrop(e) {
        e.preventDefault();
        this.dragging = false;
        this.handleInputChange(e);
    }

    handleImageLoad() {
        this.imageLoaded = true;
        this.iconColor = this.overlayColor;
    }

    handleInputChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        var pattern = /image-*/;
        var reader = new FileReader();

        if (!file.type.match(pattern)) {
            alert('invalid format');
            return;
        }

        this.loaded = false;
        this.file.emit(file);
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);

    }

    _handleReaderLoaded(e) {
        var reader = e.target;
        this.imageSrc = reader.result;
        this.loaded = true;

        this.newImage.emit(this.imageSrc);
        this.newBackground.emit(this.backgroundColor);
    }

    _setActive() {

        this.borderColor = this.activeColor;
        if (this.imageSrc.length === 0) {
            this.iconColor = this.activeColor;
        }
    }

    _setInactive() {
        this.borderColor = this.baseColor;
        if (this.imageSrc.length === 0) {
            this.iconColor = this.baseColor;
        }
    }

    cancel() {
        this.imageSrc = "null"
        this.closeBgEdit.emit(false);
    }
    clear() {
        this.imageSrc = "null"
    }

}
