import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/core/services/login';
import { SurveysService } from 'src/app/core/services/surveys/surveys.service';
import { MatomoAnalyticsUtils } from 'src/app/core/utils/matomo-analytics.utils';
import { MODAL_DIALOG_TYPES } from 'src/app/core/utils/modal-dialog-types';
import { Utils } from 'src/app/core/utils/utils';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';
import { EncuestasPreguntasEditComponent } from '../encuestas-preguntas-edit/encuestas-preguntas-edit.component';
import { SurveyModel } from 'src/app/core/models/surveys/survey.model';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/core/services/shared/toaster.service';
import { QuestionModel } from 'src/app/core/models/surveys/question.model';

@Component({
    selector: 'app-encuestas-edit',
    templateUrl: './encuestas-edit.component.html',
    styleUrls: ['./encuestas-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class EncuestasEditComponent implements OnInit {

	id: any = 'nuevo';
	survey: any = null;
	questionsList: any[] = [];

	public formulario: UntypedFormGroup;
	viewList: boolean = false;
	cargando = false;
	questionsTypes = [
		{	id: 1, color: "#ff3d71", title: "Respuesta libre"	},
		{	id: 2, color: "#4ac9b4", title: "Selección única"	},
		{	id: 3, color: "#ffc94d", title: "Selección múltiple"},
	];
	langList = [
		{ idLang: 38,	nombre: "Español"	},
		{ idLang: 36,	nombre: "Ingles" },
		{ idLang: 31,	nombre: "Alemán" },
		{ idLang: 46,	nombre: "Francés" },
		{ idLang: 71,	nombre: "Italiano" },
		{ idLang: 127, nombre: "Portugués" },
	];

	constructor(
		public activeModal: NgbActiveModal,
		public surveysService: SurveysService,
		private formBuild: UntypedFormBuilder,
		public loginService: LoginService,
		private imagePipe: ImagenPipe,
		private utils: Utils,
		private modalService: NgbModal,
		private ma: MatomoAnalyticsUtils,
		public translateService: TranslateService,
		private toaster: ToasterService,
	){
		this.createForm();
	}

	ngOnInit(): void{
		this.loadInitialSurveyData();
		this.loadQuestions();
	}

	closeModal(sendData?: any) {
		// Si no hay cambios en el formulario, cierra el modal directamente
		if (this.formulario.pristine) {
			this.activeModal.close(sendData);
		} else {
			// Si hay cambios, guarda los datos y luego cierra el modal si la operación es exitosa
			this.editSurveyData().then(success => {
				if (success) {
					this.activeModal.close(sendData);
				}
			});
		}
	}

	createForm() {
		this.formulario = this.formBuild.group({
			title: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				],
			],
			description: [
				"",
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(500),
				],
			],
			cpicture: [{ value: "", disabled: false }],
			lang: ["", [Validators.required]],
			published: [false],
			visible: [false],
		});
	}

	loadInitialSurveyData(){
		this.formulario.patchValue({
			title: this.survey.title,
			description: this.survey.description,
			lang: this.survey.language.idLanguage,
			cpicture: this.survey.cpicture
				? this.imagePipe.transform(this.survey.cpicture, "surveys")
				: "",
			published: this.survey.published ? true : false,
			visible: this.survey.visible === 1 ? true : false,
		});
	}

	getUserAvatar(): string {
		return this.utils.getUserAvatar(this.survey.user.pictureUser);
	}

	getQuestionType(id: number): any | undefined {
		const questionType = this.questionsTypes.find(qt => qt.id === id);
		return questionType ? questionType : undefined;
	}

	loadQuestions(){
		this.surveysService.getAllQuestionsListBySurvey(this.survey.idEncuestas).subscribe(res => {
			this.questionsList = res.data;
		});
	}

	openQuestion(question: any){
		this.ma.event("click", "view_item", "Curso");
		const modalRef = this.modalService.open(EncuestasPreguntasEditComponent, {
			scrollable: false,
			windowClass: `${MODAL_DIALOG_TYPES.W30} h-100`,
		});
		modalRef.componentInstance.idEncuesta = this.id;
		modalRef.componentInstance.question = question;
		modalRef.result.then(
			(resp) => {
				setTimeout(() => {
					this.loadQuestions();
				}, 300);
			},
			(err) => {}
		);
	}

	newQuestion(){
		/**
		let question : QuestionModel = new QuestionModel(
			0,
			this.id,
			"",
			1,
			0,
		);
		this.surveysService.createUpdateQuestion(question).subscribe(res => {
			if(res.data){
				this.openQuestion(res.data);
			} else{
				this.toaster.error(this.translateService.instant('ENCUESTAS.QUESTIONCREATEDKO'));
			}
		});
		*/
	}

	deleteQuestion(){}

	editSurveyData(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			/**
			let survey: SurveyModel = new SurveyModel(
				this.id,
				this.formulario.value.title,
				this.formulario.value.description,
				this.formulario.value.lang,
				this.formulario.value.cpicture,
				this.formulario.value.published,
				this.formulario.value.visible,
				this.questionsList.length,
			);
			*/
			this.surveysService.createUpdateSurvey(null).subscribe(res => {
				if (res.data) {
					this.toaster.success(this.translateService.instant('ENCUESTAS.EDITOK'));
					resolve(true);
				} else {
					this.toaster.error(this.translateService.instant('ENCUESTAS.EDITKO'));
					resolve(false);
				}
			}, error => {
				this.toaster.error(this.translateService.instant('ENCUESTAS.EDITKO'));
				reject(false);
			});
		});
	}

	deleteSurvey(){}

}
