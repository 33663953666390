import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    standalone: false
})
export class RatingComponent {

  @Input() valoracion: number;
  @Input() maxStars: number;
}
