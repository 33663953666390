import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-calendar',
    templateUrl: './modal-calendar.component.html',
    styleUrls: ['./modal-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ModalCalendarComponent implements OnInit {

  images: Array<string> = []

  constructor(    public activeModal: NgbActiveModal) {
    this.images = [
      '../../../../assets/images/demo_images/schedule.png',
    ]
  }

  ngOnInit() {
  }

  closeModal(sendData?: any) {
    this.activeModal.close(sendData);
  }
}
