import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import printJS from 'print-js';
import { QuizModel } from 'src/app/core/models/quizzes';
import { QuizTypes } from 'src/app/core/models/quizzes/quiz-types.enum';

@Component({
    selector: 'app-recordar-quiz-play-multiple-header',
    templateUrl: './quiz-play-multiple-header.component.html',
    styleUrls: ['./quiz-play-multiple-header.component.scss'],
    standalone: false
})
export class RecordarQuizPlayMultipleHeaderComponent implements OnInit {
    @Input() quiz: QuizModel;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() modeAuto: boolean = false;
    @Input() hideBar: boolean = false
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    public get quizTypes() {
        return QuizTypes;
    }

    onCloseModal(sendData?: any) {
        this.close.next(true);
    }

    showInfo() {
        // const modalRef = this.modalService.open(QuizEditInfoComponent,
        //     {
        //         scrollable: false,
        //         windowClass: MODAL_DIALOG_TYPES.W95
        //     }
        // );
        // modalRef.componentInstance.quiz = this.quiz;
        // modalRef.componentInstance.courseId = this.idCurso;
        // modalRef.componentInstance.graphId = this.idMapa;
    }
}
