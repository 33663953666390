import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ModelQuizElement, QuizModel } from '../../models/quizzes';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';
import { ApiResponse } from '../../models/shared/api-response.model';
import { QuizTemplate } from '../../models/quizzes/quiz-template.model';
import { ElementDataModel } from '../../models/quizzes/element-data.model';
import { TEMPLATE_ELEMENTS_TYPES } from '../../utils/template-elements-types';
import { LoginService } from '../login';
import { QuizElement } from '../../models/quizzes/quiz-element.model';
import { LOCALSTORAGESTRINGS } from '../../models/masters/localstorage.enum';
import { SocketService } from '../socket/socket-service.service';

@Injectable({
	providedIn: "root",
})
export class QuizzesService {
	public currentQuiz = new Subject();
	public selectQuiz = new Subject();
	public userQuiz = new Subject();
	public newQuiz = new Subject();
	public currentImagen = new Subject();
	public arrayQuiz = new Subject();
	public loading = new Subject();
	public currentGraph = new Subject();
	public currentNode = new Subject();
	public type = new Subject();
	public executeAutomatic = new Subject();
	public checkQuiz = new Subject();
	public quizGotResponse = new Subject();
	public automatic;
	private _automatic: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	constructor(
		private datePipe: DatePipe,
		private http: HttpClient,
		private loginService: LoginService,
		private socketService: SocketService
	) {
		this._automatic.next(
			JSON.parse(localStorage.getItem(LOCALSTORAGESTRINGS.AUTOMATIC))
		);
	}

	public _quizOptions: any[] = [];

	public get quizOptions(): any[] {
		return this._quizOptions;
	}
	public set quizOptions(value: any[]) {
		this._quizOptions = value;
	}

	public createQuiz(
		courseId: number,
		graphId: number,
		quiz: QuizModel,
		ordinalType?: number,
	): Observable<any> {
		const q = {
			user: quiz.user,
			quizTittle: quiz.quizTittle,
			quizInstructions: quiz.quizInstructions,
			quizType: quiz.quizType,
			x: quiz.x,
			y: quiz.y,
			duration: quiz.duration,
			ordinal: quiz.ordinal,
			ordinalType: ordinalType ? ordinalType : 0,
			quizSwlevel: quiz.quizSwlevel,
			idTargetCreation: graphId,
			idCourseCreation: courseId,
			nodeType: quiz.nodeType,
			id: quiz.id,
			idOriginal: quiz.idOriginal,
			creationDate: quiz.creationDate,
			published: quiz.published,
			isMultiplexed: quiz.isMultiplexed,
			defaultQuizPattern: quiz.defaultQuizPattern,
			idQPattern: quiz.idQPattern,
		};

		return this.http
			.post<any>(`quizze/courses/${courseId}/graphs/${graphId}/quizzes`, q)
			.pipe(
				map((res) => {
					if (!res.error.code) {
						res.data = {
							...res.data,
							originalX: res.data.x,
							originalY: res.data.y,
							type: "text",
							size:
								this.loginService.esAutor() || res.sizeQuiz === "large"
									? SIGMA_CONSTANTS.QUIZ_SIZE
									: SIGMA_CONSTANTS.QUIZ_SMALL_SIZE,
							edges: [],
						};
					}

					return res;
				})
			);
	}

	/**
	 * Metodo para estimar el tiempo de un quiz con IA
	 */
	estimateQuizTimeWithIA(idQuiz: number): Observable<any>{
		const body = {};
		return this.http.post(`quizze/estimateQuizTimeWithIA/${idQuiz}`, body);
	}
	
	/**
	 * METODO PARA CREAR UN QUIZ DESDE EL MODO LISTA
	 */
	createQuizFromModeList(idCourse: number, idTarget: number, idNode: number): Observable<any>{
		const body = {};
		return this.http.post(`quizze/createQuizFromModeList/${idCourse}/${idTarget}/${idNode}`, body);
	}

	/**
	 * Endpoint para cambiar el ordinal de un registro en la tabla quizzesNode
	 */
	changeOrdinalQuizzesNode(idNode: number, from: number, to: number): Observable<any> {
		const url = `quizze/changeOrdinalQuizzesNode/${idNode}/${from}/${to}`;
		const q = {};
		return this.http.put(url, q);
	}

	public saveQuizPos(
		id: number,
		x: number,
		y: number,
		courseId: number,
		graphId: number,
		idUser: number
	) {
		const q = {
			idOriginal: id,
			x,
			y,
			idCourseCreation: courseId,
			idTargetCreation: graphId,
			idUser,
		};

		return this.http.post<any>(
			`quizze/courses/${courseId}/graphs/${graphId}/quizzes/`,
			q
		);
	}

	public deleteQuiz(quizId: number, courseId: number, graphId: number) {
		return this.http.delete(
			`quizze/courses/${courseId}/graphs/${graphId}/quizzes/${quizId}`
		);
	}

	public createEdge(
		nodeFromId: string,
		nodeToId: string,
		courseId: number,
		graphId: number
	) {
		return this.http.put(
			`quizze/courses/${courseId}/graphs/${graphId}/idFrom/${nodeFromId}/edges/idConect/${nodeToId}`,
			{}
		);
	}

	public deleteEdge(
		nodeFromId: string,
		nodeToId: string,
		courseId: number,
		graphId: number
	) {
		return this.http.delete(
			`quizze/courses/${courseId}/graphs/${graphId}/idFrom/${nodeFromId}/edges/idConect/${nodeToId}`
		);
	}

	/**
	 * Return quizzes from determinated node
	 * @param idNode idNode from current node
	 */
	getQuizzesFromNode(
		idNode: number,
		idCourse: number,
		idGraph: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`node/getquizzesnode/course/${idCourse}/graph/${idGraph}/node/${idNode}`
			)
			.pipe(map((res) => res.data));
	}

	/**
	 * Return idNode source from the currentQuiz
	 * @param idQuiz
	 * @returns
	 */
	getNodeFromQuiz(
		idQuiz: number,
		idCourse: number,
		idGraph: number,
		isMultiplexed: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`node/getnodeconnectquizz/course/${idCourse}/graph/${idGraph}/quiz/${idQuiz}/${isMultiplexed}`
			)
			.pipe(map((res) => res.data));
	}

	/**
	 * Return el detalle de un quiz simple para poder reproducirlo o ejecutarlo como estudiante
	 * @param id
	 * @returns
	 */
	public getQuizSimpleCanvasQuizSimple(
		id: number,
		courseId?: number,
		graphId?: number
	): Observable<any> {
		if (id == undefined) {
			return of(null);
		} else {
			return this.http
				.get<ApiResponse<any>>(
					`quizze/quizzes/roleStudent/${id}/${courseId}/${graphId}`
				)
				.pipe(map((res) => res.data));
		}
	}

	/**
	 * Return el detalle de un quiz hijo de un quiz multiple para ejecutarlo como estudiante
	 * @param id
	 * @returns
	 */
	public getQuizMultipleCanvasQuizMultiple(
		id: number,
		courseId?: number,
		graphId?: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`quizze/quizzesMultiples/roleStudent/${id}/${courseId}/${graphId}`
			)
			.pipe(map((res) => res.data));
	}

		/**
	 * Metodo para obtener el quiz hijo del multiple que toca resolver
	 */
		public getNextQuizMultiple(idCourse: number, idTarget: number, idGrupo: number, idQuiz: number){
			return this.http.get<any>(`quizze/getNextQuizMultiple/${idCourse}/${idTarget}/${idGrupo}/${idQuiz}/rolestudent`)
		}

	public getQuizMultiple(
		id: number,
		courseId?: number,
		graphId?: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(`quizze/quizzes/${id}/${courseId}/${graphId}`)
			.pipe(map((res) => res.data));
	}

	public getQuiz(
		id: number,
		courseId?: number,
		graphId?: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`quizze/quizzes/roleStudent/${id}/${courseId}/${graphId}`
			)
			.pipe(map((res) => res.data));
	}

	// URL (TIPO GET):
	// idQuiz : id del quiz simple
	// idUser : id del estudiante
	// api-dev-pruebas/rest/group/getQuizSimpleInforme/{idQuiz}/{idUser}

	public getQuizInforme(id: number, idUser: number): Observable<any> {
		// return this.http.get<ApiResponse<any>>(`courses/${courseId}/graphs/${graphId}/quizzes/${id}`).pipe(map(res => res.data));
		return this.http
			.get<ApiResponse<any>>(`group/getQuizSimpleInforme/${id}/${idUser}`)
			.pipe(map((res) => res.data));
	}

	// URL (TIPO GET):
	// idQuiz : id del quiz multiple
	// idUser : id del estudiante
	// api-dev-pruebas/rest/group/getQuizzesHijosInInforme/{idQuiz}/{idUser}
	public getQuizInformMultipleHijos(
		id: number,
		idUser: number,
		idGroup: number
	): Observable<any> {
		// return this.http.get<ApiResponse<any>>(`courses/${courseId}/graphs/${graphId}/quizzes/${id}`).pipe(map(res => res.data));
		return this.http
			.get<ApiResponse<any>>(
				`group/getQuizzesHijosInInforme/${id}/${idUser}/${idGroup}`
			)
			.pipe(map((res) => res.data));
	}

	// URL (TIPO GET):
	// idQuiz : id del quiz simple
	// idUser : id del estudiante
	// api-dev-pruebas/rest/group/getQuizSimpleInforme/{idQuiz}/{idUser}
	public getQuizInformMultiple(id: number, idUser: number): Observable<any> {
		// return this.http.get<ApiResponse<any>>(`courses/${courseId}/graphs/${graphId}/quizzes/${id}`).pipe(map(res => res.data));
		return this.http
			.get<ApiResponse<any>>(`group/getQuizMultipleInforme/${id}/${idUser}`)
			.pipe(map((res) => res.data));
	}

	// URL (TIPO POST):
	// api-dev-pruebas/rest/group/quizReview
	// BODY: JSON.
	// idQuizzesstack : number
	// check : string			“Correcto” / “Repetir”
	// comentary : string
	public correcOrIncorrect(
		idQuizzesstack: string,
		check: string,
		comentary: string,
		idGroup: number,
	): Observable<any> {
		const body = {
			idQuizzesstack: idQuizzesstack,
			check: check,
			comentary: comentary,
			idGroup: idGroup,
		};

		return this.http.post<ApiResponse<any>>(`group/quizReview`, body);
	}

	public checkingQuiz(idQuizzesstack: string, calificacion: string): Observable<any> {
		const body = {
			idQuizzesstack: idQuizzesstack,
			calificacion: calificacion,
		}
		return this.http.post<ApiResponse<any>>(`group/checkingQuiz`, body);
	}

	public getQuizBusqueda(id: number): Observable<any> {
		// return this.http.get<ApiResponse<any>>(`courses/${courseId}/graphs/${graphId}/quizzes/${id}`).pipe(map(res => res.data));
		return this.http.get<ApiResponse<any>>(`quizze/quizzes/roleStudent/${id}`);
	}
	
	public getQuizFromTargetByModeList(idCourse: number, idTarget: number, idQuiz: number): Observable<any> {
		return this.http.get<ApiResponse<any>>(`quizze/getQuizFromTargetByModeList/${idCourse}/${idTarget}/${idQuiz}`);
	}

	public createTempQuizz(quiz: any) {
		this.newQuiz.next({
			id: "q" + quiz.idOriginal,
			idOriginal: quiz.idOriginal,
			label: "",
			x: quiz.x,
			y: quiz.y,
			originalX: quiz.x,
			originalY: quiz.y,
			type: "text",
			text: quiz.stringCaptionsTitle,
			size: 8,
			originalColor: "#D7DBDC",
			color: "#D7DBDC",
			description: quiz.description,
			nodeType: "Quiz",
			nodeSwlevel: quiz.quizSwlevel,
			duration: quiz.duration,
			delete: false,
		});
	}

	assignTemplateToQuiz(
		quizId: number,
		templateId: number
	): Observable<ApiResponse<any>> {
		return this.http.put<ApiResponse<any>>(
			`template/${templateId}/quiz/${quizId}`,
			{}
		);
	}

	/**
	 * Devuelve la plantilla de un quiz con sus elementos. Antiguo getTemplatesQuizzesById.
	 * @param quizId identificador del quiz cuya plantilla se va a recuperar
	 * @returns un observable que contiene la información dentro del objeto data
	 */
	getQuizTemplate(quizId: number): Observable<QuizTemplate> {
		return this.http
			.get<ApiResponse<QuizTemplate>>(`templateQuizzesById/${quizId}`)
			.pipe(map((res) => res.data));
	}

	/**
	 * Devuelve los datos del elemento de la plantilla en el quiz: si es autocorregido y el texto/archivo asociado. Antiguo getTemplatesElementQuizzesByIdElement.
	 * @param templateElementId identificador del elemento de la plantilla
	 * @param quizId identificador del quiz al que pertenece la plantilla que tiene el elemento
	 * @returns un observable que contiene la información dentro del objeto data
	 */
	public getQuizElementData(
		templateElementId: number,
		quizId: number
	): Observable<ElementDataModel> {
		return this.http
			.get<ApiResponse<ElementDataModel[]>>(
				`templateElementsBy/${quizId}/${templateElementId}`
			)
			.pipe(map((res) => res.data[0]));
	}

	/**
	 * Devuelve la plantilla asociada al quiz con todos sus elementos.
	 * @param quiz quiz cuya template y elementos se van a recuperar.
	 * @returns un observable que contiene la información de la plantilla con los elementos en la propiedad elements.
	 */
	public getQuizTemplateWithElements(quiz: QuizModel): Observable<any> {
		return this.getQuizTemplate(quiz.idOriginal).pipe(
			switchMap((quizTemplate) => {
				// Tenemos la información de la plantilla y la plantilla tiene elementos
				const calls = [];
				if (
					quizTemplate.template &&
					quizTemplate.template.templateElement &&
					quizTemplate.template.templateElement.length > 0
				) {
					quizTemplate.template.templateElement.forEach((element) => {
						calls.push(
							this.getQuizElementData(
								element.idTemplateElement,
								quiz.idOriginal
							).pipe(
								map((elementData) => {
									return {
										idTemplateElement: element.idTemplateElement,
										idTemplate: element.idTemplate,
										elementType: element.elementsType.idElementType,
										xPosition: element.xPosition,
										yPosition: element.yPosition,
										xSize: element.xSize,
										ySize: element.ySize,
										style:
											TEMPLATE_ELEMENTS_TYPES[
												element.elementsType.idElementType
											].name,
										icon: TEMPLATE_ELEMENTS_TYPES[
											element.elementsType.idElementType
										].icon,
										data: elementData ? elementData.data : undefined,
										idTemplateElementQuizz: elementData
											? elementData.idTemplateElementQuizz
											: undefined,
									};
								})
							)
						);
					});
				}
				return calls.length > 0
					? forkJoin(calls).pipe(
							map((elements: any) => {
								quizTemplate.elements = elements;
								return quizTemplate;
							})
					  )
					: of(quizTemplate);
			})
		);
	}

	public setQuizElementData(
		quizId: number,
		elementId: number,
		responseCheck?: number,
		templateElementId?: number,
		file?: File,
		text?: string
	) {
		const body = {
			idTemplateElement: elementId,
			idQuiz: quizId,
			idTemplateElementQuizz: templateElementId,
			data: text,
			responseCheck: responseCheck ? 1 : 0,
		};

		const form: FormData = new FormData();

		form.append("quiz", JSON.stringify(body));
		form.append("files", file || "");

		return this.http.post<ApiResponse<any>>(`quizze/uploadFileQuiz`, form);
	}

	// *** CURRENT NODE ***
	public setCurrentQuiz(quiz) {
		this.currentQuiz.next(quiz);
	}

	public getCurrentQuiz() {
		return this.currentQuiz.asObservable();
	}

	public setCurrentGraph(currentGraph) {
		this.currentGraph.next(currentGraph);
	}

	public setSelectQuiz(quiz) {
		this.selectQuiz.next(quiz);
	}

	public setNewQuiz(quiz) {
		this.newQuiz.next(quiz);
	}

	public setUserQuiz(userQuiz) {
		this.userQuiz.next(userQuiz);
	}

	public setArrayQuiz(arrayquiz) {
		this.arrayQuiz.next(arrayquiz);
	}

	public setLoading(loading: any) {
		this.loading.next(loading);
	}

	public setCurrenImagen(imagen: any) {
		this.currentImagen.next(imagen);
	}

	public getAutomatic() {
		return this._automatic.asObservable();
	}

	public setQuizGotResponse(quiz: any) {
		this.quizGotResponse.next(quiz);
	}

	public getQuizGotResponse() {
		return this.quizGotResponse.asObservable();
	}

	public setAutomatic(automatic: boolean): void {
		this._automatic.next(automatic);
		localStorage.setItem(LOCALSTORAGESTRINGS.AUTOMATIC, automatic.toString());
	}

	public setExecuteAutomatic(executeAutomatic) {
		this.executeAutomatic.next(executeAutomatic);
		localStorage.setItem("executeAutomatic", executeAutomatic);
	}

	public setCurrentNode(node) {
		this.currentNode.next(node);
	}

	public setType(type) {
		this.type.next(type);
	}

	public emptyCurrentQuiz() {}
	public emptyArrayQuiz() {}

	// *** N O D E ***
	public getEmptyQuiz() {
		var date = this.datePipe.transform(Date.now(), "yyyy-MM-dd HH:mm:ss");
		return {
			stringCaptionsTitle: "",
			language1: "",
			language2: "",
			author: "",
			creation: date,
			edition: date,
			quizSwlevel: 0,
			duration: 0,
			text: "",
		};
	}

	public createOrUpdateQuizElement(
		quizId: number,
		element: any,
		file?: File
	): Observable<any> {
		const formData: FormData = new FormData();

		formData.append("elements", JSON.stringify(element));
		formData.append("files", file);

		return this.http.post<any>(
			`quizze/createUpdate/ElementsQuiz/${quizId}`,
			formData
		);
	}

	public deleteQuizElement(idElement: number) {
		return this.http.delete(`quizze/deleteElementsQuiz/${idElement}`);
	}

	public anserwQuizElement(
		quizId: number,
		element,
		file?: File
	): Observable<any> {
		const formData: FormData = new FormData();

		//Endpoint Post /quizzes/{idQuiz}/uploadfile/{type}/manualcorrect

		formData.append("elements", JSON.stringify(element));
		formData.append("files", file);

		return this.http.post<any>(
			`quizze/quizzes/${quizId}/uploadfile/${element.type}/manualcorrect`,
			formData
		);
	}

	/***********SUB QUIZZES************************* */
	public getSubQuizMultiEdit(quizId: number): Observable<any> {
		const formData: FormData = new FormData();
		// formData.append('quizz', '' ))

		const url = `quizze/createQuizzesMultipleAuto/${quizId}`;
		return this.http.get<any>(url);
	}

	deleteSubQuiz(quizId: number) {
		const formData: FormData = new FormData();
		// formData.append('quizz', '' ))

		const url = `quizze/quizmultiple/deletequiz/${quizId}`;
		return this.http.delete<any>(url);
	}

	addSubQuiz(quizId: number) {
		const formData: FormData = new FormData();
		// formData.append('quizz', '' ))

		const url = `quizze/quizmultiple/addquiz/${quizId}`;
		return this.http.put<any>(url, formData);
	}

	/********************************** */
	/**METODOS SUBQUIZZES */
	saveSubQuiz(idQuizMultiple: number, quizz_multiple: any) {
		const q = {
			creationDate: quizz_multiple.creationDate,
			duration: quizz_multiple.duration,
			idQuiz: quizz_multiple.idQuiz,
			lastEditionDate: quizz_multiple.lastEditionDate,
			quizInstructions: quizz_multiple.quizInstructions,
			quizTitle: quizz_multiple.quizTitle,
			idQuizMultiple: quizz_multiple.idQuizMultiple,
		};

		const formData: FormData = new FormData();
		formData.append("quizzes_multiples", JSON.stringify(quizz_multiple));

		const url = `quizze/quizmultiple/updatequiz/${idQuizMultiple}`;
		return this.http.post<any>(url, q);
	}

	//   @PostMapping("/quizmultiple/elements/{idQuizMultiple}/{elementType}")
	quizzAddElement(
		idQuizMultiple: number,
		elementType: string,
		fileName?,
		language?: number
	) {
		const url = `quizze/quizmultiple/elements/${idQuizMultiple}/${elementType}`;
		const formData: FormData = new FormData();
		formData.append("data", fileName);
		formData.append(LOCALSTORAGESTRINGS.LANG, JSON.stringify(language));

		return this.http.post<any>(url, formData);
	}

	//   @PostMapping("/quizmultiple/elements/{idQuizMultiple}/{elementType}")
	quizzUpdateElement(data: ModelQuizElement) {
		const url = `quizze/quizmultiple/elements/update`;
		const formData: FormData = new FormData();
		formData.append("data", JSON.stringify(data));
		return this.http.post<any>(url, formData);
	}

	//@DeleteMapping("/quizmultiple/elements/{idQuizzesDataElements}")
	quizzDeleteElement(idQuizzesDataElements: number) {
		const url = `quizze/quizmultiple/elements/${idQuizzesDataElements}`;

		return this.http.delete<any>(url);
	}

	//@DeleteMapping("/quizmultiple/elements/{idQuizzesDataElements}")
	deleteAllSubQuizesMultiples(idQuizzesMultiple: number) {
		const url = `quizze/quizmultiple/deletequizzeshijos/${idQuizzesMultiple}`;
		return this.http.delete<any>(url);
	}

	certificarQuiz(idQuiz: any, idGrafo: any, idCurso: any) {
		// api-dev-pruebas/rest/user/certifyQuiz/courses/{idCourse}/graphs/{idGraph}/quizzes/{idQuiz}.
		const url = `quizze/certifyQuiz/courses/${idCurso}/graphs/${idGrafo}/quizzes/${idQuiz}`;
		return this.http.get<any>(url);
	}

	getRepetitionTypes() {
		const url = `quizze/repetitionTypes`;
		return this.http.get<any>(url);
	}

	updateRepetitionTypes(idQuiz: number, pattern: number) {
		const body = {};
		const url = `quizze/changeRepetitionTypes/${idQuiz}/${pattern}`;
		return this.http.post<any>(url, body);
	}

	/**
	 * Función para obtener los datos del quiz pasado como parámetro
	 * @param idQuiz ID del Quiz a devolver
	 * @returns
	 */
	getQuizById(idQuiz: number): Observable<any> {
		const url = `quizze/quizzes/roleStudent/${idQuiz}`;
		return this.http.get<any>(url);
	}

	getResponsesFromStudents(
		idGroup: number,
		idQuiz: number
	): Observable<ApiResponse<any>> {
		const url = `quizze/quizSessionReport/${idGroup}/${idQuiz}`;
		return this.http.get<ApiResponse<any>>(url);
	}

	getNextQuizSemiAuto(
		idCourse: number,
		idTarget: number,
		idQuiz: number,
		idGroup: number,
		action: string
	): Observable<ApiResponse<any>> {
		const url = `quizze/nextQuizSemiAuto/${idCourse}/${idTarget}/${idQuiz}/${idGroup}/${action}`;
		return this.http.get<ApiResponse<any>>(url);
	}

	showNextQuizSemiAuto(
		idCourse: number,
		idTarget: number,
		idQuiz: number,
		idGroup: number
	): Observable<ApiResponse<any>> {
		const url = `quizze/showNextQuizSemiAuto/${idCourse}/${idTarget}/${idQuiz}/${idGroup}`;
		return this.http.get<ApiResponse<any>>(url);
	}

	/**
	 * Función que devuelve el modo lista de un grafo
	 * @param idTarget ID del grafo
	 * @returns Devuelve un listado con los quizzes de ese grafo para mostrárselos al estudiante en modo lista
	 */
	getModeList(idTarget: number): Observable<any> {
		const url = `quizze/getquizzesfromtargetforchallengemodelist/${idTarget}`;
		return this.http.get(url);
	}

	/**
	 *
	 * @param idTarget ID del grado
	 * @param idCase ID de la opción seleccionada según los parámetros que haya configurado el profesor
	 * @returns Devuelve el listado
	 */
	getModeAutoList(idTarget: number, idCase: number): Observable<any> {
		if (idCase == 2 || idCase == 1) {
			this.socketService.setSyncChallenge(true);
		}
		const url = `quizze/getquizzesfromtargetforchallengemodeauto/${idTarget}/${idCase}`;
		return this.http.get(url);
	}

	/**
	 * Return less power node from determinated graph
	 * @param idQuiz idQuiz from current quiz
	 * @param idCourse idCourse from current course
	 * @param idGraph idGraph from current graph
	 */
	getNodesLessPower(
		idCourse: number,
		idGraph: number,
		idQuiz: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`node/getnodeslesspower/${idCourse}/${idGraph}/${idQuiz}`
			)
			.pipe(map((res) => res.data));
	}

	/**
	 * Return less power node from determinated graph
	 * @param idQuiz idQuiz from current quiz
	 * @param idCourse idCourse from current course
	 * @param idGraph idGraph from current graph
	 */
	getQuizLessOrdinal(
		idCourse: number,
		idGraph: number,
		idNode: number
	): Observable<any> {
		return this.http
			.get<ApiResponse<any>>(
				`quizze/getquizlessordinal/${idCourse}/${idGraph}/${idNode}`
			)
			.pipe(map((res) => res.data));
	}

	//   @PostMapping("/quizmultiple/elements/{idQuizMultiple}/{elementType}")
	createUpdatePrompt(data) {
		const url = `quizze/createupdateprompt`;
		return this.http.post<any>(url, data);
	}

	getQuizPrompt(
		idCourse: number,
		idTarget: number,
		idQuiz: number
	): Observable<any> {
		const url = `quizze/getquizprompt/${idCourse}/${idTarget}/${idQuiz}`;
		return this.http.get(url);
	}

	public onDeleteOptions() {
		const httpCalls = [];

		for (let index = 0; index < this._quizOptions.length; index++) {
			const element = this._quizOptions[index];
			httpCalls.push(this.deleteQuizElement(element.idQuizzesDataElements));
		}

		forkJoin(httpCalls).subscribe((response: any[]) => {
			response.forEach((res) => {
				if (res.error.code === 0) {
					this._quizOptions = [];
				}
			});
		});
	}

	/**
	 * METODO PARA OBTENER LA LISTA DE QUIZZES DE ACTIVIDADES FLASH
	 */
	getQuizzesForActividadesFlash(
		idCourse: any,
		targets: any,
		deckSize: number,
		smallQuizzes: boolean
	): Observable<any> {
		const body = {
			idCourse: idCourse,
			targets: targets,
			deckSize: deckSize,
			smallQuizzes: smallQuizzes,
		};
		return this.http.post<any>(`quizze/getQuizzesForActividadesFlash`, body);
	}

	/**
	 * METODO PARA OBTENER LA CANTIDAD DE QUIZZES DE ACTIVIDADES FLASH
	 */
	getCantidadQuizzesForActividadesFlash(
		idCourse: any,
		targets: any
	): Observable<any> {
		const body = {
			idCourse: idCourse,
			targets: targets,
			deckSize: 0,
			smallQuizzes: true,
		};
		return this.http.post<any>(
			`quizze/getCantidadQuizzesForActividadesFlash`,
			body
		);
	}

	/**
	 * METODO PARA OBTENER LA CANTIDAD DE QUIZZES DE ACTIVIDADES FLASH
	 */
	createQuizHistorial(quizHistorial: any):Observable<any>{
        return this.http.post<any>(`quizze/createQuizHistorial`, quizHistorial)
    }
}
