<div>
	<div class="pad-right-container" [ngClass]="isShow ? 'show-pad' : 'hide-pad'">
		<!-- PAD RIGHT -->
		<!--<div>
			<a class="">
				<p>{{nowIndex}}</p>
			</a>
		</div>-->

		<div class="set">
			<nav class="d-pad">
				<!-- <a *ngIf="hasUpPowers" class="up" (click)="addPower()"></a> -->
				<!-- (click)="addPower()"   this method is disabled for now, when aviable just pit it on the up a element-->

				<a class="right" (click)="pointNodeAction(true, false)" *ngIf="nowIndex < finishIndex"></a>

				<div *ngIf="showDiscoverButton == true">
					<a class="center" (click)="discoverModeSwitch()" nbTooltip="{{ 'PADS.STEPBYSTEP' | translate }}">
						<img src="assets/images/icons/PisadasDiscovery.png"
							[ngClass]="discoverModeOn ? 'granate-color' : 'white-color'" width="20" height="28">
					</a>
				</div>

				<!-- (click)="removePower()"   this method is disabled for now, when aviable just pit it on the down a element-->

				<!-- <a *ngIf="hasDownPowers" class="down" (click)="removePower()"></a> -->

				<a class="left" (click)="pointNodeAction(false, true)" *ngIf="nowIndex > 0"></a>
			</nav>
		</div>

	</div>
</div>


<ng-container *ngIf="showPowerSelector">
	<app-power-selector [arrayValues]="arrayValues"
		(arrayValuesChanged)="onArrayValuesChanged($event)"></app-power-selector>
</ng-container>
