
<div class="d-flex flex-column align-items-center justify-content-center">
	<!-- Grabando -->
	<div *ngIf="grabando" class="d-flex flex-column justify-content-center w-100 gap-2">
		<!-- animacion que indica el inicio de la grabacion -->
		<!-- <div class="d-flex justify-content-center align-items-center">
			<img src="/assets/images/onda-sonora.gif" width="50px" height="50px" class="loading-gif" />
		</div> -->
		<!-- minutos y segundos transcurridos de la grabacion -->
		<div class="d-flex flex-column justify-content-center align-items-center w-100">
			<div class="d-flex justify-content-center align-items-center">
				<span id="minutes">{{minutes}}</span>:<span id="seconds">{{seconds}}</span>
			</div>
			<span>{{ 'MODALAUDIOMICRO.RECORDING' | translate }}</span>
		</div>
		<!-- Evento para detener grabacion -->
		<div class="d-flex justify-content-center align-items-center">
			<img [src]="Stop" width="50px" height="50px" class="pointer" (click)="stopRecording()"
				nbTooltip="Parar" />
		</div>
	</div>
	<!-- Audio grabado -->
	<div class="d-flex justify-content-center align-items-center position-relative" *ngIf="!grabando && audioFiles">
		<audio *ngFor="let audio of audioFiles" controls='true' [src]="audio"></audio>
		<!-- Boton para eliminar el archivo pre cargado -->
		<div *ngIf="fromQuiz && !answered" class="position-btn-delete">
			<button nbButton size="medium" class="btn-red" (click)="deleteAll()">
				<nb-icon icon="trash-outline"></nb-icon>
			</button>
		</div>
	</div>
</div>
